import { Breadcrumbs, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { DataContext } from "../../../../Context/dataContext";
import { getRequest } from "../../../../Helpers/httpRequests";
import { useAppSelector } from "../../../../Redux/app/hooks";

const BSBreadcrumbs = () => {
  const current_customer = useAppSelector(
    (state) => state.customer
  );
  const [queryParams] = useSearchParams();
  const [projectName, setProjectName] = useState("");
  const { accessToken } = useContext(DataContext);

  const projectUUID = queryParams.get("project");
  useEffect(() => {
    if (projectUUID) {
      const getProjectName = async () => {
        const res = await getRequest(
          `/react/api/${current_customer.uuid}/get-project/` + projectUUID,
          accessToken
        );
        setProjectName(res.data.project.name);
      };
      getProjectName();
    }
  }, [queryParams, projectUUID, accessToken, current_customer.uuid]);

  // Home / Projects / Project XYZ / Campaign Results
  if (projectUUID) {
    return (
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">Home</Link>
        <Link
          to={`/${current_customer.uuid}/simulations/campaigns/projects/list`}
        >
          Projects
        </Link>
        <Link
          to={`/${current_customer.uuid}/simulations/campaigns/projects/${projectUUID}`}
        >
          {projectName}
        </Link>
        <Typography color="text.primary">Campaign Results</Typography>
      </Breadcrumbs>
    );
  } else {
    return (
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">Home</Link>
        <Link
          className="text-links"
          to={`/${current_customer.uuid}/simulations/campaigns/list`}
        >
          Campaigns
        </Link>
        <Typography color="text.primary">Campaign Results</Typography>
      </Breadcrumbs>
    );
  }
};

export default BSBreadcrumbs;
