import { Box, Paper, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import {
  FaBan,
  FaExclamationTriangle,
  FaEyeSlash,
  FaFileCode,
  FaTimesCircle,
} from "react-icons/fa";
import { COLORS } from "../../../Styles/colors";
import CampaignContext from "./provider/CampaignContext";

const ResultsBanner = () => {
  const { outcomeCounts } = useContext(CampaignContext); // Access campaign from context

  // Use fallback values for outcomeCounts if they are not valid
  const blockedCount = outcomeCounts && outcomeCounts.blocked || 0;
  const alertedCount = outcomeCounts && outcomeCounts.alerted || 0;
  const loggedCount = outcomeCounts && outcomeCounts.logged || 0;
  const nothingCount = outcomeCounts && outcomeCounts.nothing || 0;
  const notScoredCount = outcomeCounts && outcomeCounts.notScored || 0;

  return (
    <Box
      sx={{
        height: 100,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Paper
        sx={{
          backgroundColor: COLORS.success.main,
          flex: 1,
          marginRight: 5,
          marginTop: 1,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <FaBan
          style={{ marginTop: 20, marginLeft: 20 }}
          size={40}
          color={"white"}
        />
        <Stack sx={{ marginLeft: 5, marginTop: 1 }}>
          <Typography
            component={"span"}
            fontSize={25}
            fontWeight={"bold"}
            color={"white"}
          >
            {blockedCount}
          </Typography>
          <Typography
            component={"span"}
            fontSize={15}
            fontWeight={"bold"}
            color="white"
          >
            Blocked
          </Typography>
        </Stack>
      </Paper>
      <Paper
        sx={{
          backgroundColor: COLORS.primary.main,
          flex: 1,
          marginRight: 4,
          marginTop: 1,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <FaExclamationTriangle
          style={{ marginTop: 20, marginLeft: 20 }}
          size={40}
          color={"white"}
        />
        <Stack sx={{ marginLeft: 5, marginTop: 1 }}>
          <Typography
            component={"span"}
            fontSize={25}
            fontWeight={"bold"}
            color={"white"}
          >
            {alertedCount}
          </Typography>
          <Typography
            component={"span"}
            fontSize={15}
            fontWeight={"bold"}
            color="white"
          >
            Alerted
          </Typography>
        </Stack>
      </Paper>
      <Paper
        sx={{
          backgroundColor: COLORS.yellow,
          flex: 1,
          marginRight: 5,
          marginTop: 1,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <FaFileCode
          style={{ marginTop: 20, marginLeft: 20 }}
          size={40}
          color={"white"}
        />
        <Stack sx={{ marginLeft: 5, marginTop: 1 }}>
          <Typography
            component={"span"}
            fontSize={25}
            fontWeight={"bold"}
            color={"white"}
          >
            {loggedCount}
          </Typography>
          <Typography
            component={"span"}
            fontSize={15}
            fontWeight={"bold"}
            color="white"
          >
            Logged
          </Typography>
        </Stack>
      </Paper>
      <Paper
        sx={{
          backgroundColor: COLORS.error.main,
          flex: 1,
          marginRight: 5,
          marginTop: 1,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <FaEyeSlash
          style={{ marginTop: 20, marginLeft: 20 }}
          size={40}
          color={"white"}
        />
        <Stack sx={{ marginLeft: 5, marginTop: 1 }}>
          <Typography
            component={"span"}
            fontSize={25}
            fontWeight={"bold"}
            color={"white"}
          >
            {nothingCount}
          </Typography>
          <Typography
            component={"span"}
            fontWeight={"bold"}
            fontSize={15}
            color="white"
          >
            No evidence
          </Typography>
        </Stack>
      </Paper>
      <Paper
        sx={{
          backgroundColor: COLORS.secondary.main,
          flex: 1,
          marginTop: 1,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <FaTimesCircle
          style={{ marginTop: 20, marginLeft: 20 }}
          size={40}
          color={"white"}
        />
        <Stack sx={{ marginLeft: 5, marginTop: 1 }}>
          <Typography
            component={"span"}
            fontSize={25}
            fontWeight={"bold"}
            color={"white"}
          >
            {notScoredCount}
          </Typography>
          <Typography
            component={"span"}
            fontWeight={"bold"}
            fontSize={15}
            color="white"
          >
            Not Scored
          </Typography>
        </Stack>
      </Paper>
    </Box>
  );
};


export default ResultsBanner;
