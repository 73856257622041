import React, { useEffect, useState, useContext } from "react";
import TextButton from "../../Buttons/TextButton";
import { FaPlus } from "react-icons/fa";
import { Button, Stack } from "@mui/material";
import FormRowWithDetails from "../../FormHelpers/FormRowWithDetails/FormRowWithDetails";
import FormModal from "../../Modals/FormModal";
import { DataContext } from "../../../Context/dataContext";
import {
  getSecurityTools,
  getSimulations,
  handleFormSubmit,
} from "./functions";
import { useAppSelector } from "../../../Redux/app/hooks";

const Form = (props) => {
  // Context
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector((state) => state.customer);
  const { open, setOpen } = props;

  // States
  const [simulations, setSimulations] = useState([]);
  const [securityTools, setSecurityTools] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    simulation: "",
    securityTool: "",
  });

  // Opens the form on button click
  const handleClick = () => {
    setOpen(true);
  };

  // Submits the form
  const handleSubmit = (e) => {
    // prevent page from reloading
    e.preventDefault();

    const args = {
      accessToken,
      formData,
      current_customer,
    };
    handleFormSubmit(args);
  };

  // handles the closing of the form and ensures that we reset the form.
  const handleClose = (value) => {
    setOpen(value);

    // clear the form
    setFormData({
      name: "",
      simulation: "",
      securityTool: "",
    });
  };

  useEffect(() => {
    // Args use in getSimulations()
    const simArgs = {
      accessToken,
      current_customer,
      setSimulations,
    };

    // Args for getSecurityTools()
    const toolArgs = {
      accessToken,
      setSecurityTools,
      current_customer
    };

    getSimulations(simArgs);
    getSecurityTools(toolArgs);
  }, [accessToken, current_customer]);

  return (
    <>
      <Stack alignItems="flex-end">
        <TextButton
          tooltip="Create Expected Alert"
          onClick={handleClick}
          icon={FaPlus}
        />
      </Stack>
      <FormModal open={open} setOpen={handleClose} sx={{ width: 800 }}>
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <FormRowWithDetails
              type="input"
              label="Name"
              formData={formData}
              setFormData={setFormData}
              formKey="name"
              description="The name you would like your expected alert to be called."
            />
            <FormRowWithDetails
              type="select"
              label="Simulation"
              formData={formData}
              setFormData={setFormData}
              formKey="simulation"
              description="Select one of the available simulations."
              options={simulations}
            />
            <FormRowWithDetails
              type="select"
              label="Security tool"
              formData={formData}
              setFormData={setFormData}
              formKey="securityTool"
              description="Select one of the available security tools."
              options={securityTools}
            />
            <Button type="submit" variant="contained">
              Create
            </Button>
          </Stack>
        </form>
      </FormModal>
    </>
  );
};

export default Form;
