import React from "react";
import {
  Card,
  CardHeader,
  CardActions,
  Box,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import { COLORS } from "../../../../Styles/colors";
import { useAppSelector } from "../../../../Redux/app/hooks";

export const RecommendationCard = ({ rec, deletefn, editfn }) => {
  const darkmode = useAppSelector((state) => state.darkmode);

  return (
    <Card
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
      }}
    >
      <CardHeader
        sx={{
          background: darkmode
            ? "linear-gradient(to right, rgb(75, 85, 99), rgb(17, 24, 39))"
            : "linear-gradient(to right, #e0eafc, #cfdef3)",
        }}
        title={
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: -1,
            }}
          >
            <Typography variant="h6">{rec.name}</Typography>
            <Box>
              <IconButton onClick={(e) => editfn()}>
                <FaPencilAlt />
              </IconButton>
              <IconButton
                onClick={(e) => deletefn(rec.uuid)}
                disabled={rec.universal}
              >
                {!rec.universal ? (
                  <FaTrashAlt color={COLORS.error.main} />
                ) : (
                  <FaTrashAlt />
                )}
              </IconButton>
            </Box>
          </Box>
        }
        subheader={<>{rec.score[0].toUpperCase() + rec.score.substring(1)}</>}
      />
      <CardActions sx={{ marginTop: "auto" }}>
        <Stack>
          <Typography>{rec.description}</Typography>
        </Stack>
      </CardActions>
    </Card>
  );
};
