import React from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import TextLink from "../../../Components/TextLink/TextLink";

const ProccessInjection = () => {
  // ----- Context -----

  // ----- States -----

  // ----- Variable -----

  // ----- Functions -----

  // ----- On page load -----

  return (
    <Stack spacing={3}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <TextLink url="/support/help">Support</TextLink>
        <Typography color="text.primary">Proccess Injection</Typography>
      </Breadcrumbs>

      {/* Page Title */}
      <Typography variant="h3">
        Creating a Campaign - Process Injection
      </Typography>
      <div>
        <ol>
          <li>
            <div>
              Login to the BlindSPOT platform, using your given credentials.
            </div>
          </li>
          <li>
            <div>
              Generate and start running a BlindSPOT agent on your system. See
              the{" "}
              <TextLink display="inline" url="/support/Agent">
                Generating an Agent
              </TextLink>{" "}
              tutorial
            </div>
          </li>
          <li>
            <div>
              <div>
                Click the{" "}
                <TextLink display="inline" url="/simulations/list">
                  Simulation Library
                </TextLink>{" "}
                option in the left-hand menu options sidebar.
              </div>
              <img
                src="../../../static/img/help/SimLibraryRedo.png"
                alt="Simulation Library menu screenshot"
              />
            </div>
            <br />
          </li>
          <li>
            <div>
              <div>
                Once on the{" "}
                <TextLink display="inline" url="/simulations/list">
                  Simulations page
                </TextLink>
                , you will notice that there are a nubmer of options you have
                available to you. Start by clicking on the simulation you'd like
                to run.
              </div>
              <img
                src="../../../static/img/help/simulationExample.png"
                alt="Simulations Example"
              />
            </div>
            <br />
          </li>
          <li>
            <div>
              <div>
                Your screen schould look like this, but with your{" "}
                <b>chosen simulation name at the top</b>. In this case, we are
                using the Conti simulation.
              </div>
              <img
                src="../../../static/img/help/ContiExample.png"
                alt="Conti simulation screenshot"
              />
            </div>
            <br />
          </li>
          <li>
            <div>
              <div>
                Scroll down the page until the <b>'Payload Details'</b> section
                is visible. There will be a{" "}
                <b>'Microsoft Windows 64-bit - Process Injection'</b> and a{" "}
                <b>'Microsoft Windows 32-bit - Process Injection'</b>.
              </div>
              <img
                src="../../../static/img/help/ProcessInjectionExample.png"
                alt="Process Injection example"
              />
              <div>Select which one you would like to work with.</div>
            </div>
            <br />
          </li>
          <li>
            <div>
              <div>
                To start your new campaign, hit the blue <b>'New Campaign'</b>{" "}
                button, found adjacent to the <b>'Process Injection'</b> title.
              </div>
              <img
                src="../../../static/img/help/ProcessInjectionCreateCampaign.png"
                alt="Process injection create campaign"
              />
            </div>
            <br />
          </li>
          <li>
            <div>
              <div>
                The <b>Payload</b> and <b>Execution option</b> should be
                atuo-populated with the correct data. However, you will need to
                manually fill out the campaign a <b>Name</b>, and the process
                injection specific options: <b>Process injection Method</b> and{" "}
                <b>Pi target</b>.
              </div>
              <img
                src="../../../static/img/help/ProcessInjectionForm.png"
                alt="Process injection create campaign form"
              />
            </div>
            <div>
              The "method" is the type of process injection the campaign will
              use and the "target" is the process the campaign will start and
              inject into. Finally, select the <b>Agent</b> machine name to
              execute the campaign on then click the blue <b>Submit</b> button.
            </div>
            <br />
          </li>
          <li>
            <div>
              <div>
                You will also need to select which agent you want the campaign
                to run on.
              </div>
              <img
                src="../../../static/img/help/agentselection.png"
                alt="Agent Selection"
              />
            </div>
            <br />
          </li>
          <li>
            <div>
              <div>
                Now just push the <b>submit</b> button.
              </div>
              <img
                src="../../../static/img/help/submitbutton.png"
                alt="Submit button"
              />
            </div>
            <br />
          </li>
          <li>
            <div>
              <div>
                The campaign will be downloaded and run as the BlindSPOT agent
                checks in with the BlindSPOT service.
              </div>
              <img
                src="../../../static/img/help/ProccessInjectionRunning.png"
                alt="Running Proccess Injection campaign"
              />
            </div>
            <div>
              **Opening task manager or process hacker, the new
              'SecurityHealthSystray.exe' can be observed running on the
              system.**
            </div>
            <img
              src="../../../static/img/help/ProccessInjectionTask.png"
              alt="Proccess Injection task"
            />
            <br />
          </li>
          <br />
          <li>
            <div>
              <div>
                As the BlindSPOT agent executes the campaign, the BlindSPOT
                service will mark the campaign as <b>in progress</b>. The agent
                will track the running injection campaign and will alert the
                BlindSPOT service when execution has{" "}
                <b>finished, then scoring can begin</b>.
              </div>
              <img
                src="../../../static/img/help/campaigninprogress.png"
                alt="campaign in progress"
              />
            </div>
            <br />
          </li>
        </ol>
      </div>
    </Stack>
  );
};

export default ProccessInjection;
