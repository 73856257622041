import { useEffect, useState, useContext } from "react";
import { TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { getRequest, postRequest } from "../../../Helpers/httpRequests";
import { DataContext } from "../../../Context/dataContext";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../Redux/app/hooks";

const AdminServerSettings = () => {
  // ----- Page Title -----
  document.title = "Server Settings";
  // ----- Context -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector((state) => state.customer);

  // ----- States -----
  const [formData, setFormData] = useState({
    uuid: "",
    baseurl: "",
    aws_ses_access_key_id: "",
    aws_ses_access_key_secret: "",
    agent_gen_url: "",
    payload_gen_url: "",
    unified_builder_url: "",
    latest_agent_version: "",
    certs_file: "",
    certs_password: "",
    jira_username: "",
    jira_api_key: "",
  });


  // ----- Variable -----

  // ----- Functions -----
  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await postRequest(
      "/react/api/admin/server-settings",
      accessToken,
      formData,
    );

    if (res.status === 201) {
      toast.success("Saved!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setFormData(res.data);
    } else {
      toast.error("Failed to Save :(", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // ----- On page load -----

  useEffect(() => {
    const getCurrentSettings = async () => {
      const res = await getRequest(
        `/react/api/admin/server-settings`,
        accessToken,
      );

      if (res.status === 200) {
        setFormData(res.data);
      }
    };

    getCurrentSettings();
  }, [accessToken, current_customer]);

  return (
    <Stack spacing={3}>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2}>
          {/* BASEURL */}
          <TextField
            placeholder="Base URL"
            label="Base URL"
            type="text"
            value={formData.baseurl || ""}
            onChange={(e) =>
              setFormData((data) => ({ ...data, baseurl: e.target.value }))
            }
          />
          {/* Awa Ses Acess Key ID */}
          {/* <TextField
              placeholder="Aws Ses Access Key ID"
              label="Aws Ses Access Key ID"
              type="text"
              value={formData.aws_ses_access_key_id || ""}
              onChange={(e) =>
                setFormData((data) => ({
                  ...data,
                  aws_ses_access_key_id: e.target.value,
                }))
              }
              hidden
            /> */}

          {/* Aws Ses Access Key Secret */}
          {/* <TextField
              placeholder="Aws Ses Access Key Secret"
              label="Aws Ses Access Key Secret"
              type="text"
              value={formData.aws_ses_access_key_secret || ""}
              onChange={(e) =>
                setFormData((data) => ({ ...data, aws_ses_access_key_secret: e.target.value }))
              }
              hidden
            /> */}

          {/* Agent Gen URL */}
          {/* <TextField
              placeholder="Agent Gen URL"
              label="Agent Gen URL"
              type="text"
              value={formData.agent_gen_url || ""}
              onChange={(e) =>
                setFormData((data) => ({ ...data, agent_gen_url: e.target.value }))
              }
            /> */}

          {/* Payload Gen URL */}
          {/* <TextField
              placeholder="Payload Gen URL"
              label="Payload Gen URL"
              type="text"
              value={formData.payload_gen_url || ""}
              onChange={(e) =>
                setFormData((data) => ({ ...data, payload_gen_url: e.target.value }))
              }
            />  */}

          {/* Unified Builder URL */}
          <TextField
            placeholder="Unified Builder URL"
            label="Unified Builder URL"
            type="text"
            value={formData.unified_builder_url || ""}
            onChange={(e) =>
              setFormData((data) => ({
                ...data,
                unified_builder_url: e.target.value,
              }))
            }
          />

          {/* Agent Version */}
          <TextField
            placeholder="Latest Agent Version"
            label="Latest Agent Version"
            type="text"
            value={formData.latest_agent_version || ""}
            onChange={(e) =>
              setFormData((data) => ({
                ...data,
                latest_agent_version: e.target.value,
              }))
            }
          />

          {/* Jira username */}
          <TextField
            placeholder="Jira Username"
            label="Jira Username"
            type="text"
            value={formData.jira_username || ""}
            onChange={(e) =>
              setFormData((data) => ({
                ...data,
                jira_username: e.target.value,
              }))
            }
          />

          {/* Jira password */}
          <TextField
            placeholder="Jira Api Key"
            label="Jira Api Key"
            type="password"
            value={formData.jira_api_key || ""}
            onChange={(e) =>
              setFormData((data) => ({
                ...data,
                jira_api_key: e.target.value,
              }))
            }
          />

          <LoadingButton variant="contained" type="submit">
            Save
          </LoadingButton>
        </Stack>
      </form>
    </Stack>
  );
};

export default AdminServerSettings;
