import React, { useEffect, useState, useContext } from "react";
import {
  Breadcrumbs,
  Typography,
  Paper,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { Stack } from "@mui/system";
import { getRequest } from "../../../Helpers/httpRequests";
import { DataContext } from "../../../Context/dataContext";
import { COLORS } from "../../../Styles/colors";
import moment from "moment";
import { useAppSelector } from "../../../Redux/app/hooks";

const ReleaseNotes = () => {
  // ----- Context -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  // ----- States -----
  const [releaseNotes, setReleaseNotes] = useState([]);

  // ----- Variable -----
  let BASE_URL;
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "PROD":
      BASE_URL = process.env.REACT_APP_PROD_BASEURL;
      break;
    case "DEV":
      BASE_URL = process.env.REACT_APP_DEV_BASEURL;
      break;
    case "LOCAL":
      BASE_URL = process.env.REACT_APP_DEV_BASEURL;
      break;
    default:
      BASE_URL = "";
  }

  // ----- Functions -----
  const timeDiff = (time1, time2) => {
    const years = parseInt(time2.diff(time1, "years"));
    const months = parseInt(time2.diff(time1, "months"));
    const days = parseInt(time2.diff(time1, "days"));
    if (years > 0) {
      return time2.diff(time1, "years") + " years ago";
    } else if (months > 0) {
      return time2.diff(time1, "months") + " months ago";
    } else {
      if (days === 0) return "Today";
      else return time2.diff(time1, "days") + " days ago";
    }
  };

  // ----- On page load -----

  useEffect(() => {
    const getReleaseNotes = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/support/release-notes`,
        accessToken
      );

      if (res.status === 200) {
        setReleaseNotes(res.data.notes);
      }
    };

    getReleaseNotes();
  }, [accessToken, current_customer]);

  return (
    <Stack spacing={3}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Support</Typography>
        <Typography color="text.primary">Release Notes</Typography>
      </Breadcrumbs>

      {/* Page Title */}
      <Typography variant="h3">Release Notes</Typography>
      {releaseNotes.length > 0 &&
        releaseNotes.map((note) => {
          return (
            <Paper
              key={`release-${note.date}`}
              sx={{ p: 3, backgroundColor: "rgba(0, 0, 100, 0.05)" }}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                color={COLORS.primary.main}
                sx={{ mb: 1 }}
              >
                {`${moment(note.date).format("MMMM Do YYYY")} (${timeDiff(
                  moment(note.date),
                  moment()
                )})`}
              </Typography>

              <Stack direction="row" spacing={2}>
                {note.features.length > 0 &&
                  note.features.map((feature, index) => {
                    return (
                      <Card
                        key={note.date + feature.name}
                        sx={{ maxWidth: 345 }}
                      >
                        <CardMedia
                          component="img"
                          height="140"
                          image={BASE_URL + feature.image}
                          alt={`feature ${index + 1}`}
                        />
                        <CardContent>
                          <Typography
                            gutterBottom
                            variant="body1"
                            fontWeight="bold"
                            component="div"
                          >
                            {feature.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            color={COLORS.secondary.dark}
                          >
                            {feature.description}
                          </Typography>
                        </CardContent>
                      </Card>
                    );
                  })}
              </Stack>
              <ul>
                {note.fixes.length > 0 &&
                  // This needs to be filtered because some fixes come acress as an empty string.
                  note.fixes
                    .filter((fix) => fix.length > 0)
                    .map((filteredFix) => (
                      <li key={filteredFix}>{filteredFix}</li>
                    ))}
              </ul>
            </Paper>
          );
        })}
    </Stack>
  );
};

export default ReleaseNotes;
