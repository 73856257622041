import React, {useEffect, useState} from "react";
import {Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,} from "recharts";
import {Box, FormControl, MenuItem, Select, Typography, Stack} from "@mui/material";
import {COLORS} from "../../../Styles/colors";
import {useHttpRequest} from "../../../Hooks";
import CircularProgress from "@mui/material/CircularProgress";

const CategoryLine = (props) => {
  const [data, setData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("All");

  const {
    response,
    loading,
  } = useHttpRequest({
    method: "GET",
    path: "/api/v2/simulations/trends/bycategory?",
    params: {
      depth: 1,
    },
  });

  useEffect(() => {
    if (response) {
      setData(response);
    }
  }, [response, setData]);

  if (loading || !data) {
    return <CircularProgress/>;
  }

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const selectedCategoryData = data.find(
    (category) => category.category === selectedCategory
  );

  return (
    <>
      <FormControl sx={{m: 1, minWidth: 120}} size="small">
        <Stack direction="row" spacing={2}>
        <Select
          value={selectedCategory}
          onChange={handleCategoryChange}
          fullWidth
        >
          <MenuItem value="All">All</MenuItem>
          {data.map((category) => (
            <MenuItem key={category.category} value={category.category}>
              {category.category}
            </MenuItem>
          ))}
        </Select>
        {selectedCategoryData && (
          <Typography variant="body1">
            Average Score for {selectedCategoryData.category}: {selectedCategoryData.average_score} %
          </Typography>
        )}
        </Stack>
      </FormControl>
      {data.map((category) =>
        category.category === selectedCategory || selectedCategory === "All"
          ? category.simulations.map((simulation, index) => {
            return (
              <Box key={index}>
                <Stack direction="row" spacing={2}>
                  <Typography variant="h6">{simulation.simulation}</Typography>
                  <Typography variant="subtitle1"> Average Score: {simulation.average_score}%</Typography>
                </Stack>
                <ResponsiveContainer width="100%" height={400}>
                  <AreaChart
                    width={500}
                    height={300}
                    data={simulation.campaigns}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="start"/>
                    <YAxis domain={[0, 100]} ticks={[0, 25, 50, 75, 100]}/>
                    <Tooltip/>
                    <Legend/>
                    <Area
                      type="monotone"
                      dataKey="score"
                      stroke={COLORS.blue}
                      activeDot={{r: 8}}
                      fill={COLORS.blue}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </Box>
            );
          })
          : null
      )}
    </>
  );
};

export default CategoryLine;
