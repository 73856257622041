import {
  Breadcrumbs,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { FaEllipsisV, FaFileCsv, FaFileWord } from "react-icons/fa";
import { toast } from "react-toastify";
import { DataContext } from "../../../../Context/dataContext";
import { fileRequest } from "../../../../Helpers/httpRequests";
import { useAppSelector } from "../../../../Redux/app/hooks";
import { AvOverviewContext } from "../Provider/Context";

const BSBreadcrumbs = ({ downloadWord }) => {
  const role = useAppSelector((state) => state.user.role);
  const current_customer = useAppSelector((state) => state.customer);
  const { startDate, endDate } = useContext(AvOverviewContext);
  const { accessToken } = useContext(DataContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [downloading, setDownloading] = useState(false);

  // Handle opening the menu
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle closing the menu
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDownloadCSV = async () => {
    setDownloading(true);
    const loadingtoast = toast.loading("Generating AV CSV");
    const res = await fileRequest(
      `/react/api/${current_customer.uuid}/alertvalidation/overview/get-csv`,
      accessToken,
      "Overview.csv",
      {
        startDate,
        endDate,
      }
    );
    setDownloading(false);
    toast.dismiss(loadingtoast);
    if (res.status === 200) {
      toast.success("CSV generated successfully");
    } else {
      toast.error("Something went wrong");
    }
    handleCloseMenu();
  };

  const handleDownloadWord = () => {
    setDownloading(true);
    setTimeout(async () => {
      await downloadWord();
      setDownloading(false);
    }, 0);
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="text.primary">Alert Validation</Typography>
          <Typography color="text.primary">Overview</Typography>
        </Breadcrumbs>

        {/* Download button that triggers the dropdown */}
        <IconButton
          role={role}
          aria-controls="download-menu"
          aria-haspopup="true"
          onClick={handleOpenMenu}
          disabled={downloading} // Disable if downloading
        >
          <FaEllipsisV />
        </IconButton>

        {/* Dropdown menu for choosing format */}
        <Menu
          id="download-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem
            disabled={downloading} // Disable when downloading
            onClick={handleDownloadCSV}
          >
            <ListItemIcon>
              <FaFileCsv />
            </ListItemIcon>
            Download CSV
          </MenuItem>
          <MenuItem
            disabled={downloading} // Disable when downloading
            onClick={handleDownloadWord}
          >
            <ListItemIcon>
              <FaFileWord />
            </ListItemIcon>
            Download Word Doc
          </MenuItem>
        </Menu>
      </Stack>
    </>
  );
};

export default BSBreadcrumbs;
