import { useEffect, useState, useContext } from "react";
import {
  Breadcrumbs,
  Box,
  Typography,
  Button,
  Modal,
  Tabs,
  Tab,
  Grid,
} from "@mui/material";
import { Stack } from "@mui/system";
import { deleteRequest, getRequest } from "../../../Helpers/httpRequests";
import TextButton from "../../../Components/Buttons/TextButton";
import { FaPlus } from "react-icons/fa";
import { DataContext } from "../../../Context/dataContext";
import RecommendationForm from "../../../Components/Forms/Reporting/RecommendationForm";
import FormModal from "../../../Components/Modals/FormModal";
import { toast } from "react-toastify";
import { RecommendationCard } from "./subcomponents/RecommendationCard";
import { useAppSelector } from "../../../Redux/app/hooks";

const Recommendations = () => {
  // ----- CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector((state) => state.customer);
  const role = useAppSelector((state) => state.user.role);

  // ----- STATES -----
  const [recommendations, setRecommendations] = useState([]);
  const [selectedRecs, setSelectedRecs] = useState("All");
  const [modalInfo, setModalInfo] = useState({
    open: false,
    rec: null,
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteUUID, setDeleteUUID] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [deleteName, setDeleteName] = useState("");

  const sortRecs = (a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) return -1;
    else if (nameA > nameB) return 1;
    else return 0;
  };

  const filteredRecs =
    selectedRecs === "All"
      ? recommendations.sort(sortRecs)
      : recommendations
          .filter((rec) => rec.score === selectedRecs)
          .sort(sortRecs);

  // ----- Functions -----
  const deleteRecommendation = async () => {
    toast.promise(
      deleteRequest(
        `/react/api/${current_customer.uuid}/report/recommendation/delete?rec_uuid=${deleteUUID}`,
        accessToken,
      ),
      {
        pending: "Deleting Recommendation",
        success: `Recommendation Deleted`,
        error: "Something went wrong!",
      },
    );
    const temp = recommendations;
    const recIdx = recommendations.findIndex((rec) => rec.uuid === deleteUUID);
    temp.splice(recIdx, 1);
    setDeleteModal(false);
    setRecommendations(temp);
  };

  const handleScoreChange = (e, value) => {
    setSelectedRecs(value);
  };

  // ----- On page load -----

  useEffect(() => {
    const getData = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/report/recommendations`,
        accessToken,
      );

      if (res.status === 200) {
        setRecommendations(res.data.recommendations);
      } else {
        console.log(
          "%cerror Recommendations.jsx useEffect()",
          "color: red; display: block; width: 100%;",
          "Reponse status not 200",
        );
      }
    };

    getData();
  }, [accessToken, current_customer, refresh]);

  return (
    <Stack spacing={3}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Reports</Typography>
        <Typography color="text.primary">Recommendations</Typography>
      </Breadcrumbs>

      {/* Page Title */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Typography variant="h3" sx={{ marginTop: "auto" }}>
          Recommendations
        </Typography>
        <TextButton
          role={role}
          tooltip="New Recommendation"
          href="/"
          icon={FaPlus}
          onClick={() => setModalInfo({ open: true })}
        />
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <Tabs
          value={selectedRecs}
          onChange={handleScoreChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab value="All" label="All" />
          <Tab value="critical" label="Critical" />
          <Tab value="high" label="High" />
          <Tab value="medium" label="Medium" />
          <Tab value="low" label="Low" />
        </Tabs>
        <Grid container spacing={3} columns={18}>
          {filteredRecs.map((rec, idx) => {
            return (
              <Grid
                key={idx}
                item
                sx={{ display: "flex" }}
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={6}
              >
                <RecommendationCard
                  rec={rec}
                  deletefn={(uuid) => {
                    setDeleteUUID(uuid);
                    setDeleteName(rec.name);
                    setDeleteModal(true);
                  }}
                  editfn={() => {
                    setModalInfo({
                      open: true,
                      rec: rec,
                    });
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Modal
        open={modalInfo ? modalInfo.open : false}
        onClose={() => {
          setModalInfo({
            open: false,
            rec: null,
          });
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <RecommendationForm
            recommendation={modalInfo.rec}
            setOpen={(rec) => {
              setModalInfo({
                open: false,
                rec: null,
              });
              const temp = recommendations;
              const rec_idx = temp.findIndex((r) => r.uuid === rec.uuid);
              if (rec_idx !== -1) {
                temp.splice(rec_idx, 1);
              }
              temp.push({ ...rec, id: rec.uuid });
              setRecommendations(temp);
              setRefresh(!refresh);
            }}
          />
        </div>
      </Modal>
      {/* Delete Modal */}
      <FormModal open={deleteModal} setOpen={setDeleteModal}>
        <Box component="form">
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: 4 }}
          >
            Are you sure you want to remove Recommendation {deleteName} ?
          </Typography>
          <Stack direction="column" spacing={2}>
            <Button onClick={() => deleteRecommendation()} variant="contained">
              Yes
            </Button>
          </Stack>
        </Box>
      </FormModal>
    </Stack>
  );
};

export default Recommendations;
