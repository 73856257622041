import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  AdministrationRoutes,
  AlertValidationRoutes,
  BetaRoutes,
  ConfigRoutes,
  ReportingRoutes,
  ServiceProviderRoutes,
  SimulationRoutes,
  SupportRoutes,
} from ".";
import { useAppSelector } from "../Redux/app/hooks";

const CustomerRoutes = () => {
  const user = useAppSelector((state) => state.user);
  const current_customer = useAppSelector((state) => state.customer);

  return (
    <Routes>
      {/* Simulations */}
      <Route path="/simulations/*" element={<SimulationRoutes />} />

      {/* Reports */}
      <Route path="/reporting/*" element={<ReportingRoutes />} />

      {/* Configurations */}
      <Route path="/config/*" element={<ConfigRoutes />} />

      {/* Service Provider */}
      <Route path="/serviceprovider/*" element={<ServiceProviderRoutes />} />

      {/* Alert Validation */}
      {current_customer.features.av && (
        <Route path="/alertvalidation/*" element={<AlertValidationRoutes />} />
      )}

      {/* Support */}
      <Route path="/support/*" element={<SupportRoutes />} />

      {/* Beta */}
      <Route path="/beta/*" element={<BetaRoutes />} />

      {/* Administration (SuperUser needed) */}
      {user.superuser && (
        <Route path="/administration/*" element={<AdministrationRoutes />} />
      )}
    </Routes>
  );
};

export default CustomerRoutes;
