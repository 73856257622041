import { Stack, Chip } from "@mui/material";
import {
  FaNetworkWired,
  FaTh,
  FaCss3,
  FaGoogle,
  FaAmazon,
  FaMicrosoft,
  FaFileWord,
  FaWindows,
  FaApple,
  FaLinux,
} from "react-icons/fa";
import { Link } from "react-router-dom";

// Variables

export const dataTableStyles = {
  "&.MuiDataGrid-root .MuiDataGrid-cell:focus": { outline: "none" },
  ".even": { backgroundColor: "#F3F7F9" },
};

export const modalStyle = {
  position: "absolute",
  bgcolor: "background.paper",
  top: "50%",
  left: "50%",
  width: "100%",
  height: "60%",
  maxWidth: 1000,
  transform: "translate(-25%, -50%)",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export const reRunStyle = {
  position: "absolute",
  bgcolor: "background.paper",
  top: "50%",
  left: "50%",
  width: "70%",
  height: "100%",
  maxWidth: 600,
  transform: "translate(-25%, -50%)",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export const timelineStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  height: "100%",
  minWidth: 1000,
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export const techOutcomesColumns = [
  { field: "name", headerName: "Name", minWidth: 100, flex: 8 },
  { field: "blocked", headerName: "Blocked", minWidth: 100, flex: 1 },
  { field: "alerted", headerName: "Alerted", minWidth: 100, flex: 1 },
  { field: "logged", headerName: "Logged", minWidth: 100, flex: 1 },
  { field: "no_evidence", headerName: "No Evidence", minWidth: 100, flex: 1 },
  { field: "not_scored", headerName: "Not Scored", minWidth: 100, flex: 1 },
];

export const techniquesColumns = (navigate, current_customer) => {
  return [
    {
      field: "name",
      headerName: "Name",
      minWidth: 270,
      flex: 1,
      renderCell: (params) => {
        return (
          <Link
            to={`/${current_customer.uuid}/reporting/technique/${params.row.attack_id}`}
          >
            {params.row.name}
          </Link>
        );
      },
    },
    { field: "attack_id", headerName: "Attack ID", minWidth: 100, flex: 1 },
    {
      field: "tactics",
      headerName: "Tactics",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        const tactics = params.row.tactics;
        return (
          <Stack direction="row" spacing={1}>
            {tactics?.length > 0 &&
              tactics.map((tactic, index) => {
                return (
                  <Chip
                    key={`${tactic}${index}`}
                    label={tactic.name}
                    size="small"
                  />
                );
              })}
          </Stack>
        );
      },
    },
    {
      field: "platforms",
      headerName: "Platforms",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        const platforms = params.row.platforms;
        return (
          <Stack direction="row" spacing={1}>
            {platforms?.length > 0 &&
              platforms.map((platform) => {
                return (
                  <div key={platform.uuid}>
                    {platform.name === "Network" && (
                      <FaNetworkWired key={platform.uuid} />
                    )}
                    {platform.name === "PRE" && <FaTh key={platform.uuid} />}
                    {platform.name === "SaaS" && <FaCss3 key={platform.uuid} />}
                    {platform.name === "Azure" && (
                      <FaMicrosoft key={platform.uuid} />
                    )}
                    {platform.name === "GCP" && (
                      <FaGoogle key={platform.uuid} />
                    )}
                    {platform.name === "AWS" && (
                      <FaAmazon key={platform.uuid} />
                    )}
                    {platform.name === "Azure AD" && (
                      <FaMicrosoft key={platform.uuid} />
                    )}
                    {platform.name === "Office 365" && (
                      <FaFileWord key={platform.uuid} />
                    )}
                    {platform.name === "Windows" && (
                      <FaWindows key={platform.uuid} />
                    )}
                    {platform.name === "MacOS" && (
                      <FaApple key={platform.uuid} />
                    )}
                    {platform.name === "macOS" && (
                      <FaApple key={platform.uuid} />
                    )}
                    {platform.name === "Linux" && (
                      <FaLinux key={platform.uuid} />
                    )}
                  </div>
                );
              })}
          </Stack>
        );
      },
    },
  ];
};
