import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { useHttpRequest } from "../../Hooks";
import { useDispatch } from "react-redux";
import { selectCustomer } from "../../Redux/features/customer/customerSlice";
import { useAppSelector } from "../../Redux/app/hooks";

const CustomerSelect = () => {
  const user = useAppSelector((state) => state.user);
  const customer = useAppSelector((state) => state.customer);
  const dispatch = useDispatch();

  const { response: customers, status: customersHTTPStatus } = useHttpRequest({
    method: "GET",
    path: "/api/v2/customers",
    params: {
      depth: 1,
    },
  });

  const updateCustomer = async (uuid) => {
    const customer = customers.find((cx) => cx.uuid === uuid);
    await dispatch(selectCustomer(customer));
    window.location.href = `/${customer.uuid}/simulations/campaigns/list`;
  }

  const handleChange = async (event) => {
    updateCustomer(event.target.value);
  };

  return (
    customersHTTPStatus === 200 && (
      <div>
        {user.current_customer && user.customer_access.length !== 0 && (
          <FormControl sx={{ minWidth: "10rem" }} size="small">
            <InputLabel id="customer-select-label">Customer</InputLabel>
            <Select
              labelId="customer-select-label"
              id="customer-select"
              value={customer.uuid}
              label="Customer"
              onChange={handleChange}
              sx={{ marginRight: 1 }}
              MenuProps={{
                style: {
                  maxHeight: 400,
                },
              }}
            >
              {customers.length > 0 ? (
                customers.map((customer) => (
                  <MenuItem key={customer.uuid} value={customer.uuid}>
                    {customer.display_name}
                  </MenuItem>
                ))
              ) : (
                <div></div>
              )}
            </Select>
          </FormControl>
        )}
      </div>
    )
  );
};

export default CustomerSelect;
