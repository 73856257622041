import React from "react";
import { Typography, Paper, TextField, Button } from "@mui/material";
import { Stack } from "@mui/system";

const Feature = React.memo( function Feature ({setFeatures, index}) {

  const [fileName, setFileName] = React.useState("")

  // ----- Functions ------
  const handleNameChange = (e) => {
    setFeatures((prev) => {
      let newArray = Array.from(prev)
      newArray[index].name = e.target.value
      return newArray
    })
  }
  
  const handleDescChange = (e) => {
    setFeatures((prev) => {
      let newArray = Array.from(prev)
      newArray[index].description = e.target.value
      return newArray
    })
  }

  const handleUploadInput = (e) => {
    setFeatures((prev) => {
      let newArray = Array.from(prev)
      newArray[index].file = e.target.files[0]
      return newArray
    })
    setFileName(e.target.files[0].name)
  }
  return (
    <Paper sx={{ p: 3, width: "100%" }}>
      <Stack spacing={2}>
        <Typography variant="h5">Feature {index + 1}</Typography>

        {/* Feature Name */}
        <TextField
          placeholder="Feature Name"
          label="Feature Name"
          type="text"
          required
          onChange={handleNameChange}
        />

        {/* Description */}
        <TextField
          placeholder="Description"
          label="Description"
          type="text"
          required
          multiline
          minRows={4}
          maxRows={8}
          onChange={handleDescChange}
        />

        {/* File upload */}
        <TextField
          value={fileName}
          label="Upload File"
          required
          InputProps={{
            fullWidth: true,
            readOnly: true,
            startAdornment: (
              <Button
                variant="outlined"
                size="small"
                fullWidth
                component="label"
                sx={{ mr: 1 }}
              >
                Choose file
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={handleUploadInput}
                />
              </Button>
            ),
          }}
        />
      </Stack>
    </Paper>
  );
});

export default Feature;
