import React, { useState } from "react";
import { Box, Stack, Paper, CircularProgress } from "@mui/material";
import CalendarWithStyles from "../../../../Components/Calendar/CalendarWithStyles";
import { scoreAlerts } from "../Utils/functions";
import SelectedAlerts from "./SelectedAlerts";
import { useContext } from "react";
import { AvOverviewContext } from "../Provider/Context";

const Calendar = () => {
  // states
  const [selectedAlerts, setSelectedAlerts] = useState([]);
  const { data: response } = useContext(AvOverviewContext);

  /**
   * If we haven't gotten a response back from the backend then we need to show a loading circle,
   * Calling <CalendarWithStyles/> without passing a `data` prop will do this for us automatically.
   */
  if (response.status === null) {
    const styles = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: 200,
    };
    return (
      <Box sx={styles}>
        <CircularProgress />
      </Box>
    );
  }

  /**
   * Show a empty calendar if there haven't been any AV's ran.
   */
  if (response.status !== null && response.data.exec_campaigns.length === 0) {
    return <CalendarWithStyles loadEmpty />;
  }

  const calendarData = scoreAlerts(response.data.exec_campaigns);
  /**
   * Handles the onClick event that happens when a user clicks a date on the calendar.
   * @param {Object} day
   */
  const onDayClick = (day) => {
    setSelectedAlerts(day.data.campaigns || []);
  };

  return (
    <Stack spacing={2}>
      <Paper>
        <CalendarWithStyles data={calendarData} onClick={onDayClick} />
        <SelectedAlerts alerts={selectedAlerts} />
      </Paper>
    </Stack>
  );
};

export default Calendar;
