import { Alert, Box, CircularProgress, Grid, MenuItem, Select, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { SimLibraryContext } from "../../provider/context";
import SimCard from "../SimCard";

export default function CategoryTab(props) {
  const { value, index, ...other } = props;
  const { favorites, simulations, categories, searchValue, loading } =
    useContext(SimLibraryContext);

  const [sortedSimulations, setSortedSimulations] = useState([]);
  const [sortOption, setSortOption] = useState("aToZ");

  // Display loading circle until simulations is populated from backend
  if (simulations.length === 0 && loading) {
    return (
      <Box className="w-full h-full flex justify-center items-center">
        <CircularProgress />
      </Box>
    );
  }

  // This should never trigger but here to prevent infinite loading.
  if (simulations.length === 0 && !loading) {
    return (
      <Box className="w-full h-full flex justify-center items-center">
        <Typography variant="h4">No simulations found.</Typography>
      </Box>
    );
  }

  // Function to sort simulations based on selected option
  const sortSimulations = (option) => {
    const sorted = [...simulations].sort((a, b) => {
      switch (option) {
        case "aToZ": {
          return a.name.localeCompare(b.name);
        }
        case "zToA": {
          return b.name.localeCompare(a.name);
        }
        case "newToOld": {
          const dateA = a.published_date || a.created_date;
          const dateB = b.published_date || b.created_date;
          return new Date(dateB) - new Date(dateA);
        }
        case "oldToNew": {
          const dateA = a.published_date || a.created_date;
          const dateB = b.published_date || b.created_date;
          return new Date(dateA) - new Date(dateB);
        }
        default: {
          return 0;
        }
      }
    });
    setSortedSimulations(sorted);
  };

  // Handle sort option change
  const handleSortChange = (event) => {
    const option = event.target.value;
    setSortOption(option);
    sortSimulations(option);
  };

  const displaySimulations = sortedSimulations.length > 0 ? sortedSimulations : simulations;

  return (
    <>
      <Box sx={{ marginBottom: 2 }}>
        <Select value={sortOption} onChange={handleSortChange}>
          <MenuItem value="newToOld">New to Old</MenuItem>
          <MenuItem value="oldToNew">Old to New</MenuItem>
          <MenuItem value="aToZ">A-Z</MenuItem>
          <MenuItem value="zToA">Z-A</MenuItem>
        </Select>
      </Box>
      {categories.map((category, index) => {
        const sims = displaySimulations
          .filter(
            // Filter the search by name || attack technique
            (sim) =>
              sim.name.toLowerCase().includes(searchValue.toLowerCase()) ||
              sim.techs.some((tech) =>
                tech.toLowerCase().includes(searchValue.toLowerCase())
              )
          )
          .filter(
            // filter by current category
            (sim) =>
              sim.category === category ||
              category === "All" ||
              (category === "Favorites" && favorites.includes(sim.id))
          );

        const name = category.replaceAll(" ", "-");

        return (
          <div
            key={`category-${index}`}
            role="tabpanel"
            hidden={value !== index}
            id={`${name}-tabpanel-${index}`}
            aria-labelledby={`${name}-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={3} columns={24}>
                  <GridItems simulations={sims} />
                </Grid>
              </Box>
            )}
          </div>
        );
      })}
    </>
  );
}

CategoryTab.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number.isRequired,
};

const GridItems = ({ simulations }) => {
  return simulations.length > 0 ? (
    simulations.map((sim) => {
      return (
        <Grid
          key={sim.id}
          item
          sx={{ display: "flex" }}
          xs={24}
          sm={24}
          md={12}
          lg={8}
          xl={6}
        >
          <SimCard sim={sim} title={sim.name} desc={sim.description} />
        </Grid>
      );
    })
  ) : (
    <Grid item xs={12}>
      <Alert severity="info">No simulations available for this category.</Alert>
    </Grid>
  );
};
