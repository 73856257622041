import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import timeline from 'highcharts/modules/timeline';
import React, { useEffect, useState} from "react";

const DownloadTimeline = (props) => {

  // ----- Context -----
  const [timelineData, setTimelineData] = useState([]);
  timeline(Highcharts);

  // ----- Functions -----

  const populateTimeline = async (timelines) => {
    const data = timelines.map((timed) => {
      let name = ""
      switch (timed.name) {
        case "run":
          name = "CLI";
          break;
        case "upsh":
          name = "Powershell";
          break;
        case "downloader":
          name = "File Download";
          break;
        case "uploader":
          name = "File Exfiltration";
          break;
        case "sysinfo":
          name = "System Info - API";
          break;
        case "crypt":
          name = "Encrypting Data";
          break;
        case "arp":
          name = "ARP - API";
          break;
        case "file":
          name = "Creating Sample Data";
          break;
        case "services":
          name = "Services - API";
          break;
        case "search":
          name = "File Search - API";
          break;
        case "processes":
          name = "Processes - API";
          break;
        case "reg":
          name = "Registry Access";
          break;
        case "mimikatz":
          name = "Mimikatz";
          break;
        default:
          name = timed.name;
      }
      return {
        name: name,
        color: timed.color,
        label: timed.request.substring(0,50),
      };
    });
    setTimelineData(data);
  };

  const showtimelineDataChart = () => {
    return {
      chart: {
        type: "timeline",
        inverted: true,
        clip: false,
        plotBackgroundColor: "#e4e5e6",
        scrollablePlotArea: {
          minWidth: 100, // Set a minimum width to enable scrolling
          minHeight: 4000, // Set the desired minimum height for scrolling
          scrollPositionX: 1, // Scroll to the end of the chart by default
          scrollPositionY: 1,
        },
        height: 900,
        spacing: [20, 20, 20, 20], // Add spacing to evenly distribute the chart elements
      },
      title: {
        text: "Timeline of " + props.reportName,
      },
      subtitle: {
        text: `Info source: Blindspot Reporting`,
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        visible: false,
      },
      plotOptions: {
        row: {
          grouping: false,
        },
        series: {
          dataLabels: {
            distance: 200,
            allowOverlap: false,
          },
          marker: {
            radius: 8,
          },
          pointPlacement: function (pointWidth, _, point) {
            return point.index % 2 === 0 ? -pointWidth / 2 : pointWidth / 2;
          }, // Alternate the position of the data points
        },
      },
      series: [
        {
          datalabels: [{}],
          data: timelineData,
          pointWidth: 40, // Adjust the width of the bars
        },
      ],
    };
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    populateTimeline(props.timeline);
  }, [props.timeline]);

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={showtimelineDataChart()} />
    </div>
  );
};

export default DownloadTimeline;
