import { LoadingButton } from "@mui/lab";
import {
  Box,
  Breadcrumbs,
  IconButton,
  Modal,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { FaCheck, FaEdit, FaPlus, FaTimes, FaTrashAlt } from "react-icons/fa";
import TextButton from "../../../Components/Buttons/TextButton";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import AdminAddUserForm from "../../../Components/Forms/Administration/AdminAddUserForm";
import AdminEditUserForm from "../../../Components/Forms/Administration/AdminEditUserForm/AdminEditUserForm";
import FormModal from "../../../Components/Modals/FormModal";
import { DataContext } from "../../../Context/dataContext";
import { getRequest } from "../../../Helpers/httpRequests";
import { COLORS } from "../../../Styles/colors";
import DeleteModal from "./Components/DeleteModal";
import axios from "axios";
import { useAppSelector } from "../../../Redux/app/hooks";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const AdminUsers = () => {
  // ----- STATES && CONTEXT -----

  const [dataTableRows, setDataTableRows] = useState([]);
  const [dataTableColumns, setDataTableColumns] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [dataTableRowsInactive, setDataTableRowsInactive] = useState([]);
  const [dataTableColumnsInactive, setDataTableColumnsInactive] = useState([]);

  // Modal States
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  // Contexts
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  // ----- VARIABLES -----

  // ----- FUNCTIONS -----

  // Closes the modal
  const handleClose = () => {
    setOpen(false);
  };
  /**
   * This function handles the creation of columns and rows for MUI Datagrid. Running this function will could the MUI-Datagrid to re-mount.
   * @param {Array.<{name: String, email: String, currentView: Number, superuser: Boolean, userrole: String}>} users
   */
  const populateDataGridInactive = (users) => {
    const columns = [
      {
        field: "name",
        headerName: "Name",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "currentView",
        headerName: "Current View",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "superuser",
        headerName: "Super User?",
        minWidth: 100,
        flex: 1,
        renderCell: (params) =>
          params.row.superuser ? (
            <FaCheck color={COLORS.success.main} />
          ) : (
            <FaTimes color={COLORS.error.main} />
          ),
      },
      {
        field: "userrole",
        headerName: "User Role",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "passwordreset",
        headerName: "Password Reset",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {
          let options = {
            method: "POST",
            url: process.env.REACT_APP_PASSWORD_RESET_URL,
            headers: { "content-type": "application/json" },
            data: {
              client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
              email: params.row.email,
              connection: "Username-Password-Authentication",
            },
          };

          const onClick = () => {
            axios
              .request(options)
              .then((res) => {
                document.getElementById(
                  `pass-btn-${params.row.id}-sent`
                ).style.display = "block";
              })
              .catch((error) => console.error(error));
          };

          return (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <LoadingButton
                id={`pass-btn-${params.row.id}`}
                variant="contained"
                onClick={onClick}
              >
                Reset
              </LoadingButton>
              <Typography
                id={`pass-btn-${params.row.id}-sent`}
                sx={{
                  display: "none",
                  color: COLORS.success.main,
                }}
              >
                Email Sent
              </Typography>
            </Box>
          );
        },
      },
      {
        field: "edit",
        headerName: "Edit User",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {
          const onClick = () => {
            setSelectedUser(params.row.user);
            setEditOpen(true);
          };
          const onDeleteClick = () => {
            setSelectedUser(params.row.user);
            setDeleteOpen(true);
          };
          return (
            <Stack direction="row" spacing={2}>
              <IconButton onClick={onClick}>
                <FaEdit />
              </IconButton>
              <IconButton onClick={onDeleteClick}>
                <FaTrashAlt color={COLORS.error.main} />
              </IconButton>
            </Stack>
          );
        },
      },
    ];

    const rows = users.map((user) => {
      return {
        id: user.uuid,
        name: `${user.first} ${user.last}`,
        email: user.user.email,
        currentView: user.customer?.name,
        superuser: user.superuser,
        userrole: user.role,
        user: user,
      };
    });

    setDataTableRowsInactive(rows);
    setDataTableColumnsInactive(columns);
  };

  /**
   * This function handles the creation of columns and rows for MUI Datagrid. Running this function will could the MUI-Datagrid to re-mount.
   * @param {Array.<{name: String, email: String, currentView: Number, superuser: Boolean, userrole: String}>} users
   */
  const populateDataGrid = (users) => {
    const columns = [
      {
        field: "name",
        headerName: "Name",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "currentView",
        headerName: "Current View",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "superuser",
        headerName: "Super User?",
        minWidth: 100,
        flex: 1,
        renderCell: (params) =>
          params.row.superuser ? (
            <FaCheck color={COLORS.success.main} />
          ) : (
            <FaTimes color={COLORS.error.main} />
          ),
      },
      {
        field: "userrole",
        headerName: "User Role",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "passwordreset",
        headerName: "Password Reset",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {
          let options = {
            method: "POST",
            url: process.env.REACT_APP_PASSWORD_RESET_URL,
            headers: { "content-type": "application/json" },
            data: {
              client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
              email: params.row.email,
              connection: "Username-Password-Authentication",
            },
          };

          const onClick = () => {
            axios
              .request(options)
              .then((res) => {
                document.getElementById(
                  `pass-btn-${params.row.id}-sent`
                ).style.display = "block";
              })
              .catch((error) => console.error(error));
          };

          return (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <LoadingButton
                id={`pass-btn-${params.row.id}`}
                variant="contained"
                onClick={onClick}
              >
                Reset
              </LoadingButton>
              <Typography
                id={`pass-btn-${params.row.id}-sent`}
                sx={{
                  display: "none",
                  color: COLORS.success.main,
                }}
              >
                Email Sent
              </Typography>
            </Box>
          );
        },
      },
      {
        field: "edit",
        headerName: "Edit User",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {
          const onClick = () => {
            setSelectedUser(params.row.user);
            setEditOpen(true);
          };
          const onDeleteClick = () => {
            setSelectedUser(params.row.user);
            setDeleteOpen(true);
          };
          return (
            <Stack direction="row" spacing={2}>
              <IconButton onClick={onClick}>
                <FaEdit />
              </IconButton>
              <IconButton onClick={onDeleteClick}>
                <FaTrashAlt color={COLORS.error.main} />
              </IconButton>
            </Stack>
          );
        },
      },
    ];

    const rows = users.map((user) => {
      return {
        id: user.uuid,
        name: `${user.first} ${user.last}`,
        email: user.user.email,
        currentView: user.customer?.name,
        superuser: user.superuser,
        userrole: user.role,
        user: user,
      };
    });

    setDataTableRows(rows);
    setDataTableColumns(columns);
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    // Retrieves all the users for the current cx view.
    const getUsers = async () => {
      const url = "/react/api/admin-users";
      const res = await getRequest(url, accessToken);
      if (res.status === 200) {
        populateDataGrid(res.data.users);
        populateDataGridInactive(res.data.inactiveUsers);
      }
    };

    if (!deleteOpen) {
      getUsers();
    }
  }, [current_customer, accessToken, open, editOpen, deleteOpen]);

  return (
    <>
      <Stack spacing={1}>
        {/* Breadcrumbs */}
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="text.primary">Administration</Typography>
          <Typography color="text.primary">Users</Typography>
        </Breadcrumbs>

        {/* Page Title  && Buttons */}
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography variant="h3">Users</Typography>
          <TextButton
            tooltip="Create New User"
            icon={FaPlus}
            onClick={() => setOpen(true)}
          />
        </Stack>

        <Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={(_event, newValue) => setTabValue(newValue)}
            >
              <Tab label="Users" />
              <Tab label="Inactive Users" />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <DataGridWithStyles
              name="admin-users"
              autoHeight
              paper
              rows={dataTableRows}
              columns={dataTableColumns}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <DataGridWithStyles
              name="admin-users"
              autoHeight
              paper
              rows={dataTableRowsInactive}
              columns={dataTableColumnsInactive}
            />
          </TabPanel>
        </Box>
      </Stack>

      {/* Modal for form */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="Modal-Container">
          <AdminAddUserForm />
        </div>
      </Modal>

      <FormModal setOpen={setEditOpen} open={editOpen}>
        <AdminEditUserForm user={selectedUser} setOpen={setEditOpen} />
      </FormModal>

      <DeleteModal
        open={deleteOpen}
        setOpen={setDeleteOpen}
        user={selectedUser}
        customer={current_customer.uuid}
      />
    </>
  );
};

export default AdminUsers;
