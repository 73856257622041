import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { DataContext } from "../../../../Context/dataContext";
import { postRequest } from "../../../../Helpers/httpRequests";
import { useAppSelector } from "../../../../Redux/app/hooks";

const CrowdstrikeConfigForm = (props) => {
  // ----- STATES && CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const customer = useAppSelector((state) => state.customer);
  const [clientSecret, setClientSecret] = useState("");
  const [showClientScret, setShowClientSecret] = useState(false);
  const [baseUrl, setBaseUrl] = useState("");
  const [falconUrl, setFalconUrl] = useState("");
  const [clientId, setClientId] = useState("");
  const [uuid, setUUID] = useState("");
  const { onFinish, setModalOpen, toolData } = props;
  const [selectedSecurtiyTool, setSelectedSecurityTool] = useState(
    toolData.uuid
  );

  // ----- VARIABLES -----
  // Required Prop

  // ----- FUNCTIONS -----
  const handleSubmit = async () => {
    const data = {
      securityTool: selectedSecurtiyTool,
      base_url: baseUrl.trim(),
      falcon_base_url: falconUrl.trim(),
      client_id: clientId.trim(),
      client_secret: clientSecret.trim(),
      uuid: uuid,
    };

    if (toolData.action === "edit") {
      let loading_toast = toast.loading(
        `Attempting to ${toolData.action} Security Tool Integration`
      );
      const result = await postRequest(
        `/react/api/${customer.uuid}/config/securitytoolconfigs/crowdstrike/edit`,
        accessToken,
        data
      );
      if (result.data.status === 200) {
        toast.dismiss(loading_toast);
        toast.success("Request Successful");
        setModalOpen(false);
        if (onFinish) {
          onFinish();
        }
      } else {
        toast.dismiss(loading_toast);
        toast.error("Failed to connect Security Tool Integration");
      }
    }

    if (toolData.action === "add") {
      let loading_toast = toast.loading(
        `Attempting to ${toolData.action} Security Tool Integration`
      );
      const result = await postRequest(
        `/react/api/${customer.uuid}/config/securitytoolconfigs/crowdstrike/add`,
        accessToken,
        data
      );
      if (result.data.status === 200) {
        toast.dismiss(loading_toast);
        toast.success("Request Successful");
        setModalOpen(false);
        if (onFinish) {
          onFinish();
        }
      } else {
        toast.dismiss(loading_toast);
        toast.error("Failed to connect Security Tool Integration");
      }
    }
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const setForm = async () => {
      if (toolData.action === "edit") {
        setSelectedSecurityTool(toolData.tool.uuid);
        setBaseUrl(toolData.tool.tool_config.base_url);
        setFalconUrl(toolData.tool.tool_config.falcon_base_url);
        setClientId(toolData.tool.tool_config.client_id);
        setClientSecret(toolData.tool.tool_config.client_secret);
        setUUID(toolData.tool.tool_config.uuid);
      }
    };
    setForm();
  }, [accessToken, toolData]);

  return (
    <Stack>
      <Typography variant="h5">Crowdstrike Integration Details</Typography>
      <Divider style={{ margin: "1rem 0" }} />

      <Stack spacing={2}>
        {/* Base Url */}
        <TextField
          required
          id="tool-azure-resource-group"
          label="Base Url"
          value={baseUrl}
          onChange={(e) => setBaseUrl(e.target.value)}
        />

        {/* Falcon Url */}
        <TextField
          required
          id="tool-azure-resource-group"
          label="Falcon Url"
          value={falconUrl}
          onChange={(e) => setFalconUrl(e.target.value)}
        />

        {/* Workspace Name */}
        <TextField
          required
          id="tool-azure-workspace-name"
          label="Client Id"
          value={clientId}
          onChange={(e) => setClientId(e.target.value)}
        />

        {/* Client Secret */}
        <TextField
          required
          id="tool-azure-subscription"
          label="Client Secret"
          type={showClientScret ? "text" : "password"}
          value={clientSecret}
          onChange={(e) => setClientSecret(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowClientSecret(!showClientScret)}
                >
                  {showClientScret ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
          onClick={handleSubmit}
          variant="contained"
        >
          Submit
        </Button>
      </Stack>
    </Stack>
  );
};

CrowdstrikeConfigForm.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
  onFinish: PropTypes.func,
};

export default CrowdstrikeConfigForm;
