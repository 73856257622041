import React, { ReactNode, useState } from "react";
import useGetRequest from "../../../../Helpers/httpRequests/useGetRequest";
import { FocusContext } from "./Context";

// If useGetRequest has a defined return type, replace 'any' with that type
type UseGetResponseType = any;

// Define a type for the Provider's props
interface ProviderProps {
  children: ReactNode;
}

const Provider: React.FC<ProviderProps> = ({ children }) => {
  const [startDate, setStartDate] = useState<Date>(new Date("0001-01-01"));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const response: UseGetResponseType = useGetRequest(
    `/react/api/admin/focus`,
  );
  const [loading, setLoading] = useState(true); 

  // Provider values
  const values = {
    data: response,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    loading,
    setLoading,
  };

  return (
    response.status === 200 && (
      <FocusContext.Provider value={values}>
        {children}
      </FocusContext.Provider>
    )
  );
};

export default Provider;
