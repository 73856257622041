import React from "react";
import { Box, TextField, FormControl, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { DataContext } from "../../../../Context/dataContext";
import { getRequest, postRequest } from "../../../../Helpers/httpRequests";
import { LoadingButton } from "@mui/lab";
import { useAppSelector } from "../../../../Redux/app/hooks";

const ConfigInviteUserForm = ({ setOpen }) => {
  // ----- Context -----
  const { accessToken } = React.useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  // ----- States -----

  const [customerName, setCustomerName] = React.useState([]);

  const [formData, setFormData] = React.useState({
    email: "",
    customerUUID: "",
  });
  // Loading btn
  const [loadingBtn, setLoadinBtn] = React.useState({
    loading: false,
    text: "Submit",
    color: "primary",
  });

  // ----- Variables -----
  /**
   * These are the styles for the Box inside of the modal.
   * The Modal component renders as position Absolute so we
   * have to write styles accordingly
   */
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };

  // ----- Functions -----
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadinBtn((prev) => ({ ...prev, loading: true }));

    const res = await postRequest(
      `/react/api/${current_customer.uuid}/config/user-invites`,
      accessToken,
      formData,
      true
    );
    if (res.status === 201) {
      setLoadinBtn((prev) => ({
        loading: false,
        text: "Success",
        color: "success",
      }));
      setTimeout(() => setOpen(false), 2000);
    } else if (res.status === 409) {
      alert("User already exists");
      setLoadinBtn((prev) => ({
        loading: false,
        text: "Submit",
        color: "primary",
      }));
    } else {
      setLoadinBtn((prev) => ({
        ...prev,
        text: "Failed - Try again",
        color: "error",
      }));
    }
  };

  // ----- On Page Load -----
  React.useEffect(() => {
    const getCustomer = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/config/user-invites`,
        accessToken
      );
      if (res.status === 200) {
        setCustomerName(res.data.customer.display_name);
        setFormData((oldData) => ({
          ...oldData,
          customerUUID: res.data.customer.uuid,
        }));
      }
    };

    getCustomer();
  }, [current_customer, accessToken]);

  return (
    <Box sx={style}>
      {/* Form Header */}
      <Typography sx={{ mb: 1 }} variant="h5">
        Invite User
      </Typography>

      {/* Form Start */}
      <form onSubmit={handleSubmit}>
        <Stack direction="column" spacing={2}>
          <TextField
            type="email"
            label="Email"
            value={formData.email}
            required
            onChange={(e) =>
              setFormData((oldData) => ({ ...oldData, email: e.target.value }))
            }
          />

          {/* User - Select */}
          <FormControl required margin="none">
            <TextField
              inputProps={{ style: { fontWeight: "bold", fontSize: 25 } }}
              value={customerName}
              disabled
            />
          </FormControl>

          <LoadingButton
            color={loadingBtn.color}
            type="submit"
            variant="contained"
            loading={loadingBtn.loading}
          >
            {loadingBtn.text}
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
};

export default ConfigInviteUserForm;
