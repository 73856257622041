import { LoadingButton } from "@mui/lab";
import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import { DataContext } from "../../../Context/dataContext";
import { encryptId } from "../../../Helpers/functions/uni_funcs";
import { getRequest, postRequest } from "../../../Helpers/httpRequests";
import { useAppSelector } from "../../../Redux/app/hooks";

const AV_History = () => {
  // TODO: "run now btn"

  // Navigation for redirects
  const navigate = useNavigate();

  // Alert UUID from Params (URL)
  const { alertUUID } = useParams();

  // ----- STATES && CONTEXT -----
  const [dataTableRows, setDataTableRows] = useState([]);
  const [campaignName, setCampaignName] = useState("");
  const [loading, setLoading] = useState(true);

  // run now btn
  const [runBtnText, setRunBtnText] = useState("run now");
  const [runNowBtnDisabled, setRunNowBtnDisabled] = useState(false);
  const [runNowBtnLoading, setRunNowBtnLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  // run cleanup campaign btn
  const [cleanupBtnText, setCleanupBtnText] = useState("cleanup");
  const [cleanupBtnDisabled, setCleanupBtnDisabled] = useState(false);
  const [cleanupBtnLoading, setCleanupBtnLoading] = useState(false);

  // Contexts
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  // ----- VARIABLES -----
  const buttonStyles = {
    "&.Mui-disabled": {
      backgroundColor: "green",
      color: "white",
    },
  };

  const columns = [
    {
      field: "createdDate",
      headerName: "Created Date",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "timeOfExecution",
      headerName: "Time of Execution",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        const status = params.row.status.toLowerCase();
        if (status === "failed") {
          return <Chip label="Failed" color="error" variant="outlined" />;
        } else if (status === "completed") {
          return <Chip label="Complete" color="success" variant="outlined" />;
        } else {
          return (
            <Chip label={params.row.status} color="error" variant="outlined" />
          );
        }
      },
    },
    {
      field: "outcome",
      headerName: "Outcome",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        if (params.row.outcome === "passed") {
          return <Chip label="PASS" color="success" variant="outlined" />;
        } else if (params.row.outcome === "error") {
          return <Chip label="ERROR" color="error" variant="outlined" />;
        } else if (params.row.outcome === "failed") {
          return <Chip label="FAIL" color="error" variant="outlined" />;
        } else {
          return <Chip label="N/A" color="error" variant="outlined" />;
        }
      },
    },
    {
      field: "details",
      headerName: "Details",
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const isDisabled = params.row.timeOfExecution === null;
        const detailsUrl = `/${current_customer.uuid}/alertvalidation/alerts/details/${encryptId(params.row.details)}`;
    
        return (
          <>
            {isDisabled ? (
              <Button disabled variant="contained">
                Details
              </Button>
            ) : (
              <Link to={detailsUrl} style={{ textDecoration: 'none' }}>
                <Button variant="contained">
                  Details
                </Button>
              </Link>
            )}
          </>
        );
      },
    }
    
  ];

  // ----- FUNCTIONS -----

  // Function used to refresh table
  const handleRefresh = async () => {
    setRefreshing(true);
    const res = await getRequest(
      `/react/api/${current_customer.uuid}/alertvalidation/alert-history/${alertUUID}`,
      accessToken
    );
    if (res.status === 200) {
      populateDataGrid(res.data.executedCampaigns);
      setRefreshing(false);
    }
  };

  // Run now button handler
  const runAlertNow = async () => {
    setRunNowBtnLoading(true);
    const res = await getRequest(
      `/react/api/${current_customer.uuid}/alertvalidation/alert/run-now/${alertUUID}`,
      accessToken
    );
    if (res.status === 200) {
      setRunNowBtnDisabled(true);
      setRunNowBtnLoading(false);
      setRunBtnText("success");
    }
  };

  const runCleanupCampaign = async () => {
    const res = await postRequest(
      `/react/api/${current_customer.uuid}/alertvalidation/alert/run-clean-up/${alertUUID}`,
      accessToken
    );
    if (res.status === 200) {
      setCleanupBtnDisabled(true);
      setCleanupBtnLoading(false);
      setCleanupBtnText("success");
    }
  };

  const populateDataGrid = (campaigns) => {
    const rows = campaigns.map((campaign) => {
      return {
        id: campaign.uuid,
        timeOfExecution: campaign.start
          ? moment(campaign.start).format("MMMM Do YYYY, h:mm a")
          : null,
        createdDate: moment(campaign.created).format("MMMM Do YYYY, h:mm a"),
        status: campaign.result || "N/A",
        outcome: campaign.alert_validation_status,
        details: campaign.id,
      };
    });
    setDataTableRows(rows);
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getAlertHistory = async () => {
      const res = await getRequest(
        `/api/v2/${current_customer.uuid}/alertcampaigns/history/${alertUUID}?depth=1`,
        accessToken
      );
      if (res.status === 200) {
        populateDataGrid(res.data);
        setCampaignName(res.data[0]?.scheduled_exercise.name);
      } else {
        navigate(
          `/${current_customer.uuid}/alertvalidation/scheduled-exercises`
        );
      }
      setLoading(false);
    };

    getAlertHistory();
  }, [current_customer, accessToken, navigate, alertUUID]);
  return (
    <Stack spacing={3} className="w-full h-full">
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Alert Validation</Typography>
        <Link
          color="#0071b1"
          to={`/${current_customer.uuid}/alertvalidation/scheduled-exercises`}
        >
          Scheduled Exercises
        </Link>
        <Typography color="text.primary">Exercise History</Typography>
      </Breadcrumbs>

      {/* Page Title */}
      <Typography variant="h4">{campaignName}</Typography>

      <Box>
        {/* Run Now button */}
        <Box
          sx={{
            display: "flex-start",
            marginBottom: 1,
            width: "50%",
            minWidth: 700,
            justifyContent: "space-between",
          }}
        >
          <LoadingButton
            disabled={runNowBtnDisabled}
            variant="contained"
            loading={runNowBtnLoading}
            sx={buttonStyles}
            onClick={runAlertNow}
          >
            {runBtnText}
          </LoadingButton>
          <LoadingButton
            disabled={cleanupBtnDisabled}
            variant="contained"
            loading={cleanupBtnLoading}
            sx={{ ...buttonStyles, marginLeft: 1 }}
            onClick={runCleanupCampaign}
          >
            {cleanupBtnText}
          </LoadingButton>
        </Box>
        {/* DataGrid */}

        <DataGridWithStyles
          name="av-history"
          autoHeight
          paper
          rows={dataTableRows}
          columns={columns}
          disableSelectionOnClick
          loading={loading}
          refreshing={refreshing}
          handleRefresh={handleRefresh}
        />
      </Box>
    </Stack>
  );
};

export default AV_History;
