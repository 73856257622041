import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { LoadingButton } from "@mui/lab";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { COLORS } from "../../../../Styles/colors";
import { useHttpRequest } from "../../../../Hooks";
import { FaCircle } from "react-icons/fa";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../Redux/app/hooks";

export const DuplicateProject = ({ oldProject, onSuccess }) => {
  const navigate = useNavigate();
  const current_customer = useAppSelector((state) => state.customer);

  // defined here for mapExecName
  const execMethod = [
    {
      value: "exe",
      name: "Binary Executable",
    },
    {
      value: "dll",
      name: "Signed Proxy Execution",
    },
    {
      value: "sh",
      name: "Remote Process Injection",
    },
    {
      value: "local",
      name: "Local Process Injection",
    },
  ];

  // the value that comes from backend is annoyingly the verbose name
  //just get the simple short one that we need elsewhere
  const mapExecName = (name, campaign) => {
    let value = "";
    switch (name.trim()) {
      case "Binary Executable":
        value = "exe";
        break;
      case "AWL Bypass":
        if (campaign.transform.input_file_type) {
          value = "local";
        } else {
          value = "dll";
        }
        break;
      default:
        if (campaign.process_injection_method.length) {
          return "sh";
        }
    }
    return value;
  };

  const [data, setData] = useState({
    uuid: oldProject.uuid || "",
    name: oldProject.name || "",
    start: moment(),
    end: moment(),
    campaigns: oldProject.campaignv2s?.map((campaign) => {
      return {
        name: campaign.name || "",
        execution: mapExecName(campaign.payload?.execution, campaign) || "",
        architecture: campaign.payload?.name.includes("x64")
          ? "64"
          : "32" || "",
        endpoint: campaign.endpoint.uuid || "",
        piMethod: campaign.process_injection_method || "",
        piTarget: campaign.process_injection_target?.uuid || "",
        transform: campaign.transform?.id || "",
        platform: campaign.payload?.simulation.platform,
        simulation: campaign.payload?.simulation.name,
      };
    }),
  });

  const architectures = [
    {
      value: "64",
      name: "64-bit",
    },
    {
      value: "32",
      name: "32-bit",
    },
  ];

  const linuxExecMethod = [
    {
      value: "exe",
      name: "Executable",
    },
  ];

  const piMethods = [
    "CreateRemoteThread",
    "NtCreateRemoteThread",
    "QueueUserAPC",
    "NtQueueUserAPC",
    "RtlCreateUserThread",
  ];

  const modalStyle = {
    position: "absolute",
    bgcolor: "background.paper",
    top: "50%",
    left: "50%",
    // width: "calc(100vw - 33%)",
    width: "80%",
    maxWidth: "calc(100vw - 2rem)",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  const formatDateForDjangoModel = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  };

  // requests
  const { response: endpoints, loading } = useHttpRequest({
    method: "GET",
    path: "/api/v2/agents",
  });

  const { response: piTargets } = useHttpRequest({
    method: "GET",
    path: "/api/v2/process-injection-targets",
  });

  const { response: transformMethods } = useHttpRequest({
    method: "GET",
    path: "/api/v2/admin/payload-transforms",
  });

  const {
    response: success,
    loading: submitLoading,
    fetch: submit,
    error: statusMsg,
  } = useHttpRequest({
    method: "POST",
    path: `/react/api/${current_customer.uuid}/projects/duplicate/${oldProject.uuid}`,
    data: {
      ...data,
      start: formatDateForDjangoModel(data.start),
      end: formatDateForDjangoModel(data.end),
    },
    defer: true,
  });

  useEffect(() => {
    if (success) {
      onSuccess();
      navigate(
        `/${current_customer.uuid}/simulations/campaigns/projects/${success.project.uuid}`
      );
    }
  }, [navigate, onSuccess, current_customer, success]);

  //update value in the campaigns array by name
  const updateCampaign = (idx, value, variable) => {
    const updatedData = data;
    updatedData.campaigns[idx][variable] = value;
    setData({ ...updatedData });
  };

  return (
    <Paper sx={modalStyle}>
      <Typography variant="h5">Duplicate Project</Typography>
      <Stack spacing={2}>
        {/* New Project Name */}
        <TextField
          required
          id="projectName"
          label="Project Name"
          value={data.name}
          onChange={(e) => setData({ ...data, name: e.target.value })}
        />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {/* Start Date */}
            <DesktopDatePicker
              label="Start Date"
              inputFormat="MM/DD/yyyy"
              value={data.start}
              id="startDate"
              onChange={(newVal) => {
                setData({ ...data, start: newVal });
                if (data.start > data.end) {
                  setData({ ...data, end: newVal });
                }
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <Button variant="contained" disabled>
              To
            </Button>
            {/* End Date */}
            <DesktopDatePicker
              label="End Date"
              inputFormat="MM/DD/yyyy"
              value={data.end}
              minDate={data.start}
              id="endDate"
              onChange={(newVal) => setData({ ...data, end: newVal })}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
        </LocalizationProvider>
        <Stack sx={{ width: "100%" }} spacing={0}>
          <Typography variant="h4">Campaigns</Typography>
          {!loading ? (
            data.campaigns.map((campaign, idx) => {
              return (
                <>
                  <Typography gutterBottom={false}>
                    {campaign.simulation}
                  </Typography>
                  <Stack
                    spacing={1}
                    direction={"row"}
                    sx={{ width: "100%", mb: 3, mt: 1 }}
                  >
                    <TextField
                      required
                      id={`campaign${idx}name`}
                      label="Campaign name"
                      value={campaign.name}
                      onChange={(e) =>
                        updateCampaign(idx, e.target.value, "name")
                      }
                      sx={{ flex: 2 }}
                    />
                    <FormControl sx={{ flex: 2 }}>
                      <InputLabel required>Endpoint</InputLabel>
                      <Select
                        required
                        value={campaign.endpoint}
                        onChange={(e) =>
                          updateCampaign(idx, e.target.value, "endpoint")
                        }
                        label="Endpoint"
                      >
                        {endpoints &&
                          endpoints
                            .sort((a, b) => {
                              if (a.active && !b.active) {
                                return -1;
                              } else if (!a.active && b.active) {
                                return 1;
                              } else {
                                return 0;
                              }
                            })
                            .map((endpoint) => {
                              const active = endpoint.active;
                              const color = active
                                ? COLORS.success.light
                                : COLORS.secondary.dark;
                              return endpoint.host_os
                                .toLowerCase()
                                .includes(campaign.platform) ? (
                                <MenuItem
                                  key={endpoint.uuid}
                                  value={endpoint.uuid}
                                >
                                  <Stack direction={"row"} spacing={"1rem"}>
                                    <FaCircle color={color} />
                                    <Typography>{endpoint.name}</Typography>
                                  </Stack>
                                </MenuItem>
                              ) : null;
                            })}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ flex: 1 }}>
                      <InputLabel required>Architecture</InputLabel>
                      <Select
                        value={campaign.architecture}
                        onChange={(e) =>
                          updateCampaign(idx, e.target.value, "architecture")
                        }
                        label="Architecture"
                      >
                        {architectures.map((arch) => {
                          return (
                            <MenuItem key={arch.value} value={arch.value}>
                              {arch.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ flex: 2 }}>
                      <InputLabel required>Execution Method</InputLabel>
                      <Select
                        value={campaign.execution || ""}
                        onChange={(e) => {
                          switch (e.target.value) {
                            case "local":
                              updateCampaign(idx, e.target.value, "execution");
                              updateCampaign(idx, "", "piMethod");
                              updateCampaign(idx, "", "piTarget");
                              break;
                            case "sh":
                              updateCampaign(idx, e.target.value, "execution");
                              updateCampaign(idx, "", "transform");
                              break;
                            default:
                              updateCampaign(idx, e.target.value, "execution");
                              updateCampaign(idx, "", "piMethod");
                              updateCampaign(idx, "", "piTarget");
                              updateCampaign(idx, "", "transform");
                              break;
                          }
                        }}
                        label="Exectution Method"
                      >
                        {campaign.endpoint?.os === "linux"
                          ? linuxExecMethod.map((exec) => {
                              return (
                                <MenuItem key={exec.value} value={exec.value}>
                                  {exec.name}
                                </MenuItem>
                              );
                            })
                          : execMethod.map((exec) => {
                              return (
                                <MenuItem key={exec.value} value={exec.value}>
                                  {exec.name}
                                </MenuItem>
                              );
                            })}
                      </Select>
                    </FormControl>
                    {campaign.execution.includes("sh") && (
                      <>
                        <FormControl sx={{ flex: 2 }}>
                          <InputLabel required>Injection Method</InputLabel>
                          <Select
                            value={campaign.piMethod || ""}
                            onChange={(e) =>
                              updateCampaign(idx, e.target.value, "piMethod")
                            }
                            label="Process Injection Method"
                          >
                            {piMethods &&
                              piMethods.map((exec) => {
                                return (
                                  <MenuItem value={exec} key={exec}>
                                    {exec}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                        <FormControl sx={{ flex: 1 }}>
                          <InputLabel required>Injection Target</InputLabel>
                          <Select
                            value={campaign.piTarget}
                            onChange={(e) =>
                              updateCampaign(idx, e.target.value, "piTarget")
                            }
                            label="Process Injection Target"
                          >
                            {piTargets
                              ? piTargets.map((exec) => {
                                  return (
                                    <MenuItem key={exec.uuid} value={exec.uuid}>
                                      {exec.name}
                                    </MenuItem>
                                  );
                                })
                              : null}
                          </Select>
                        </FormControl>
                      </>
                    )}
                    {campaign.execution.includes("local") && (
                      <FormControl sx={{ flex: 2 }}>
                        <InputLabel required>Transform</InputLabel>
                        <Select
                          value={campaign.transform ? campaign.transform : ""}
                          onChange={(e) =>
                            updateCampaign(idx, e.target.value, "transform")
                          }
                          label="Transform"
                        >
                          {transformMethods &&
                            transformMethods.map((exec, index) => {
                              return (
                                <MenuItem value={exec.id} key={index}>
                                  {exec.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    )}
                  </Stack>
                </>
              );
            })
          ) : (
            <CircularProgress />
          )}
        </Stack>
        {/* Error Status Message */}
        <Typography
          sx={{ display: statusMsg?.length ? "block" : "none" }}
          color="error"
          variant="p"
        >
          Bad Request: {statusMsg}
        </Typography>
        <LoadingButton
          variant="contained"
          loading={submitLoading}
          disabled={submitLoading}
          onClick={() => {
            submit();
          }}
        >
          Submit
        </LoadingButton>
      </Stack>
    </Paper>
  );
};
