import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Customer } from "../../../Types";

const initialState: Customer = null;

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    selectCustomer: (state, action: PayloadAction<Customer | null>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { selectCustomer } = customerSlice.actions;
export default customerSlice.reducer;
