import ProjectList from "../../Pages/Simulations/Projects/ProjectList";
import ProjectDetails from "../../Pages/Simulations/Projects/ProjectDetails";
import ProjectReport from "../../Pages/Simulations/Projects/ProjectReport";
import ProjectTestPlan from "../../Pages/Simulations/Projects/ProjectTestPlans";
import EndpointsList from "../../Pages/Simulations/Endpoints/EndpointsList";
import EndpointEditForm from "../../Components/Forms/Endpoints/EndpointEditForm";
import EndpointDetails from "../../Pages/Simulations/Endpoints/EndpointDetails";
import EndpointsDownloadAgents from "../../Pages/Simulations/Endpoints/EndpointsDownloadAgents";

import SimulationDetails from "../../Pages/Simulations/SimulationLibrary/SimulationDetails";

import CampaignsList from "../../Pages/Simulations/Campaigns/CampaignsList";

import { Routes, Route } from "react-router-dom";
import SimLibrary from "../../Pages/Simulations/SimLibrary/SimLibrary";
import ProjectFormAddTestPlans from "../../Components/Forms/Simulations/ProjectFormAddTestPlans/ProjectFormAddTestPlans";

const SimulationRoutes = () => {
  return (
    <Routes>
      <Route exact path="/campaigns/projects/list" element={<ProjectList />} />

      <Route
        exact
        path="/campaigns/projects/:projectUUID"
        element={<ProjectDetails />}
      />
      <Route
        exact
        path="/campaigns/projects/:projectUUID/report"
        element={<ProjectReport />}
      />

      <Route exact path="/campaigns/list" element={<CampaignsList />} />
      <Route
        exact
        path="/campaigns/projects/testplans"
        element={<ProjectTestPlan />}
      />
      <Route
        exact
        path="/campaigns/projects_add_test_plans"
        element={<ProjectFormAddTestPlans />}
      />
      <Route
        exact
        path="/campaigns/projects/:projectUUID"
        element={<ProjectDetails />}
      />
      <Route
        exact
        path="/campaigns/projects/:projectUUID/report"
        element={<ProjectReport />}
      />
      <Route exact path="/endpoints/list/" element={<EndpointsList />} />
      <Route
        exact
        path="/endpoints/edit/:endpointUUID"
        element={<EndpointEditForm />}
      />
      <Route
        exact
        path="/endpoints/detail/:endpointUUID"
        element={<EndpointDetails />}
      />
      <Route
        exact
        path="/endpoints/download-agents"
        element={<EndpointsDownloadAgents />}
      />
      <Route exact path="/list" element={<SimLibrary />} />
      <Route exact path="/:simulationUUID" element={<SimulationDetails />} />
    </Routes>
  );
};

export default SimulationRoutes;
