import React from "react";
import image1 from "./images/image1.png";
import image2 from "./images/image2.png";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

const SimBuilderHowToHtml = () => {
  return (
    <Stack direction={"column"} spacing={2}>
      <Typography variant="h5">
        Custom Simulation Builder - Help Page Content
      </Typography>
      <Typography>
        BlindSPOT simulations are sequences of actions that enable testing
        security controls. The simulations are expressed as payloads, which are
        executed by the BlindSPOT agent. The actions are executed through the
        BlindSPOT payload automation, and the results of each action is returned
        to the BlindSPOT server for review and scoring.
      </Typography>
      <Typography>
        Each step in a simulation is called an &ldquo;action&rdquo;, and it is
        composed of the payload module you select (what this step should do) and
        any arguments or additional files necessary to execute the step.
      </Typography>
      <Typography>
        You can create a new simulation from a blank template, or you can start
        with an existing published simulation.
      </Typography>
      <img
        src={image1}
        alt="Simulation Builder Home Screen"
        style={{ imageRendering: "auto", maxWidth: "100%" }}
      />
      <Typography>
        From here you can see the simulations that are still in the
        &ldquo;draft&rdquo; state, choose to build a whole new simulation from
        scratch, or you can start from an existing published simulation in the
        &ldquo;Published Simulations&rdquo; dropdown.
      </Typography>
      <Typography>
        Once you are in building mode, you&rsquo;ll see a few sections of the
        page available:
      </Typography>
      <img src={image2} alt="Simulation Builder creation screen" />
      <Typography>
        <strong>
          <em>Simulation Details</em>
        </strong>
      </Typography>
      <p style={{ marginBottom: 2 }}>
        Title and description. These will be used once the simulation is
        published, and we&rsquo;ll check to make sure that there isn&rsquo;t
        already an existing simulation using the same name before you can save
        it.
      </p>
      <p style={{ marginBottom: 2 }}>
        <strong>
          <em>Embedded Payload Files</em>
        </strong>
      </p>
      <p style={{ marginBottom: 2 }}>
        The BlindSPOT payload system allows for you to embed certain files
        directly into the payload when built, which can then be written to disk
        on the target system, or can be used entirely within memory by modules
        like &ldquo;execute-assembly&rdquo;, &ldquo;coffloader&rdquo;, and
        &ldquo;embed&rdquo;. We track whether or not an embedded payload file is
        being utilized for you as well.
      </p>
      <p style={{ marginBottom: 2 }}>
        <strong>
          <em>Add Action</em>
        </strong>
      </p>
      <p style={{ marginBottom: 2 }}>
        Each step in a simulation is called an action. An action is created by
        selecting the module you&rsquo;d like ot use that matches the procedure
        you are creating. Depending on which module you select you may need to
        provide additional arguments, depending on what the module does.
      </p>
      <p style={{ marginBottom: 2 }}>
        <strong>Payload Modules</strong>
      </p>
      <p>Let&rsquo;s start with the payload modules.</p>
      <TableContainer component={Paper}>
        <Table aria-label="Custom Simulation Builder">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Module Name</strong>
              </TableCell>
              <TableCell>
                <strong>Functionality</strong>
              </TableCell>
              <TableCell>
                <strong>Arguments</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>clipboard</TableCell>
              <TableCell>
                retrieves the contents of the active user's clipboard using OS
                APIs
              </TableCell>
              <TableCell>None</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>coffloader</TableCell>
              <TableCell>
                Execute Beacon Object Files (BOF) in memory (BOF is embedded
                into payload)
              </TableCell>
              <TableCell>
                <ul>
                  <li>
                    BOF file - Path to the BOF file, either in the VFS or on the
                    host system disk - String
                  </li>
                  <li>Key - encryption key for the BOF file</li>
                  <li>
                    Optional Arguments:
                    <ul>
                      <li>Entry - entrypoint for the BOF</li>
                      <li>wstring - add a variable of type "wstring"</li>
                      <li>string - add a variable of type "string"</li>
                      <li>short - add a variable of type short</li>
                      <li>int - add a variable of type int</li>
                    </ul>
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>crypt</TableCell>
              <TableCell>
                Encrypts/Decrypt a file or directory using symmetric encryption,
                optionally erase files after they are encrypted/decrypted
              </TableCell>
              <TableCell>
                <ul>
                  <li>Password for encryption/decryption String</li>
                  <li>Recurse? Boolean</li>
                  <li>Target - File Path to directory or file - String</li>
                  <li>
                    Selection of:
                    <ul>
                      <li>Encrypt</li>
                      <li>Decrypt</li>
                    </ul>
                  </li>
                  <li>Erase? (If selecting encrypt) Boolean</li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>datagen</TableCell>
              <TableCell>
                Generate dummy data that looks like PII data sets
              </TableCell>
              <TableCell>
                <ul>
                  <li>Length - Int</li>
                  <li>Outfile - file path to write the file to - String</li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>dnslookup</TableCell>
              <TableCell>
                Execute a DNS lookup without using CLI tools on host system
              </TableCell>
              <TableCell>
                <ul>
                  <li>Lookup - Hostname to look up - String</li>
                  <li>
                    Optional Arguments:
                    <ul>
                      <li>Resolver - Specify the DNS server to use - string</li>
                      <li>
                        Port - Specify a custom port to use for the specified
                        DNS resolver - Int
                      </li>
                    </ul>
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>downloader</TableCell>
              <TableCell>
                Downloads a file from an external web address (HTTP/S)
              </TableCell>
              <TableCell>
                <ul>
                  <li>Src - the source to download - HTTP or FTP - string</li>
                  <li>
                    Dest - destination to put the file, can be the internal VFS
                    or host disk system - string
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>embed</TableCell>
              <TableCell>
                Access files embedded into the payload, can write to disk or
                passed to another step in the simulation
              </TableCell>
              <TableCell>
                <ul>
                  <li>Src - the file in the payload VFS - Selection</li>
                  <li>
                    Dest - THe path on the host file system to copy the file -
                    String
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>executeAssembly</TableCell>
              <TableCell>
                Execute a .NET assembly in memory (assembly is embedded into
                payload) with arbitrary commands
              </TableCell>
              <TableCell>
                <ul>
                  <li>
                    -e - The .NET assembly to be executed, can be from payload
                    VFS or file path on the host disk - String
                  </li>
                  <li>
                    Optional Arguments:
                    <ul>
                      <li>
                        -a - Additional arguments to pass to the assembly when
                        executed - String
                      </li>
                      <li>
                        -p - Specify the remote process to inject into, use the
                        name of the executable - String
                      </li>
                      <li>
                        -r - Refresh ntdll and kernel32 DLLs from disk before
                        execution - Boolean
                      </li>
                      <li>
                        -d - Enable debug mode of the execute-assembly module -
                        Boolean
                      </li>
                    </ul>
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>exfil</TableCell>
              <TableCell>
                Send files to external hosts over different protocols (BlindSPOT
                target services available to stage where testing is necessary)
              </TableCell>
              <TableCell>
                <ul>
                  <li>
                    -o - The name of the file to output the generated data to
                  </li>
                  <li>-l - number of lines of data (rows) to generate</li>
                  <li>-s - Host:port to send the data to (TCP socket)</li>
                  <li>-w Host:port to send the data to (HTTP/S)</li>
                  <li>-d - Enable debug messaging for this module</li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>file</TableCell>
              <TableCell>
                Create different file types with dummy test data
              </TableCell>
              <TableCell>
                <ul>
                  <li>--path - The full path of the output file to create</li>
                  <li>--size - the size of the file to create (in MB)</li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>mimikatz</TableCell>
              <TableCell>
                Proof of concept for payload internal process injection, this
                one happens to be mimikatz, runs in memory
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>pingsweep</TableCell>
              <TableCell>
                Execute an ICMP ping sweep of the specified network range
              </TableCell>
              <TableCell>
                <ul>
                  <li>
                    -r - the remote host or network to ping. For networks use
                    standard CIDR notation (10.20.30.0/24)
                  </li>
                  <li>
                    -t - number of threads to use for pinging (default is 1)
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>portsweep</TableCell>
              <TableCell>
                Execute a port scan on the specified network range on the
                specified ports
              </TableCell>
              <TableCell>
                <ul>
                  <li>
                    -r - the remote host or network to ping. For networks use
                    standard CIDR notation (10.20.30.0/24)
                  </li>
                  <li>-p - TCP Port to sweep</li>
                  <li>
                    -t - Timeout value (in seconds) when attempting to connect
                    to a port (default is 10)
                  </li>
                  <li>
                    -n - Number of threads to use for sweeping (default is 1)
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>privileges</TableCell>
              <TableCell>
                Determine the OS permissions that the payload is running with,
                using OS API calls
              </TableCell>
              <TableCell>None</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>processes</TableCell>
              <TableCell>
                Enumerate running processes on the host system via API calls
              </TableCell>
              <TableCell>None</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>run</TableCell>
              <TableCell>
                Execute a command on the target system with optional parameters
              </TableCell>
              <TableCell>
                <p>
                  The run module expects the first argument to be the executable
                  you are calling - powershell.exe, cmd.exe, cscript.exe, etc,
                  and then the arguments you want to pass that executable.
                </p>
                <p>
                  For cmd.exe, use the /c to start the next section of
                  arguments.
                </p>
                <p>
                  For powershell.exe, use the -command flag to start the next
                  section or arguments
                </p>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>screenshot</TableCell>
              <TableCell>Return a screenshot of the target system</TableCell>
              <TableCell>None</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>search</TableCell>
              <TableCell>
                Search files or directories for specified expressions, using OS
                APIs
              </TableCell>
              <TableCell>
                <ul>
                  <li>
                    -regex - the pattern (regex) that matches the data you want
                    the search to find - NOTE: matches against filenames only,
                    not file contents
                  </li>
                  <li>-directory - Directory to search in</li>
                  <li>-threads - number of threads to use (defaults to 1)</li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>services</TableCell>
              <TableCell>
                Enumerate the services on the system via API calls
              </TableCell>
              <TableCell>None</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>sleep</TableCell>
              <TableCell>
                Set the payload to sleep for the indicated number (in seconds)
              </TableCell>
              <TableCell>
                -seconds - Number of seconds for the payload to sleep
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>sysinfo</TableCell>
              <TableCell>
                Enumerate system information using the windows sysinfo.exe
                utility
              </TableCell>
              <TableCell>None</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default SimBuilderHowToHtml;
