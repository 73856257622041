import React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  InputLabel,
  Checkbox,
  Select,
  MenuItem,
  ListItemText,
  OutlinedInput,
} from "@mui/material";

const SimulationMultiSelect = ({ formData, setFormData, simulations }) => {
  // ----- Context -----

  // ----- States -----

  // ----- Variables -----
  /**
   * Multi-Select Styles for Customer Accesses in form
   */
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // ---- Functions -----

  /**
   * This function handles the Customer Access select of the form.
   * @param {Event} event
   * @returns
   */
  const handleSimSelectChange = (event) => {
    const {
      target: { value },
    } = event;

    // if the last item in array is "all", set forData.customer_access
    //to an empty array if it is full, otherwise set it to equal ALL the
    //simulations in the simulations state
    if (value[value.length - 1] === "all") {
      setFormData((oldData) => ({
        ...oldData,
        simulations:
          oldData.simulations.length === simulations.length ? [] : simulations,
      }));
      return;
    }

    setFormData((oldData) => ({
      ...oldData,
      simulations: typeof value === "string" ? value.split(",") : value,
    }));
  };

  // ----- On page load -----

  return (
    <FormControl sx={{width: "10rem"}}>
      <InputLabel id="sim-multiple-checkbox-label">Simulations</InputLabel>
      <Select
        labelId="cx-multiple-checkbox-label"
        id="cx-multiple-checkbox"
        multiple
        value={formData.simulations}
        onChange={handleSimSelectChange}
        input={<OutlinedInput label="Simulations" />}
        renderValue={(selected) => selected.join(",")}
        MenuProps={MenuProps}
      >
        <MenuItem sx={{ paddingLeft: 0 }} value="all">
          <Checkbox
            id="all-cx-checkbox"
            checked={
              simulations.length > 0 &&
              simulations.length === formData.simulations.length
            }
            indeterminate={
              formData.simulations.length > 0 &&
              formData.simulations.length < simulations.length
            }
          />
          <ListItemText primary="Select All" />
        </MenuItem>

        {simulations.map((s) => (
          <MenuItem key={s} value={s}>
            <Checkbox
              checked={formData.simulations.some(
                (sim) => sim === s
              )}
            />
            <ListItemText primary={s} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};


SimulationMultiSelect.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  simulations: PropTypes.array.isRequired
};

export default SimulationMultiSelect;
