import React, { useEffect, useContext, useState } from "react";
import {
  Typography,
  Paper,
  TableContainer,
  TableBody,
  TableCell,
  Table,
  TableHead,
  TableRow,
  Modal,
  Button,
  Checkbox,
} from "@mui/material";
import { Box } from "@mui/system";
import RecommendationForm from "../../../../Components/Forms/Reporting/RecommendationForm/RecommendationForm";
import { DataContext } from "../../../../Context/dataContext";
import { getRequest, postRequest } from "../../../../Helpers/httpRequests";
import { FaPlus } from "react-icons/fa";
import TextButton from "../../../../Components/Buttons/TextButton/TextButton";
import DataGridWithStyles from "../../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../Redux/app/hooks";

export const Recommendations = (props) => {
  const { superSetRecs, uuidReport } = props;
  const { accessToken } = useContext(DataContext);
  const customer = useAppSelector((state) => state.customer);
  const [recommendations, setRecommendations] = useState([]);
  const [presetRecs, setPreSetRecs] = useState([]);
  const [addedRecs, setAddedRecs] = useState([]);
  const [changesMade, setChangesMade] = useState(false);
  const [allRecommendations, setAllRecommendations] = useState([]);
  const [recDataGridRows, setRecDataGridRows] = useState([]);
  const [recDataGridCols, setRecDataGridCols] = useState([]);
  const [openRecModal, setOpenRecModal] = useState(false);
  const [recModalInfo, setRecModalInfo] = useState({
    open: false,
    rec: null,
  });
  const [refresh, setRefresh] = useState(false);

  const modalStyle = {
    position: "absolute",
    bgcolor: "background.paper",
    top: "50%",
    left: "50%",
    width: "60%",
    maxWidth: 1000,
    maxHeight: "90%",
    transform: "translate(-25%, -50%)",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
  };

  const handleSave = async () => {
    const data = {
      report: uuidReport,
      recommendations: addedRecs,
    };
    const loadingToast = toast.loading("Adding Recommendations");
    const res = await postRequest(
      `/react/api/${customer.uuid}/report/addRecommendation`,
      accessToken,
      data,
      true
    );

    if (res.status === 200) {
      toast.dismiss(loadingToast);
      toast.success("Recommendations added");
      setRefresh((current) => !current);
      setOpenRecModal(false);
    } else {
      toast.dismiss(loadingToast);
      toast.error("Error occured adding Recommendations");
    }
  };

  useEffect(() => {
    const getRecommendations = async () => {
      const res = await getRequest(
        `/react/api/${customer.uuid}/projectreport/${uuidReport}/recommendations`,
        accessToken
      );

      if (res.status === 200) {
        setRecommendations(res.data.recommendations);
        setAllRecommendations(res.data.allRecommendations);
        superSetRecs(res.data.recommendations);
        setPreSetRecs(res.data.recommendations);
        const recommendationUUIDs = res.data.recommendations.map(
          (rec) => rec.uuid
        );
        setAddedRecs((prevAddedRecs) => [
          ...prevAddedRecs,
          ...recommendationUUIDs,
        ]);
      }
    };
    getRecommendations();
  }, [accessToken, superSetRecs, uuidReport, refresh, customer.uuid]);

  useEffect(() => {
    const getGridData = () => {
      const columns = [
        {
          field: "name",
          headerName: "Name",
          minWidth: 100,
          flex: 2,
          renderCell: (params) => {
            return (
              <Button
                onClick={() => {
                  setRecModalInfo({
                    open: true,
                    rec: params.row,
                  });
                }}
                sx={{ width: "100%", justifyContent: "left" }}
                title={params.value}
              >
                {params.value}
              </Button>
            );
          },
        },
        {
          field: "score",
          headerName: "Score",
          minWidth: 100,
          flex: 1,
        },
        {
          field: "included",
          headerName: "Include in Report",
          minWidth: 100,
          flex: 1,
          renderCell: (params) => {
            return (
              <Checkbox
                checked={params.row.included}
                onChange={(event) => {
                  setChangesMade(true);
                  const isChecked = event.target.checked;
                  const recId = params.row.id;

                  // Update addedRecs based on isChecked
                  setAddedRecs((prevAddedRecs) => {
                    if (isChecked) {
                      return [...prevAddedRecs, recId];
                    } else {
                      return prevAddedRecs.filter((id) => id !== recId);
                    }
                  });

                  // Update the included status in the row
                  setRecDataGridRows((prevRows) => {
                    return prevRows.map((row) => {
                      if (row.id === recId) {
                        return { ...row, included: isChecked };
                      }
                      return row;
                    });
                  });
                }}
              />
            );
          },
        },
      ];
      const rows = allRecommendations.map((rec) => {
        return {
          id: rec.uuid,
          ...rec,
          // true if the value exists, false otherwise
          included: Boolean(
            recommendations.find((rec2) => rec2.uuid === rec.uuid)
          ),
        };
      });
      setRecDataGridCols(columns);
      setRecDataGridRows(rows);
    };

    // so allRecommendations to load, so I set the grid data to render afterwards. Invisible until you click "Add Recommendation"
    if (allRecommendations) {
      getGridData();
    }
  }, [allRecommendations, accessToken, uuidReport, recommendations]);

  return (
    <div>
      <Paper sx={{ padding: "1rem" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            marginBottom: 1,
          }}
        >
          <Typography
            variant="h5"
            sx={{ marginTop: "auto", marginBottom: "auto" }}
          >
            Recommendations
          </Typography>
          <Button
            size="small"
            startIcon={<FaPlus />}
            variant="contained"
            onClick={() => {
              setOpenRecModal(true);
            }}
          >
            Add / Remove
          </Button>
        </Box>
        {presetRecs.length > 0 && (
          <TableContainer>
            {/* Linked Recommendations table */}
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="Recommendations"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Risk Level</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {presetRecs.map((rec) => {
                  return (
                    <TableRow
                      key={rec.uuid}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {rec.name}
                      </TableCell>
                      <TableCell align="left">{rec.score}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
      <Modal
        open={openRecModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper sx={modalStyle} spacing={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Typography variant="h5">Add Recommendations to Project</Typography>
            <TextButton
              tooltip="New Recommendation"
              href="/"
              icon={FaPlus}
              onClick={() => setRecModalInfo({ open: true })}
            />
          </Box>
          <DataGridWithStyles
            name="project-report-recs"
            autoHeight
            paper
            rows={recDataGridRows}
            columns={recDataGridCols}
          />
          {
            <Box
              sx={{
                width: "100%",
                marginTop: 1,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={() => {
                  setOpenRecModal(false);
                }}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                disabled={!changesMade}
                variant="contained"
                onClick={() => handleSave()}
              >
                Save
              </Button>
            </Box>
          }
        </Paper>
      </Modal>
      <Modal
        open={recModalInfo ? recModalInfo.open : false}
        onClose={() => {
          setRecModalInfo({
            open: false,
            rec: null,
          });
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <RecommendationForm
            recommendation={recModalInfo.rec}
            setOpen={(rec) => {
              setRecModalInfo({
                open: false,
                rec: null,
              });
              const temp = allRecommendations;
              const rec_idx = temp.findIndex((r) => r.uuid === rec.uuid);
              if (rec_idx !== -1) {
                temp.splice(rec_idx, 1);
              }
              temp.push({ ...rec, id: rec.uuid });
              setAllRecommendations(temp);
              setRefresh(!refresh);
            }}
          />
        </div>
      </Modal>
    </div>
  );
};
