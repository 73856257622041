import React, { useEffect, useState } from "react";
import { Typography, Box, Paper, Stack } from "@mui/material";
import {
  FaBan,
  FaFileCode,
  FaTimesCircle,
  FaEyeSlash,
  FaExclamationTriangle,
} from "react-icons/fa";
import {COLORS} from "../../../Styles/colors"

const ResultsBanner2 = (props) => {
  // ----- Context -----
  const [outcomeCounts, setOutcomeCounts] = useState([]);

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getCampaignDetails = async () => {
      setOutcomeCounts(props.outcomeCounts);
    };
    getCampaignDetails();
  }, [props.outcomeCounts]);

  return (
    <Box
      sx={{
        height: 100,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Paper
        sx={{
          backgroundColor: COLORS.success.main,
          flex: 1,
          marginRight: 5,
          marginTop: 1,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <FaBan
          style={{ marginTop: 20, marginLeft: 20 }}
          size={40}
          color={"white"}
        />
        <Stack sx={{ marginLeft: 5, marginTop: 1 }}>
          <Typography
            component={"span"}
            fontSize={25}
            fontWeight={"bold"}
            color={"white"}
          >
            {outcomeCounts.blocked}
          </Typography>
          <Typography
            component={"span"}
            fontSize={15}
            fontWeight={"bold"}
            color="white"
          >
            Blocked
          </Typography>
        </Stack>
      </Paper>
      <Paper
        sx={{
          backgroundColor: COLORS.primary.main,
          flex: 1,
          marginRight: 4,
          marginTop: 1,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <FaExclamationTriangle
          style={{ marginTop: 20, marginLeft: 20 }}
          size={40}
          color={"white"}
        />
        <Stack sx={{ marginLeft: 5, marginTop: 1 }}>
          <Typography
            component={"span"}
            fontSize={25}
            fontWeight={"bold"}
            color={"white"}
          >
            {outcomeCounts.alerted}
          </Typography>
          <Typography
            component={"span"}
            fontSize={15}
            fontWeight={"bold"}
            color="white"
          >
            Alerted
          </Typography>
        </Stack>
      </Paper>
      <Paper
        sx={{
          backgroundColor: COLORS.yellow,
          flex: 1,
          marginRight: 5,
          marginTop: 1,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <FaFileCode
          style={{ marginTop: 20, marginLeft: 20 }}
          size={40}
          color={"white"}
        />
        <Stack sx={{ marginLeft: 5, marginTop: 1 }}>
          <Typography
            component={"span"}
            fontSize={25}
            fontWeight={"bold"}
            color={"white"}
          >
            {outcomeCounts.logged}
          </Typography>
          <Typography
            component={"span"}
            fontSize={15}
            fontWeight={"bold"}
            color="white"
          >
            Logged
          </Typography>
        </Stack>
      </Paper>
      <Paper
        sx={{
          backgroundColor: COLORS.error.main,
          flex: 1,
          marginRight: 5,
          marginTop: 1,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <FaEyeSlash
          style={{ marginTop: 20, marginLeft: 20 }}
          size={40}
          color={"white"}
        />
        <Stack sx={{ marginLeft: 5, marginTop: 1 }}>
          <Typography
            component={"span"}
            fontSize={25}
            fontWeight={"bold"}
            color={"white"}
          >
            {outcomeCounts.nothing}
          </Typography>
          <Typography
            component={"span"}
            fontWeight={"bold"}
            fontSize={15}
            color="white"
          >
            No evidence
          </Typography>
        </Stack>
      </Paper>
      <Paper
        sx={{
          backgroundColor: COLORS.secondary.main,
          flex: 1,
          marginTop: 1,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <FaTimesCircle
          style={{ marginTop: 20, marginLeft: 20 }}
          size={40}
          color={"white"}
        />
        <Stack sx={{ marginLeft: 5, marginTop: 1 }}>
          <Typography
            component={"span"}
            fontSize={25}
            fontWeight={"bold"}
            color={"white"}
          >
            {outcomeCounts.notScored}
          </Typography>
          <Typography
            component={"span"}
            fontWeight={"bold"}
            fontSize={15}
            color="white"
          >
            Not Scored
          </Typography>
        </Stack>
      </Paper>
    </Box>
  );
};


export default ResultsBanner2;
