import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { capitalize } from "../../Helpers/functions";
import { FormContext } from "../context/FormContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";

/**
 * The `PasswordField` is a convenience wrapper for the MUI Input that automatically
 * handles the show/hide adornment and validation.
 *
 * - `prop`: (type - default) Description
 *
 * - `name`: (string) - name of the key used in formData, this will be what the key name will be in the request data.
 * - `required`: (Bool - false) Field cannot be null or undefined.
 * - `fullWidth`: (Bool - false) Inputs width will stretch to fit the containers width.
 * - `maxLength`: (Integer - null) Maximum length allowed for the password
 * - `allowSpaces`: (Bool - false) Allow spaces in the password
 * - `label`: (String - defaults to name prop) - Change the label from "Password" to anything   else, such as "Key", or "Secret".
 * -
 */
const PasswordField = (props) => {
  const { name, required, fullWidth, label, maxLength, allowSpaces } = props;
  const [showPassword, setShowPassword] = useState(false);
  const { formData, setFormData } = useContext(FormContext);

  const isPasswordValid = (password) => {
    // Check for spaces in password.
    if (password.includes(" ") && !allowSpaces) {
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    if (!isPasswordValid(e.target.value)) return;
    setFormData((data) => ({
      ...data,
      [name]: e.target.value,
    }));
  };

  // Returns formated max length input can be
  const charCountDisplay = () => {
    if (formData[name]) {
      return `${formData[name]?.length} / ${maxLength}`;
    }
    return `0 / ${maxLength}`;
  };

  // Wonder what this function does..
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  // According to the example given on MUI, this is needed. Reasoning unknown
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl fullWidth={fullWidth} required={required}>
      <InputLabel>{label || capitalize(name)}</InputLabel>
      <OutlinedInput
        label={label || capitalize(name)}
        type={showPassword ? "text" : "password"}
        onChange={handleChange}
        value={formData[name] || ""}
        inputProps={{ maxLength: maxLength }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText id="component-helper-text">
        {maxLength ? charCountDisplay() : null}
      </FormHelperText>
    </FormControl>
  );
};

PasswordField.propTypes = {
  // Required
  name: PropTypes.string.isRequired,

  // Optional
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  maxLength: PropTypes.number,
  allowSpaces: PropTypes.bool,
};

PasswordField.defaultProps = {
  required: false,
  fullWidth: false,
  maxLength: null,
  allowSpaces: false,
};

export default PasswordField;
