import React, { useContext, useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useHttpRequest } from "../../../Hooks";
import { ProviderFormContext } from "../context/ProviderFormContext";

const CustomerSelect = () => {
  const { id, formData, setFormData, customers, setCustomers, type } =
    useContext(ProviderFormContext);
  const { response, error, loading } = useHttpRequest({
    method: "GET",
    path: "/api/v2/customers",
  });

  const handleCustomerSelectChange = (e) => {
    setFormData((data) => ({ ...data, customer: e.target.value }));
  };

  useEffect(() => {
    if (!error && response) {
      let filteredCustomers = response;

      // Only filter customers if the form type is "edit"
      if (type !== "edit") {
        filteredCustomers = response.filter(
          (customer) => !customer.is_service_provider
        );
      }

      setCustomers(filteredCustomers);
    }
  }, [response, error, setCustomers, id, type]);

  return (
    !loading && (
      <FormControl fullWidth required>
        <InputLabel id="customer-select-label">Customer</InputLabel>
        <Select
          labelId="customer-select-label"
          value={formData.customer}
          label="Customer"
          onChange={handleCustomerSelectChange}
          disabled={Boolean(id)}
        >
          {customers.map((c) => (
            <MenuItem key={c.id} value={c.id}>
              {c.display_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  );
};

export default CustomerSelect;
