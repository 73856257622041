export const useGetRedirectURL = () => {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  let baseURL = "https://app.blindspotsec.com";
  if (environment === "LOCAL") {
    baseURL = "https://localhost:3000";
  } else if (environment === "DEV") {
    baseURL = "https://blindspotdev.com";
  }
  return baseURL;
};
