import { useState, useEffect } from "react";

/**
 * Custom hook that returns the visibility state of the document.
 * @returns {boolean} The visibility state of the document.
 */
export const useVisibility = (): boolean => {
  const [isVisible, setIsVisible] = useState<boolean>(!document.hidden);

  useEffect(() => {
    const handleVisibilityChange = (): void => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Cleanup
    return (): void => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return isVisible;
};
