import React, { useState } from "react";
import {
  Stack,
  Typography,
  FormControl,
  InputLabel,
  Select,
  TextField,
  MenuItem,
  Box,
  IconButton,
} from "@mui/material";
import { FaTimes } from "react-icons/fa";
import { useAppSelector } from "../../../Redux/app/hooks";

export const ArchitectureSpecificFileUpload = ({
  simOS,
  setData,
  data,
  existingFile = null,
}) => {
  const [selectedArchitectures, setSelectedArchitectures] = useState([]);
  const [alias, setAlias] = useState("");

  const user = useAppSelector((state) => state.user);

  // (actual value, displayed value, supported OS)
  const supportedArchitectures = [
    ["x64", "Windows 64-bit", "windows"],
    ["x86", "Windows 32-bit (x86)", "windows"],
  ];

  const handleArchChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedArchitectures(value);
  };

  const handleUploadFile = (e, archName) => {
    const f = e.target.files[0];

    const reader = new FileReader();
    reader.onload = () => {
      let content = reader.result.split(",")[1]; // b64 part
      const byteCharacters = atob(content);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const sizeKB = (byteArray.length / 1024).toFixed(2);

      // Append archName before the file extension
      const fileNameParts = alias.split(".");
      const fileExtension = fileNameParts.pop();
      const modifiedFileName = `${fileNameParts.join(
        "_"
      )}_${archName}.${fileExtension}`;
      setData((prevData) => ({
        ...prevData,
        input_files: [
          ...prevData.input_files,
          {
            name: modifiedFileName,
            content: byteArray,
            size: sizeKB,
            uploaded_by: user.email,
            uploaded_date: new Date().toLocaleString(),
            alias: alias,
          },
        ],
      }));
    };
    reader.readAsDataURL(f);
  };

  return (
    <Box>
      <Stack sx={{ px: "1rem" }} spacing={1}>
        <Typography variant="h5">Architecture Specific Files</Typography>
        <Typography>
          Files uploaded here will have their architecture appended to them. In
          the simulation steps, they can be referenced as whatever alias is set.
        </Typography>
        <TextField
          required
          value={alias}
          onChange={(e) => {
            setAlias(e.target.value);
          }}
          type="text"
          label="Alias"
          variant="outlined"
        />
        <FormControl required>
          <InputLabel>Architectures Supported</InputLabel>
          <Select
            value={selectedArchitectures}
            onChange={(e) => {
              handleArchChange(e);
            }}
            multiple
            renderValue={(selected) => {
              return selected.map((x) => x[1]).join(",");
            }}
          >
            {supportedArchitectures
              .filter((arch) => arch[2] === simOS)
              .map((arch) => {
                return (
                  <MenuItem key={arch[0]} value={arch}>
                    {arch[1]}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <Stack>
          {selectedArchitectures.map((arch) => {
            return (
              <Stack direction={"row"} spacing={3}>
                <Typography>{arch[1]} File:</Typography>
                <TextField
                  type="file"
                  onChange={(e) => handleUploadFile(e, arch[0])}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <Box ml={1}>
                        <IconButton
                          size="small"
                          onClick={() => {
                            setData((prevData) => ({
                              ...prevData,
                              input_files: [],
                            }));
                          }}
                          disabled={data.input_files.length === 0}
                        >
                          <FaTimes />
                        </IconButton>
                      </Box>
                    ),
                  }}
                />
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </Box>
  );
};
