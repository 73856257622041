import {
    Alert,
    Box,
    Button,
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { DataContext } from "../../../../Context/dataContext";
import { filePostRequest } from "../../../../Helpers/httpRequests";
import { SimLibraryContext } from "../provider/context";


const MAX_DISPLAY_ITEMS = 8;

const DownloadSimActions = ({ setOpen, customer }) => {
  const { simulations } = useContext(SimLibraryContext);
  const { accessToken } = useContext(DataContext);

  const [selectedSimulations, setSelectedSimulations] = useState([]);

  const handleChange = (event) => {
    setSelectedSimulations(event.target.value);
  };

  const handleSelectAll = () => {
    const allSimUUIDs = simulations.map((sim) => sim.uuid);
    setSelectedSimulations(allSimUUIDs);
  };

  const submit = async () => {
    const LoadingToast = toast.loading("Downloading Excel File");
    const res = await filePostRequest(
      `/react/api/${customer.uuid}/simulations/downloadSimActions`,
      accessToken,
      "SimulationActions.xlsx",
      { uuids: selectedSimulations }
    );
    if (res.status === 200) {
      toast.dismiss(LoadingToast);
      toast.success("Excel downloaded Successfully");
      setOpen(false);
    } else {
      toast.dismiss(LoadingToast);
      toast.error("Failed to Download Excel");
    }
  };

  const renderSelectedValue = (selected) => {
    if (selected.length === simulations.length) {
      return <Alert severity="info">All sims selected</Alert>;
    }
    const selectedNames = selected.map((uuid) => {
      const simulation = simulations.find((sim) => sim.uuid === uuid);
      return simulation ? simulation.name : uuid;
    });

    const displayText = selectedNames.join(", ");
    return displayText;
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" sx={{mb: 2}}>Simulation Actions Download</Typography>
      <FormControl fullWidth>
        <InputLabel id="simulations-select-label">Simulations</InputLabel>
        <Select
          labelId="simulations-select-label"
          multiple
          value={selectedSimulations}
          onChange={handleChange}
          renderValue={renderSelectedValue}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * MAX_DISPLAY_ITEMS + 8, // Adjust height (48px per item + padding)
                width: 250,
              },
            },
          }}
        >
          {simulations.map((sim) => (
            <MenuItem key={sim.uuid} value={sim.uuid}>
              <Checkbox checked={selectedSimulations.indexOf(sim.uuid) > -1} />
              <ListItemText primary={sim.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box sx={{ mt: 2 }}>
        <Button variant="outlined" onClick={handleSelectAll}>
          Select All
        </Button>
        <Button
          disabled={selectedSimulations.length === 0}
          variant="contained"
          onClick={submit}
          sx={{ ml: 2 }}
        >
          Download Excel
        </Button>
      </Box>
    </Box>
  );
};

export default DownloadSimActions;
