import React from "react";
import { PropTypes } from "prop-types";
import { Paper, Box } from "@mui/material";

/**
 * This Component serves the CalendarWithStyles Componenet as a
 * wrapper to optionally use either Paper or Box from MUI as a Parent container.
 * @param {*} props
 * @returns
 */
const Container = (props) => {
  const sx = props.sx;
  if (props.paper) {
    return <Paper sx={sx}>{props.children}</Paper>;
  } else {
    return <Box sx={sx}>{props.children}</Box>;
  }
};

Container.propTypes = {
  paper: PropTypes.bool,
};

export default Container;
