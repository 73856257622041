import React, { useEffect, useState, useContext } from "react";
import { Alert } from "@mui/material";
import { getRequest } from "../../Helpers/httpRequests";
import { DataContext } from "../../Context/dataContext";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../Redux/app/hooks";

const OutdatedAgentAlert = () => {
  const { accessToken } = useContext(DataContext);
  const [open, setOpen] = useState(false);
  const [agentString, setAgentString] = useState("");
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  useEffect(() => {
    const getOutdated = async () => {
      const res = await getRequest(`/react/api/${current_customer.uuid}/check-agents`, accessToken);
      if (res.status === 200) {
        let agents = res.data.agents || [];
        if (agents.length > 0) {
          agents = agents.join(", ");
          setAgentString(agents);
          setOpen(true);
        }
      }
    };

    getOutdated();
  }, [accessToken, current_customer]);
  return (
    open && (
      <Alert severity="info" variant="outlined" onClose={() => setOpen(false)}>
        You have outdated agents! <b>({agentString})</b> Click{" "}
        <Link
          to={`/${current_customer.uuid}/simulations/endpoints/download-agents`}
        >
          here
        </Link>
        to create new ones.
      </Alert>
    )
  );
};

export default OutdatedAgentAlert;
