import Recommendations from "../../Pages/Reports/Recommendations/Recommendations";
import ReportsTemplates from "../../Pages/Reports/ReportsTemplates/ReportsTemplates";
import TemplatesAddForm from "../../Components/Forms/Reporting/TemplatesAddForm";
import TemplatesEditForm from "../../Components/Forms/Reporting/TemplatesEditForm";
import ReportsEndpoints from "../../Pages/Reports/ReportsEndpoints/ReportsEndpoints";
import ReportsEndpointsDetails from "../../Pages/Reports/ReportsEndpointsDetails/ReportsEndpointsDetails";
import ReportsCampaignDetails from "../../Pages/Reports/ReportsCampaignDetails/ReportsCampaignDetails";
import { Routes, Route } from "react-router-dom";
import AttackMatrix from "../../Pages/Reports/AttackMatrix/AttackMatrix";
import AttackTechnique from "../../Pages/Reports/AttackTechnique/AttackTechnique";
import ReportTrends from "../../Pages/Reports/ReportTrends/ReportTrends";

import Dashboard from "../../Pages/Reports/Dashboard/Dashboard";

import ReportsFindingsDetails from "../../Pages/Reports/ReportsFindingsDetails/ReportsFindingsDetails";
import ReportCharts from "../../Pages/Reports/ReportCharts/ReportCharts";
import CampaignsUsage from "../../Pages/Simulations/Campaigns/CampaignsUsage/CampaignsUsage";
import UploadC2Beacon from "../../Pages/Reports/UploadC2Beacon/UploadC2Beacon";

const ReportingRoutes = () => {
  return (
    <Routes>
      {/* <Route exact path="agents" element={<AgentProfileAddForm />}/> */}
      <Route exact path="/recommendations" element={<Recommendations />} />
      <Route exact path="/templates" element={<ReportsTemplates />} />
      <Route exact path="/templates/add" element={<TemplatesAddForm />} />
      <Route
        exact
        path="/templates/edit/:templateUUID"
        element={<TemplatesEditForm />}
      />
      <Route exact path="/endpoints" element={<ReportsEndpoints />} />
      <Route
        exact
        path="/endpoints/:endpointUUID"
        element={<ReportsEndpointsDetails />}
      />
      <Route
        exact
        path="/campaign/:executedUUID"
        element={<ReportsCampaignDetails />}
      />
      <Route
        exact
        path="/campaign/:executedUUID/uploadBeacon"
        element={<UploadC2Beacon />}
      />
      <Route exact path="/matrix" element={<AttackMatrix />} />
      <Route exact path="/matrix/:campaignUUID" element={<AttackMatrix />} />
      <Route
        exact
        path="/technique/:techniqueID"
        element={<AttackTechnique />}
      />
      <Route path="/dashboards" element={<Dashboard />} />
      <Route
        path="/finding/:executedID/:cachedID"
        element={<ReportsFindingsDetails />}
      />
      <Route path="/charts/:projectUUID" element={<ReportCharts />} />
      <Route path="/trends/" element={<ReportTrends />} />
      <Route path="/campaign/usage/" element={<CampaignsUsage />} />
    </Routes>
  );
};

export default ReportingRoutes;
