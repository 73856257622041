import { LoadingButton } from "@mui/lab";
import {
  Button,
  Divider,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { DataContext } from "../../../../Context/dataContext";
import {
  fileRequest,
  getRequest,
  postRequest,
} from "../../../../Helpers/httpRequests";
import CustomerAccessForm from "./CustomerAccessForm";
import SimulationDetailsForm from "./SimulationDetailsForm";
import SimulationReviewForm from "./SimulationReviewForm";
import { renderJsonStatus } from "./functions";
import { useAppSelector } from "../../../../Redux/app/hooks";

const AdminSimulationForm = ({ simUUID, setReload, setOpen, type }) => {
  // ----- CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );
  // ----- STATES -----
  const [categories, setCategories] = useState([]);
  const [jsonString, setJsonString] = useState("");
  const [platforms, setPlatforms] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    json_file: null,
    zip_file: null,
    customer_access: [],
    category: "",
    platform: "windows",
    default: false,
    alert_validation: false,
    smv_access: false,
  });
  const [checkingJson, setCheckingJson] = useState({
    message: "",
    invalidKeys: [],
  });

  /**
   * Used for handling the props/children of <LoadingButton>
   */
  const [loadingBtn, setLoadingBtn] = useState({
    loading: false,
    color: "primary",
    text: "Submit",
    helperText: "",
    helperTextColor: "green",
  });
  const pageFlow = [
    SimulationDetailsForm,
    CustomerAccessForm,
    SimulationReviewForm,
  ];
  const [formPage, setFormPage] = useState(0);
  const [nxtBtnDisabled, setNxtBtnDisabled] = useState(true);
  const CurrentPage = pageFlow[formPage];

  const steps = ["Simulation Details", "Customer Access", "Review and Submit"];

  // ---- Variables ------

  // ----- Functions -----
  const handleZIPupload = (e) => {
    setFormData((prev) => {
      let newObj = { ...prev };
      newObj["zip_file"] = e.target.files[0];
      return newObj;
    });
  };

  const handleJSONUpload = (event) => {
    setCheckingJson({ message: "validating JSON...", invalidKeys: [] });
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const jsonData = JSON.parse(e.target.result);
      try {
        const validFirstKeys = [
          "name",
          "internal_name",
          "desc",
          "actions",
          "modules",
          "files",
        ];
        const allFirstKeysValid = Object.keys(jsonData).every((key) =>
          validFirstKeys.includes(key)
        );
        const validSecondKeys = [
          "clipboard",
          "crypt",
          "dnslookup",
          "downloader",
          "embed",
          "exfilData",
          "file",
          "pingsweep",
          "portsweep",
          "privileges",
          "processes",
          "run",
          "screenshot",
          "services",
          "sleep",
          "sysinfo",
          "coffloader",
        ];
        const invalidSecondKeys = (jsonData.modules || []).filter(
          (key) => !validSecondKeys.includes(key)
        );
        const allSecondKeysValid = jsonData.modules.every((key) =>
          validSecondKeys.includes(key)
        );
        if (allFirstKeysValid && allSecondKeysValid) {
          setCheckingJson({ message: "JSON validated", invalidKeys: [] });
          setFormData((prev) => ({
            ...prev,
            json_file: file,
          }));
        } else {
          const invalidKeys = [
            ...(!allFirstKeysValid
              ? Object.keys(jsonData).filter(
                (key) => !validFirstKeys.includes(key)
              )
              : []),
            ...invalidSecondKeys,
          ];
          setCheckingJson({
            message: "Invalid JSON",
            invalidKeys: invalidKeys,
          });
        }
      } catch {
        setCheckingJson({ message: "Invalid JSON", invalidKeys: [] });
      }
    };
    reader.readAsText(file);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  /**
   * Handles the form submission.
   * @param {Event} e
   */
  const handleSubmitAdd = async (e) => {
    // prevent page reload on submit
    e.preventDefault();
    let jsonFileBase64 = null;
    let zipFileBase64 = null;

    if (formData.json_file !== null) {
      jsonFileBase64 = await toBase64(formData.json_file);
    }
    if (formData.zip_file !== null) {
      zipFileBase64 = await toBase64(formData.zip_file);
    }

    let formDataToSend = {
      ...formData,
      json_file: jsonFileBase64,
      zip_file: zipFileBase64,
    };

    // set submit btn to loading
    setLoadingBtn((prev) => ({ ...prev, loading: true }));
    toast.info("Attempting to Create Simulation");
    const res = await postRequest(
      "/react/api/admin/add/simulation",
      accessToken,
      formDataToSend,
      true
    );
    setLoadingBtn((prev) => ({ ...prev, loading: false }));

    // send form response back as 201 (created)
    if (res.status === 201) {
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Success",
        color: "success",
        helperText: "Success",
        helperTextColor: "#2e7d32", // green
      }));
      toast.success("Simulation created successfully");
      setOpen(false);
      setReload((current) => !current);

      /**
       * If this form is contained in a modal, you can pass the setOpen state to this component.
       * This will wait 1.5 seconds and then close the modal
       * Add 'open' state to this components parent useEffect dependencies array to have your page reload the data.
       */
    } else {
      toast.error("Failed to create simulation");
      // handle errors here
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Error - Try again",
        color: "error",
        helperText: "Form submission failed",
        helperTextColor: "#d32f2f", // red
      }));
      console.log(
        "%cerror AdminAddSimulationForm.jsx handleSubmit()",
        "color: red; display: block; width: 100%;",
        "Failed to submit form"
      );
    }
  };

  const handleNavigation = (direction) => {
    if (direction === "next") {
      setFormPage((prevPage) => prevPage + 1);
    } else if (direction === "prev") {
      setFormPage((prevPage) => prevPage - 1);
    }
  };

  const OpenJsonInNewTab = async (jsonString) => {
    const jsonBlob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(jsonBlob);
    window.open(url, "_blank");
    URL.revokeObjectURL(url);
  };

  const OpenJsonInNewTabWithDownload = async (jsonString, filename) => {
    const jsonBlob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(jsonBlob);
    // Create a temporary anchor element for downloading
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
  };

  const downloadFile = async (params) => {
    const res = await fileRequest(
      `/react/api/admin/simulation/download/${params}`,
      accessToken,
      `${formData.name}.zip`
    );
    if (res.status === 200) {
      return;
    }
  };

  const handleCategoryChange = (e) => {
    setFormData((prev) => ({ ...prev, category: e.target.value }));
  };

  const handlePlatformChange = (e) => {
    setFormData((prev) => ({ ...prev, platform: e.target.value }));
  };

  /**
   * Handles the form submission.
   * @param {Event} e
   */
  const handleSubmitEdit = async (e) => {
    // prevent page reload on submit
    e.preventDefault();
    const formDataToSend = {
      ...formData,
    };

    // set submit btn to loading
    setLoadingBtn((prev) => ({ ...prev, loading: true }));
    toast.info("Attempting to Edit Simulation");
    const res = await postRequest(
      `/react/api/admin/simulation/edit/${simUUID}`,
      accessToken,
      formDataToSend,
      true
    );
    setLoadingBtn((prev) => ({ ...prev, loading: false }));

    // send form response back as 201 (created)
    if (res.status === 200) {
      toast.success("Simulation editted successfully");
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Success",
        color: "success",
        helperText: "Success",
        helperTextColor: "#2e7d32", // green
      }));
      setOpen(false);
      setReload((current) => !current);
    } else {
      toast.error("Something went wrong");
      // handle errors here
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Error - Try again",
        color: "error",
        helperText: "Form submission failed",
        helperTextColor: "#d32f2f", // red
      }));
      console.log(
        "%cerror AdminAddSimulationForm.jsx handleSubmit()",
        "color: red; display: block; width: 100%;",
        "Failed to submit form"
      );
    }
  };

  useEffect(() => {
    const getData = async () => {
      let res;

      if (type === "add") {
        res = await getRequest("/react/api/admin/add/simulation", accessToken);
      } else {
        res = await getRequest(
          `/react/api/admin/simulation/edit/${simUUID}`,
          accessToken
        );
      }

      if (res.status === 200) {
        const data = res.data;
        setCategories(data.categories);
        setPlatforms(data.platforms);

        if (type === "edit") {
          const simulation = data.simulation;
          setJsonString(simulation.json_string);
          setFormData({
            name: simulation.name || "",
            description: simulation.description || "",
            json_file:
              simulation.json_string !== null
                ? simulation.name.substring(0, 10) + ".json"
                : null,
            zip_file:
              simulation.zip_file_string !== null
                ? simulation.name.substring(0, 10) + ".zip"
                : null,
            customer_access: simulation.customer_accesses || [],
            category: simulation.category.name || "",
            platform: simulation.platform || "windows",
            default: simulation.default,
            alert_validation: simulation.alert_validation,
            smv_access: simulation.smv_access,
          });
        }
      }
    };

    getData();
  }, [current_customer, accessToken, simUUID, type]);

  useEffect(() => {
    /**
     * Before allowing the user to click next, ensure that all required fields have been filled.
     */
    const validatePage = () => {
      return (
        formData.name !== "" &&
        formData.description !== "" &&
        formData.category !== "" &&
        formData.platform !== "" &&
        formData.json_file !== null
      );
    };

    setNxtBtnDisabled(!validatePage());
  }, [formData]);

  return (
    <Stack spacing={2}>
      {/* Form Header */}
      {type === "add" ? (
        <Typography variant="h5">Add Simulation</Typography>
      ) : (
        <Typography variant="h5">Edit {formData.name}</Typography>
      )}
      <Divider />
      <Stepper activeStep={formPage} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <form
        onSubmit={(e) =>
          type === "add" ? handleSubmitAdd(e) : handleSubmitEdit(e)
        }
      >
        <Stack spacing={2}>
          <CurrentPage
            formData={formData}
            setFormData={setFormData}
            jsonString={jsonString}
            categories={categories}
            platforms={platforms}
            simUUID={simUUID}
            OpenJsonInNewTab={OpenJsonInNewTab}
            OpenJsonInNewTabWithDownload={OpenJsonInNewTabWithDownload}
            downloadFile={downloadFile}
            handleCategoryChange={handleCategoryChange}
            handlePlatformChange={handlePlatformChange}
            type={type}
            checkingJson={checkingJson}
            setCheckingJson={setCheckingJson}
            handleJSONUpload={handleJSONUpload}
            renderJsonStatus={renderJsonStatus}
            handleZIPupload={handleZIPupload}
          />
          <Stack direction="row" spacing={2}>
            {/* Navigation Buttons */}
            {formPage > 0 && (
              <Button
                variant="contained"
                onClick={() => handleNavigation("prev")}
              >
                Prev
              </Button>
            )}
            {formPage < pageFlow.length - 1 && (
              <Button
                variant="contained"
                onClick={() => handleNavigation("next")}
                disabled={nxtBtnDisabled}
              >
                Next
              </Button>
            )}
            {formPage === pageFlow.length - 1 && (
              <LoadingButton
                loading={loadingBtn.loading}
                color={loadingBtn.color}
                type="submit"
                variant="contained"
              >
                {loadingBtn.text}
              </LoadingButton>
            )}
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

export default AdminSimulationForm;
