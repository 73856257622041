import React, { useState, useContext, useEffect } from "react";
import {
  Stack,
  TextField,
  Typography,
  Paper,
  Box,
  Breadcrumbs,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Link, useNavigate } from "react-router-dom";
import { DataContext } from "../../../../Context/dataContext";
import { postRequest } from "../../../../Helpers/httpRequests";
import { useAppSelector } from "../../../../Redux/app/hooks";

const TemplatesAddForm = () => {
  // ----- STATES && CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const user = useAppSelector((state) => state.user);
  const current_customer = useAppSelector(
    (state) => state.customer,
  );
  const [fileName, setFile] = useState();
  const [defaultTemplate, setDefaultTemplate] = useState(false);
  const [singleCampaign, setSingleCampaign] = useState(false);
  const [Universal, setUniversal] = useState(false);

  // Submit Btn
  const [submitBtnColor, setSubmitBtnColor] = useState("primary");
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [submitBtnText, setSubmitBtnText] = useState("Submit");
  const [errorMsg, setErrorMsg] = useState("");

  // ----- VARIABLES -----
  const navigate = useNavigate();

  // ----- FUNCTIONS -----
  const handleSubmit = async () => {
    setErrorMsg("");
    setSubmitBtnLoading(true);
    const data = {
      default_template: defaultTemplate,
      universal: Universal,
      file_name: fileName,
      singleCampaign: singleCampaign,
    };
    const res = await postRequest(
      `/react/api/${current_customer.uuid}/report/templates/add`,
      accessToken,
      data,
    );
    setSubmitBtnLoading(false);
    if (res.status === 200) {
      navigate(`/${current_customer.uuid}/reporting/templates`);
    } else {
      setSubmitBtnColor("error");
      setSubmitBtnText("Error - Try again");
      setErrorMsg(res.data.error);
    }
  };

  useEffect(() => {
    setSingleCampaign(current_customer.license_type === "consumption_smv");
  }, [current_customer]);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">Home</Link>
        <Link to={`/${current_customer.uuid}/reporting/templates`}>
          Templates
        </Link>
        <Typography color="text.primary">Add Project Report</Typography>
      </Breadcrumbs>

      <Typography fontWeight={"bold"} variant="h4">
        Add Project Report
      </Typography>

      <Box sx={{ display: "flex", width: "50%" }}>
        <Box
          className="left-box"
          sx={{ flex: 1, paddingRight: 1, marginTop: 1 }}
        >
          <Paper sx={{ padding: 2 }}>
            <Stack spacing={1.5}>
              {/* File Name */}
              <Typography component={"span"}>
                File Name
                <TextField
                  sx={{ marginLeft: 2 }}
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Typography>

              {/* SingleCampaign */}
              {user.superuser && (
                <Typography component={"span"}>
                  Single Campaign Template
                  <Checkbox
                    label="Single Campaign Template"
                    onChange={(e) => setSingleCampaign(e.target.checked)}
                    value={singleCampaign && "on"}
                    checked={singleCampaign}
                  />
                </Typography>
              )}
              {/* Default Template */}
              <Typography component={"span"}>
                Default Template
                <Checkbox
                  label="Default Template"
                  onChange={(e) => setDefaultTemplate(e.target.checked)}
                  value={defaultTemplate && "on"}
                  checked={defaultTemplate}
                />
              </Typography>

              {/* Universal */}
              {user.superuser && (
                <Typography component={"span"}>
                  Universal
                  <Checkbox
                    label="Universal"
                    onChange={(e) => setUniversal(e.target.checked)}
                    value={Universal && "on"}
                    checked={Universal}
                  />
                </Typography>
              )}
              {/* Loading Button */}
              <LoadingButton
                variant="contained"
                loading={submitBtnLoading}
                onClick={handleSubmit}
                color={submitBtnColor}
              >
                {submitBtnText}
              </LoadingButton>
              <Typography>{errorMsg}</Typography>
            </Stack>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default TemplatesAddForm;
