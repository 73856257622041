import React, { useEffect, useContext, useState } from "react";
import {
  Breadcrumbs,
  Typography,
  CircularProgress,
  Divider,
  Paper,
  Modal,
  Alert
} from "@mui/material";
import { Stack } from "@mui/system";
import { getRequest } from "../../../../Helpers/httpRequests";
import { DownloadReport } from "./DownloadReport";
import { PlextracProcess } from "./PlextracProcess";
import { ExecutiveSummary } from "./ExecutiveSummary";
import { AttackMatrixProject } from "./AttackMatrixProject";
import { SankeyProject } from "./SankeyProject";
import { Recommendations } from "./Recommendations";
import { Link, useParams } from "react-router-dom";
import { Box } from "@mui/system";
import TextButton from "../../../../Components/Buttons/TextButton/TextButton";
import { FaCog } from "react-icons/fa";
import { CampaignList } from "./CampaignList";
import ProjectReportSettings from "../../../../Components/Reporting/ProjectReportSettings/ProjectReportSettings";
import { DataContext } from "../../../../Context/dataContext";
import { DownloadReportJson } from "./DownloadProjectJson";
import { useAppSelector } from "../../../../Redux/app/hooks";

const ProjectReport = () => {
  const { projectUUID } = useParams();
  const role = useAppSelector((state) => state.user.role);

  // ----- Context -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector((state) => state.customer);

  // ----- States -----

  const [report, setReport] = useState({});
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [recommendations, setRecommendations] = useState({});
  const [executiveSummary, setExecutiveSummary] = useState({});

  // ----- Variable -----

  const modalStyle = {
    position: "absolute",
    bgcolor: "background.paper",
    top: "50%",
    left: "50%",
    width: "40%",
    maxWidth: 1000,
    maxHeight: "90%",
    transform: "translate(-25%, -50%)",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
  };

  // ----- Functions -----
  // ----- On page load -----

  useEffect(() => {
    const getReport = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/projectreport/${projectUUID}`,
        accessToken
      );

      if (res.status === 200) {
        setReport(res.data.report);
      }
    };
    getReport();
  }, [accessToken, current_customer, projectUUID]);

  useEffect(() => {
    const getExecutiveSummary = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/projectreport/${report.uuid}/summary`,
        accessToken
      );
      if (res.status === 200) {
        setExecutiveSummary(res.data.summary);
      }
    };

    if (report.uuid) {
      getExecutiveSummary();
    }
  }, [accessToken, report, current_customer]);
  return (
    <Stack spacing={3}>
      {/* Breadcrumbs */}
      {
        // name check is really if the report has loaded in
        report.name ? (
          <div>
            {!report?.template?.name && (
              <>
              <Alert severity="warning">Please select a template from the project settings to access report download !! </Alert>
              </>
            )}
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="text.primary">Simulations</Typography>
              <Link
                to={`/${current_customer.uuid}/simulations/campaigns/projects/list`}
              >
                Projects
              </Link>
              <Link
                to={`/${current_customer.uuid}/simulations/campaigns/projects/${projectUUID}`}
              >
                {report.name}
              </Link>
              <Typography color="text.primary">Project Report</Typography>
            </Breadcrumbs>
            {/* Page Title */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <Typography variant="h4">{report.name}</Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexDirection: "row",
                }}
              >
                <TextButton
                  role={role}
                  tooltip="Report Settings"
                  icon={FaCog}
                  onClick={() => setOpenSettingsModal(true)}
                />
                <DownloadReportJson uuid={report.uuid} name={report.name} />
                <DownloadReport
                  uuid={report.uuid}
                  name={report.name}
                  report={report}
                  executiveSummary={executiveSummary}
                />
                <PlextracProcess uuid={report.uuid} />
              </Box>
            </Box>

            <ExecutiveSummary
              executiveSummary={executiveSummary}
              report={report}
              setExecutiveSummary={setExecutiveSummary}
            />
            <Divider />
            <AttackMatrixProject uuid={projectUUID} />
            <Divider />
            <SankeyProject uuid={projectUUID} />
            <Divider />
            <Recommendations
              uuidReport={report.uuid}
              superSetRecs={setRecommendations}
            />
            <Divider />
            <CampaignList
              uuid={report.uuid}
              recommendations={recommendations}
            />
            <Modal
              open={openSettingsModal}
              onClose={() => setOpenSettingsModal(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Paper sx={modalStyle} spacing={2}>
                {report ? (
                  <ProjectReportSettings
                    projectReport={report}
                    executiveSummary={executiveSummary}
                    setSuperReport={setReport}
                    setSuperSummary={setExecutiveSummary}
                    setSuperClose={setOpenSettingsModal}
                  />
                ) : null}
              </Paper>
            </Modal>
          </div>
        ) : (
          <CircularProgress />
        )
      }
    </Stack>
  );
};

export default ProjectReport;
