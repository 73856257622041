import React, { useEffect, useContext, useState } from "react";

import { postRequest } from "../../../../Helpers/httpRequests";
import {
  Divider,
  Stack,
  Typography,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataContext } from "../../../../Context/dataContext";
import PropTypes from "prop-types";
import { useAppSelector } from "../../../../Redux/app/hooks";

const RecommendationForm = ({ setOpen, recommendation, modal = true }) => {
  // ----- CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const user = useAppSelector((state) => state.user);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  // ----- STATES -----

  /**
   * Object to hold the values of each input in form.
   */
  const [data, setFormData] = useState({
    // initialize form values here
    name: "",
    description: "",
    remediation: "",
    score: "low",
    universal: false,
    is_author: true,
    screenshot: "",
    screenshot_name: "",
  });

  /**
   * Used for handling the props/children of <LoadingButton>
   */
  const [loadingBtn, setLoadingBtn] = useState({
    loading: false,
    color: "primary",
    text: "Submit",
    helperText: "",
    helperTextColor: "green",
  });

  // ---- Variables ------

  const scores = ["low", "medium", "high", "critical"];

  /**
   * These are the styles for the Box inside of the modal.
   * The Modal component renders as position Absolute so we
   * have to write styles accordingly
   *
   */
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };

  // ----- Functions -----

  /**
   * Handles the form submission.
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    // prevent page reload on submit
    e.preventDefault();

    // set submit btn to loading
    setLoadingBtn((prev) => ({ ...prev, loading: true }));
    const res = await postRequest(
      `/react/api/${current_customer.uuid}/report/recommendation/edit`,
      accessToken,
      data,
      false
    );
    setLoadingBtn((prev) => ({ ...prev, loading: false }));

    // send form response back as 200
    if (res.status === 200) {
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Success",
        color: "success",
        helperText: "Success",
        helperTextColor: "#2e7d32", // green
      }));

      /**
       * If this form is contained in a modal, you can pass the setOpen state to this component.
       * This will wait 1.5 seconds and then close the modal
       * Add 'open' state to this components parent useEffect dependencies array to have your page reload the data.
       */
      if (setOpen !== undefined) {
        setTimeout(() => setOpen(res.data), 1500);
      }
    } else {
      // handle errors here
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Error - Try again",
        color: "error",
        helperText: "Form submission failed",
        helperTextColor: "#d32f2f", // red
      }));
      console.log(
        "%cerror RecommendationForm.jsx handleSubmit()",
        "color: red; display: block; width: 100%;",
        "Failed to submit form"
      );
    }
  };

  // ----- On page load -----
  useEffect(() => {
    const getData = () => {
      if (recommendation) {
        setFormData({ ...recommendation });
      }
    };

    getData();
  }, [current_customer, accessToken, recommendation]);

  return (
    <Box sx={modal ? style : null}>
      {/* Form Header */}
      <Typography variant="h5">
        {recommendation ? "Edit Recommendation" : "New Recommendation"}
      </Typography>

      <Divider style={{ marginBottom: "0.5rem" }} />

      {/* FORM */}
      <form onSubmit={(e) => handleSubmit(e)}>
        <Stack direction="column" spacing={2}>
          {/* Start form fields here */}

          {/* Name */}
          <TextField
            required
            value={data.name}
            onChange={(e) => setFormData({ ...data, name: e.target.value })}
            label="Name"
          />

          {/* Description */}
          <TextField
            value={data.description}
            onChange={(e) =>
              setFormData({ ...data, description: e.target.value })
            }
            label="Description"
            multiline
          />

          {/* Remediation */}
          <TextField
            value={data.remediation}
            onChange={(e) =>
              setFormData({ ...data, remediation: e.target.value })
            }
            label="Remediation"
            multiline
          />

          {/* Score */}
          <FormControl fullWidth>
            <InputLabel>Score</InputLabel>
            <Select
              value={data.score}
              onChange={(e) => setFormData({ ...data, score: e.target.value })}
              label="Score"
            >
              {scores.map((score) => {
                return (
                  <MenuItem value={score} key={score}>
                    {score.charAt(0).toUpperCase() + score.slice(1)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* Screenshot */}
          <Typography component={"span"}>
            Screenshot
            <TextField
              sx={{ marginLeft: 2 }}
              type="file"
              onChange={(e) =>
                setFormData({ ...data, screenshot: e.target.files[0] })
              }
            />
          </Typography>

          {/* Universal */}
          {user.superuser && (
            <FormControl fullWidth>
              <InputLabel>Universal</InputLabel>
              <Checkbox
                label="Universal"
                checked={data.universal}
                onChange={() =>
                  setFormData({ ...data, universal: !data.universal })
                }
              />
            </FormControl>
          )}

          {/* End form fields */}

          {/* helper text */}
          <Typography color={loadingBtn.helperTextColor} variant="subtitle2">
            {loadingBtn.helperText}
          </Typography>

          {/* Submit Button */}
          <LoadingButton
            loading={loadingBtn.loading}
            color={loadingBtn.color}
            type="submit"
            variant="contained"
          >
            {loadingBtn.text}
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
};

RecommendationForm.propTypes = {
  setOpen: PropTypes.func,
  modal: PropTypes.bool,
};

export default RecommendationForm;
