import React, { useEffect, useContext, useState } from "react";
import { getRequest } from "../../../Helpers/httpRequests";
import {
  Breadcrumbs,
  Stack,
  Typography,
  IconButton,
  Box,
  Tab,
  Tabs,
  Button,
} from "@mui/material";
import { FaPlus, FaPowerOff, FaCheck, FaTimes } from "react-icons/fa";
import { DataContext } from "../../../Context/dataContext";
import FormModal from "../../../Components/Modals/FormModal";
import { toast } from "react-toastify";
import { COLORS } from "../../../Styles/colors";
import AdminEditCustomer from "../../../Components/Forms/Administration/AdminEditCustomer.jsx";
import AdminAddCustomerForm from "../../../Components/Forms/Administration/AdminAddCustomerForm";
import TextButton from "../../../Components/Buttons/TextButton";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import { useAppSelector } from "../../../Redux/app/hooks";

const AdminCustomers = () => {
  // ----- STATES && CONTEXT -----
  const [dataTableRows, setDataTableRows] = useState([]);
  const [dataTableColumns, setDataTableColumns] = useState([]);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [cxUUID, setCxUUID] = useState("");
  const [activeTab, setActiveTab] = useState("active"); // Default to "active" tab

  // Contexts
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer,
  );

  // ----- FUNCTIONS -----

  // ----- VARIABLES -----

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    /**
     * Disa functeon issa responsible per taken an array of
     * customer objects and createn da rows and columns
     * per da data grid
     * @param {Object[]} customers
     */
    const populateDataGrid = (customers) => {
      const columns = [
        {
          field: "name",
          headerName: "Name",
          maxWidth: 250,
          flex: 1,
          renderCell: (params) => {
            return (
              <Button
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {params.row.name}
              </Button>
            );
          },
        },
        {
          field: "display_name",
          headerName: "Display Name",
          maxWidth: 250,
          flex: 1,
        },
        {
          field: "poc",
          headerName: "POC",
          maxWidth: 250,
          flex: 1,
        },
        {
          field: "contract_reference_information",
          headerName: "Contact Reference Information",
          maxWidth: 250,
          flex: 1,
        },
        {
          field: "license_type",
          headerName: "License Type",
          maxWidth: 250,
          flex: 1,
        },
        {
          field: "users",
          headerName: "Users",
          maxWidth: 100,
          flex: 1,
        },
        {
          field: "av",
          headerName: "Alert Validation",
          maxWidth: 120,
          flex: 1,
          renderCell: (params) => {
            return (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {params.row.av ? (
                  <FaCheck size={30} color="green" />
                ) : (
                  <FaTimes size={30} color="red" />
                )}
              </Box>
            );
          },
        },
        {
          field: "smv",
          headerName: "SMV",
          maxWidth: 50,
          flex: 2,
          renderCell: (params) => {
            return (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {params.row.license_type === "consumption_smv" ? (
                  <FaCheck size={30} color="green" />
                ) : (
                  <FaTimes size={30} color="red" />
                )}
              </Box>
            );
          },
        },
        {
          field: "actions",
          headerName: "Active",
          maxWidth: 60,
          flex: 0,
          renderCell: (params) => {
            const changeActive = async () => {
              const uuid = params.row.id;
              if (!params.row.active) {
                const res = await toast.promise(
                  getRequest(
                    `/react/api/admin/customer-activate/${uuid}`,
                    accessToken
                  ),
                  {
                    pending: "Activating Customer",
                    success: `${params.row.name} Activated`,
                    error: "Something went wrong!",
                  }
                );
                if (res.status === 200) {
                  params.row.active = true;
                  document.getElementById(
                    `power-icon-${params.row.id}`
                  ).style.color = COLORS.success.main;
                }
              } else {
                const res = await toast.promise(
                  getRequest(
                    `/react/api/admin/customer-deactivate/${uuid}`,
                    accessToken
                  ),
                  {
                    pending: "De-activating Customer",
                    success: `${params.row.name} De-activated`,
                    error: "Something went wrong!",
                  }
                );
                if (res.status === 200) {
                  params.row.active = false;
                  document.getElementById(
                    `power-icon-${params.row.id}`
                  ).style.color = COLORS.error.main;
                }
              }
            };

            const onClick = (e) => {
              e.stopPropagation();
              changeActive();
            };

            return (
              <Box sx={{ width: "100%", textAlign: "center" }}>
                <IconButton onClick={onClick}>
                  <FaPowerOff
                    id={`power-icon-${params.row.id}`}
                    color={
                      params.row.active
                        ? COLORS.success.main
                        : COLORS.error.main
                    }
                  />
                </IconButton>
              </Box>
            );
          },
        },
      ];

      const rows = customers.map((cx) => {
        return {
          id: cx.uuid,
          name: cx.name,
          display_name: cx.display_name,
          license_type: cx.license_type,
          poc: cx.poc_name,
          users: cx.users,
          active: cx.active,
          av: cx.features?.av,
          customer_type: cx.license_type,
          contract_reference_information: cx.contract_reference_information,
        };
      });

      setDataTableRows(rows);
      setDataTableColumns(columns);
    };
    const getAlerts = async () => {
      const res = await getRequest("/react/api/admin-customers", accessToken);
      populateDataGrid(res.data.customers);
    };

    getAlerts();
  }, [accessToken, current_customer, open, open2]);

  // Filter active and inactive customers based on the activeTab state
  const filteredDataTableRows = dataTableRows.filter(
    (row) =>
      (activeTab === "active" && row.active) ||
      (activeTab === "inactive" && !row.active) ||
      (activeTab === "all" && row)
  );

  return (
    <>
      <Stack spacing={1}>
        {/* Breadcrumbs */}
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="text.primary">Administration</Typography>
          <Typography color="text.primary">Customers</Typography>
        </Breadcrumbs>

        {/* Page Title  && Buttons */}
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography variant="h3">Customer</Typography>
          <TextButton
            tooltip="Add Customer"
            icon={FaPlus}
            onClick={() => setOpen2(true)}
          />
        </Stack>

        {/* Tabs for filtering active and inactive customers */}
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => {
            setActiveTab(newValue);
          }}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="All" value="all" />
          <Tab label="Active" value="active" />
          <Tab label="Inactive" value="inactive" />
        </Tabs>
        {/* DataGrid */}
        <DataGridWithStyles
          name="admin-customers"
          autoHeight
          pointer
          paper
          rows={filteredDataTableRows}
          columns={dataTableColumns}
          onRowClick={(params) => {
            setCxUUID(params.row.id);
            setOpen(true);
          }}
        />
      </Stack>
      <FormModal
        open={open}
        setOpen={setOpen}
        sx={{
          maxHeight: "95%",
          overflowY: "auto",
          overflowX: "hidden",
          minWidth: 700,
        }}
      >
        {cxUUID !== "" && <AdminEditCustomer cxUUID={cxUUID} />}
      </FormModal>
      <FormModal
        open={open2}
        setOpen={setOpen2}
        sx={{
          maxHeight: "95%",
          overflowY: "auto",
          overflowX: "hidden",
          minWidth: 700,
        }}
      >
        <AdminAddCustomerForm setOpen={setOpen2} />
      </FormModal>
    </>
  );
};

export default AdminCustomers;
