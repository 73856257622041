const dark = {
  // Removes Cell outline on click
  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
  ".even": {
    backgroundColor: "rgba(255,255,255,0.05)",
  },
};

const darkPointer = {
  // Removes Cell outline on click
  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
  "&.MuiDataGrid-root .MuiDataGrid-row:hover": {
    cursor: "pointer",
  },
  ".even": {
    backgroundColor: "rgba(255,255,255,0.05)",
  },
};

const light = {
  // Removes Cell outline on click
  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
    outline: "none !important",
  },
  ".even": {
    backgroundColor: "#F3F7F9",
  },
};

const lightPointer = {
  // Removes Cell outline on click
  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
    outline: "none !important",
  },
  "&.MuiDataGrid-root .MuiDataGrid-row:hover": {
    cursor: "pointer",
  },
  ".even": {
    backgroundColor: "#F3F7F9",
  },
};

export const dataGridStyles = {
  light: light,
  dark: dark,
  lightPointer: lightPointer,
  darkPointer: darkPointer,
};
