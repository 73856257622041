import { getRequest, postRequest } from "../../../Helpers/httpRequests";
import { toast } from "react-toastify";

// generate an array of [[simuuid, simname],...]
const generateOptions = (items) => {
  return items.map((item) => {
    return [item.uuid, item.name];
  });
};

/**
 * Retrieves the available simulatinos to the customer that are marked for
 * alert validations
 * @param {Object} args
 */
export const getSimulations = async (args) => {
  // decontruct our args
  const { accessToken, current_customer, setSimulations } = args;

  // retrieve simulations
  const res = await getRequest(`/react/api/${current_customer.uuid}/get-av-simulations`, accessToken);
  const simData = res.data.simulations;

  // set the state of simulations
  if (res.status === 200) {
    const simOptions = generateOptions(simData);
    setSimulations(simOptions);
  }
};

/**
 * Handles the retrieval of available security tool options for the form select
 * @param {Object} args
 */
export const getSecurityTools = async (args) => {
  // deconstruct args
  const { accessToken, setSecurityTools, current_customer } = args;

  // retrieve security tools available to customer
  const res = await getRequest(`/react/api/${current_customer.uuid}/get-security-tools`, accessToken);
  const secTools = res.data.securityTools;

  // set the state of simulations
  if (res.status === 200) {
    const securityToolOptions = generateOptions(secTools);
    setSecurityTools(securityToolOptions);
  }
};

/**
 * Submits the expect alert creation form using values from formData
 * @param {Object} args
 */
export const handleFormSubmit = async (args) => {
  const { accessToken, formData, current_customer } = args;
  await toast.promise(
    postRequest(
      `/react/api/${current_customer.uuid}/alertvalidation/create-expected-alert`,
      accessToken,
      formData,
      true
    ),
    {
      pending: "Success",
      success: `'${formData.name}' created Successfully!`,
      error: "Something went wrong!",
    }
  );
};
