import React, { useContext, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  Chip,
  Paper,
  Stack,
  Button,
} from "@mui/material";
import { COLORS } from "../../../Styles/colors";
import { genericPostRequest } from "../../../Helpers/httpRequests";
import { DataContext } from "../../../Context/dataContext";
import { AVDetailsContext } from "./ContextProvider/ContextProvider";
import { TextButton } from "../../../Components";
import { FaPlus } from "react-icons/fa";
import { useAppSelector } from "../../../Redux/app/hooks";

const AdditionalAlerts = (props) => {
  const { additionalAlerts, av_campaign } = props || [];
  const { accessToken } = useContext(DataContext);
  const { alertID, reloadPage, setReloadPage } = useContext(AVDetailsContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  const [expanded, setExpanded] = useState(false);

  // handles the opening and closing of each finding
  const handleChange = (panel) => (_event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const chipStyle = {
    marginLeft: "0.5rem",
    minWidth: "auto",
    padding: "0.5rem",
    borderRadius: "4px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: COLORS.error.dark,
    color: COLORS.error.text,
  };

  const parseURL = (url) => {
    if (url?.startsWith("https://security.microsoft.com")) return "Defender for Endpoint";
    if (url?.includes("crowdstrike.com")) return "CrowdStrike";
    if (url?.includes("sentinelone")) return "SentinelOne";
    return "Microsoft Sentinel";
  };

  const addExpectedAlerts = async (e, index = null) => {
    let alerts = [];
    // alerts by default is going to be an array of the uuids of all the additional alerts
    additionalAlerts.map((al) => alerts.push(al.uuid));
    if (index !== null) {
      // if an index is passed in, then we just make the array be only that uuid
      alerts = [alerts[index]];
    }
    const res = await genericPostRequest(
      `/react/api/${current_customer.uuid}/alertvalidation/alert/expected/add`,
      accessToken,
      {
        alert_id: alertID,
        expected_alert_ids: alerts,
        av_campaign_uuid: av_campaign.uuid,
      },
      "true"
    );
    if (res.status === 200) {
      // I tried setting this the right way but doing so essentially requires me to pull in
      // all the data required for the page to begin with
      // so just for simplicity I'm just using the already existing reloadpage state
      // the process needs to be reworked in the backend
      setReloadPage(!reloadPage);
      setExpanded(false);
    }
  };

  return (
    <Paper sx={{ p: 3 }}>
      <Stack spacing={2}>
        <Stack
          direction={"row"}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {" "}
            Additional Alerts
          </Typography>
          <Button onClick={addExpectedAlerts}>
            Add all Additional Alerts to be Expected
          </Button>
        </Stack>
        {additionalAlerts.length > 0 ? (
          additionalAlerts.map((alert, index) => {
            let alertLink = parseURL(alert.alerturl || "");
            let sentinelLink = parseURL(alert.incident_url || "");
            return (
              <Box key={index}>
                <Accordion
                  expanded={expanded === `panel${index}`}
                  onChange={handleChange(`panel${index}`)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}bh-content`}
                    id={`panel${index}bh-header`}
                    sx={{ flexDirection: "row-reverse", alignItems: "center" }}
                  >
                    <Box
                      sx={{
                        width: "70%",
                        flexGrow: 1,
                        overflowWrap: "break-word",
                      }}
                    >
                      {alert.alert_title} - {alert.alert_id}
                      {alert.alerturl.includes("crowdstrike.com") && (
                        <Chip sx={chipStyle} label="CrowdStrike" />
                      )}
                      {alert.alerturl.includes("microsoft.com") && (
                        <Chip sx={chipStyle} label="Defender" />
                      )}
                      {alert.alerturl.includes("sentinelone") && (
                        <Chip sx={chipStyle} label="SentinelOne" />
                      )}
                      {/* {!alert.alerturl && alert.incident_url && (
                        <Chip sx={chipStyle} label="Sentinel Only" />
                      )} */}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Divider light sx={{ mb: 1 }} />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        maxWidth: "20rem",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                      >
                        View Alert In
                      </Typography>
                      {alert.alerturl.length > 0 && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            maxWidth: "20rem",
                          }}
                        >
                          <Typography
                            key={`evidence-url-${alert.alert_id}-${index}`}
                          >
                            <span
                              key={`evidence-span-${alert.alert_id}-${index}`}
                              style={{
                                color: COLORS.primary.main,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                window.open(alert.alerturl, "_blank")
                              }
                            >
                              {alertLink}
                            </span>
                          </Typography>
                        </Box>
                      )}
                      {alert.incident_url && alert.incident_url.length > 0 && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            maxWidth: "20rem",
                          }}
                        >
                          <Typography
                            key={`evidence-url-${alert.incident_id}-${index}`}
                          >
                            <span
                              key={`evidence-span-${alert.incident_id}-${index}`}
                              style={{
                                color: COLORS.primary.main,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                window.open(alert.incident_url, "_blank")
                              }
                            >
                              {sentinelLink}
                            </span>
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        maxWidth: "20rem",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                      >
                        Detection Source:
                      </Typography>
                      <Typography>{alert.detection_source}</Typography>
                    </Box>
                    <TextButton
                      icon={FaPlus}
                      tooltip="Add to Expected Alerts"
                      onClick={(e) => {
                        addExpectedAlerts(e, index);
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              </Box>
            );
          })
        ) : (
          <Typography>No additional alerts found.</Typography>
        )}
      </Stack>
    </Paper>
  );
};

export default AdditionalAlerts;
