import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/user/userSlice";
import customerReducer from "../features/customer/customerSlice";
import darkmodeReducer from "../features/darkmode/darkmodeSlice";
import accessTokenReducer from "../features/accessTokenSlice/accesstokenSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    customer: customerReducer,
    darkmode: darkmodeReducer,
    accessToken: accessTokenReducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
