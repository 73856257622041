import { useEffect } from "react";
import { useHttpRequest } from "../../../Hooks";
import { Stack, Button, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export const DeleteModal = ({ data, onClose }) => {
  const { fetch, loading, status } = useHttpRequest({
    method: "DELETE",
    path: `/api/v2/process_name/${data.id}`,
    defer: true,
  });

  useEffect(() => {
    if (status === 204) {
      onClose();
    }
  }, [status, onClose]);

  return (
    <Stack spacing={2}>
      <Typography variant="h5">Delete Process Name</Typography>
      <Typography>Are you sure you want to delete {data.name}?</Typography>
      <Stack spacing={2} direction="row" sx={{ justifyContent: "center" }}>
        <LoadingButton
          onClick={() => {
            fetch();
          }}
          loading={loading}
          disabled={loading}
          variant="contained"
        >
          Submit
        </LoadingButton>
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};
