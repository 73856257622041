import React from "react";
import { Box, Typography } from "@mui/material";

const Description = (props) => {
  const styles = {
    width: `${props.split[1]}%` || "75%",
  };
  return (
    <Box sx={styles} direction="row">
      <Typography display="inline" variant="h6" fontWeight="bold">
        {props.label}:{" "}
      </Typography>
      <Typography display="inline">{props.description}</Typography>
    </Box>
  );
};

export default Description;
