import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import {
  FaBell,
  FaBook,
  FaCog,
  FaExclamationTriangle,
  FaWrench,
} from "react-icons/fa";
import { LinkWithCustomerUUID } from "../../../Components/Links";
import { useAppSelector } from "../../../Redux/app/hooks";

const iconColor = "#BDCCD4";
const iconSize = "16px";

const AlertValidation = ({ open }) => {
  const current_customer = useAppSelector((state) => state.customer);
  const data = {
    navHeader: "ALERT VALIDATION",
    links: [
      {
        icon: <FaWrench color={iconColor} size={iconSize} />,
        text: "Overview",
        to: "/alertvalidation/overview",
      },
      {
        icon: <FaExclamationTriangle color={iconColor} size={iconSize} />,
        text: "Scheduled Exercises",
        to: "/alertvalidation/scheduled-exercises",
      },
      {
        icon: <FaCog color={iconColor} size={iconSize} />,
        text: "Config",
        to: "/alertvalidation/config",
      },
      // {
      //   icon: <FaDownload color={iconColor} size={iconSize} />,
      //   text: "Service Manager",
      //   to: "/alertvalidation/service-manager",
      // },
      {
        icon: <FaBook color={iconColor} size={iconSize} />,
        text: "Exercise Library",
        to: "/alertvalidation/library",
      },
      {
        icon: <FaBell color={iconColor} size={iconSize} />,
        text: "Notifications",
        to: "/alertvalidation/settings",
      },
    ],
  };
  if (!current_customer.features.av) {
    return null;
  }
  return (
    <div>
      {open ? (
        <Typography
          variant="h6"
          px={1}
          mt={3}
          sx={{ fontSize: 10, color: "#BDCCD4" }}
        >
          {data.navHeader}
        </Typography>
      ) : null}
      {data.links.map((link) => {
        return (
          <ListItem key={link.text} disablePadding sx={{ display: "block" }}>
            <LinkWithCustomerUUID
              to={link.to}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 32,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {link.icon}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "12px" }}
                  primary={link.text}
                  sx={{ opacity: open ? 1 : 0, color: "white" }}
                />
              </ListItemButton>
            </LinkWithCustomerUUID>
          </ListItem>
        );
      })}
    </div>
  );
};

export default AlertValidation;
