import React from "react";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  FaBell,
  FaDownload,
  FaBook,
  FaCog,
  FaExclamationTriangle,
  FaWrench,
  FaLaptop,
  FaShieldAlt,
} from "react-icons/fa";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { useAppSelector } from "../../../Redux/app/hooks";

const iconColor = "#BDCCD4";
const iconSize = "16px";

const AlertNav = ({ open }) => {
  const current_customer = useAppSelector((state) => state.customer);
  const data = {
    navHeader: "ALERT VALIDATION",
    links: [
      {
        icon: <FaWrench color={iconColor} size={iconSize} />,
        text: "Overview",
        to: "/alertvalidation/overview", // Use 'to' instead of 'href' for <Link>
      },
      {
        icon: <FaExclamationTriangle color={iconColor} size={iconSize} />,
        text: "Scheduled Exercises",
        to: "/alertvalidation/scheduled-exercises",
      },
      {
        icon: <FaCog color={iconColor} size={iconSize} />,
        text: "Security Tool Integrations",
        to: "/alertvalidation/config",
      },
      {
        icon: <FaDownload color={iconColor} size={iconSize} />,
        text: "Service Manager",
        to: "/alertvalidation/service-manager",
      },
      {
        icon: <FaBook color={iconColor} size={iconSize} />,
        text: "Exercise Library",
        to: "/alertvalidation/library",
      },
      {
        icon: <FaBell color={iconColor} size={iconSize} />,
        text: "Notifications",
        to: "/alertvalidation/settings",
      },
      {
        icon: <FaLaptop color={iconColor} size={iconSize} />,
        text: "Endpoints",
        to: "simulations/endpoints/list",
      },
      {
        icon: <FaShieldAlt color={iconColor} size={iconSize} />,
        text: "Security Tools",
        to: "/config/securitytools",
      },
    ],
  };

  if (!current_customer.features.av) {
    return null;
  }

  return (
    <div>
      {open ? (
        <Typography
          variant="h6"
          px={1}
          mt={3}
          sx={{ fontSize: 10, color: "#BDCCD4" }}
        >
          {data.navHeader}
        </Typography>
      ) : null}
      {data.links.map((link) => (
        <ListItem key={link.text} disablePadding sx={{ display: "block" }}>
          <Link
            to={`/${current_customer.uuid}${link.to}`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <ListItemButton
              sx={{
                minHeight: 32,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {link.icon}
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontSize: "12px" }}
                primary={link.text}
                sx={{ opacity: open ? 1 : 0, color: "white" }}
              />
            </ListItemButton>
          </Link>
        </ListItem>
      ))}
    </div>
  );
};

export default AlertNav;
