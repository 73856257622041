import { Breadcrumbs, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { COLORS } from "../../../Styles/colors";
import TextLink from "../../../Components/TextLink/TextLink";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../Redux/app/hooks";

const ScoreCampaign = () => {
  const current_customer = useAppSelector(
    (state) => state.customer);
  // ----- Context -----

  // ----- States -----

  // ----- Variable -----

  // ----- Functions -----

  // ----- On page load -----

  return (
    <Stack spacing={3}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Link url={`/${current_customer.uuid}/support/help`}>Support</Link>
        <Typography color="text.primary">Score Campaign</Typography>
      </Breadcrumbs>

      {/* Page Title */}
      <Typography variant="h3">Scoring a Campaign with BlindSPOT</Typography>
      <div>
        <ol>
          <li>
            <div>
              Login to the BlindSPOT platform, using your given credentials.
            </div>
          </li>
          <li>
            <div>
              Click the{" "}
              <TextLink display="inline" url="/simulations/campaigns/list">
                Campaigns
              </TextLink>{" "}
              option in the left-hand menu options sidebar.
            </div>
            <img
              src="../../../static/img/help/CampaignNavitem.png"
              alt="campaign nav item"
            />
          </li>
          <br />
          <li>
            <div>Now, look at all the recent campaigns you have run.</div>
            <img
              src="../../../static/img/help/CampaignPage.png"
              alt="Campaign Page"
            />
          </li>
          <br />
          <li>
            <div>
              Click on the <b>'report'</b> button for the desired campaign. Once
              you click this option, it will open the report results for the
              desired campaign.
            </div>
            <img
              src="../../../static/img/help/ReportButton.png"
              alt="Report button"
            />
          </li>
          <br />
          <li>
            <div>
              The report should look like the image below. It should have your
              desired <b>campaign name</b> at the top, followed by some{" "}
              <b>green and grey markers</b> on a timeline.
            </div>
            <img
              src="../../../static/img/help/ReportsButton.png"
              alt="reports buttons"
            />
          </li>
          <br />
          <li>
            <div>
              BlindSPOT partially marks actions run during the campaign if they
              are detected as "Blocked".
            </div>
            <img
              src="../../../static/img/help/BlockedExample.png"
              alt="Blocked example on report"
            />
          </li>
          <br />
          <li>
            <div>
              BlindSPOT may not understand <b>why</b> a tactic, technique or
              procedure (<b>TTP</b>) was blocked, but it <b>does</b> understand
              that execution of that TTP was halted. This will be reported along
              with the <b>action name</b>.
            </div>
            <img
              src="../../../static/img/help/ReportScoring1.png"
              alt="Scoring reports one"
            />
          </li>
          <br />
          <li>
            <div>
              BlindSPOT will also automatically mark the <b>"Threshold met?"</b>{" "}
              column if the campaign's defined threshold was met for each
              action.
            </div>
            <div style={{ display: "flex" }}>
              <img
                src="../../../static/img/help/ScoringKey.png"
                alt="Scoring key"
              />
              <ul>
                <li>
                  <span style={{ color: COLORS.success.main }}>Green</span> -
                  activities that were blocked
                </li>
                <li>
                  <span style={{ color: COLORS.primary.main }}>Blue</span> -
                  activities that were marked as "alerted"
                </li>
                <li>
                  <span style={{ color: COLORS.yellow }}>Yellow</span> -
                  activities that were marked as "logged"
                </li>
                <li>
                  <span style={{ color: COLORS.error.main }}>Red</span> -
                  activities marked as "no evidence"
                </li>
                <li>
                  <span style={{ color: COLORS.secondary.main }}>Gray</span> -
                  activities that are not yet scored
                </li>
              </ul>
            </div>
          </li>
          <br />
          <li>
            <div>
              By clicking on an <b>"action name"</b>, a breakdown of the action
              details is shown. These details include the{" "}
              <b>request and response</b> (command run and output), a{" "}
              <b>timestamp</b>, and <b>process ID</b>.
            </div>
            <img
              src="../../../static/img/help/ScoringDetails.png"
              alt="Scoring Details Screenshot"
            />
            <div>
              <b>Note: Note all Requests (commands) will have output</b>
            </div>
          </li>
          <br />
          <li>
            <div>
              By going through the endpoint logs (whether they be Windows or an
              antivirus/endpoint detection log) you can begin to mark actions in
              BlindSPOT as <b>"Blocked"</b>, <b>"Alerted"</b>,<b>"Logged"</b>,
              or <b>"No Evidence"</b>.
            </div>
            <img
              src="../../../static/img/help/ScoringLogs.png"
              alt="Scoring logs"
            />
            <div>
              <b>
                Note: You mark these actions in accordance with your findings in
                your endpoint log.
              </b>
            </div>
          </li>
          <br />
          <li>
            <div>
              You can also select the specific <b>defensive</b> tool that may
              have been responsible for logging the event. Logging these events
              is important for later because it aids in future reporting by
              determining how effective specific security tools are.
            </div>
            <img
              src="../../../static/img/help/scoring1.png"
              alt="Scoring exampole two"
            />
          </li>
          <br />
          <li>
            <div>
              Once the campaign actions are scored and defense tools are logged,{" "}
              <b>refreshing the page</b> will regenerate the{" "}
              <b>"Activity Timeline"</b> with the newly scored actions.
            </div>
            <img
              src="../../../static/img/help/ScoringActivity.png"
              alt="Scoring Activity"
            />
          </li>
          <br />
          <li>
            <div>
              To export your <b>"Activity Timeline"</b> use the ellipsis button
              above the timeline.
            </div>
            <img
              src="../../../static/img/help/ReportDownload.png"
              alt="Report download"
            />
          </li>
        </ol>
      </div>
    </Stack>
  );
};

export default ScoreCampaign;
