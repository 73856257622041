import { LoadingButton } from "@mui/lab";
import { Box, Divider, Stack, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { DataContext } from "../../../../Context/dataContext";
import { postRequest } from "../../../../Helpers/httpRequests";

const AdminAddNames = ({ user, setReload }) => {
  // ----- CONTEXT -----
  const { accessToken } = useContext(DataContext);

  // ----- STATES -----

  /**
   * Object to hold the values of each input in form.
   */
  const [formData, setFormData] = useState({
    first: user.first,
    last: user.last,
  });

  /**
   * Used for handling the props/children of <LoadingButton>
   */
  const [loadingBtn, setLoadingBtn] = useState({
    loading: false,
    color: "primary",
    text: "Submit",
    helperText: "",
    helperTextColor: "green",
  });

  // ---- Variables ------

  // ----- Functions -----

  /**
   * Handles the form submission.
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    // prevent page reload on submit
    e.preventDefault();

    // set submit btn to loading
    setLoadingBtn((prev) => ({ ...prev, loading: true }));

    const res = await postRequest(
      `/react/api/admin/user/edit_names/${user.uuid}`,
      accessToken,
      formData,
      true
    );
    setLoadingBtn((prev) => ({ ...prev, loading: false }));

    // send form response back as 201 (created)
    if (res.status === 200) {
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Success",
        color: "success",
        helperText: "Success",
        helperTextColor: "#2e7d32", // green
      }));
      setReload((current) => !current);
    } else {
      // handle errors here
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Error - Try again",
        color: "error",
        helperText: "Form submission failed",
        helperTextColor: "#d32f2f", // red
      }));
      console.log(
        "%cerror AdminEditUserForm.jsx handleSubmit()",
        "color: red; display: block; width: 100%;",
        "Failed to submit form"
      );
    }
  };

  return (
    <Box sx={{ width: "55%" }}>
      {/* Form Header */}
      <Typography variant="h5">
        We noticed that we don't have all the information we need for your
        account! Please help us update that here
      </Typography>

      <Divider style={{ marginBottom: "1rem" }} />

      {/* FORM */}
      <form onSubmit={(e) => handleSubmit(e)}>
        <Stack direction="column" spacing={2}>
          {/* First */}
          <TextField
            placeholder="First"
            label="First"
            type="text"
            required
            value={formData.first}
            onChange={(e) =>
              setFormData((data) => ({ ...data, first: e.target.value }))
            }
          />

          {/* Last */}
          <TextField
            placeholder="Last"
            label="Last"
            type="text"
            required
            value={formData.last}
            onChange={(e) =>
              setFormData((data) => ({ ...data, last: e.target.value }))
            }
          />

          {/* helper text */}
          <Typography color={loadingBtn.helperTextColor} variant="subtitle2">
            {loadingBtn.helperText}
          </Typography>

          {/* Submit Button */}
          <LoadingButton
            disabled={
              !formData.first ||
              !formData.last ||
              formData.first === "" ||
              formData.last === ""
            }
            loading={loadingBtn.loading}
            color={loadingBtn.color}
            type="submit"
            variant="contained"
          >
            {loadingBtn.text}
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
};

AdminAddNames.propTypes = {
  setReload: PropTypes.func,
};

export default AdminAddNames;
