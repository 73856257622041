import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { useHttpRequest } from "../../../Hooks";
import { COLORS } from "../../../Styles/colors";

export const GapRiskMatrix = () => {
  const [matrix, setMatrix] = useState([]);
  const [names, setNames] = useState([]);
  const [colors, setColors] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCell, setSelectedCell] = useState({ row: null, col: null });
  const [editingIndex, setEditingIndex] = useState(null);
  const [colorPickerAnchorEl, setColorPickerAnchorEl] = useState(null);
  const [tempColor, setTempColor] = useState("#ffffff");
  const [notUpdated, setNotUpdated] = useState(true);

  const levelNames = ["Blocked", "Alerted", "Logged", "No Evidence"];

  const updateAllData = (entry) => {
    setMatrix(entry.gap_risk);
    setNames(entry.scoring);
    setColors(entry.colors);
  };

  const { response: data } = useHttpRequest({
    path: "/api/v2/gap_risk_map",
    method: "GET",
  });

  const { response: updateData, fetch: update } = useHttpRequest({
    path: "/api/v2/gap_risk_map",
    method: "PUT",
    data: {
      gap_risk: matrix,
      scoring: names,
      colors: colors,
    },
    defer: true,
  });

  const { response: resetData, fetch: reset } = useHttpRequest({
    path: "/api/v2/gap_risk_map/reset",
    method: "GET",
    defer: true,
  });

  useEffect(() => {
    if (data) {
      updateAllData(data);
    }
  }, [data]);

  useEffect(() => {
    if (updateData) {
      updateAllData(updateData);
    }
  }, [updateData]);

  useEffect(() => {
    if (resetData) {
      updateAllData(resetData);
    }
  }, [resetData]);

  const handleClick = (event, rowIndex, colIndex) => {
    setSelectedCell({ row: rowIndex, col: colIndex });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (nameIndex) => {
    const newMatrix = [...matrix];
    newMatrix[selectedCell.row][selectedCell.col] = nameIndex;
    setMatrix(newMatrix);
    handleClose();
    setNotUpdated(false);
  };

  const handleNameChange = (index, event) => {
    const newNames = [...names];
    newNames[index] = event.target.value;
    setNames(newNames);
    setNotUpdated(false);
  };

  const handleColorClick = (event, index) => {
    setEditingIndex(index);
    setTempColor(colors[index]);
    setColorPickerAnchorEl(event.currentTarget);
  };

  const handleColorChange = (color) => {
    setTempColor(color.hex);
  };

  const handleColorClose = () => {
    const newColors = [...colors];
    newColors[editingIndex] = tempColor;
    setColors(newColors);
    setColorPickerAnchorEl(null);
    setNotUpdated(false);
  };

  const addNameColorPair = () => {
    const newNames = [...names, `New Name ${names.length + 1}`];
    const newColors = [...colors, "#ffffff"];
    setNames(newNames);
    setColors(newColors);
  };

  const removeNameColorPair = (index) => {
    // when we delete a name, we need to update the matrix to remove all references to that cell's index
    // any values that are greater than the index of the name we are deleting need to be decremented

    const newMatrix = matrix
      .map((row) => row.map((cell) => (cell === index ? 0 : cell)))
      .map((row) => row.map((cell) => (cell > index ? cell - 1 : cell)));

    const newNames = names.filter((_, i) => i !== index);
    const newColors = colors.filter((_, i) => i !== index);

    setNames(newNames);
    setColors(newColors);
    setMatrix(newMatrix);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h6">Edit Names and Colors</Typography>
          {names.map((name, offset) => {
            const index = names.length - offset - 1;
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 2,
                }}
              >
                <TextField
                  label="Name"
                  value={names[index]}
                  onChange={(event) => handleNameChange(index, event)}
                  variant="outlined"
                  size="small"
                  sx={{ marginRight: 2 }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 36,
                    backgroundColor: colors[index],
                    border: "1px solid #ccc",
                    cursor: "pointer",
                    marginRight: 2,
                  }}
                  onClick={(event) => handleColorClick(event, index)}
                />
                <IconButton
                  onClick={() => removeNameColorPair(index)}
                  sx={{ marginLeft: 1 }}
                  disabled={names.length <= 1}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            );
          })}
          <Button
            variant="contained"
            color="primary"
            onClick={addNameColorPair}
            startIcon={<AddIcon />}
          >
            Add Scoring Level
          </Button>
        </Box>
        <Grid container justifyContent="center" spacing={1}>
          <Grid item xs={2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            ></Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="body1" align="center">
                Actual
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={2}>
              <Box
                sx={{
                  width: 120,
                  height: 60,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body1" align="center">
                  Expected
                </Typography>
              </Box>
            </Grid>
            {matrix[0] &&
              matrix[0].map((_, colIndex) => (
                <Grid
                  item
                  key={colIndex}
                  sx={{ borderBottom: "1px solid black" }}
                >
                  <Box
                    sx={{
                      width: 120,
                      height: 60,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="body1" align="center">
                      {levelNames[colIndex]}
                    </Typography>
                  </Box>
                </Grid>
              ))}
          </Grid>
          {matrix.map((row, rowIndex) => (
            <Grid
              item
              key={rowIndex}
              container
              spacing={1}
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                xs={2}
                sx={{
                  borderRight: "1px solid black",
                  height: "100%",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    top: "50%",
                    transform: "translateY(25%)",
                  }}
                >
                  {levelNames[rowIndex]}
                </Typography>
              </Grid>
              {row.map((cell, colIndex) => (
                <Grid item key={colIndex}>
                  <Paper
                    sx={{
                      backgroundColor: colors[cell],
                      width: 120,
                      height: 60,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={(event) => handleClick(event, rowIndex, colIndex)}
                  >
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      sx={{
                        textAlign: "center",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        padding: 1,
                      }}
                    >
                      {names[cell]}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
      </Stack>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {names.map((name, offset) => {
          const index = names.length - offset - 1;
          return (
            <MenuItem
              key={index}
              onClick={() => handleSelect(index)}
              sx={{ backgroundColor: colors[index] }}
            >
              {names[index]}
            </MenuItem>
          );
        })}
      </Menu>

      <Menu
        anchorEl={colorPickerAnchorEl}
        open={Boolean(colorPickerAnchorEl)}
        onClose={handleColorClose}
      >
        <SketchPicker color={tempColor} onChange={handleColorChange} />
        <Button onClick={handleColorClose}>Done</Button>
      </Menu>

      <Box
        sx={{ marginTop: 4, display: "flex", justifyContent: "center", gap: 2 }}
      >
        <Button
          variant="contained"
          onClick={() => {
            update();
            setNotUpdated(true);
          }}
          sx={{
            backgroundColor: COLORS.primary.main,
            display: notUpdated ? "none" : "block",
          }}
        >
          Save Changes
        </Button>
        <Button
          variant="contained"
          onClick={(e) => {
            reset();
          }}
          sx={{ backgroundColor: COLORS.secondary.main }}
        >
          Reset
        </Button>
      </Box>
    </Box>
  );
};
