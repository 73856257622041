import React, { useContext } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { v1 as uuidv1 } from "uuid";
import PropTypes from "prop-types";
import { FormContext } from "../context/FormContext";
import { capitalize } from "../../Helpers/functions";

const DynamicSelect = (props) => {
  // options needs to be array such as:
  // [[value, displayValue], [value, displayValue], ...]
  // example:
  // [[uuid, name], [uuid, name], ...]
  const { name, fullWidth, label, required, disabled, options, multi } = props;
  const id = uuidv1();
  const { formData, setFormData } = useContext(FormContext);

  const handleChange = (e) => {
    let temp = e.target.value;
    if (multi) {
      temp = formData[name] || [];
      temp = [...e.target.value];
    }
    setFormData((data) => ({ ...data, [name]: temp }));
  };

  return (
    options.length > 0 && (
      <FormControl fullWidth={fullWidth} required={required}>
        <InputLabel id={id}>{label || capitalize(name)}</InputLabel>
        <Select
          labelId={id}
          value={formData[name] || (multi ? [] : "")}
          label={label || capitalize(name)}
          onChange={handleChange}
          disabled={disabled}
          multiple={multi}
        >
          {options.map((o) => (
            <MenuItem key={o[0]} value={o[0]}>
              {o[1]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  );
};

DynamicSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  multi: PropTypes.bool,
};

DynamicSelect.defaultProps = {
  fullWidth: false,
  required: false,
  disabled: false,
  multi: false,
};
export default DynamicSelect;
