import React, { useContext } from "react";
import { Box, Modal } from "@mui/material";
import { SimLibraryContext } from "../provider/context";
import CampaignForm from "../../../../Components/Forms/Simulations/CampaignForm";
import { useAppSelector } from "../../../../Redux/app/hooks";

const CreateCampaignForm = () => {
  const { formOpen, setFormOpen, selectedSim } = useContext(SimLibraryContext);
  const current_customer = useAppSelector(
    (state) => state.customer)

  // Modal styles to center and add padding
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    maxHeight: "90%",
    maxWidth: "25%", 
    overflowY: "auto"
  };

  return (
    <Modal open={formOpen} onClose={() => setFormOpen(false)}>
      <Box sx={style}>
        <CampaignForm simulation={selectedSim} setModalOpen={setFormOpen} customer={current_customer} />
      </Box>
    </Modal>
  );
};

export default CreateCampaignForm;
