import { Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { DataGridWithStyles } from "../../../../Components";
import { encryptId } from "../../../../Helpers/functions/uni_funcs";
import { useAppSelector } from "../../../../Redux/app/hooks";
import { COLORS } from "../../../../Styles/colors";

const SelectedAlerts = ({ alerts }) => {
  const current_customer = useAppSelector(
    (state) => state.customer,
  );
  const [dataTableRows, setDataTableRows] = useState([]);
  const columns = [
    {
      field: "result",
      headerName: "",
      flex: 1,
      maxWidth: 50,
      renderCell: (params) => {
        return params.value ? (
          <FaCheck color={COLORS.success.light} />
        ) : (
          <FaTimes color={COLORS.error.light} />
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (param) => {
        console.log(param);
        return (
          <Link
            to={`/${current_customer.uuid}/alertvalidation/alerts/details/${encryptId(param.row.id)}`}
          >
            {param.value}
          </Link>
        );
      },
    },
    { field: "endpoint", headerName: "Endpoint", flex: 1 },
  ];

  useEffect(() => {
    const populateDataGrid = (data) => {
      const rows = data.map((item) => {
        let id = item.id;
        return {
          id,
          result: item.alert_validation_status === "passed",
          name: item.name,
          endpoint: item.machinename.name,
        };
      });
      setDataTableRows(rows);
    };
    if (alerts.length > 0) populateDataGrid(alerts);
  }, [alerts]);
  return (
    <Paper sx={{ p: 1 }}>
      {alerts.length > 0 ? (
        <DataGridWithStyles
          name="selected-alerts-datagrid"
          rows={dataTableRows}
          columns={columns}
          autoHeight
          paper
        />
      ) : null}
      {/* <Box sx={{ pl: 3, pr: 3 }}> */}
      {/*   {alerts.length > 0 ? ( */}
      {/*     <List> */}
      {/*       {alerts.map((alert) => { */}
      {/*         return ( */}
      {/*           <ListItem key={alert.uuid} disablePadding> */}
      {/*             <Link */}
      {/*               to={`/${current_customer.uuid}/alertvalidation/alerts/details/${encryptId(alert.id)}`} */}
      {/*             > */}
      {/*               <ListItemButton> */}
      {/*                 <ListItemIcon> */}
      {/*                   {alert.alert_validation_status === "passed" ? ( */}
      {/*                     <FaCheck color={COLORS.success.main} /> */}
      {/*                   ) : ( */}
      {/*                     <FaTimes color={COLORS.error.main} /> */}
      {/*                   )} */}
      {/*                 </ListItemIcon> */}
      {/*                 <ListItemText primary={alert.name} /> */}
      {/*               </ListItemButton> */}
      {/*             </Link> */}
      {/*           </ListItem> */}
      {/*         ); */}
      {/*       })} */}
      {/*     </List> */}
      {/*   ) : null} */}
      {/* </Box> */}
    </Paper>
  );
};

export default SelectedAlerts;
