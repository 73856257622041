import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";

const Totals = ({ counts }) => {
  const total = counts.daily + counts.weekly + counts.monthly;

  return (
    <>
      <Typography variant="h5"> Scheduled Exercises</Typography>
      <br></br>
      <Typography variant="h6">
        <b>Total:</b> {total}
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary={`Daily: ${counts.daily}`} />
        </ListItem>
        <ListItem>
          <ListItemText primary={`Weekly: ${counts.weekly}`} />
        </ListItem>
        <ListItem>
          <ListItemText primary={`Monthly: ${counts.monthly}`} />
        </ListItem>
      </List>
    </>
  );
};

export default Totals;

