import { useContext, useState } from "react";
import { Grid, Paper } from "@mui/material";
import ExpectedAlertDetail from "./ExpectedAlertDetail";
import ExpectedAlertList from "./ExpectedAlertList";
import { AVDetailsContext } from "../../ContextProvider/ContextProvider";

const ExpectedAlertPanel = (props) => {
  const { alertInfo } = useContext(AVDetailsContext);

  const expectedAlerts = alertInfo.expected_alerts || [];
  const tools = (
    alertInfo.av_campaign?.scheduled_exercise?.security_stack?.tools || []
  ).map((tool) => ({ name: tool.tool.name, product: tool.tool.product }));
  const [selectedExpectedAlert, setSelectedExpectedAlert] = useState(
    expectedAlerts[0] || null
  );

  return (
    <Paper elevation={3} sx={{ width: "100%", marginTop: 3, p: 3 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <ExpectedAlertList
            expectedAlerts={expectedAlerts}
            setSelectedExpectedAlert={setSelectedExpectedAlert}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <ExpectedAlertDetail
            selectedExpectedAlert={selectedExpectedAlert}
            tools={tools}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ExpectedAlertPanel;
