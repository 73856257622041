import React from "react";
import {
  Form,
  PasswordField,
  SubmitButton,
  TextField,
} from "../../../../../FormBuilder";
import { Paper, Stack } from "@mui/material";
import { useAppSelector } from "../../../../../Redux/app/hooks";

const CreateForm = () => {
  const current_customer = useAppSelector(
    (state) => state.customer
  );
  return (
    <Paper sx={{ p: 3, maxWidth: 500 }}>
      <Form path={`/api/v2/${current_customer.uuid}/integrations`} toast>
        <Stack spacing={2}>
          <TextField name="name" required maxLength={200} />
          <TextField name="url" label="URL" required type="url" />
          <TextField name="username" required />
          <PasswordField name="password" required />
          <TextField name="customer_identifier" required maxLength={50} />
          <SubmitButton text="add" />
        </Stack>
      </Form>
    </Paper>
  );
};

export default CreateForm;
