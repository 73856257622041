import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../Context/dataContext";
import {
  fileRequest,
  getRequest,
  postRequest,
} from "../../../Helpers/httpRequests";
import { Link } from "react-router-dom";
import TextButton from "../../../Components/Buttons/TextButton";
import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  FaCheckCircle,
  FaDownload,
  FaEdit,
  FaExclamationCircle,
  FaPlus,
  FaTrashAlt,
} from "react-icons/fa";
import { COLORS } from "../../../Styles/colors";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import FormModal from "../../../Components/Modals/FormModal";
import { toast } from "react-toastify";
import ExecutiveSummaryForm from "./ExecutiveSummaryForm";
import TemplateEditForm from "./TemplateEditForm";
import TemplateAddForm from "./TemplateAddForm";
import { useAppSelector } from "../../../Redux/app/hooks";

const Reports_Templates = () => {
  // ----- STATES && CONTEXT -----
  const role = useAppSelector((state) => state.user.role);
  const [dataTableRows, setDataTableRows] = useState([]);
  const { accessToken } = useContext(DataContext);
  const user = useAppSelector((state) => state.user);
  const current_customer = useAppSelector((state) => state.customer);
  const [reloadTemplates, setReloadTemplates] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteUUID, setDeleteUUID] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const [execSumModal, setExecSumModal] = useState(false);
  const [execSumToEdit, setExecSumToEdit] = useState(false);
  const [templateAddModal, setTemplateAddModal] = useState(false);
  const [templateEditModal, setTemplateEditModal] = useState(false);
  const [template, setTemplate] = useState(null);

  //Variables

  const columns = [
    {
      field: "name",
      headerName: "Name",
      maxWidth: 400,
      flex: 1,
      renderCell: (params) => {
        return <Typography> {params.row.name} </Typography>;
      },
    },
    {
      field: "executive_summary",
      headerName: "Executive Summary",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        const { executive_summary } = params.row;
        const handleEditClick = () => {
          setTemplate(params.row.template);
          setExecSumToEdit(true);
          setExecSumModal(true);
        };

        const handleAddClick = () => {
          setTemplate(params.row.template);
          setExecSumToEdit(false);
          setExecSumModal(true);
        };

        if (executive_summary !== null) {
          return (
            <>
              <Typography
                className={
                  params.row.universal && !user.superuser ? "" : "text-links"
                }
                onClick={handleEditClick}
              >
                {executive_summary?.name}
              </Typography>
            </>
          );
        } else {
          return (
            <>
              <Button
                variant="contained"
                onClick={handleAddClick}
                disabled={params.row.universal && !user.superuser}
              >
                {" "}
                Add
              </Button>
            </>
          );
        }
      },
    },
    {
      field: "customer",
      headerName: "Customer",
      minWidth: 50,
      flex: 1,
      renderCell: (params) => {
        return params.row.universal ? (
          <Typography>Universal</Typography>
        ) : (
          <Typography>{current_customer.display_name}</Typography>
        );
      },
    },
    {
      field: "default",
      headerName: "Default Report Template",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return params.row.default ? (
          <FaCheckCircle size={25} style={{ color: "green" }} />
        ) : (
          <Button
            onClick={setDefaultTemplate(params.row.id)}
            variant="contained"
          >
            Set as Default
          </Button>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      maxWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack spacing={2} direction="row">
            <Tooltip title="Edit Template">
              <IconButton
                onClick={() => {
                  setTemplate(params.row.template);
                  setTemplateEditModal(true);
                }}
              >
                <FaEdit color={COLORS.secondary.main} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Template">
              <IconButton
                disabled={role === "View_Only"}
                style={{ opacity: role === "View_Only" ? 0.5 : 1 }}
                onClick={() => {
                  setDeleteUUID(params.row.id);
                  setDeleteName(params.row.name);
                  setDeleteModal(true);
                }}
              >
                <FaTrashAlt color={COLORS.error.main} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download Template">
              <IconButton onClick={() => downloadTemplate(params)}>
                <FaDownload />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      field: "testFile",
      headerName: "Test File",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          title="Test File"
          onClick={() => downloadTemplateTest(params)}
        >
          <FaExclamationCircle />
        </IconButton>
      ),
    },
  ];

  //Functions

  const handleExecutiveSummarySubmit = async (formData) => {
    // Determine whether to add or edit based on execSumToEdit state.
    const endpoint = execSumToEdit
      ? `/react/api/${current_customer.uuid}/report/templates/exec_summary/edit/${template.executive_summary.uuid}`
      : `/react/api/${current_customer.uuid}/report/templates/exec_summary/add/${template.uuid}`;
    const res = await postRequest(endpoint, accessToken, formData);
    if (res.status === 200) {
      setExecSumModal(false);
      setReloadTemplates((current) => !current);
    }
  };

  const deleteTemplate = async () => {
    const res = await toast.promise(
      postRequest(
        `/react/api/${current_customer.uuid}/report/templates/delete/${deleteUUID}`,
        accessToken
      ),
      {
        pending: "Deleting Template",
        success: `Template Deleted`,
        error: "Something went wrong!",
      }
    );
    if (res.status === 200) {
      setDeleteModal(false);
      setReloadTemplates((current) => !current);
    }
  };

  const downloadTemplate = async (params) => {
    await toast.promise(
      fileRequest(
        `/react/api/${current_customer.uuid}/report/templates/download/${params.row.id}`,
        accessToken,
        `${params.row.fileName}`
      ),
      {
        pending: "Downloading Template",
        success: `Template Downloaded`,
        error: "Something went wrong!",
      }
    );
  };

  const downloadTemplateTest = async (params) => {
    const loadingToast = toast.loading("Attempting to download test document");
    try {
      const res = await fileRequest(
        `/react/api/${current_customer.uuid}/report/templates/test?template=${params.row.id}`,
        accessToken,
        "example.docx"
      );

      if (res.status === 200) {
        toast.dismiss(loadingToast);
        toast.success("Test document downloaded successfully");
      } else {
        toast.dismiss(loadingToast);
        toast.error(`${res.data.msg || "Unknown error"}`);
      }
    } catch (error) {
      toast.dismiss(loadingToast);
      toast.error(`Test document failed to download: ${error.message}`);
    }
  };

  const setDefaultTemplate = (id) => async () => {
    let data = { template_id: id };
    const res = await postRequest(
      `/api/v2/${current_customer.uuid}/customers/${current_customer.id}/template`,
      accessToken,
      data
    );
    if (res.status === 200) {
      setReloadTemplates((current) => !current);
    }
  };

  // ----- ON PAGE LOAD -----

  useEffect(() => {
    const getTemplates = async () => {
      const res = await getRequest(
        `/api/v2/${current_customer.uuid}/reporttemplates?depth=1`,
        accessToken
      );
      if (res.status === 200) {
        populateDataGrid(res.data);
      }
    };

    const populateDataGrid = (templates) => {
      const rows = templates.map((template) => {
        return {
          id: template.id,
          template: template,
          name: template.name,
          fileName: template.name + ".docx",
          executive_summary: template.executive_summary,
          actions: [],
          universal: template.universal,
          default: template.default,
        };
      });
      setDataTableRows(rows);
    };

    getTemplates();
  }, [current_customer, accessToken, reloadTemplates]);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">Home</Link>
        <Typography color="text.primary">Reports</Typography>
        <Typography color="text.primary">Templates</Typography>
      </Breadcrumbs>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Typography component={"span"} fontWeight={"bold"} variant="h4">
          Templates
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <TextButton
            role={role}
            tooltip="Add Template"
            icon={FaPlus}
            onClick={() => setTemplateAddModal(true)}
          />
          <TextButton
            role={role}
            tooltip="Get list of Template Variables"
            onClick={async () => {
              await fileRequest(
                `/react/api/${current_customer.uuid}/report/templateVars`,
                accessToken,
                "template_variables.json"
              );
            }}
            icon={FaDownload}
          />
        </Box>
      </Box>

      <DataGridWithStyles
        name="reports-templates"
        autoHeight
        paper
        rows={dataTableRows}
        columns={columns}
      />
      {/* Delete Modal */}
      <FormModal open={deleteModal} setOpen={setDeleteModal}>
        <Box component="form">
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: 4 }}
          >
            Are you sure you want to remove Report {deleteName} ?
          </Typography>
          <Stack direction="column" spacing={2}>
            <Button onClick={() => deleteTemplate()} variant="contained">
              Yes
            </Button>
          </Stack>
        </Box>
      </FormModal>
      {/*Executive Summary Model*/}
      <FormModal
        open={execSumModal}
        setOpen={setExecSumModal}
        sx={{ height: "60%", width: "50%" }}
      >
        <ExecutiveSummaryForm
          template={template}
          edit={execSumToEdit}
          onSubmit={handleExecutiveSummarySubmit}
          onCancel={() => setExecSumModal(false)}
        />
      </FormModal>
      {/* Template Edit Form*/}
      <FormModal
        open={templateEditModal}
        setOpen={setTemplateEditModal}
        sx={{ height: "60%", width: "50%" }}
      >
        <TemplateEditForm
          template={template}
          onCancel={() => setTemplateEditModal(false)}
          setReloadTemplates={setReloadTemplates}
        />
      </FormModal>
      <FormModal
        open={templateAddModal}
        setOpen={setTemplateAddModal}
        sx={{ height: "60%", width: "50%" }}
      >
        <TemplateAddForm
          onCancel={() => setTemplateAddModal(false)}
          setReloadTemplates={setReloadTemplates}
        />
      </FormModal>
    </>
  );
};
export default Reports_Templates;
