import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../Redux/app/hooks";

const LandingPage = () => {
  // Navigate
  const navigate = useNavigate();
  const customer = useAppSelector((state) => state.customer);

  //On Load
  useEffect(() => {
    navigate(`${customer.uuid}/simulations/campaigns/list`);
  }, [navigate, customer]);
  return <div></div>;
};

export default LandingPage;
