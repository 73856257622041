import React, { useEffect, useState, useContext } from "react";
import { getRequest } from "../../../Helpers/httpRequests";
import { DataContext } from "../../../Context/dataContext";
import { populateDataGrid } from "./functions";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles";
import { useAppSelector } from "../../../Redux/app/hooks";
import { FocusContext } from "./Provider/Context";

export const FocusData = () => {
  // ----- CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector((state) => state.customer);

  const { setLoading, startDate, endDate } = useContext(FocusContext);

  /**
   * contains the datagrid props
   */
  const [dataGridData, setDataGridData] = useState({
    rows: [],
    columns: [],
  });

  // ----- FUNCTIONS -----

  // ----- ON PAGE LOAD -----

  useEffect(() => {
    const getData = async () => {
      const res = await getRequest("/react/api/admin/focus", accessToken, {
        startDate: startDate,
        endDate: endDate,
      });

      if (res.status === 200) {
        populateDataGrid(res.data.rows, setDataGridData);
      } else {
        console.log(
          "%cerror AdminFocus.jsx useEffect()",
          "color: red; display: block; width: 100%;",
          "Reponse status not 200"
        );
      }
      setLoading(false); // Set loading state to false once data is fetched
    };

    setLoading(true); // Set loading state to true when starting to fetch data
    getData();
  }, [accessToken, current_customer, startDate, endDate, setLoading]);

  return (
    <DataGridWithStyles
      name="admin-focus"
      autoHeight
      paper
      rows={dataGridData.rows}
      columns={dataGridData.columns}
      pageSize={dataGridData.pageSize}
    />
  );
};
