import React from "react";
import { Paper } from "@mui/material";
import blindspotLogo from "../../Images/logo/blindspotLogo.jpeg";

const Maintenance = () => {
  return (
    <div className="flex items-center justify-center w-screen h-screen bg-[#1B3846]">
      <Paper className="flex flex-col container text-center max-w-xl p-5 gap-4">
        <img src={blindspotLogo} alt="bs-logo" />
        <p className="font-semibold text-xl">
          Site is currently down for maintenance.
        </p>
        <p className="text-sm">
          If you are in need of immediate assistance please reach out to{" "}
          <a
            className="text-blue-700"
            href="mailto:blindspot_support@ondefend.com"
          >
            blindspot_support@ondefend.com
          </a>
        </p>
      </Paper>
    </div>
  );
};

export default Maintenance;
