/**
 * This function handles the creation of columns and rows for MUI Datagrid. Running this function will cause the MUI-Datagrid to re-mount.
 * @param {} param
 */
export const populateDataGrid = (customers, setDataGridData) => {
  const columns = [
    {
      field: "customer",
      headerName: "Customer",
      minWidth: 250,
      flex: 1,
    },
    {
      field: "agents",
      headerName: "# of Agents",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "users",
      headerName: "# of Users",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "campaigns",
      headerName: "Campaigns",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "oneWeek",
      headerName: "Camps (7 Days)",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "twoWeek",
      headerName: "Camps (14 days)",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "oneMonth",
      headerName: "Camps (30 days)",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "EARan",
      headerName: "Assessments Ran",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "campNotScored",
      headerName: "Camps not scored",
      minWidth: 100,
      flex: 1,
    },
  ];

  const rows = customers.map((cx) => {
    return {
      id: cx.uuid,
      active: cx.active,
      customer: cx.name,
      agents: cx.agents,
      users: cx.users,
      campaigns: cx.campaigns,
      oneWeek: cx.one_week,
      twoWeek: cx.two_week,
      oneMonth: cx.one_month,
      EARan: cx.endpoint_assessment_list,
      campNotScored: cx.campaigns_not_scored,
    };
  });

  setDataGridData((data) => ({
    ...data,
    rows: rows,
    columns: columns,
  }));
};
