import { Typography, Box } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import { useContext, useEffect } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DataContext } from "../../../../Context/dataContext";
import { getRequest } from "../../../../Helpers/httpRequests";
import { COLORS } from "../../../../Styles/colors";
import { AvOverviewContext } from "../Provider/Context";
import { useAppSelector } from "../../../../Redux/app/hooks";

const Counts = () => {
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector((state) => state.customer);
  const {
    startDate,
    endDate,
    passedCount,
    setPassedCount,
    failedCount,
    setFailedCount,
  } = useContext(AvOverviewContext);

  // variables

  useEffect(() => {
    const updateDate = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/alertvalidation/get-pass-rate`,
        accessToken,
        { startDate, endDate },
      );
      if (res.status === 200) {
        const results = res.data.results;
        const passed = results.passed || 0;
        const failed = results.failed || 0;
        setFailedCount(failed);
        setPassedCount(passed);
      }
    };
    updateDate();
  }, [
    accessToken,
    current_customer,
    startDate,
    endDate,
    setFailedCount,
    setPassedCount,
  ]);
  // const itemSizeStyles = { xs: 12, md: 12, lg: 12, xl: 12 };
  // const gridItemStyles = {
  //   borderRadius: 2,
  //   p: 2,
  // };

  const passedPercent = (
    (passedCount / (passedCount + failedCount)) *
    100
  ).toFixed(2);
  const failedPercent = (
    (failedCount / (passedCount + failedCount)) *
    100
  ).toFixed(2);

  return (
    <Box>
      <Typography variant="h6">Pass/Fail Rate</Typography>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* https://www.npmjs.com/package/react-date-range */}
        <PieChart
          series={[
            {
              arcLabel: (item) => `${item.percent}%`,
              highlightScope: { faded: "global", highlighted: "item" },
              faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
              data: [
                {
                  id: 0,
                  value: passedCount,
                  label: "Passed",
                  percent: passedPercent,
                },
                {
                  id: 1,
                  value: failedCount,
                  label: "Failed",
                  percent: failedPercent,
                },
              ],
            },
          ]}
          width={400}
          height={220}
          colors={[COLORS.success.light, COLORS.error.light]}
        />
        {/* <Grid container spacing={1} alignItems="center"> */}
        {/*   <Grid item {...itemSizeStyles}> */}
        {/*     <Stack */}
        {/*       sx={{ ...gridItemStyles, backgroundColor: COLORS.success.light }} */}
        {/*     > */}
        {/*       <Typography fontSize={24}>{passedCount}</Typography> */}
        {/*       <Typography>Passed</Typography> */}
        {/*       <Typography> */}
        {/*         {((passedCount / (passedCount + failedCount)) * 100).toFixed(2)}% */}
        {/*       </Typography> */}
        {/*     </Stack> */}
        {/*   </Grid> */}
        {/**/}
        {/*   <Grid item {...itemSizeStyles}> */}
        {/*     <Stack */}
        {/*       sx={{ ...gridItemStyles, backgroundColor: COLORS.error.light }} */}
        {/*     > */}
        {/*       <Typography fontSize={24}>{failedCount}</Typography> */}
        {/*       <Typography>Failed</Typography> */}
        {/*       <Typography> */}
        {/*         {((failedCount / (passedCount + failedCount)) * 100).toFixed(2)}% */}
        {/*       </Typography> */}
        {/*     </Stack> */}
        {/*   </Grid> */}
        {/* </Grid> */}
        {/* <Paper> */}
        {/*   <Stack direction="row" spacing={3}> */}
        {/*     <Stack spacing={2} sx={{ width: "100%" }}> */}
        {/*       <Paper */}
        {/*         sx={{ */}
        {/*           bgcolor: COLORS.success.light, */}
        {/*           flexGrow: 1, */}
        {/*           p: 1, */}
        {/**/}
        {/*           maxHeight: 75, */}
        {/*         }} */}
        {/*       > */}
        {/*         <Stack */}
        {/*           spacing={2} */}
        {/*           direction="row" */}
        {/*           sx={{ pl: 1, alignItems: "center" }} */}
        {/*         > */}
        {/*           <FaCheck size={40} color={textColor} /> */}
        {/*           <Stack spacing={1}> */}
        {/*             <Typography fontWeight="bold" color={textColor}> */}
        {/*               {passedCount} */}
        {/*             </Typography> */}
        {/*             <Typography fontWeight="bold" color={textColor}> */}
        {/*               PASSED */}
        {/*             </Typography> */}
        {/*           </Stack> */}
        {/*         </Stack> */}
        {/*       </Paper> */}
        {/*       <Paper */}
        {/*         sx={{ */}
        {/*           bgcolor: COLORS.error.light, */}
        {/*           flexGrow: 1, */}
        {/*           p: 1, */}
        {/*           maxHeight: 75, */}
        {/*         }} */}
        {/*       > */}
        {/*         <Stack */}
        {/*           spacing={2} */}
        {/*           direction="row" */}
        {/*           sx={{ pl: 1, alignItems: "center" }} */}
        {/*         > */}
        {/*           <FaTimes size={40} color={textColor} /> */}
        {/*           <Stack spacing={1}> */}
        {/*             <Typography fontWeight="bold" color={textColor}> */}
        {/*               {failedCount} */}
        {/*             </Typography> */}
        {/*             <Typography fontWeight="bold" color={textColor}> */}
        {/*               FAILED */}
        {/*             </Typography> */}
        {/*           </Stack> */}
        {/*         </Stack> */}
        {/*       </Paper> */}
        {/*     </Stack> */}
        {/*   </Stack> */}
        {/* </Paper> */}
      </Box>
    </Box>
  );
};

export default Counts;
