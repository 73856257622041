import { Button, Checkbox, Modal, Paper, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import DataGridWithStyles from "../../../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import { DataContext } from "../../../../../Context/dataContext";
import { postRequest } from "../../../../../Helpers/httpRequests";
import ScoreSelect from "./ScoreSelect";
import SecToolSelect from "./SecToolSelect";
import { modalStyles, paperStyles } from "./styles";
import { useAppSelector } from "../../../../../Redux/app/hooks";

const BulkUpdate = ({
  setReload,
  findings,
  open,
  setOpen,
  securityTools,
  executedUUID,
  current_customer,
}) => {
  // Contexts
  const { accessToken } = useContext(DataContext);
  const customer = useAppSelector((state) => state.customer);

  // States
  const [selectedRows, setSelectedRows] = useState([]);
  const [dataGrid, setDataGrid] = useState({
    columns: [],
    rows: [],
  });
  const [filter, setFilter] = useState({
    score: "unchanged",
    securityTool: "unchanged",
  });

  // Functions

  /**
   * Handles the onClick event for the `Apply` button.
   * @param {Event} e
   */
  const handleApplyClick = async (e) => {
    /**
     * Data to be sent to backend as post
     */
    const data = {
      findings: selectedRows,
      updateTo: {
        score: filter.score,
        securityTool: filter.securityTool,
      },
    };
    const loadingToast = toast.loading("Attempting to update findings");
    const res = await postRequest(
      `/react/api/${customer.uuid}/report/campaign/bulkupdate`,
      accessToken,
      data,
      true
    );
    if (res.status === 200) {
      setReload((current) => !current);
      toast.dismiss(loadingToast);
      toast.success("Successfully updated findings");
      setOpen((current) => !current);
    } else {
      toast.dismiss(loadingToast);
      toast.error("Failed to update Findings");
    }
  };

  // useEffect

  useEffect(() => {
    /**
     * Creates the columns and rows for the DataGrid then sets the appropriate states.
     */
    const populateDataGrid = () => {
      const columns = [
        {
          field: "check",
          headerName: "",
          sortable: false,
          renderHeader: (params) => (
            <Checkbox
              onChange={(e) => {
                const isChecked = e.target.checked;
                setSelectedRows(isChecked ? [...dataGrid.rows] : []);
              }}
            />
          ),
          renderCell: (params) => (
            <Checkbox
              checked={
                selectedRows.findIndex((row) => row.id === params.row.id) !== -1
              }
              onChange={(e) => {
                const isChecked = e.target.checked;
                setSelectedRows((prevSelectedRows) => {
                  if (isChecked) {
                    return [...prevSelectedRows, params.row];
                  } else {
                    return prevSelectedRows.filter(
                      (row) => row.id !== params.row.id
                    );
                  }
                });
              }}
            />
          ),
          flex: 1,
        },
        {
          field: "score",
          headerName: "Score",
          flex: 1,
        },
        {
          field: "secTool",
          headerName: "Security Tool",
          flex: 1,
          renderCell: (params) =>
            params.row.secTool ? params.row.secTool.name : "None",
        },
        {
          field: "actionName",
          headerName: "Action Name",
          flex: 4,
        },
        {
          field: "pid",
          headerName: "Process ID",
          flex: 1,
        },
      ];

      const rows = findings.map((f) => {
        let score = "";
        if (f.alerted) score = "Alerted";
        else if (f.blocked) score = "Blocked";
        else if (f.logged) score = "Logged";
        else if (f.nothing) score = "No Evidence";
        return {
          id: f.id,
          alerted: f.alerted,
          blocked: f.blocked,
          logged: f.logged,
          nothing: f.nothing,
          score: score,
          secTool: f.security_tool,
          actionName: f.name,
          pid: f.process,
        };
      });

      setDataGrid({
        columns: columns,
        rows: rows,
      });
    };
    if (findings) {
      populateDataGrid();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows, open]);

  return (
    <Modal sx={modalStyles} open={open} onClose={() => setOpen(false)}>
      <Paper sx={paperStyles}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            {/* Score */}
            <ScoreSelect data={filter} setData={setFilter} />
            {/* Security Tool Select */}
            <SecToolSelect
              data={filter}
              setData={setFilter}
              securityTools={securityTools}
            />
            <Button
              sx={{ flex: 1 }}
              variant="contained"
              onClick={handleApplyClick}
            >
              Apply
            </Button>
          </Stack>
          <DataGridWithStyles
            name="bulk-update"
            rows={dataGrid.rows}
            columns={dataGrid.columns}
            autoHeight
          />
        </Stack>
      </Paper>
    </Modal>
  );
};

BulkUpdate.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  findings: PropTypes.array.isRequired,
  securityTools: PropTypes.array.isRequired,
};

BulkUpdate.defaultProps = {
  findings: [],
  securityTools: [],
};

export default BulkUpdate;
