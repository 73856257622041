import React from "react";
import {
  Box,
  TextField,
  Paper,
  Stack,
  Button,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { COLORS } from "../../../Styles/colors";
import logo from "../../../Images/logo/blindspotLogo.jpeg";
import { toast } from "react-toastify";
import { postRequest } from "../../../Helpers/httpRequests";
import { useContext } from "react";
import { DataContext } from "../../../Context/dataContext";
import { useAuth0 } from "@auth0/auth0-react";

const Register = () => {
  // ----- Auth0 -----
  const { loginWithRedirect } = useAuth0();

  // ----- Context -----
  const { accessToken } = useContext(DataContext);
  // ----- States -----
  const [showText, setShowText] = useState(false);
  const [passIsInvalid, setPassIsInvalid] = useState(false);
  const [passwordErrorText, setPasswordErrorText] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    passwordConfirm: "",
    inviteCode: "",
  });

  // ----- Styles -----

  /**
   * Styles for page container to center the form in the middle vertically and horizontally
   */
  const containerStyles = {
    display: "flex",
    width: "100vw",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
  };

  /**
   * Styles for Typography error message
   */
  const helperTextStyles = {
    display: showText ? "block" : "none",
    pl: 1,
    color: COLORS.error.main,
  };

  /**
   * Styles for Typography error message
   */
  const invalidPassTextStyles = {
    display: passIsInvalid ? "block" : "none",
    pl: 1,
    color: COLORS.error.main,
  };

  // ----- Functions -----

  /**
   * This function is ran anytime the input for passwordConfirm is exited
   * @returns {Boolean}
   */
  const handlePasswordCheckBlur = () => {
    // reset the passwords match
    setPasswordsMatch(false);

    // if the passwords match, update the state to true
    if (formData.password === formData.passwordConfirm) {
      setPasswordsMatch(true);
      return true;
    } else {
      // show error message
      setShowText(true);
      return false;
    }
  };

  /**
   * This function is ran anytime the input for passwordConfirm is changed
   * @param {Event} e
   * @returns
   */
  const handlePasswordCheckOnChange = (e) => {
    // Reset passwords match
    setPasswordsMatch(false);

    // update the formData
    setFormData((data) => ({ ...data, passwordConfirm: e.target.value }));

    // Only run the rest of the code if there is an error text visible
    if (showText) {
      // If the passwords match:
      //    remove the error message and update passwordsMatch state to true
      if (formData.password === e.target.value) {
        setShowText(false);
        setPasswordsMatch(true);
        return true;
      }
    }
    return false;
  };

  const testPassword = (pwd) => {
    if (pwd.length <= 7)
      return {
        valid: false,
        message: "Please make sure password is longer than 7 characters.",
      };

    if (!/[A-Z]/.test(pwd))
      return {
        valid: false,
        message: "Please make sure password includes 1 capital letter",
      };

    if (!/\d/.test(pwd))
      return {
        valid: false,
        message: "Please make sure Password Includes a Number",
      };

    if (/\s/.test(pwd))
      return { valid: false, message: "Please only use visible characters" };

    if (!/[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/.test(pwd))
      return {
        valid: false,
        message: "Password must contain atleast one special character",
      };

    return { valid: true, message: "Valid Password" };
  };

  /**
   * Handles the form Submission to register a user
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setPassIsInvalid(false);
    setPasswordErrorText("");

    // Check password to meet criteria
    if (passwordsMatch) {
      const check = testPassword(formData.password);
      if (!check.valid) {
        setPassIsInvalid(true);
        setPasswordErrorText(check.message);
        return;
      }
      const res = await toast.promise(
        postRequest(`/react/api/register`, accessToken, formData),
        {
          pending: "Registering ...",
          success: `Success! Redirecting to login ...`,
          error: "Something went wrong!",
        }
      );

      if (res.status === 201) {
        loginWithRedirect();
      }
    }
  };

  return (
    <Box sx={containerStyles}>
      <Paper sx={{ p: 3 }}>
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            {/* Blindspot logo */}
            <img
              style={{ height: 150, width: "auto" }}
              loading="lazy"
              src={logo}
              alt="Blindspot Logo"
            />

            {/* Row 1 */}
            <Stack spacing={2} direction="row">
              {/* Email Address */}
              <TextField
                fullWidth
                placeholder="Email Address"
                label="Email Address"
                type="text"
                required
                value={formData.email}
                onChange={(e) =>
                  setFormData((data) => ({ ...data, email: e.target.value }))
                }
              />
            </Stack>

            {/* Row 2 */}
            <Stack spacing={2} direction="row" justifyContent="space-evenly">
              {/* First Name */}
              <TextField
                fullWidth
                placeholder="First Name"
                label="First Name"
                type="text"
                required
                value={formData.firstName}
                onChange={(e) =>
                  setFormData((data) => ({
                    ...data,
                    firstName: e.target.value,
                  }))
                }
              />
              {/* Last Name */}
              <TextField
                fullWidth
                placeholder="Last Name"
                label="Last Name"
                type="text"
                required
                value={formData.lastName}
                onChange={(e) =>
                  setFormData((data) => ({ ...data, lastName: e.target.value }))
                }
              />
            </Stack>

            {/* Row 3 */}
            <Stack spacing={2} direction="row" justifyContent="space-evenly">
              {/* Password */}
              <TextField
                fullWidth
                placeholder="Password"
                label="Password"
                type="password"
                required
                value={formData.password}
                onChange={(e) =>
                  setFormData((data) => ({ ...data, password: e.target.value }))
                }
              />
              {/* Confirm Password */}
              <TextField
                fullWidth
                placeholder="Confirm Password"
                label="Confirm Password"
                type="password"
                required
                value={formData.passwordConfirm}
                onChange={handlePasswordCheckOnChange}
                onBlur={handlePasswordCheckBlur}
              />
            </Stack>

            {/* Row 4 */}
            <Typography variant="caption" sx={helperTextStyles}>
              Passwords do not match
            </Typography>

            {/* Row 5 */}
            <Typography variant="caption" sx={invalidPassTextStyles}>
              {passwordErrorText}
            </Typography>

            {/* Row 6 */}
            <Stack direction="row">
              {/* Invite Code */}
              <TextField
                fullWidth
                placeholder="Invite Code"
                label="Invite Code"
                type="text"
                required
                value={formData.inviteCode}
                onChange={(e) =>
                  setFormData((data) => ({
                    ...data,
                    inviteCode: e.target.value,
                  }))
                }
              />
            </Stack>

            <Button type="submit" variant="contained">
              Submit
            </Button>
          </Stack>
        </form>
      </Paper>
    </Box>
  );
};

export default Register;
