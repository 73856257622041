import React, { useEffect, useContext, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { getRequest, postRequest } from "../../../../Helpers/httpRequests";
import {
  Stack,
  Typography,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataContext } from "../../../../Context/dataContext";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../Redux/app/hooks";

const AdminEditAttackForm = ({
  setOpen,
  modal = true,
  formData,
  setFormData,
}) => {
  // ----- CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const user = useAppSelector((state) => state.user);

  // ----- STATES -----
  const [formSelects, setFormSelects] = useState({
    tactics: [],
    x_mitre_data_sources: [],
    x_mitre_platforms: [],
  });

  /**
   * Used for handling the props/children of <LoadingButton>
   */
  const [loadingBtn, setLoadingBtn] = useState({
    loading: false,
    color: "primary",
    text: "Submit",
    helperText: "",
    helperTextColor: "green",
  });

  // ---- Variables ------

  // Styles for MultiSelects
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  /**
   * These are the styles for the Box inside of the modal.
   * The Modal component renders as position Absolute so we
   * have to write styles accordingly
   *
   */
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };

  // ----- Functions -----
  const handleDupes = (array) => {
    // initialize duplicate as empty string
    let duplicate = "";

    // initialize empty object
    let map = {};

    /**
     * count how many times a uuid is seen
     * If it doesn't exist, it's added and set to 1
     * otherwise, its current value is incremented
     */
    for (const i in array) {
      map[array[i]["uuid"]] = map[array[i]["uuid"]] + 1 || 1;
    }

    /**
     * Loop through the object until the duplicate is found.
     * Then break out of loop
     */
    for (const [k, v] of Object.entries(map)) {
      if (v === 2) {
        duplicate = k;
        break;
      }
    }

    return duplicate;
  };

  const handleTacticChange = (e) => {
    const dupe = handleDupes(e.target.value);
    if (dupe !== "") {
      const filtered_tactics = e.target.value.filter((tactic) => {
        return tactic.uuid !== dupe;
      });
      setFormData((data) => ({
        ...data,
        tactic: filtered_tactics,
      }));
    } else {
      setFormData((data) => ({
        ...data,
        tactic: e.target.value,
      }));
    }
  };

  const handleXMitreDataChange = (e) => {
    const dupe = handleDupes(e.target.value);
    if (dupe !== "") {
      const filtered_XMitre_Data = e.target.value.filter((data) => {
        return data.uuid !== dupe;
      });
      setFormData((data) => ({
        ...data,
        x_mitre_data_sources: filtered_XMitre_Data,
      }));
    } else {
      setFormData((data) => ({
        ...data,
        x_mitre_data_sources: e.target.value,
      }));
    }
  };

  const handleXMitrePlatformChange = (e) => {
    const dupe = handleDupes(e.target.value);
    if (dupe !== "") {
      const filtered_XMitre_platform = e.target.value.filter((platform) => {
        return platform.uuid !== dupe;
      });
      setFormData((data) => ({
        ...data,
        x_mitre_platforms: filtered_XMitre_platform,
      }));
    } else {
      setFormData((data) => ({
        ...data,
        x_mitre_platforms: e.target.value,
      }));
    }
  };

  /**
   * Handles the form submission.
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    // prevent page reload on submit
    e.preventDefault();

    // set submit btn to loading
    setLoadingBtn((prev) => ({ ...prev, loading: true }));

    const res = await postRequest(
      "/react/api/admin/attack-ref-form",
      accessToken,
      formData,
      true
    );
    setLoadingBtn((prev) => ({ ...prev, loading: false }));

    // send form response back as 201 (created)
    if (res.status === 201) {
      toast.success("Attack Technique Updated Successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      /**
       * If this form is contained in a modal, you can pass the setOpen state to this component.
       * This will wait 1.5 seconds and then close the modal
       * Add 'open' state to this components parent useEffect dependencies array to have your page reload the data.
       */
      if (setOpen !== undefined) {
        setOpen(false);
      }
    } else {
      toast.error("Form submission failed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      console.log(
        "%cerror AdminEditAttackForm.jsx handleSubmit()",
        "color: red; display: block; width: 100%;",
        "Failed to submit form"
      );
    }
  };

  // ----- On page load -----
  useEffect(() => {
    const getAttackRef = async () => {
      const res = await getRequest(
        "/react/api/admin/attack-ref-form",
        accessToken
      );
      if (res.status === 200) {
        setFormSelects(res.data);
      }
    };

    // if user.current_customer changes re-run api-request
    if (user.current_customer) {
      getAttackRef();
    }
  }, [user.current_customer, accessToken]);

  return (
    <>
      <Box sx={modal ? style : null}>
        {/* FORM */}
        <form onSubmit={(e) => handleSubmit(e)}>
          <Stack direction="column" spacing={2}>
            {/* Start form fields here */}

            {/* Name */}
            <TextField
              placeholder="Name"
              label="Name"
              value={formData.name}
              onChange={(e) =>
                setFormData((data) => ({ ...data, name: e.target.value }))
              }
            />

            {/* Attack ID */}
            <TextField
              placeholder="Attack ID"
              label="Attack ID"
              value={formData.attack_id}
              onChange={(e) =>
                setFormData((data) => ({ ...data, attack_id: e.target.value }))
              }
            />

            {/* Description */}
            <TextField
              multiline
              maxRows={4}
              placeholder="Description"
              label="Description"
              value={formData.description}
              onChange={(e) =>
                setFormData((data) => ({
                  ...data,
                  description: e.target.value,
                }))
              }
            />

            {/* Mitigations */}
            <TextField
              multiline
              maxRows={4}
              placeholder="Mitigations"
              label="Mitigations"
              value={formData.mitigations}
              onChange={(e) =>
                setFormData((data) => ({
                  ...data,
                  mitigations: e.target.value,
                }))
              }
            />

            {/* Detection */}
            <TextField
              multiline
              maxRows={4}
              placeholder="Detection"
              label="Detection"
              value={formData.detection}
              onChange={(e) =>
                setFormData((data) => ({ ...data, detection: e.target.value }))
              }
            />

            {/* Tactic */}
            <FormControl sx={{ m: 1 }}>
              <InputLabel>Tactic</InputLabel>
              <Select
                multiple
                value={formData.tactic}
                onChange={handleTacticChange}
                input={<OutlinedInput label="Tactic" />}
                renderValue={(selected) =>
                  selected.map((x) => x.name).join(",")
                }
                MenuProps={MenuProps}
              >
                {formSelects.tactics.map((tactic) => (
                  <MenuItem key={tactic.uuid} value={tactic}>
                    <Checkbox
                      checked={formData.tactic.some(
                        (tact) => tact.uuid === tactic.uuid
                      )}
                    />
                    <ListItemText primary={tactic.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* X mitre data sources */}
            <FormControl sx={{ m: 1 }}>
              <InputLabel>X Mitre Data Sources</InputLabel>
              <Select
                multiple
                value={formData.x_mitre_data_sources}
                onChange={handleXMitreDataChange}
                input={<OutlinedInput label="X Mitre Data Sources" />}
                renderValue={(selected) =>
                  selected.map((x) => x.name).join(",")
                }
                MenuProps={MenuProps}
              >
                {formSelects.x_mitre_data_sources.map((data) => (
                  <MenuItem key={data.uuid} value={data}>
                    <Checkbox
                      checked={formData.x_mitre_data_sources.some(
                        (d) => d.uuid === data.uuid
                      )}
                    />
                    <ListItemText primary={data.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* X mitre platforms */}
            <FormControl sx={{ m: 1 }}>
              <InputLabel>X Mitre Platforms</InputLabel>
              <Select
                multiple
                value={formData.x_mitre_platforms}
                onChange={handleXMitrePlatformChange}
                input={<OutlinedInput label="X Mitre Platforms" />}
                renderValue={(selected) =>
                  selected.map((x) => x.name).join(",")
                }
                MenuProps={MenuProps}
              >
                {formSelects.x_mitre_platforms.map((data) => (
                  <MenuItem key={data.uuid} value={data}>
                    <Checkbox
                      checked={formData.x_mitre_platforms.some(
                        (d) => d.uuid === data.uuid
                      )}
                    />
                    <ListItemText primary={data.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* End form fields */}
            {/* helper text */}
            <Typography color={loadingBtn.helperTextColor} variant="subtitle2">
              {loadingBtn.helperText}
            </Typography>
            {/* Submit Button */}
            <LoadingButton
              loading={loadingBtn.loading}
              color={loadingBtn.color}
              type="submit"
              variant="contained"
            >
              {loadingBtn.text}
            </LoadingButton>
          </Stack>
        </form>
      </Box>
    </>
  );
};

AdminEditAttackForm.propTypes = {
  setOpen: PropTypes.func,
  modal: PropTypes.bool,
};

export default withAuthenticationRequired(AdminEditAttackForm);
