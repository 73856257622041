import React, { useEffect, useContext, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { getRequest, postRequest } from "../../../../Helpers/httpRequests";
import {
  Divider,
  Stack,
  Typography,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Button,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import PropTypes from "prop-types";
import { DataContext } from "../../../../Context/dataContext";
import { useAppSelector } from "../../../../Redux/app/hooks";

const C2BeaconForm = ({ setOpen, modal = true, handleClose }) => {
  // ----- CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  // ----- STATES -----

  const [formData, setFormData] = useState({
    // initialize form values here
    name: "",
    file: { name: "" },
    command: "",
    description: "",
    platform: "Microsoft Windows 64-bit",
    execution: ".bin",
    proxyRequired: false,
    c2Proxy: "",
  });

  const [fileMessage, setFileMessage] = useState("");

  const [c2Proxies, setC2Proxies] = useState([]);

  const [loadingBtn, setLoadingBtn] = useState({
    loading: false,
    color: "primary",
    text: "Submit",
    helperText: "",
    helperTextColor: "green",
  });

  // ---- Variables ------

  const platforms = [
    "Microsoft Windows 64-bit",
    "Microsoft Windows 32-bit",
    // "Linux 64-bit",
    // "Linux 32-bit"
  ];

  // ----- Functions -----

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    const filetype = "." + file.name.split(".").reverse()[0];
    setFormData({ ...formData, file: file, execution: filetype });
    if (filetype === ".bin") {
      setFileMessage("Shellcode Injection");
    } else if (filetype === ".exe") {
      setFileMessage(
        "Windows Executable. *Warning* These can crash at launch. If this happens please try shellcode instead."
      );
    } else {
      setFileMessage("Potentially Unsupported File");
    }
  };

  /**
   * Handles the form submission.
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    // prevent page reload on submit
    e.preventDefault();

    // set submit btn to loading
    setLoadingBtn((prev) => ({ ...prev, loading: true }));

    const execFileB64 = await toBase64(formData.file);

    const readyFormData = { ...formData, file: execFileB64 };

    const res = await postRequest(
      `/react/api/${current_customer.uuid}/simulations/addbeacon`,
      accessToken,
      readyFormData,
      false
    );
    setLoadingBtn((prev) => ({ ...prev, loading: false }));

    // send form response back as 201 (created)
    if (res.status === 201) {
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Success",
        color: "success",
        helperText: "Success",
        helperTextColor: "#2e7d32", // green
      }));

      /**
       * If this form is contained in a modal, you can pass the setOpen state to this component.
       * This will wait 1.5 seconds and then close the modal
       * Add 'open' state to this components parent useEffect dependencies array to have your page reload the data.
       */
      if (setOpen !== undefined) {
        setTimeout(() => setOpen(false), 1500);
      }
      if (handleClose !== undefined) {
        handleClose();
      }
    } else {
      // handle errors here
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Error - Try again",
        color: "error",
        helperText: "Form submission failed",
        helperTextColor: "#d32f2f", // red
      }));
      console.log(
        "%cerror C2BeaconForm.jsx handleSubmit()",
        "color: red; display: block; width: 100%;",
        "Failed to submit form"
      );
    }
  };

  // ----- On page load -----
  useEffect(() => {
    const getData = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/config/c2proxyconfigs`,
        accessToken
      );
      if (res.status === 200) {
        setC2Proxies(res.data.configs);
      }
    };

    getData();
  }, [current_customer, accessToken]);

  return (
    <>
      {/* Form Header */}
      <Typography variant="h5">Create New C2 Beacon</Typography>

      <Divider style={{ marginBottom: "0.5rem" }} />

      {/* FORM */}
      <form onSubmit={(e) => handleSubmit(e)}>
        <Stack direction="column" spacing={2}>
          {/* Name */}
          <TextField
            required
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            label="Name"
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              verticalAlign: "middle",
            }}
          >
            <TextField
              value={formData.file.name}
              label={"Upload Beacon Executable"}
              required
              InputProps={{
                fullWidth: true,
                readOnly: true,
                startAdornment: (
                  <Button
                    variant="outlined"
                    size="small"
                    fullWidth
                    component="label"
                    sx={{ mr: 1 }}
                  >
                    {"CHOOSE FILE"}
                    <input type="file" onChange={handleFileSelect} hidden />
                  </Button>
                ),
              }}
            />

            <Typography sx={{ display: "flex", alignItems: "center", px: 2 }}>
              {fileMessage}
            </Typography>
          </Box>

          {/* So the command here is kinda difficult to pass in. The way we execute the payload doesn't seem to allow for arguments. Will need to research further */}
          {/* <TextField
            value={formData.command}
            onChange={(e) => setFormData({ ...formData, command: e.target.value })}
            label="Payload Command"
          /> */}

          <TextField
            value={formData.description}
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
            label="Description"
          />

          <FormControl>
            <InputLabel required>Platform</InputLabel>
            <Select
              value={formData.platform}
              onChange={(e) =>
                setFormData({ ...formData, platform: e.target.value })
              }
              label="Platform"
            >
              {platforms.map((platform) => {
                return (
                  <MenuItem key={platform} value={platform}>
                    {platform}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.proxyRequired}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      proxyRequired: !formData.proxyRequired,
                    })
                  }
                />
              }
              label="C2 Proxy Required"
            />
          </FormControl>
          {formData.proxyRequired && (
            <>
              {c2Proxies.length ? (
                <FormControl>
                  <InputLabel>C2 Proxy</InputLabel>
                  <Select
                    value={formData.c2Proxy}
                    onChange={(e) =>
                      setFormData({ ...formData, c2Proxy: e.target.value })
                    }
                    label="C2 Proxy"
                  >
                    {c2Proxies.map((proxy) => {
                      return (
                        <MenuItem key={proxy.uuid} value={proxy.uuid}>
                          {proxy.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              ) : (
                <Typography>No C2 Proxy Configured</Typography>
              )}
            </>
          )}

          <Typography color={loadingBtn.helperTextColor} variant="subtitle2">
            {loadingBtn.helperText}
          </Typography>

          {/* Submit Button */}
          <LoadingButton
            loading={loadingBtn.loading}
            color={loadingBtn.color}
            type="submit"
            variant="contained"
          >
            {loadingBtn.text}
          </LoadingButton>
        </Stack>
      </form>
    </>
  );
};

C2BeaconForm.propTypes = {
  setOpen: PropTypes.func,
  modal: PropTypes.bool,
};

export default withAuthenticationRequired(C2BeaconForm);
