import {
  Box,
  FormControl,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import SelectSearch from "react-select-search";
import "react-select-search/style.css";
import { useHttpRequest } from "../../../../Hooks";
import classes from "./style.module.css";

const CustomerAccessForm = ({ formData, setFormData }) => {
  // ----- Context -----

  // ----- States -----
  const [customers, setCustomers] = useState([]);
  const { response: cxResponse } = useHttpRequest({
    method: "GET",
    path: `/api/v2/customers`,
  });

  // ----- Functions -----
  const selectAll = () => {
    setFormData((oldData) => ({
      ...oldData,
      customer_access: customers,
    }));
  };

  const removeAll = () => {
    setFormData((oldData) => ({
      ...oldData,
      customer_access: [],
    }));
  };

  // Sets customers state AFTER response is received
  useEffect(() => {
    // Prevents functions from being called twice
    if (cxResponse) {
      setCustomers(cxResponse);
    }
  }, [cxResponse]);
  return (
    <Box>
      <Box
        style={{
          display: "flex",
          maxHeight: 550,
          justifyContent: "space-evenly",
        }}
      >
        {/* Available Customers */}
        <Box flex={1}>
          <Typography component={"span"}>Available Customers</Typography>
          <Tooltip title={"Add All"}>
            <IconButton onClick={() => selectAll()} sx={{ marginLeft: 15 }}>
              <FaPlus size={20} />
            </IconButton>
          </Tooltip>
          <FormControl required margin="none">
            <SelectSearch
              className={classes}
              multiple
              options={customers
                .filter((customer) => {
                  return !formData.customer_access.some(
                    (selectedCustomer) =>
                      selectedCustomer.uuid === customer.uuid
                  );
                })
                .map((customer) => ({
                  value: customer.uuid,
                  name: customer.display_name,
                }))}
              onChange={(value) => {
                const selected = customers.find(
                  (customer) => customer.uuid === value[0]
                );
                if (selected) {
                  setFormData((oldData) => ({
                    ...oldData,
                    customer_access: [
                      ...oldData.customer_access,
                      {
                        uuid: selected.uuid,
                        name: selected.name,
                      },
                    ],
                  }));
                }
              }}
              placeholder="Search Avail Customers..."
              search
            />
          </FormControl>
        </Box>

        {/* Selected Customers */}
        <Box style={{ flex: 1, marginTop: 1 }}>
          <Typography component={"span"}>Selected Customers</Typography>
          <Tooltip title={"Remove All"}>
            <IconButton onClick={() => removeAll()} sx={{ marginLeft: 15 }}>
              <FaMinus size={20} />
            </IconButton>
          </Tooltip>
          <FormControl required margin="none">
            <SelectSearch
              className={classes}
              multiple
              options={formData.customer_access.map((customer) => ({
                value: customer.uuid,
                name: customer.display_name,
              }))}
              onChange={(selectedOptions) => {
                const updatedCustomers = formData.customer_access.filter(
                  (selectedCustomer) =>
                    !selectedOptions.includes(selectedCustomer.uuid)
                );
                setFormData((oldData) => ({
                  ...oldData,
                  customer_access: updatedCustomers,
                }));
              }}
              placeholder="Search Selected Customers..."
              search
            />
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

CustomerAccessForm.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
};

export default CustomerAccessForm;
