import React from "react";

import AdminEditProjectForm from "../../../Components/Forms/Administration/AdminEditProjectForm/AdminEditProjectForm";
import { Breadcrumbs, Paper, Stack, Typography } from "@mui/material";

const AdminEditProject = () => {
  return (
    <Stack spacing={2}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Administration</Typography>
        <Typography color="text.primary">Templates</Typography>
        <Typography color="text.primary">Edit Template</Typography>
      </Breadcrumbs>

      {/* Form */}
      <Paper sx={{ padding: 3, maxWidth: 900 }}>
        <AdminEditProjectForm />
      </Paper>
    </Stack>
  );
};

export default AdminEditProject;
