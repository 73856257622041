import React from "react";
import { BreadCrumbs, TitleAndButtons, TabContainer } from "./components";
import SimLibraryProvider from "./provider/SimLibraryProvider";

const SimLibrary = () => {
  return (
    <SimLibraryProvider>
      <BreadCrumbs />
      <TitleAndButtons />
      <TabContainer />
    </SimLibraryProvider>
  );
};

export default SimLibrary;
