import React, { useEffect, useState, useContext } from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { getRequest } from "../../../Helpers/httpRequests";
import { DataContext } from "../../../Context/dataContext";
import TextButton from "../../../Components/Buttons/TextButton";
import { FaPlus } from "react-icons/fa";
import FormModal from "../../../Components/Modals/FormModal";
import AdminAddUtilityForm from "../../../Components/Forms/Administration/AdminAddUtilityForm";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import { useAppSelector } from "../../../Redux/app/hooks";

const AdminUtilityFiles = () => {
  // ----- CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  // ----- STATES -----
  // Modal state
  const [open, setOpen] = useState(false);

  /**
   * contains the datagrid props
   */
  const [dataGridData, setDataGridData] = useState({
    rows: [],
    columns: [],
  });

  // ----- VARIABLES -----

  // ----- Functions -----

  /**
   * This function handles the creation of columns and rows for MUI Datagrid. Running this function will could the MUI-Datagrid to re-mount.
   * @param {} param
   */
  const populateDataGrid = (files) => {
    const columns = [
      {
        field: "name",
        headerName: "Name",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "payloadFile",
        headerName: "Payload File",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "command",
        headerName: "Command",
        minWidth: 100,
        flex: 1,
      },
    ];

    const rows = files.map((element) => {
      return {
        id: element.uuid,
        name: element.name,
        payloadFile: element.payload_file.split("utilities/")[1],
        command: element.payload_command,
      };
    });

    setDataGridData((data) => ({
      ...data,
      rows: rows,
      columns: columns,
    }));
  };

  // ----- On page load -----

  useEffect(() => {
    const getData = async () => {
      const res = await getRequest("/react/api/admin/utilities", accessToken);

      if (res.status === 200) {
        populateDataGrid(res.data.utilities);
      } else {
        console.log(
          "%cerror AdminUtilityFiles.jsx useEffect()",
          "color: red; display: block; width: 100%;",
          "Reponse status not 200"
        );
      }
    };

    getData();
  }, [accessToken, current_customer, open]);

  return (
    <>
      <Stack spacing={3}>
        {/* Breadcrumbs */}
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="text.primary">Administration</Typography>
          <Typography color="text.primary">Utility Files</Typography>
        </Breadcrumbs>
        {/* Page Title  && Buttons */}
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography variant="h3">Utility Files</Typography>
          <TextButton
            tooltip="Create New Utility"
            icon={FaPlus}
            onClick={() => setOpen(true)}
          />
        </Stack>
        {/* Template Grid */}
        <DataGridWithStyles
          name="admin-utility-files"
          autoHeight
          paper
          rows={dataGridData.rows}
          columns={dataGridData.columns}
        />
      </Stack>
      <FormModal open={open} setOpen={setOpen}>
        <AdminAddUtilityForm setOpen={setOpen} />
      </FormModal>
    </>
  );
};

export default AdminUtilityFiles;
