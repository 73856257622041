import { Stack } from "@mui/system";
import React, { useContext, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import TextButton from "../../../../Components/Buttons/TextButton/TextButton";
import { DataContext } from "../../../../Context/dataContext";
import { filePostRequest } from "../../../../Helpers/httpRequests";
import { useAppSelector } from "../../../../Redux/app/hooks";

export const DownloadReport = (props) => {
  const { uuid, name, report, executiveSummary } = props;

  const { accessToken } = useContext(DataContext);
  const customer = useAppSelector((state) => state.customer);
  const [downloading, setDownloading] = useState(false);

  const downloadReport = async () => {
    setDownloading(true);
    // Show the initial toast message
    const infoToast = toast.loading("Attempting to Download Project Report", {
      autoClose: false,
    });

    try {
      // Make the HTTP GET request to download the report
      const res = await filePostRequest(
        `/react/api/${customer.uuid}/report/${uuid}/download`,
        accessToken,
        `${name}.docx`,
        {description: executiveSummary.description,
          prepared_for: executiveSummary.prepared_for,
          endDate: executiveSummary.end_date }
        );
      // Close the initial toast message
      toast.dismiss(infoToast);

      if (res.status === 200) {
        // Show a success toast if the response status is 200
        toast.success("Project Report Downloaded successfully");
        setDownloading(false);
      } else {
        const contentType = res.headers["content-type"];
        if (contentType && contentType.includes("application/json")) {
          // If the response is JSON, parse it to extract the error message
          const data = await res.data.text();
          const obj = JSON.parse(data);
          if (obj && obj.msg) {
            toast.error("Template error: " + obj.msg);
          } else {
            toast.error("System error");
          }
          setDownloading(false);
        } else {
          toast.error("An error occurred while downloading the report.");
          setDownloading(false);
        }
      }
    } catch (error) {
      // Handle network or request-related errors
      toast.dismiss(infoToast); // Close the initial toast on error
      toast.error("Network error. Failed to Download Project Report");
      setDownloading(false);
    }
  };

  return (
    <div>
      <Stack spacing={2} direction="row">
        <TextButton
          disabled={report.template === null || downloading}
          tooltip="Download Project Report"
          icon={FaCheckCircle}
          onClick={downloadReport}
        />
      </Stack>
    </div>
  );
};
