import React, { useEffect, useContext, useState } from "react";
import { DataContext } from "../../../../Context/dataContext";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AttackMatrixDiagram from "../../../../Components/Reporting/AttackMatrixDiagram/AttackMatrixDiagram";
import { getRequest } from "../../../../Helpers/httpRequests";
import { useAppSelector } from "../../../../Redux/app/hooks";

export const AttackMatrixProject = (props) => {
  const { uuid } = props;
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  const [techniques, setTechniques] = useState([]);

  const killChain = [
    { display: "Reconnaissance", identifier: "reconnaissance" },
    { display: "Resource Development", identifier: "resource-development" },
    { display: "Initial Access", identifier: "initial-access" },
    { display: "Execution", identifier: "execution" },
    { display: "Persistence", identifier: "persistence" },
    { display: "Privilege Escalation", identifier: "privilege-escalation" },
    { display: "Defense Evasion", identifier: "defense-evasion" },
    { display: "Credential Access", identifier: "credential-access" },
    { display: "Discovery", identifier: "discovery" },
    { display: "Lateral Movement", identifier: "lateral-movement" },
    { display: "Collection", identifier: "collection" },
    { display: "Command & Control", identifier: "command-and-control" },
    { display: "Exfiltration", identifier: "exfiltration" },
    { display: "Impact", identifier: "impact" },
  ];

  useEffect(() => {
    const getTechniques = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/projectreport/${uuid}/techniques`,
        accessToken
      );
      if (res.status === 200) {
        const techs = res.data.techniques;
        techs.map((tech) => {
          if (tech.attack_id in res.data.techOutcomes) {
            tech.outcomes = res.data.techOutcomes[tech.attack_id];
          }
          return null;
        });
        setTechniques(techs);
      }
    };

    getTechniques();
  }, [current_customer, accessToken, uuid]);

  return techniques.length > 0 ? (
    <Accordion sx={{ maxWidth: "100%" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="attack-accordion"
        id="attack-accordion"
      >
        <Typography
          variant="h5"
          sx={{ marginTop: "auto", marginBottom: "auto" }}
        >
          Project ATT&CK<sup>®</sup> Matrix
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ maxWidth: "100%", overflow: "scroll" }}>
        <div>
          <AttackMatrixDiagram killChain={killChain} techniques={techniques} />
        </div>
      </AccordionDetails>
    </Accordion>
  ) : (
    <CircularProgress />
  );
};
