import React, { useContext, useEffect, useState } from "react";
import { Stack, Typography, Modal, IconButton, Paper, Checkbox } from "@mui/material";
import { DetailsContext } from "../ContextProvider/ContextProvider";
import { FaEdit, FaPlusCircle, FaTrash } from "react-icons/fa";
import { TimeSlotModal } from "./TimeSlotModal";
import { useHttpRequest } from "../../../../Hooks";

export const TimeSlots = () => {

  const { schedule, getSchedule } = useContext(DetailsContext)

  const [modalState, setModalState] = useState({
    open: false,
    times: null,
    day: -1
  })

  const daysOfWeeks = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ]

  const {
    fetch: deleteTimeslot,
    response
  } = useHttpRequest({
    method: "DELETE",
    path: "/api/v2/schedule/",
    defer: true,
  })

  useEffect(() => {
    getSchedule()
  }, [response, getSchedule])



  const timeSlotRow = (idx) => {
    const times = []
    schedule?.time_availability.forEach(timeslot => {
      if (timeslot.day === idx) {
        let { start_hour, start_minute, end_hour, end_minute } = timeslot
        let contentStr = ""
        if (start_minute.toString().length === 1) {
          start_minute = `0${start_minute}`
        }
        if (end_minute.toString().length === 1) {
          end_minute = `0${end_minute}`
        }
        contentStr += `${start_hour}:${start_minute} - ${end_hour}:${end_minute}`
        times.push({
          ...timeslot,
          string: contentStr
        })
      }
    })

    const checked = times.length > 0

    return (
      <>
        <Checkbox checked={checked} disabled />
        <Typography>{daysOfWeeks[idx]}</Typography>

        {times.map(
          (time) => {
            return (
              <Paper sx={{ p: "2px" }}>
                <Typography>{time.string}</Typography>
                <Stack direction={"row"}>
                  <IconButton onClick={() => {
                    setModalState({ open: true, times: time, day: idx })
                  }}
                  title="Edit Timeslot">
                    <FaEdit />
                  </IconButton>
                  <IconButton onClick={() => {
                    deleteTimeslot(time.id)
                  }}
                  title="Delete Timeslot">
                    <FaTrash />
                  </IconButton>
                </Stack>
              </Paper>
            )
          }
        )}
        <IconButton>
          <FaPlusCircle onClick={() => {
            setModalState({ open: true, times: null, day: idx })
          }} 
          title="Add New TimeSlot"/>
        </IconButton>
      </>
    )
  }

  return (
    <Stack>
      {daysOfWeeks.map((day, idx) => {
        return (
          <Paper>
            <Stack direction="row" spacing={3}>
              {timeSlotRow(idx)}
            </Stack>
          </Paper>
        )

      })}

      <Modal
        open={modalState.open}
        onClose={() => {
          setModalState({ open: false, times: null, day: -1 })
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <TimeSlotModal times={modalState.times} day={modalState.day} onSuccess={() => {
          setModalState({ open: false, times: null, day: -1 })
          getSchedule()
        }}
        />
      </Modal>
    </Stack>
  )
}