import { FaDownload } from "react-icons/fa";
import { TextButton } from "../../../Components";
import { useContext } from "react";
import { DataContext } from "../../../Context/dataContext";
import { FocusContext } from "./Provider/Context";
import { fileRequest } from "../../../Helpers/httpRequests";

export const DownloadExcel = () => {
  const { accessToken } = useContext(DataContext);
  const { startDate, endDate } = useContext(FocusContext);

  const handleDownload = async () => {
    const response = await fileRequest(
      "/react/api/admin/focus/download",
      accessToken,
      "focus.xlsx",
      {
        startDate: startDate,
        endDate: endDate,
      }
    );

    if (response.status !== 200) {
      console.error("Failed to download file");
    }
  };

  return (
    <TextButton onClick={handleDownload} icon={FaDownload}>
      Download Excel
    </TextButton>
  );
};
