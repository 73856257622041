import { Paper, Stack, Typography } from "@mui/material";
import { COLORS } from "../../../../Styles/colors";
import AdminAgreements from "../../AdminAgreements";

const AgreementSettings = () => {
  return (
    <Paper sx={{ p: 3 }}>
      {/* eslint-disable-next-line */}
      <a
        id="agreements-section"
        style={{
          display: "block",
          position: "relative",
          top: "-100px",
          visibility: "hidden",
        }}
      />
      <Stack spacing={2}>
        <Typography color={COLORS.primary.main} variant="h4">
          Agreements
        </Typography>
        <Stack spacing={1}>
          <AdminAgreements />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default AgreementSettings;
