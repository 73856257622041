import { useEffect, useState, useContext } from "react";
import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Paper,
} from "@mui/material";
import { Stack } from "@mui/system";
import { DataContext } from "../../../Context/dataContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import { postRequest } from "../../../Helpers/httpRequests";
import { useAppSelector } from "../../../Redux/app/hooks";

const UploadC2Beacon = () => {
  const navigate = useNavigate();
  const { executedUUID } = useParams();

  // ----- Context -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer,
  );

  // ----- States -----
  const [logData, setLogData] = useState({
    inputs: [],
    outputs: [],
    metadata: [],
    file_json: [],
  });
  const [fileName, setFile] = useState();

  const [outputAssignment, setOutputAssignment] = useState([]);

  // Submit Btn
  const [submitBtnColor, setSubmitBtnColor] = useState("primary");
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [submitBtnText, setSubmitBtnText] = useState("Submit");
  // ----- Variable -----

  // ----- Functions -----

  const uploadCobaltStrike = async () => {
    setSubmitBtnLoading(true);
    const data = {
      file_name: fileName,
    };

    const res = await postRequest(
      `/react/api/${current_customer.uuid}/campaigns/uploadlogs/${executedUUID}`,
      accessToken,
      data,
    );
    setSubmitBtnLoading(false);
    if (res.status === 200) {
      setSubmitBtnColor("primary");
      setSubmitBtnText("Submit");
      setLogData(res.data);
    } else {
      setSubmitBtnColor("error");
      setSubmitBtnText("Error - Try again");
    }
  };

  const uploadPairings = async () => {
    setSubmitBtnLoading(true);
    const outputs = Array.from({ length: logData.inputs.length }, () => [
      "",
      "",
    ]);
    outputAssignment.forEach((pairing) => {
      // command output
      outputs[pairing.destination - 1][0] +=
        logData.outputs[pairing.output - 1][0];
      // timestamp [last output received]
      outputs[pairing.destination - 1][1] =
        logData.outputs[pairing.output - 1][1];
    });

    const data = {
      inputs: logData.inputs,
      outputs: outputs,
      metadata: logData.metadata,
    };

    // I was getting some issues with the size of data getting cut off through stringify
    // so why not just make the data be a file?
    // makes sense to me and it works ¯\_(ツ)_/¯
    const jsonBlob = new Blob([JSON.stringify(data)], {
      type: "application/json",
    });

    const formData = new FormData();
    formData.append("file", jsonBlob, "data.json");

    const res = await postRequest(
      `/react/api/${current_customer.uuid}/campaigns/setResults/${executedUUID}`,
      accessToken,
      formData,
    );
    setSubmitBtnLoading(false);

    if (res.status === 200) {
      navigate(`/${current_customer.uuid}/reporting/campaign/${executedUUID}`);
    } else {
      setSubmitBtnColor("error");
      setSubmitBtnText("Error - Try again");
    }
  };

  // ----- On page load -----

  useEffect(() => {
    if (outputAssignment.length === 0) {
      const tmpArray = [];
      const inputLen = logData.inputs.length;
      logData.outputs.map((_, idx) => {
        if (idx <= logData.inputs.length) {
          tmpArray.push({
            output: idx + 1,
            destination: idx + 1,
          });
        } else {
          tmpArray.push({
            output: idx + 1,
            destination: inputLen,
          });
        }
        return null;
      });
      setOutputAssignment(tmpArray);
    }
  }, [
    accessToken,
    current_customer,
    logData.inputs.length,
    logData.outputs,
    outputAssignment.length,
  ]);

  return (
    <Box>
      <Stack spacing={3}>
        {logData.inputs.length > 0 ? (
          <Box>
            <Typography variant="h3">Assign Outputs to Inputs</Typography>
            <Stack direction={"row"} sx={{ justifyContent: "space-evenly" }}>
              <Box sx={{ pr: 5 }}>
                <Paper>
                  <Typography variant="h5">Inputs</Typography>
                  <Box flexDirection={"column"}>
                    {logData.inputs.map((input, idx) => {
                      return (
                        <Box key={idx + 1} sx={{ py: "4px" }}>
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="exec-sum-accordion"
                              id="exec-sum-accordion"
                            >
                              <Typography>
                                Input {idx + 1}: {input[0].substring(0, 50)}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <pre>Command: {input[0].substring(0, 200)}</pre>
                              <Typography>Time: {input[1]}</Typography>
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                      );
                    })}
                  </Box>
                </Paper>
              </Box>
              <Box sx={{ pl: 5 }}>
                <Paper sx={{ sl: 5 }}>
                  <Typography variant="h5">Outputs</Typography>
                  <Box flexDirection={"column"}>
                    {outputAssignment.length > 0 &&
                      logData.outputs.map((output, idx) => {
                        return (
                          <Stack
                            direction="row"
                            key={idx + 1}
                            sx={{ p: "4px" }}
                          >
                            <FormControl>
                              <InputLabel required>Output for</InputLabel>
                              <Select
                                value={outputAssignment[idx].destination}
                                onChange={(e) => {
                                  const updatedOutputAssignment = [
                                    ...outputAssignment,
                                  ];
                                  updatedOutputAssignment[idx] = {
                                    ...updatedOutputAssignment[idx],
                                    destination: e.target.value,
                                  };
                                  setOutputAssignment(updatedOutputAssignment);
                                }}
                              >
                                {logData.inputs.map((input, idx2) => {
                                  return (
                                    <MenuItem key={input[0]} value={idx2 + 1}>
                                      Input {idx2 + 1}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <Accordion sx={{ width: "60%" }}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="exec-sum-accordion"
                                id="exec-sum-accordion"
                              >
                                <Typography>
                                  Output {idx + 1} @ {output[1]}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <pre
                                  style={{
                                    whiteSpace: "pre-wrap",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {output[0]}
                                </pre>
                              </AccordionDetails>
                            </Accordion>
                          </Stack>
                        );
                      })}
                  </Box>
                </Paper>
              </Box>
            </Stack>
            <LoadingButton
              variant="contained"
              loading={submitBtnLoading}
              onClick={uploadPairings}
              color={submitBtnColor}
            >
              {submitBtnText}
            </LoadingButton>
          </Box>
        ) : (
          <Box>
            <Stack spacing={2}>
              <Typography fontWeight={"bold"} variant="h4">
                Upload Cobalt Strike Log
              </Typography>
              {/* File Name */}
              <Typography component={"span"}>
                Choose Log File
                <TextField
                  sx={{ marginLeft: 2 }}
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Typography>
              {/* Loading Button */}
              <LoadingButton
                variant="contained"
                loading={submitBtnLoading}
                onClick={uploadCobaltStrike}
                color={submitBtnColor}
              >
                {submitBtnText}
              </LoadingButton>
            </Stack>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default UploadC2Beacon;
