import React from "react";
import { FormControl, Switch } from "@mui/material";

const FormBool = (props) => {
  const handleToggleChange = (e) => {
    props.setFormData((prev) => ({
      ...prev,
      [props.formKey]: e.target.checked,
    }));
  };

  let styles = {};

  if (!props.noDetails) {
    styles = { width: `${props.split[0]}%` || "25%" };
  }

  return (
    <FormControl sx={styles}>
      <Switch
        checked={props.formData[props.formKey] || false}
        onChange={handleToggleChange}
        label={props.label}
      />
    </FormControl>
  );
};

FormBool.defaultProps = {
  required: false,
};

export default FormBool;
