import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { getRequest } from "../../../Helpers/httpRequests";
import { DataContext } from "../../../Context/dataContext";
import {
  FormControl,
  InputLabel,
  Checkbox,
  Select,
  MenuItem,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import { useAppSelector } from "../../../Redux/app/hooks";

const CustomerAccessesFiltered = ({ formData, setFormData }) => {
  // ----- Context -----
  const { accessToken } = useContext(DataContext);
  const user = useAppSelector((state) => state.user);
  // ----- States -----
  const [customers, setCustomers] = useState([]);

  // ----- Variables -----
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleCxAccessChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value[value.length - 1] === "all") {
      setFormData((oldData) => ({
        ...oldData,
        customer_access:
          oldData.customer_access.length === customers.length
            ? []
            : customers.map((customer) => customer.uuid), // Save only UUIDs
      }));
      return;
    }

    setFormData((oldData) => ({
      ...oldData,
      customer_access: value, // Save selected UUIDs
    }));
  };

  useEffect(() => {
    const getfilteredCustomers = async () => {
      const res = await getRequest(`/react/api/get-cx-accesses`, accessToken);
      if (res.status === 200) {
        setCustomers(res.data);
      }
    };

    if (user.current_customer) {
      getfilteredCustomers();
    }
  }, [user.current_customer, accessToken]);

  return (
    <FormControl required>
      <InputLabel id="cx-multiple-checkbox-label">Customer Accesses</InputLabel>
      <Select
        labelId="cx-multiple-checkbox-label"
        id="cx-multiple-checkbox"
        multiple
        value={formData.customer_access}
        onChange={handleCxAccessChange}
        input={<OutlinedInput label="Customer Accesses" />}
        renderValue={(selected) => {
          if (selected.length === customers.length) return "All";
          else {
            const selectedCustomers = customers.filter((customer) =>
              selected.includes(customer.uuid)
            );
            return selectedCustomers.map((x) => x.name).join(",");
          }
        }}
        MenuProps={MenuProps}
      >
        <MenuItem sx={{ paddingLeft: 0 }} value="all">
          <Checkbox
            id="all-cx-checkbox"
            checked={
              customers.length > 0 &&
              customers.length === formData.customer_access.length
            }
            indeterminate={
              formData.customer_access.length > 0 &&
              formData.customer_access.length < customers.length
            }
          />
          <ListItemText primary="Select All" />
        </MenuItem>

        {customers.map((cx) => (
          <MenuItem key={cx.uuid} value={cx.uuid}>
            <Checkbox checked={formData.customer_access.includes(cx.uuid)} />
            <ListItemText primary={cx.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

CustomerAccessesFiltered.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
};

export default CustomerAccessesFiltered;
