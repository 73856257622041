import { Breadcrumbs, Typography } from "@mui/material";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { encryptId } from "../../../../Helpers/functions/uni_funcs";
import { useAppSelector } from "../../../../Redux/app/hooks";
import { AVDetailsContext } from "../ContextProvider/ContextProvider";

const Bcrumbs = () => {
  const { alertID } = useContext(AVDetailsContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Typography color="text.primary">Alert Validation</Typography>
      <Link
        to={`/${current_customer.uuid}/alertvalidation/scheduled-exercises`}
      >
        Scheduled Exercises
      </Link>
      <Link
        to={`/${current_customer.uuid}/alertvalidation/alerts/history/${encryptId(alertID)}`}
      >
        Exercise History
      </Link>
      <Typography color="text.primary">Alert Activity</Typography>
    </Breadcrumbs>
  );
};

export default Bcrumbs;
