import moment from "moment";

/**
 * Organizes a list of alerts by day and returns an array of objects where each object is a day
 * containing 3 fields: campaigns, day, value.
 * This is for the calendar data prop.
 * @param {Array} alerts
 * @returns Array of days
 */
export const scoreAlerts = (alerts) => {
  // create an object so that we can store each day we come across into it.
  const calendarData = {};

  /**
   * Loop through each of the alerts and score it.
   * If the alerts status === passed, then score it 100
   * If the alerts status === failed, then score it 0
   * Otherwise we don't add it to the calendarData because it
   * may either still be running or the results havne't been processed yet.
   */
  for (const i in alerts) {
    const status = alerts[i].alert_validation_status;
    // const uuid = alerts[i].alert_validation.uuid;

    // if status isn't passed or failed, don't add to calendar.
    if (status !== "passed" && status !== "processed" && status !== "failed")
      continue;

    // format the time to the format <CalendarWithStyles /> uses.
    const date = new Date(alerts[i].start);

    // if the date isn't in our calendarData, add it.
    if (calendarData[moment(date).format("YYYY-MM-DD")] === undefined) {
      calendarData[moment(date).format("YYYY-MM-DD")] = {
        campaigns: [],
        day: date,
        value: 100,
      };
    }

    // score 100 for passed
    if (status === "failed") {
      alerts[i].value = 0;
      calendarData[moment(date).format("YYYY-MM-DD")].value = 0;
    }

    // if it's not failed , it has to be 'passed'
    alerts[i].value = 100;

    // add the campaign to the days campaign list.
    calendarData[moment(date).format("YYYY-MM-DD")].campaigns.push(alerts[i]);
  }

  // convert the object to an array.
  let data = [];
  for (const day in calendarData) data.push(calendarData[day]);
  return data;
};
