import React, { useLayoutEffect } from "react";
import { Paper } from "@mui/material";
import { Stack } from "@mui/system";
import * as am5 from "@amcharts/amcharts5";
import * as am5flow from "@amcharts/amcharts5/flow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useAppSelector } from "../../../Redux/app/hooks";

const SankeyDiagram = (props) => {
  const sankey_data = props.sankey_data;
  const includeUnassigned = props.includeUnassigned;
  const current_customer = useAppSelector((state) => state.customer);

  // ----- On page load -----

  useLayoutEffect(() => {
    // let chart = am4core.create("sankey-chart", am4charts.SankeyDiagram);

    let root = am5.Root.new("sankey-chart");

    const getData = async () => {
      // am4core.ready(function () {
      //   chart.data = sankey_data

      //   let hoverState2 = chart.links.template.states.create("hover");
      //   chart.links.template.colorMode = "gradient";
      //   hoverState2.properties.fillOpacity = 0.8;

      //   // Exporting
      //   chart.exporting.menu = new am4core.ExportMenu();
      //   chart.exporting.menu.items = [
      //     {
      //       label: "...",
      //       menu: [
      //         {
      //           type: "png",
      //           label: "PNG",
      //         },
      //         {
      //           type: "jpg",
      //           label: "JPG",
      //         },
      //         {
      //           type: "svg",
      //           label: "SVG",
      //         },
      //         {
      //           type: "pdf",
      //           label: "PDF",
      //         },
      //       ],
      //     },
      //   ];
      //   chart.exporting.filePrefix = "blindspot";

      //   chart.dataFields.fromName = "from";
      //   chart.dataFields.toName = "to";
      //   chart.dataFields.value = "value";
      //   chart.dataFields.color = "nodeColor";
      //   chart.paddingRight = 120;

      //   let nodeTemplate = chart.nodes.template;
      //   nodeTemplate.inert = true;
      //   nodeTemplate.readerTitle = "Drag me!";
      //   nodeTemplate.showSystemTooltip = true;
      //   nodeTemplate.width = 30;
      //   nodeTemplate.readerTitle = "Click to show/hide or drag to rearrange";
      //   nodeTemplate.showSystemTooltip = true;
      //   nodeTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      //   nodeTemplate.nameLabel.label.width = 250;

      //   // let title2 = chart.titles.create();
      //   // title2.text = "Detection Pipeline By Tactic";
      //   // title2.fontSize = 25;
      //   // title2.marginBottom = 30;
      // });

      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([am5themes_Animated.new(root)]);

      // Create series
      // https://www.amcharts.com/docs/v5/charts/flow-charts/
      let series = root.container.children.push(
        am5flow.Sankey.new(root, {
          sourceIdField: "from",
          targetIdField: "to",
          valueField: "value",
          paddingRight: 100,
          nodeWidth: 30,
        })
      );

      series.nodes.get("colors").set("step", 2);

      series.nodes.rectangles.template.set("templateField", "nodeSettings");
      series.links.template.set("templateField", "linkSettings");

      const settings = [
        {
          id: "Blocked",
          nodeSettings: {
            fill: am5.color(0x28a745),
            stroke: am5.color(0x000000),
          },
        },
        {
          id: "Alerted",
          nodeSettings: {
            fill: am5.color(0x007bff),
            stroke: am5.color(0x000000),
          },
        },
        {
          id: "Logged",
          nodeSettings: {
            fill: am5.color(0xffc107),
            stroke: am5.color(0x000000),
          },
        },
      ];

      // I really don't want to have a straggling "No Evidence" block when we're not showing those so this removes it
      if (includeUnassigned) {
        settings.push({
          id: "No Evidence",
          nodeSettings: {
            fill: am5.color(0xfc3b3b),
            stroke: am5.color(0x000000),
          },
        });
      } else {
        sankey_data.forEach((element) => {
          if (element.to === "No Evidence" || element.from === "No Evidence") {
            const idx = sankey_data.indexOf(element);
            sankey_data.splice(idx, 1);
          }
        });
      }

      series.nodes.labels.template.setAll({
        x: am5.percent(80),
      });
      series.nodes.data.setAll(settings);
      series.data.setAll(sankey_data);

      // Make stuff animate on load
      series.appear(1000, 100);
    };
    getData();

    return () => {
      root.dispose();
    };
  }, [current_customer, sankey_data, includeUnassigned]);

  return (
    <Stack spacing={3} id="sankey-diagram">
      <Paper sx={{ height: "75vh", p: 1.5 }}>
        <div style={{ height: "100%" }} id="sankey-chart"></div>
      </Paper>
    </Stack>
  );
};

export default SankeyDiagram;
