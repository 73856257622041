import {
  Stack,
  TextField,
  Typography,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { postRequest } from "../../../../Helpers/httpRequests";
import { DataContext } from "../../../../Context/dataContext";
import { FaRegTimesCircle } from "react-icons/fa";
import { useAppSelector } from "../../../../Redux/app/hooks";

const UploadUniFileModal = ({
  setChangesMade,
  setOpenFileModal,
  setUniFiles,
}) => {
  const [files, setFiles] = useState([]);
  const { accessToken } = useContext(DataContext);
  const customer = useAppSelector((state) => state.customer);

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles([...files, ...selectedFiles]);
  };

  const handleDeleteFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const saveFiles = async () => {
    if (files.length === 0) {
      toast.error("Please select a file");
      return;
    }

    try {
      const infoToast = toast.info("Attempting to save Universal Files");

      const fileData = {};
      for (const file of files) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          fileData[file.name] = reader.result.split(",")[1];

          if (Object.keys(fileData).length === files.length) {
            // When all files are processed, send the request
            sendRequest(fileData);
            setChangesMade(false);
            setOpenFileModal(false);
            toast.dismiss(infoToast);
          }
        };
      }
    } catch (error) {
      toast.error("Failed to connect to server");
    }
  };

  const sendRequest = async (fileData) => {
    const requestData = {
      files: JSON.stringify(fileData),
    };

    try {
      const res = await postRequest(
        `/react/api/${customer.uuid}/payload_builder/uniFile`,
        accessToken,
        requestData,
        false
      );

      if (res.status === 200) {
        toast.success("Files saved Successfully!");
        // Process the uploaded files from fileData object and update the UniFiles state
        const uploadedFiles = Object.keys(fileData).map((fileName) => {
          const content = fileData[fileName];
          const byteCharacters = atob(content); // Decode base64
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const sizeKB = (byteArray.length / 1024).toFixed(2); // Calculate size in KB
          return {
            name: fileName,
            content: byteArray,
            size: sizeKB,
          };
        });

        // Update the UniFiles state by merging the uploaded files with existing files
        setUniFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
      } else {
        toast.error("Failed to save files");
      }
    } catch (error) {
      toast.error("Failed to connect to server");
    }
  };

  return (
    <Stack>
      <Typography fontWeight={"bold"} variant="h5" sx={{ marginBottom: 2 }}>
        Upload Universal Files
      </Typography>
      {files.map((file, index) => (
        <Box
          key={index}
          sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}
        >
          <Typography>{file.name}</Typography>
          <IconButton onClick={() => handleDeleteFile(index)}>
            <FaRegTimesCircle />
          </IconButton>
        </Box>
      ))}
      <TextField type="file" onChange={handleFileChange} />
      <Button sx={{ marginTop: 1 }} variant="outlined" onClick={saveFiles}>
        Upload
      </Button>{" "}
    </Stack>
  );
};

export default UploadUniFileModal;
