import {
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Modal,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { FaAtom, FaEllipsisV, FaFileDownload, FaUndo } from "react-icons/fa";
import { toast } from "react-toastify";
import C2BeaconForm from "../../../../Components/Forms/Simulations/C2BeaconForm/C2BeaconForm";
import { DataContext } from "../../../../Context/dataContext";
import { postRequest } from "../../../../Helpers/httpRequests";
import { SimLibraryContext } from "../provider/context";
import DownloadSimActions from "./DownloadSimActions";
import { useAppSelector } from "../../../../Redux/app/hooks";

const TitleAndButtons = () => {
  const { accessToken } = useContext(DataContext);
  const customer = useAppSelector((state) => state.customer);
  const { reloadComponents } = useContext(SimLibraryContext);
  const [open, setOpen] = useState(false);
  const [downloadSims, setDownloadSims] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseModal = () => {
    setOpen(false);
    reloadComponents();
  };

  const handleSimsClose = () => {
    setDownloadSims(false);
  };

  const handleRestore = async () => {
    const loadingToast = toast.loading(
      "Attempting to restore simulation thresholds"
    );
    const res = await postRequest(
      `/react/api/${customer.uuid}/simulations/thresholds/restore`,
      accessToken
    );
    if (res.status === 200) {
      toast.dismiss(loadingToast);
      toast.success("Successfully restored simulation thresholds to default");
    } else {
      toast.dismiss(loadingToast);
      toast.error("Failed to restore simulation thresholds defaults");
    }
  };

  const menuItems = [
    {
      icon: <FaFileDownload />,
      text: "Download Simulation Actions",
      action: () => setDownloadSims(true),
    },
    {
      icon: <FaAtom />,
      text: "New C2 Beacon",
      action: () => setOpen(true),
    },
    {
      icon: <FaUndo />,
      text: "Restore Thresholds Defaults",
      action: handleRestore,
    },
  ];

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
  };
  const current_customer = useAppSelector((state) => state.customer);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="h3">Library</Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton onClick={handleClick}>
          <FaEllipsisV />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          {menuItems.map((item, index) => (
            <Box key={index}>
              <MenuItem
                onClick={() => {
                  item.action();
                  handleCloseMenu();
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                {item.text}
              </MenuItem>
              {item.divider && <Divider />}
            </Box>
          ))}
        </Menu>
      </Box>

      <Modal open={open} onClose={handleCloseModal}>
        <Box sx={style}>
          <C2BeaconForm
            modal={false}
            handleClose={handleCloseModal}
            setOpen={setOpen}
          />
        </Box>
      </Modal>
      <Modal open={downloadSims} onClose={handleSimsClose}>
        <Box sx={style}>
          <DownloadSimActions
            modal={false}
            handleClose={handleSimsClose}
            setOpen={setDownloadSims}
            customer={current_customer}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default TitleAndButtons;
