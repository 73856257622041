import React, { useEffect, useContext } from "react";
import { Breadcrumbs, Typography, Paper, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import { getRequest } from "../../../Helpers/httpRequests";
import { DataContext } from "../../../Context/dataContext";
import TextLink from "../../../Components/TextLink/TextLink";
import zoho from "./zoho.js";
import { useAppSelector } from "../../../Redux/app/hooks";

const HelpPage = () => {
  // ----- Context -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer);

  // ----- States -----

  // ----- Variable -----

  // ----- Functions -----

  // ----- On page load -----

  useEffect(() => {
    const getData = async () => {
      const res = await getRequest("", accessToken);

      if (res.status === 200) {
        return;
      }
    };

    getData();
  }, [accessToken, current_customer]);

  return (
    <Stack spacing={3}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Support</Typography>
      </Breadcrumbs>

      <div dangerouslySetInnerHTML={{ __html: zoho }}></div>

      <Paper sx={{ p: 3 }}>
        <Typography sx={{ mb: 1 }} variant="h5" textAlign="center">
          Helpful Links
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextLink sx={{ textAlign: "center" }} url="/support/AWLBypass">
              Creating a campaign - AWL Bypass
            </TextLink>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextLink sx={{ textAlign: "center" }} url="/support/RunAgent">
              Downloading and Running an Agent with BlindSPOT
            </TextLink>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextLink sx={{ textAlign: "center" }} url="/support/BinaryEXE">
              Creating a Campaign - Binary Executable
            </TextLink>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextLink sx={{ textAlign: "center" }} url="/support/scorecampaign">
              Scoring a Campaign with BlindSPOT
            </TextLink>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextLink
              sx={{ textAlign: "center" }}
              url="/support/ProccessInjection"
            >
              Creating a Campaign - Process Injection
            </TextLink>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextLink sx={{ textAlign: "center" }} url="/support/walkthrough">
              BlindSPOT Walk Through the Simulation Library
            </TextLink>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextLink sx={{ textAlign: "center" }} url="/support/Agent">
              Creating an Agent with BlindSPOT
            </TextLink>
          </Grid>
        </Grid>
      </Paper>

      <TextLink url="/support/release-notes" sx={{ textAlign: "center" }}>
        Release Notes
      </TextLink>
    </Stack>
  );
};

export default HelpPage;
