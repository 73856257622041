import { Breadcrumbs, CircularProgress, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useContext, useEffect } from "react";
import { DateSelector } from "./DateSelector";
import { DownloadExcel } from "./DownloadExcel";
import { FocusData } from "./FocusData";
import { FocusContext } from "./Provider/Context";
import Provider from "./Provider/Provider";

export const AdminFocusContent = () => {
  const { loading, data, setLoading } = useContext(FocusContext);

  useEffect(() => {
    if (data.status === 200) {
      setLoading(false);
    }
  }, [data, setLoading]);
  return (
    <Stack spacing={3}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Administration</Typography>
        <Typography color="text.primary">Focus</Typography>
      </Breadcrumbs>

      {/* Page Title */}
      <Typography variant="h3">Focus</Typography>

      {/* Show CircularProgress while loading */}
      {loading ? (
        <CircularProgress />
      ) : (
        <Provider>
          <Stack direction={"row"}>
            <DateSelector />
            <DownloadExcel />
          </Stack>
          <FocusData />
        </Provider>
      )}
    </Stack>
  );
};
