import React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  InputLabel,
  Checkbox,
  Select,
  MenuItem,
  ListItemText,
  OutlinedInput,
} from "@mui/material";

const ProjectsMultiSelect = ({ formData, setFormData, projects }) => {
  // ----- Context -----

  // ----- States -----

  // ----- Variables -----
  /**
   * Multi-Select Styles for Customer Accesses in form
   */
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  // ---- Functions -----

  /**
   * This function handles the Customer Access select of the form.
   * @param {Event} event
   * @returns
   */
  const handleProjectSelectChange = (event) => {
    const {
      target: { value },
    } = event;

    // if the last item in array is "all", set forData.customer_access
    //to an empty array if it is full, otherwise set it to equal ALL the
    //simulations in the simulations state
    const uuids = []
    const names = []
    const allUuids = []

    projects.map((proj) => {
      if (value.includes(proj.name)) {
        uuids.push(proj.uuid)
      }
      names.push(proj.name)
      allUuids.push(proj.uuid)
      return ""
    })
    if (value[value.length - 1] === "all") {
      setFormData((oldData) => ({
        ...oldData,
        projects:
          oldData.projects.length === projects.length ? [] : names,
        projectUUIDs: allUuids
      }));
      return;
    }

    setFormData((oldData) => ({
      ...oldData,
      projects: typeof value === "string" ? value.split(",") : value,
      projectUUIDs: uuids
    }));
  };

  // ----- On page load -----

  return (
    <FormControl sx={{ width: "10rem" }}>
      <InputLabel id="ep-multiple-checkbox-label">Projects</InputLabel>
      <Select
        labelId="ep-multiple-checkbox-label"
        id="ep-multiple-checkbox"
        multiple
        value={formData.projects}
        onChange={handleProjectSelectChange}
        input={<OutlinedInput label="Projects" />}
        renderValue={(proj) => {
          return proj.join(",")
        }}
        MenuProps={MenuProps}
      >
        <MenuItem sx={{ paddingLeft: 0 }} value="all">
          <Checkbox
            id="all-ep-checkbox"
            checked={
              projects.length > 0 &&
              projects.length === formData.projects.length
            }
            indeterminate={
              formData.projects.length > 0 &&
              formData.projects.length < projects.length
            }
          />
          <ListItemText primary="Select All" />
        </MenuItem>

        {projects.map((e) => (
          <MenuItem key={e.uuid} value={e.name}>
            <Checkbox
              checked={formData.projects.some(
                (ep) => ep === e.name
              )}
            />
            <ListItemText primary={e.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};


ProjectsMultiSelect.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  projects: PropTypes.array.isRequired
};

export default ProjectsMultiSelect;
