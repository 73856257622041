import React, { useContext, useState } from "react";
import { Stack, Paper, Button, Typography, TextField } from "@mui/material";
import { useHttpRequest } from "../../../../Hooks";
import { DetailsContext } from "../ContextProvider/ContextProvider";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";

export const TimeSlotModal = ({ times, day, onSuccess }) => {
  const { schedule } = useContext(DetailsContext);

  const [formData, setFormData] = useState(
    times
      ? times
      : {
          day: day,
          start_hour: 0,
          start_minute: 0,
          end_hour: 0,
          end_minute: 1,
          tz: new Date().getTimezoneOffset() / 60,
        }
  );

  let url = "";
  if (times) {
    url = `/api/v2/schedule/${times.id}`;
  } else {
    url = `/api/v2/schedule/${schedule.id}`;
  }

  const { fetch: submit, response } = useHttpRequest({
    method: times ? "PATCH" : "POST",
    path: url,
    defer: true,
    data: formData,
  });

  if (response) {
    onSuccess();
  }

  const modalStyle = {
    position: "absolute",
    bgcolor: "background.paper",
    top: "50%",
    left: "50%",
    width: "40%",
    maxWidth: 1000,
    maxHeight: "90%",
    transform: "translate(-25%, -50%)",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
  };

  const handleTimeChange = (time, end) => {
    try {
      const hour = time ? time.getHours() : null;
      const minute = time ? time.getMinutes() : null;
      setFormData((prevData) => ({
        ...prevData,
        [`${end}_hour`]: hour,
        [`${end}_minute`]: minute,
      }));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Paper sx={modalStyle}>
      <Stack spacing={3}>
        <Typography variant="h2">Select Time Range</Typography>
        <Stack
          direction="row"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="Start Time"
              value={
                formData.start_hour !== null && formData.start_minute !== null
                  ? moment({
                      hour: formData.start_hour,
                      minute: formData.start_minute,
                    })
                  : null
              }
              onChange={(newValue) => {
                const selectedTime = newValue !== null ? newValue.$d : newValue;
                handleTimeChange(selectedTime, "start");
              }}
              renderInput={(inputProps) => (
                <TextField {...inputProps} variant="outlined" />
              )}
            />
            <TimePicker
              label="End Time"
              value={
                formData.end_hour !== null && formData.end_minute !== null
                  ? moment({
                      hour: formData.end_hour,
                      minute: formData.end_minute,
                    })
                  : null
              }
              onChange={(newValue) => {
                const selectedTime = newValue !== null ? newValue.$d : newValue;
                handleTimeChange(selectedTime, "end");
              }}
              renderInput={(inputProps) => (
                <TextField {...inputProps} variant="outlined" />
              )}
            />
          </LocalizationProvider>
        </Stack>
        <Button
          disabled={
            formData.end_hour === null ||
            isNaN(formData.end_hour) ||
            formData.end_minute === null ||
            isNaN(formData.end_minute) ||
            formData.start_hour === null ||
            isNaN(formData.start_hour) ||
            formData.start_minute === null ||
            isNaN(formData.start_minute)
          }
          onClick={() => {
            submit();
          }}
        >
          Submit
        </Button>
      </Stack>
    </Paper>
  );
};
