import { useState, useEffect, useContext, Fragment } from "react";
import {
  Typography,
  Grid,
  Breadcrumbs,
  Paper,
  Box,
  Button,
  ButtonGroup,
} from "@mui/material";
import { DataContext } from "../../../Context/dataContext";
import { getRequest, postRequest } from "../../../Helpers/httpRequests";
import { Stack } from "@mui/system";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { FaExclamationTriangle, FaEyeSlash } from "react-icons/fa";
import { useCallback } from "react";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import { useAppSelector } from "../../../Redux/app/hooks";

const AVLibDetails = () => {
  // ----- STATES && CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer,
  );
  const { avUUID } = useParams();
  const [simactionTableRows, setSimactionTableRows] = useState([]);
  const [simactionTableColumns, setSimactionTableColumns] = useState([]);

  const [data, setData] = useState({
    simulation: {},
    simactions: [],
    techniques: [],
    securitytools: [],
  });

  // ----- VARIABLES -----

  // ----- FUNCTIONS -----

  const changeThreshold = useCallback(
    async (mode, action = null) => {
      let postData;
      let res;
      // mode can be "single", "default", or "clear"
      let threshold;
      switch (mode) {
        case "single":
          if (action.blocked) {
            threshold = "blocked";
          } else if (action.alerted) {
            threshold = "alerted";
          } else if (action.logged) {
            threshold = "logged";
          } else if (action.nothing) {
            threshold = "nothing";
          } else {
            threshold = "clear";
          }
          postData = {
            mode: mode,
            action_uuid: action.uuid,
            threshold: threshold,
          };
          res = await postRequest(
            `/react/api/${current_customer.uuid}/simulations/updatesimthreshold`,
            accessToken,
            postData,
            false,
          );
          break;
        default:
          postData = {
            mode: mode,
            simulation_uuid: avUUID,
          };
          res = await postRequest(
            `/react/api/${current_customer.uuid}/simulations/updatesimthreshold`,
            accessToken,
            postData,
            false,
          );
          if (res.status === 200) {
            setData({ ...data, simactions: res.data.sim_actions });
          }
          break;
      }
    },
    [accessToken, data, avUUID, current_customer],
  );

  // TODO need to add in security tools drop down and pass them back in seralizer
  // Add Button Group

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getSimDetails = async () => {
      // Get simulation detail
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/alertvalidation/library/${avUUID}`,
        accessToken,
      );

      if (res.status === 200) {
        setData(res.data);
      }
    };

    getSimDetails();
  }, [current_customer, accessToken, avUUID]);

  useEffect(() => {
    const populatesimactions = (actions, securityTools) => {
      const columns = [
        {
          field: "name",
          headerName: "Name",
          minWidth: 100,
          flex: 1,
        },
        {
          field: "execType",
          headerName: "Execution Type",
          minWidth: 50,
          flex: 1,
        },
        {
          field: "threshold",
          headerName: "Threshold",
          minWidth: 100,
          flex: 1,
          renderCell: (params) => {
            let row = params.row;
            // const handleThresholdClick = async (row, threshold) => {
            //   let actions = data.simactions;
            //   const action = data.simactions.find(
            //     (obj) => obj?.uuid === row.id
            //   );

            //   actions[action.step - 1].alerted =
            //     threshold === "alerted" && !actions[action.step - 1].alerted
            //       ? true
            //       : false;
            //   actions[action.step - 1].nothing =
            //     threshold === "nothing" && !actions[action.step - 1].nothing
            //       ? true
            //       : false;

            //   setData({ ...data, actions });

            //   changeThreshold("single", actions[action.step - 1]);
            // };
            return (
              <>
                <ButtonGroup
                  variant="outlined"
                  aria-label="outlined button group"
                  size="large"
                  fullWidth
                >
                  <Button
                    color="primary"
                    variant={
                      row.threshold === "alerted" ? "contained" : "outlined"
                    }
                    // onClick={(e) => {
                    //   e.persist();
                    //   handleThresholdClick(params.row, "alerted");
                    // }}
                  >
                    <FaExclamationTriangle />
                  </Button>
                  <Button
                    color="error"
                    variant={
                      row.threshold === "nothing" ? "contained" : "outlined"
                    }
                    // onClick={(e) => {
                    //   e.persist();
                    //   handleThresholdClick(params.row, "nothing");
                    // }}
                  >
                    <FaEyeSlash />
                  </Button>
                </ButtonGroup>
                {row.alert_set_up === "true" && (
                  <Typography variant="subtitle2" color="error">
                    Alert Set Up
                  </Typography>
                )}
                {row.clean_up === "true" && (
                  <Typography variant="subtitle2" color="error">
                    Alert Clean Up
                  </Typography>
                )}
              </>
            );
          },
        },
      ];

      const rows = actions.map((action, index) => {
        return {
          id: action.uuid,
          name: action.name,
          execType: action.execution_type,
          securityTool: action.security_tool || "",
          threshold:
            action.alert_set_up === "true" || action.clean_up === "true"
              ? "nothing"
              : "alerted",
          clean_up: action.clean_up,
          alert_set_up: action.alert_set_up,
          index: index,
        };
      });

      setSimactionTableRows(rows);
      setSimactionTableColumns(columns);
    };

    const setActionTable = async () => {
      populatesimactions(data.simactions, data.securitytools);
    };
    if (data.simactions.length !== 0) {
      setActionTable();
    }
  }, [data, avUUID, accessToken, changeThreshold]);

  return (
    <Stack spacing={1}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Alert Validation</Typography>
        <Link
          className="text-links"
          to={`/${current_customer.uuid}/alertvalidation/library`}
        >
          Exercise Library
        </Link>
        <Typography color="text.primary">Exercise Details</Typography>
      </Breadcrumbs>
      {data && (
        <Fragment>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Typography component={"span"} fontWeight={"bold"} variant="h4">
              {data?.simulation?.name}
            </Typography>
          </Box>

          <Paper sx={{ p: 1 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
              Exercise Details
            </Typography>
            <Stack direction="row">
              {/* Headers */}
              <Stack spacing={2} sx={{ mr: 3 }}>
                {/* name */}
                <Typography variant="body1" fontWeight="bold">
                  Name:
                </Typography>
                {/* category */}
                <Typography variant="body1" fontWeight="bold">
                  Category:
                </Typography>
                {/* platform */}
                <Typography variant="body1" fontWeight="bold">
                  Platform:
                </Typography>
                {/* description */}
                <Typography variant="body1" fontWeight="bold">
                  Description:
                </Typography>
              </Stack>
              {/* Values */}
              <Stack spacing={2}>
                {/* name value */}
                <Typography variant="body1">
                  {data?.simulation?.name}
                </Typography>
                {/* category value */}
                <Typography variant="body1">
                  {data?.simulation?.category?.name}
                </Typography>
                {/* platform value */}
                <Typography variant="body1">
                  {data?.simulation?.platform}
                </Typography>
                {/* desc value */}
                <Typography variant="body1">
                  {data?.simulation?.description}
                </Typography>
              </Stack>
            </Stack>
          </Paper>

          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                <Paper>
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      padding: "0.5rem 0",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6" sx={{ fontWeight: "bold", p: 1 }}>
                      Exercise Details Steps and Thresholds{" "}
                    </Typography>
                    <ButtonGroup
                      aria-label="outlined button group"
                      size="large"
                    >
                      <Button
                        variant="contained"
                        sx={{ marginX: "8px", paddingX: "8px" }}
                        onClick={() => changeThreshold("default")}
                      >
                        Restore Defaults
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ marginX: "8px", paddingX: "8px" }}
                        onClick={() => changeThreshold("clear")}
                      >
                        Clear
                      </Button>
                    </ButtonGroup>
                  </Stack>
                  <DataGridWithStyles
                    name="av-library-details"
                    autoHeight
                    rows={simactionTableRows}
                    columns={simactionTableColumns}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Fragment>
      )}
    </Stack>
  );
};

export default AVLibDetails;
