import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const getAccessTokenFromCookies = (): string => {
  const token = Cookies.get("token");
  if (token !== undefined) {
    return token;
  }
  return null;
};

const initialState: string = getAccessTokenFromCookies();

export const accessTokenSlice = createSlice({
  name: "darkmode",
  initialState,
  reducers: {
    storeAccessToken: (state, action) => {
      Cookies.set("token", action.payload);
      return action.payload;
    },
  },
});

export const { storeAccessToken } = accessTokenSlice.actions;
export default accessTokenSlice.reducer;
