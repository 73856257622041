import React, { useEffect, useContext, useState } from "react";
import { getRequest, postRequest } from "../../../../Helpers/httpRequests";
import {
  Divider,
  Stack,
  Typography,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataContext } from "../../../../Context/dataContext";
import PropTypes from "prop-types";
import { useAppSelector } from "../../../../Redux/app/hooks";

const ExecutionThresholdSettingsForm = ({ setOpen, modal = true }) => {
  // ----- CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  // ----- STATES -----

  /**
   * Object to hold the values of each input in form.
   */
  const [executionThresholds, setExecutionThresholds] = useState({});

  /**
   * Used for handling the props/children of <LoadingButton>
   */
  const [loadingBtn, setLoadingBtn] = useState({
    loading: false,
    color: "primary",
    text: "Submit",
    helperText: "",
    helperTextColor: "green",
  });

  // ---- Variables ------

  const results = ["No Evidence", "Blocked", "Alerted", "Logged"];

  // ----- Functions -----

  /**
   * Handles the form submission.
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    // prevent page reload on submit
    e.preventDefault();

    // set submit btn to loading
    setLoadingBtn((prev) => ({ ...prev, loading: true }));

    const data = {
      ...executionThresholds,
      customer_uuid: current_customer,
    };

    const res = await postRequest(
      `/react/api/${current_customer.uuid}/executionThresholds`,
      accessToken,
      data,
      false
    );
    setLoadingBtn((prev) => ({ ...prev, loading: false }));
    if (res.status === 200) {
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Success",
        color: "success",
        helperText: "Success",
        helperTextColor: "#2e7d32", // green
      }));

      /**
       * If this form is contained in a modal, you can pass the setOpen state to this component.
       * This will wait 1.5 seconds and then close the modal
       * Add 'open' state to this components parent useEffect dependencies array to have your page reload the data.
       */
      if (setOpen !== undefined) {
        setTimeout(() => setOpen(false), 1500);
      }
    } else {
      // handle errors here
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Error - Try again",
        color: "error",
        helperText: "Form submission failed",
        helperTextColor: "#d32f2f", // red
      }));
      console.log(
        "%cerror ExecutionThresholdSettingsForm.jsx handleSubmit()",
        "color: red; display: block; width: 100%;",
        "Failed to submit form"
      );
    }
  };

  // ----- On page load -----
  useEffect(() => {
    const getThresholdSettings = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/executionThresholds?customer_uuid=${current_customer.uuid}`,
        accessToken
      );
      if (res.status === 200) {
        setExecutionThresholds(res.data.thresholds);
      }
    };

    getThresholdSettings();
  }, [current_customer, accessToken]);

  return (
    <Box>
      {executionThresholds.windows_binary ? (
        <div>
          <Typography variant="h5">Change Execution Thresholds</Typography>
          <Typography variant="p">
            What you expect to happen whenever the payload is ran
          </Typography>
          <Divider style={{ marginBottom: "0.5rem" }} />
          {/* FORM */}
          <form onSubmit={(e) => handleSubmit(e)}>
            <Stack direction="column" spacing={2}>
              {/* Start form fields here */}

              <FormControl>
                <InputLabel required>Windows Binary</InputLabel>
                <Select
                  value={executionThresholds.windows_binary}
                  onChange={(e) =>
                    setExecutionThresholds({
                      ...executionThresholds,
                      windows_binary: e.target.value,
                    })
                  }
                  label="Windows Binary"
                >
                  {results.map((result) => {
                    return (
                      <MenuItem key={result} value={result.toLowerCase()}>
                        {result}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel required>Windows Binary - In Memory</InputLabel>
                <Select
                  value={executionThresholds.windows_binary_inmem}
                  onChange={(e) =>
                    setExecutionThresholds({
                      ...executionThresholds,
                      windows_binary_inmem: e.target.value,
                    })
                  }
                  label="Windows Binary - In Memory"
                >
                  {results.map((result) => {
                    return (
                      <MenuItem key={result} value={result.toLowerCase()}>
                        {result}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel required>Windows DLL</InputLabel>
                <Select
                  value={executionThresholds.windows_dll}
                  onChange={(e) =>
                    setExecutionThresholds({
                      ...executionThresholds,
                      windows_dll: e.target.value,
                    })
                  }
                  label="Windows DLL"
                >
                  {results.map((result) => {
                    return (
                      <MenuItem key={result} value={result.toLowerCase()}>
                        {result}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel required>
                  Windows Process Injection - Remote Process
                </InputLabel>
                <Select
                  value={executionThresholds.windows_pi_remote}
                  onChange={(e) =>
                    setExecutionThresholds({
                      ...executionThresholds,
                      windows_pi_remote: e.target.value,
                    })
                  }
                  label="Windows Process Injection - Remote Process"
                >
                  {results.map((result) => {
                    return (
                      <MenuItem key={result} value={result.toLowerCase()}>
                        {result}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel required>
                  Windows Process Injection - Local Process
                </InputLabel>
                <Select
                  value={executionThresholds.windows_pi_local}
                  onChange={(e) =>
                    setExecutionThresholds({
                      ...executionThresholds,
                      windows_pi_local: e.target.value,
                    })
                  }
                  label="Windows Process Injection - Local Process"
                >
                  {results.map((result) => {
                    return (
                      <MenuItem key={result} value={result.toLowerCase()}>
                        {result}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel required>Linux Binary</InputLabel>
                <Select
                  value={executionThresholds.linux_binary}
                  onChange={(e) =>
                    setExecutionThresholds({
                      ...executionThresholds,
                      linux_binary: e.target.value,
                    })
                  }
                  label="Linux Binary"
                >
                  {results.map((result) => {
                    return (
                      <MenuItem key={result} value={result.toLowerCase()}>
                        {result}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              {/* End form fields */}
              {/* helper text */}
              <Typography
                color={loadingBtn.helperTextColor}
                variant="subtitle2"
              >
                {loadingBtn.helperText}
              </Typography>
              {/* Submit Button */}
              <LoadingButton
                loading={loadingBtn.loading}
                color={loadingBtn.color}
                type="submit"
                variant="contained"
              >
                {loadingBtn.text}
              </LoadingButton>
            </Stack>
          </form>
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100vw",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {/* Form Header */}
    </Box>
  );
};

ExecutionThresholdSettingsForm.propTypes = {
  setOpen: PropTypes.func,
  modal: PropTypes.bool,
};

export default ExecutionThresholdSettingsForm;
