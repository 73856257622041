import { Box, Button, Alert } from "@mui/material";
import React, { useContext } from "react";
import { FaSync } from "react-icons/fa";
import { DataContext } from "../../../../Context/dataContext";
import { getRequest } from "../../../../Helpers/httpRequests";
import CampaignContext from "../provider/CampaignContext";
import { useAppSelector } from "../../../../Redux/app/hooks";

const RefreshScore = () => {
  const {
    refresh,
    executedUUID,
    setTimeline,
    setOutcomeCounts,
    setCampaign,
    setReload,
    setRefresh,
  } = useContext(CampaignContext); // Access campaign from context
  const { accessToken } = useContext(DataContext);
  const customer = useAppSelector((state) => state.customer);
  // Function to refresh the data
  const refreshData = async () => {
    setReload((current) => !current);
    const [res, res2] = await Promise.all([
      getRequest(
        `/react/api/${customer.uuid}/report/campaign/timeline/${executedUUID}`,
        accessToken
      ),
      getRequest(`/react/api/${customer.uuid}/report/campaign/${executedUUID}`, accessToken),
    ]);
    if (res.status === 200 && res2.status === 200) {
      setTimeline(res.data.timeline);
      setOutcomeCounts(res2.data.outcome_counts);
      setCampaign(res2.data.campaign);
    }
    setRefresh(false);
  };
  if (!refresh) {
    return (
      <>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            direction: "row",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 40,
          }}
        ></Box>
      </>
    );
  }

  if (refresh) {
    return (
      <>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            direction: "row",
            justifyContent: "left",
            alignItems: "center", // Align items to the center vertically
            gap: 1, // Add some gap between the button and the alert
            maxHeight: 40,
          }}
        >
          <Button onClick={refreshData} aria-label="Refresh" variant="outlined">
            <FaSync />
          </Button>
          <Alert severity="info" sx={{ flex: 1 }}>
            Changes Made! Please Refresh to Update Timeline and Score
          </Alert>
        </Box>
      </>
    );
  }
};

export default RefreshScore;
