import React, {useContext, useEffect, useState} from "react";
import {getRequest, postRequest} from "../../../../Helpers/httpRequests";
import {Button, CircularProgress, Divider, Stack, Step, StepLabel, Stepper, Typography,} from "@mui/material";
import moment from "moment-timezone";
import {LoadingButton} from "@mui/lab";
import {DataContext} from "../../../../Context/dataContext";
import PropTypes from "prop-types";
import AlertDetailForm from "./AlertDetailForm";
import AlertSimulationForm from "./AlertISimulationForm";
import AlertReviewForm from "./AlertReviewForm";
import ExpectedAlertsForm from "./ExpectedAlertsForm";
import {useAppSelector} from "../../../../Redux/app/hooks";

const AlertForm = ({
                     setOpen,
                     alert = null,
                     modal = true,
                     simulation = "",
                   }) => {
  // ----- CONTEXT -----
  const {accessToken} = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  // ----- STATES -----

  /**
   * Object to hold the values of each input in form.
   */
  const [formData, setFormData] = useState({
    name: "",
    simulation: simulation,
    architecture: "",
    payload: {},
    endpoint: "",
    frequency: "daily",
    time: "",
    weekday: "Monday",
    monthday: 1,
    security_stack: "",
    sla_time: 30,
    verbose: false,
    debug: false,
    bypassReport: true,
    outputToDisk: false,
    inMemory: false,
    piTarget: "",
    piMethod: "",
    execution: "",
    sleepTime: 2,
    maxRuntime: 30,
    jitterPct: 0,
    expected_alerts: [],
    auto_close: true,
  });

  const [piTargets, setPiTargets] = useState([]);

  const [formPage, setFormPage] = useState(0);

  const pageFlow = [
    AlertDetailForm,
    ExpectedAlertsForm,
    AlertSimulationForm,
    AlertReviewForm,
  ];

  const [nxtBtnDisabled, setNxtBtnDisabled] = useState(true);

  const [dataLoaded, setDataLoaded] = useState(false);

  const [formOptions, setFormOptions] = useState({
    simulation: [simulation],
    endpoints: [],
    securityStacks: [],
    expected_alerts: [],
  });

  const steps = [
    "Exercise Details",
    "Expected Alerts",
    "Exercise Execution Options",
    "Review and Create",
  ];

  const CurrentPage = pageFlow[formPage];

  /**
   * Used for handling the props/children of <LoadingButton>
   */
  const [loadingBtn, setLoadingBtn] = useState({
    loading: false,
    color: "primary",
    text: "Activate",
    helperText: "",
    helperTextColor: "green",
    disabled: true,
  });

  // ---- Variables ------

  /**
   * These are the styles for the Box inside of the modal.
   * The Modal component renders as position Absolute so we
   * have to write styles accordingly
   *
   */
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    maxWidth: "50%",
  };

  // ----- Functions -----

  // This take in the execution from the alert and returns an array of the arch of 32 or 64 bit and the exec method
  const getarchandexec = (exec) => {
    let arch = "";
    let execution = "";
    if (exec.includes("sh")) {
      execution = exec.substring(0, 2);
      arch = exec.substring(2);
    } else {
      execution = exec.substring(0, 3);
      arch = exec.substring(3);
    }
    return [arch, execution];
  }

  /**
   * Handles the form submission.
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    // prevent page reload on submit
    e.preventDefault();

    // set submit btn to loading
    setLoadingBtn((prev) => ({...prev, loading: true}));

    let url = "";

    if (alert) {
      url = `/react/api/${current_customer.uuid}/alertvalidation/alert/edit`;
    } else {
      url = `/react/api/${current_customer.uuid}/alertvalidation/alert/add`;
    }
    // fix formData values here
    const sanitizedFormData = formData;
    sanitizedFormData.piTarget = sanitizedFormData.piTarget.uuid;
    // sanitizedFormData.execution = sanitizedFormData.execution.value;
    const res = await postRequest(url, accessToken, sanitizedFormData, true);
    setLoadingBtn((prev) => ({...prev, loading: false}));

    // send form response back as 201 (created)
    if (res.status === 201 || res.status === 200) {
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Success",
        color: "success",
        helperText: "Success",
        helperTextColor: "#2e7d32", // green
      }));

      /**
       * If this form is contained in a modal, you can pass the setOpen state to this component.
       * This will wait 1.5 seconds and then close the modal
       * Add 'open' state to this components parent useEffect dependencies array to have your page reload the data.
       */
      if (setOpen !== undefined) {
        setTimeout(() => setOpen(false), 1500);
      }
    } else {
      // handle errors here
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Error - Try again",
        color: "error",
        helperText: "Form submission failed",
        helperTextColor: "#d32f2f", // redh
      }));
      console.log(
        "%cerror AlertForm.jsx handleSubmit()",
        "color: red; display: block; width: 100%;",
        "Failed to submit form"
      );
    }
  };

  const handleNavigation = (direction) => {
    if (direction === "next") {
      setFormPage((prevPage) => prevPage + 1);
    } else if (direction === "prev") {
      setFormPage((prevPage) => prevPage - 1);
    }
  };

  // ----- On page load -----
  /**
   * Whenever the form is updated, validate the inputs.
   */
  useEffect(() => {
    /**
     * before allowing the user to click next, ensure that all required fields have been filled.
     */
    const validatePage = () => {
      // alert name
      if (formData.name === "") return true;

      // endpoint
      if (formData.endpoint === "") return true;

      // security stack
      if (formData.security_stack === "") return true;

      // detection time Threshold

      // time to run
      if (formData.time === "") return true;

      // frequency
      if (formData.frequency === "") return true;

      // PAGE 2

      if (formPage === 2 && formData.execution === "") return true;
      if (formPage === 2 && formData.architecture === "") return true;

      return false;
    };
    setNxtBtnDisabled(validatePage());
    validatePage();
  }, [formData, formPage]);

  useEffect(() => {

    const convertUtcToLocal = (utcHour) => {
      const timezone = moment.tz.guess();
      if (utcHour < 0 || utcHour > 23) {
        throw new Error("Hour must be between 0 and 23");
      }
      const utcMoment = moment.utc().hour(utcHour).minute(0).second(0);
      const localMoment = utcMoment.tz(timezone);
      return localMoment.hour();
    };
    const getData = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/alertvalidation/scheduleexercise/${simulation.uuid}`,
        accessToken
      );
      setFormOptions(res.data);
      const resPiTargets = await getRequest(
        `/react/api/${current_customer.uuid}/pi-targets`,
        accessToken
      );
      if (resPiTargets.status === 200) {
        setPiTargets(resPiTargets.data.pi_targets);
      }
      setDataLoaded(true);
    };
    getData();
    if (alert) {
      let archandexec = getarchandexec(alert.execution);
      const updatedFormData = {
        ...alert,
        time: convertUtcToLocal(alert.time),
        expected_alerts: alert.expected_alerts.map(
          (expectedAlert) => expectedAlert.uuid
        ),
        endpoint: alert.endpoint.uuid,
        security_stack: alert.security_stack.uuid,
        execution: archandexec[1],
        architecture: archandexec[0],
      };
      setFormData((prev) => ({...prev, ...updatedFormData}));
    }
  }, [current_customer, accessToken, alert, simulation]);

  return (
    <Stack spacing={2} sx={modal ? style : null}>
      {/* Form Header */}
      <Typography variant="h5">
        {alert ? "Edit" : "Add"} {simulation.name}
      </Typography>

      <Divider/>

      <Stepper activeStep={formPage} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {/* Check if dataloaded is true */}
      {dataLoaded ? (
        <form onSubmit={(e) => handleSubmit(e)}>
          <Stack spacing={2}>
            <CurrentPage
              formData={formData}
              setFormData={setFormData}
              formOptions={formOptions}
              simulation={simulation}
              piTargets={piTargets}
            />
            <Stack direction="row" spacing={2}>
              {/* Navigation Buttons */}
              {formPage > 0 && (
                <Button
                  variant="contained"
                  onClick={() => handleNavigation("prev")}
                >
                  Prev
                </Button>
              )}
              {formPage < pageFlow.length - 1 && (
                <Button
                  variant="contained"
                  onClick={() => handleNavigation("next")}
                  disabled={nxtBtnDisabled}
                >
                  Next
                </Button>
              )}
              {formPage === pageFlow.length - 1 && (
                <LoadingButton
                  loading={loadingBtn.loading}
                  color={loadingBtn.color}
                  type="submit"
                  variant="contained"
                >
                  {loadingBtn.text}
                </LoadingButton>
              )}
            </Stack>
          </Stack>
        </form>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress/>
        </div>
      )}
    </Stack>
  );
};

AlertForm.propTypes = {
  setOpen: PropTypes.func,
  modal: PropTypes.bool,
};

export default AlertForm;
