import React, { useEffect, useState, useContext } from "react";
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { getRequest, postRequest } from "../../../../Helpers/httpRequests";
import { DataContext } from "../../../../Context/dataContext";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../Redux/app/hooks";

const ServiceProviderSettingsForm = ({ selectedCustomer }) => {
  // ----- Context -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector((state) => state.customer);
  const customer = useAppSelector((state) => state.customer);

  // ----- STATES  -----
  const [reportTools, setReportTools] = useState([]);
  const [reportToolUUID, setReportToolUUID] = useState();
  const [licenseCount, setLicenseCount] = useState();

  // Submit Btn
  const [submitBtnColor, setSubmitBtnColor] = useState("primary");
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [submitBtnText, setSubmitBtnText] = useState("Submit");

  // ----- VARIABLES -----

  // ----- FUNCTIONS -----
  const handleSubmit = async () => {
    setSubmitBtnLoading(true);
    const data = {
      newReportingToolUUID: reportToolUUID,
      newLicenseCount: licenseCount,
      customer: selectedCustomer,
    };

    const res = await toast.promise(
      postRequest(
        `/react/api/serviceprovider/settings/edit/${selectedCustomer.uuid}`,
        accessToken,
        data
      ),
      {
        pending: "Updating Settings",
        success: "Settings updated",
        error: "Something went wrong!",
      }
    );

    setSubmitBtnLoading(false);
    if (res.status === 200) {
      setSubmitBtnColor("success");
      setSubmitBtnText("Success");
      setTimeout(() => {
        setSubmitBtnColor("primary");
        setSubmitBtnText("Submit");
      }, 2000);
    } else {
      setSubmitBtnColor("error");
      setSubmitBtnText("Error - Try again");
    }
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getFormData = async () => {
      const res = await getRequest(
        `/react/api/serviceprovider/settings/edit/${selectedCustomer.uuid}`,
        accessToken
      );
      try {
        setReportTools(res.data.reportTools);
        setLicenseCount(res.data.adminConfig.license_count);
        setReportToolUUID(res.data.adminConfig.report_tool.uuid);
      } catch (err) {
        console.log("Error while trying to set States from getFormData()", err);
      }
    };
    getFormData();
  }, [current_customer, customer, accessToken, selectedCustomer]);

  return (
    <Stack>
      <Typography variant="h5">{`Edit ${selectedCustomer.name} Settings`}</Typography>
      <Divider style={{ margin: "1rem 0" }} />

      <Stack spacing={2}>
        {/* Report Tool */}
        <FormControl>
          <InputLabel>Report Tool</InputLabel>
          <Select
            value={reportToolUUID || ""}
            label="Report Tool"
            onChange={(e) => setReportToolUUID(e.target.value)}
          >
            {reportTools?.map((tool) => (
              <MenuItem key={tool.uuid} value={tool.uuid}>
                {tool.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* License Count */}
        <TextField
          type="number"
          label="License Count"
          helperText=""
          value={licenseCount || ""}
          onChange={(e) => setLicenseCount(e.target.value)}
        />
        <LoadingButton
          loading={submitBtnLoading}
          onClick={handleSubmit}
          color={submitBtnColor}
          variant="contained"
        >
          {submitBtnText}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default ServiceProviderSettingsForm;
