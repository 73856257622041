import React from "react";
import { FaDesktop } from "react-icons/fa";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SecurityUpdateWarningIcon from "@mui/icons-material/SecurityUpdateWarning";
import { Stack, Typography, Card, CardHeader } from "@mui/material";
import DefenderSecurityCard from "./securityToolCards/DefenderSecurityCard";
import SentinelSecurityCard from "./securityToolCards/SentinelSecurityCard";
import CrowdstrikeSecurityCard from "./securityToolCards/CrowdstrikeSecurityCard";
import { useAppSelector } from "../../../../Redux/app/hooks";
import SentinelOneSecurityCard from "./securityToolCards/SentinelOneSecurityCard";

const SecurityFlowChart = (props) => {
  const { alertInfo, alertFinding } = props;
  const darkmode = useAppSelector((state) => state.darkmode);
  let machineName = alertInfo.av_campaign.machinename.name;

  const formattedName = (name) => {
    return name.charAt(0).toUpperCase() + name.slice(1);
  };
  return (
    <div className="flowchart-container">
      <Stack spacing={2} justifyContent="center" alignItems="center">
        <Card
          sx={{
            display: "flex",
            minWidth: "600px",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <CardHeader
            sx={{
              background: darkmode
                ? "linear-gradient(to right, rgb(75, 85, 99), rgb(17, 24, 39))"
                : "linear-gradient(to right, #e0eafc, #cfdef3)",
            }}
            title={
              <Stack direction="row" spacing={2} justifyContent="center">
                <FaDesktop color="black" fontSize="32px" />
                <Typography variant="h5">{machineName}</Typography>
              </Stack>
            }
          />
        </Card>
        <ArrowDownwardIcon />
        {alertFinding?.tool_data?.length > 0
          ? alertFinding.tool_data.map((tool, index) => {
              if (tool.tool_type === "defender") {
                return (
                  <DefenderSecurityCard
                    key={`sec-tool-flow-${index}`}
                    tool={formattedName(tool.tool_type)}
                    alertInfo={alertInfo}
                    tool_data={tool}
                  />
                );
              } else if (tool.tool_type === "sentinel") {
                return (
                  <SentinelSecurityCard
                    key={`sec-tool-flow-${index}`}
                    tool={formattedName(tool.tool_type)}
                    alertInfo={alertInfo}
                    tool_data={tool}
                  />
                );
              } else if (tool.tool_type === "crowdstrike") {
                return (
                  <CrowdstrikeSecurityCard
                    key={`sec-tool-flow-${index}`}
                    tool={formattedName(tool.tool_type)}
                    alertInfo={alertInfo}
                    alertFinding={alertFinding}
                    tool_data={tool}
                  />
                );
              } else if (tool.tool_type === "sentinelone") {
                return (
                  <SentinelOneSecurityCard
                    key={`sec-tool-flow-${index}`}
                    tool={formattedName(tool.tool_type)}
                    alertInfo={alertInfo}
                    alertFinding={alertFinding}
                    tool_data={tool}
                  />
                );
              }
              return null;
            })
          : alertInfo.av_campaign.alert_validation?.security_stack?.tools.map(
              (tool, index) => {
                if (tool.tool.product.toLowerCase() === "defender") {
                  return (
                    <DefenderSecurityCard
                      key={`sec-tool-flow-${index}`}
                      alertInfo={alertInfo}
                      tool={formattedName(tool.tool.product)}
                    />
                  );
                } else if (tool.tool.product.toLowerCase() === "sentinel") {
                  return (
                    <SentinelSecurityCard
                      key={`sec-tool-flow-${index}`}
                      alertInfo={alertInfo}
                      tool={formattedName(tool.tool.product)}
                    />
                  );
                } else if (tool.tool.product.toLowerCase() === "crowdstrike") {
                  return (
                    <CrowdstrikeSecurityCard
                      key={`sec-tool-flow-${index}`}
                      alertInfo={alertInfo}
                      tool={formattedName(tool.tool.product)}
                    />
                  );
                } else if (tool.tool.product.toLowerCase() === "sentinelone") {
                  return (
                    <SentinelOneSecurityCard
                      key={`sec-tool-flow-${index}`}
                      alertInfo={alertInfo}
                      tool={formattedName(tool.tool.product)}
                    />
                  );
                }
                return null;
              }
            )}

        <Card
          sx={{
            display: "flex",
            minWidth: "600px",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <CardHeader
            sx={{
              background: darkmode
                ? "linear-gradient(to right, rgb(75, 85, 99), rgb(17, 24, 39))"
                : "linear-gradient(to right, #e0eafc, #cfdef3)",
            }}
            title={
              <Stack direction="row" spacing={2} justifyContent="center">
                <SecurityUpdateWarningIcon htmlColor="black" fontSize="large" />
                <Typography variant="h5">SOC Alert</Typography>
              </Stack>
            }
          />
        </Card>
      </Stack>
    </div>
  );
};

export default SecurityFlowChart;
