import React, { useEffect, useState, useContext } from "react";
import {
  Stack,
  Typography,
  Paper,
  Box,
  Breadcrumbs,
  Checkbox,
  Input,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DataContext } from "../../../../Context/dataContext";
import { getRequest, postRequest } from "../../../../Helpers/httpRequests";
import { fileRequest } from "../../../../Helpers/httpRequests";
import { useAppSelector } from "../../../../Redux/app/hooks";

const TemplatesEditForm = () => {
  // ----- STATES && CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const user = useAppSelector((state) => state.user);
  const current_customer = useAppSelector(
    (state) => state.customer,
  );
  const [fileName, setFile] = useState();
  const [Name, setName] = useState();
  const [newFileName, setNewFile] = useState();
  const [defaultTemplate, setDefaultTemplate] = useState(false);
  const [Universal, setUniversal] = useState(false);
  const [singleCampaign, setSingleCampaign] = useState(false);

  const [id, setId] = useState();

  // Submit Btn
  const [submitBtnColor, setSubmitBtnColor] = useState("primary");
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [submitBtnText, setSubmitBtnText] = useState("Submit");

  // ----- VARIABLES -----
  const { templateUUID } = useParams();
  const navigate = useNavigate();

  // ----- FUNCTIONS -----

  const downloadTemplate = async () => {
    const res = await fileRequest(
      `/react/api/${current_customer.uuid}/report/templates/download/${id}`,
      accessToken,
      `${fileName.substring(23)}`,
    );
    if (res.status === 200) {
      return;
    }
  };

  const handleSubmit = async () => {
    setSubmitBtnLoading(true);
    const data = {
      default_template: defaultTemplate,
      universal: Universal,
      file_name: newFileName,
      singleCampaign: singleCampaign,
    };

    const res = await postRequest(
      `/react/api/${current_customer.uuid}/report/templates/edit/${templateUUID}`,
      accessToken,
      data,
    );
    setSubmitBtnLoading(false);
    if (res.status === 200) {
      navigate(`/${current_customer.uuid}/reporting/templates`);
    } else {
      setSubmitBtnColor("error");
      setSubmitBtnText("Error - Try again");
    }
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getFormData = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/report/templates/edit/${templateUUID}`,
        accessToken,
      );
      if (res.status === 200) {
        console.log(res.data)
        try {
          setId(res.data.template.uuid);
          res.data.template.name
            ? setName(res.data.template.name)
            : setFile("");
          res.data.template.file_name
            ? setFile(res.data.template.file_name)
            : setFile("");
          res.data.template.default.length === 0
            ? setDefaultTemplate(false)
            : setDefaultTemplate(true);
          res.data.template.universal
            ? setUniversal(res.data.template.universal)
            : setUniversal(false);
        } catch (err) {
          console.log(
            "Error while trying to set States from getFormData()",
            err,
          );
        }
      }
    };

    getFormData();
  }, [current_customer, accessToken, templateUUID]);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">Home</Link>
        <Link
          className="text-links"
          to={`/${current_customer.uuid}/reporting/templates`}
        >
          Templates
        </Link>
        <Typography color="text.primary">Edit Project Report</Typography>
      </Breadcrumbs>

      <Typography fontWeight={"bold"} variant="h4">
        Edit Project Report {Name}
      </Typography>

      <Box sx={{ display: "flex", width: "50%" }}>
        <Box
          className="left-box"
          sx={{ flex: 1, paddingRight: 1, marginTop: 1 }}
        >
          <Paper sx={{ padding: 2 }}>
            <Stack spacing={2}>
              {/* Current file */}
              <Stack spacing={1} direction="row">
                <Typography sx={{ fontWeight: "bold" }}>
                  Current Template File:
                </Typography>
                <Typography
                  className="text-links"
                  onClick={() => downloadTemplate()}
                >
                  {Name}
                </Typography>
              </Stack>

              {/* Choose File (docx) */}
              <Input
                type="file"
                onChange={(e) => setNewFile(e.target.files[0])}
                inputProps={{ accept: ".docx" }}
                required
              />

              {/* SingleCampaign */}
              {user.superuser && (
                <Typography component={"span"}>
                  Single Campaign Template
                  <Checkbox
                    label="Single Campaign Template"
                    onChange={(e) => setSingleCampaign(e.target.checked)}
                    value={singleCampaign && "on"}
                    checked={singleCampaign}
                  />
                </Typography>
              )}

              {/* Default Template */}
              <Typography component={"span"}>
                Default Template
                <Checkbox
                  label="Default Template"
                  checked={defaultTemplate}
                  value={defaultTemplate && "on"}
                  onChange={(e) => setDefaultTemplate(e.target.checked)}
                />
              </Typography>

              {/* Universal */}
              {user.superuser && (
                <Typography component={"span"}>
                  Universal
                  <Checkbox
                    label="Universal"
                    checked={Universal}
                    value={Universal && "on"}
                    onChange={(e) => setUniversal(e.target.checked)}
                  />
                </Typography>
              )}

              {/* Loading Button */}
              <LoadingButton
                variant="contained"
                loading={submitBtnLoading}
                onClick={handleSubmit}
                color={submitBtnColor}
              >
                {submitBtnText}
              </LoadingButton>
            </Stack>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default TemplatesEditForm;
