import { MenuItem, Select, IconButton, Typography } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { DataContext } from "../../../Context/dataContext";
import { getRequest, postRequest } from "../../../Helpers/httpRequests";
import { useAppSelector } from "../../../Redux/app/hooks";
import { COLORS } from "../../../Styles/colors";
import DataGridWithStyles from "../../DataGrid/DataGridWithStyles";

const CampaignDataTable = (props) => {
  // required props
  const updateChart = props.updateChart;
  const executionThreshold = props.executionThreshold;

  // ----- STATES && CONTEXT -----
  const [dataTableRows, setDataTableRows] = useState([]);
  const [dataTableColumns, setDataTableColumns] = useState([]);
  const [securityTools, setSecurityTools] = useState(props.securityTools);
  const [recommendations, setRecommendations] = useState(props.recommendations);
  const [findings, setFindings] = useState(props.findings);

  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  // ----- VARIABLES -----

  // ----- FUNCTIONS -----
  const thresholdMet = (expected, actual) => {
    switch (expected) {
      case "blocked":
        return actual === "blocked";
      case "alerted":
        return actual === "alerted" || actual === "blocked";
      case "logged":
        return (
          actual === "logged" || actual === "alerted" || actual === "blocked"
        );
      default:
        return true;
    }
  };

  // ----- ON PAGE LOAD -----

  useEffect(() => {
    const populateDataGrid = (asdf) => {
      const outcomes = ["no evidence", "logged", "alerted", "blocked"];
      const columns = [
        {
          field: "step",
          headerName: "Step",
          minWidth: 50,
          flex: 1,
        },
        {
          field: "command",
          headerName: "Command",
          minWidth: 100,
          flex: 3,
        },
        {
          field: "securityTool",
          headerName: "Security Tool",
          minWidth: 100,
          flex: 2,
          renderCell: (params) => {
            const changeSecurityTool = async (e) => {
              const data = {
                finding: params.id,
                tool: e.target.value,
              };
              const res = await postRequest(
                `/react/api/${current_customer.uuid}/report/changeTool`,
                accessToken,
                data
              );
              if (res.status === 200) {
                // setDataTableRows(rows);
                rows.find((row) => row.id === params.id).securityTool =
                  res.data.securityTool;

                findings.find(
                  (finding) => finding.uuid === params.id
                ).security_tool = res.data.securityTool;
                // setFindings(findings)
                populateDataGrid(findings);
              }
            };

            return (
              <Select
                value={
                  Boolean(params.value) && securityTools
                    ? params.value.uuid
                    : ""
                }
                onChange={(e) => changeSecurityTool(e)}
                label="Not Set"
                fullWidth
              >
                <MenuItem value={"none"} key={"no-sec-tool"}>
                  (None)
                </MenuItem>
                {securityTools &&
                  securityTools.map((tool) => (
                    <MenuItem value={tool.uuid} key={tool.uuid}>
                      {tool.name}
                    </MenuItem>
                  ))}
              </Select>
            );
          },
        },
        {
          field: "expected",
          headerName: "Expected Outcome",
          minWidth: 100,
          flex: 2,
          renderCell: (params) => {
            const changeOutcome = async (e) => {
              const data = {
                finding: params.id,
                expected: e.target.value,
              };

              rows.find((row) => row.id === params.id).expected =
                e.target.value;
              const res = await postRequest(
                `/react/api/${current_customer.uuid}/report/changeOutcome`,
                accessToken,
                data
              );
              if (res.status === 200) {
                const temp = findings;
                temp.find(
                  (finding) => finding.uuid === params.id
                ).expected_outcome = data.expected;
                setFindings(temp);
                rows.find((row) => row.id === params.id).score = res.data.score;
                // setFindings(findings)

                // setDataTableRows(rows);
              }
            };
            return (
              <Select
                value={params.value || ""}
                onChange={(e) => changeOutcome(e)}
                label="Not Set"
                fullWidth
              >
                {outcomes &&
                  outcomes.map((outcome) => (
                    <MenuItem value={outcome} key={outcome}>
                      {outcome}
                    </MenuItem>
                  ))}
              </Select>
            );
          },
        },
        {
          field: "actual",
          headerName: "Actual Outcome",
          minWidth: 100,
          flex: 2,
          renderCell: (params) => {
            const changeOutcome = async (e) => {
              const data = {
                finding: params.id,
                actual: e.target.value,
              };

              rows.find((row) => row.id === params.id).actual = e.target.value;

              const res = await postRequest(
                `/react/api/${current_customer.uuid}/report/changeOutcome`,
                accessToken,
                data
              );
              // setDataTableRows(rows);
              if (res.status === 200) {
                rows.find((row) => row.id === params.id).score = res.data.score;
                const temp = findings;
                const row = temp.find((finding) => finding.uuid === params.id);
                row.actual_outcome = res.data.actual;
                row.score = res.data.score;
                switch (res.data.actual) {
                  case "blocked":
                    row.blocked = true;
                    row.alerted = row.logged = row.nothing = false;
                    row.outcome = "blocked";
                    break;
                  case "alerted":
                    row.blocked = row.logged = row.nothing = false;
                    row.alerted = true;
                    row.outcome = "alerted";
                    break;
                  case "logged":
                    row.blocked = row.alerted = row.nothing = false;
                    row.logged = true;
                    row.outcome = "logged";
                    break;
                  case "no evidence":
                    row.blocked = row.logged = row.alerted = false;
                    row.nothing = true;
                    row.outcome = "no evidence";
                    break;
                  default:
                    row.blocked = row.logged = row.alerted = row.nothing = false;
                    row.outcome = "";
                    break;
                }
                // replace the finding in the array
                const index = temp.findIndex(
                  (finding) => finding.uuid === params.id
                );
                let newFindings = [...temp];
                newFindings[index] = row;
                setFindings(newFindings);
                updateChart();
                // setFindings(findings)
                // populateDataGrid(findings)
              }
            };
            return (
              <Select
                value={params.value || ""}
                onChange={(e) => changeOutcome(e)}
                label="Not Set"
                fullWidth
              >
                {outcomes &&
                  outcomes.map((outcome) => (
                    <MenuItem value={outcome} key={outcome}>
                      {outcome}
                    </MenuItem>
                  ))}
              </Select>
            );
          },
        },
        {
          field: "thresholdMet",
          headerName: "threshold Met",
          minWidth: 100,
          flex: 1,
          renderCell: (params) => {
            return (
              <>
                {thresholdMet(params.row.expected, params.row.actual) &&
                  params.row.expected !== "" ? (
                  <>
                    <IconButton>
                      <FaThumbsUp color={COLORS.success.main} />
                    </IconButton>
                    <Typography>Yes</Typography>
                  </>
                ) : (
                  <>
                    <IconButton>
                      <FaThumbsDown color={COLORS.error.main} />
                    </IconButton>
                    <Typography>No</Typography>
                  </>
                )}
              </>
            );
          },
        },
        {
          field: "score",
          headerName: "Score",
          minWidth: 100,
          flex: 1,
        },
        {
          field: "attachedRecommendation",
          headerName: "Attached Recommendation",
          minWidth: 100,
          flex: 2,
          renderCell: (params) => {
            const attachRecommendation = async (e) => {
              const data = {
                finding: params.id,
                recommendation: e.target.value,
              };

              const res = await postRequest(
                `/react/api/${current_customer.uuid}/report/attachRecommendation`,
                accessToken,
                data
              );
              // rows.find((row) => row.id === params.id).attachedRecommendation =
              //   data.recommendation;
              if (res.status === 200) {
                findings.find(
                  (finding) => finding.uuid === params.id
                ).linked_recommendation = res.data.attachedRecommendation;
                // setFindings(findings)
                populateDataGrid(findings);
              }
            };
            return (
              <Select
                value={
                  params.value && params.value.uuid ? params.value.uuid : ""
                }
                onChange={(e) => attachRecommendation(e)}
                label="Not Set"
                fullWidth
              >
                <MenuItem value="" key="none">
                  None
                </MenuItem>
                {recommendations.map((recommendation) => (
                  <MenuItem
                    value={recommendation.uuid}
                    key={recommendation.uuid}
                  >
                    {recommendation.name}
                  </MenuItem>
                ))}
              </Select>
            );
          },
        },
      ];
      const rows = findings.map((finding) => {
        return {
          id: finding.uuid,
          step: finding.step + 2,
          command: finding.name,
          securityTool: finding.security_tool || "",
          expected:
            finding.simulation_action?.outcome || executionThreshold || "",
          actual: finding.outcome || "",
          score: finding.score,
          attachedRecommendation: finding.linked_recommendation,
        };
      });
      if (rows.length) {
        setDataTableColumns(columns);
        setDataTableRows(rows);
      }
    };

    const getSecurityTools = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/config/securitytools`,
        accessToken
      );
      if (res.status === 200) {
        setSecurityTools(res.data.security_tools);
      }
    };

    const getRecommendations = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/report/recommendations`,
        accessToken
      );
      if (res.status === 200) {
        setRecommendations(res.data.recommendations);
      }
    };

    if (!securityTools) {
      getSecurityTools();
    }

    if (!recommendations) {
      getRecommendations();
    }

    populateDataGrid(findings);
  }, [
    findings,
    accessToken,
    current_customer,
    securityTools,
    recommendations,
    updateChart,
    executionThreshold,
  ]);

  return (
    <>
      {findings.length && (
        <DataGridWithStyles
          name="project-report-campaigns"
          components={{ Toolbar: GridToolbar }}
          getRowHeight={() => "auto"}
          autoHeight
          rows={dataTableRows}
          columns={dataTableColumns}
        />
      )}
    </>
  );
};

export default CampaignDataTable;
