import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import { DataGridWithStyles, FormModal, TextButton } from "../../../Components";
import { useHttpRequest } from "../../../Hooks";
import { useAppSelector } from "../../../Redux/app/hooks";
import { AddEditModal } from "./AddEditModal";
import { DeleteModal } from "./DeleteModal";

const ProcessNames = () => {
  const current_customer = useAppSelector((state) => state.customer);
  const { response: data, fetch } = useHttpRequest({
    method: "GET",
    path: "/api/v2/process_name",
  });

  const [rows, setRows] = useState([]);
  const [modal, setModal] = useState({
    open: false,
    content: null,
  });

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "executable",
      headerName: "Executable",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            {current_customer.id === params.row.customer && (
              <>
                <IconButton
                  title="Edit"
                  onClick={() => {
                    setModal({
                      open: true,
                      content: (
                        <AddEditModal data={params.row} onClose={onClose} />
                      ),
                    });
                  }}
                >
                  <FaEdit />
                </IconButton>
                <IconButton
                  title="Delete"
                  onClick={() => {
                    setModal({
                      open: true,
                      content: (
                        <DeleteModal data={params.row} onClose={onClose} />
                      ),
                    });
                  }}
                >
                  <FaTrash />
                </IconButton>
              </>
            )}
          </Box>
        );
      },
    },
  ];

  const onClose = () => {
    setModal({
      open: false,
      content: null,
    });
    fetch();
  };

  useEffect(() => {
    const populateRows = () => {
      const rows = [];
      data?.forEach((row) => {
        rows.push({
          id: row.id,
          name: row.name,
          executable: row.executable,
          customer: row.customer,
        });
      });
      return rows;
    };
    if (data) {
      setRows(populateRows());
    }
  }, [data]);

  return !data ? (
    <CircularProgress />
  ) : (
    <Box>
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h3">Process Names</Typography>
        <TextButton
          onClick={() =>
            setModal({
              open: true,
              content: <AddEditModal onClose={onClose} />,
            })
          }
          tooltip="Add new Friendly Executable Name"
          icon={FaPlus}
        />
      </Stack>
      <Typography>
        In the Context section of a Campaign, there is a list of Security
        Processes that were running at time of Campaign Execution. This section
        is to replace the name of the process names to a more Human-Friendly
        one. For example: "msmpeng" becomes "Microsoft Malware Protection
        Engine". Note that the .exe is removed from the process name
        automatically when the agent sends the context and that not all
        processes running are sent.
      </Typography>
      <DataGridWithStyles
        rows={rows}
        columns={columns}
        paper
        autoHeight
        pageSize={10}
        disableSelectionOnClick
      />
      <FormModal
        open={modal.open}
        setOpen={() =>
          setModal({
            open: false,
            content: null,
          })
        }
      >
        {modal.content}
      </FormModal>
    </Box>
  );
};

export default ProcessNames;
