import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import { useLocation, Link } from "react-router-dom";
import { useAppSelector } from "../../../Redux/app/hooks";

const HelpPageTemplate = () => {
  const location = useLocation();
  const customer = useAppSelector((state) => state.customer);
  const { title, videoUrl, textData } = location.state;

  return (
    <Container maxWidth="100%" sx={{ marginTop: 1 }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography>Home</Typography>
        <Link to={`/${customer.uuid}/support/help-pages`}>
          <Typography className="text-links">Help Pages</Typography>
        </Link>
        <Typography color="text.primary">{title}</Typography>
      </Breadcrumbs>
      <Typography variant="h4" align="left" gutterBottom>
        {title}
      </Typography>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "flex-start",
          overflow: "hidden",
          width: "60%",
          height: "600px",
        }}
      >
        <iframe
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "80%",
            height: "70%",
            border: "none",
          }}
          src={videoUrl}
          allow="autoplay; fullscreen; picture-in-picture"
          title="Simulation Builder"
        ></iframe>
      </Box>

      <Typography sx={{ marginTop: 1 }} variant="body1">
        {textData}
      </Typography>
    </Container>
  );
};

export default HelpPageTemplate;
