import React, { useContext, useState, useEffect } from "react";
import { Box, Checkbox, Input, Typography, Stack } from "@mui/material";
import { getRequest, postRequest } from "../../../../Helpers/httpRequests";
import { useParams } from "react-router-dom";
import { DataContext } from "../../../../Context/dataContext";
import { LoadingButton } from "@mui/lab";
import { fileRequest } from "../../../../Helpers/httpRequests";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../Redux/app/hooks";

const AdminEditProjectForm = () => {
  // ----- STATES && CONTEXT -----
  const { accessToken } = useContext(DataContext);

  const user = useAppSelector((state) => state.user);
  const customer = user.current_customer;
  const [fileName, setFile] = useState();
  const [name, setName] = useState();
  const [newFileName, setNewFile] = useState();
  const [defaultTemplate, setDefaultTemplate] = useState(false);
  const [Universal, setUniversal] = useState(false);

  // Submit Btn
  const [submitBtnColor, setSubmitBtnColor] = useState("primary");
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [submitBtnText, setSubmitBtnText] = useState("Submit");

  // ----- VARIABLES -----
  const { projectUUID } = useParams();
  const navigate = useNavigate();

  // ----- FUNCTIONS -----

  const downloadTemplate = async () => {
    const res = await fileRequest(
      `/react/api/${customer.uuid}/report/templates/download/${projectUUID}`,
      accessToken,
      `${fileName}`,
    );
    if (res.status === 200) {
      return;
    }
  };

  const handleSubmit = async () => {
    setSubmitBtnLoading(true);
    const data = {
      default_template: defaultTemplate,
      universal: Universal,
      file_name: newFileName,
    };

    const res = await postRequest(
      `/react/api/admin-edit-template/${projectUUID}`,
      accessToken,
      data,
    );
    setSubmitBtnLoading(false);
    if (res.status === 200) {
      navigate(`/${user.current_customer}/administration/templates`);
    } else {
      setSubmitBtnColor("error");
      setSubmitBtnText("Error - Try again");
    }
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getFormData = async () => {
      const res = await getRequest(
        `/react/api/admin-edit-template/${projectUUID}`,
        accessToken,
      );
      if (res.status === 200) {
        try {
          setDefaultTemplate(res.data.isDefault);
          setUniversal(res.data.isUniversal);
          setFile(res.data.templateName);
          setName(res.data.name);
        } catch (err) {
          console.log(
            "Error while trying to set States from getFormData()",
            err,
          );
        }
      }
    };
    if (user.current_customer) {
      getFormData();
    }
  }, [user.current_customer, accessToken, projectUUID]);

  return (
    <>
      <Typography fontWeight={"bold"} variant="h4">
        Edit Project Report {name}
      </Typography>

      <Box sx={{ display: "flex", width: "flex" }}>
        <Box
          className="left-box"
          sx={{ flex: 1, paddingRight: 1, marginTop: 1 }}
        >
          <Stack spacing={2}>
            {/* Current file */}
            <Stack spacing={1} direction="row">
              <Typography sx={{ fontWeight: "bold" }}>
                Current Template File:
              </Typography>
              <Typography
                className="text-links"
                onClick={() => downloadTemplate()}
              >
                {fileName}
              </Typography>
            </Stack>

            {/* Choose File (docx) */}
            <Input
              type="file"
              onChange={(e) => setNewFile(e.target.files[0])}
              inputProps={{ accept: ".docx" }}
              required
            />

            {/* Default Template */}
            <Typography component={"span"}>
              Default Template
              <Checkbox
                label="Default Template"
                checked={defaultTemplate}
                onChange={(e) => setDefaultTemplate(e.target.checked)}
              />
            </Typography>

            {/* Universal */}
            {user.superuser && (
              <Typography component={"span"}>
                Universal
                <Checkbox
                  label="Universal"
                  checked={Universal}
                  onChange={(e) => setUniversal(e.target.checked)}
                />
              </Typography>
            )}

            {/* Loading Button */}
            <LoadingButton
              variant="contained"
              loading={submitBtnLoading}
              onClick={handleSubmit}
              color={submitBtnColor}
            >
              {submitBtnText}
            </LoadingButton>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default AdminEditProjectForm;
