import { useCallback } from "react";
import { postRequest } from "../../Helpers/httpRequests";
import { Customer } from "../../Types";
import { isUUID } from "../../Helpers/functions/isUUID";

interface CustomerAccessResponse {
  data: {
    customer: Customer;
    verified: boolean;
  };
}

interface verifyReturn {
  verified: boolean;
  customer: Customer | null;
}

export const useVerifyCustomerAccess = (
  accessToken: string,
  customerUUID: string,
) => {
  const verifyCustomerAccess = useCallback(
    async (
      accessToken: string,
      customerUUID: string,
    ): Promise<verifyReturn> => {
      if (!isUUID(customerUUID)) {
        return { verified: false, customer: null };
      }
      try {
        const data = {
          customer_uuid: customerUUID,
        };
        const response: CustomerAccessResponse = await postRequest(
          `/universal/verify_customer_access`,
          accessToken,
          data,
          true,
        );

        if (!response.data.verified) {
          console.error("Customer verification failed");
          return { verified: false, customer: null };
        }
        return { verified: true, customer: response.data.customer };
      } catch (error) {
        console.error("Error fetching customer access:", error);
        return { verified: false, customer: null };
      }
    },
    [],
  );

  return verifyCustomerAccess;
};
