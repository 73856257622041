import { useContext } from "react";
import { Grid, Typography, Paper, Stack, Divider } from "@mui/material";
import moment from "moment";
import { AVDetailsContext } from "../ContextProvider/ContextProvider";

const capitalize = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export default function ExecutionDetails() {
  return (
    <Paper className="w-[50%] flex-1" sx={{ p: 3 }}>
      <Stack spacing={1}>
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            fontWeight: "bold",
          }}
        >
          Execution Details
        </Typography>
        <Divider />
        <Grid container columns={2} rowSpacing={1}>
          <GridItems />
        </Grid>
      </Stack>
    </Paper>
  );
}

const GridItems = () => {
  const { alertInfo, expectedAlerts } = useContext(AVDetailsContext);
  const gridData = [
    {
      title: "Execution Status",
      value: capitalize(alertInfo.av_campaign.result),
    },
    {
      title: "Alerting Rules",
      value: expectedAlerts.length,
    },
    {
      title: "Process ID",
      value: alertInfo.av_campaign.pid,
    },
    {
      title: "Execution Date",
      value: moment(alertInfo.av_campaign.start).format("MM/DD/YYYY"),
    },
    {
      title: "Start Time",
      value: moment(alertInfo.av_campaign.start).format("hh:mm:ss a"),
    },
    {
      title: "End Time",
      value: moment(alertInfo.av_campaign.end).format("hh:mm:ss a"),
    },
  ];

  return gridData.map((item) => (
    <Grid key={item.title} item sm={1} md={1} lg={1}>
      <Typography>
        <b>{item.title}:</b> {item.value}
      </Typography>
    </Grid>
  ));
};
