import React, { useEffect, useContext, useState } from "react";

import { Link } from "react-router-dom";
import { getRequest } from "../../../Helpers/httpRequests";
import {
  Breadcrumbs,
  Stack,
  Typography,
  Box,
  IconButton,
  Button,
} from "@mui/material";
import TextButton from "../../../Components/Buttons/TextButton";
import { DataContext } from "../../../Context/dataContext";
import { FaCheck, FaDownload, FaTrashAlt, FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import { fileRequest } from "../../../Helpers/httpRequests";
import { COLORS } from "../../../Styles/colors";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import FormModal from "../../../Components/Modals/FormModal";
import { useAppSelector } from "../../../Redux/app/hooks";

const AdminTemplates = () => {
  // Navigation for redirects

  // ----- STATES && CONTEXT -----
  const [dataTableRows, setDataTableRows] = useState([]);
  const [dataTableColumns, setDataTableColumns] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteUUID, setDeleteUUID] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const [reloadPage, setReloadPage] = useState(true);

  // Contexts
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  // ----- VARIABLES -----

  // ----- FUNCTIONS -----
  const deleteTemplate = async () => {
    const res = await toast.promise(
      getRequest(`/react/api/admin-delete-template/${deleteUUID}`, accessToken),
      {
        pending: "Deleting Template",
        success: `Template Deleted`,
        error: "Something went wrong!",
      }
    );
    if (res.status === 200) {
      setDeleteModal(false);
      setReloadPage((current) => !current);
    }
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const populateDataGrid = (templates) => {
      const columns = [
        {
          field: "name",
          headerName: "Name",
          minWidth: 100,
          flex: 2,
          renderCell: (params) => {
            return (
              <Link
                to={`/${current_customer.uuid}/administration/editProject/${params.row.id}`}
              >
                {params.row.name}
              </Link>
            );
          },
        },
        {
          field: "customer",
          headerName: "Customer",
          minWidth: 100,
          flex: 1,
        },
        {
          field: "universal",
          headerName: "Universal",
          minWidth: 100,
          flex: 1,
          renderCell: (params) => {
            if (params.row.universal) {
              return <FaCheck color="green" />;
            } else {
              return <div></div>;
            }
          },
        },
        {
          field: "actions",
          headerName: "",
          minWidth: 130,
          flex: 0,
          renderCell: (params) => {
            const downloadTemplate = async (params) => {
              await toast.promise(
                fileRequest(
                  `/react/api/${current_customer.uuid}/report/templates/download/${params.row.id}`,
                  accessToken,
                  `${params.row.name}.docx`
                ),
                {
                  pending: "Downloading Template",
                  success: `Template Downloaded`,
                  error: "Something went wrong!",
                }
              );
            };
            return (
              <Stack direction="row" spacing={2}>
                <IconButton
                  onClick={() => {
                    setDeleteUUID(params.row.id);
                    setDeleteName(params.row.name);
                    setDeleteModal(true);
                  }}
                  title="Delete Template"
                >
                  <FaTrashAlt color={COLORS.error.main} />
                </IconButton>
                <IconButton
                  onClick={() => downloadTemplate(params)}
                  title="Download template"
                >
                  <FaDownload />
                </IconButton>
              </Stack>
            );
          },
        },
      ];

      const rows = templates.map((template) => {
        return {
          id: template.uuid,
          name: template.name,
          customer: template.customer.display_name,
          universal: template.universal,
        };
      });

      setDataTableRows(rows);
      setDataTableColumns(columns);
    };

    const getTemplates = async () => {
      const res = await getRequest(`/react/api/admin-templates`, accessToken);
      populateDataGrid(res.data.templates);
    };

    getTemplates();
  }, [current_customer, accessToken, reloadPage]);

  return (
    <Stack>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Administration</Typography>
        <Typography color="text.primary">Templates</Typography>
      </Breadcrumbs>

      {/* Page Title */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Typography component={"span"} fontWeight={"bold"} variant="h4">
          Templates
        </Typography>
        <Link to={`/${current_customer.uuid}/administration/templates/add`}>
          <TextButton tooltip="Add Template" icon={FaPlus} />
        </Link>
      </Box>

      {/* Template Grid */}
      <DataGridWithStyles
        name="admin-templates"
        autoHeight
        paper
        rows={dataTableRows}
        columns={dataTableColumns}
      />
      {/* Delete Modal */}
      <FormModal open={deleteModal} setOpen={setDeleteModal}>
        <Box component="form">
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: 4 }}
          >
            Are you sure you want to remove {deleteName}?
          </Typography>
          <Stack direction="column" spacing={2}>
            <Button onClick={() => deleteTemplate()} variant="contained">
              Yes
            </Button>
          </Stack>
        </Box>
      </FormModal>
    </Stack>
  );
};

export default AdminTemplates;
