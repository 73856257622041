import { Box, Divider, IconButton, Paper, Typography } from "@mui/material";
import React, { useContext } from "react";
import { FaChartArea } from "react-icons/fa";
import { Link } from "react-router-dom";
import CampaignContext from "../provider/CampaignContext";
import CircularProgress from "@mui/material/CircularProgress";
import { useAppSelector } from "../../../../Redux/app/hooks";

const CampaignNotes = () => {
  const current_customer = useAppSelector(
    (state) => state.customer
  );
  const { campaign, executedUUID } = useContext(CampaignContext); // Access campaign from context
  if (!campaign) {
    // Campaign data is not available yet, show loading or fallback content
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {campaign.notes.length > 0 && (
        <Paper sx={{ padding: 2, marginTop: 2 }}>
          {/* Notes */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Notes
            </Typography>

            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", marginLeft: 12 }}
            >
              Author
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Finding
            </Typography>
          </Box>

          {campaign.notes.length === 0 && <Typography>No Notes</Typography>}
          {campaign.notes.length > 0 &&
            campaign.notes.map((note, index) => {
              return (
                <div key={note.uuid}>
                  {/* Notes */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ alignSelf: "center", width: "20rem" }}>
                      {index + 1}. {note.note}
                    </Typography>

                    <Typography>{note.author}</Typography>
                    <Link
                      to={`/${current_customer.uuid}//reporting/finding/${executedUUID}/${note.finding_uuid}`}
                    >
                      <IconButton sx={{ marginRight: 3 }}>
                        <FaChartArea />
                      </IconButton>
                    </Link>
                  </Box>
                  {campaign.notes.length > 1 && <Divider />}
                </div>
              );
            })}
        </Paper>
      )}
    </>
  );
};

export default CampaignNotes;
