import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { FaCircle, FaInfo } from "react-icons/fa";
import { DataContext } from "../../../../Context/dataContext";
import { getRequest, postRequest } from "../../../../Helpers/httpRequests";
import { useHttpRequest } from "../../../../Hooks";
import { COLORS } from "../../../../Styles/colors";

const CampaignForm = (props) => {
  // usage <CampaignForm simulation=simulationObject setModalOpen=stateFunctionForOpenModal project=projectObject />

  // ----- STATES && CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const [endpoints, setEndpoints] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [isFormProcessing, setFormProcessing] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [threshold_set, setThresholdSet] = useState(true);
  const [statusMsg, setStatusMsg] = useState("");
  const [remainingCampaigns, setRemainingCampaigns] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [remainingOpen, setRemainingOpen] = React.useState(true);

  const [formData, setFormData] = useState({
    campaignName: "",
    endpoint: "",
    execMethod: "exe",
    piMethod: "",
    piTarget: "",
    sleepTime: 2,
    maxRuntime: 30,
    transform: "",
    architecture: "64",
    simulation: props.simulation.uuid,
    relay: false,
    inMemory: false,
    obfuscate: false,
    changeName: false,
    campaign_level_variables: [],
    schedule: "now",
    scheduleTime: null,
  });

  // ----- VARIABLES -----

  // Required Prop
  const simulation = props.simulation;
  const setModalOpen = props.setModalOpen;
  const customer = props.customer;

  // Optional Prop used for adding campaign directly to a project
  const project = props.project;
  const onFinish = props.onFinish;

  const execMethod = [
    {
      value: "exe",
      name: "Binary Executable",
    },
    {
      value: "dll",
      name: "Signed Proxy Execution",
    },
    {
      value: "sh",
      name: "Remote Process Injection",
    },
    {
      value: "local",
      name: "Local Process Injection",
    },
  ];

  const architectures = [
    {
      value: "64",
      name: "64-bit",
    },
    {
      value: "32",
      name: "32-bit",
    },
  ];

  const linuxExecMethod = [
    {
      value: "exe",
      name: "Executable",
    },
  ];

  // There actually isn't a model for this. It's hard-coded in the models.py so I'll hardcode them here too for now
  const piMethods = [
    "CreateRemoteThread",
    "NtCreateRemoteThread",
    "QueueUserAPC",
    "NtQueueUserAPC",
    "RtlCreateUserThread",
  ];

  const { response: piTargets } = useHttpRequest({
    method: "GET",
    path: "/api/v2/process-injection-targets",
  });

  const { response: transformMethods } = useHttpRequest({
    method: "GET",
    path: "/api/v2/admin/payload-transforms",
  });
  // ----- FUNCTIONS -----
  const handleSubmit = async () => {
    setSubmitDisabled(true);
    setShowErrorMsg(false);
    setFormProcessing(true);
    const mode = project ? "project" : "stand-alone";
    const data = formData;
    data.mode = mode;
    let url = `/react/api/${customer.uuid}/campaign/add`;
    const result = await postRequest(url, accessToken, data, true);

    if (result.status === 200) {
      if (mode === "project") {
        const dataProject = {
          campaign: result.data.campaign.uuid,
          project: project.uuid,
        };
        const result2 = await postRequest(
          `/react/api/${customer.uuid}/projects/addCampaign`,
          accessToken,
          dataProject
        );
        if (result2.status === 200) {
          setFormProcessing(false);
          setModalOpen(false);
          if (onFinish) {
            onFinish();
          }
        } else {
          setFormProcessing(false);
          setSubmitDisabled(false);
          setShowErrorMsg(true);
          setStatusMsg(result.data.message);
        }
      } else {
        setFormProcessing(false);
        setModalOpen(false);
        if (onFinish) {
          onFinish();
        }
      }
    } else {
      setFormProcessing(false);
      setSubmitDisabled(false);
      setShowErrorMsg(true);
      setStatusMsg(result.data.message);
    }
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getLists = async () => {
      if (
        formData.campaign_level_variables.length === 0 &&
        simulation.campaign_level_vars
      ) {
        setFormData({
          ...formData,
          campaign_level_variables: simulation.campaign_level_var_defaults,
        });
      }
      const get_sim_plan = await getRequest(
        `/react/api/${customer.uuid}/simulations/simplan/${simulation.uuid}`,
        accessToken
      );
      if (get_sim_plan.status === 200) {
        setThresholdSet(get_sim_plan.data.simplan.threshold_set);
      }
      // get the list of endpoints and targets
      const resEndpoints = await getRequest(
        `/react/api/${customer.uuid}/endpoints?license=True`,
        accessToken
      );
      if (resEndpoints.status === 200) {
        setEndpoints(resEndpoints.data.endpoints);
        setRemainingCampaigns(resEndpoints.data.remaining_campaigns);
      }
      setLoaded(true);
    };

    if (customer) {
      getLists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, simulation, customer]);

  return (
    <>
      {!loaded ? (
        <Box className="w-full h-full flex justify-center items-center">
          <CircularProgress />
        </Box>
      ) : (
        <Stack>
          {remainingCampaigns > 0 &&
            (customer.customer_type === "consumption" ||
              customer.customer_type === "consumption_smv") &&
            !customer.consumption_hard_cap && (
              <Collapse in={remainingOpen}>
                <Alert
                  severity={remainingCampaigns < 10 ? "error" : "info"}
                  variant="outlined"
                  onClose={() => {
                    setRemainingOpen(false);
                  }}
                >
                  <Typography>
                    You have {remainingCampaigns} campaigns remaining.{" "}
                    {remainingCampaigns < 10 &&
                      "Please contact administration for increase"}
                  </Typography>
                </Alert>
              </Collapse>
            )}
          {remainingCampaigns <= 0 &&
            (customer.customer_type === "consumption" ||
              customer.customer_type === "consumption_smv") && (
              <Collapse in={remainingOpen}>
                <Alert
                  sx={{ marginBottom: 1 }}
                  severity="error"
                  variant="outlined"
                  onClose={() => {
                    setRemainingOpen(false);
                  }}
                >
                  <Typography>
                    {customer.consumption_hard_cap
                      ? "Campaign limit reached. Please contact administrator for increase"
                      : `Currently ${Math.abs(
                          remainingCampaigns
                        )} campaigns over limit`}
                  </Typography>
                </Alert>
              </Collapse>
            )}

          <Typography variant="h5">Create New Campaign</Typography>
          <Divider style={{ margin: "1rem 0" }} />
          {!threshold_set && (
            <Alert sx={{ marginBottom: 1 }} severity="error">
              Thresholds are not set on this Simulation. This may affect
              scoring!
            </Alert>
          )}

          <Stack spacing={2}>
            {/* Name */}
            <TextField
              required
              value={formData.campaignName}
              onChange={(e) =>
                setFormData({ ...formData, campaignName: e.target.value })
              }
              label="Name"
              size="small"
            />

            {/* Endpoint */}
            <FormControl>
              <InputLabel required>Endpoint</InputLabel>
              <Select
                required
                value={formData.endpoint}
                onChange={(e) =>
                  setFormData({ ...formData, endpoint: e.target.value })
                }
                label="Endpoint"
                size="small"
              >
                {endpoints && (
                  <>
                    {/* Filter and display active endpoints */}
                    {endpoints.some((endpoint) => endpoint.active) && (
                      <>
                        <MenuItem disabled>
                          <Typography variant="caption" color="textSecondary">
                            Active
                          </Typography>
                        </MenuItem>
                        {endpoints
                          .filter((endpoint) => endpoint.active)
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((endpoint) => (
                            <MenuItem key={endpoint.uuid} value={endpoint.uuid}>
                              <Stack direction="row" spacing="1rem">
                                <FaCircle color={COLORS.success.light} />
                                <Typography>{endpoint.name}</Typography>
                              </Stack>
                            </MenuItem>
                          ))}
                        <Divider />
                      </>
                    )}

                    {/* Filter and display inactive endpoints */}
                    {endpoints.some((endpoint) => !endpoint.active) && (
                      <>
                        <MenuItem disabled>
                          <Typography variant="caption" color="textSecondary">
                            Not Active
                          </Typography>
                        </MenuItem>
                        {endpoints
                          .filter((endpoint) => !endpoint.active)
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((endpoint) => (
                            <MenuItem key={endpoint.uuid} value={endpoint.uuid}>
                              <Stack direction="row" spacing="1rem">
                                <FaCircle color={COLORS.secondary.dark} />
                                <Typography>{endpoint.name}</Typography>
                              </Stack>
                            </MenuItem>
                          ))}
                      </>
                    )}
                  </>
                )}
              </Select>
            </FormControl>

            {/* Platform */}
            <TextField
              value={simulation.platform || ""}
              label="Platform"
              disabled
              size="small"
            />

            {/* Campaign Level Variables */}
            {simulation.campaign_level_vars && (
              <>
                {simulation.campaign_level_vars.map((variable, index) => (
                  <Stack key={index} direction="row">
                    <Tooltip
                      title={simulation.campaign_level_var_descriptions[index]}
                    >
                      <TextField
                        label={variable}
                        value={formData.campaign_level_variables[index]}
                        required={true}
                        onChange={(e) => {
                          const updatedVariables = [
                            ...formData.campaign_level_variables,
                          ];
                          updatedVariables[index] = e.target.value;
                          setFormData({
                            ...formData,
                            campaign_level_variables: updatedVariables,
                          });
                        }}
                      />
                    </Tooltip>
                  </Stack>
                ))}
              </>
            )}

            {/* Architecture */}
            <FormControl>
              <InputLabel required>Architecture</InputLabel>
              <Select
                value={formData.architecture ? formData.architecture : ""}
                onChange={(e) => {
                  setFormData({ ...formData, architecture: e.target.value });
                }}
                label="Architecture"
                size="small"
              >
                {architectures.map((arch) => {
                  return (
                    <MenuItem key={arch.value} value={arch.value}>
                      {arch.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            {/* Execution Method */}
            <FormControl>
              <InputLabel required>Execution Method</InputLabel>
              <Select
                size="small"
                value={formData.execMethod ? formData.execMethod : ""}
                onChange={(e) => {
                  setFormData({ ...formData, execMethod: e.target.value });
                  switch (e.target.value) {
                    case "local":
                      setFormData({
                        ...formData,
                        execMethod: e.target.value,
                        piMethod: "",
                        piTarget: "",
                        inMemory: false,
                      });
                      break;
                    case "sh":
                      setFormData({
                        ...formData,
                        execMethod: e.target.value,
                        transform: "",
                        inMemory: false,
                      });
                      break;
                    default:
                      setFormData({
                        ...formData,
                        execMethod: e.target.value,
                        piMethod: "",
                        piTarget: "",
                        transform: "",
                        inMemory: false,
                      });
                      break;
                  }
                }}
                label="Exectution Method"
              >
                {simulation.platform === "linux"
                  ? linuxExecMethod.map((exec) => {
                      return (
                        <MenuItem key={exec.value} value={exec.value}>
                          {exec.name}
                        </MenuItem>
                      );
                    })
                  : execMethod.map((exec) => {
                      return (
                        <MenuItem key={exec.value} value={exec.value}>
                          {exec.name}
                        </MenuItem>
                      );
                    })}
              </Select>
            </FormControl>

            {/* PI stuff */}
            {/* PI method */}
            {formData.execMethod.includes("sh") && (
              <>
                <FormControl>
                  <InputLabel required>Process Injection Method</InputLabel>
                  <Select
                    value={formData.piMethod ? formData.piMethod : ""}
                    onChange={(e) =>
                      setFormData({ ...formData, piMethod: e.target.value })
                    }
                    label="Process Injection Method"
                    size="small"
                  >
                    {piMethods &&
                      piMethods.map((exec) => {
                        return (
                          <MenuItem value={exec} key={exec}>
                            {exec}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel required>Process Injection Target</InputLabel>
                  <Select
                    value={formData.piTarget || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, piTarget: e.target.value })
                    }
                    label="Process Injection Target"
                    size="small"
                  >
                    {piTargets
                      ? piTargets.map((exec) => {
                          return (
                            <MenuItem key={exec.uuid} value={exec.uuid}>
                              {exec.name}
                            </MenuItem>
                          );
                        })
                      : null}
                  </Select>
                </FormControl>
              </>
            )}
            {formData.execMethod.includes("local") && (
              <FormControl>
                <InputLabel required>Transform</InputLabel>
                <Select
                  value={formData.transform ? formData.transform : ""}
                  onChange={(e) =>
                    setFormData({ ...formData, transform: e.target.value })
                  }
                  label="Transform"
                  size="small"
                >
                  {transformMethods &&
                    transformMethods.map((exec, index) => {
                      return (
                        <MenuItem value={index + 1} key={index}>
                          {exec.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            )}

            {/* Schedule Time */}
            <FormControl>
              <InputLabel required>Schedule</InputLabel>
              <Select
                value={formData.schedule ? formData.schedule : ""}
                onChange={(e) =>
                  setFormData({ ...formData, schedule: e.target.value })
                }
                label="Schedule"
                size="small"
              >
                <MenuItem value="now">Now</MenuItem>
                <MenuItem value="time">Later</MenuItem>
              </Select>
            </FormControl>
            {formData.schedule === "time" && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    maxWidth: 300,
                  }}
                >
                  <DateTimePicker
                    label="Select Date & Time"
                    value={formData.scheduleTime}
                    onChange={(newValue) => {
                      setFormData({ ...formData, scheduleTime: newValue });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    minDateTime={dayjs()}
                  />
                </Box>
              </LocalizationProvider>
            )}

            <TextField
              type={"number"}
              value={formData.sleepTime}
              label="Sleep Time"
              size="small"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  sleepTime:
                    e.target.value <= 0
                      ? 0
                      : parseInt(e.target.value.toString()),
                });
              }}
            />

            <FormControl>
              <InputLabel required>Max Runtime</InputLabel>
              <Select
                value={formData.maxRuntime ? formData.maxRuntime : ""}
                onChange={(e) =>
                  setFormData({ ...formData, maxRuntime: e.target.value })
                }
                label="Max Runtime"
                size="small"
              >
                <MenuItem value={0}>Unlimited</MenuItem>
                {[...Array(15)].map((x, i) =>
                  i !== 0 ? (
                    <MenuItem key={i * 15} value={i * 15}>
                      {i * 15} minutes
                    </MenuItem>
                  ) : (
                    ""
                  )
                )}
              </Select>
            </FormControl>

            <FormGroup>
              {!formData.execMethod.includes("sh") &&
                !formData.execMethod.includes("dll") && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.inMemory}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            inMemory: e.target.checked,
                          })
                        }
                      />
                    }
                    label="inMemory"
                  />
                )}
              <Tooltip title="Instead of embedding files within the payload, have the files be downloaded from the agent. Note: only for the Beta Agent">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.relay}
                      onChange={(e) => {
                        setFormData({ ...formData, relay: e.target.checked });
                      }}
                    />
                  }
                  label={
                    <Stack direction="row">
                      <FaInfo
                        style={{
                          color: COLORS.info.main,
                          marginRight: "0.5rem",
                        }}
                      />
                      File Relay (! BETA !)
                    </Stack>
                  }
                />
              </Tooltip>

              <Tooltip title="Obfuscate the contents of the payload to make it harder to detect. Note: This will make the payload take longer to compile than normal">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.obfuscate}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          obfuscate: e.target.checked,
                        })
                      }
                    />
                  }
                  label={
                    <Stack direction="row">
                      <FaInfo
                        style={{
                          color: COLORS.info.main,
                          marginRight: "0.5rem",
                        }}
                      />
                      Obfuscate Payload
                    </Stack>
                  }
                />
              </Tooltip>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.changeName}
                    onChange={(e) =>
                      setFormData({ ...formData, changeName: e.target.checked })
                    }
                  />
                }
                label="Randomize payload name"
              />
            </FormGroup>

            <Typography
              sx={{ display: showErrorMsg ? "block" : "none" }}
              color="error"
              variant="p"
            >
              Bad Request: {statusMsg}
            </Typography>
            <LoadingButton
              variant="contained"
              loading={isFormProcessing}
              disabled={
                submitDisabled ||
                (remainingCampaigns <= 0 &&
                  customer.customer_type === "subscription") ||
                (remainingCampaigns <= 0 &&
                  (customer.customer_type === "consumption" ||
                    customer.customer_type === "consumption_smv") &&
                  customer.consumption_hard_cap) ||
                formData.endpoint === "" ||
                formData.campaignName === ""
              }
              onClick={handleSubmit}
            >
              Submit
            </LoadingButton>
          </Stack>
        </Stack>
      )}
    </>
  );
};

CampaignForm.propTypes = {
  // simulation: PropTypes.array,
  setModalOpen: PropTypes.func.isRequired,
  project: PropTypes.object,
  onFinish: PropTypes.func,
};

export default CampaignForm;
