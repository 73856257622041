import { useEffect, useState } from "react";
import { Button, Stack, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useHttpRequest } from "../../../../Hooks";
import { useAppSelector } from "../../../../Redux/app/hooks";

const PrevNext = ({ campaignUUID, findingUUID }) => {
  const navigate = useNavigate();

  const current_customer = useAppSelector(
    (state) => state.customer,
  );
  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [finding, setFinding] = useState({
    current: {
      step: null,
    },
    maxStep: null,
  });

  const { response: findings } = useHttpRequest({
    method: "GET",
    path: `/api/v2/campaigns/findings/${campaignUUID}`,
  });

  /*
   * Handles navigation to the previous Cached finding
   */
  const handlePreviousClick = () => {
    navigate(previous);
  };

  /*
   * Handles navigation to the next Cached finding
   */
  const handleNextClick = () => {
    navigate(next);
  };

  useEffect(() => {
    if (findings) {
      const currentIndex = findings.findIndex((f) => f.uuid === findingUUID);
      const maxIndex = findings.length - 1;
      setFinding((prev) => ({
        ...prev,
        current: {
          step: findings[currentIndex].step,
        },
        maxStep: findings[maxIndex].step,
      }));

      // config navigation to next step
      if (currentIndex < maxIndex) {
        setNext(
          `/${current_customer.uuid}/reporting/finding/${campaignUUID}/${
            findings[currentIndex + 1].uuid
          }`,
        );
      }

      // configure navigation to previous step
      if (currentIndex > 0) {
        setPrevious(
          `/${current_customer.uuid}/reporting/finding/${campaignUUID}/${
            findings[currentIndex - 1].uuid
          }`,
        );
      }

      // disable buttons if there is not previous or next step
      if (currentIndex === maxIndex) setNext(null);
      if (currentIndex === 0) setPrevious(null);
    }
  }, [findings, campaignUUID, findingUUID, current_customer]);

  return (
    <Stack direction="row" justifyContent="space-between">
      <Button
        disabled={previous === null ? true : false}
        color="info"
        variant="contained"
        onClick={handlePreviousClick}
      >
        Previous
      </Button>
      <Chip
        label={`Step: ${finding.current.step + 1}/${finding.maxStep + 1}`}
      />
      <Button
        disabled={next === null ? true : false}
        color="info"
        variant="contained"
        onClick={handleNextClick}
      >
        Next
      </Button>
    </Stack>
  );
};

export default PrevNext;
