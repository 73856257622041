import React, {useContext} from "react";
import {Paper, Typography, Box} from "@mui/material";
import SecurityFlowChartDetails from "../SecurityFlowChartsDetails";
import {AVDetailsContext} from "../../ContextProvider/ContextProvider";

const ExpectedAlertDetail = ({selectedExpectedAlert, tools}) => {
  const {alertInfo} = useContext(AVDetailsContext);

  if (!selectedExpectedAlert) {
    return (
      <Paper elevation={3} sx={{width: '99%', height: '99%', marginTop: 3, p: 3}}>
        <Typography variant="h6">No Expected Alert Selected, Select an item</Typography>
      </Paper>
    );
  }

  return (
    <Paper elevation={3} sx={{width: '99%', height: '99%', marginTop: 3, p: 3}}>
      <Typography variant="h6"> {selectedExpectedAlert.alert_finding.alert_title}  </Typography>
      <br></br>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        <SecurityFlowChartDetails
          tools={tools}
          alertInfo={alertInfo}
          alertFinding={selectedExpectedAlert.alert_finding}
        />
      </Box>
    </Paper>
  );
}

export default ExpectedAlertDetail;