import { useContext } from "react";
import { Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import { AVDetailsContext } from "../ContextProvider/ContextProvider";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../Redux/app/hooks";

const capitalize = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export default function ExerciseDetails() {
  return (
    <Paper className="w-[50%] flex-1" sx={{ p: 3 }}>
      <Stack spacing={1}>
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            fontWeight: "bold",
          }}
        >
          Exercise Details
        </Typography>
        <Divider />
        <Grid container columns={1} rowSpacing={1}>
          <GridItems />
        </Grid>
      </Stack>
    </Paper>
  );
}

const GridItems = () => {
  const { alertInfo } = useContext(AVDetailsContext);
  const navigate = useNavigate();
  const current_customer = useAppSelector(
    (state) => state.customer
  );
  const gridData = [
    {
      title: "Exercise",
      value: (
        <Typography
          component={"span"}
          className="text-links"
          onClick={() =>
            navigate(
              `/${current_customer.uuid}/simulations/exercises/detail/${alertInfo.av_campaign.scheduled_exercise.uuid}`
            )
          }
        >
          {alertInfo.av_campaign.payload.simulation.name}
        </Typography>
      ),
    },
    {
      title: "Endpoint",
      value: (
        <Typography
          component={"span"}
          className="text-links"
          onClick={() =>
            navigate(
              `/${current_customer.uuid}/simulations/endpoints/detail/${alertInfo.av_campaign.machinename.uuid}`
            )
          }
        >
          {alertInfo.av_campaign.machinename.name}
        </Typography>
      ),
    },
    {
      title: "Exercise Frequency",
      value: capitalize(alertInfo.av_campaign.scheduled_exercise.frequency),
    },
    {
      title: "SLA Time",
      value: alertInfo.av_campaign.scheduled_exercise.sla_time + " minutes",
    },
  ];

  return gridData.map((item) => (
    <Grid key={item.title} item sm={1} md={1} lg={1}>
      <Typography>
        <b>{item.title}:</b> {item.value}
      </Typography>
    </Grid>
  ));
};
