import { Box, Breadcrumbs, IconButton, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { FaEdit, FaPlus, FaTrashAlt } from "react-icons/fa";
import TextButton from "../../../Components/Buttons/TextButton";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import AdminAddC2Form from "../../../Components/Forms/Administration/AdminAddC2Form";
import FormModal from "../../../Components/Modals/FormModal";
import { DataContext } from "../../../Context/dataContext";
import { deleteRequest, getRequest } from "../../../Helpers/httpRequests";
import { useAppSelector } from "../../../Redux/app/hooks";
import { COLORS } from "../../../Styles/colors";

const AdminC2Systems = () => {
  // ----- Routing -----

  // ----- CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  // ----- STATES -----
  const [open, setOpen] = useState(false);
  const [existing, setExisting] = useState(undefined);

  /**
   * contains the datagrid props
   */
  const [dataGridData, setDataGridData] = useState({
    rows: [],
    columns: [],
    pageSize: 10,
  });

  // ----- VARIABLES -----

  // ----- Functions -----
  const handleAddC2SystemClick = () => {
    setExisting(undefined);
    setOpen(true);
  };

  // ----- On page load -----
  useEffect(() => {
    /**
     * This function handles the creation of columns and rows for MUI Datagrid. Running this function will could the MUI-Datagrid to re-mount.
     * @param {} param
     */
    const populateDataGrid = (c2s) => {
      const columns = [
        {
          field: "name",
          headerName: "Name",
          minWidth: 100,
          flex: 1,
        },
        {
          field: "username",
          headerName: "Username",
          minWidth: 100,
          flex: 1,
        },
        {
          field: "url",
          headerName: "URL",
          minWidth: 100,
          flex: 1,
        },
        {
          field: "host",
          headerName: "Host",
          minWidth: 100,
          flex: 1,
        },
        {
          field: "actions",
          headerName: "",
          minWidth: 100,
          flex: 1,
          renderCell: (params) => {
            const handleDelete = async (e) => {
              e.stopPropagation();
              const res = await deleteRequest(
                `/react/api/admin-c2systems?uuid=${params.row.id}`,
                accessToken
              );
              if (res.status === 200) {
                populateDataGrid(res.data.c2systems);
              }
            };
            const handleEdit = () => {
              setExisting({
                uuid: params.row.id,
                name: params.row.name,
                username: params.row.username,
                url: params.row.url,
                host: params.row.sentinel || "None",
                active: params.row.active,
                customer: params.row.customer || "",
                integrated: params.row.integrated,
                vendor: params.row.vendor,
                version: params.row.version,
                password: params.row.password,
              });
              setOpen(true);
            };
            return (
              <Box sx={{ width: "100%", textAlign: "center" }}>
                <IconButton onClick={handleEdit}>
                  <FaEdit
                    id={`power-icon-${params.row.id}`}
                    // color={"#963536"}
                  />
                </IconButton>
                <IconButton onClick={handleDelete}>
                  <FaTrashAlt
                    id={`power-icon-${params.row.id}`}
                    color={COLORS.error.main}
                  />
                </IconButton>
              </Box>
            );
          },
        },
      ];

      const rows = c2s.map((element) => {
        return {
          id: element.uuid,
          name: element.name,
          username: element.username,
          url: element.url,
          host: element.sentinel || "None",
          active: element.active,
          customer: element.customer || "",
          integrated: element.integrated,
          vendor: element.vendor,
          version: element.version,
          password: element.password,
        };
      });

      setDataGridData((data) => ({
        ...data,
        rows: rows,
        columns: columns,
      }));
    };

    const getData = async () => {
      const res = await getRequest("/react/api/admin-c2systems", accessToken);
      if (res.status === 200) {
        populateDataGrid(res.data.c2systems);
      } else {
        console.log(
          "%cerror AdminC2Systems.jsx useEffect()",
          "color: red; display: block; width: 100%;",
          "Reponse status not 200"
        );
      }
    };

    getData();
  }, [current_customer, accessToken, open]);

  return (
    <>
      <Stack spacing={3}>
        {/* Breadcrumbs */}
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="text.primary">Administration</Typography>
          <Typography color="text.primary">C2 Systems</Typography>
        </Breadcrumbs>
        {/* Page Title  && Buttons */}
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography variant="h3">C2 Systems</Typography>
          <TextButton
            tooltip="Add C2 System"
            icon={FaPlus}
            onClick={handleAddC2SystemClick}
          />
        </Stack>
        {/* Template Grid */}
        <DataGridWithStyles
          name="admin-c2-systems"
          autoHeight
          paper
          rows={dataGridData.rows}
          columns={dataGridData.columns}
        />
      </Stack>
      <FormModal sx={{ width: "auto" }} open={open} setOpen={setOpen}>
        <AdminAddC2Form existing={existing} setOpen={setOpen} />
      </FormModal>
    </>
  );
};

export default AdminC2Systems;
