import React from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../Redux/app/hooks";

const AWLBypass = () => {
  const current_customer = useAppSelector(
    (state) => state.customer
  );
  // ----- Context -----

  // ----- States -----

  // ----- Variable -----

  // ----- Functions -----

  // ----- On page load -----

  return (
    <Stack spacing={3}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          to={`/${current_customer.uuid}/support/help`}
          color="text.primary"
        >
          Support
        </Link>
        <Typography color="text.primary">AWLBypass</Typography>
      </Breadcrumbs>

      {/* Page Title */}
      <Typography variant="h3">Creating a Campaign - AWL Bypass</Typography>
      <div>
        <ol>
          <li>
            <div>
              <b>Login</b> to the BlindSPOT platform, using your given
              credentials
            </div>
          </li>
          <li>
            Generate and start running a BlindSPOT agent on your system. See the{" "}
            <Link
              display="inline"
              to={`/${current_customer.uuid}/support/Agent`}
            >
              Generating an Agent
            </Link>{" "}
            tutorial
          </li>
          <li>
            <div>
              <div>
                Click the{" "}
                <Link to={`/${current_customer.uuid}/simulations/list`}>
                  Simulation Library
                </Link>{" "}
                option in the left-hand menu options sidebar.
              </div>
              <img
                src="../../../static/img/help/SimLibraryRedo.png"
                alt="Simulation Library menu screenshot"
              />
            </div>
            <br />
          </li>
          <li>
            <div>
              <div>
                Once on the{" "}
                <Link to={`/${current_customer.uuid}/simulations/list`}>
                  Simulations page
                </Link>
                , you will notice that there are a nubmer of options you have
                available to you. Start by clicking on the simulation you'd like
                to run.
              </div>
              <img
                src="../../../static/img/help/simulationExample.png"
                alt="Simulations Example"
              />
            </div>
            <br />
          </li>
          <li>
            <div>
              <div>
                Your screen schould look like this, but with your
                <b>chosen simulation name at the top</b>. In this case, we are
                using the Conti simulation.
              </div>
              <img
                src="../../../static/img/help/ContiExample.png"
                alt="Conti simulation screenshot"
              />
            </div>
            <br />
          </li>
          <li>
            <div>
              <div>
                Now scroll down until the <b>left-hand side</b> of the screen
                shows <b>"AWL Bypass"</b>.
              </div>
              <img
                src="../../../static/img/help/awlbypassExample.png"
                alt="AWL Bypass screenshot"
              />
            </div>
            <br />
          </li>
          <li>
            <div>
              <div>
                To start your new campaign, hit the
                <b>"New Campaign"</b> button, found adjacent to the AWL Bypass
                title.
              </div>
              <img
                src="../../../static/img/help/newCampaginExample.png"
                alt="New Campaign Example Screenshot"
              />
            </div>
            <br />
          </li>
          <li>
            <div>
              <div>
                Once the <b>"New Campaign"</b> button has been selected you
                should now be on the <b>"Create New Campaign"</b> page. Here,
                everything should auto-populate, <b>EXCEPT</b> the name which
                you will need to manually enter.
              </div>
              <img
                src="../../../static/img/help/newCampaignForm.png"
                alt="New Campaign Form Screenshot"
              />
            </div>
            <br />
          </li>
          <li>
            <div>
              <div>
                You will also need to select which <b>agent</b> you want the
                campaign to run on.
              </div>
              <img
                src="../../../static/img/help/agentselection.png"
                alt="Agent Selection Screenshot"
              />
            </div>
            <br />
          </li>
          <li>
            <div>
              <div>
                Now just push the blue <b>submit</b> button.
              </div>
              <img
                src="../../../static/img/help/submitbutton.png"
                alt="Submit button Screenshot"
              />
            </div>
            <br />
          </li>
          <li>
            <div>
              <div>
                The campaign will be <b>downloaded</b> and
                <b>run</b> as the BlindSPOT agent checks in with the BlindSPOT
                service.
              </div>
              <img
                src="../../../static/img/help/campaignstartexample.png"
                alt="Starting a campaign example screenshot"
              />
            </div>
            <br />
          </li>
          <li>
            <div>
              <div>
                As the BlindSPOT agent executes the campaign, the BlindSPOT
                service will mark the campaign as <b>in progress</b>. The agent
                will track the running ransomware campaign and will alert the
                BlindSPOT service when execution has{" "}
                <b>finished, then scoring can begin</b>.
              </div>
              <img
                src="../../../static/img/help/campaigninprogress.png"
                alt="Campaign in progress example screenshot"
              />
            </div>
            <br />
          </li>
        </ol>
      </div>
    </Stack>
  );
};

export default AWLBypass;
