import React from "react";
import { Route, Routes } from "react-router-dom";
import ServiceProviderFocus from "../../Pages/ServiceProvider/ServiceProviderFocus";
import ServiceProviderUsers from "../../Pages/ServiceProvider/ServiceProviderUsers";
import ServiceProviderSettings from "../../Pages/ServiceProvider/ServiceProviderSettings";
import ServiceProviderCustomers from "../../Pages/ServiceProvider/ServiceProviderCustomers/ServiceProviderCustomers";
import ServiceProviderCampaigns from "../../Pages/ServiceProvider/ServiceProviderCampaigns/CampaignsList/ServiceProviderCampaigns";
import ServiceProviderScheduledExercises from "../../Pages/ServiceProvider/ServiceProviderScheduledExercises/ServiceProviderScheduledExercises";

const ServiceProviderRoutes = () => {
  return (
    <Routes>
      <Route path="customers" element={<ServiceProviderCustomers/>}/>
      <Route path="utilization" element={<ServiceProviderFocus/>}/>
      <Route path="users" element={<ServiceProviderUsers/>}/>
      <Route path="admin" element={<ServiceProviderSettings/>}/>
      <Route path="campaigns" element={<ServiceProviderCampaigns/>}/>
      <Route path="scheduled-exercises" element={<ServiceProviderScheduledExercises/>}/>
    </Routes>
  );
};

export default ServiceProviderRoutes;
