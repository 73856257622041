import React, { useEffect, useContext, useState } from "react";
import { getRequest, postRequest } from "../../../../Helpers/httpRequests";
import {
  Button,
  Divider,
  Stack,
  Typography,
  Box,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataContext } from "../../../../Context/dataContext";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../Redux/app/hooks";

const AdminAddAgreementForm = ({ setOpen, modal = true }) => {
  // ----- CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const user = useAppSelector((state) => state.user);
  // ----- STATES -----
  // agreement types
  const [types, setTypes] = useState([]);

  /**
   * Object to hold the values of each input in form.
   */
  const [formData, setFormData] = useState({
    name: "",
    pdf_file: null,
    fileName: "",
    agreement_text: "",
    type: "",
    active: false,
  });

  /**
   * Used for handling the props/children of <LoadingButton>
   */
  const [loadingBtn, setLoadingBtn] = useState({
    loading: false,
    color: "primary",
    text: "Submit",
    helperText: "",
    helperTextColor: "green",
  });

  // ---- Variables ------

  // ----- Functions -----
  const handleUploadInput = (e) => {
    setFormData((data) => ({
      ...data,
      pdf_file: e.target.files[0],
      fileName: e.target.files[0].name,
    }));
  };

  /**
   * Handles the form submission.
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    // prevent page reload on submit
    e.preventDefault();

    // set submit btn to loading
    setLoadingBtn((prev) => ({ ...prev, loading: true }));
    const res = await postRequest(
      "/react/api/admin/upload-agreement",
      accessToken,
      formData
    );
    setLoadingBtn((prev) => ({ ...prev, loading: false }));

    // send form response back as 201 (created)
    if (res.status === 201) {
      toast.success("Success!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      /**
       * If this form is contained in a modal, you can pass the setOpen state to this component.
       * This will wait 1.5 seconds and then close the modal
       * Add 'open' state to this components parent useEffect dependencies array to have your page reload the data.
       */
      if (setOpen !== undefined) {
        setOpen(false);
      }
    } else {
      // handle errors here
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Error - Try again",
        color: "error",
        helperText: "Form submission failed",
        helperTextColor: "#d32f2f", // red
      }));
      console.log(
        "%cerror AdminAddAgreementForm.jsx handleSubmit()",
        "color: red; display: block; width: 100%;",
        "Failed to submit form"
      );
    }
  };

  // ----- On page load -----
  useEffect(() => {
    const getFormData = async () => {
      const res = await getRequest(
        "/react/api/admin/upload-agreement",
        accessToken
      );
      if (res.status === 200) {
        setTypes(res.data.agreement_types);
      }
    };

    // if user.current_customer changes re-run api-request
    if (user.current_customer) {
      getFormData();
    }
  }, [user.current_customer, accessToken]);

  return (
    <Box>
      {/* Form Header */}
      <Typography variant="h5">Add Agreement</Typography>

      <Divider style={{ marginBottom: "0.5rem" }} />

      {/* FORM */}
      <form onSubmit={(e) => handleSubmit(e)}>
        <Stack direction="column" spacing={2}>
          {/* Start form fields here */}
          {/* Name */}
          <TextField
            placeholder="Name"
            label="Name"
            type="text"
            required
            value={formData.name}
            onChange={(e) =>
              setFormData((data) => ({ ...data, name: e.target.value }))
            }
          />

          {/* File upload */}
          <TextField
            value={formData.pdf_file?.name || ""}
            label="Upload File"
            required
            InputProps={{
              fullWidth: true,
              readOnly: true,
              startAdornment: (
                <Button
                  variant="outlined"
                  size="small"
                  fullWidth
                  component="label"
                  sx={{ mr: 1 }}
                >
                  Choose file
                  <input
                    type="file"
                    accept=".pdf,.PDF"
                    hidden
                    onChange={handleUploadInput}
                  />
                </Button>
              ),
            }}
          />

          {/* Agreement Text */}
          <TextField
            placeholder="Agreement Text"
            label="Agreement Text"
            type="text"
            required
            multiline
            maxRows={8}
            minRows={4}
            value={formData.agreement_text}
            onChange={(e) =>
              setFormData((data) => ({
                ...data,
                agreement_text: e.target.value,
              }))
            }
          />

          {/* Type */}
          <FormControl required margin="none">
            <InputLabel id="type-select-label">Type</InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              value={formData.type}
              label="Type *"
              required={true}
              onChange={(e) =>
                setFormData((data) => ({
                  ...data,
                  type: e.target.value,
                }))
              }
            >
              {types.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Active */}
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.active}
                  onChange={() =>
                    setFormData((data) => ({
                      ...data,
                      active: !data.active,
                    }))
                  }
                />
              }
              label="Active"
            />
          </FormControl>

          {/* End form fields */}

          {/* helper text */}
          <Typography color={loadingBtn.helperTextColor} variant="subtitle2">
            {loadingBtn.helperText}
          </Typography>

          {/* Submit Button */}
          <LoadingButton
            loading={loadingBtn.loading}
            color={loadingBtn.color}
            type="submit"
            variant="contained"
          >
            {loadingBtn.text}
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
};

AdminAddAgreementForm.propTypes = {
  setOpen: PropTypes.func,
  modal: PropTypes.bool,
};

export default AdminAddAgreementForm;
