import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../../Context/dataContext";
import { postRequest } from "../../../../Helpers/httpRequests";
import { useAppSelector } from "../../../../Redux/app/hooks";

const SecurityToolAddForm = ({ closeModal, setReload, products }) => {
  // ----- STATES && CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const customer = useAppSelector((state) => state.customer);
  const [formData, setFormData] = useState({
    name: "",
    product: "Other",
    vendor: "",
    log_destination: "none",
    access_url: "",
    api_key: "",
  });

  // Submit Btn
  const [submitBtnColor, setSubmitBtnColor] = useState("primary");
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [submitBtnText, setSubmitBtnText] = useState("Submit");

  // ----- VARIABLES -----

  // ----- FUNCTIONS -----
  const handleSubmit = async () => {
    setSubmitBtnLoading(true);
    const res = await postRequest(
      `/react/api/${customer.uuid}/config/securitytools/add`,
      accessToken,
      formData,
    );
    setSubmitBtnLoading(false);
    if (res.status === 200) {
      closeModal();
      setReload((current) => !current);
    } else {
      setSubmitBtnColor("error");
      setSubmitBtnText("Error - Try again");
    }
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {}, []);

  return (
    <>
      <Typography fontWeight={"bold"} variant="h4">
        Create New Security Tool
      </Typography>

      <Box className="left-box" sx={{ flex: 1, paddingRight: 1, marginTop: 1 }}>
        <Paper sx={{ padding: 2 }}>
          <Stack spacing={1.5}>
            {/* Name */}
            <TextField
              type="text"
              label="Name"
              helperText=""
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
            />

            {/* Vendor */}
            <TextField
              type="text"
              label="Vendor"
              helperText=""
              onChange={(e) =>
                setFormData({ ...formData, vendor: e.target.value })
              }
            />

            {/* Products */}
            <FormControl>
              <InputLabel id="product">Product</InputLabel>
              {products.length > 0 && (
                <Select
                  labelId="product-label"
                  label="Product"
                  value={formData.product}
                  onChange={(e) =>
                    setFormData({ ...formData, product: e.target.value })
                  }
                >
                  {products.map((product, index) => (
                    <MenuItem key={index} value={product.name}>
                      {product.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>

            {/* Log Destination */}
            <FormControl>
              <InputLabel id="demo-simple-select-label">
                Log Destination
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Log Destination"
                defaultValue={"none"}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    log_destination: e.target.value,
                  })
                }
              >
                <MenuItem value={"siem"}>SIEM</MenuItem>
                <MenuItem value={"console"}>System Console</MenuItem>
                <MenuItem value={"other"}>Other Log Destination</MenuItem>
                <MenuItem value={"none"}>
                  No Logs forwarded or collected
                </MenuItem>
              </Select>
            </FormControl>

            {/* Loading Button */}
            <LoadingButton
              variant="contained"
              loading={submitBtnLoading}
              onClick={handleSubmit}
              color={submitBtnColor}
            >
              {submitBtnText}
            </LoadingButton>
          </Stack>
        </Paper>
      </Box>
    </>
  );
};

export default SecurityToolAddForm;
