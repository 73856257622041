import React, { useEffect, useContext, useState } from "react";
import { DataContext } from "../../../../Context/dataContext";
import { getRequest } from "../../../../Helpers/httpRequests";
import { Box, Paper, Typography, Stack } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import CampaignContext from "../provider/CampaignContext";
import { useAppSelector } from "../../../../Redux/app/hooks";

const CampaignContextInfo = () => {
  const { accessToken } = useContext(DataContext);
  const { executedUUID, campaign } = useContext(CampaignContext);
  const [activehostsession, setActiveHostSession] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const customer = useAppSelector((state) => state.customer);

  useEffect(() => {
    const fetchTechOutcomes = async () => {
      try {
        const res = await getRequest(
          `/react/api/${customer.uuid}/report/campaign/host_info/${executedUUID}`,
          accessToken
        );

        if (res.status === 200) {
          setActiveHostSession(res.data.activehostsession);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTechOutcomes();
  }, [executedUUID, accessToken, customer.uuid]);

  return (
    campaign && (
      <Paper sx={{ marginTop: 2 }}>
        <Typography
          component={"span"}
          fontWeight={"bold"}
          variant="h6"
          sx={{
            display: "flex",
            padding: 2,
          }}
        >
          Campaign Context Information
        </Typography>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography align="left" color="error">
            Error: {error.message}
          </Typography>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Stack sx={{ pl: 6, textAlign: "right" }}>
              <Typography sx={{ marginBottom: 1 }}>Host</Typography>
              <Typography sx={{ marginBottom: 1 }}>User Name</Typography>
              <Typography sx={{ marginBottom: 1 }}>Domain</Typography>
              <Typography sx={{ marginBottom: 1 }}>Local User</Typography>
              <Typography sx={{ marginBottom: 1 }}>Local Admin</Typography>
              <Typography sx={{ marginBottom: 1 }}>LogonType</Typography>
              <Typography sx={{ marginBottom: 1 }}>LogonTime</Typography>
              <Typography sx={{ marginBottom: 1 }}>DnsDomainName</Typography>
              <Typography sx={{ marginBottom: 1 }}>FullUser</Typography>
              <Typography sx={{ marginBottom: 1 }}>
                LogonFriendlyType
              </Typography>
              <Typography sx={{ marginBottom: 1 }}>Operating System</Typography>
              <Typography sx={{ marginBottom: 1 }}>Domain</Typography>
              <Typography sx={{ marginBottom: 1 }}>Elevated</Typography>
              <Typography sx={{ marginBottom: 1 }}>Processes</Typography>
            </Stack>
            <Stack sx={{ flex: 1, marginLeft: 5 }}>
              <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                {(activehostsession.host &&
                  activehostsession.host.dns_hostname) ||
                  "None"}
              </Typography>
              <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                {activehostsession.username || "None"}
              </Typography>
              <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                {activehostsession.domain || "None"}
              </Typography>
              <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                {(activehostsession.localUser && "Yes") || "No"}
              </Typography>
              <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                {(activehostsession.localAdmin && "Yes") || "No"}
              </Typography>
              <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                {activehostsession.logonType || "None"}
              </Typography>
              <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                {moment(activehostsession.logonTime).format(
                  "MMM. D, YYYY, hh:mm a"
                ) || "None"}
              </Typography>
              <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                {activehostsession.dnsDomainName || "None"}
              </Typography>
              <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                {activehostsession.fullUser || "None"}
              </Typography>
              <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                {activehostsession.logonFriendlyType || "None"}
              </Typography>
              <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                {campaign.operating_system || "None"}
              </Typography>
              <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                {campaign.domain || "None"}
              </Typography>
              <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                {(campaign.elevated && "Yes") || "No"}
              </Typography>
              {(campaign.processes?.length > 0 &&
                campaign.processes.map((process) => {
                  return (
                    <Stack key={process.uuid} sx={{ marginBottom: 1 }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Executable: {process.executable}
                      </Typography>
                      <Typography>PID: {process.pid}</Typography>
                      <Typography>PPID: {process.ppid}</Typography>
                    </Stack>
                  );
                })) || (
                <Typography sx={{ fontWeight: "bold" }}>None</Typography>
              )}
            </Stack>
          </Box>
        )}
      </Paper>
    )
  );
};

export default CampaignContextInfo;
