import { Stack } from "@mui/system";
import { useContext } from "react";
import { FaJs } from "react-icons/fa";
import TextButton from "../../../../Components/Buttons/TextButton/TextButton";
import { DataContext } from "../../../../Context/dataContext";
import { fileRequest } from "../../../../Helpers/httpRequests";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../Redux/app/hooks";

export const DownloadReportJson = (props) => {
  const { uuid, name } = props;
  const { accessToken } = useContext(DataContext);
  const customer = useAppSelector((state) => state.customer);

  const downloadReportJson = async () => {
    const loadingToast = toast.loading("Attempting to Downlaod Project Json")
    const res = await fileRequest(
      `/react/api/${customer.uuid}/report/${uuid}/download-json`,
      accessToken,
      `${name}.json`
    );
    if (res.status !== 200) {
      toast.dismiss(loadingToast)
      toast.error("Failed to Download Project JSON")
    } else {
      toast.dismiss(loadingToast)
      toast.success("Project JSON Downloaded")
    }
  };

  return (
    <div>
      <Stack spacing={2} direction="row">
        <TextButton
          tooltip="Download Project Json"
          icon={FaJs}
          onClick={downloadReportJson}
        />
      </Stack>
    </div>
  );
};
