import {
  Box,
  Button,
  Stack,
  Typography
} from "@mui/material";
import React from "react";
import "react-select-search/style.css";
import FormModal from "../../../../Components/Modals/FormModal";

const OsChangeModal = ({osChangeModal, setOsChangeModal, setOsType, tempOs, setTempOs, setActionData, setData}) => {
  return (
    <FormModal open={osChangeModal} setOpen={setOsChangeModal}>
        <Box component="form">
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: 4 }}
          >
            Changing the OS will result in losing all changes, do you wish to
            continue?
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Button
              variant="outlined"
              onClick={() => {
                setOsType(tempOs);
                setTempOs("");
                setActionData({
                  module: "",
                  ttps: [],
                  arguments: [],
                  note: "",
                  time_out: 60,
                  clean_up: false,
                  expected_outcome: null,
                });
                setData((prevData) => ({
                  ...prevData,
                  actions: [],
                }));
                setOsChangeModal(false);
              }}
            >
              Yes
            </Button>
          </Stack>
        </Box>
      </FormModal>
  )
}

export default OsChangeModal
