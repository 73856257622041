// functions.js
import { Typography, Stack } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { FaCheck, FaTimes } from "react-icons/fa";
import { COLORS } from "../../../../Styles/colors";

export const renderJsonStatus = (checkingJson) => {
  if (checkingJson.message === "validating JSON...") {
    return (
      <Stack direction="row" alignItems="center">
        <Typography sx={{ marginRight: 1, marginLeft: 2 }} component="span">
          {checkingJson.message}
        </Typography>
        <CircularProgress size={20} />
      </Stack>
    );
  } else if (checkingJson.message === "JSON validated") {
    return (
      <Stack direction="row" alignItems="center">
        <Typography sx={{ marginRight: 1, marginLeft: 2 }} component="span">
          {checkingJson.message}
        </Typography>
        <FaCheck color={COLORS.success.main} />
      </Stack>
    );
  } else if (checkingJson.message === "Invalid JSON") {
    return (
      <Stack direction="row" alignItems="center">
        <Typography sx={{ marginRight: 1, marginLeft: 2 }} component={"span"}>
          {checkingJson.message}
        </Typography>
        <FaTimes color={COLORS.error.main} />
        {checkingJson.invalidKeys.length > 0 && (
          <Typography>
            {" "}
            Modules not supported - {checkingJson.invalidKeys.join(", ")}{" "}
          </Typography>
        )}
      </Stack>
    );
  } else {
    return null;
  }
};
