import { Typography, Card, Box, CardContent } from "@mui/material";
import { Stack } from "@mui/system";
import { COLORS } from "../../../Styles/colors";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../Redux/app/hooks";

const AttackMatrixDiagram = (props) => {
  const { killChain, techniques } = props;
  const current_customer = useAppSelector(
    (state) => state.customer,
  );

  // ----- States -----

  // ----- Variable -----

  // ----- Functions -----

  // ----- On page load -----

  return (
    <Box
      sx={{ display: "flex", flexDirection: "row", maxWidth: "100%" }}
      id="attack-matrix"
    >
      {killChain.length > 0
        ? killChain.map((group) => {
            return (
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  paddingX: 0.5,
                  whiteSpace: "nowrap",
                  maxWidth: "100%",
                }}
                key={group.display}
              >
                <Card
                  sx={{
                    backgroundColor: COLORS.info.dark,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" sx={{ color: "white" }}>
                      {group.display}
                    </Typography>
                  </CardContent>
                </Card>
                <div className="grid grid-rows-6 grid-flow-col gap-4">
                  {techniques
                    ? techniques.map((tech) => {
                        let tactics = [];
                        for (let i = 0; i < tech.tactic.length; i++) {
                          tactics.push({
                            name: tech.tactic[i].name,
                            outcomes: tech.tactic[i].outcomes,
                          });
                        }
                        if (tactics.find((t) => t.name === group.identifier)) {
                          return (
                            <Card
                              key={tech.uuid}
                              sx={{
                                marginTop: "8px",
                                whiteSpace: "normal",
                                minWidth: "8rem",
                              }}
                            >
                              <CardContent>
                                <Link
                                  to={`/${current_customer.uuid}/reporting/technique/${tech.attack_id}`}
                                >
                                  {tech.attack_id}
                                </Link>
                                <Typography>{tech.name}</Typography>
                                <Stack
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  {tech.outcomes?.blocked > 0 ? (
                                    <Box
                                      sx={{
                                        backgroundColor: COLORS.success.main,
                                        paddingX: "6px",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Typography color="white">
                                        {tech.outcomes.blocked}
                                      </Typography>
                                    </Box>
                                  ) : null}
                                  {tech.outcomes?.alerted > 0 ? (
                                    <Box
                                      sx={{
                                        backgroundColor: COLORS.info.main,
                                        paddingX: "6px",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Typography color="white">
                                        {tech.outcomes.alerted}
                                      </Typography>
                                    </Box>
                                  ) : null}
                                  {tech.outcomes?.logged > 0 ? (
                                    <Box
                                      sx={{
                                        backgroundColor: COLORS.yellow,
                                        paddingX: "6px",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Typography color="white">
                                        {tech.outcomes.logged}
                                      </Typography>
                                    </Box>
                                  ) : null}
                                  {tech.outcomes?.nothing > 0 ? (
                                    <Box
                                      sx={{
                                        backgroundColor: COLORS.error.main,
                                        paddingX: "6px",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Typography color="white">
                                        {tech.outcomes.nothing}
                                      </Typography>
                                    </Box>
                                  ) : null}
                                  {tech.outcomes?.not_scored > 0 ? (
                                    <Box
                                      sx={{
                                        backgroundColor: COLORS.secondary.main,
                                        paddingX: "6px",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Typography color="white">
                                        {tech.outcomes.not_scored}
                                      </Typography>
                                    </Box>
                                  ) : null}
                                </Stack>
                              </CardContent>
                            </Card>
                          );
                        } else {
                          return null;
                        }
                      })
                    : null}
                </div>
              </Stack>
            );
          })
        : null}
    </Box>
  );
};

export default AttackMatrixDiagram;
