import { Button, Modal, Stack, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { DataContext } from "../../../Context/dataContext";
import { postRequest } from "../../../Helpers/httpRequests";
import { useAppSelector } from "../../../Redux/app/hooks";
import { COLORS } from "../../../Styles/colors";

const UsageModal = (props) => {
  const { open, setOpen, tool, setTool, setReload, text, instance } = props;
  const { accessToken } = useContext(DataContext);
  const darkmode = useAppSelector((state) => state.darkmode);
  const customer = useAppSelector((state) => state.customer);

  const [toolExists, setToolExists] = useState(false);

  if (tool == null) return null;

  const deleteSecurityTool = async () => {
    const loadingToast = toast.loading("Deleting Security Tool");
    const res = await postRequest(
      `/react/api/${customer.uuid}/config/securitytools/delete/${tool.uuid}`,
      accessToken
    );
    if (res.status === 200) {
      toast.dismiss(loadingToast);
      toast.success("Security Tool Deleted");
      setReload((prev) => !prev);
      setOpen(false);
    }
    if (res.status === 403) {
      toast.dismiss(loadingToast);
      toast.error("Error: Security tool in use");
      setToolExists(true);
    }
  };

  const archiveSecurityTool = async () => {
    const loadingToast = toast.loading("Archiving Security Tool");
    const res = await postRequest(
      `/react/api/${customer.uuid}/config/securitytools/archive/${tool.uuid}`,
      accessToken
    );
    if (res.status === 200) {
      toast.dismiss(loadingToast);
      toast.success("Security Tool Archived");
      setReload((prev) => !prev);
      setOpen(false);
    }
    if (res.status === 403) {
      toast.dismiss(loadingToast);
      toast.error("Error: Security tool in use");
    }
  };

  const restoreSecurityTool = async () => {
    const loadingToast = toast.loading("Restoring Security Tool");
    const res = await postRequest(
      `/react/api/${customer.uuid}/config/securitytools/restore/${tool.uuid}`,
      accessToken
    );
    if (res.status === 200) {
      toast.dismiss(loadingToast);
      toast.success("Security Tool Restored");
      setReload((prev) => !prev);
      setOpen(false);
    } else {
      toast.dismiss(loadingToast);
      toast.error("Failed to restore security tool");
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
    setTool(null);
    setToolExists(false);
  };

  const BUTTON_WIDTH = "5rem";

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      BackdropComponent={() => (
        <div
          style={{
            backgroundColor: darkmode ? "white" : "black",
            opacity: 0.4,
            width: "100%",
            height: "100%",
          }}
          onClick={handleCloseModal}
        />
      )}
    >
      <Stack alignItems="center" sx={style}>
        <Typography
          variant="h6"
          style={{ textAlign: "center", marginBottom: 4 }}
        >
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: 4 }}
          >
            {text ? text : `Are you sure you want to remove ${tool.name} tool?`}
          </Typography>
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button
            disabled={toolExists}
            sx={{ width: BUTTON_WIDTH }}
            onClick={() => {
              if (instance === "archive") {
                archiveSecurityTool();
              } else if (instance === "delete") {
                deleteSecurityTool();
              } else if (instance === "restore") {
                restoreSecurityTool();
              }
            }}
            variant="contained"
          >
            Yes
          </Button>

          <Button
            disabled={toolExists}
            sx={{ width: BUTTON_WIDTH }}
            color="error"
            onClick={handleCloseModal}
            variant="contained"
          >
            No
          </Button>
        </Stack>
        {toolExists && (
          <Typography color={COLORS.error.main} variant="subtitle2">
            This tool has been used to score campaigns, unable to delete.
          </Typography>
        )}
      </Stack>
    </Modal>
  );
};

export default UsageModal;
