import { Alert, Breadcrumbs, Typography, Box, Stack, Grid } from "@mui/material"; 
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DataContext } from "../../../Context/dataContext";
import { getRequest } from "../../../Helpers/httpRequests";
import { useAppSelector } from "../../../Redux/app/hooks";
import SecurityToolCard from "../../Config/ConfigSecurityTools/SecurityToolCard";
import { LogChain } from "./LogChain/LogChain";

const AV_Config = () => {
  // ----- STATES && CONTEXT -----
  const [reload, setReload] = useState(false);
  const [tools, setTools] = useState([]);
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector((state) => state.customer);

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getConfig = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/alertvalidation/config`,
        accessToken
      );
      if (res.status === 200) {
        console.log(res)
        setTools(res.data.securityTools);
      }
    };

    getConfig();
  }, [current_customer, accessToken, reload]);

  return (
    <Stack spacing={2}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Alert Validation</Typography>
        <Typography color="text.primary">Config</Typography>
      </Breadcrumbs>

      {/* Page Title */}
      <Box
        sx={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Typography fontWeight={"bold"} variant="h4">
          Security Tool Integrations
        </Typography>
      </Box>

      {/* Alerts when no tools are added */}
      {tools.length < 1 && (
        <>
          <Alert severity="info">
            Security Tools have not been added— please add them from the{" "}
            <Link to={`/${current_customer.uuid}/config/securitytools`}>
              <b>Security Tool Page</b>
            </Link>
          </Alert>
        </>
      )}

      {/* Map tools to SecurityToolCard */}
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3} columns={18}>
          {tools.map((tool, index) => (
            <SecurityToolCard key={index} tool={tool} hide={true} setReload={setReload}/>
          ))}
        </Grid>
      </Box>

      {/* LogChain Component */}
      <Box>
        <LogChain />
      </Box>
    </Stack>
  );
};

export default AV_Config;
