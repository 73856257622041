import { Button, Divider, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { DataContext } from "../../../../Context/dataContext";
import { postRequest } from "../../../../Helpers/httpRequests";
import FileSelect from "../../../FileSelect/FileSelect";

const ServiceProviderUpdateCxLogo = ({ selectedCustomer }) => {
  // ----- Context -----
  const { accessToken } = useContext(DataContext);

  // ----- States -----
  const [formData, setFormData] = useState({
    img: null,
  });

  // ----- Variable -----

  // ----- Functions -----
  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await toast.promise(
      postRequest(
        `/react/api/serviceprovider/customer-logo/${selectedCustomer.uuid}`,
        accessToken,
        formData
      ),
      {
        pending: "Updating Settings",
        success: "Settings updated",
        error: "Something went wrong!",
      }
    );
    if (res.status === 200) {
      setFormData({ img: null });
    }
  };

  // ----- On page load -----

  return (
    <Paper sx={{ p: 3, maxWidth: 600 }}>
      <Typography variant="h5">{`Update ${selectedCustomer.name} Logo`}</Typography>
      <Divider style={{ margin: "1rem 0" }} />
      <form onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <FileSelect
            label="Upload Image"
            formKey="img"
            accept="image/*"
            setFormData={setFormData}
          />
          <Button type="submit" variant="contained">
            Save
          </Button>
        </Stack>
      </form>
    </Paper>
  );
};

export default ServiceProviderUpdateCxLogo;
