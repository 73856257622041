import { Breadcrumbs, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../Redux/app/hooks";

const Agent = () => {
  const current_customer = useAppSelector(
    (state) => state.customer
  );
  // ----- Context -----

  // ----- States -----

  // ----- Variable -----

  // ----- Functions -----

  // ----- On page load -----

  return (
    <Stack spacing={3}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          to={`/${current_customer.uuid}/support/help`}
          color="text.primary"
        >
          Support
        </Link>
        <Typography color="text.primary">Agent</Typography>
      </Breadcrumbs>

      {/* Page Title */}
      <Typography variant="h4">Creating an Agent with Blindspot</Typography>
      <div>
        <ol>
          <li>
            <div>
              Login to the BlindSPOT platform, using your given credentials.
            </div>
          </li>
          <li>
            <div>
              Start by pushing the green <b>Download Agents</b> button found at
              the end of the left-hand sidebar.
            </div>
            <img
              src="../../../static/img/help/DownloadAgentButton.png"
              alt="Download agent button"
            />
          </li>
          <br />
          <li>
            <div>
              You should now be brought to the
              <b>Download BlingSPOT Agents</b> page. Here, you can download the
              default BlindSPOT agent, or create a new agent.
            </div>
            <img
              src="../../../static/img/help/DownloadAgentsPage.png"
              alt="Download agents page"
            />
          </li>
          <br />
          <li>
            <div>
              Create a new agent by selecting the
              <b>Create New Agent</b> button.
            </div>
            <img
              src="../../../static/img/help/CreateAgentButton.png"
              alt="Create agent button"
            />
          </li>
          <br />
          <li>
            <div>
              Next, fill out the <b>Create New Agent</b> form using thet
              <b>Agent Build Help</b> description found on the right hand side
              of the screen.
            </div>
            <img
              src="../../../static/img/help/AgentBuildHelp.png"
              alt="Agent Build Help"
            />
          </li>
          <br />
          <li>
            <div>
              Once the form is filled out, push the <b>submit button</b>.
            </div>
            <img
              src="../../../static/img/help/AgentSubmitButton.png"
              alt="Agent Submit button"
            />
            <div>
              Now, this agent will be automatically populated under the
              <b>Download Agents</b> with the relevant unique loCs.
            </div>
          </li>
          <li>
            <div>
              You can find you new agent by clicking the <b>Agents</b> tab on
              the left side of the screen.
            </div>
            <img
              src="../../../static/img/help/AgentTabLocation.png"
              alt="Agent tab location"
            />
          </li>
          <br />
          <li>
            <div>
              Now you should see your newly configured agent here, on the
              <b>Download Blindspot Agents</b> list.
            </div>
            <img
              src="../../../static/img/help/DownloadAgentExample.png"
              alt="Download agent example"
            />
          </li>
        </ol>
      </div>
    </Stack>
  );
};

export default Agent;
