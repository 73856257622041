import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  Paper,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import { DataContext } from "../../../Context/dataContext";
import { useAppSelector } from "../../../Redux/app/hooks";
import { LoadingButton } from "@mui/lab";
import { postRequest } from "../../../Helpers/httpRequests";

const TemplatesAddForm = ({ onCancel, setReloadTemplates }) => {
  // ----- STATES && CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const user = useAppSelector((state) => state.user);
  const current_customer = useAppSelector((state) => state.customer);
  const [formData, setFormData] = useState({
    defaultTemplate: false,
    universal: false,
    name: "",
    file_name: "",
    file_base64: "",
    singleCampaign: false,
  });

  // Submit Btn
  const [submitBtnColor, setSubmitBtnColor] = useState("primary");
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [submitBtnText, setSubmitBtnText] = useState("Submit");
  const [errorMsg, setErrorMsg] = useState("");

  // ----- FUNCTIONS -----
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result.split(",")[1];
      setFormData((prevData) => ({
        ...prevData,
        file_name: file.name,
        file_base64: base64String,
      }));
    };

    reader.readAsDataURL(file);
  };

  const handleSubmit = async () => {
    setErrorMsg("");
    setSubmitBtnLoading(true);

    const payload = {
      name: formData.name,
      universal: formData.universal,
      singleCampaign: formData.singleCampaign,
      defaultTemplate: formData.defaultTemplate,
      file_name: formData.file_name,
      file_base64: formData.file_base64,
    };

    const res = await postRequest(
      `/api/v2/${current_customer.uuid}/reporttemplates`,
      accessToken,
      payload
    );

    setSubmitBtnLoading(false);
    if (res.status === 200) {
      onCancel();
      setReloadTemplates((current) => !current);
    } else {
      setSubmitBtnColor("error");
      setSubmitBtnText("Error - Try again");
      setErrorMsg(res.data.error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    setFormData({
      defaultTemplate: false,
      universal: false,
      name: "",
      file_name: null,
      file_base64: "",
      singleCampaign: false,
    });
  }, []);

  return (
    <Stack spacing={2}>
      <Typography fontWeight={"bold"} variant="h4">
        Add Project Report
      </Typography>

      <Box sx={{ display: "flex", width: "100%" }}>
        <Box
          className="left-box"
          sx={{ flex: 1, paddingRight: 1, marginTop: 1 }}
        >
          <Paper sx={{ padding: 2 }}>
            <Stack spacing={1.5}>
              {/*File Name*/}
              <TextField
                label="Template Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              {/* File Upload */}
              <Typography component={"span"}>
                File Name
                <TextField
                  sx={{ marginLeft: 2 }}
                  type="file"
                  onChange={handleFileChange}
                />
              </Typography>

              {/* SingleCampaign */}
              {user.superuser && (
                <Typography component={"span"}>
                  Single Campaign Template
                  <Checkbox
                    label="Single Campaign Template"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        singleCampaign: e.target.checked,
                      })
                    }
                    checked={formData.singleCampaign}
                  />
                </Typography>
              )}
              {/* Universal */}
              {user.superuser && (
                <Typography component={"span"}>
                  Universal
                  <Checkbox
                    label="Universal"
                    onChange={(e) =>
                      setFormData({ ...formData, universal: e.target.checked })
                    }
                    checked={formData.universal}
                  />
                </Typography>
              )}
              {/* Loading Button */}
              <LoadingButton
                variant="contained"
                loading={submitBtnLoading}
                onClick={handleSubmit}
                color={submitBtnColor}
              >
                {submitBtnText}
              </LoadingButton>
              <Typography>{errorMsg}</Typography>
            </Stack>
          </Paper>
        </Box>
      </Box>
    </Stack>
  );
};

export default TemplatesAddForm;
