import React, { useEffect, useContext, useState } from "react";
import { getRequest, postRequest } from "../../../../Helpers/httpRequests";
import {
  Divider,
  Stack,
  Typography,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataContext } from "../../../../Context/dataContext";
import PropTypes from "prop-types";
import FileSelect from "../../../../Components/FileSelect/FileSelect";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../Redux/app/hooks";

const AdminAddUtilityForm = ({ setOpen }) => {
  // ----- CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const user = useAppSelector((state) => state.user);

  // ----- STATES -----
  const [platforms, setPlatforms] = useState([]);
  const [customers, setCustomers] = useState([]);

  /**
   * Object to hold the values of each input in form.
   */
  const [formData, setFormData] = useState({
    name: "",
    payload_file: null,
    payload_command: "",
    platform: "",
    customerUUID: "",
  });

  /**
   * Used for handling the props/children of <LoadingButton>
   */
  const [loadingBtn, setLoadingBtn] = useState({
    loading: false,
    color: "primary",
    text: "Submit",
    helperText: "",
    helperTextColor: "green",
  });

  // ---- Variables ------

  // ----- Functions -----

  /**
   * Handles the form submission.
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    // prevent page reload on submit
    e.preventDefault();

    // set submit btn to loading
    setLoadingBtn((prev) => ({ ...prev, loading: true }));

    const res = await toast.promise(
      postRequest("/react/api/admin/utilities/add", accessToken, formData),
      {
        pending: "Creating Utility",
        success: `'${formData.name}' created Successfully!`,
        error: "Something went wrong!",
      }
    );

    setLoadingBtn((prev) => ({ ...prev, loading: false }));

    // send form response back as 201 (created)
    if (res.status === 201) {
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Success",
        color: "success",
        helperText: "Success",
        helperTextColor: "#2e7d32", // green
      }));

      /**
       * If this form is contained in a modal, you can pass the setOpen state to this component.
       * This will wait 1.5 seconds and then close the modal
       * Add 'open' state to this components parent useEffect dependencies array to have your page reload the data.
       */
      if (setOpen !== undefined) {
        setOpen(false);
      }
    } else {
      // handle errors here
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Error - Try again",
        color: "error",
        helperText: "Form submission failed",
        helperTextColor: "#d32f2f", // red
      }));
      console.log(
        "%cerror AdminAddUtilityForm.jsx handleSubmit()",
        "color: red; display: block; width: 100%;",
        "Failed to submit form"
      );
    }
  };

  // ----- On page load -----
  useEffect(() => {
    const getFormData = async () => {
      const res = await getRequest(
        "/react/api/admin/utilities/add",
        accessToken
      );
      if (res.status === 200) {
        setCustomers(res.data.customers);
        setPlatforms(res.data.platforms);
      }
    };

    // if user.current_customer changes re-run api-request
    if (user.current_customer) {
      getFormData();
    }
  }, [user.current_customer, accessToken]);

  return (
    <Box>
      {/* Form Header */}
      <Typography variant="h5">Add New Utiliy File</Typography>

      <Divider style={{ marginBottom: "0.5rem" }} />

      {/* FORM */}
      <form onSubmit={(e) => handleSubmit(e)}>
        <Stack direction="column" spacing={2}>
          {/* Start form fields here */}

          {/* Name */}
          <TextField
            placeholder="Name"
            label="Name"
            type="text"
            required
            value={formData.name}
            onChange={(e) =>
              setFormData((data) => ({ ...data, name: e.target.value }))
            }
          />

          {/* Payload File */}
          <FileSelect
            formKey="payload_file"
            setFormData={setFormData}
            label="Payload File"
            accept="*"
          />

          {/* Payload Command */}
          <TextField
            placeholder="Payload Command"
            label="Payload Command"
            type="text"
            required
            value={formData.payload_command}
            onChange={(e) =>
              setFormData((data) => ({
                ...data,
                payload_command: e.target.value,
              }))
            }
          />

          {/* Platform */}
          <FormControl fullWidth>
            <InputLabel>Platform</InputLabel>
            <Select
              value={formData.platform || ""}
              label="Platform"
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, platform: e.target.value }))
              }
            >
              {platforms.length > 0 &&
                platforms.map((platform) => (
                  <MenuItem value={platform} key={`platform-${platform}`}>
                    {platform}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          {/* Customer */}
          <FormControl fullWidth>
            <InputLabel>Customer</InputLabel>
            <Select
              value={formData.customerUUID || ""}
              label="Customer"
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  customerUUID: e.target.value,
                }))
              }
            >
              {customers.length > 0 &&
                customers.map((customer) => (
                  <MenuItem key={customer.uuid} value={customer.uuid}>
                    {customer.display_name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          {/* End form fields */}

          {/* helper text */}
          <Typography color={loadingBtn.helperTextColor} variant="subtitle2">
            {loadingBtn.helperText}
          </Typography>

          {/* Submit Button */}
          <LoadingButton
            loading={loadingBtn.loading}
            color={loadingBtn.color}
            type="submit"
            variant="contained"
          >
            {loadingBtn.text}
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
};

AdminAddUtilityForm.propTypes = {
  setOpen: PropTypes.func,
  modal: PropTypes.bool,
};

export default AdminAddUtilityForm;
