import React, { useEffect, useState, useContext } from "react";
import { DataContext } from "../../../Context/dataContext";
import { getRequest } from "../../../Helpers/httpRequests";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useAppSelector } from "../../../Redux/app/hooks";

const AlertSelect = (props) => {
  // PROPS
  const { alertUUID, setAlertUUID } = props;

  // ----- STATES && CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const user = useAppSelector((state) => state.user);
  const customer = useAppSelector((state) => state.customer);
  const [alerts, setAlerts] = useState([]);

  // ----- VARIABLES -----

  // ----- FUNCTIONS -----
  const handleChange = (event) => {
    setAlertUUID(event.target.value);
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getAlerts = async () => {
      const res = await getRequest(
        `/react/api/${customer.uuid}/alertvalidation/scheduled-exercises`,
        accessToken
      );
      if (res.status === 200) {
        setAlerts(res.data.alerts);
      }
    };
    if (user.current_customer) {
      getAlerts();
    }
  }, [user.current_customer, accessToken, customer.uuid]);

  return (
    <FormControl sx={{ minWidth: 160 }}>
      <InputLabel id="alert-select">Alert Selection</InputLabel>
      <Select
        labelId="alert-select-label"
        id="alert-select"
        value={alertUUID}
        label="All Alerts"
        onChange={handleChange}
        autoWidth
      >
        {alerts.map((alert) => (
          <MenuItem key={alert.uuid} value={alert.uuid}>
            {alert.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default AlertSelect;
