import React from "react";
import { FormControl, Select, InputLabel, MenuItem } from "@mui/material";

const SecToolSelect = ({ data, setData, securityTools }) => {
  const handleChange = (e) => {
    setData((prev) => {
      return {
        ...prev,
        securityTool: e.target.value,
      };
    });
  };

  const options = securityTools.map((tool) => {
    return (
      <MenuItem key={`secTool-${tool.uuid}`} value={tool.uuid}>
        {tool.name}
      </MenuItem>
    );
  });

  return (
    <FormControl sx={{ flex: 3 }}>
      <InputLabel>Security Tool</InputLabel>
      <Select
        value={data.securityTool}
        label="Security Tool"
        onChange={handleChange}
      >
        {options}
      </Select>
    </FormControl>
  );
};

export default SecToolSelect;
