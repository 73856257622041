import React from "react";
import { CircularProgress } from "@mui/material";
import { useAppSelector } from "../../../Redux/app/hooks";

const Splashscreen = () => {
  const darkmode = useAppSelector((state) => state.darkmode);

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: darkmode ? "#0A1940" : "#1B3846",
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default Splashscreen;
