import PreviewIcon from "@mui/icons-material/Preview";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import DataGridWithStyles from "../../../../Components/DataGrid/DataGridWithStyles";
import FormModal from "../../../../Components/Modals/FormModal";
import { DataContext } from "../../../../Context/dataContext";
import { deleteRequest, postRequest } from "../../../../Helpers/httpRequests";
import { COLORS } from "../../../../Styles/colors";
import SecurityFlowChart from "./SecurityFlowChart";
import { useAppSelector } from "../../../../Redux/app/hooks";

export const LogChainTable = (props) => {
  const { accessToken } = useContext(DataContext);
  const customer = useAppSelector((state) => state.customer);
  const { tableData, setTableData } = props;
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [selectedToolOptions, setSelectedToolOptions] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteName, setDeleteName] = useState("");
  const [deleteUUID, setDeleteUUID] = useState("");

  const handleView = (e, tools) => {
    setIsViewModalOpen(true);
    setSelectedToolOptions(tools);
  };

  const handleDelete = async (e) => {
    const res = await toast.promise(
      deleteRequest(
        `/react/api/${customer.uuid}/alertvalidation/securitystack/delete/${deleteUUID}`,
        accessToken
      ),
      {
        pending: "Deleting Log Chain",
        success: `Log Chain Deleted`,
        error: "Something went wrong!",
      }
    );
    if (res.status === 200) {
      // Create a new copy of the tableData state
      const updatedTableData = [...tableData];
      // Find the index of the item to remove
      const indexToRemove = updatedTableData.findIndex(
        (data) => data.uuid === deleteUUID
      );
      if (indexToRemove !== -1) {
        // Remove the item from the copy
        updatedTableData.splice(indexToRemove, 1);
        // Update the securityStacks property
        updatedTableData.securityStacks = updatedTableData;
        // Set the state to the updated copy
        setTableData(updatedTableData);
        setDeleteModal(false);
      }
    }
  };

  const submitNameChange = async (id, name) => {
    const data = {
      stack: id,
      name: name,
    };
    const res = await toast.promise(
      postRequest(
        `/react/api/${customer.uuid}/alertvalidation/securitystack/name`,
        accessToken,
        data
      ),
      {
        pending: "Renaming Log Chain...",
        success: "Log Chain renamed",
        error: "Something went wrong!",
      }
    );
    return res;
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 50,
      flex: 1,
      editable: true,
      valueSetter: (params) => {
        submitNameChange(params.row.id, params.value);
        return { ...params.row, name: params.value };
      },
      renderCell: (params) => (
        <Tooltip title={"Double click to edit name"}>
          <span className="table-cell-trucate">{params.row.name}</span>
        </Tooltip>
      ),
    },
    {
      field: "toolNames",
      headerName: "Tools",
      minWidth: 50,
      flex: 1,
    },
    {
      field: "visualization",
      headerName: "Log Chain View",
      minWidth: 50,
      flex: 1,
      renderCell: (params) => {
        const tools = params.row.toolNames.split(", ");
        return (
          <IconButton onClick={(e) => handleView(e, tools)}>
            <PreviewIcon />
          </IconButton>
        );
      },
    },
    {
      field: "",
      headerName: "Actions",
      midWidth: 100,
      flex: 0,
      renderCell: (params) => {
        // const tools = params.row.toolNames.split(", ");
        return (
          <ButtonGroup>
            <IconButton
              title="Delete Log"
              onClick={(e) => {
                e.stopPropagation();
                setDeleteUUID(params.row.id);
                setDeleteName(params.row.name);
                setDeleteModal(true);
              }}
            >
              <FaTrashAlt color={COLORS.error.main} />
            </IconButton>
          </ButtonGroup>
        );
      },
    },
  ];

  const rows = tableData.map((data) => ({
    id: data.uuid,
    name: data.name,
    toolNames: data.tools.map((tool) => tool.tool.name).join(", "),
    tools: data.tools,
  }));

  const handleCloseModal = () => {
    setIsViewModalOpen(false);
    setSelectedToolOptions([]);
  };

  return (
    <>
      <DataGridWithStyles
        name="log-chain"
        columns={columns}
        rows={rows}
        getRowHeight={() => "auto"}
        autoHeight
        paper
        disableSelectionOnClick={true}
      />
      <Modal
        open={isViewModalOpen}
        onClose={handleCloseModal}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "90%",
            maxHeight: "90%",
            padding: "16px",
            overflow: "auto",
            p: 3,
          }}
        >
          <SecurityFlowChart tools={selectedToolOptions} />
        </Paper>
      </Modal>
      <FormModal open={deleteModal} setOpen={setDeleteModal}>
        <Box component="form">
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: 4 }}
          >
            Are you sure you want to remove Log for {deleteName}?
          </Typography>
          <Stack direction="column" spacing={2}>
            <Button onClick={() => handleDelete()} variant="contained">
              Yes
            </Button>
          </Stack>
        </Box>
      </FormModal>
    </>
  );
};
