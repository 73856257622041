import React, { useContext, useState, useEffect } from "react";
import { Stack } from "@mui/system";
import { DataContext } from "../../../../Context/dataContext";
import { getRequest } from "../../../../Helpers/httpRequests";
import { Modal, Paper, Typography } from "@mui/material";
import { FaExclamationTriangle } from "react-icons/fa";
import { COLORS } from "../../../../Styles/colors";
import { useAppSelector } from "../../../../Redux/app/hooks";
import { Link } from "react-router-dom";

export const Checklist = () => {
  const [showComponent, setShowComponent] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [results, setResults] = useState({
    tools: false,
    config: false,
    connection: false,
    stack: false,
    exercise: false,
    email: false,
    checkin: false,
  });

  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  const modalStyle = {
    position: "absolute",
    bgcolor: "background.paper",
    top: "50%",
    left: "50%",
    width: "40%",
    maxWidth: "50%",
    maxHeight: "90%",
    transform: "translate(-25%, -50%)",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
  };

  const CheckListBox = ({ isChecked, url, children, ...props }) => {
    return (
      <Stack spacing={2} direction="row">
        <input type="checkbox" checked={isChecked} disabled />
        <Link
          to={url}
          sx={{
            textDecoration: `${isChecked ? "line-through" : ""}`,
            opacity: `${isChecked ? "50%" : "100%"}`,
          }}
          inline={true}
          {...props}
        >
          {children}
        </Link>
      </Stack>
    );
  };

  const checklistContent = (
    <Stack sx={{ p: "1rem" }}>
      <Typography variant="h5">
        Alert Validation Onboarding Checklist
      </Typography>
      <CheckListBox
        url={`/${current_customer.uuid}/config/securitytools`}
        isChecked={results.tools}
      >
        Security Tools Created
      </CheckListBox>
      <Stack spacing={1} direction={"row"}>
        <CheckListBox
          url={`/${current_customer.uuid}/alertvalidation/config`}
          isChecked={results.config && results.connection}
        >
          Security Tools Configured
        </CheckListBox>
        {!results.connection && (
          <Stack direction="row" spacing={1} sx={{ pl: "1rem" }}>
            <FaExclamationTriangle />
            <Typography color={COLORS.warning.main}>
              One or more Tools are failing to connect
            </Typography>
          </Stack>
        )}
      </Stack>
      <CheckListBox
        url={`/${current_customer.uuid}/alertvalidation/config`}
        isChecked={results.stack}
      >
        Security Stack Created
      </CheckListBox>
      <CheckListBox
        url={`/${current_customer.uuid}/alertvalidation/library`}
        isChecked={results.exercise}
      >
        Alert Exercise Scheduled
      </CheckListBox>
      <CheckListBox
        url={`/${current_customer.uuid}/alertvalidation/settings`}
        isChecked={results.email}
      >
        Notification Email Configured
      </CheckListBox>
      <CheckListBox
        url={`/${current_customer.uuid}/alertvalidation/service-manager`}
        isChecked={results.checkin}
      >
        Endpoint has checked in
      </CheckListBox>
    </Stack>
  );
  useEffect(() => {
    const getResult = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/alertvalidation/check-configured`,
        accessToken
      );
      if (res.status === 200) {
        setResults(res.data.results);
        const temp = res.data.results;
        setShowComponent(
          !(
            temp.tools &&
            temp.config &&
            temp.connection &&
            temp.stack &&
            temp.exercise &&
            temp.email &&
            temp.checkin
          )
        );
        setShowModal(true);
      }
    };

    getResult();
  }, [accessToken, current_customer]);

  if (showComponent) {
    return (
      <>
        <Paper spacing={2}>{checklistContent}</Paper>
        <Modal open={showModal} onClose={() => setShowModal(false)}>
          <Paper sx={modalStyle} spacing={2}>
            {checklistContent}
          </Paper>
        </Modal>
      </>
    );
  } else {
    return null;
  }
};
