import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import PropTypes from "prop-types";

const EndpointsMultiSelect = ({ formData, setFormData, endpoints }) => {

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const normalizeSelection = (selected) => {
    // Normalize the selected values to match the references in endpoints
    return selected.map((sel) => 
      endpoints.find((endpoint) => endpoint[1] === sel[1]) || sel
    );
  };

  const handleEndpointSelectChange = (event) => {
    const {
      target: { value },
    } = event;

    // Remove duplicates based on UUIDs (index 1)
    const uniqueValues = value.filter(
      (v, index, self) => index === self.findIndex((t) => t[1] === v[1])
    );

    if (uniqueValues[uniqueValues.length - 1] === "all") {
      setFormData((oldData) => ({
        ...oldData,
        endpoints:
          oldData.endpoints.length === endpoints.length ? [] : endpoints,
      }));
      return;
    }

    setFormData((oldData) => ({
      ...oldData,
      endpoints: normalizeSelection(uniqueValues),
    }));
  };

  return (
    <FormControl sx={{ width: "10rem" }}>
      <InputLabel id="ep-multiple-checkbox-label">Endpoints</InputLabel>
      <Select
        labelId="ep-multiple-checkbox-label"
        id="ep-multiple-checkbox"
        multiple
        value={formData.endpoints}
        onChange={handleEndpointSelectChange}
        input={<OutlinedInput label="Endpoints" />}
        renderValue={(selected) => selected.map((s) => s[0]).join(", ")}
        MenuProps={MenuProps}
      >
        <MenuItem sx={{ paddingLeft: 0 }} value="all">
          <Checkbox
            id="all-ep-checkbox"
            checked={
              endpoints.length > 0 &&
              endpoints.length === formData.endpoints.length
            }
            indeterminate={
              formData.endpoints.length > 0 &&
              formData.endpoints.length < endpoints.length
            }
          />
          <ListItemText primary="Select All" />
        </MenuItem>

        {endpoints.map((e) => (
          <MenuItem
            key={e[1]}
            value={e}
            selected={formData.endpoints.some((ep) => ep[1] === e[1])} 
          >
            <Checkbox
              checked={formData.endpoints.some((ep) => ep[1] === e[1])}
            />
            <ListItemText primary={e[0]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

EndpointsMultiSelect.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  endpoints: PropTypes.array.isRequired,
};

export default EndpointsMultiSelect;
