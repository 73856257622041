import { LoadingButton } from "@mui/lab";
import { Box, Stack, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { DataContext } from "../../../../Context/dataContext";
import { getRequest, postRequest } from "../../../../Helpers/httpRequests";
import { useAppSelector } from "../../../../Redux/app/hooks";

const ProcessInjectionTargetForm = ({
  targetUUID,
  setTargetModal,
  setReloadTargets,
}) => {
  // ----- STATES && CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );
  const [targetName, setTargetName] = useState("");
  const [targetPath, setTargetPath] = useState("");

  // Submit Btn
  const [submitBtnColor, setSubmitBtnColor] = useState("primary");
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [submitBtnText, setSubmitBtnText] = useState("Submit");

  // ----- VARIABLES -----

  // ----- FUNCTIONS -----
  const handleSubmit = async () => {
    setSubmitBtnLoading(true);
    const data = {
      name: targetName,
      filepath: targetPath,
    };

    const apiUrl = targetUUID
      ? `/react/api/${current_customer.uuid}/config/pi-targets/edit/${targetUUID}`
      : `/react/api/${current_customer.uuid}/config/pi-targets/add`;

    const res = await postRequest(apiUrl, accessToken, data);
    setSubmitBtnLoading(false);
    if (res.status === 200) {
      targetUUID
        ? toast.success("Target editted successfully")
        : toast.success("Target added successfully");
      setReloadTargets((current) => !current);
      setTargetModal(false);
    } else {
      toast.error("An Error occurred");
      setSubmitBtnColor("error");
      setSubmitBtnText("Error - Try again");
    }
  };

  useEffect(() => {
    const getFormData = async () => {
      try {
        if (!targetUUID) {
          return; // Early return when targetUUID is not defined
        }

        const apiUrl = `/react/api/${current_customer.uuid}/config/pi-targets/edit/${targetUUID}`;
        const res = await getRequest(apiUrl, accessToken);

        if (res.status === 200) {
          const { name, filepath } = res.data.target;
          setTargetName(name || "");
          setTargetPath(filepath || "");
        } else {
          setTargetName("");
          setTargetPath("");
        }
      } catch (err) {
        console.error("Error while trying to fetch and set data:", err);
      }
    };

    getFormData();
  }, [current_customer, accessToken, targetUUID]);

  return (
    <>
      <Typography fontWeight={"bold"} variant="h4">
        {targetUUID ? "Edit" : "Add"} Process Injection Target
      </Typography>

      <Box sx={{ display: "flex" }}>
        <Box
          className="left-box"
          sx={{ flex: 1, paddingRight: 1, marginTop: 1 }}
        >
          <Stack spacing={1.5}>
            {/* Name */}
            <TextField
              type="text"
              label="Name"
              helperText=""
              fullWidth
              value={targetName}
              onChange={(e) => setTargetName(e.target.value)}
            />

            {/* File Path */}
            <TextField
              type="text"
              label="File Path"
              helperText=""
              fullWidth
              value={targetPath}
              onChange={(e) => setTargetPath(e.target.value)}
            />

            {/* Loading Button */}
            <LoadingButton
              variant="contained"
              loading={submitBtnLoading}
              onClick={handleSubmit}
              color={submitBtnColor}
            >
              {submitBtnText}
            </LoadingButton>
          </Stack>
          <Box sx={{ flex: 1, paddingRight: 1, marginTop: 2 }}>
            <Typography
              sx={{ marginBottom: 1 }}
              fontWeight={"bold"}
              variant="h6"
            >
              Process Injection Targets
            </Typography>
            <Typography sx={{ marginBottom: 1 }}>
              {" "}
              You can name the Process Injection Target whatever label is
              helpful. For the FilePath, please use the absolute file path, and
              use two slashes for directory names.
            </Typography>
            <Typography fontWeight={"bold"}>
              Example: C:\\Windows\\Systems32\\cmd.exe
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProcessInjectionTargetForm;
