import React from "react";
import { Box, Modal } from "@mui/material";
import { useAppSelector } from "../../../Redux/app/hooks";

const FormModal = ({ open, setOpen, sx, children, onClose }) => {
  // ----- Context -----
  const darkmode = useAppSelector((state) => state.darkmode);

  // ----- States -----

  // ----- Variable -----
  /**
   * These are the styles for the Box inside of the modal.
   * The Modal component renders as position Absolute so we
   * have to write styles accordingly
   */
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };

  // ----- Functions -----

  // ----- On page load -----

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false); // Close the modal
        if (onClose) {
          onClose(); // Trigger the onClose function if provided
        }
      }}
      BackdropComponent={() => (
        <div
          style={{
            backgroundColor: darkmode ? "white" : "black",
            opacity: 0.4,
            width: "100%",
            height: "100%",
          }}
          onClick={() => {
            setOpen(false); // Close the modal
            if (onClose) {
              onClose(); // Trigger the onClose function if provided
            }
          }}
        />
      )}
    >
      <Box sx={{ ...style, ...sx }} className="child-container">
        {children}
      </Box>
    </Modal>
  );
};

export default FormModal;
