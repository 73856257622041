import { Stack, Typography } from "@mui/material";
import FormRowWithDetails from "../../../FormHelpers/FormRowWithDetails/FormRowWithDetails";
import moment from 'moment-timezone';
import React, { useEffect } from "react";

// The time of day that the event will run
const timeToRunOptions = [
  [0, "12am (Midnight)"],
  [1, "1am"],
  [2, "2am"],
  [3, "3am"],
  [4, "4am"],
  [5, "5am"],
  [6, "6am"],
  [7, "7am"],
  [8, "8am"],
  [9, "9am"],
  [10, "10am"],
  [11, "11am"],
  [12, "12pm (Noon)"],
  [13, "1pm"],
  [14, "2pm"],
  [15, "3pm"],
  [16, "4pm"],
  [17, "5pm"],
  [18, "6pm"],
  [19, "7pm"],
  [20, "8pm"],
  [21, "9pm"],
  [22, "10pm"],
  [23, "11pm"],
];

// How frequently the Alert will run
const frequencyOptions = [
  ["daily", "Daily"],
  ["weekly", "Weekly"],
  ["monthly", "Monthly"],
];

// Get the list of timezones from moment-timezone
const timezoneOptions = moment.tz.names().map(zone => [zone, zone]);

// For some reason humans can't understand that 1 means Monday without you telling them
// isoweekday has monday as 1 and sunday as 7
const daysOfWeek = [
  [1, "Monday"],
  [2, "Tuesday"],
  [3, "Wednesday"],
  [4, "Thursday"],
  [5, "Friday"],
  [6, "Saturday"],
  [7, "Sunday"]
]

const monthdayOptions = [
  [1, "1st"],
  [2, "2nd"],
  [3, "3rd"],
  [4, "4th"],
  [5, "5th"],
  [6, "6th"],
  [7, "7th"],
  [8, "8th"],
  [9, "9th"],
  [10, "10th"],
  [11, "11th"],
  [12, "12th"],
  [13, "13th"],
  [14, "14th"],
  [15, "15th"],
  [16, "16th"],
  [17, "17th"],
  [18, "18th"],
  [19, "19th"],
  [20, "20th"],
  [21, "21st"],
  [22, "22nd"],
  [23, "23rd"],
  [24, "24th"],
  [25, "25th"],
  [26, "26th"],
  [27, "27th"],
  [28, "28th"],
  [29, "29th"],
  [30, "30th"],
  [31, "31st"],
]



const AlertDetailFrom = ({
  formData,
  setFormData,
  formOptions,
  simulation,
}) => {

  // generates a array in the form of [[uuid, name], [uuid, name]]
  const endpointOptions = () => {
    const endpoints = formOptions.endpoints;
    let options = [];
    if (endpoints.length > 0) {
      endpoints.forEach((endpoint) => {
        if (endpoint.host_os.toLowerCase().includes(simulation.platform)) {
          options.push([endpoint.uuid, endpoint.name]);
        }
      });
    }
    return options;
  };

  const secStackOpts = () => {
    const secStacks = formOptions.securityStacks;
    if (secStacks.length > 0) {
      return secStacks.map((stack) => [stack.uuid, stack.name])
    };
    return [];
  }

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      timezone: prev.timezone || moment.tz.guess() // Set default timezone to user's local timezone
    }));
  }, [setFormData]);

  return (
    <Stack spacing={3}>
      <Typography variant="h6">Scheduled Exercise Details</Typography>
      {/* Alert Name */}
      <FormRowWithDetails
        type="input"
        label="Alert Name"
        formData={formData}
        setFormData={setFormData}
        formKey="name"
        description="This is the display name for the scheduled
        exercise"
        required
      />
      {/* Endpoint */}
      <FormRowWithDetails
        type="select"
        options={endpointOptions()}
        label="Endpoint"
        formData={formData}
        setFormData={setFormData}
        formKey="endpoint"
        description="Choose the endpoint(machine) where the exercise will
        be run. **NOTE- Endpoint must have checked in before to be in this
        list"
      />
      {/* Security Stack */}
      <FormRowWithDetails
        type="select"
        options={secStackOpts()}
        label="Security Stack"
        formData={formData}
        setFormData={setFormData}
        formKey="security_stack"
        description="Select one of the available security stacks"
      />
      {/* Detection/SLA time */}
      <FormRowWithDetails
        type="input"
        inputType="number"
        label="Detection Time Threshold"
        formData={formData}
        setFormData={setFormData}
        formKey="sla_time"
        description="In minutes, the longest time between activity
        and the event being logged allowed"
      />
      {/* Time to Run */}
      <FormRowWithDetails
        type="select"
        options={timeToRunOptions}
        label="Time to Run"
        formData={formData}
        setFormData={setFormData}
        formKey="time"
        description="Select a time of day you would like your alert to be ran"
      />
      {/* Timezone Selection */}
      <FormRowWithDetails
        type="select"
        options={timezoneOptions}
        label="Timezone"
        formData={formData}
        setFormData={setFormData}
        formKey="timezone"
        description="Select the timezone in which you want to run the event"
      />
      {/* Frequency */}
      <FormRowWithDetails
        type="select"
        options={frequencyOptions}
        label="Frequency"
        formData={formData}
        setFormData={setFormData}
        formKey="frequency"
        description="How frequently the Alert will run at the above specified time"
      />

      {/* Weekday */}
      {formData.frequency === "weekly" && (
        <FormRowWithDetails
          type="select"
          options={daysOfWeek}
          label="Weekday"
          formData={formData}
          setFormData={setFormData}
          formKey="weekday"
          description="Which day of the week this weekly Alert will run"
        />
      )}

      {formData.frequency === "monthly" && (
        <FormRowWithDetails
          type="select"
          options={monthdayOptions}
          label="Monthday"
          formData={formData}
          setFormData={setFormData}
          formKey="monthday"
          description="Which day of the month this monthly Alert will run (if day chosen is higher than number of days in month, then it will run on the last day of month)"
        />
      )}

      {/* Auto Close */}
      <FormRowWithDetails
        type="bool"
        label="Auto Close"
        formData={formData}
        setFormData={setFormData}
        formKey="auto_close"
        description="Blindspot will close all open incidents and alerts from exercise activities"
      />
    </Stack>
  );
};

export default AlertDetailFrom;