import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, TextField } from "@mui/material";

const FileSelect = ({ formKey, setFormData, accept, label, buttonLabel }) => {
  // ----- States -----
  const [fileName, setFileName] = useState("");

  // ----- Functions -----
  const handleUploadInput = (e) => {
    setFormData((prev) => {
      let newObj = { ...prev };
      newObj[formKey] = e.target.files[0];
      return newObj;
    });
    setFileName(e.target.files[0].name);
  };

  return (
    <TextField
      value={fileName}
      label={label || ""}
      required
      InputProps={{
        fullWidth: true,
        readOnly: true,
        startAdornment: (
          <Button
            variant="outlined"
            size="small"
            fullWidth
            component="label"
            sx={{ mr: 1 }}
          >
            {buttonLabel || "Choose file"}
            <input
              type="file"
              accept={accept}
              hidden
              onChange={handleUploadInput}
            />
          </Button>
        ),
      }}
    />
  );
};

FileSelect.propTypes = {
  label: PropTypes.string,
  buttonLabel: PropTypes.string,
  formKey: PropTypes.string.isRequired,
  accept: PropTypes.string.isRequired,
  setFormData: PropTypes.func.isRequired,
};

export default FileSelect;
