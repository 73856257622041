import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

export const ExecutiveSummary = (props) => {
  const { executiveSummary, setExecutiveSummary } = props;
  const [isEditing, setIsEditing] = useState(false);

  // State to track edited values
  const [editedDescription, setEditedDescription] = useState("");
  const [editedPreparedFor, setEditedPreparedFor] = useState("");
  const [editedEndDate, setEditedEndDate] = useState(null);

  // Initialize edited values when executiveSummary changes
  useEffect(() => {
    setEditedDescription(executiveSummary.description || "");
    setEditedPreparedFor(executiveSummary.prepared_for || "");
    setEditedEndDate(executiveSummary.end_date || null);
  }, [executiveSummary]);

  const handleSave = async () => {
    setExecutiveSummary({
      ...executiveSummary,
      description: editedDescription,
      prepared_for: editedPreparedFor,
      end_date: editedEndDate,
    });
    setIsEditing(false);
  };

  const handleCancel = () => {
    // Revert edited values to original values
    setEditedDescription(executiveSummary.description || "");
    setEditedPreparedFor(executiveSummary.prepared_for || "");
    setEditedEndDate(executiveSummary.end_date || "");
    setIsEditing(false);
  };

  return (
    <Accordion
      sx={{
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3,
        border: "none",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="exec-sum-accordion"
        id="exec-sum-accordion"
      >
        <Typography
          variant="h5"
          sx={{ marginTop: "auto", marginBottom: "auto" }}
        >
          Executive Summary
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 3 }}>
        {isEditing ? (
          <TableContainer variant="outlined">
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="Executive Summary"
            >
              <TableHead>
                <TableRow key="head">
                  <TableCell align="left" width="60%">
                    Description
                  </TableCell>
                  <TableCell align="left" width="13%">
                    Prepared For
                  </TableCell>
                  <TableCell align="left" width="12%">
                    End Date
                  </TableCell>
                  <TableCell align="left" width="15%">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <TextField
                      fullWidth
                      multiline
                      value={editedDescription}
                      onChange={(e) => setEditedDescription(e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      label="Prepared For"
                      value={editedPreparedFor}
                      onChange={(e) => setEditedPreparedFor(e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DesktopDatePicker
                        label="End Date"
                        inputFormat="MM/DD/yyyy"
                        value={editedEndDate || null}
                        onChange={(newVal) => {
                          setEditedEndDate(moment(newVal._d).format("MM-DD-yyyy"));
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSave}
                      sx={{ marginRight: 1 }}
                    >
                      Save
                    </Button>
                    <Button variant="outlined" onClick={handleCancel}>
                      Cancel
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer variant="outlined">
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="Executive Summary"
            >
              <TableHead>
                <TableRow key="head">
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="left" width="160">
                    Prepared For
                  </TableCell>
                  <TableCell align="left" width="160">
                    End Date
                  </TableCell>
                  <TableCell align="left">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ whiteSpace: "normal", overflow: "revert-layer", width: "60%" }}>
                    <pre style={{
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                    }}>
                      {executiveSummary.description}
                    </pre>
                  </TableCell>
                  <TableCell>{executiveSummary.prepared_for}</TableCell>
                  <TableCell>{executiveSummary.end_date}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setIsEditing(true)}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
