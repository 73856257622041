import { useState } from "react";
import PropTypes from "prop-types";
import {
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import { FaTimesCircle, FaCheckCircle } from "react-icons/fa";
import { COLORS } from "../../../../../Styles/colors";

const ExpectedAlertList = (props) => {
  const { expectedAlerts, setSelectedExpectedAlert } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <Paper
      elevation={3}
      sx={{ width: "99%", height: "99%", marginTop: 3, p: 3 }}
    >
      <Box>
        <Typography variant="h4">Expected Alerts</Typography>
        <List component="nav" aria-label="expected alerts">
          {expectedAlerts?.map((expectedAlert, index) => {
            return (
              <ListItemButton
                key={index}
                selected={selectedIndex === index}
                onClick={() => {
                  setSelectedIndex(index);
                  setSelectedExpectedAlert(expectedAlert);
                }}
                sx={{
                  "&.Mui-selected": { backgroundColor: COLORS.primary.light },
                }}
              >
                <ListItemIcon>
                  {expectedAlert.alert_finding.passed ? (
                    <FaCheckCircle size={25} color={COLORS.success.main} />
                  ) : (
                    <FaTimesCircle size={25} color={COLORS.error.main} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="h6">
                      {expectedAlert.expected_alert.alert_name}
                    </Typography>
                  }
                />
              </ListItemButton>
            );
          })}
        </List>
      </Box>
    </Paper>
  );
};

// props validation
ExpectedAlertList.propTypes = {
  expectedAlerts: PropTypes.array.isRequired,
  setSelectedExpectedAlert: PropTypes.func.isRequired,
};
export default ExpectedAlertList;
