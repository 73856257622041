import React from 'react';
import { Typography, Paper, Icon } from '@mui/material';
import {BiSolidError} from 'react-icons/bi';



const InternalError = ({ errorMessage }) => {
  return (
    <Paper sx={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    }}>
      <Icon >
        <BiSolidError />
      </Icon>
      <Typography variant="h6" sx={{textAlign: 'center'}}>
        An internal error occurred.
        {errorMessage && <div>{errorMessage}</div>}
      </Typography>
      <Typography variant="body1" sx={{textAlign: 'center'}}>
        The support team has been notified.
      </Typography>
    </Paper>
  );
};

export default InternalError;
