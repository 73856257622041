import React, { useEffect, useContext, useState } from "react";
import { DataContext } from "../../../../Context/dataContext";
import { getRequest } from "../../../../Helpers/httpRequests";
import { techniquesColumns } from "../utils";
import DataGridWithStyles from "../../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import { Box, Paper, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import CampaignContext from "../provider/CampaignContext";
import { useAppSelector } from "../../../../Redux/app/hooks";

const Techniques = () => {
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );
  const { executedUUID } = useContext(CampaignContext);
  const [techniques, setTechniques] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  let techniquesColumnsArray = techniquesColumns(navigate, current_customer);

  const populateTechs = (techs) => {
    const rows = techs.map((tech) => {
      return {
        id: tech.uuid,
        name: tech.name,
        attack_id: tech.attack_id,
        tactics: tech.tactic,
        platforms: tech.x_mitre_platforms,
      };
    });
    setTechniques(rows);
  };

  useEffect(() => {
    const fetchTechOutcomes = async () => {
      try {
        const res = await getRequest(
          `/react/api/${current_customer.uuid}/report/campaign/techniques/${executedUUID}`,
          accessToken
        );

        if (res.status === 200) {
          populateTechs(res.data.techniques);
        }
      } catch (error) {
        setError("Failed to Load Techniques");
      } finally {
        setLoading(false);
      }
    };

    fetchTechOutcomes();
  }, [executedUUID, accessToken, current_customer]);

  if (loading) {
    return (
      <Box className="right-box" sx={{ flex: 1, marginTop: 2 }}>
        <Paper sx={{ padding: 2, marginBottom: 1 }}>
          <Typography fontWeight={"bold"} variant="h6">
            Techniques Included in Simulation
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        </Paper>
      </Box>
    );
  }

  if (error) {
    return (
      <Box className="right-box" sx={{ flex: 1, marginTop: 2 }}>
        <Paper sx={{ padding: 2, marginBottom: 1 }}>
          <Typography fontWeight={"bold"} variant="h6">
            Techniques Included in Simulation
          </Typography>
          <Typography align="left" color="error">
            Error: {error}
          </Typography>
        </Paper>
      </Box>
    );
  }

  // Render DataGrid only when there's no error and loading is false
  return (
    <Box className="right-box" sx={{ flex: 1, marginTop: 2 }}>
      <Paper sx={{ padding: 2, marginBottom: 1 }}>
        <Typography fontWeight={"bold"} variant="h6">
          Techniques Included in Simulation
        </Typography>
        <DataGridWithStyles
          name="teqs-included"
          autoHeight
          rows={techniques}
          columns={techniquesColumnsArray}
        />
      </Paper>
    </Box>
  );
};

export default Techniques;
