import React, { useEffect, useContext, useState } from "react";
import { getRequest } from "../../../Helpers/httpRequests";
import { Breadcrumbs, Stack, Typography, IconButton, Box } from "@mui/material";
import { FaPlus, FaPowerOff, FaUserAlt } from "react-icons/fa";
import { DataContext } from "../../../Context/dataContext";
import FormModal from "../../../Components/Modals/FormModal";
import { toast } from "react-toastify";
import { COLORS } from "../../../Styles/colors";
import AdminEditCustomer from "../../../Components/Forms/Administration/AdminEditCustomer.jsx";
import ServiceProviderAddCustomerForm from "../../../Components/Forms/ServiceProvider/ServiceProviderAddCustomerForm/ServiceProviderAddCustomerForm";
import TextButton from "../../../Components/Buttons/TextButton";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import { useAppSelector } from "../../../Redux/app/hooks";
import ServiceProviderAddUserGroups from "./ServiceProviderAddUserGroups";

const ServiceProviderCustomers = () => {
  // ----- STATES && CONTEXT -----
  const [dataTableRows, setDataTableRows] = useState([]);
  const [dataTableColumns, setDataTableColumns] = useState([]);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [cxUUID, setCxUUID] = useState("");

  // Contexts
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector((state) => state.customer);

  // ----- FUNCTIONS -----

  // ----- VARIABLES -----

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    /**
     * Disa functeon issa responsible per taken an array of
     * customer objects and createn da rows and columns
     * per da data grid
     * @param {Object[]} customers
     */
    const populateDataGrid = (customers) => {
      const columns = [
        {
          field: "name",
          headerName: "Name",
          minWidth: 100,
          flex: 1,
        },
        {
          field: "display_name",
          headerName: "Display Name",
          minWidth: 100,
          flex: 1,
        },
        {
          field: "poc",
          headerName: "POC",
          minWidth: 100,
          flex: 1,
        },
        {
          field: "users",
          headerName: "Users",
          minWidth: 100,
          flex: 0,
        },
        {
          field: "actions",
          headerName: "Active",
          minWidth: 100,
          flex: 0,
          renderCell: (params) => {
            const changeActive = async () => {
              const uuid = params.row.id;
              if (!params.row.active) {
                const res = await toast.promise(
                  getRequest(
                    `/react/api/admin/customer-activate/${uuid}`,
                    accessToken
                  ),
                  {
                    pending: "Activating Customer",
                    success: `${params.row.name} Activated`,
                    error: "Something went wrong!",
                  }
                );
                if (res.status === 200) {
                  params.row.active = true;
                  document.getElementById(
                    `power-icon-${params.row.id}`
                  ).style.color = COLORS.success.main;
                }
              } else {
                const res = await toast.promise(
                  getRequest(
                    `/react/api/admin/customer-deactivate/${uuid}`,
                    accessToken
                  ),
                  {
                    pending: "De-activating Customer",
                    success: `${params.row.name} De-activated`,
                    error: "Something went wrong!",
                  }
                );
                if (res.status === 200) {
                  params.row.active = false;
                  document.getElementById(
                    `power-icon-${params.row.id}`
                  ).style.color = COLORS.error.main;
                }
              }
            };

            const onClick = (e) => {
              e.stopPropagation();
              changeActive();
            };

            return (
              <Box sx={{ width: "100%", textAlign: "center" }}>
                <IconButton onClick={onClick}>
                  <FaPowerOff
                    id={`power-icon-${params.row.id}`}
                    color={
                      params.row.active
                        ? COLORS.success.main
                        : COLORS.error.main
                    }
                  />
                </IconButton>
              </Box>
            );
          },
        },
      ];

      const rows = customers.map((cx) => {
        return {
          id: cx.uuid,
          name: cx.name,
          display_name: cx.display_name,
          poc: cx.poc_name,
          users: cx.users,
          active: cx.active,
        };
      });

      setDataTableRows(rows);
      setDataTableColumns(columns);
    };
    const getAlerts = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/serviceprovider/customers`,
        accessToken
      );
      populateDataGrid(res.data.customers);
    };

    getAlerts();
  }, [accessToken, current_customer, open, open2, open3]);

  return (
    <>
      <Stack spacing={3}>
        {/* Breadcrumbs */}
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="text.primary">Service Provider</Typography>
          <Typography color="text.primary">Customers</Typography>
        </Breadcrumbs>

        {/* Page Title  && Buttons */}
        <Stack
          direction="row"
          justifyContent={"space-between"}
        >
          <Typography variant="h3">Customers</Typography>
          <div style={{ display: "flex", gap: "8px" }}>
            <TextButton
              tooltip="Add Customer"
              icon={FaPlus}
              onClick={() => setOpen2(true)}
            />
            <TextButton
              tooltip="Add User Group to Customer"
              icon={FaUserAlt}
              onClick={() => setOpen3(true)}
            />
          </div>
        </Stack>

        {/* DataGrid */}
        <DataGridWithStyles
          name="service-provider-customers"
          autoHeight
          pointer
          paper
          rows={dataTableRows}
          columns={dataTableColumns}
          onRowClick={(params) => {
            setCxUUID(params.row.id);
            setOpen(true);
          }}
        />
      </Stack>
      <FormModal open={open} setOpen={setOpen}>
        {cxUUID !== "" && <AdminEditCustomer cxUUID={cxUUID} />}
      </FormModal>
      <FormModal open={open2} setOpen={setOpen2}>
        <ServiceProviderAddCustomerForm setOpen={setOpen2} />
      </FormModal>
      <FormModal open={open3} setOpen={setOpen3}>
        <ServiceProviderAddUserGroups setOpen={setOpen3} />
      </FormModal>
    </>
  );
};

export default ServiceProviderCustomers;
