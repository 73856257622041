import React from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useAppSelector } from "../../Redux/app/hooks";

const TextLink = ({ url, onClick, sx, inline, children, ...props }) => {
  if (sx === undefined) {
    sx = {};
  }
  // ----- Navigation -----
  const navigate = useNavigate();

  // ----- Context -----
  const darkmode = useAppSelector((state) => state.darkmode);

  // ----- States -----

  // ----- Variables -----
  const transitionTime = 0.2;

  let linkStyles = {
    color: darkmode ? "#90caf9" : "#0071b1",
    cursor: "pointer",
    transition: `color ${transitionTime}s`,
    "&:hover": {
      color: "#0071b1",
    },
  };

  if (inline) {
    linkStyles["display"] = "inline-block";
  }

  // ----- Functions -----
  const handleClick = () => {
    if (url !== undefined && onClick !== undefined) {
      console.error(
        "<TextLink/> requires either 'url' or 'onClick' you cannot use both",
      );
      return;
    }
    if (url !== undefined) navigate(url);
    if (onClick !== undefined) onClick();
  };

  // ----- On page load -----

  return (
    <Typography
      data-testid="text-link"
      sx={{ ...linkStyles, ...sx }}
      onClick={handleClick}
      {...props}
    >
      {children}
    </Typography>
  );
};

TextLink.propTypes = {
  url: PropTypes.string,
  onClick: PropTypes.func,
  sx: PropTypes.object,
};

export default TextLink;
