import React from "react";
import { Paper, Stack, Typography, Skeleton } from "@mui/material";
import { COLORS } from "../../../../../Styles/colors";

const QuickStat = ({ number, text, desc, flexGrow }) => {
  const containerStyles = {
    height: "100%",
    alignItems: "center",
    justifyContent: "space-evenly",
  };
  const numberStyles = {
    alignItems: "center",
    backgroundColor: COLORS.info.main,
    color: "white",
    height: "7rem",
    width: "7rem",
    borderRadius: "50%",
    paddingTop: "0.75rem",
    textAlign: "center",
  };
  return (
    <Paper sx={{ minHeight: 200, p: 3, flexGrow: flexGrow || 1 }}>
      <Stack spacing={1} sx={containerStyles}>
        <Stack sx={numberStyles}>
          {/* number */}
          {number !== null ? (
            <Typography variant="h4">{number}</Typography>
          ) : (
            <Skeleton variant="rounded" width={40} height={40} />
          )}
          {/* text */}
          <Typography sx={{ lineHeight: 1 }}>{text}</Typography>
        </Stack>

        {/* desc */}

        <Typography sx={{ paddingTop: 2 }} variant="caption">
          {desc}
        </Typography>
      </Stack>
    </Paper>
  );
};

export default QuickStat;
