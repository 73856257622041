import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Modal,
  Stack,
  Typography,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import {
  FaCheckCircle,
  FaFileAlt,
  FaPlus,
  FaTimesCircle,
  FaTrashAlt,
  FaEllipsisV,
  FaFileExcel,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import TextButton from "../../../../Components/Buttons/TextButton";
import DataGridWithStyles from "../../../../Components/DataGrid/DataGridWithStyles";
import ProjectForm from "../../../../Components/Forms/Simulations/ProjectForm/ProjectForm";
import FormModal from "../../../../Components/Modals/FormModal";
import { DataContext } from "../../../../Context/dataContext";
import {
  fileRequest,
  getRequest,
  postRequest,
} from "../../../../Helpers/httpRequests";
import { useHttpRequest } from "../../../../Hooks";
import { useAppSelector } from "../../../../Redux/app/hooks";
import { COLORS } from "../../../../Styles/colors";
import ScoringExcelModal from "../ProjectDetails/ScoringExcelModal";

const Project_list = () => {
  const role = useAppSelector((state) => state.user.role);

  // ----- STATES && CONTEXT -----
  const [dataTableRows, setDataTableRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [reloadProjs, setReloadProjs] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [deleteUUID, setDeleteUUID] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [projectUUID, setProjectUUID] = useState(false);

  // Contexts

  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector((state) => state.customer);

  const { response: testplans } = useHttpRequest({
    method: "GET",
    path: "/api/v2/project-test-plans",
  });

  // ----- VARIABLES -----
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    maxHeight: "80%",
    width: "30%",
    overflowY: "auto",
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 200,
      flex: 2,
      renderCell: (params) => {
        return (
          <Link
            to={`/${current_customer.uuid}/simulations/campaigns/projects/${params.row.id}`}
          >
            {params.row.name}
          </Link>
        );
      },
    },
    {
      field: "numCampaigns",
      headerName: "# of Campaigns",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "endDate",
      headerName: "End Date",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "fullyScored",
      headerName: "Fully Scored",
      maxWidth: 200,
      flex: 1,
      renderCell: (params) => {
        if (params.value) {
          return <FaCheckCircle color={COLORS.success.main} size="32" />;
        } else {
          return <FaTimesCircle color={COLORS.error.main} size="32" />;
        }
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      maxWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return <ActionsCell params={params} />;
      },
    },
  ];

  // ----- FUNCTIONS -----

  const ActionsCell = ({ params }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleMenuClick = (event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    return (
      <Stack spacing={1} direction="row">
        <Tooltip title="Options">
          <IconButton onClick={handleMenuClick}>
            <FaEllipsisV />
          </IconButton>
        </Tooltip>
        <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              setDownloading(true);
              genScoringExcel(params.row.id, params.row.name);
              handleMenuClose();
            }}
            disabled={
              role === "View_Only" ||
              downloading ||
              params.row.numCampaigns === 0
            }
          >
            <ListItemIcon>
              <FaFileAlt
                style={{
                  marginRight: "10px",
                  opacity: role === "View_Only" ? 0.5 : 1,
                }}
              />
            </ListItemIcon>
            Download Scoring Excel
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              setProjectUUID(params.row.id);
              setOpenExcelModal(true);
              handleMenuClose();
            }}
            disabled={
              role === "View_Only" ||
              downloading ||
              params.row.numCampaigns === 0
            }
          >
            <ListItemIcon>
              <FaFileExcel
                style={{
                  marginRight: "10px",
                  opacity: role === "View_Only" ? 0.5 : 1,
                }}
              />
            </ListItemIcon>
            Upload Scoring Excel
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              setDeleteName(params.row.name);
              setDeleteModal(true);
              setDeleteUUID(params.id);
              handleMenuClose();
            }}
            disabled={role === "View_Only"}
          >
            <ListItemIcon>
              <FaTrashAlt
                color={COLORS.error.main}
                style={{
                  marginRight: "10px",
                  opacity: role === "View_Only" ? 0.5 : 1,
                }}
              />
            </ListItemIcon>
            Delete Project
          </MenuItem>
        </Menu>
      </Stack>
    );
  };

  const genScoringExcel = async (uuid, name) => {
    toast.loading("Downloading Excel File");
    // Remove spaces from the name and replace with underscores
    const filename = `${name.replace(/\s+/g, "_")}.xlsx`;
    try {
      const res = await fileRequest(
        `/react/api/${current_customer.uuid}/projects/scoring_excel/${uuid}`,
        accessToken,
        filename
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Scoring Excel Downloaded Successfully");
      } else {
        toast.dismiss();
        toast.error("Error downloading scoring document");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.dismiss();
      toast.error("An error occurred while downloading the document");
    }
    setDownloading(false);
  };

  const deleteProject = async () => {
    const res = await toast.promise(
      getRequest(
        `/react/api/${current_customer.uuid}/projects/delete/${deleteUUID}`,
        accessToken
      ),
      {
        pending: "Deleting Project",
        success: `Project Deleted`,
        error: "Something went wrong!",
      }
    );
    if (res.status === 200) {
      setDeleteModal(false);
      setReloadProjs((current) => !current);
    }
  };

  const handleSubmit = async (data) => {
    const result = await postRequest(
      `/react/api/${current_customer.uuid}/projects/add`,
      accessToken,
      data,
      true
    );
    if (result.status === 200) {
      setReloadProjs((current) => !current);
      setOpenModal(false);
    }
  };

  const isFullyScored = (campaigns) => {
    let result = true;
    if (campaigns.length === 0) {
      result = false;
    }
    campaigns.forEach((campaign) => {
      if (campaign.not_scored > 0 || campaign.status !== "completed") {
        result = false;
      }
    });
    return result;
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getProjects = async () => {
      const populateDataGrid = (projects) => {
        const rows = projects.map((project) => {
          return {
            id: project.uuid,
            name: project.name,
            numCampaigns: project.campaignv2s.length,
            startDate: project.date_start
              ? moment(project.date_start).format("MMMM Do, YYYY")
              : "",
            endDate: project.date_end
              ? moment(project.date_end).format("MMMM Do, YYYY")
              : "",
            fullyScored: isFullyScored(project.campaignv2s),
            actions: <FaTrashAlt />,
          };
        });
        setDataTableRows(rows);
      };
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/projects`,
        accessToken
      );
      if (res.status === 200) {
        populateDataGrid(res.data.projects);
      }
    };

    if (current_customer) {
      getProjects();
    }
  }, [current_customer, accessToken, reloadProjs]);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Simulations</Typography>
        <Typography color="text.primary">Projects</Typography>
      </Breadcrumbs>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          marginBottom: "1rem",
          alignItems: "center",
        }}
      >
        <Typography variant="h3">Projects</Typography>
        <TextButton
          role={role}
          tooltip="Add Project"
          icon={FaPlus}
          onClick={() => {
            setOpenModal(true);
          }}
        />
      </Box>

      {/* Project List Table */}

      <DataGridWithStyles
        name="project-list"
        paper
        autoHeight
        pointer
        rows={dataTableRows}
        columns={columns}
      />
      {/* New Project modal */}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <ProjectForm
            handleSubmit={handleSubmit}
            testplans={testplans}
            customer={current_customer}
          />
        </Box>
      </Modal>

      {/* Scoring Excel Modal */}
      <Modal
        open={openExcelModal}
        onClose={() => setOpenExcelModal(false)}
        sx={{
          width: "30%",
          height: "30%",
          position: "absolute",
          top: "10%",
          left: "40%",
        }}
      >
        <ScoringExcelModal
          uuid={projectUUID}
          setOpenExcelModal={setOpenExcelModal}
        />
      </Modal>

      {/* Delete Modal */}
      <FormModal open={deleteModal} setOpen={setDeleteModal}>
        <Stack spacing={2}>
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: 4 }}
          >
            Are you sure you want to remove Project {deleteName} ?
          </Typography>
          <Button onClick={() => deleteProject()} variant="contained">
            Yes
          </Button>
        </Stack>
      </FormModal>
    </>
  );
};

export default Project_list;
