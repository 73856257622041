import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import sentineloneImage from "../../../../../Images/security_tools_icons/sentinel_one.png";
import { useAppSelector } from "../../../../../Redux/app/hooks";

const formatURL = (url) => {
  if (!url) return "";
  return url.startsWith("https://") || url.startsWith("http://") ? url : `https://${url}`;
};

const SentinelOneSecurityCard = (props) => {
  const { tool_data } = props;
  const darkmode = useAppSelector((state) => state.darkmode);
  const cardSizeLeft = 3;
  const cardSizeRight = 9;
  let time_diff = "";
  let time_delta_label = "";
  let time_delta_color = "";

  if (tool_data?.time_delta) {
    time_diff = moment
      .duration(tool_data?.time_delta, "seconds")
      .format("h [hr], m [min], ss [secs]");
    const time_diff_mins = moment
      .duration(tool_data?.time_delta, "seconds")
      .minutes();
    if (time_diff_mins <= 14) {
      time_delta_color = "success";
    } else if (15 < time_diff_mins && time_diff_mins < 29) {
      time_delta_color = "warning";
    } else {
      time_delta_color = "error";
    }
    time_delta_label = time_diff;
  } else {
    time_diff = "";
    time_delta_color = "error";
  }
  
  return (
    <Stack spacing={2} sx={{ alignItems: "center" }}>
      <Card
        sx={{
          display: "flex",
          minWidth: "600px",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <CardHeader
          sx={{
            background: darkmode
              ? "linear-gradient(to right, rgb(75, 85, 99), rgb(17, 24, 39))"
              : "linear-gradient(to right, #e0eafc, #cfdef3)",
          }}
          avatar={
            <Avatar
              src={sentineloneImage}
              sx={{ backgroundColor: "transparent" }}
              variant="rounded"
            />
          }
          title={<Typography variant="h5">SentinelOne</Typography>}
        />
        <CardContent>
          <Grid container>
            <Grid item sm={cardSizeLeft} md={cardSizeLeft} lg={cardSizeLeft}>
              <b>Alert ID:</b>
            </Grid>
            <Grid item sm={cardSizeRight} md={cardSizeRight} lg={cardSizeRight} sx={{marginBottom: 1}}>
              {tool_data?.alert_id ? (
                <a
                  className="text-links"
                  href={formatURL(tool_data?.url)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {tool_data?.alert_id}
                </a>
              ) : (
                <Chip
                  label={"N/A"}
                  color={time_delta_color}
                  variant="outlined"
                />
              )}
            </Grid>
            <Grid item sm={cardSizeLeft} md={cardSizeLeft} lg={cardSizeLeft}>
              <b>Time Delta:</b>
            </Grid>
            <Grid item sm={cardSizeRight} md={cardSizeRight} lg={cardSizeRight}>
              <Chip
                label={time_delta_label || "N/A"}
                color={time_delta_color}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions></CardActions>
      </Card>
      <ArrowDownwardIcon />
    </Stack>
  );
};

export default SentinelOneSecurityCard;
