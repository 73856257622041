import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import "react-select-search/style.css";
import FormModal from "../../../../Components/Modals/FormModal";
import { handleChange, handleExecMethodChange } from "../functions";


const TestSettingModal = ({settingModal, setSettingsModal, data, setData, setChangesMade, linuxExecMethod, execMethod, transformMethods, platforms, architectures, piMethods, piTargets}) => {
  return (
    <FormModal open={settingModal} setOpen={setSettingsModal}>
    <Stack spacing={2}>
      <Typography variant="h5">Builder Settings</Typography>
      <Divider/>
      <FormControl sx={{ width: "50%", marginBottom: 1 }}>
        <InputLabel>Platform</InputLabel>
        <Select
          value={data.platform || ""}
          label="Platform"
          onChange={(e) => {
            setData((prev) => ({
              ...prev,
              platform: e.target.value,
            }));
            setChangesMade(true);
          }}
        >
          {platforms.map((p) => (
            <MenuItem key={`plat-${p}`} value={p}>
              {p}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Architecture */}
      <FormControl>
        <InputLabel required>Architecture</InputLabel>
        <Select
          value={data.architecture || ""}
          onChange={(e) => {
            setData({ ...data, architecture: e.target.value });
          }}
          label="Architecture"
        >
          {architectures.map((arch) => {
            return (
              <MenuItem key={arch.value} value={arch.value}>
                {arch.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {/* Execution Method */}
      <FormControl>
        <InputLabel required>Execution Method</InputLabel>
        <Select
          value={data.execMethod || ""}
          onChange={(e) =>
            handleChange(e, handleExecMethodChange, setData, setChangesMade)
          }
          label="Execution Method"
        >
          {data.platform === "linux"
            ? linuxExecMethod.map((exec) => {
                return (
                  <MenuItem key={exec.value} value={exec.value}>
                    {exec.name}
                  </MenuItem>
                );
              })
            : execMethod.map((exec) => {
                return (
                  <MenuItem key={exec.value} value={exec.value}>
                    {exec.name}
                  </MenuItem>
                );
              })}
        </Select>
      </FormControl>
      {/* PI method */}
      {data.execMethod && data.execMethod.includes("sh") && (
        <>
          <FormControl>
            <InputLabel required>Process Injection Method</InputLabel>
            <Select
              value={data.piMethod ? data.piMethod : ""}
              onChange={(e) =>
                setData({ ...data, piMethod: e.target.value })
              }
              label="Process Injection Method"
            >
              {piMethods &&
                piMethods.map((exec) => {
                  return (
                    <MenuItem value={exec} key={exec}>
                      {exec}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel required>Process Injection Target</InputLabel>
            <Select
              value={data.piTarget || ""}
              onChange={(e) =>
                setData({ ...data, piTarget: e.target.value })
              }
              label="Process Injection Target"
            >
              {piTargets
                ? piTargets.map((exec) => {
                    return (
                      <MenuItem key={exec.uuid} value={exec.uuid}>
                        {exec.name}
                      </MenuItem>
                    );
                  })
                : null}
            </Select>
          </FormControl>
        </>
      )}
      {data.execMethod && data.execMethod.includes("local") && (
        <FormControl>
          <InputLabel required>Transform</InputLabel>
          <Select
            value={data.transform ? data.transform : ""}
            onChange={(e) =>
              setData({ ...data, transform: e.target.value })
            }
            label="Transform"
          >
            {transformMethods &&
              transformMethods.map((exec, index) => {
                return (
                  <MenuItem value={index + 1} key={index}>
                    {exec}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      )}
      <TextField
        type={"number"}
        value={data.sleepTime}
        label="Sleep Time"
        onChange={(e) => {
          setData({
            ...data,
            sleepTime:
              e.target.value <= 0 ? 0 : parseInt(e.target.value.toString()),
          });
        }}
      />

      <FormControl>
        <InputLabel required>Max Runtime</InputLabel>
        <Select
          value={data.maxRuntime ? data.maxRuntime : ""}
          onChange={(e) => setData({ ...data, maxRuntime: e.target.value })}
          label="Max Runtime"
        >
          <MenuItem value={0}>Unlimited</MenuItem>
          {[...Array(15)].map((x, i) =>
            i !== 0 ? (
              <MenuItem key={i * 15} value={i * 15}>
                {i * 15} minutes
              </MenuItem>
            ) : (
              ""
            )
          )}
        </Select>
      </FormControl>

      <FormGroup>
        {data.execMethod &&
          !data.execMethod.includes("sh") &&
          !data.execMethod.includes("dll") && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.inMemory}
                  onChange={(e) =>
                    setData({ ...data, inMemory: e.target.checked })
                  }
                />
              }
              label="inMemory"
            />
          )}
        <FormControlLabel
          control={
            <Checkbox
              checked={data.obfuscate}
              onChange={(e) =>
                setData({ ...data, obfuscate: e.target.checked })
              }
            />
          }
          label="Obfuscate Payload - Checking will add time to Campaign"
        />
      </FormGroup>
    </Stack>
  </FormModal>
  )
}

export default TestSettingModal
