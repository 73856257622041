import React, { useContext, useState } from "react";
import { Typography, Paper, Button, Stack } from "@mui/material";
import { postRequest } from "../../../../Helpers/httpRequests";
import { DataContext } from "../../../../Context/dataContext";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../Redux/app/hooks";

const ReRunCampaignModal = (props) => {
  const { campaign_id, project_uuid, setOpenModal, reloadProj } = props;
  const { accessToken } = useContext(DataContext);
  const customer = useAppSelector((state) => state.customer);
  const [statusMsg, setStatusMsg] = useState("");

  const modalStyle = {
    position: "absolute",
    bgcolor: "background.paper",
    top: "50%",
    left: "50%",
    width: "40%",
    maxWidth: 1000,
    maxHeight: "90%",
    transform: "translate(-25%, -50%)",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
  };

  const rerunCampaign = async (e) => {
    e.stopPropagation();
    setStatusMsg("");
    toast.info("Attempting to Re-Run Campaign");
    const res = await postRequest(
      `/react/api/${customer.uuid}/projects/reruncampaign/${project_uuid}/${campaign_id}`,
      accessToken
    );
    if (res.status === 200) {
      toast.success("Campaign started successfully");
      setOpenModal(false);
      reloadProj();
    } else if (res.status === 400) {
      toast.error(
        "Campaign failed to Run, Please check if Endpoint is Licensed"
      );
    } else {
      toast.error("Campaign failed to Run");
      setStatusMsg(res.data.message);
      setOpenModal(false);
    }
  };

  return (
    <Paper sx={modalStyle}>
      <Stack sx={{ px: "1rem" }}>
        <Typography>
          Rerunning the campaign here will replace the campaign with a new
          instance of the campaign
        </Typography>
        <Typography>
          The original campaign will still exist and will be viewable on the
          campaign list page
        </Typography>
        <Stack
          direction="row"
          spacing={3}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Button variant="contained" onClick={rerunCampaign}>
            Run
          </Button>
          <Button variant="contained" onClick={() => setOpenModal(false)}>
            Cancel
          </Button>
        </Stack>
        <Typography
          sx={{ display: statusMsg.length > 0 ? "block" : "none" }}
          color="error"
          variant="p"
        >
          Bad Request: {statusMsg}
        </Typography>
      </Stack>
    </Paper>
  );
};

export default ReRunCampaignModal;
