import { useEffect, useContext, useState } from "react";
import { DataContext } from "../../../../Context/dataContext";
import { getRequest, postRequest } from "../../../../Helpers/httpRequests";
import TextButton from "../../../../Components/Buttons/TextButton";
import {
  Breadcrumbs,
  Typography,
  Box,
  IconButton,
  Stack,
  Modal,
  Button,
  Tooltip,
} from "@mui/material";
import { COLORS } from "../../../../Styles/colors";

import {
  FaCheck,
  FaTimes,
  FaPencilAlt,
  FaBan,
  FaWindows,
  FaLinux,
  FaApple,
  FaPlus,
  FaDownload,
  FaHandHolding,
  FaTrashAlt,
  FaCircle,
  FaRegWindowClose,
} from "react-icons/fa";
import AgentProfileAddForm from "../../../../Components/Forms/Config/AgentProfileAddForm/AgentProfileAddForm";
import DataGridWithStyles from "../../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import FormModal from "../../../../Components/Modals/FormModal";
import { toast } from "react-toastify";
import moment from "moment";
import { useAppSelector } from "../../../../Redux/app/hooks";
import { Link } from "react-router-dom";
import TerminateAgentsModal from "./TerminateAgentsModal";
import { time } from "highcharts";

const Endpoints_List = () => {
  // ----- STATES && CONTEXT -----
  const role = useAppSelector((state) => state.user.role);
  const [dataTableRows, setDataTableRows] = useState([]);
  const [licenses, setLicensesAvailable] = useState([]);
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector((state) => state.customer);
  const [reloadEndpoints, setReloadEndpoints] = useState(false);
  const [modal, setModal] = useState({
    open: false,
    content: null,
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteUUID, setDeleteUUID] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const [termModalOpen, setTermModalOpen] = useState(false);

  //Variables

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    maxHeight: "90%",
    minWidth: "65%",
    overflowY: "auto",
  };

  const defaultModalState = {
    open: false,
    content: null,
  };

  function isWithinLast60Minutes(dateString) {
    if (!dateString) return false;
    // Parse the provided date string into a Date object
    const inputDate = new Date(dateString);

    // Get the current date and time
    const currentDate = new Date();

    // Calculate the difference in milliseconds between the current date and input date
    const timeDifference = currentDate - inputDate;

    // Convert 60 minutes to milliseconds (60 minutes * 60 seconds * 1000 milliseconds)
    const sixtyMinutesInMilliseconds = 60 * 60 * 1000;

    // Check if the time difference is less than or equal to 60 minutes
    return timeDifference <= sixtyMinutesInMilliseconds;
  }

  const uninstallService = async (endpoint_uuid) => {
    const res = await getRequest(
      `/react/api/${current_customer.uuid}/endpoints/uninstall_service/${endpoint_uuid}`,
      accessToken
    );
    if (res.status === 200) {
      toast.success("Service scheduled for uninstallation");
      return;
    }
    toast.error("Something went wrong!");
    return;
  };

  const columns = [
    {
      field: "id",
      headerName: "Host Label",
      minWidth: 300,
      flex: 1,
      renderCell: (params) => {
        const link = "/simulations/endpoints/detail/" + params.row.id;
        const color = params.row.active
          ? COLORS.success.light
          : COLORS.secondary.dark;
        return (
          <>
            <Stack direction={"row"} spacing={"1rem"}>
              <FaCircle color={color} />
              <Link
                className="text-links"
                to={`/${current_customer.uuid}${link}`}
              >
                {params.row.name}
              </Link>
            </Stack>
          </>
        );
      },
    },
    { field: "name", headerName: "Host Name", minWidth: 100, flex: 1 },
    { field: "domain", headerName: "Domain", minWidth: 100, flex: 1 },
    {
      field: "host_os",
      headerName: "Host Os",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        const hostOs = params.row?.host_os ?? "None"; // Provide a default value of an empty string if host_os is undefined
        if (hostOs.toLowerCase().includes("windows")) {
          return (
            <>
              {hostOs}
              <IconButton>
                <FaWindows />
              </IconButton>
            </>
          );
        } else if (hostOs.toLowerCase().includes("linux")) {
          return (
            <>
              {hostOs}
              <IconButton>
                <FaLinux />
              </IconButton>
            </>
          );
        } else if (hostOs.toLowerCase().includes("macos")) {
          return (
            <>
              {hostOs}
              <IconButton>
                <FaApple />
              </IconButton>
            </>
          );
        }
      },
    },
    {
      field: "last_heartbeat",
      headerName: "Last HeartBeat",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "license",
      headerName: "License",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return params.row.license === true ? (
          <IconButton>
            <FaCheck color={COLORS.success.main} />
          </IconButton>
        ) : (
          <IconButton>
            <FaTimes color={COLORS.error.main} />
          </IconButton>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        const link = `/${current_customer.uuid}/simulations/endpoints/edit/${params.row.id}`;
        if (params.row.license === true) {
          return (
            <>
              <Stack
                direction="row"
                sx={{ justifyContent: "space-between", width: "100%" }}
              >
                <Link to={link}>
                  <IconButton
                    disabled={role === "View_Only"}
                    style={{ opacity: role === "View_Only" ? 0.5 : 1 }}
                    title="Edit Endpoint"
                  >
                    <FaPencilAlt />
                  </IconButton>
                </Link>
                <IconButton
                  disabled={role === "View_Only"}
                  style={{ opacity: role === "View_Only" ? 0.5 : 1 }}
                  onClick={() => unLicense(params.row.endpointUUID)}
                  title="Revoke License"
                >
                  <FaBan />
                </IconButton>

                <IconButton
                  disabled={
                    role === "View_Only" ||
                    !isWithinLast60Minutes(params.row.service_check_in)
                  }
                  style={{ opacity: role === "View_Only" ? 0.5 : 1 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    uninstallService(params.row.endpointUUID);
                  }}
                  title="Uninstall service"
                >
                  <FaRegWindowClose />
                </IconButton>

                <IconButton
                  disabled={role === "View_Only"}
                  style={{ opacity: role === "View_Only" ? 0.5 : 1 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeleteName(params.row.name);
                    setDeleteUUID(params.row.endpointUUID);
                    setDeleteModal(true);
                  }}
                  title="Delete Endpoint"
                >
                  <FaTrashAlt color={COLORS.error.main} />
                </IconButton>
              </Stack>
            </>
          );
        } else {
          return (
            <>
              <Stack
                direction="row"
                sx={{ justifyContent: "space-between", width: "100%" }}
              >
                <Link to={link}>
                  <IconButton
                    disabled={role === "View_Only"}
                    style={{ opacity: role === "View_Only" ? 0.5 : 1 }}
                    title="Edit Endpoint"
                  >
                    <FaPencilAlt />
                  </IconButton>
                </Link>
                <IconButton
                  disabled={role === "View_Only" || licenses === 0}
                  style={{ opacity: role === "View_Only" ? 0.5 : 1 }}
                  onClick={() => license(params.row.endpointUUID)}
                  title="Grant License"
                >
                  <FaHandHolding />
                </IconButton>
                <IconButton
                  disabled={role === "View_Only"}
                  style={{ opacity: role === "View_Only" ? 0.5 : 1 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeleteName(params.row.name);
                    setDeleteUUID(params.row.endpointUUID);
                    setDeleteModal(true);
                  }}
                  title="Delete Endpoint"
                >
                  <FaTrashAlt color={COLORS.error.main} />
                </IconButton>
              </Stack>
            </>
          );
        }
      },
    },
  ];
  //Functions

  const deleteEndpoint = async () => {
    const res = await toast.promise(
      postRequest(
        `/react/api/${current_customer.uuid}/endpoints/delete/${deleteUUID}`,
        accessToken
      ),
      {
        pending: "Deleting Endpoint",
        success: `Endpoint Deleted`,
        error: "Something went wrong!",
      }
    );
    if (res.status === 200) {
      setDeleteModal(false);
      setReloadEndpoints((current) => !current);
    }
  };

  const license = async (params) => {
    const res = await postRequest(
      `/react/api/${current_customer.uuid}/endpoints/licensed/${params}`,
      accessToken
    );
    if (res.status === 200) {
      setReloadEndpoints((current) => !current);
    }
  };

  const unLicense = async (params) => {
    const res = await postRequest(
      `/react/api/${current_customer.uuid}/endpoints/unlicensed/${params}`,
      accessToken
    );
    if (res.status === 200) {
      setReloadEndpoints((current) => !current);
    }
  };

  const revokeLicenses = async () => {
    const res = await postRequest(
      `/react/api/${current_customer.uuid}/endpoints/revoke_licenses`,
      accessToken
    );
    if (res.status === 200) {
      setReloadEndpoints((current) => !current);
    }
  };

  const openAgentTerminationModal = () => {
    setTermModalOpen(true);
  };

  // ----- ON PAGE LOAD -----

  useEffect(() => {
    const getEndpoints = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/endpointslist`,
        accessToken
      );
      if (res.status === 200) {
        populateDataGrid(res.data.endpoints);
        setLicensesAvailable(res.data.licenses);
      }
    };

    const populateDataGrid = (endpoints) => {
      const rows = endpoints
        .sort((a, b) => {
          if (a.active && !b.active) {
            return -1;
          } else if (!a.active && b.active) {
            return 1;
          } else {
            return 0;
          }
        })
        .map((endpoint) => {
          return {
            id: endpoint.uuid,
            endpointUUID: endpoint.uuid,
            name: endpoint.name,
            domain: endpoint.sessions?.domain || "None",
            host_os: endpoint.host_os,
            last_heartbeat: moment(endpoint.heartbeat).format(
              "MMM DD YYYY, hh:mm a"
            ),
            service_check_in: endpoint.service_check_in,
            license: endpoint.license,
            actions: [],
            active: endpoint.active,
          };
        });
      setDataTableRows(rows);
    };

    getEndpoints();
  }, [current_customer, accessToken, reloadEndpoints]);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/" className="text-links">
          Home
        </Link>
        <Link
          to={`/${current_customer.uuid}/simulations/list`}
          className="text-links"
        >
          Simulations
        </Link>
        <Typography color="text.primary">Endpoints</Typography>
      </Breadcrumbs>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Typography component={"span"} fontWeight={"bold"} variant="h4">
          Endpoints
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: "row",
          }}
        >
          <Typography component={"span"}>
            <TextButton
              role={role}
              onClick={() => {
                setModal({
                  open: true,
                  content: (
                    <AgentProfileAddForm
                      setModalOpen={() => setModal(defaultModalState)}
                      onFinish={() => setModal(defaultModalState)}
                    />
                  ),
                });
              }}
              tooltip="Create Agent"
              icon={FaPlus}
            />
          </Typography>
          <Link
            to={`/${current_customer.uuid}/simulations/endpoints/download-agents`}
          >
            <TextButton tooltip="Download Agents" icon={FaDownload} />
          </Link>
          <Typography component={"span"} onClick={() => revokeLicenses()}>
            <TextButton tooltip="Revoke All Licenses" icon={FaBan} />
          </Typography>
        </Box>
      </Box>

      <Stack direction="row" justifyContent="space-between" alignItems="end">
        <b variant="h4">{licenses} Licenses Available.</b>

        <Button
          onClick={openAgentTerminationModal}
          variant="contained"
          disabled={!dataTableRows.some((e) => e.active)}
        >
          Terminate Active Agents
        </Button>
      </Stack>

      <DataGridWithStyles
        name="licenses-available"
        autoHeight
        paper
        rows={dataTableRows}
        columns={columns}
      />
      <Modal
        open={modal.open}
        onClose={() => setModal(defaultModalState)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>{modal.content}</Box>
      </Modal>
      {/* Delete Modal */}
      <FormModal open={deleteModal} setOpen={setDeleteModal}>
        <Box component="form">
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: 4 }}
          >
            Are you sure you want to remove Endpoint {deleteName} ?
          </Typography>
          <Stack direction="column" spacing={2}>
            <Button onClick={() => deleteEndpoint()} variant="contained">
              Yes
            </Button>
          </Stack>
        </Box>
      </FormModal>
      <TerminateAgentsModal
        open={termModalOpen}
        setOpen={setTermModalOpen}
        setReloadEndpoints={setReloadEndpoints}
        //endpoints={dataTableRows.filter((row) => row.active)}
        endpoints={dataTableRows}
      />
    </>
  );
};

export default Endpoints_List;
