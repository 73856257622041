import { Breadcrumbs, Typography, Stack, Paper } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  Form,
  TextField,
  PasswordField,
  SubmitButton,
} from "../../../../FormBuilder";
import { useHttpRequest } from "../../../../Hooks";
import { useAppSelector } from "../../../../Redux/app/hooks";

const IntegrationEditForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const current_customer = useAppSelector(
    (state) => state.customer,
  );

  const { response: integration, status } = useHttpRequest({
    method: "GET",
    path: `/api/v2/integrations/${id}`,
  });

  return (
    status === 200 && (
      <>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/">Home</Link>
          <Link to={`/${current_customer.uuid}/config/integrations`}>
            Integrations
          </Link>
          <Typography>Edit Integration</Typography>
        </Breadcrumbs>

        <Paper sx={{ p: 3, maxWidth: 500 }}>
          <Form
            path={`/api/v2/${current_customer.uuid}/integrations/${id}`}
            method="PATCH"
            toast
            defaultValues={integration}
            onSuccess={() =>
              navigate(`/${current_customer.uuid}/config/integrations`)
            }
          >
            <Stack spacing={2}>
              <TextField name="name" required maxLength={200} />
              <TextField name="url" label="URL" required type="url" />
              <TextField name="username" required />
              <PasswordField name="password" required />
              <TextField name="customer_identifier" required maxLength={50} />
              <SubmitButton text="add" />
            </Stack>
          </Form>
        </Paper>
      </>
    )
  );
};

export default IntegrationEditForm;
