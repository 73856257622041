import { useContext, useEffect, useState } from "react";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { AvOverviewContext } from "../Provider/Context";
import { scoreAlerts } from "../Utils/functions";
import { startOfDay, endOfDay, subWeeks, subMonths } from "date-fns";
import { Checkbox, FormControlLabel, Paper, Typography } from "@mui/material";

type Props = {};

const DataSelector = (_props: Props) => {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    data: response,
  } = useContext(AvOverviewContext);
  const [dateRange, setDateRange] = useState<Date[] | null[]>([null, null]);
  const [selectedRange, setSelectedRange] = useState<string | null>(null);
  const { allowedMaxDays } = DateRangePicker;

  const onDateChange = (values: Date[]) => {
    setStartDate(values[0]);
    setEndDate(values[1]);
  };

  const onRangeChange = (range: string) => {
    setSelectedRange(range);
    const now = new Date();
    if (range === "1 week") {
      setStartDate(subWeeks(now, 1));
      setEndDate(now);
    } else if (range === "1 month") {
      setStartDate(subMonths(now, 1));
      setEndDate(now);
    } else if (range === "3 months") {
      setStartDate(subMonths(now, 3));
      setEndDate(now);
    }
  };

  useEffect(() => {
    const initDate = () => {
      const calendarData = scoreAlerts(response.data.exec_campaigns);

      if (calendarData.length === 0) {
        return;
      }
      const earliest = calendarData.reduce((curr, prev) => {
        return prev.day > curr.day ? curr : prev;
      });
      setStartDate(earliest.day);

      const oldest = calendarData.reduce((curr, prev) =>
        prev.day < curr.day ? curr : prev,
      );
      setEndDate(oldest.day);
      if (dateRange[0] === null) {
        setDateRange([startOfDay(earliest.day), endOfDay(oldest.day)]);
      }
    };
    initDate();
  }, [response, setStartDate, setEndDate, dateRange]);

  return (
    <Paper className="self-center" elevation={3} sx={{ p: 1 }}>
      <Typography variant="h6">Select Date Range</Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={selectedRange === "1 week"}
            onChange={() => onRangeChange("1 week")}
          />
        }
        label="1 Week"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={selectedRange === "1 month"}
            onChange={() => onRangeChange("1 month")}
          />
        }
        label="1 Month"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={selectedRange === "3 months"}
            onChange={() => onRangeChange("3 months")}
          />
        }
        label="3 Months"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={selectedRange === "custom"}
            onChange={() => onRangeChange("custom")}
          />
        }
        label="Custom"
      />
      <DateRangePicker
        size="lg"
        shouldDisableDate={allowedMaxDays(365)}
        ranges={[{ label: "All time", value: [dateRange[0], dateRange[1]] }]}
        onChange={onDateChange}
        value={[startDate, endDate]}
        disabled={selectedRange !== "custom"}
      />
    </Paper>
  );
};

export default DataSelector;

