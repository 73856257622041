import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import { FaPencilAlt } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { useAppSelector } from "../../../Redux/app/hooks";

const renderTableRow = (label, value, condition, role, current_customer) => {
  return (
    <TableRow key={label}>
      <TableCell sx={{ fontWeight: "bold", borderBottom: "none" }}>
        {label}
      </TableCell>
      <TableCell sx={{ borderBottom: "none" }}>
        {condition ? (
          <Link
            to={`/${current_customer.uuid}/simulations/endpoints/download-agents`}
          >
            <Button
              disabled={role === "View_Only"}
              style={{
                opacity: role === "View_Only" ? 0.5 : 1,
                maxHeight: 27,
              }}
              size="small"
              variant="contained"
            >
              Download New Agent
            </Button>
          </Link>
        ) : (
          value
        )}
      </TableCell>
    </TableRow>
  );
};

const EndpointDetailsTable = (props) => {
  const current_customer = useAppSelector(
    (state) => state.customer,
  );
  // ----- STATES && CONTEXT -----
  const tableRows = [
    {
      label: "Name",
      value: props.endpoint.name,
    },
    {
      label: "Platform",
      value: (props.endpoint.host_os && props.endpoint.host_os) || "None",
    },
    {
      label: "Agent Version",
      value: props.agentVersion,
      condition: props.agentVersion !== props.currentVersion,
      render: <>You currently have the latest version</>,
    },
    {
      label: "Last Command Arg",
      value: props.endpoint?.cmd_args || "None",
    },
    {
      label: "Licensed",
      value:
        (props.endpoint.license &&
          (props.endpoint.license ? "True" : "False")) ||
        "None",
    },
    {
      label: "AV Processes",
      value: props.AVs.map((av) => av.display_name).join(", ") || "None",
    },
    {
      label: "IP Addresses",
      value:
        (props.agentHost.ip_address && props.agentHost.ip_address) || "None",
    },
    {
      label: "DNS Name",
      value:
        (props.agentHost.dns_hostname && props.agentHost.dns_hostname) ||
        "None",
    },
    {
      label: "Install Date",
      value:
        (props.endpoint?.installed_date &&
          moment(props.endpoint.installed_date).format(
            "MMM. D, YYYY, hh:mm a",
          )) ||
        "null",
    },
    {
      label: "Agent User Context",
      value: props.agentHost?.username || "None",
    },
    {
      label: "Logged in User",
      value: props.sessions[0]?.username || "None",
    },
    {
      label: "Domain",
      value: props.sessions[0]?.domain || "None",
    },
    {
      label: "Admin",
      value:
        (props.sessions[0]?.localAdmin &&
          (props.sessions[0]?.localAdmin ? "True" : "False")) ||
        "None",
    },
    {
      label: "Login Date",
      value:
        (props.sessions[0]?.logonTime &&
          moment(props.sessions[0].logonTime).format("MMM D,YYYY hh:mm a")) ||
        "None",
    },
    {
      label: "Login Type",
      value: props.sessions[0]?.logonFriendlyType || "None",
    },
  ];

  if (props.endpoint.service_check_in) {
    tableRows.push({
      label: "Service Check In",
      value: moment(props.endpoint.service_check_in).format(
        "MMM D, YYYY hh:mm a",
      ),
    });
  }

  // ----- VARIABLES -----
  const role = useAppSelector((state) => state.user.role);
  const { endpointUUID } = useParams();

  // ----- FUNCTIONS -----

  return (
    <Paper sx={{ marginBottom: 2, padding: 2 }}>
      <TableContainer>
        <Table sx={{ border: "none" }}>
          <TableBody>
            {/* Custom table head */}
            <TableRow>
              <TableCell colSpan={2}>
                <Typography
                  component={"span"}
                  fontWeight={"bold"}
                  variant="h6"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    marginBottom: 2,
                  }}
                >
                  Endpoint Details
                  <Link
                    to={`/${current_customer.uuid}/simulations/endpoints/edit/${endpointUUID}`}
                  >
                    <Button
                      disabled={role === "View_Only"}
                      style={{ opacity: role === "View_Only" ? 0.5 : 1 }}
                      variant="contained"
                      size="small"
                      title="Edit Endpoint"
                    >
                      <FaPencilAlt />
                      &nbsp;Edit Endpoint
                    </Button>
                  </Link>
                </Typography>
              </TableCell>
            </TableRow>

            {/* Table data rows */}
            {tableRows.map((row) =>
              renderTableRow(
                row.label,
                row.value,
                row.condition,
                role,
                current_customer,
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default EndpointDetailsTable;
