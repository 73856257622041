import React, { useContext } from "react";
import { Box, CircularProgress, Typography, Stack } from "@mui/material";
import { FaRedo } from "react-icons/fa";
import { AVDetailsContext } from "../ContextProvider/ContextProvider";
import { TextButton } from "../../../../Components";
import { useReprocessAlerts } from "../lib/hooks";
import { DownloadAlertJson } from "./DownloadAlertJson";
import { DownloadAlertReport } from "./DownloadAlertReport";

const PageTitle = () => {
  const { alertProcessing, pageTitle, campaignUUID } = useContext(AVDetailsContext);
  const { reprocessAlerts } = useReprocessAlerts();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Typography component={"span"} fontWeight={"bold"} variant="h4">
        {pageTitle}
      </Typography>
      <Stack direction={"row"}>
        <DownloadAlertJson uuid={campaignUUID} name={pageTitle} />
        <DownloadAlertReport uuid={campaignUUID} name={pageTitle} />
        <TextButton
          tooltip="Reprocess Results"
          icon={alertProcessing ? CircularProgress : FaRedo}
          loading={alertProcessing}
          onClick={reprocessAlerts}
        />

      </Stack>
    </Box>
  );
};

export default PageTitle;
