import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  InputLabel,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { FaPowerOff } from "react-icons/fa";
import { toast } from "react-toastify";
import { DataContext } from "../../Context/dataContext";
import { getRequest, postRequest } from "../../Helpers/httpRequests";
import { COLORS } from "../../Styles/colors";
import { useAppSelector } from "../../Redux/app/hooks";

const UnifiedBuilder = () => {
  const [isUnifiedBuilderActive, setIsUnifiedBuilderActive] = useState(null);
  const [lastChecked, setLastChecked] = useState(null);
  const [baseUrl, setBaseUrl] = useState("");
  const [isBaseUrlChanged, setIsBaseUrlChanged] = useState(false);
  const { accessToken } = useContext(DataContext);
  const customer = useAppSelector((state) => state.customer);

  useEffect(() => {
    const fetchData = async () => {
      const res1 = await getRequest(
        `/react/api/${customer.uuid}/config/serversettings`,
        accessToken,
      );
      if (res1.status === 200) {
        setIsUnifiedBuilderActive(res1.data.active);
        setBaseUrl(res1.data.baseUrl);
      }
      setLastChecked(new Date().toLocaleString());
    };

    fetchData();
  }, [accessToken, customer]);

  const handlePowerButtonClick = async () => {
    const LoadingToast = toast.loading("Attempting to Turn on Unified Builder");
    const res = await postRequest(
      `/react/api/${customer.uuid}/config/on_unifiedbuilder`,
      accessToken,
    );
    if (res.status === 200) {
      toast.dismiss(LoadingToast);
      setIsUnifiedBuilderActive(res.data.active);
      toast.success("Unified Builder successfully started");
    } else {
      toast.dismiss(LoadingToast);
      toast.error("Unified Builder failed to start");
    }
  };

  const handleSubmit = async () => {
    const LoadingToast = toast.loading("Attempting to chnage Base Url");
    const res = await postRequest(
      `/react/api/${customer.uuid}/config/serversettings_change`,
      accessToken,
      { baseUrl },
    );
    if (res.status === 200) {
      toast.dismiss(LoadingToast);
      setIsBaseUrlChanged(false);
      toast.success("BaseUrl updated successfully");
    } else {
      toast.dismiss(LoadingToast);
      toast.error("Failed to update BaseUrl");
    }
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Unified Builder Status</TableCell>
              <TableCell>
                <Tooltip
                  title={
                    isUnifiedBuilderActive
                      ? "Unified Builder Active"
                      : "Activate Unified Builder"
                  }
                >
                  <IconButton
                    className="power-button"
                    onClick={
                      !isUnifiedBuilderActive ? handlePowerButtonClick : null
                    }
                  // disabled={isUnifiedBuilderActive}
                  >
                    <FaPowerOff
                      color={
                        isUnifiedBuilderActive
                          ? COLORS.success.main
                          : COLORS.error.main
                      }
                      size={60}
                    />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Last Checked</TableCell>
              <TableCell>{lastChecked}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>BaseUrl</TableCell>
              <TableCell>
                <Box sx={{ display: "flex" }}>
                  <InputLabel id="type-select-label"></InputLabel>
                  <TextField
                    sx={{ width: 300 }}
                    id="outlined-basic"
                    label="BaseUrl"
                    variant="outlined"
                    value={baseUrl}
                    type="text"
                    onChange={(e) => {
                      setBaseUrl(e.target.value);
                      setIsBaseUrlChanged(true);
                    }}
                  />
                  {isBaseUrlChanged && (
                    <Button
                      sx={{ marginLeft: 1, alignSelf: "center" }}
                      variant="contained"
                      color="primary"
                      disabled={baseUrl === ""}
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default UnifiedBuilder;
