import React from "react";
import { Box, Stack, Typography, Tooltip } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";
import { useAppSelector } from "../../../Redux/app/hooks";

const TextButton = (props) => {
  // ----- EXAMPLE -----
  // <TextButton text="Create a Alert here" icon={FaPlus} href="/" onClick={someFunction} tooltip="Tooltip Text"/>
  // if onClick is passed in, href is ignored

  const Icon = props.icon;
  // ----- Context -----
  const darkmode = useAppSelector((state) => state.darkmode);

  // ----- VARIABLES -----

  const theme = createTheme({
    palette: {
      secondary: {
        main: "#FFF",
      },
    },
  });

  const stackStyle = {
    alignItems: "center",
    padding: "16px 12px",
    borderRadius: "0.5rem",
    "&:hover": !(props.role === "View_Only" || props.disabled)
      ? {
          cursor: "pointer",
          backgroundColor: "rgba(0, 0, 0, 0.1)",
        }
      : null,
  };

  const boxStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: darkmode ? "#90CAF9" : "#0071B1",
    color: "white",
    width: "50px",
    height: "50px",
  };

  const defaultOnClick = () => {
    if (props.role !== "View_Only") {
      props.onClick();
    }
  };

  return (
    <Tooltip title={props.tooltip || ""}>
      <Stack
        sx={stackStyle}
        spacing={1}
        onClick={
          props.role === "View_only" || props.disabled
            ? undefined
            : defaultOnClick
        }
        style={{
          opacity: props.role === "View_Only" || props.disabled ? 0.5 : 1,
        }}
      >
        <Box sx={boxStyles}>
          <ThemeProvider theme={theme}>
            <Icon
              size={props.iconSize || "50%"}
              color={props.loading ? "secondary" : "primary"}
            />
          </ThemeProvider>
        </Box>
        <Typography noWrap>{props.text || ""}</Typography>
      </Stack>
    </Tooltip>
  );
};

export default TextButton;
