import { LoadingButton } from "@mui/lab";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { toast } from "react-toastify";
import { DataContext } from "../../../../Context/dataContext";
import { fileRequest } from "../../../../Helpers/httpRequests";
import { useState, useContext } from "react";
import { useAppSelector } from "../../../../Redux/app/hooks";

export const AgentUIForm = ({ onFinish }) => {
  const [data, setData] = useState({
    os: "windows",
    arch: "amd64",
  });
  const [downloading, setDownloading] = useState(false);

  const { accessToken } = useContext(DataContext);

  const current_customer = useAppSelector((state) => state.customer);

  const operatingSystems = [
    {
      value: "windows",
      label: "Windows",
    },
    {
      value: "linux",
      label: "Linux",
    },
    {
      value: "darwin",
      label: "MacOS",
    },
  ];

  const architectures = [
    { value: "amd64", label: "AMD 64" },
    { value: "arm64", label: "ARM 64" },
  ];

  const downloadFile = async (os, arch) => {
    let filename = "";
    // based on os, create the filename
    switch (os) {
      case "windows":
        filename = `agent-ui-${arch}.exe`;
        break;
      case "linux":
        filename = `agent-ui-${arch}`;
        break;
      case "darwin":
        filename = `agent-ui-${arch}.app`;
        break;
      default:
        filename = "unknown";
        break;
    }
    setDownloading(true);
    toast.loading("Downloading AgentUI");
    const res = await fileRequest(
      `/react/api/${current_customer.uuid}/config/agents/ui`,
      accessToken,
      filename,
      data,
    );
    if (res.status === 200) {
      toast.dismiss();
      toast.success("Downloaded AgentUI");
      onFinish();
    } else {
      toast.dismiss();
      toast.error("Failed to download AgentUI");
    }
    setDownloading(false);
  };

  return (
    <Stack>
      <h1>Agent UI Configuration</h1>
      <FormControl>
        <InputLabel>Operating System</InputLabel>
        <Select
          value={data.os}
          onChange={(e) => setData({ ...data, os: e.target.value })}
        >
          {operatingSystems.map((os) => (
            <MenuItem key={os.value} value={os.value}>
              {os.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel>Architecture</InputLabel>
        <Select
          value={data.arch}
          onChange={(e) => setData({ ...data, arch: e.target.value })}
        >
          {architectures.map((arch) => (
            <MenuItem key={arch.value} value={arch.value}>
              {arch.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <LoadingButton
        loading={downloading}
        onClick={() => downloadFile(data.os, data.arch)}
        variant="contained"
        disabled={downloading}
      >
        Submit
      </LoadingButton>
    </Stack>
  );
};
