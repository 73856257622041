import React, { useEffect, useState, useContext } from "react";

import {
  Breadcrumbs,
  Box,
  Typography,
  Button,
  Modal,
  IconButton,
} from "@mui/material";
import { Stack } from "@mui/system";
import { deleteRequest, getRequest } from "../../../Helpers/httpRequests";
import TextButton from "../../../Components/Buttons/TextButton";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import { DataContext } from "../../../Context/dataContext";
import RecommendationForm from "../../../Components/Forms/Reporting/RecommendationForm";
import { fileRequest } from "../../../Helpers/httpRequests";
import { COLORS } from "../../../Styles/colors";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles";
import FormModal from "../../../Components/Modals/FormModal";
import { useAppSelector } from "../../../Redux/app/hooks";

const AdminRecommendations = () => {
  // ----- CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  // ----- STATES -----
  const [recommendations, setRecommendations] = useState([]);
  const [modalInfo, setModalInfo] = useState({
    open: false,
    rec: null,
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteUUID, setDeleteUUID] = useState("");
  const [deleteName, setDeleteName] = useState("");

  /**
   * contains the datagrid props
   */
  const [dataGridData, setDataGridData] = useState({
    rows: [],
    columns: [],
  });

  // ----- VARIABLES -----

  // ----- Functions -----

  /**
   * This function handles the creation of columns and rows for MUI Datagrid. Running this function will could the MUI-Datagrid to re-mount.
   * @param {} param
   */
  const populateDataGrid = (recommendations) => {
    const columns = [
      {
        field: "name",
        headerName: "Name",
        minWidth: 100,
        flex: 10,
        renderCell: (params) => {
          return (
            <Button
              onClick={() => {
                setModalInfo({
                  open: true,
                  rec: params.row,
                });
              }}
              sx={{ width: "100%", justifyContent: "left" }}
            >
              <Typography>{params.value}</Typography>
            </Button>
          );
        },
      },
      {
        field: "screenshot",
        headerName: "Screenshot",
        minWidth: 900,
        flex: 1,
        renderCell: (params) => {
          return (
            <Button
              onClick={() => {
                fileRequest(
                  `/react/api/${current_customer.uuid}/report/recommendation/screenshot/download?rec_uuid=${params.id}`,
                  accessToken,
                  `${params?.value?.substring(32)}`
                );
                const temp = recommendations;
                const recIdx = recommendations.findIndex(
                  (rec) => rec.uuid === params.id
                );
                temp.splice(recIdx, 1);
              }}
            >
              {params?.value?.substring(32)}
            </Button>
          );
        },
      },
      {
        field: "delete",
        headerName: "",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {
          return (
            <IconButton
              onClick={() => {
                setDeleteUUID(params.row.id);
                setDeleteName(params.row.name);
                setDeleteModal(true);
              }}
            >
              <FaTrashAlt color={COLORS.error.main} />
            </IconButton>
          );
        },
      },
    ];

    const rows = recommendations.map((element) => {
      return {
        id: element.uuid,
        ...element,
        delete: element.is_author,
      };
    });

    setDataGridData((data) => ({
      ...data,
      rows: rows,
      columns: columns,
    }));
  };

  // ----- On page load -----

  useEffect(() => {
    const getData = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/admin/recommendations`,
        accessToken
      );

      if (res.status === 200) {
        setRecommendations(res.data.recommendations);
        populateDataGrid(res.data.recommendations);
      } else {
        console.log(
          "%cerror Recommendations.jsx useEffect()",
          "color: red; display: block; width: 100%;",
          "Reponse status not 200"
        );
      }
    };

    getData();

    // TODO: Move populate data grid funciton inside useEffect, re-arrage logic to refresh. This is not the way.
    //eslint-disable-next-line
  }, [accessToken, current_customer]);

  return (
    <Stack spacing={3}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Reports</Typography>
        <Typography color="text.primary">Recommendations</Typography>
      </Breadcrumbs>

      {/* Page Title */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Typography variant="h3" sx={{ marginTop: "auto" }}>
          Recommendations
        </Typography>
        <TextButton
          tooltip="New Recommendation"
          icon={FaPlus}
          onClick={() => setModalInfo({ open: true })}
        />
      </Box>

      {/* Template Grid */}
      <DataGridWithStyles
        name="recommendations"
        autoHeight
        paper
        rows={dataGridData.rows}
        columns={dataGridData.columns}
        pageSize={dataGridData.pageSize}
      />
      <Modal
        open={modalInfo ? modalInfo.open : false}
        onClose={() => {
          setModalInfo({
            open: false,
            rec: null,
          });
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <RecommendationForm
            recommendation={modalInfo.rec}
            setOpen={(rec) => {
              setModalInfo({
                open: false,
                rec: null,
              });
              const temp = recommendations;
              const rec_idx = temp.findIndex((r) => r.uuid === rec.uuid);
              if (rec_idx !== -1) {
                temp.splice(rec_idx, 1);
              }
              temp.push({ ...rec, id: rec.uuid });
              setRecommendations(temp);
              populateDataGrid(temp);
            }}
          />
        </div>
      </Modal>
      {/* Delete Modal */}
      <FormModal open={deleteModal} setOpen={setDeleteModal}>
        <Box component="form">
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: 4 }}
          >
            Are you sure you want to remove {deleteName}?
          </Typography>
          <Stack direction="column" spacing={2}>
            <Button
              onClick={() => {
                deleteRequest(
                  `/react/api/${current_customer.uuid}/report/recommendation/delete?rec_uuid=${deleteUUID}`,
                  accessToken
                );
                const temp = recommendations;
                const recIdx = recommendations.findIndex(
                  (rec) => rec.uuid === deleteUUID
                );
                temp.splice(recIdx, 1);
                setRecommendations(temp);
                setDeleteModal(false);
                populateDataGrid(temp);
              }}
              variant="contained"
            >
              Yes
            </Button>
          </Stack>
        </Box>
      </FormModal>
    </Stack>
  );
};

export default AdminRecommendations;
