import {
  MenuItem,
  Select,
  TextField,
  Alert,
  Divider,
  Typography,
} from "@mui/material";
import { handleArgInputBlur, handleEditArgInputBlur } from "./functions";

const InputTypeSelector = ({
  data,
  setData,
  arg,
  argInputValue,
  setArgInputValue,
  argEditIndex,
  setArgEditIndex,
  actionData,
  edittedAction,
  setActionData,
  setEdittedAction,
  embedHandleChange,
  embedUniFile,
  isAddAction,
  module,
  setChangesMade,
  uniFiles,
  user,
}) => {
  const handleInputChange = (e) => {
    setArgInputValue(e.target.value);
  };

  const handleInputBlur = () => {
    if (isAddAction) {
      handleArgInputBlur(
        argInputValue,
        argEditIndex,
        setActionData,
        actionData,
        setArgEditIndex,
        setArgInputValue
      );
    } else {
      handleEditArgInputBlur(
        argInputValue,
        argEditIndex,
        setEdittedAction,
        edittedAction,
        setArgEditIndex,
        setArgInputValue
      );
    }
  };

  if (arg.startsWith("(Input bool") || arg === "True" || arg === "False") {
    return (
      <Select
        value={argInputValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
      >
        <MenuItem value={"True"}>True</MenuItem>
        <MenuItem value={"False"}>False</MenuItem>
      </Select>
    );
  } else if (arg.startsWith("(Input int") || typeof arg === "number") {
    return (
      <TextField
        style={{ height: "auto", fontSize: "20px", width: "100%" }}
        type={"number"}
        value={argInputValue}
        onFocus={(e) => e.target.select()} // Selects text on focus
        onChange={handleInputChange}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleInputBlur();
          }
        }}
        autoFocus
      />
    );
  } else if (
    arg.startsWith("(Input vfs_path") ||
    arg.startsWith("(Input src_file")
  ) {
    const files = [];
    data.input_files.forEach((f) => {
      const value = f.alias ? f.alias : f.name;
      if (!files.includes(value)) {
        files.push(value);
      }
    });
    return (
      <div>
        {files.length > 0 || uniFiles.length > 0 ? (
          <Select
            value={""}
            placeholder="Please Select File"
            sx={{ width: 190 }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (isAddAction) {
                  handleArgInputBlur(
                    argInputValue,
                    argEditIndex,
                    setActionData,
                    actionData,
                    setArgEditIndex,
                    setArgInputValue
                  );
                } else {
                  handleEditArgInputBlur(
                    argInputValue,
                    argEditIndex,
                    setEdittedAction,
                    edittedAction,
                    setArgEditIndex,
                    setArgInputValue
                  );
                }
              }
            }}
            onChange={(e) => {
              const selectedValue = e.target.value;
              if (uniFiles.includes(selectedValue)) {
                embedUniFile(
                  e,
                  argEditIndex,
                  isAddAction,
                  module,
                  setActionData,
                  setEdittedAction,
                  setArgInputValue,
                  setChangesMade,
                  actionData,
                  edittedAction,
                  setData,
                  user
                );
              } else {
                embedHandleChange(
                  e,
                  argEditIndex,
                  isAddAction,
                  module,
                  setActionData,
                  setEdittedAction,
                  setArgInputValue,
                  setChangesMade,
                  actionData,
                  edittedAction
                );
              }
            }}
          >
            <Typography disabled>Uploaded Files</Typography>
            {files.map((file_name, index) => (
              <MenuItem key={index} value={file_name}>
                {file_name}
              </MenuItem>
            ))}
            {files.length === 0 && (
              <Alert severity="info">No Uploaded Files</Alert>
            )}
            <Divider />
            <Typography disabled>Universal Files</Typography>
            {uniFiles.map((uniFile, index) => (
              <MenuItem key={index} value={uniFile}>
                {uniFile.name}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Alert severity="error">Please Upload a File for Selection</Alert>
        )}
      </div>
    );
  } else if (arg !== "") {
    return (
      <TextField
        multiline
        style={{
          height: "auto",
          fontSize: "20px",
          whiteSpace: "normal",
          wordWrap: "break-word",
          width: "100%",
        }}
        type="string"
        value={argInputValue}
        onFocus={(e) => e.target.select()} // Selects text on focus
        onChange={handleInputChange}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleInputBlur();
          }
        }}
        onBlur={handleInputBlur}
        autoFocus
      />
    );
  } else {
    return null;
  }
};

export default InputTypeSelector;
