import {
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import { useState } from "react";
import {
  FaChessBoard,
  FaDownload,
  FaEllipsisV,
  FaFileAlt,
  FaFileExcel,
  FaFileUpload,
  FaTrashAlt,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { COLORS } from "../../../../Styles/colors";

const CustomCell = (props) => {
  const {
    params,
    role,
    handleDeleteCampaign,
    handleDownloadCampaign,
    genScoringExcel,
    handleUploadExcel,
    matrixLink,
    downloading,
    addDefenderScoring,
    securityTools,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadExcel = () => {
    // Add your download logic here
    genScoringExcel(params);
    handleClose();
  };

  const handleDelete = () => {
    handleDeleteCampaign();
    handleClose();
  };

  const handleDownload = () => {
    handleDownloadCampaign(params);
    handleClose();
  };

  const handleUploadExcelFunc = () => {
    handleUploadExcel();
    handleClose();
  };

  return (
    <Stack direction="row" sx={{ justifyContent: "flex-end", width: "100%" }}>
      <IconButton onClick={handleClick} title="Actions">
        <FaEllipsisV />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem>
          <Link
            to={matrixLink}
            style={{ textDecoration: "none", color: "rgba(0,0,0,0.87)" }}
          >
            <ListItemIcon>
              <FaChessBoard />
            </ListItemIcon>
            Report Attack Matrix
          </Link>
        </MenuItem>
        <Divider />
        <MenuItem
          disabled={role === "View_Only" || downloading}
          onClick={handleUploadExcelFunc}
        >
          <ListItemIcon>
            <FaFileUpload />
          </ListItemIcon>
          Upload Scoring Excel
        </MenuItem>
        <Divider />
        <MenuItem
          disabled={role === "View_Only" || downloading}
          onClick={handleDownloadExcel}
        >
          <ListItemIcon>
            <FaFileExcel />
          </ListItemIcon>
          Download Scoring Excel
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleDownload}>
          <ListItemIcon>
            <FaDownload />
          </ListItemIcon>
          Download Campaign Details
        </MenuItem>
        <Divider />
        {securityTools.some((tool) => tool.product === "Defender") && (
          <MenuItem
            onClick={addDefenderScoring}
            disabled={role === "View_Only"}
          >
            <ListItemIcon>
              <FaFileAlt />
            </ListItemIcon>
            Autoscore with logs
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={handleDelete} disabled={role === "View_Only"}>
          <ListItemIcon>
            <FaTrashAlt color={COLORS.error.main} />
          </ListItemIcon>
          Delete Campaign
        </MenuItem>
      </Menu>
    </Stack>
  );
};

export default CustomCell;
