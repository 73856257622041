import { Typography, Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";

const BSBreadcrumbs = () => {
  // show alert table. ( Build component for this )

  // build form for adding expected alerts ( also it's own component )

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link to="/">Home</Link>
      <Typography color="text.primary">Alert Validation</Typography>
      <Typography color="text.primary">Expected Alerts</Typography>
    </Breadcrumbs>
  );
};

export default BSBreadcrumbs;
