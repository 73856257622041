import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Button, TextField } from "@mui/material";
import { FormContext } from "../context/FormContext";
import { v1 as uuidv1 } from "uuid";
import { capitalize } from "../../Helpers/functions";

const FileField = (props) => {
  const { name, accept, label, buttonLabel } = props;
  // ----- States -----
  const { formData, setFormData } = useContext(FormContext);

  // Generate a unique ID for the form
  const id = uuidv1();

  // ----- Functions -----
  const handleFileChange = (e) => {
    setFormData((data) => ({
      ...data,
      [name]: e.target.files[0],
    }));
  };

  return (
    <TextField
      id={id}
      value={formData[name]?.name || ""}
      label={label || capitalize(name)}
      InputProps={{
        fullWidth: true,
        readOnly: true,
        startAdornment: (
          <Button
            variant="outlined"
            size="small"
            fullWidth
            component="label"
            sx={{ mr: 1 }}
          >
            <input
              type="file"
              accept={accept}
              hidden
              onChange={handleFileChange}
            />
            {buttonLabel}
          </Button>
        ),
      }}
    />
  );
};

FileField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  buttonLabel: PropTypes.string,
  accept: PropTypes.string,
};

FileField.defaultProps = {
  buttonLabel: "Choose file",
  accept: "",
};

export default FileField;
