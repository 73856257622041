import React, { useEffect, useContext, useState } from "react";

import { getRequest, postRequest } from "../../../Helpers/httpRequests";
import {
  Breadcrumbs,
  Modal,
  Stack,
  Typography,
  IconButton,
  Button,
  ButtonGroup,
} from "@mui/material";
import { DataContext } from "../../../Context/dataContext";
import { FaCheck, FaTimes, FaPlus } from "react-icons/fa";
import TextButton from "../../../Components/Buttons/TextButton";
import moment from "moment";
import AdminInviteUserForm from "../../../Components/Forms/Administration/AdminInviteUserForm";
import { COLORS } from "../../../Styles/colors";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../Redux/app/hooks";

const AdminUsers = () => {
  // ----- STATES && CONTEXT -----

  const [dataTableRows, setDataTableRows] = useState([]);
  const [dataTableColumns, setDataTableColumns] = useState([]);
  const [filteredAction, setFilteredAction] = useState("");

  // Modal States
  const [open, setOpen] = useState(false);

  // Contexts
  const { accessToken } = useContext(DataContext);
  const darkmode = useAppSelector((state) => state.darkmode);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  // ----- VARIABLES -----

  // ----- FUNCTIONS -----

  // Closes the modal
  const handleClose = () => {
    setOpen(false);
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    /**
     * This function handles the creation of columns and rows for MUI Datagrid. Running this function will could the MUI-Datagrid to re-mount.
     * @param {Array.<{email: String, customer: String, inviteCode: String, created: Date, valid: Boolean, result: String}>} invites
     */
    const populateDataGrid = (invites) => {
      const columns = [
        {
          field: "email",
          headerName: "Email",
          minWidth: 100,
          flex: 1,
        },
        {
          field: "customer",
          headerName: "Customer",
          minWidth: 100,
          flex: 1,
        },
        {
          field: "inviteCode",
          headerName: "Invite Code",
          minWidth: 100,
          flex: 1,
        },
        {
          field: "created",
          headerName: "Created",
          minWidth: 100,
          flex: 1,
        },
        {
          field: "valid",
          headerName: "Valid?",
          minWidth: 100,
          flex: 0,
          renderCell: (params) =>
            params.row.valid ? (
              <IconButton>
                <FaCheck color="green" />
              </IconButton>
            ) : (
              <IconButton>
                <FaTimes color={COLORS.error.main} />
              </IconButton>
            ),
        },
        {
          field: "result",
          headerName: "Email Send Result",
          minWidth: 100,
          flex: 1,
        },
        {
          field: "is_used",
          headerName: "Resend Invite",
          minWidth: 100,
          flex: 1,
          renderCell: (params) =>
            !params.row.is_used && (
              <Button onClick={() => resendInvite(params)} variant="contained">
                Resend Invite
              </Button>
            ),
        },
      ];

      const rows = invites.map((invite) => {
        return {
          id: invite.uuid,
          email: invite.email,
          customer: invite.customer.display_name,
          inviteCode: invite.inviteCode,
          created: moment(invite.created).format("MMMM Do YYYY, h:mm a"),
          valid: invite.valid,
          result: invite.email_result || "None",
          is_used: invite.is_used,
        };
      });

      setDataTableRows(rows);
      setDataTableColumns(columns);
    };

    const resendInvite = async (params) => {
      const formData = {
        email: params.row.email,
        customer: params.row.customer,
      };
      await toast.promise(
        postRequest(
          `/react/api/${params.row.customer.uuid}/config/resend_user_invite`,
          accessToken,
          formData,
          true
        ),
        {
          pending: `Resending invite to ${params.row.email}`,
          success: "Successfully re-sent invitation",
          error: "Failed to re-send invite",
        }
      );
    };
    // Retrieves all the invites for the current cx view.
    const getUserInvites = async () => {
      const res = await getRequest(
        "/react/api/admin-user-invites",
        accessToken
      );
      populateDataGrid(res.data.invites);
    };

    getUserInvites();
  }, [current_customer, accessToken, open]);

  return (
    <>
      <Stack spacing={1}>
        {/* Breadcrumbs */}
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="text.primary">Administration</Typography>
          <Typography color="text.primary">User Invites</Typography>
        </Breadcrumbs>

        {/* Page Title  && Buttons */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h3">Users Invites</Typography>
          <TextButton
            tooltip="Create New Invite"
            icon={FaPlus}
            href="/"
            onClick={() => setOpen(true)}
          />
        </Stack>
        <Stack>
          {/* Invite Grid */}
          <ButtonGroup>
            <Button
              variant={filteredAction === "" ? "contained" : "outlined"}
              color="primary"
              onClick={() => setFilteredAction("")}
            >
              All
            </Button>
            <Button
              variant={filteredAction === false ? "contained" : "outlined"}
              color="primary"
              onClick={() => setFilteredAction(false)}
            >
              Unused Invites
            </Button>
            <Button
              variant={filteredAction === true ? "contained" : "outlined"}
              color="primary"
              onClick={() => setFilteredAction(true)}
            >
              Used Invites
            </Button>
          </ButtonGroup>
          {/* Invite Grid */}
          <DataGridWithStyles
            name="admin-user-invites"
            autoHeight
            paper
            rows={dataTableRows}
            columns={dataTableColumns}
            filterModel={{
              items: [
                {
                  columnField: "is_used",
                  operatorValue: "equals",
                  value: String(filteredAction), // Convert to string since the field is boolean
                },
              ],
            }}
          />
        </Stack>
      </Stack>

      {/* Modal for form */}
      <Modal
        open={open}
        onClose={handleClose}
        BackdropComponent={() => (
          <div
            style={{
              backgroundColor: darkmode ? "white" : "black",
              opacity: 0.4,
              width: "100%",
              height: "100%",
            }}
            onClick={handleClose}
          />
        )}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="Modal-Container">
          <AdminInviteUserForm setOpen={setOpen} />
        </div>
      </Modal>
    </>
  );
};

export default AdminUsers;
