import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Stack,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

const moment = require("moment");

const AVTestCaseActivies = (props) => {

  const avCachedFindings = props.avCachedFindings;
  const [detailsExpanded, setDetailsExpanded] = useState(false);

  const handleDetailsChange = (panel) => (_event, isExpanded) => {
    setDetailsExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ flexDirection: "row-reverse" }}
      >
        <Typography variant="h5">Exercise Actions</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack>
          {avCachedFindings?.map((finding, index) => {
            return (
              <div key={finding.uuid}>
                <Accordion
                  expanded={detailsExpanded === `panelDetails${index}`}
                  onChange={handleDetailsChange(`panelDetails${index}`)}
                  sx={{ marginTop: "16px" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panelDetails${index}bh-content`}
                    id={`panelDetails${index}bh-header`}
                    sx={{ flexDirection: "row-reverse" }}
                  >
                    <Typography sx={{ width: "33%", flexGrow: 1 }}>
                      <b>Step {finding.step}: </b>
                      {finding.request}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack direction="row">
                      <Box sx={{ mr: 3 }}>
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          PID
                        </Typography>
                        <Typography variant="subtitle2">
                          {finding.process}
                        </Typography>
                      </Box>
                      <Box sx={{ mr: 3 }}>
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          Action Taken
                        </Typography>
                        <Typography variant="subtitle2">
                          {moment(finding.timestamp).format(
                            "MMMM Do YYYY, h:mm a"
                          )}
                        </Typography>
                      </Box>
                      <Box sx={{ mr: 3 }}>
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          User ID
                        </Typography>
                        <Typography variant="subtitle2">
                          {finding.user}
                        </Typography>
                      </Box>
                    </Stack>
                    <Box sx={{ display: "flex", mt: 1 }}>
                      <Box sx={{ width: "50%", paddingRight: "1rem" }}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Request
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ whiteSpace: "pre-line", color: "#e83e8c" }}
                        >
                          {finding.request}
                        </Typography>
                      </Box>
                      <Box sx={{ width: "50%", textOverflow: "ellipsis" }}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Response
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            whiteSpace: "pre-line",
                            color: "#e83e8c",
                            wordWrap: "break-word",
                          }}
                        >
                          {finding.response}
                        </Typography>
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default AVTestCaseActivies;
