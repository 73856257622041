import { COLORS } from "../../../Styles/colors";

//function used to define the modal style 
export const modalStyle = {
  position: "absolute",
  bgcolor: "background.paper",
  overflow: "scroll",
  left: "50%",
  width: "60%",
  maxHeight: "100%",
  maxWidth: 1500,
  transform: "translate(-25%, -50%)",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

//function used to define the argStyle
export const argStyle = (arg) => {
  const style = {
    width: 200,
    marginBottom: 2,
  };

  if (arg.startsWith("(")) {
    style.backgroundColor = COLORS.error.light;
  } else if (arg.startsWith("-")) {
    style.backgroundColor = COLORS.secondary.light;
    style.width = 150;
  } else if (arg === "") {
    style.backgroundColor = COLORS.error.light;
  } else {
    style.backgroundColor = COLORS.success.light;
  }

  return style;
};

// Define a function to determine the chip style
export const getChipStyle = (argument) => {
  let chipStyle = { backgroundColor: COLORS.success.light };

  if (argument.startsWith("(")) {
    chipStyle.backgroundColor = COLORS.error.light;
  } else if (argument.startsWith("-")) {
    chipStyle.backgroundColor = COLORS.secondary.light;
  } else if (argument === "") {
    chipStyle.backgroundColor = COLORS.error.light;
  }

  return chipStyle;
};


export const chipStylesArgs = {
  chip: {
    marginRight: 1,
    height: "auto",
    width: "100%",
    flex: 1,
    display: "flex",
    flexWrap: "wrap",
    minWidth: 0, // Allow text to shrink
    "& .MuiChip-label": {
      display: "block",
      whiteSpace: "normal",
    },
  },
  labelContainer: {
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    width: "100%",
    wordBreak: "break-word",
  },
};

