import { useContext } from "react";
import PropTypes from "prop-types";
import Container from "./Components/Container";
import { Box, CircularProgress } from "@mui/material";
import { Tooltip, onClick } from "./helpers/functions";
import { containerStyles, loadingContainerStyles } from "./styles";
import { COLORS } from "../../Styles/colors";
import moment from "moment";
import CalendarHeatmap from "react-calendar-heatmap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-calendar-heatmap/dist/styles.css";
import "./calendarStyles.css";
import "react-tooltip/dist/react-tooltip.css";
import { AvOverviewContext } from "../../Pages/AlertValidation/AVOverview/Provider/Context";
import { startOfDay, endOfDay } from "date-fns";

const CalendarWithStyles = ({ data, paper, onClick, loadEmpty }) => {
  // States
  const { startDate, endDate } = useContext(AvOverviewContext);

  /*
   * this function formats the obj.
   * this is neccesary because we switched calendar libraries and they take data values differently.
   */
  const newData = data.map((item) => {
    return {
      data: {
        campaigns: item.campaigns,
      },
      count: item.value,
      date: item.day,
    };
  });

  function fillMissingDates(objects) {
    // Helper function to generate all dates between two dates
    function generateDateRange(startDate, endDate) {
      const dates = [];
      let currentDate = startDate;

      while (currentDate <= endOfDay(endDate)) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return dates;
    }

    // Helper function to format date as a string for easy comparison
    function formatDate(date) {
      return date.toISOString().split("T")[0];
    }

    // Find the earliest and latest dates
    const sortedObjects = objects.sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );
    let startDate = new Date();
    let endDate = new Date();
    if (sortedObjects.length !== 0) {
      startDate = new Date(sortedObjects[0].date);
      endDate = new Date(sortedObjects[sortedObjects.length - 1].date);
    }

    // Generate the full range of dates
    const allDates = generateDateRange(startDate, endDate);

    // Create a new array that includes all objects, adding missing dates
    const result = allDates.map((date) => {
      // Format the date for comparison
      const dateString = formatDate(date);

      // Check if the date already exists in the original array
      const existingObject = objects.find(
        (obj) => formatDate(new Date(obj.date)) === dateString
      );

      if (existingObject) {
        return existingObject;
      }

      // If the date doesn't exist, create a new object with the date
      return { date: date }; // Example new object
    });

    return result;
  }

  // Example usage
  const newDateArray = fillMissingDates(newData);

  return (
    <Container sx={containerStyles} paper={paper}>
      <Box>
        {data.length > 0 || loadEmpty ? (
          <>
            <Box sx={{ px: 3, mt: 3 }}>
              <CalendarHeatmap
                startDate={startOfDay(startDate)}
                endDate={endOfDay(endDate)}
                values={newDateArray}
                showWeekdayLabels
                gutterSize={2}
                onClick={(value) => {
                  if (value?.count !== undefined) {
                    onClick(value);
                  }
                }}
                classForValue={(value) => {
                  if (value?.count !== undefined)
                    return `color-scale-${value.count}`;
                  return "color-empty";
                }}
                transformDayElement={(element, value, index) => {
                  return (
                    <g key={`calendar-day-${index}`}>
                      {element}
                      <text
                        x={element["props"].x + 1}
                        y={element["props"].y + 4}
                        style={{
                          fontSize: "0.2em",
                          fill: COLORS.secondary.dark,
                          pointerEvents: "none",
                        }}
                      >
                        {value?.date ? moment(value?.date).format("D") : null}
                      </text>
                    </g>
                  );
                }}
                tooltipDataAttrs={(value) => {
                  if (value?.data && Object.keys(value.data).length === 0)
                    return;
                  return {
                    "data-tooltip-id": "rchid",
                    "data-tooltip-content": `${value.date}`,
                  };
                }}
              />
            </Box>
            <ReactTooltip id="rchid" />
          </>
        ) : (
          <Box sx={loadingContainerStyles}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Container>
  );
};

CalendarWithStyles.propTypes = {
  height: PropTypes.number,
  pagination: PropTypes.bool,
  data: PropTypes.array,
  paper: PropTypes.bool,
  tooltip: PropTypes.func,
  onClick: PropTypes.func,
  loadEmpty: PropTypes.bool,
};

CalendarWithStyles.defaultProps = {
  height: 300,
  pagination: false,
  paper: false,
  tooltip: Tooltip,
  onClick: onClick,
  loadEmpty: false,
  data: [],
};

export default CalendarWithStyles;
