import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  FaBook,
  FaChartArea,
  FaChartLine,
  FaChessBoard,
  FaFile,
  FaSitemap,
} from "react-icons/fa";
import { LinkWithCustomerUUID } from "../../../Components/Links";
import { useAppSelector } from "../../../Redux/app/hooks";

const iconColor = "#BDCCD4";
const iconSize = "16px";

const Reports = ({ open }) => {
  const user = useAppSelector((state) => state.user);
  const customerType = user.current_customer.license_type;
  const data = {
    navHeader: "REPORTS",
    links: [
      //     {
      //       icon: <FaChartPie color={iconColor} size={iconSize} />,
      //       text: 'Campaign Results',
      //       to: '/reporting/',
      //     },
      {
        icon: <FaSitemap color={iconColor} size={iconSize} />,
        text: "Pipeline View",
        to: "/reporting/dashboards",
      },
      {
        icon: <FaChessBoard color={iconColor} size={iconSize} />,
        text: "ATT&CK® Matrix",
        to: "/reporting/matrix",
      },
      // {
      //   icon: <FaShareAlt color={iconColor} size={iconSize} />,
      //   text: "Endpoint Assessments",
      //   to: "/reporting/endpoints",
      // },
      {
        icon: <FaBook color={iconColor} size={iconSize} />,
        text: "Recommendations",
        to: "/reporting/recommendations",
      },
      {
        icon: <FaFile color={iconColor} size={iconSize} />,
        text: "Templates",
        to: "/reporting/templates",
      },
      {
        icon: <FaChartLine color={iconColor} size={iconSize} />,
        text: "Trends & Insights",
        to: "/reporting/trends",
      },
      {
        icon: <FaChartArea color={iconColor} size={iconSize} />,
        text: "Campaign Usage",
        to: "/reporting/campaign/usage",
      },
    ],
  };

  return (
    <div>
      {open ? (
        <Typography
          variant="h6"
          px={1}
          mt={3}
          sx={{ fontSize: 10, color: "#BDCCD4" }}
        >
          {data.navHeader}
        </Typography>
      ) : null}
      {data.links.map((link) => {
        if (
          link.text === "Campaign Usage" &&
          (customerType !== "consumption_smv" || customerType !== "consumption")
        ) {
          return null;
        }
        return (
          <ListItem key={link.text} disablePadding sx={{ display: "block" }}>
            <LinkWithCustomerUUID
              to={link.to}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 32,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
                disabled={
                  user.current_customer.license_type === "av_only" ||
                  user.current_customer.license_type === "consumption_smv"
                }
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {link.icon}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "12px" }}
                  primary={link.text}
                  sx={{ opacity: open ? 1 : 0, color: "white" }}
                />
              </ListItemButton>
            </LinkWithCustomerUUID>
          </ListItem>
        );
      })}
    </div>
  );
};

export default Reports;
