// Deprecated file: Ability to edit a log chain has been removed
// Keeping file for posterity

import React, { useState, useEffect, useContext } from "react";
import { Button, Stack, Typography, Paper, TextField } from "@mui/material";
import LogChainDND from "./LogChainDND";
import { FaDesktop } from "react-icons/fa";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SecurityUpdateWarningIcon from "@mui/icons-material/SecurityUpdateWarning";
import { postRequest } from "../../../../Helpers/httpRequests";
import { DataContext } from "../../../../Context/dataContext";
import AddSecTool from "./AddSecTool/AddSecTool";

export const LogChainEdit = (props) => {
  const { securityToolOptions, securityStack, setEditOpen, customer, action } = props;
  const [logChain, setLogChain] = useState({ tools: [] });
  const { accessToken } = useContext(DataContext);
  const [logChainName, setLogChainName] = useState(
    securityStack ? securityStack.name : ""
  );

  const handleNameChange = (event) => {
    setLogChainName(event.target.value);
  };

  const addItemToChain = (toolUUID) => {
    // get tool from tool options via uuid
    const tools = securityToolOptions.securityTools;
    const tool = tools.find((t) => t.uuid === toolUUID);

    //TODO if the security stack is empty. If so they do a different spread operator, also update to the correct call

    if (logChain.tools?.length > 0) {
      const newLogChain = {
        ...logChain,
        tools: [...logChain.tools, { tool: tool }],
      };
      setLogChain(newLogChain);
    } else {
      const newLogChain = {
        tools: [{ tool: tool }],
      };
      setLogChain(newLogChain);
    }
  };

  const deleteItem = (tool) => {
    const newLogChain = {
      ...logChain,
      tools: logChain.tools.filter((t) => t !== tool),
    };

    setLogChain(newLogChain);
  };

  const handleSave = async () => {
    let tool_uuids = logChain.tools.map((tool) => tool.tool.uuid);

    // if (action === "edit") {
    //   const data = {
    //     name: logChainName,
    //     stack: securityStack.id,
    //     tools: tool_uuids,
    //   };
    //   const updateResults = await postRequest(
    //     `/react/api/${customer.uuid}/alertvalidation/update-securitystacks`,
    //     accessToken,
    //     data,
    //     true
    //   );
    //
    //   if (updateResults.status === 200) {
    //     setEditOpen(false);
    //   }
    // }

    if (action === "add") {
      const data = {
        name: logChainName,
        tools: tool_uuids,
      };
      const addLogChain = await postRequest(
        `/react/api/${customer.uuid}/alertvalidation/create-securitystacks`,
        accessToken,
        data,
        true
      );
      if (addLogChain.status === 201) {
        setEditOpen(false);
      }
    }
  };

  useEffect(() => {
    if (securityStack) {
      setLogChain({
        tools: securityStack.tools,
      });
    }
  }, [securityStack]);

  return (
    <Paper sx={{ p: 3, width: "fit-content" }}>
      <Stack alignItems="center" spacing={2}>
        <h1>Log Chain</h1>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="body1">Name:</Typography>
          <TextField
            id="logChainName"
            value={logChainName}
            onChange={handleNameChange}
            variant="outlined"
            size="small"
          />
        </Stack>
        <AddSecTool
          securityToolOptions={securityToolOptions}
          addItemToChain={addItemToChain}
        />
        <Stack alignItems="center" spacing={2}>
          <Stack direction="row" alignItems="center">
            <FaDesktop className="icon" />
            <Typography fontWeight="bold">Host Machine</Typography>
          </Stack>
          <ArrowDownwardIcon />
          <LogChainDND
            logChain={logChain.tools}
            deleteItem={deleteItem}
            setLogChain={setLogChain}
          />
          <Stack direction="row">
            <SecurityUpdateWarningIcon />
            <Typography fontWeight="bold"> SOC Alert</Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Button variant="contained" onClick={() => handleSave()}>
              Save
            </Button>
            <Button variant="contained" onClick={() => setEditOpen(false)}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default LogChainEdit;
