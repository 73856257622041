import {
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Typography
} from "@mui/material";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../Redux/app/hooks";

const HelpPages = () => {
  const navigate = useNavigate();
  const customer = useAppSelector(
    (state) => state.customer
  );
  
  const sectionsMapping = {
    Agents: {
      path: "agents",
      state: {
        title: "Agents",
        videoUrl: "https://player.vimeo.com/video/895640385?h=ff6d5e1456&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        textData: ""
      }
    },
    Endpoints: {
      path: "endpoints",
      state: {
        title: "Endpoints",
        videoUrl: "https://player.vimeo.com/video/895640377?h=0779fd7e0e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        textData: ""
      }
    },
    "Simulation Library": {
      path: "sim_library",
      state: {
        title: "Simulation Library",
        videoUrl: "https://player.vimeo.com/video/895640356?h=bfd120d026&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        textData: ""
      }
    },
    Campaigns: {
      path: "campaigns",
      state: {
        title: "Campaigns",
        videoUrl: "https://player.vimeo.com/video/895640317?h=c6d1f4784a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        textData: ""
      }
    },
    Scoring: {
      path: "scoring",
      state: {
        title: "Scoring",
        videoUrl: "https://player.vimeo.com/video/895640298?h=34e51e679d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        textData: ""
      }
    },
    Projects: {
      path: "projects",
      state: {
        title: "Projects",
        videoUrl: "https://player.vimeo.com/video/895640286?h=34be9e3966&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        textData: ""
      }
    },
    "Simulation Builder": {
      path: "sim_builder",
      state: {
        title: "Simulation Builder",
        videoUrl: "https://player.vimeo.com/video/895640185?h=fb88f86233&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        textData: ""
      }
    },
    Reporting: {
      path: "reporting",
      state: {
        title: "Reporting",
        videoUrl: "https://player.vimeo.com/video/895640227?h=cafe1b4e8a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        textData: ""
      }
    },
  };

  return (
    <Container maxWidth="100%">
      <Breadcrumbs aria-label="breadcrumb">
        <Typography>Home</Typography>
        <Typography>Support</Typography>
        <Typography color="text.primary">Help Pages</Typography>
      </Breadcrumbs>
      <Typography variant="h4" align="center" gutterBottom>
        Help Pages
      </Typography>
      <Grid container spacing={3}>
        {Object.entries(sectionsMapping).map(([section, { path }], index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  {section}
                </Typography>
                <Box key={index} display="flex" alignItems="center">
                  <Link
                    sx={{ fontSize: 20 }}
                    onClick={() => navigate(`/${customer.uuid}/support/help-pages/${path}`, { state: sectionsMapping[section].state })}
                    underline="none"
                    style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                  >
                    {section} support
                    <FaExternalLinkAlt style={{ marginLeft: "8px" }} />
                  </Link>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default HelpPages;
