import fileDownload from "js-file-download";
import axios from "axios";

const BASEURL =
  process.env.REACT_APP_ENVIRONMENT === "DEV" ||
  process.env.REACT_APP_ENVIRONMENT === "LOCAL"
    ? process.env.REACT_APP_DEV_BASEURL
    : process.env.REACT_APP_PROD_BASEURL;


/**
 * This function serves to provide an easy way to send HTTP GET Requests via axios to the backend.
 * @param {String} path The URL you are trying to hit
 * @param {String} token The access token for auth0, get this from `useContext(DataContext)`
 * @returns axios response
 */
export async function getRequest(path, token, data = null) {
  if (token == null) throw new Error("Token not provided to getRequest");

  // Create a cancel token source
  const cancelTokenSource = axios.CancelToken.source();

  let options = {
    method: "GET",
    url: `${BASEURL}${path}`,
    headers: { Authorization: `Bearer ${token}` },
    params: data,
    cancelToken: cancelTokenSource.token, // Assign cancel token to the request
  };

  try {
    const res = await axios.request(options);
    return res;
  } catch (e) {
    // Check if the request was canceled due to unmounting
    if (axios.isCancel(e)) {
      console.log("Request canceled:", e.message);
    } else {
      const res = e.response;
      return res;
    }
  }

  // Return the cancel token source to allow manual cancellation if needed
  return {
    ...cancelTokenSource,
    cancel: () => {
      cancelTokenSource.cancel("Request canceled manually.");
    },
  };
}

/**
 * This functions serves to provide an easy way to send HTTP POST Requests via axios to the backend.
 * @param {String} path The url you are trying to hit
 * @param {String} token The access token for auth0, get this from `useContext(DataContext)`
 * @param {Object} data A JavaScript object containting the body of your post
 * @param {Boolean} stringify Default = `false`. If this is true, is will wrap your data with JSON.stringify
 * @returns axios response
 */
export async function postRequest(path, token, data, stringify = false) {
  if (token == null) throw new Error("token not provided to postRequest");
  let options = {
    method: "POST",
    url: `${BASEURL}${path}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: stringify ? JSON.stringify(data) : data,
  };

  if (!stringify) {
    options.headers["Content-Type"] = "multipart/form-data";
  }
  try {
    const res = await axios.request(options);
    return res;
  } catch (e) {
    const res = e.response;
    console.log(e);
    return res;
  }
}

export async function genericPostRequest(path, token, data) {
  let jsonData = JSON.stringify(data);
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  try {
    const res = await axios.post(`${BASEURL}${path}`, jsonData, { headers });
    return res;
  } catch (e) {
    const res = e.response;
    console.log(e);
    return res;
  }
}

/**
 * This functions serves to provide an easy way to send HTTP POST Requests via axios to the backend.
 * @param {String} path The url you are trying to hit
 * @param {String} token The access token for auth0, get this from `useContext(DataContext)`
 * @param {Object} data A JavaScript object containting the body of your post
 * @param {Boolean} stringify Default = `false`. If this is true, is will wrap your data with JSON.stringify
 * @returns axios response
 */
export async function patchRequest(path, token, data) {
  if (token == null) throw new Error("token not provided to postRequest");
  let options = {
    method: "PATCH",
    url: `${BASEURL}${path}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };
  try {
    const res = await axios.request(options);
    return res;
  } catch (e) {
    const res = e.response;
    console.log(e);
    return res;
  }
}

/**
 * This function serves to provide an easy way to prompt the browser to download the file you sent from backend.
 *
 * Make sure to use the `FileResponse` in Django.
 * @param {String} path The url you are trying to hit
 * @param {String} token The access token for auth0, get this from `useContext(DataContext)`
 * @param {String} filename The name you want the file to be saved as
 * @returns axios response
 */
export async function fileRequest(path, token, filename, params = null) {
  let options = {
    method: "GET",
    url: `${BASEURL}${path}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: params,
    responseType: "blob", // Important
  };

  try {
    const res = await axios.request(options);
    if (filename === null) {
      filename = res.headers["content-disposition"]
        .split("filename=")[1]
        .split(";")[0];
    }
    fileDownload(res.data, filename);
    return res;
  } catch (e) {
    const res = e.response;

    // Check if the response is JSON even though it's in a Blob
    if (res && res.data && res.headers['content-type'].includes('application/json')) {
      const errorText = await res.data.text();
      const errorData = JSON.parse(errorText);
      return {
        ...res,
        data: errorData, // Replace blob with the parsed JSON
      };
    }

    return res;
  }
}

/**
 * This functions serves to provide an easy way to send HTTP POST Requests via axios to the backend and a
 * @param {String} path The url you are trying to hit
 * @param {String} token The access token for auth0, get this from `useContext(DataContext)`
 * @param {String} filename The name you want the file to be saved as
 * @param {Object} data A JavaScript object containting the body of your post
 * @param {Boolean} stringify Default = `false`. If this is true, is will wrap your data with JSON.stringify
 * @returns axios response
 */
export async function filePostRequest(
  path,
  token,
  filename,
  data,
  stringify = false
) {
  if (token == null) throw new Error("token not provided to filePostRequest");
  let options = {
    method: "POST",
    url: `${BASEURL}${path}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: stringify ? JSON.stringify(data) : data,
    responseType: "blob", // Important
  };
  if (!stringify) {
    options.headers["Content-Type"] = "multipart/form-data";
  }
  try {
    const res = await axios.request(options);
    fileDownload(res.data, filename);
    return res;
  } catch (e) {
    const res = e.response;
    console.log(e);
    return res;
  }
}

/**
 * This functions serves to provide an easy way to send HTTP DELETE Requests via axios to the backend.
 * @param {String} path The url you are trying to hit
 * @param {String} token The access token for auth0, get this from `useContext(DataContext)`
 * @returns axios response
 */
export async function deleteRequest(path, token) {
  if (token == null) throw new Error("token not provided to postRequest");
  let options = {
    method: "DELETE",
    url: `${BASEURL}${path}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.request(options);
    return res;
  } catch (e) {
    const res = e.response;
    console.log(e);
    return res;
  }
}
