import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../Redux/app/hooks";

import CreateForm from "./components/CreateForm";

const IntegrationsAddForm = () => {
  const current_customer = useAppSelector(
    (state) => state.customer,
  );

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">Home</Link>
        <Link to={`/${current_customer.uuid}/config/integrations`}>
          Integrations
        </Link>
        <Typography color="text.primary">Add Integration</Typography>
      </Breadcrumbs>

      <Typography fontWeight={"bold"} variant="h4">
        Add Integration
      </Typography>

      <CreateForm />
    </>
  );
};

export default IntegrationsAddForm;
