import React from "react";
import { Stack, Typography, Paper } from "@mui/material";
import Logo from "../../Images/logo/blindspotLogo.jpeg";

const ArchivedUserLanding = () => {
  return (
    <div
      style={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        sx={{ p: 3, width: "50%", height: "50%", backgroundColor: "#FCFEFF" }}
      >
        <Stack
          display="flex"
          sx={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Logo}
            style={{ width: "60%", borderRadius: "1rem", marginBottom: "1rem" }}
            alt="Blindpot Logo"
          />
          <Typography
            sx={{ textAlign: "center" }}
            fontSize={30}
            fontWeight="bold"
          >
            Your account is not currently active.
          </Typography>
          <Typography sx={{ textAlign: "center" }}>
            Please reach out to your organizations administrator or contact us
            at{" "}
            <a
              href="mailto:Blindspot_support@ondefend.com"
              style={{ color: "blue" }}
            >
              Blindspot_support@ondefend.com
            </a>
          </Typography>
        </Stack>
      </Paper>
    </div>
  );
};

export default ArchivedUserLanding;
