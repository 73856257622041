import React, { useEffect, useState, useContext } from "react";
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  IconButton,
  Tooltip,
} from "@mui/material";
import { TbReplace } from "react-icons/tb";
import { Stack } from "@mui/system";
import { fileRequest, getRequest } from "../../../Helpers/httpRequests";
import { DataContext } from "../../../Context/dataContext";
import moment from "moment";
import { FaCheck, FaFilePdf, FaTimes } from "react-icons/fa";
import FormModal from "../../../Components/Modals/FormModal";
import AdminAddAgreementForm from "../../../Components/Forms/Administration/AdminAddAgreementForm/AdminAddAgreementForm";
import { useAppSelector } from "../../../Redux/app/hooks";

const AdminAgreements = () => {
  // ----- Page Title -----
  document.title = "Agreements";

  // ----- Context -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector((state) => state.customer);

  // ----- States -----
  // form
  const [open, setOpen] = useState(false);

  // Agreements
  const [agreements, setAgreements] = useState([]);

  // ----- Variable -----

  // ----- Functions -----
  const handlePdfClick = async (id, type) => {
    let fileName;
    if (type === "privacy") {
      fileName = "Privacy_Agreement.pdf";
    } else {
      fileName = "EULA_Agreement.pdf";
    }
    await fileRequest(
      `/react/api/admin/agreement-pdf/${id}`,
      accessToken,
      fileName,
    );
    return;
  };

  // ----- On page load -----

  useEffect(() => {
    const getAgreements = async () => {
      const res = await getRequest("/react/api/admin/agreements", accessToken);

      if (res.status === 200) {
        setAgreements(res.data.agreements);
      }
    };

    getAgreements();
  }, [accessToken, current_customer, open]);

  return (
    <>
      <Stack spacing={3}>
        {/* Table */}
        <TableContainer>
          <Table sx={{ midWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Name</b>
                </TableCell>
                <TableCell>
                  <b>Type</b>
                </TableCell>
                <TableCell>
                  <b>Created</b>
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  <b>Status</b>
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  <b>Actions</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* map this */}
              {agreements.length > 0 &&
                agreements.map((agreement) => (
                  <TableRow
                    key={agreement.pdf_file}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{agreement.name}</TableCell>
                    <TableCell>{agreement.type}</TableCell>
                    <TableCell>
                      {moment(agreement.created).format("MMMM Do YYYY, h:mm a")}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {agreement.active ? (
                        <FaCheck color="green" />
                      ) : (
                        <FaTimes color="#757575" />
                      )}
                    </TableCell>
                    <TableCell>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{ justifyContent: "center" }}
                      >
                        {/* PDF */}
                        <IconButton
                          onClick={() =>
                            handlePdfClick(agreement.id, agreement.type)
                          }
                        >
                          <FaFilePdf />
                        </IconButton>
                        <Tooltip title="Replace existing agreement">
                          <IconButton onClick={() => setOpen(true)}>
                            <TbReplace />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              {/* map above */}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      {/* Form Modal */}
      <FormModal open={open} setOpen={setOpen}>
        <AdminAddAgreementForm setOpen={setOpen} />
      </FormModal>
    </>
  );
};

export default AdminAgreements;
