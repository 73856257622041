import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Stack,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { ProviderFormContext } from "./context/ProviderFormContext";
import { useHttpRequest } from "../../Hooks";
import CustomerSelect from "./subcomponents/CustomerSelect";
import CustomerMultiSelect from "./subcomponents/CustomerMultiSelect";

const ServiceProviderForm = ({ type, id, onComplete }) => {
  const [step, setStep] = useState(1); // State to track the current step
  const [customers, setCustomers] = useState([]);
  const [formData, setFormData] = useState({
    id: "",
    customer: "",
    customers: [],
  });

  const isEdit = type === "edit";
  const { response: editResponse } = useHttpRequest({
    method: "GET",
    path: `/api/v2/service-providers/${id}`,
    defer: !isEdit,
  });

  const {
    loading,
    response,
    fetch: submitForm,
  } = useHttpRequest({
    method: isEdit ? "PATCH" : "POST",
    path: isEdit
      ? `/api/v2/service-providers/${id}`
      : `/api/v2/service-providers`,
    data: formData,
    defer: true,
  });

  const handleNext = useCallback(() => {
    setStep((prevStep) => prevStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setStep((prevStep) => prevStep - 1);
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      submitForm();
    },
    [submitForm]
  );

  useEffect(() => {
    if (editResponse) {
      setFormData(editResponse);
      setStep(1);
    }
  }, [editResponse]);

  useEffect(() => {
    if (response) {
      onComplete();
    }
  }, [response, onComplete]);

  // Helper function to get customer names by their IDs
  const getCustomerNameById = (id) => {
    const customer = customers.find((cust) => cust.id === id);
    return customer ? customer.name : "Unknown Customer";
  };

  return (
    <ProviderFormContext.Provider
      value={{
        formData,
        setFormData,
        customers,
        setCustomers,
        id,
        type,
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h4">
          {isEdit ? "Edit" : "Create"} Service Provider
        </Typography>
        <Stepper activeStep={step - 1} alternativeLabel>
          <Step>
            <StepLabel>Select Customer</StepLabel>
          </Step>
          <Step>
            <StepLabel>Select Multiple Customers</StepLabel>
          </Step>
          <Step>
            <StepLabel>Review</StepLabel>
          </Step>
        </Stepper>

        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            {step === 1 && (
              <>
                <CustomerSelect />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={handleNext}
                    disabled={!formData.customer}
                  >
                    Next
                  </Button>
                </div>
              </>
            )}

            {step === 2 && (
              <>
                <CustomerMultiSelect />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button type="button" variant="outlined" onClick={handleBack}>
                    Back
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={handleNext}
                    disabled={formData.customers.length === 0}
                  >
                    Next
                  </Button>
                </div>
              </>
            )}

            {step === 3 && (
              <>
                <Paper variant="outlined" sx={{ padding: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    Review Your Details
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary="Customer"
                        secondary={getCustomerNameById(formData.customer)}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Customers"
                        secondary={
                          formData.customers.length > 0
                            ? formData.customers
                                .map((custId) => getCustomerNameById(custId))
                                .join(", ")
                            : "None"
                        }
                      />
                    </ListItem>
                  </List>
                </Paper>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button type="button" variant="outlined" onClick={handleBack}>
                    Back
                  </Button>
                  <Button type="submit" variant="contained" disabled={loading}>
                    Submit
                  </Button>
                </div>
              </>
            )}
          </Stack>
        </form>
      </Stack>
    </ProviderFormContext.Provider>
  );
};

ServiceProviderForm.propTypes = {
  type: PropTypes.oneOf(["create", "edit"]).isRequired,
  id: function (props, propName, componentName) {
    if (props.type === "edit") {
      if (props[propName] === undefined) {
        return new Error(
          `The prop '${propName}' is required in '${componentName}' when 'type' is 'edit'.`
        );
      }
      const idTypes = ["number", "string"];
      if (!idTypes.includes(typeof props[propName])) {
        return new Error(
          `Invalid prop '${propName}' supplied to '${componentName}', expected one of ${idTypes.join(
            ", "
          )}.`
        );
      }
    }
    return null;
  },
  onComplete: PropTypes.func.isRequired,
};

export default ServiceProviderForm;
