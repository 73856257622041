import { withAuthenticationRequired } from "@auth0/auth0-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { useContext, useState } from "react";
import { FaCircle } from "react-icons/fa";
import { useHttpRequest } from "../../../../Hooks";
import { COLORS } from "../../../../Styles/colors";
import { getRequest, postRequest } from "../../../../Helpers/httpRequests";
import { DataContext } from "../../../../Context/dataContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../Redux/app/hooks";

const ProjectFormAddTestPlans = () => {
  // ----- STATES -----
  const location = useLocation();
  const navigate = useNavigate();
  const customer = useAppSelector((state) => state.customer);
  const simulations = location.state.simulations;
  const testplans = location.state.testplans;
  const [formData, setFormData] = useState(location.state.formData);
  const [isFormProcessing, setIsFormProcessing] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const { accessToken } = useContext(DataContext);
  const [endpoints, setEndpoints] = useState([]);

  const architectures = [
    {
      value: "64",
      name: "64-bit",
    },
    {
      value: "32",
      name: "32-bit",
    },
  ];

  const linuxExecMethod = [
    {
      value: "exe",
      name: "Executable",
    },
  ];

  const execMethod = [
    {
      value: "exe",
      name: "Binary Executable",
    },
    {
      value: "dll",
      name: "Signed Proxy Execution",
    },
    {
      value: "sh",
      name: "Remote Process Injection",
    },
    {
      value: "local",
      name: "Local Process Injection",
    },
  ];

  const piMethods = [
    "CreateRemoteThread",
    "NtCreateRemoteThread",
    "QueueUserAPC",
    "NtQueueUserAPC",
    "RtlCreateUserThread",
  ];

  const formatDateForDjangoModel = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  };

  const handleSubmit = async (data) => {
    const result = await postRequest(
      `/react/api/${customer.uuid}/projects/add`,
      accessToken,
      data,
      true
    );
    if (result.status === 200) {
      navigate(
        `/${customer.uuid}/simulations/campaigns/projects/${result.data.project}`
      );
    }
  };

  const getEndpoints = async () => {
    // get the list of endpoints and targets
    const resEndpoints = await getRequest(
      `/react/api/${customer.uuid}/endpoints?license=True`,
      accessToken
    );
    if (resEndpoints.status === 200) {
      setEndpoints(resEndpoints.data.endpoints);
    }
  };

  const { response: piTargets } = useHttpRequest({
    method: "GET",
    path: "/api/v2/process-injection-targets",
  });

  const { response: transformMethods } = useHttpRequest({
    method: "GET",
    path: "/api/v2/admin/payload-transforms",
  });

  const handleTestPlanChange = (e) => {
    setFormData({
      ...formData,
      testplan: e.target.value,
      campaigns: e.target.value.simulations.map((sim_id) => {
        const sim = simulations.find((sim) => sim_id === sim.id);
        return {
          name: "",
          execution: "exe",
          architecture: "",
          endpoint: "",
          piMethod: "",
          piTarget: "",
          transform: "",
          platform: sim.platform,
          simulation: sim.uuid,
        };
      }),
    });
  };

  const updateCampaign = (idx, value, variable) => {
    const updatedData = formData;
    updatedData.campaigns[idx][variable] = value;
    setFormData({ ...updatedData });
  };

  return (
    <Box component="form">
      <Typography sx={{ marginBottom: 1 }} variant="h5">
        Add Project TestPlans
      </Typography>
      <Stack direction="column" spacing={2}>
        {/* Project Name */}
        <TextField
          required
          sx={{ width: "50%" }}
          id="name"
          label="Name"
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          value={formData.name}
        />

        {/* Date Selector */}
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box
            sx={{
              display: "flex",
              width: "50%",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {/* Start Date */}
            <DesktopDatePicker
              label="Start Date"
              inputFormat="MM/DD/yyyy"
              value={formData.startDate}
              id="startDate"
              onChange={(newVal) => {
                setFormData({ ...formData, startDate: newVal });
                if (formData.startDate > formData.endDate) {
                  setFormData({ ...formData, endDate: newVal });
                }
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            {/* End Date */}
            <DesktopDatePicker
              label="End Date"
              inputFormat="MM/DD/yyyy"
              value={formData.endDate}
              minDate={formData.startDate}
              id="endDate"
              onChange={(newVal) =>
                setFormData({ ...formData, endDate: newVal })
              }
              renderInput={(params) => <TextField {...params} />}
              disabled={!formData.startDate._isAMomentObject}
            />
          </Box>
        </LocalizationProvider>

        {testplans && formData && (
          <FormControl sx={{ width: "50%" }}>
            <InputLabel>Test Plan (Optional)</InputLabel>
            <Select
              value={formData.testplan}
              label={`Test Plan (Optional)`}
              onChange={handleTestPlanChange}
            >
              {testplans.map((o) => {
                return (
                  <MenuItem key={o.id} value={o}>
                    {o.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}

        {formData.testplan && (
          <>
            {formData.testplan.simulations.map((sim_id, idx) => {
              const sim = simulations.find((s) => s.id === sim_id);
              const campaign = formData.campaigns[idx];
              return (
                <>
                  <Typography gutterBottom={false}>{sim.name}</Typography>
                  <Stack
                    spacing={1}
                    direction={"row"}
                    sx={{ width: "100%", mb: 3, mt: 1 }}
                  >
                    <TextField
                      required
                      label="Campaign Name"
                      value={campaign.name}
                      onChange={(e) =>
                        updateCampaign(idx, e.target.value, "name")
                      }
                      sx={{ flex: 2 }}
                    />
                    <FormControl sx={{ flex: 2 }}>
                      <InputLabel required>Endpoint</InputLabel>
                      <Select
                        required
                        value={campaign.endpoint}
                        onClick={() => getEndpoints()}
                        onChange={(e) =>
                          updateCampaign(idx, e.target.value, "endpoint")
                        }
                        label="Endpoint"
                      >
                        {endpoints &&
                          endpoints
                            .sort((a, b) => {
                              if (a.active && !b.active) {
                                return -1;
                              } else if (!a.active && b.active) {
                                return 1;
                              } else {
                                return 0;
                              }
                            })
                            .map((endpoint) => {
                              const active = endpoint.active;
                              const color = active
                                ? COLORS.success.light
                                : COLORS.secondary.dark;
                              return endpoint.host_os
                                .toLowerCase()
                                .includes(campaign.platform) ? (
                                <MenuItem
                                  key={endpoint.uuid}
                                  value={endpoint.uuid}
                                >
                                  <Stack direction={"row"} spacing={"1rem"}>
                                    <FaCircle color={color} />
                                    <Typography>{endpoint.name}</Typography>
                                  </Stack>
                                </MenuItem>
                              ) : null;
                            })}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ flex: 1 }}>
                      <InputLabel required>Architecture</InputLabel>
                      <Select
                        value={campaign.architecture}
                        onChange={(e) =>
                          updateCampaign(idx, e.target.value, "architecture")
                        }
                        label="Architecture"
                      >
                        {architectures.map((arch) => {
                          return (
                            <MenuItem key={arch.value} value={arch.value}>
                              {arch.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ flex: 2 }}>
                      <InputLabel required>Execution Method</InputLabel>
                      <Select
                        value={campaign.execution || ""}
                        onChange={(e) => {
                          switch (e.target.value) {
                            case "local":
                              updateCampaign(idx, e.target.value, "execution");
                              updateCampaign(idx, "", "piMethod");
                              updateCampaign(idx, "", "piTarget");
                              break;
                            case "sh":
                              updateCampaign(idx, e.target.value, "execution");
                              updateCampaign(idx, "", "transform");
                              break;
                            default:
                              updateCampaign(idx, e.target.value, "execution");
                              updateCampaign(idx, "", "piMethod");
                              updateCampaign(idx, "", "piTarget");
                              updateCampaign(idx, "", "transform");
                              break;
                          }
                        }}
                        label="Exectution Method"
                      >
                        {campaign.endpoint?.os === "linux"
                          ? linuxExecMethod.map((exec) => {
                              return (
                                <MenuItem key={exec.value} value={exec.value}>
                                  {exec.name}
                                </MenuItem>
                              );
                            })
                          : execMethod.map((exec) => {
                              return (
                                <MenuItem key={exec.value} value={exec.value}>
                                  {exec.name}
                                </MenuItem>
                              );
                            })}
                      </Select>
                    </FormControl>
                    {campaign.execution.includes("sh") && (
                      <>
                        <FormControl sx={{ flex: 2 }}>
                          <InputLabel required>Injection Method</InputLabel>
                          <Select
                            value={campaign.piMethod || ""}
                            onChange={(e) =>
                              updateCampaign(idx, e.target.value, "piMethod")
                            }
                            label="Process Injection Method"
                          >
                            {piMethods &&
                              piMethods.map((exec) => {
                                return (
                                  <MenuItem value={exec} key={exec}>
                                    {exec}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                        <FormControl sx={{ flex: 1 }}>
                          <InputLabel required>Injection Target</InputLabel>
                          <Select
                            value={campaign.piTarget}
                            onChange={(e) =>
                              updateCampaign(idx, e.target.value, "piTarget")
                            }
                            label="Process Injection Target"
                          >
                            {piTargets
                              ? piTargets.map((exec) => {
                                  return (
                                    <MenuItem key={exec.uuid} value={exec.uuid}>
                                      {exec.name}
                                    </MenuItem>
                                  );
                                })
                              : null}
                          </Select>
                        </FormControl>
                      </>
                    )}
                    {campaign.execution.includes("local") && (
                      <FormControl sx={{ flex: 2 }}>
                        <InputLabel required>Transform</InputLabel>
                        <Select
                          value={campaign.transform ? campaign.transform : ""}
                          onChange={(e) =>
                            updateCampaign(idx, e.target.value, "transform")
                          }
                          label="Transform"
                        >
                          {transformMethods &&
                            transformMethods.map((exec, index) => {
                              return (
                                <MenuItem value={exec.id} key={index}>
                                  {exec.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    )}
                  </Stack>
                </>
              );
            })}
          </>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <LoadingButton
            fullWidth
            sx={{ marginRight: 1 }}
            variant="contained"
            loading={isFormProcessing}
            disabled={
              submitDisabled ||
              !formData.name ||
              (formData.campaigns &&
                formData.campaigns.some(
                  (campaign) =>
                    !campaign.name ||
                    !campaign.endpoint ||
                    !campaign.architecture ||
                    !campaign.execution
                ))
            }
            onClick={() => {
              setIsFormProcessing(true);
              setSubmitDisabled(true);
              // Format the dates before sending the form data
              const formattedData = {
                ...formData,
                startDate: formatDateForDjangoModel(formData.startDate),
                endDate: formatDateForDjangoModel(formData.endDate),
              };

              handleSubmit(formattedData);
            }}
          >
            Submit
          </LoadingButton>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              navigate(`/${customer.uuid}/simulations/campaigns/projects/list`);
            }}
          >
            Cancel
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default withAuthenticationRequired(ProjectFormAddTestPlans);
