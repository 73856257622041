import React from "react";
import FileField from "../../../../FormBuilder/fields/FileField";
import Checkbox from "../../../../FormBuilder/fields/Checkbox";
import { Button, Stack, Typography } from "@mui/material";
import Form from "../../../../FormBuilder/provider/Form";
import { useAppSelector } from "../../../../Redux/app/hooks";

export const ExerciseTemplateForm = (props) => {
  // template object corresponds to rows in the
  const { template, mode, onComplete } = props;
  const user = useAppSelector((state) => state.user);

  return (
    <Form
      method={mode === "edit" ? "PATCH" : "POST"}
      path={
        mode === "edit"
          ? `/api/v2/${user.current_customer}/exercisetemplates/${template.id}`
          : `/api/v2/${user.current_customer}/exercisetemplates`
      }
      onSuccess={onComplete}
      defaultValues={{ ...template }}
    >
      <Stack spacing={2}>
        <Typography variant="h4">
          {mode === "edit" ? "Edit" : "Add"} Exercise Template
        </Typography>

        <Stack>
          <FileField name="file_name" required accept={".docx"} />
          {user.superuser && (
            <Checkbox name="universal" label={"Universal Template"} />
          )}
          <Checkbox name="default" label={"Default Template"} />
          <div className="flex justify-center">
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </div>
        </Stack>
      </Stack>
    </Form>
  );
};
