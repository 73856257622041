import React, { useContext, useEffect } from "react";
import { Stack } from "@mui/material";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { ProviderFormContext } from "../context/ProviderFormContext";
import { useHttpRequest } from "../../../Hooks";

const CustomerMultiSelect = () => {
  const { id, formData, setFormData, customers, type } =
    useContext(ProviderFormContext);

  const isEdit = type === "edit";

  const { response } = useHttpRequest({
    method: isEdit ? "PATCH" : "GET",
    path: `/api/v2/service-providers/${id}`,
    defer: isEdit,
  });

  const handleAddCustomer = (e, cx) => {
    e.stopPropagation();
    setFormData((data) => ({
      ...data,
      customers: [...data.customers, cx.id],
    }));
  };

  const handleRemoveCustomer = (e, cx) => {
    e.stopPropagation();
    setFormData((data) => ({
      ...data,
      customers: data.customers.filter((c) => c !== cx.id),
    }));
  };

  useEffect(() => {
    if (response) {
      setFormData((data) => ({
        ...data,
        customers: customers
          .filter((c) => response.customers.includes(c.id))
          .map((c) => c.id),
      }));
    }
  }, [response, id, customers, setFormData]);

  // Filter out customers that are service_provider or service_provider_tenant, 
  // but include those already in formData.customers or where service_provider_tenant matches formData.customer
  const availableCustomers = customers.filter(
    (cx) =>
      (!cx.is_service_provider &&
        !cx.is_service_provider_tenant) ||
      formData.customers.includes(cx.id) ||
      cx.service_provider_tenant === formData.customer
  );

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1}>
        <div className="flex-1 text-center text-lg font-semibold">
          Customers
        </div>
        <div className="w-[10%]"></div>
        <div className="flex-1 text-center text-lg font-semibold">
          Selected Customers
        </div>
      </Stack>
      <Stack direction="row" spacing={1} height={500}>
        <div className="flex-1 overflow-auto border-2 border-blue-200 rounded p-3">
          <ul>
            {availableCustomers
              .filter((cx) => !formData.customers.includes(cx.id))
              .map((customer) => (
                <li
                  key={customer.id}
                  className="rounded hover:bg-blue-100 my-1 px-2 cursor-pointer"
                  onClick={(e) => handleAddCustomer(e, customer)}
                >
                  {customer.display_name}
                </li>
              ))}
          </ul>
        </div>
        <div className="flex w-[10%] items-center justify-center">
          <Stack className="flex justify-center items-center" spacing={1}>
            <p className="font-semibold">ADD</p>
            <FaArrowRight size={30} className="text-blue-500" />
            <FaArrowLeft size={30} className="text-blue-500" />
            <p className="font-semibold">REMOVE</p>
          </Stack>
        </div>
        <div className="flex-1 overflow-auto border-2 border-blue-200 rounded p-3">
          <ul>
            {formData.customers
              .map((customerId) =>
                customers.find((customer) => customer.id === customerId)
              )
              .filter(Boolean) // This will filter out any null values if the customer isn't found
              .map((customer) => (
                <li
                  key={customer.id}
                  className="rounded hover:bg-blue-100 my-1 px-2 cursor-pointer"
                  onClick={(e) => handleRemoveCustomer(e, customer)}
                >
                  {customer.display_name}
                </li>
              ))}
          </ul>
        </div>
      </Stack>
    </Stack>
  );
};

export default CustomerMultiSelect;
