import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { getRequest } from "../../../../Helpers/httpRequests";
import { useContext, useState } from "react";
import { DataContext } from "../../../../Context/dataContext";
import { FaWindowClose } from "react-icons/fa";

const TerminateAgentsModal = ({ open, setOpen, endpoints }) => {
  const { accessToken } = useContext(DataContext);
  const [loadingStates, setLoadingStates] = useState({});
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [endpointToTerminate, setEndpointToTerminate] = useState(null);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 3,
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onTerminate = async (endpoint) => {
    setLoadingStates((prevState) => ({
      ...prevState,
      [endpoint.endpointUUID]: true,
    }));
    try {
      const res = await getRequest(
        `/api/agents/kill-agent/${endpointToTerminate?.endpointUUID}`,
        accessToken
      );
      if (res.status === 200) setOpen(false);
    } catch (error) {
      console.error("Error terminating agent:", error);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="row" justifyContent="space-between">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Terminate Agents
            </Typography>
            <IconButton onClick={() => setOpen(false)} color="error">
              <FaWindowClose />
            </IconButton>
          </Stack>
          <Divider />
          <List>
            {endpoints.map((endpoint) => {
              const isLoading = loadingStates[endpoint.endpointUUID] || false;
              return (
                <ListItem
                  key={endpoint.endpointUUID}
                  secondaryAction={
                    <Button
                      variant="contained"
                      disabled={isLoading}
                      onClick={() => {
                        setEndpointToTerminate(endpoint);
                        setConfirmOpen(true);
                      }}
                    >
                      Terminate
                    </Button>
                  }
                >
                  <ListItemText primary={endpoint.name} />
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Modal>
      <Modal open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <Box sx={{ ...style, width: 600 }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Confirm Termination
          </Typography>
          <Typography>
            Are you sure you want to terminate the agent running on{" "}
            {endpointToTerminate?.name || ""}? Depending on the sleeptime of the
            agent, this can take a few seconds to a few minutes.
          </Typography>
          <Stack direction="row" justifyContent="space-between" mt={2}>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setConfirmOpen(false);
                onTerminate(endpointToTerminate);
              }}
            >
              Confirm
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setConfirmOpen(false);
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default TerminateAgentsModal;
