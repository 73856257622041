import React from "react";
import PropTypes from "prop-types";
import { Stack } from "@mui/material";
import FormSelect from "./helperComps/FormSelect";
import FormInput from "./helperComps/FormInput";
import FormBool from "./helperComps/FormBool";
import Description from "./helperComps/Description";

/**
 * Please read the readme.md if you have any questions
 * @param {*} props
 * @returns
 */
const FormRowWithDetails = (props) => {
  /**
   * Generates and returns the correct component depending on the forms type.
   * @returns
   */
  const genFormLeft = () => {
    if (props.type === "select") return <FormSelect {...props} />;
    if (props.type === "input") return <FormInput {...props} />;
    if (props.type === "bool") return <FormBool {...props} />;
  };
  const leftForm = genFormLeft();

  if (props.noDetails) {
    return <>{leftForm}</>;
  }

  return (
    <Stack direction="row" spacing={2}>
      {leftForm}
      <Description {...props} />
    </Stack>
  );
};

/**
 * FOR DETAILED PROP EXPLANATION, SEE README.md
 */
FormRowWithDetails.propTypes = {
  type: PropTypes.oneOf(["select", "input", "bool"]).isRequired,
  multiple: PropTypes.string,
  label: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
  formKey: PropTypes.string.isRequired,
  setFormData: PropTypes.func.isRequired,
  options: function (props, propName, componentName) {
    if (props["type"] === "select" && props[propName] === undefined) {
      console.error(
        `Warning: Failed prop type: The prop '${propName}' is marked as required in '${componentName}' when using a type of 'select', but its value is 'undefined'.`
      );
    }
  },

  // required input props
};

// for detailed prop explaination, please refer to the readme
FormRowWithDetails.defaultProps = {
  split: [30, 70],
};

export default FormRowWithDetails;
