import SecurityToolAddForm from "../../Components/Forms/Config/SecurityToolAddForm";
import SecurityToolEditForm from "../../Components/Forms/Config/SecurityToolEditForm";
import ConfigProxySettings from "../../Pages/Config/ConfigProxySettings/ConfigProxySettings";
import ConfigSecurityToolDetails from "../../Pages/Config/ConfigSecurityToolDetails/ConfigSecurityToolDetails";
import ConfigSecurityTools from "../../Pages/Config/ConfigSecurityTools/ConfigSecurityTools";
// import ProxySettingsAddForm from "../../Components/Forms/Config/ProxySettingsAddForm";
import { Route, Routes } from "react-router-dom";
import IntegrationEditForm from "../../Components/Forms/Config/IntegrationEditForm/IntegrationEditForm";
import IntegrationsAddForm from "../../Components/Forms/Config/IntegrationsAddForm";
import ServiceManager from "../../Pages/AlertValidation/ServiceManager/ServiceManager";
import { BlackoutScheduling } from "../../Pages/Config/BlackoutScheduling/BlackoutScheduling";
import C2ProxyConfigs from "../../Pages/Config/C2ProxyConfigs/C2ProxyConfigs";
import ConfigAttackSimulationSetting from "../../Pages/Config/ConfigAttackSimualtionSetting/ConfigAttackSimulationSetting";
import ConfigIntegrations from "../../Pages/Config/ConfigIntegrations/ConfigIntegrations";
import ConfigReportingTool from "../../Pages/Config/ConfigReportingTool";
import ConfigUserInvites from "../../Pages/Config/ConfigUserInvites";
import ProcessNames from "../../Pages/Config/ProcessNames/ProcessNames";

const ConfigRoutes = () => {
  return (
    <Routes>
      <Route exact path="securitytools" element={<ConfigSecurityTools />} />
      <Route exact path="securitytools/add" element={<SecurityToolAddForm />} />
      <Route
        exact
        path="securitytools/edit/:toolUUID"
        element={<SecurityToolEditForm />}
      />
      <Route
        exact
        path="securitytools/detail/:toolUUID"
        element={<ConfigSecurityToolDetails />}
      />
      <Route exact path="proxies" element={<ConfigProxySettings />} />
      {/* <Route exact path="proxies/add" element={<ProxySettingsAddForm />} /> */}
      <Route path="service-manager" element={<ServiceManager />} />
      <Route exact path="user-management" element={<ConfigUserInvites />} />
      <Route exact path="integrations" element={<ConfigIntegrations />} />
      <Route exact path="integrations/add" element={<IntegrationsAddForm />} />
      <Route
        exact
        path="integrations/edit/:id"
        element={<IntegrationEditForm />}
      />
      <Route
        exact
        path="attack-simulation-setting"
        element={<ConfigAttackSimulationSetting />}
      />
      <Route exact path="reporttool" element={<ConfigReportingTool />} />
      <Route exact path="c2proxyconfig" element={<C2ProxyConfigs />} />
      <Route exact path="schedule" element={<BlackoutScheduling />} />
      <Route exact path="processNames" element={<ProcessNames />} />
    </Routes>
  );
};

export default ConfigRoutes;
