import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { FormContext } from "../context/FormContext";
import { useHttpRequest } from "../../Hooks";
import { v1 as uuidv1 } from "uuid";
import { toast as toastify } from "react-toastify";

const Form = ({ method, path, children, toast, onSuccess, defaultValues }) => {
  const [formData, setFormData] = useState(defaultValues);

  // Generate a unique ID for the form
  const id = uuidv1();

  const {
    status,
    error,
    fetch: submit,
  } = useHttpRequest({
    method: method,
    path: path,
    data: formData,
    defer: true,
  });

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    submit();
  };

  // displays any errors if toast prop provided
  useEffect(() => {
    if (error && toast) {
      const errorMessage = toast?.error || error.message;
      toastify.error(errorMessage);
    }
  }, [error, toast]);

  // displays any errors if toast prop provided
  useEffect(() => {
    if (status && status >= 200 && status <= 299) {
      if (toast) {
        const toastMessage = toast?.success || "Success!";
        toastify.success(toastMessage);
      }
      if (onSuccess) {
        onSuccess()
      }
      setFormData({});
    }

  }, [status, toast, onSuccess]);

  // memoized object containing formValues (reduce re-rendering)
  const formValues = useMemo(
    () => ({
      formData,
      setFormData,
      error,
    }),
    [formData, error]
  );

  return (
    <FormContext.Provider value={formValues}>
      <form id={id} onSubmit={handleSubmit}>
        {children}
      </form>
    </FormContext.Provider>
  );
};

const toastShape = {
  success: PropTypes.string,
  error: PropTypes.string,
};

Form.propTypes = {
  method: PropTypes.oneOf(["POST", "PUT", "PATCH"]),
  path: PropTypes.string.isRequired,
  toast: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape(toastShape)]),
  onSuccess: PropTypes.func,
  sanitize: PropTypes.bool,
  defaultValues: PropTypes.object
};

Form.defaultProps = {
  method: "POST",
  defaultValues: {}
};

export default Form;
