import { ScubaDiving, Star, StarBorder } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import moment from "moment";
import { useCallback, useContext, useMemo } from "react";
import { BiInjection } from "react-icons/bi";
import { FaUserSecret } from "react-icons/fa";
import { GiEvilTower } from "react-icons/gi";
import { PiFolderLock } from "react-icons/pi";
import { SiHackaday } from "react-icons/si";
import { Link } from "react-router-dom";
import { DataContext } from "../../../../Context/dataContext";
import { useAppSelector } from "../../../../Redux/app/hooks";
import { SimLibraryContext } from "../provider/context";

export default function SimCard(props) {
  const { darkMode } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );
  const sim = useMemo(() => props.sim, [props.sim]);
  const { favorites, setFavorites, setFormOpen, setSelectedSim } =
    useContext(SimLibraryContext);

  //set recently published
  const isPublishedLessThanTwoMonthsAgo = useCallback(() => {
    const currentDate = moment();
    if (sim.published_date) {
      const publishedDate = moment(sim.published_date);
      return currentDate.diff(publishedDate, "months") < 2;
    } else if (sim.created_date) {
      const createdDate = moment(sim.created_date);
      return currentDate.diff(createdDate, "months") < 2;
    }
    return false;
  }, [sim.published_date, sim.created_date]);
  

  // set the favorites.
  const updateFavorites = useCallback(() => {
    if (!favorites.includes(sim.id)) {
      setFavorites((prev) => [...prev, sim.id]);
      const newFavs = JSON.stringify([...favorites, sim.id]);
      localStorage.setItem("favsims", newFavs);
    } else {
      setFavorites((prev) => prev.filter((id) => id !== sim.id));
      const newFavs = JSON.stringify(favorites.filter((id) => id !== sim.id));
      localStorage.setItem("favsims", newFavs);
    }
  }, [favorites, setFavorites, sim]);

  // determine the fave icon
  const FavIcon = () => {
    if (favorites.includes(sim.id)) {
      return <Star sx={{ color: "#F3B900" }} />;
    } else {
      return <StarBorder />;
    }
  };

  const onCreateCampaignClick = useCallback(
    (sim) => {
      setFormOpen(true);
      setSelectedSim(sim);
      return;
    },
    [setFormOpen, setSelectedSim]
  );

  return useMemo(
    () => (
      <Card
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <CardHeader
          sx={{
            background: darkMode
              ? "linear-gradient(to right, rgb(75, 85, 99), rgb(17, 24, 39))"
              : "linear-gradient(to right, #e0eafc, #cfdef3)",
            height: "80px",
          }}
          avatar={<AvatarIcon sim={sim} darkMode={darkMode} />}
          title={
            <Link to={`/${current_customer.uuid}/simulations/${sim.uuid}`}>
              {sim.name}
            </Link>
          }
          titleTypographyProps={{
            fontSize: 16,
            noWrap: false,
            overflow: "hidden",
            textOverflow: "ellipsis",
            lineHeight: 1.3,
          }}
          action={
            <>
              <Tooltip title="Add to favorites">
                <IconButton aria-label="favorite-sim" onClick={updateFavorites}>
                  <FavIcon />
                </IconButton>
              </Tooltip>
              {isPublishedLessThanTwoMonthsAgo() && (
                <Tooltip title="Recently published simulation">
                 <Chip label="New" color="primary"/>
                </Tooltip>
              )}
            </>
          }
          subheader={sim.category}
        />
        <CardContent>
          <Typography sx={{ pt: 1 }} variant="body2">
            {sim.description}
          </Typography>
          <Typography sx={{ pt: 1, fontStyle: "italic" }} variant="body2">
            Published -{" "}
            {sim.published_date
              ? moment(sim.published_date).format("MMMM D, YYYY")
              : sim.created_date
              ? moment(sim.created_date).format("MMMM D, YYYY")
              : "January 1, 2020"}
          </Typography>
        </CardContent>
        <CardActions sx={{ marginTop: "auto" }}>
          <Stack
            direction="row"
            sx={{ width: "100%" }}
            justifyContent="space-between"
          >
            <Link to={`/${current_customer.uuid}/simulations/${sim.uuid}`}>
              <Button>Learn More</Button>
            </Link>
            <Button onClick={() => onCreateCampaignClick(sim)}>
              Create Campaign
            </Button>
          </Stack>
        </CardActions>
      </Card>
    ),
    [
      sim,
      darkMode,
      updateFavorites,
      current_customer,
      onCreateCampaignClick,
      isPublishedLessThanTwoMonthsAgo
    ]
  );
}

// set the sim avatar based on category
const AvatarIcon = ({ sim, darkMode }) => {
  const category = sim.category.toLowerCase();
  const sx = { backgroundColor: darkMode ? "#1e1e1e" : "#FAFAFA" };

  let icon;
  switch (category) {
    case "adversary emulations":
      icon = <SiHackaday color="#C22226" size={28} />;
      break;
    case "technique deep dives":
      icon = <ScubaDiving sx={{ color: "blue" }} size={28} />;
      break;
    case "external c2 testing":
      icon = <GiEvilTower color="#C22226" size={28} />;
      break;
    case "ransomware emulations":
      icon = <PiFolderLock color="#C22226" size={28} />;
      break;
    case "table top injects":
      icon = <BiInjection color="#C22226" size={28} />;
      break;
    case "access brokers":
      icon = <FaUserSecret color="#C22226" size={28} />;
      break;
    default:
      icon = "N";
  }
  return <Avatar sx={sx}>{icon}</Avatar>;
};
