import { Link } from "react-router-dom";
import { useEffect, useContext, useState } from "react";
import {
  Breadcrumbs,
  Typography,
  Box,
  Paper,
  Stack,
  IconButton,
  Modal,
  Button,
  Tooltip,
} from "@mui/material";
import TextButton from "../../../../Components/Buttons/TextButton";
import {
  fileRequest,
  getRequest,
  postRequest,
} from "../../../../Helpers/httpRequests";
import {
  FaPlus,
  FaTrashAlt,
  FaDownload,
  FaRegClipboard,
  FaThumbsDown,
  FaThumbsUp,
  FaUserSecret,
  FaExclamationTriangle,
} from "react-icons/fa";
import { DataContext } from "../../../../Context/dataContext";
import AgentProfileAddForm from "../../../../Components/Forms/Config/AgentProfileAddForm/AgentProfileAddForm";
import DataGridWithStyles from "../../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import { COLORS } from "../../../../Styles/colors";
import { toast } from "react-toastify";
import FormModal from "../../../../Components/Modals/FormModal";
import moment from "moment";
import { useAppSelector } from "../../../../Redux/app/hooks";
import { AgentUIForm } from "../../../../Components/Forms/Config/AgentUIForm/AgentUIForm";

const Endpoints_Download_Agents = () => {
  // ----- STATES && CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector((state) => state.customer);
  const [reloadEndpoints, setReloadEndpoints] = useState(true);
  const [win64DataTableRows, setWin64DataTableRows] = useState([]);
  const [win32DataTableRows, setWin32DataTableRows] = useState([]);
  const [linux64DataTableRows, setLinux64DataTableRows] = useState([]);
  const [linux32DataTableRows, setLinux32DataTableRows] = useState([]);
  const [macosDataTableRows, setMacosDataTableRows] = useState([]);
  const [currentVersion, setCurrentVersion] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteName, setDeleteName] = useState("");
  const [deleteUUID, setDeleteUUID] = useState("");
  const [modal, setModal] = useState({
    open: false,
    content: null,
  });

  //Variables

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    maxHeight: "90%",
    minWidth: "65%",
    overflowY: "auto",
  };

  const defaultModalState = {
    open: false,
    content: null,
  };

  const role = useAppSelector((state) => state.user.role);
  const columns = [
    {
      field: "name",
      headerName: "Agent Name",
      maxWidth: 130,
      flex: 1,
      renderCell: (params) => {
        // If the agent is a beta agent, display a beta badge
        return (
          <Stack direction={"row"} spacing={1.5}>
            <div>{params.row.name}</div>
            {params.row.beta && (
              <Typography
                sx={{
                  backgroundColor: COLORS.warning.main,
                  color: "white",
                  borderRadius: "5px",
                  padding: "2px",
                }}
              >
                Beta
              </Typography>
            )}
          </Stack>
        );
      },
    },
    {
      field: "garble",
      headerName: "Garble",
      maxWidth: 70,
      flex: 1,
      renderCell: (params) => {
        return params.row.garble === true ? (
          <IconButton>
            <FaThumbsUp color={COLORS.info.main} />
          </IconButton>
        ) : (
          <IconButton>
            <FaThumbsDown color={COLORS.info.main} />
          </IconButton>
        );
      },
    },
    {
      field: "proxy",
      headerName: "Proxy",
      maxWidth: 70,
      flex: 1,
      renderCell: (params) => {
        return params.row.proxy === "no" ? (
          <IconButton>
            <FaThumbsDown color={COLORS.info.main} />
          </IconButton>
        ) : (
          <IconButton>
            <FaThumbsUp color={COLORS.primary.main} />
          </IconButton>
        );
      },
    },
    { field: "sleep_time", headerName: "Sleep time", maxWidth: 80, flex: 1 },
    { field: "arch", headerName: "Arch", maxWidth: 80, flex: 1 },
    { field: "platform", headerName: "Platform", maxWidth: 110, flex: 1 },
    {
      field: "created_date",
      headerName: "Created Date",
      maxWidth: 300,
      flex: 1,
      renderCell: (params) => {
        return params.row.created_date === null
          ? "None"
          : moment(params.row.created_date).format("MMM Do, YYYY hh:mm a");
      },
    },
    {
      field: "version",
      headerName: "Version",
      maxWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.version !== currentVersion && (
              <FaExclamationTriangle color={COLORS.warning.main} />
            )}{" "}
            {params.row.version}
          </div>
        );
      },
    },
    {
      field: "created_by",
      headerName: "Created By",
      maxWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return params.row.created_by === null ? "None" : params.row.created_by;
      },
    },
    {
      field: "iocs",
      headerName: "IOCS",
      maxWidth: 2000,
      flex: 2,
      renderCell: (params) => {
        const copyAllToClipboard = () => {
          const allIOCs = params.value
            .map((ioc) => `${ioc.type}: ${ioc.value}`)
            .join("\n");
          navigator.clipboard.writeText(allIOCs);
        };

        return (
          <Box
            direction="column"
            display="flex"
            flexDirection="column"
            height="100%"
          >
            {params.value.map((ioc) => (
              <Box
                key={ioc.uuid}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  sx={{ wordBreak: "break-all", flexGrow: 1, marginLeft: 1 }}
                >
                  {ioc.type}: {ioc.value}
                </Typography>
              </Box>
            ))}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: -2,
              }}
            >
              <Tooltip title={"Copy to Clipboard"}>
                <IconButton size="small" onClick={copyAllToClipboard}>
                  <FaRegClipboard />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      maxWidth: 170,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1}>
            <IconButton onClick={() => downloadAgent(params, false)}>
              <FaDownload title="Download Agent" />
            </IconButton>
            <IconButton onClick={() => downloadAgent(params, true)}>
              <FaUserSecret title="Download Agent with obscured name" />
            </IconButton>
            <IconButton
              disabled={role === "View_Only"}
              style={{ opacity: role === "View_Only" ? 0.5 : 1 }}
              onClick={() => {
                setDeleteName(params.row.name);
                setDeleteUUID(params.row.id);
                setDeleteModal(true);
              }}
            >
              <FaTrashAlt color={COLORS.error.main} title="Delete" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  //Functions

  const deleteAgent = async () => {
    const res = await toast.promise(
      postRequest(
        `/react/api/${current_customer.uuid}/endpoints/agent-file/delete/${deleteUUID}`,
        accessToken
      ),
      {
        pending: "Deleting Agent",
        success: `Agent Deleted`,
        error: "Something went wrong!",
      }
    );
    if (res.status === 200) {
      setDeleteModal(false);
      setReloadEndpoints((current) => !current);
    }
  };

  const downloadAgent = async (params, undercover) => {
    // please don't make endpoints with filenames on them. Just do uuid ffs
    await toast.promise(
      fileRequest(
        `/react/api/${current_customer.uuid}/endpoints/agent-file/download/${params.row.id}?undercover=${undercover}`,
        accessToken,
        null
      ),
      {
        pending: "Downloading Agent",
        success: `Agent Downloaded`,
        error: "Something went wrong!",
      }
    );
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getData = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/endpoints/download-agents`,
        accessToken
      );
      try {
        setCurrentVersion(res.data.current_version);
        populateWin64DataGrid(res.data.win64);
        populateWin32DataGrid(res.data.win32);
        populateLinux64DataGrid(res.data.linux64);
        populateLinux32DataGrid(res.data.linux32);
        populateMacosDataGrid(res.data.linux32);
      } catch (err) {
        console.log("Error while trying to set States from getData()", err);
      }
    };

    const populateLinux64DataGrid = (datarows) => {
      const rows = datarows.map((data) => {
        return {
          id: data.uuid,
          name: data.name,
          fileName: data.agent_file,
          sleep_time: data.agent_profile?.sleep_time,
          arch: data.agent_profile?.arch,
          garble: data.agent_profile?.garble,
          safelist_check: data.agent_profile?.safelist_check,
          proxy: data.agent_profile?.use_proxy,
          platform: "Linux-X64",
          created_date: data.created,
          created_by: data.agent_profile?.created_by,
          iocs: data.ioc,
          actions: [],
          version: data.version,
          beta: data.beta,
        };
      });
      setLinux64DataTableRows(rows);
    };

    const populateLinux32DataGrid = (datarows) => {
      const rows = datarows.map((data) => {
        return {
          id: data.uuid,
          name: data.name,
          fileName: data.agent_file,
          sleep_time: data.agent_profile?.sleep_time,
          arch: data.agent_profile?.arch,
          garble: data.agent_profile?.garble,
          safelist_check: data.agent_profile?.safelist_check,
          proxy: data.agent_profile?.use_proxy,
          platform: data.platform,
          created_date: data.created,
          created_by: data.agent_profile?.created_by,
          iocs: data.ioc,
          actions: [],
          version: data.version,
          beta: data.beta,
        };
      });
      setLinux32DataTableRows(rows);
    };

    const populateWin64DataGrid = (datarows) => {
      const rows = datarows.map((data) => {
        return {
          id: data.uuid,
          name: data.name,
          fileName: data.agent_file,
          sleep_time: data.agent_profile?.sleep_time,
          arch: data.agent_profile?.arch,
          garble: data.agent_profile?.garble,
          safelist_check: data.agent_profile?.safelist_check,
          proxy: data.agent_profile?.use_proxy,
          platform: "Windows-X64",
          created_date: data.created,
          created_by: data.agent_profile?.created_by,
          iocs: data.ioc,
          actions: [],
          version: data.version,
          beta: data.beta,
        };
      });
      setWin64DataTableRows(rows);
    };

    const populateWin32DataGrid = (datarows) => {
      const rows = datarows.map((data) => {
        return {
          id: data.uuid,
          name: data.name,
          fileName: data.agent_file,
          garble: data.agent_profile?.garble,
          sleep_time: data.agent_profile?.sleep_time,
          arch: data.agent_profile?.arch,
          safelist_check: data.agent_profile?.safelist_check,
          proxy: data.agent_profile?.use_proxy,
          platform: data.platform,
          created_date: data.created,
          created_by: data.agent_profile?.created_by,
          iocs: data.ioc,
          actions: [],
          version: data.version,
          beta: data.beta,
        };
      });
      setWin32DataTableRows(rows);
    };

    const populateMacosDataGrid = (datarows) => {
      const rows = datarows.map((data) => {
        return {
          id: data.uuid,
          name: data.name,
          fileName: data.agent_file,
          garble: data.agent_profile?.garble,
          sleep_time: data.agent_profile?.sleep_time,
          arch: data.agent_profile?.arch,
          safelist_check: data.agent_profile?.safelist_check,
          proxy: data.agent_profile?.use_proxy,
          platform: data.platform,
          created_date: data.created,
          created_by: data.agent_profile?.created_by,
          iocs: data.ioc,
          actions: [],
          version: data.version,
          beta: data.beta,
        };
      });
      setMacosDataTableRows(rows);
    };

    // TODO: This is not the way to refresh components... this is a hack to avoid warnings
    if (reloadEndpoints) {
      getData();
      setReloadEndpoints(false);
    }
  }, [current_customer, accessToken, reloadEndpoints]);

  return (
    <Stack spacing={2}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">Home</Link>
        <Link
          className="text-links"
          to={`/${current_customer.uuid}/simulations/endpoints/list`}
        >
          Endpoints
        </Link>
        <Typography color="text.primary">Download Agents</Typography>
      </Breadcrumbs>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography component={"span"} fontWeight={"bold"} variant="h4">
          Download Blindspot Agents
        </Typography>
        <Stack direction={"row"}>
          <TextButton
            role={role}
            onClick={() => {
              setModal({
                open: true,
                content: (
                  <AgentProfileAddForm
                    setModalOpen={() => setModal(defaultModalState)}
                    onFinish={async () => {
                      setModal(defaultModalState);
                      setReloadEndpoints(true);
                    }}
                  />
                ),
              });
            }}
            tooltip="Create Agent"
            icon={FaPlus}
          />
          <TextButton
            role={role}
            onClick={() =>
              setModal({
                open: true,
                content: (
                  <AgentUIForm
                    onFinish={() => {
                      setModal(defaultModalState);
                    }}
                  />
                ),
              })
            }
            tooltip="Download Agent UI client"
            icon={FaDownload}
          />
        </Stack>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography>Current Version: </Typography>
        <Typography sx={{ marginLeft: 1, fontWeight: "bold" }}>
          {currentVersion}
        </Typography>
      </Box>

      {win64DataTableRows.length > 0 && (
        <Paper sx={{ p: 3 }}>
          <Typography marginBottom={"10px"} fontWeight={"bold"} variant="h6">
            Microsoft Windows 64-bit{" "}
          </Typography>
          <DataGridWithStyles
            name="microsoft64-endpoints"
            autoHeight
            rows={win64DataTableRows}
            columns={columns}
            getRowHeight={() => "auto"}
          />
        </Paper>
      )}

      {win32DataTableRows.length > 0 && (
        <Paper sx={{ p: 3 }}>
          <Typography marginBottom={"10px"} fontWeight={"bold"} variant="h6">
            Microsoft Windows 32-bit{" "}
          </Typography>
          <DataGridWithStyles
            name="microsoft32-endpoints"
            getRowHeight={() => "auto"}
            autoHeight
            rows={win32DataTableRows}
            columns={columns}
          />
        </Paper>
      )}

      {linux64DataTableRows.length > 0 && (
        <Paper sx={{ p: 3 }}>
          <Typography marginBottom={"10px"} fontWeight={"bold"} variant="h6">
            Linux 64-bit{" "}
          </Typography>
          <DataGridWithStyles
            name="linux64-endpoints"
            autoHeight
            rows={linux64DataTableRows}
            columns={columns}
            getRowHeight={() => "auto"}
          />
        </Paper>
      )}

      {linux32DataTableRows.length > 0 && (
        <Paper sx={{ p: 3 }}>
          <Typography marginBottom={"10px"} fontWeight={"bold"} variant="h6">
            Linux 32-bit{" "}
          </Typography>
          <DataGridWithStyles
            name="linux32-endpoints"
            autoHeight
            rows={linux64DataTableRows}
            columns={columns}
            getRowHeight={() => "auto"}
          />
        </Paper>
      )}

      {macosDataTableRows.length > 0 && (
        <Paper sx={{ p: 3 }}>
          <Typography marginBottom={"10px"} fontWeight={"bold"} variant="h6">
            MacOS{" "}
          </Typography>
          <DataGridWithStyles
            name="macos-endpoints"
            autoHeight
            rows={linux64DataTableRows}
            columns={columns}
            rowHeight={104}
          />
        </Paper>
      )}
      <Modal
        open={modal.open}
        onClose={() => setModal(defaultModalState)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>{modal.content}</Box>
      </Modal>
      {/* Delete Modal */}
      <FormModal open={deleteModal} setOpen={setDeleteModal}>
        <Box component="form">
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: 4 }}
          >
            Are you sure you want to remove {deleteName} agent?
          </Typography>
          <Stack direction="column" spacing={2}>
            <Button onClick={() => deleteAgent()} variant="contained">
              Yes
            </Button>
          </Stack>
        </Box>
      </FormModal>
    </Stack>
  );
};

export default Endpoints_Download_Agents;
