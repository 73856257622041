import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import { DataContext } from "../../../../Context/dataContext";
import { getRequest } from "../../../../Helpers/httpRequests";
import { useAppSelector } from "../../../../Redux/app/hooks";
import CampaignContext from "./CampaignContext";
import { useHttpRequest } from "../../../../Hooks";

const CampaignDetailsProvider = ({ executedUUID, children }) => {
  const [data, setData] = useState({});
  const [timeline, setTimeline] = useState([]);
  const [campaign, setCampaign] = useState(null);
  const [reportName, setReportName] = useState("");
  const [outcomeCounts, setOutcomeCounts] = useState([]);
  const [missedThreholds, setMissedThresholds] = useState({
    blocked: 0,
    alerted: 0,
    logged: 0,
  });
  const [modifiedThreshold, setModifiedThreshold] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [reload, setReload] = useState(false);
  const [findings, setFindings] = useState([]);
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector((state) => state.customer);

  const { response: options } = useHttpRequest({
    method: "GET",
    path: `/api/v2/cached-findings/options`,
  });

  const { response: finding_r, fetch: getFindings } = useHttpRequest({
    method: "GET",
    path: `/api/v2/cached-findings/filter`,
    params: {
      campaign: executedUUID,
    },
  });

  const getCampaignDetails = useCallback(async () => {
    const [res, res2] = await Promise.all([
      getRequest(
        `/react/api/${current_customer.uuid}/report/campaign/timeline/${executedUUID}`,
        accessToken
      ),
      getRequest(
        `/react/api/${current_customer.uuid}/report/campaign/${executedUUID}`,
        accessToken
      ),
    ]);
    getFindings();
    if (res.status === 200 && res2.status === 200) {
      setTimeline(res.data.timeline);
      setCampaign(res2.data.campaign);
      setReportName(res2.data.report_name);
      setData({
        reportTool: res2.data.reportTool,
        securityTools: [
          {
            id: 0,
            name: "No Change",
            uuid: "unchanged",
          },
          {
            id: 1,
            name: "(None)",
            uuid: "none",
          },
          ...res2.data.security_tools,
        ],
        loading: false,
      });
      setOutcomeCounts(res2.data.outcome_counts);
    }
  }, [accessToken, executedUUID, current_customer, getFindings]);

  // On Page Load
  useEffect(() => {
    if (executedUUID) {
      getCampaignDetails();
    }
  }, [current_customer, accessToken, getCampaignDetails, executedUUID, reload]);

  useEffect(() => {
    setFindings(finding_r || []);
  }, [finding_r]);

  const values = useMemo(
    () => ({
      ...data,
      campaign,
      setCampaign,
      timeline,
      setTimeline,
      outcomeCounts,
      setOutcomeCounts,
      reportName,
      setReportName,
      executedUUID,
      findings,
      getFindings,
      missedThreholds,
      setMissedThresholds,
      refresh,
      setRefresh,
      modifiedThreshold,
      setModifiedThreshold,
      reload,
      setReload,
      setData,
      options,
    }),
    [
      data,
      executedUUID,
      findings,
      missedThreholds,
      modifiedThreshold,
      refresh,
      reload,
      timeline,
      campaign,
      outcomeCounts,
      reportName,
      getFindings,
      options,
    ]
  );

  return (
    <CampaignContext.Provider value={values}>
      {children}
    </CampaignContext.Provider>
  );
};

export default CampaignDetailsProvider;
