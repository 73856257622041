import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import React from "react";
import { FaDownload } from "react-icons/fa";
import FileSelect from "../../../FileSelect/FileSelect";

const SimulationDetailsForm = ({
  formData,
  setFormData,
  jsonString,
  platforms,
  simUUID,
  categories,
  OpenJsonInNewTab,
  OpenJsonInNewTabWithDownload,
  downloadFile,
  handleCategoryChange,
  handlePlatformChange,
  checkingJson,
  handleJSONUpload,
  renderJsonStatus,
  type,
}) => {
  return (
    <Stack spacing={1.5}>
      {/* Name */}
      <TextField
        placeholder="Name"
        label="Name"
        type="text"
        required
        value={formData.name}
        onChange={(e) =>
          setFormData((data) => ({ ...data, name: e.target.value }))
        }
      />
      {/* Description */}
      <TextField
        placeholder="Description"
        label="Description"
        type="text"
        multiline
        required
        value={formData.description}
        onChange={(e) =>
          setFormData((data) => ({ ...data, description: e.target.value }))
        }
        InputLabelProps={{
          shrink: true,
        }}
      />
      {/* Current JSON File */}
      {type === "edit" && (
        <>
          <Stack spacing={1} direction="row">
            <Typography sx={{ fontWeight: "bold" }}>Current JSON:</Typography>
            <Tooltip title="Open Json in new Tab">
              <Typography
                className="text-links"
                onClick={() => OpenJsonInNewTab(jsonString)}
              >
                {formData.json_file}
              </Typography>
            </Tooltip>
            <Tooltip title="Download JSON">
              <IconButton
                onClick={() =>
                  OpenJsonInNewTabWithDownload(
                    jsonString,
                    `${formData.name}.json`
                  )
                }
              >
                <FaDownload size={15} />
              </IconButton>
            </Tooltip>
          </Stack>
          <Stack spacing={1} direction="row">
            <Typography sx={{ fontWeight: "bold" }}>Current Zip:</Typography>
            <Tooltip title="Download Zip File">
              <Typography
                className="text-links"
                onClick={() => downloadFile(simUUID)}
              >
                {formData.zip_file}
              </Typography>
            </Tooltip>
          </Stack>
        </>
      )}
      {type === "add" && (
        <>
          {/* Json File upload */}
          <div>
            <TextField
              value={formData.json_file?.name}
              label={"Upload JSON"}
              required
              fullWidth
              InputProps={{
                fullWidth: true,
                readOnly: true,
                startAdornment: (
                  <Button
                    variant="outlined"
                    size="small"
                    fullWidth
                    component="label"
                    sx={{ mr: 1 }}
                  >
                    {"CHOOSE FILE"}
                    <input
                      type="file"
                      accept=".json"
                      onChange={handleJSONUpload}
                      hidden
                    />
                  </Button>
                ),
              }}
            />
            {renderJsonStatus(checkingJson)}
          </div>

          {/* Zip File Select */}
          <FileSelect
            label="Upload Zip"
            formKey="zip_file"
            accept=".zip,.ZIP"
            setFormData={setFormData}
          />
        </>
      )}

      {/* Category */}
      <FormControl fullWidth>
        <InputLabel>Category</InputLabel>
        <Select
          value={formData.category}
          label="Category"
          onChange={handleCategoryChange}
        >
          {categories.length > 0 &&
            categories.map((c) => (
              <MenuItem key={`cat-${c}`} value={c}>
                {c}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      {/* Platform */}
      {platforms.length > 0 && (
        <FormControl fullWidth>
          <InputLabel>Platform</InputLabel>
          <Select
            value={formData.platform || ""}
            label="Platform"
            onChange={handlePlatformChange}
          >
            {platforms.map((p) => (
              <MenuItem key={`plat-${p}`} value={p}>
                {p}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={formData.default}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  default: e.target.checked,
                }))
              }
            />
          }
          label="Default"
        />
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={formData.alert_validation}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  alert_validation: e.target.checked,
                }))
              }
            />
          }
          label="Alert Validation"
        />
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={formData.smv_access}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  smv_access: e.target.checked,
                }))
              }
            />
          }
          label="SMV Access"
        />
      </FormGroup>
    </Stack>
  );
};

export default SimulationDetailsForm;
