import { Paper, Stack, Typography } from "@mui/material";
import { COLORS } from "../../../../Styles/colors";
import UnifiedBuilder from "../../../UnifiedBuilder/UnifiedBuilder";

const UnifiedBuilderSettings = () => {
  return (
    <Paper sx={{ p: 3 }}>
      {/* eslint-disable-next-line */}
      <a
        id="unified-builder-section"
        style={{
          display: "block",
          position: "relative",
          top: "-100px",
          visibility: "hidden",
        }}
      />
      <Stack spacing={2}>
        <Typography color={COLORS.primary.main} variant="h4">
          Unified Builder
        </Typography>
        <Stack spacing={1}>
          <UnifiedBuilder />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default UnifiedBuilderSettings;
