import React, { useEffect, useContext, useState } from "react";
import { DataContext } from "../../../Context/dataContext";
import { getRequest } from "../../../Helpers/httpRequests";
import { Link } from "react-router-dom";
import {
  Breadcrumbs,
  Typography,
  Box,
  Stack,
  Alert,
  CircularProgress,
} from "@mui/material";
import QuickStat from "../../AlertValidation/AVOverview/Components/QuickStat/QuickStat";
import { useAppSelector } from "../../../Redux/app/hooks";
import CategoryLine from "./CategoryLine";

const ReportTrends = () => {
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );
  const [simulations, setSimulations] = useState(null);
  const [counts, setCounts] = useState({
    week: null,
    month: null,
    year: null,
    endpoints: null,
  });

  const weeklyDesc = "The amount of campaigns ran in the past week";
  const montlyDesc = "The amount of campaigns ran in the past month";
  const yearlyDesc = "The amount of campaigns ran in the past year ";

  useEffect(() => {
    const getSims = async () => {
      const res = await getRequest(`/react/api/${current_customer.uuid}/report/trends`, accessToken);
      if (res.status === 200) {
        setSimulations(res.data.simulations);
        setCounts(res.data.counts);
      }
    };

    getSims();
  }, [current_customer, accessToken]);


  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">Home</Link>
        <Typography color="text.primary">Reports</Typography>
        <Typography color="text.primary">Trends and Insights</Typography>
      </Breadcrumbs>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography component="span" fontWeight="bold" variant="h4">
          Trends and Insights
        </Typography>
      </Box>
      {!simulations && (
        <CircularProgress sx={{ display: "flex", alignItems: "center" }} />
      )}

      {simulations && (
        <Box
          sx={{ display: "flex", alignItems: "left", flexDirection: "column" }}
        >
          <Stack spacing={1} direction="row">
            <Stack direction="row" sx={{ width: "100%" }} spacing={3}>
              <QuickStat number={counts.week} text="Week" desc={weeklyDesc} />
              <QuickStat number={counts.month} text="Month" desc={montlyDesc} />
              <QuickStat number={counts.year} text="Year" desc={yearlyDesc} />
            </Stack>
          </Stack>
          <Stack spacing={1} direction="row" sx={{ marginTop: 2, height: 50 }}>
            <Alert variant="filled" severity="info" sx={{ width: "16%" }}>
              {simulations.length} simulations
            </Alert>
            <Alert variant="filled" severity="success" sx={{ width: "16%" }}>
              {counts.endpoints} Endpoints
            </Alert>
          </Stack>
          {/*<Box sx={{ mt: 2 }}>*/}
          {/*  <Typography variant="subtitle1">Select a simulation:</Typography>*/}
          {/*</Box>*/}
          {/*<Box sx={{ mt: 1 }}>*/}
          {/*  <Select*/}
          {/*    labelId="simulation-select-label"*/}
          {/*    id="simulation-select"*/}
          {/*    value={selectedSimulation}*/}
          {/*    onChange={handleSimulationChange}*/}
          {/*    sx={{ minWidth: "200px" }}*/}
          {/*  >*/}
          {/*    <MenuItem value="" disabled>*/}
          {/*      Select a simulation*/}
          {/*    </MenuItem>*/}
          {/*    {simulations.map((sim) => (*/}
          {/*      <MenuItem key={sim.uuid} value={sim.uuid}>*/}
          {/*        {sim.name}*/}
          {/*      </MenuItem>*/}
          {/*    ))}*/}
          {/*  </Select>*/}
          {/*</Box>*/}
          {/*<Box sx={{ mt: 2 }}>*/}
          {/*  <Typography variant="subtitle1">Simulation information:</Typography>*/}
          {/*</Box>*/}
          {/*<Box sx={{ mt: 1 }}>*/}
          {/*  {selectedSimulation && <TrendChart uuid={selectedSimulation} />}*/}
          {/*</Box>*/}
        {/*  Trends by Category*/}
            <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1">Trends by Category:</Typography>
            </Box>
            <Box sx={{ mt: 1 }}>
                <CategoryLine />
            </Box>
        </Box>
      )}
    </>
  );
};

export default ReportTrends;
