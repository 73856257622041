import React from "react";
import { List } from "@mui/material";
import Admin from "../Navigation/Admin";
import SMVnav from "./SMVnav";
import { useAppSelector } from "../../../Redux/app/hooks";

const SMVNavigation = ({ open }) => {
  const user = useAppSelector((state) => state.user);
  return (
    <List>
      <SMVnav open={open} />
      {user.superuser && <Admin open={open} />}
    </List>
  );
};

export default SMVNavigation;
