import { Stack, TextField, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useHttpRequest } from "../../../Hooks";
import { LoadingButton } from "@mui/lab";
export const AddEditModal = ({ data, onClose }) => {
  const [formData, setFormData] = useState({
    name: data?.name || "",
    executable: data?.executable || "",
  });

  const { fetch, loading, status } = useHttpRequest({
    method: data ? "PATCH" : "POST",
    path: data ? `/api/v2/process_name/${data.id}` : "/api/v2/process_name",
    defer: true,
    data: { ...formData, id: data?.id },
  });

  useEffect(() => {
    if (status === 200) {
      onClose();
    }
  }, [status, onClose]);

  return (
    <Stack spacing={2}>
      <Typography variant="h5">{data ? "Edit" : "Add"} Process Name</Typography>
      <TextField
        label="Name"
        value={formData.name}
        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
      />
      <TextField
        label="Process"
        value={formData.executable}
        onChange={(e) =>
          setFormData({ ...formData, executable: e.target.value })
        }
      />
      <Stack spacing={2} direction="row" sx={{ justifyContent: "center" }}>
        <LoadingButton
          onClick={() => {
            fetch();
          }}
          loading={loading}
          disabled={loading}
          variant="contained"
        >
          Submit
        </LoadingButton>
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};
