import { Checkbox, FormControlLabel, Paper, Typography } from "@mui/material";
import { subMonths, subWeeks } from "date-fns";
import { useContext, useState } from "react";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { FocusContext } from "./Provider/Context";

type Props = {};

export const DateSelector = (_props: Props) => {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  } = useContext(FocusContext);
  const [selectedRange, setSelectedRange] = useState<string | null>(null);
  const { allowedMaxDays } = DateRangePicker;

  const onDateChange = (values: Date[]) => {
    setStartDate(values[0]);
    setEndDate(values[1]);
  };

  const onRangeChange = (range: string) => {
    setSelectedRange(range);
    const now = new Date();
    if (range === '1 week') {
      setStartDate(subWeeks(now, 1));
      setEndDate(now);
    } else if (range === '1 month') {
      setStartDate(subMonths(now, 1));
      setEndDate(now);
    } else if (range === '3 months') {
      setStartDate(subMonths(now, 3));
      setEndDate(now);
    }
  };

  return (
    <Paper className="self-center" elevation={3}>
      <Typography variant="h6">Select Date Range</Typography>
      <FormControlLabel
        control={<Checkbox checked={selectedRange === '1 week'} onChange={() => onRangeChange('1 week')} />}
        label="1 Week"
      />
      <FormControlLabel
        control={<Checkbox checked={selectedRange === '1 month'} onChange={() => onRangeChange('1 month')} />}
        label="1 Month"
      />
      <FormControlLabel
        control={<Checkbox checked={selectedRange === '3 months'} onChange={() => onRangeChange('3 months')} />}
        label="3 Months"
      />
      <FormControlLabel
        control={<Checkbox checked={selectedRange === 'custom'} onChange={() => onRangeChange('custom')} />}
        label="Custom"
      />
      <DateRangePicker
        size="lg"
        shouldDisableDate={allowedMaxDays(365)}
        ranges={[{ label: "All time", value: [null, null] }]}
        onChange={onDateChange}
        value={[startDate, endDate]}
        disabled={selectedRange !== 'custom'}
      />
    </Paper>
  );
};

export default DateSelector;
