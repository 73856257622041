import { Box, Divider, Breadcrumbs, Stack, Typography } from "@mui/material";
import AgreementSettings from "./Subcomponents/AgreementSettings";
import GeneralSettings from "./Subcomponents/GeneralSettings";
import ServerSettings from "./Subcomponents/ServerSettings";
import UnifiedBuilderSettings from "./Subcomponents/UnifiedBuilderSettings";
import SettingsNavList from "./Subcomponents/SettingsNavList";

const AdminSettings = () => {
  // ----- STATES && CONTEXT -----

  // ----- VARIABLES -----

  /* ! ----- FUNCTIONS ----- */

  // ----- ON PAGE LOAD -----

  return (
    <Stack spacing={2}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Administration</Typography>
        <Typography color="text.primary">Settings</Typography>
      </Breadcrumbs>

      {/* Form */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Stack direction="row" spacing={1} position="relative">
          <SettingsNavList />
          <Divider />
          <Stack width={800} spacing={3}>
            <GeneralSettings />
            <AgreementSettings />
            <ServerSettings />
            <UnifiedBuilderSettings />
          </Stack>
        </Stack>
      </Box>
      {/* empty div to add space to bottom of page. Needed for list anchor jumping */}
      <div style={{ height: "100vh" }}></div>
    </Stack>
  );
};

export default AdminSettings;
