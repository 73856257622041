import {
  Button,
  CircularProgress,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton 
} from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { FaDownload, FaSync } from "react-icons/fa";
import XLSX from "xlsx/dist/xlsx.full.min.js"; // Import xlsx library
import { useAppSelector } from "../../../Redux/app/hooks";
import { dataGridStyles } from "../../../Styles/dataGrid";
import ToolbarWithoutExport from "./ToolbarWithoutExport";

const DataGridWithStyles = ({ pointer, sx, paper, name, ...props }) => {
  // ----- Prop handling -----

  // ----- Context -----
  const darkmode = useAppSelector((state) => state.darkmode);

  // ----- States -----
  const [pageSize, setPageSize] = useState(10);

  // ----- Functions -----
  const gridStyles = () => {
    if (darkmode && pointer) {
      return dataGridStyles.darkPointer;
    } else if (darkmode && !pointer) {
      return dataGridStyles.dark;
    } else if (!darkmode && pointer) {
      return dataGridStyles.lightPointer;
    } else {
      return dataGridStyles.light;
    }
  };

  /**
   * sets the page size to be persistent using local storage using name prop
   */
  const handlePageSizeChange = (newPageSize) => {
    // set the page size first so changes look immediate to user
    setPageSize(newPageSize);

    // set the page size state in the local storage next using the name passed in by prop
    if (name) localStorage.setItem(`data-grid-page-size-${name}`, newPageSize);
  };

  // ----- Variables -----
  const styles = gridStyles();

  // Function to export data to Excel
  const exportToExcel = () => {
    // Filter out the "id" column from the columns array
    const filteredColumns = props.columns.filter(
      (column) => column.field !== "id"
    );

    // Map over the rows to include only the necessary fields
    const excelData = props.rows.map((row) => {
      const rowData = {};
      filteredColumns.forEach((column) => {
        rowData[column.headerName] = row[column.field];
      });
      return rowData;
    });

    // Construct the Excel sheet
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: fileType });
    const fileName = `${name}_data${fileExtension}`;

    // Trigger the file download
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();

    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    // attempt to get page size from localSorage
    if (name && localStorage.getItem(`data-grid-page-size-${name}`) !== null) {
      const storedPageSize = parseInt(
        localStorage.getItem(`data-grid-page-size-${name}`)
      );
      setPageSize(storedPageSize);
    }
  }, [name]);

  const renderToolbar = () => {
    return (
      <GridToolbarContainer>
        <Stack
          direction="row"
          justifyContent="flex-start"
          sx={{ width: "100%" }}
        >
          {props.columnBar && <GridToolbarColumnsButton/>}
          {props.filter && <GridToolbarFilterButton />}
          {props.excel && (
            <Tooltip title="Export to Excel">
              <Button
                onClick={exportToExcel}
                startIcon={<FaDownload size={15} />}
              >
                <Typography sx={{ fontSize: 12 }}>
                  Export
                </Typography>
              </Button>
            </Tooltip>
          )}
          {props.refreshing !== undefined && (
            <Tooltip title="Refresh Table">
              <Button
                disabled={props.refreshing}
                startIcon={
                  props.refreshing ? (
                    <CircularProgress size={15} />
                  ) : (
                    <FaSync size={15} />
                  )
                }
                onClick={props.handleRefresh}
              >
                <Typography sx={{ fontSize: 12 }}>
                  {!props.refreshing ? "Refresh" : "Refreshing"}
                </Typography>
              </Button>
            </Tooltip>
          )}
        </Stack>
      </GridToolbarContainer>
    );
  };

  if (paper) {
    return (
      <>
        <Paper>
          <DataGrid
            sx={{ ...styles, sx }}
            pageSize={pageSize}
            onPageSizeChange={handlePageSizeChange}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            components={{
              Toolbar: renderToolbar,
              ...(props.noExport ? { Toolbar: ToolbarWithoutExport } : {}),
            }}
            {...props}
          />
        </Paper>
      </>
    );
  } else {
    return (
      <DataGrid
        sx={{ ...styles, sx }}
        pageSize={pageSize}
        onPageSizeChange={handlePageSizeChange}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        components={{
          Toolbar: renderToolbar,
          ...(props.noExport ? { Toolbar: ToolbarWithoutExport } : {}),
        }}
        {...props}
      />
    );
  }
};

DataGridWithStyles.propTypes = {
  name: PropTypes.string.isRequired,
};

DataGridWithStyles.defaultProps = {
  pointer: false,
  sx: {},
  paper: false,
  noExport: false,
};

export default DataGridWithStyles;
