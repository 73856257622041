import { useState } from "react";
import {
  Button,
  Stack,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from "@mui/material";

const AddSecTool = (props) => {
  const { securityToolOptions, addItemToChain } = props;

  const tools = securityToolOptions.securityTools;
  const [selectedTool, setSelectedTool] = useState("");

  const handleSubmit = (e) => {
    addItemToChain(selectedTool);
  };

  const handleChange = (event) => {
    setSelectedTool(event.target.value);
  };

  return (
    <Stack direction="row" width="100%" spacing={2}>
      <FormControl fullWidth>
        <InputLabel id="sec-tool-select-label">Security Tool</InputLabel>
        <Select
          labelId="sec-tool-select-label"
          id="sec-tool-select"
          value={selectedTool}
          label="Security Tool"
          onChange={handleChange}
        >
          {tools.map((tool) => (
            <MenuItem key={tool.uuid} value={tool.uuid}>
              {tool.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button onClick={handleSubmit} variant="contained">
        Add
      </Button>
    </Stack>
  );
};

export default AddSecTool;
