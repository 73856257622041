import React from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import TextLink from "../../../Components/TextLink/TextLink";

const BinaryEXE = () => {
  // ----- Context -----

  // ----- States -----

  // ----- Variable -----

  // ----- Functions -----

  // ----- On page load -----

  return (
    <Stack spacing={3}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <TextLink url="/support/help">Support</TextLink>
        <Typography color="text.primary">Binary Executable</Typography>
      </Breadcrumbs>

      {/* Page Title */}
      <Typography variant="h3">
        Creating a Campaign - Binary Executable
      </Typography>
      <div>
        <ol>
          <li>
            <div>
              Login to the BlindSPOT platform, using your given credentials.
            </div>
          </li>
          <li>
            <div>
              Generate and start running a BlindSPOT agent on your system. See
              the{" "}
              <TextLink display="inline" url="/support/Agent">
                Generating an Agent
              </TextLink>{" "}
              tutorial.
            </div>
          </li>
          <li>
            <div>
              <div>
                Click the{" "}
                <TextLink display="inline" url="/simulations/list">
                  Simulation Library
                </TextLink>{" "}
                option in the left-hand menu options sidebar.
              </div>
              <img
                src="../../../static/img/help/SimLibraryRedo.png"
                alt="Simulation Library Screenshot"
              />
            </div>
            <br />
          </li>
          <li>
            <div>
              <div>
                Once on the{" "}
                <TextLink display="inline" url="/simulations/list">
                  Simulations Library
                </TextLink>{" "}
                page, you will notice that there are several options you have
                available to you. Start by clicking on the simulation you'd like
                to run.
              </div>
              <img
                src="../../../static/img/help/simulationExample.png"
                alt="Simulations Example"
              />
            </div>
            <br />
          </li>
          <li>
            <div>
              <div>
                Your screen schould look like this, but with your{" "}
                <b>chosen simulation name at the top</b>. In this case, we are
                using the Conti simulation.
              </div>
              <img
                src="../../../static/img/help/ContiExample.png"
                alt="Conti simulation screenshot"
              />
            </div>
            <br />
          </li>
          <li>
            <div>
              <div>
                Now scroll down until the left-hand side of the screen shows
                both a 'Microsoft Windows 64-bit - Binary Executable' and a
                'Microsoft Windows 32-bit - Binary Executable'
              </div>
              <img
                src="../../../static/img/help/BinaryExeExample.png"
                alt="Binary Executable options"
              />
            </div>
            <br />
          </li>
          <li>
            <div>
              <div>
                To start your new campaign, hit the blue <b>'New Campaign'</b>{" "}
                button, found adjacent to the <b>'Binary Executable'</b> title.
              </div>
              <img
                src="../../../static/img/help/BinaryCampaignCreateExample.png"
                alt="Binary Campaign create example"
              />
            </div>
            <br />
          </li>
          <li>
            <div>
              <div>
                Once the 'New Campaign' button has been selected you should now
                be on the <b>'Create New Campaign'</b> page. Here, everything
                should auto-populate, <b>EXCEPT the name</b>, which you will
                need to manually enter.
              </div>
              <img
                src="../../../static/img/help/newCampaignForm.png"
                alt="New Campaign Form"
              />
            </div>
            <br />
          </li>
          <li>
            <div>
              <div>
                You will also need to select which agent you want the campaign
                to run on.
              </div>
              <img
                src="../../../static/img/help/agentselection.png"
                alt="Agent Selection"
              />
            </div>
            <br />
          </li>
          <li>
            <div>
              <div>
                Now just push the <b>'submit'</b> button.
              </div>
              <img
                src="../../../static/img/help/submitbutton.png"
                alt="Submit button example"
              />
            </div>
            <br />
          </li>
          <li>
            <div>
              <div>
                The campaign will be <b>downloaded</b> and <b>run</b> as the
                BlindSPOT agent checks in with the BlindSPOT service.
              </div>
              <img
                src="../../../static/img/help/campaignstartexample.png"
                alt="campaign start example"
              />
            </div>
            <br />
          </li>
          <li>
            <div>
              <div>
                As the BlindSPOT agent executes the campaign, the BlindSPOT
                service will mark the campaign as <b>in progress</b>. The agent
                will track the running ransomware campaign and will alert the
                BlindSPOT service when execution has{" "}
                <b>finished, then scoring can begin</b>.
              </div>
              <img
                src="../../../static/img/help/campaigninprogress.png"
                alt="Campaign in progress example screenshot"
              />
            </div>
            <br />
          </li>
        </ol>
      </div>
    </Stack>
  );
};

export default BinaryEXE;
