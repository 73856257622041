import React, { useEffect, useContext, useState } from "react";
import { DataContext } from "../../../../Context/dataContext";
import { getRequest } from "../../../../Helpers/httpRequests";
import {
  Breadcrumbs,
  Typography,
  Box,
  Stack,
  Alert,
  Collapse,
  IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { COLORS } from "../../../../Styles/colors";

import {
  FaTimesCircle,
  FaChartArea,
  FaCheckCircle,
  FaSpinner,
  FaHourglass,
} from "react-icons/fa";
import "./spinner.css";
import DataGridWithStyles from "../../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import { useAppSelector } from "../../../../Redux/app/hooks";

const CampaignsUsage = () => {
  const [remainingOpen, setRemainingOpen] = React.useState(true);
  const [dataTableRows, setDataTableRows] = useState([]);
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );
  const [remainingCampaigns, setRemainingCampaigns] = useState([]);
  const [status, setStatus] = useState(false);
  //Variables
  const columns = [
    { field: "index", headerName: "ID", maxWidth: 60, flex: 2 },
    {
      field: "name",
      headerName: "Campaign Name",
      minWidth: 200,
      flex: 2,
      renderCell: (params) => {
        let link =
          `/${current_customer.uuid}/reporting/campaign/` + params.row.id;
        if (params.id !== params.value) {
          return <Typography>{params.row.name}</Typography>;
        }
        return <Link to={link}>{params.row.name}</Link>;
      },
    },
    {
      field: "campaign",
      headerName: "Endpoint",
      minWidth: 200,
      flex: 2,
      renderCell: (params) => {
        let link = `/${current_customer.uuid}/simulations/endpoints/detail/${params.row.endpoint_uuid}`;
        return <Link to={link}>{params.row.endpoint}</Link>;
      },
    },
    { field: "simulation", headerName: "Simulation", minWidth: 200, flex: 2 },
    {
      field: "Execution_method",
      headerName: "Execution Method",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      maxWidth: 130,
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            {params.row.status === "completed" && (
              <Stack
                direction="row"
                sx={{ justifyContent: "space-between", width: "100%" }}
              >
                <FaCheckCircle size={20} color={COLORS.success.main} />
                <Typography>Completed</Typography>
              </Stack>
            )}
            {params.row.status === "ready" && (
              <Stack
                direction="row"
                sx={{ justifyContent: "space-between", width: "100%" }}
              >
                <FaHourglass size={20} color={COLORS.primary.main} />
                <Typography>Processed</Typography>
              </Stack>
            )}
            {params.row.status === "running" && (
              <Stack
                direction="row"
                sx={{ justifyContent: "space-between", width: "100%" }}
              >
                <FaSpinner size={20} color="orange" className="spinner" />
                <Typography>Running</Typography>
              </Stack>
            )}
            {params.row.status === "failed" && (
              <Stack
                direction="row"
                sx={{ justifyContent: "space-between", width: "100%" }}
              >
                <FaTimesCircle size={20} color={COLORS.error.main} />
                <Typography>Failed</Typography>
              </Stack>
            )}
          </>
        );
      },
    },
    { field: "created", headerName: "Created", minWidth: 140, flex: 1 },
    {
      field: "score",
      headerName: "Score",
      maxWidth: 100,
      flex: 1.1,
      renderCell: (params) => {
        let color = "success";
        if (params.row.score * 100 < 50) {
          color = "error";
        } else if (params.row.score * 100 < 75) {
          color = "warning";
        }
        return (
          <div style={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
              variant="determinate"
              value={params.row.score * 100}
              size={45}
              thickness={3}
              color={color}
            />
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Typography variant="body2">
                {`${(params.row.score * 100).toFixed(0)}%`}
              </Typography>
            </div>
          </div>
        );
      },
    },
    {
      field: "report",
      headerName: "Report",
      maxWidth: 80,
      flex: 1,
      renderCell: (params) => {
        let link = `${current_customer.uuid}/reporting/campaign/${params.row.id}`;
        return params.id !== params.row.name ? (
          <Stack direction="row" marginLeft={1}>
            <Link to={link}>
              <IconButton title={params.row.name}>
                <FaChartArea />
              </IconButton>
            </Link>
          </Stack>
        ) : null;
      },
    },
  ];

  const populateDataGrid = (campaigns) => {
    const rows = campaigns.map((campaign, index) => {
      return {
        index: index + 1,
        id: campaign.uuid,
        campaign_uuid: campaign.uuid,
        endpoint_uuid: campaign.endpoint?.uuid,
        name: campaign.name,
        endpoint: campaign.endpoint_name,
        simulation: campaign.payload.name,
        Execution_method:
          campaign?.payload?.execution +
          "-" +
          campaign?.payload?.platform +
          " Method" +
          campaign?.process_injection_method +
          " PI Target: " +
          campaign?.pi_target?.name,
        status: campaign.status,
        created: moment(campaign.created).format("M/D/YY,hh:mm a"),
        score: campaign.score,
        report: "",
      };
    });
    setDataTableRows(rows);
  };

  // ----- ON PAGE LOAD -----

  useEffect(() => {
    const getEndpoints_Assess = async () => {
      const res = await getRequest(`/react/api/${current_customer.uuid}/campaigns/usage`, accessToken);
      if (res.status === 200) {
        populateDataGrid(res.data.campaigns);
        setRemainingCampaigns(res.data.remaining_campaigns);
        setStatus(true);
      }
    };

    getEndpoints_Assess();
  }, [current_customer, accessToken]);

  if (status === false) {
    return (
      <>
        <Typography align="center">
          <CircularProgress />{" "}
        </Typography>
      </>
    );
  } else {
    return (
      <Stack spacing={1}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/">Home</Link>
          <Typography color="text.primary">Reports</Typography>
          <Typography color="text.primary">Campaigns Usage</Typography>
        </Breadcrumbs>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            marginBottom: "1rem",
            alignItems: "center",
          }}
        >
          <Typography component={"span"} fontWeight={"bold"} variant="h4">
            Campaigns Usage
          </Typography>
        </Box>
        {remainingCampaigns !== null && remainingCampaigns !== "unlimited" && (
          <Collapse in={remainingOpen}>
            <Alert
              severity={remainingCampaigns < 10 ? "error" : "info"}
              variant="outlined"
              onClose={() => {
                setRemainingOpen(false);
              }}
            >
              <Typography>
                You have {remainingCampaigns} campaigns remaining.{" "}
                {remainingCampaigns < 10 &&
                  "Please contact administration for increase"}
              </Typography>
            </Alert>
          </Collapse>
        )}
        <DataGridWithStyles
          name="campaign-usage"
          paper
          autoHeight
          rows={dataTableRows}
          columns={columns}
        />
      </Stack>
    );
  }
};

export default CampaignsUsage;
