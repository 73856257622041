import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { DataContext } from "../../../../Context/dataContext";
import { postRequest } from "../../../../Helpers/httpRequests";
import CustomerAccesses from "../../../Dropdowns/CustomerAccesses/CustomerAccesses";

const AdminEditUserForm = ({ user, setOpen }) => {
  // ----- CONTEXT -----
  const { accessToken } = useContext(DataContext);
  // const customer = useAppSelector(
  //   (state) => state.customer,
  // );


  // ----- STATES -----

  /**
   * Object to hold the values of each input in form.
   */
  const [formData, setFormData] = useState({
    role: user.role,
    first: user.first,
    last: user.last,
    customer_access: user.customer_access,
    current_customer_view: user.current_customer_view,
    superuser: user.superuser,
  });

  /**
   * Used for handling the props/children of <LoadingButton>
   */
  const [loadingBtn, setLoadingBtn] = useState({
    loading: false,
    color: "primary",
    text: "Submit",
    helperText: "",
    helperTextColor: "green",
  });

  // ---- Variables ------

  // ----- Functions -----

  /**
   * Handles the form submission.
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    // prevent page reload on submit
    e.preventDefault();

    // set submit btn to loading
    setLoadingBtn((prev) => ({ ...prev, loading: true }));

    const res = await postRequest(
      `/react/api/admin/user/edit/${user.uuid}`,
      accessToken,
      formData,
      true
    );
    setLoadingBtn((prev) => ({ ...prev, loading: false }));

    // send form response back as 201 (created)
    if (res.status === 200) {
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Success",
        color: "success",
        helperText: "Success",
        helperTextColor: "#2e7d32", // green
      }));

      /**
       * If this form is contained in a modal, you can pass the setOpen state to this component.
       * This will wait 1.5 seconds and then close the modal
       * Add 'open' state to this components parent useEffect dependencies array to have your page reload the data.
       */
      if (setOpen !== undefined) setOpen(false);
    } else {
      // handle errors here
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Error - Try again",
        color: "error",
        helperText: "Form submission failed",
        helperTextColor: "#d32f2f", // red
      }));
      console.log(
        "%cerror AdminEditUserForm.jsx handleSubmit()",
        "color: red; display: block; width: 100%;",
        "Failed to submit form"
      );
    }
  };

  return (
    <Box>
      {/* Form Header */}
      <Typography variant="h5">Edit Blindspot User</Typography>

      <Divider style={{ marginBottom: "1rem" }} />

      {/* FORM */}
      <form onSubmit={(e) => handleSubmit(e)}>
        <Stack direction="column" spacing={2}>
          {/* Role */}
          <FormControl required margin="none">
            <InputLabel id="role-select-label">Role</InputLabel>
            <Select
              labelId="role-select-label"
              id="role-select"
              value={formData.role}
              label="Role *"
              required={true}
              onChange={(e) =>
                setFormData((data) => ({
                  ...data,
                  role: e.target.value,
                }))
              }
            >
              <MenuItem value="Analyst">Analyst</MenuItem>
              <MenuItem value="View_Only">View Only</MenuItem>
              <MenuItem value="Provider_Admin">Provider Admin</MenuItem>
            </Select>
          </FormControl>

          {/* First */}
          <TextField
            placeholder="First"
            label="First"
            type="text"
            value={formData.first}
            onChange={(e) =>
              setFormData((data) => ({ ...data, first: e.target.value }))
            }
          />

          {/* Last */}
          <TextField
            placeholder="Last"
            label="Last"
            type="text"
            value={formData.last}
            onChange={(e) =>
              setFormData((data) => ({ ...data, last: e.target.value }))
            }
          />

          {/* Customer Access */}
          <CustomerAccesses formData={formData} setFormData={setFormData} />

          {/* Current Customer View */}
          <TextField
            placeholder="Current Customer View"
            label="Current Customer View"
            type="text"
            required
            value={formData.current_customer_view}
            onChange={(e) =>
              setFormData((data) => ({
                ...data,
                current_customer_view: e.target.value,
              }))
            }
          />

          {/* Superuser */}
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.superuser}
                  onChange={() =>
                    setFormData((data) => ({
                      ...data,
                      superuser: !data.superuser,
                    }))
                  }
                />
              }
              label="Superuser"
            />
          </FormControl>

          {/* helper text */}
          <Typography color={loadingBtn.helperTextColor} variant="subtitle2">
            {loadingBtn.helperText}
          </Typography>

          {/* Submit Button */}
          <LoadingButton
            loading={loadingBtn.loading}
            color={loadingBtn.color}
            type="submit"
            variant="contained"
          >
            {loadingBtn.text}
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
};

AdminEditUserForm.propTypes = {
  setOpen: PropTypes.func,
  modal: PropTypes.bool,
};

export default AdminEditUserForm;
