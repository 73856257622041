import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import TextButton from "../../../../Components/Buttons/TextButton";
import DataGridWithStyles from "../../../../Components/DataGrid/DataGridWithStyles";
import FormModal from "../../../../Components/Modals/FormModal";
import { useHttpRequest } from "../../../../Hooks";
import { useAppSelector } from "../../../../Redux/app/hooks";
import { COLORS } from "../../../../Styles/colors";
import { ProjectTestPlanModal } from "./submodules/ProjectTestPlanModal";

const ProjectTestPlan = () => {
  const role = useAppSelector((state) => state.user.role);
  
  const [deleteID, setDeleteID] = useState(-1);
  const [testPlanModal, setTestPlanModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteName, setDeleteName] = useState("");
  const [dataTableRows, setDataTableRows] = useState([]);
  const [selectedTestPlan, setSelectedTestPlan] = useState();

  let { response: testplans, fetch } = useHttpRequest({
    method: "GET",
    path: "/api/v2/project-test-plans",
  });

  let {
    status: delRes,
    fetch: deletePlan,
    loading: delLoading,
  } = useHttpRequest({
    method: "DELETE",
    path: `/api/v2/project-test-plans/${deleteID}`,
    defer: true,
  });

  const { response: simulations } = useHttpRequest({
    method: "GET",
    path: "/api/v2/simulations",
  });

  useEffect(() => {
    if (delRes === 204) {
      setDeleteModal(false);
      fetch();
    }
  }, [delRes, delLoading, fetch]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      maxWidth: 400,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Typography
              className="text-links"
              style={{
                opacity: role === "View_Only" ? 0.5 : 1,
                fontWeight: params.row.default ? "bold" : "normal",
              }}
              onClick={() => {
                if (role !== "View_Only") {
                  setTestPlanModal(true);
                  setSelectedTestPlan(
                    testplans.find((tp) => tp.id === params.row.id)
                  );
                }
              }}
            >
              {params.row.name}
            </Typography>
          </>
        );
      },
    },
    {
      field: "simulations",
      headerName: "Simulations",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        const sims = params.row.simulations || [];
        return (
          <>
            {sims.map((id) => (
              <Chip
                key={id}
                label={simulations.find((sim) => sim.id === id)?.name || ""}
                variant="outlined"
                style={{ margin: "2px" }}
              />
            ))}
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      maxWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Stack spacing={2} direction="row" title="Delete Template">
              <IconButton
                disabled={role === "View_Only"}
                style={{ opacity: role === "View_Only" ? 0.5 : 1 }}
                onClick={() => {
                  setDeleteName(params.row.name);
                  setDeleteID(params.row.id);
                  setDeleteModal(true);
                }}
              >
                <FaTrashAlt color={COLORS.error.main} />
              </IconButton>
            </Stack>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (testplans !== null) {
      const rows = testplans.map((testplan) => {
        return {
          id: testplan.id,
          name: testplan.name,
          simulations: testplan.simulations,
          actions: [],
        };
      });
      setDataTableRows(rows);
    } else {
      fetch();
    }
  }, [testplans, fetch, simulations]);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Simulations</Typography>
        <Typography color="text.primary">Projects</Typography>
        <Typography color="text.primary">Test Plans</Typography>
      </Breadcrumbs>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Typography component={"span"} fontWeight={"bold"} variant="h4">
          Test Plans
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <TextButton
            role={role}
            tooltip="Add TestPlan"
            icon={FaPlus}
            onClick={() => setTestPlanModal(true)}
          />
        </Box>
      </Box>

      <DataGridWithStyles
        name="reports-templates"
        autoHeight
        paper
        rows={dataTableRows}
        columns={columns}
      />
      {/* Delete Modal */}
      <FormModal open={deleteModal} setOpen={setDeleteModal}>
        <Box component="form">
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: 4 }}
          >
            Are you sure you want to remove template {deleteName}?
          </Typography>
          <Stack direction="column" spacing={2}>
            <Button onClick={() => deletePlan()} variant="contained">
              Yes
            </Button>
          </Stack>
        </Box>
      </FormModal>

      {/* Edit/Add modal */}
      <FormModal
        open={testPlanModal}
        setOpen={setTestPlanModal}
        onClose={() => setSelectedTestPlan(null)}
        sx={{ width: "30%" }}
      >
        <ProjectTestPlanModal
          testplan={selectedTestPlan}
          mode={selectedTestPlan ? "edit" : "add"}
          onComplete={() => {
            setTestPlanModal(false);
            setSelectedTestPlan(null);
            fetch();
          }}
          simulations={simulations}
        />
      </FormModal>
    </>
  );
};

export default ProjectTestPlan;
