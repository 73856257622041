import React, { useEffect, useState, useContext } from "react";
import {
  FormControl,
  Stack,
  TextField,
  Typography,
  Paper,
  Box,
  MenuItem,
  InputLabel,
  Select,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataContext } from "../../../../Context/dataContext";
import { getRequest, postRequest } from "../../../../Helpers/httpRequests";
import PropTypes from "prop-types";
import { useAppSelector } from "../../../../Redux/app/hooks";

const AgentProfileAddForm = (props) => {
  // ----- STATES && CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );
  const [endpoint, setEndpoint] = useState({
    name: "",
    platform: "windows-x64",
    arch: "amd64",
    sleep_time: 60,
    proxy: "",
    conf_file: "blindspot-agent.conf",
    log_file: "blindspot.log",
    sqlite_file: "blindspot-agent.db",
    safelist_check: false,
    use_proxy: "no",
    garble: false,
  });
  const [noneProxy, setNoneProxy] = useState("");
  const [proxyList, setProxyList] = useState([]);
  const [nameError, setNameError] = useState(false);
  const [beta, setBeta] = useState(false);

  // Submit Btn
  const [submitBtn, setSubmitBtn] = useState({
    color: "primary",
    loading: false,
    text: "Submit",
  });

  const handleNameChange = (e) => {
    const newValue = e.target.value;
    const regex = /^[a-zA-Z0-9-]*$/;

    if (regex.test(newValue)) {
      setNameError(false);
      setEndpoint((prevState) => ({
        ...prevState,
        name: newValue,
      }));
    } else {
      setNameError(true);
    }
  };

  // ----- FUNCTIONS -----
  const handleSubmit = async () => {
    setSubmitBtn({ ...submitBtn, loading: true });
    const data = endpoint;
    if (data.use_proxy === "no") {
      data.proxy = noneProxy;
    }
    let url;
    if (beta) {
      url = `/react/api/${current_customer.uuid}/config/agents/beta`;
    } else {
      url = `/react/api/${current_customer.uuid}/config/agents`;
    }
    const res = await postRequest(
      url,
      accessToken,
      data
    );
    setSubmitBtn({ ...submitBtn, loading: false });
    if (res.status === 200) {
      props.onFinish();
    } else {
      setSubmitBtn({
        color: "error",
        loading: false,
        text: "Error - Try again",
      });
    }
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getFormData = async () => {
      const res = await getRequest(`/react/api/${current_customer.uuid}/config/agents`, accessToken);
      if (res.status === 200) {
        try {
          setProxyList(
            res.data.proxies.filter((proxy) => proxy.name !== "None")
          );
          setNoneProxy(res.data.proxies[0].uuid);
        } catch (err) {
          console.log(
            "Error while trying to set States from getFormData()",
            err
          );
        }
      }
    };

    getFormData();
  }, [current_customer, accessToken]);

  return (
    <>
      <Typography fontWeight={"bold"} variant="h4">
        Create New Agent
      </Typography>

      <Box sx={{ display: "flex" }}>
        <Box
          className="left-box"
          sx={{ flex: 1, paddingRight: 1, marginTop: 1 }}
        >
          <Paper sx={{ padding: 2 }}>
            <Stack spacing={1.5}>
              {/* Name */}
              <TextField
                type="text"
                label="Name"
                helperText="Only alphanumeric and '-' are allowed."
                error={nameError}
                onChange={handleNameChange}
              />

              {/* Platform */}
              <FormControl>
                <InputLabel id="demo-simple-select-label">Platform</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Platfrom"
                  defaultValue={"windows-x64"}
                  onChange={(e) =>
                    setEndpoint((prevState) => ({
                      ...prevState,
                      platform: e.target.value,
                    }))
                  }
                >
                  <MenuItem value={"windows-x64"}>Windows</MenuItem>
                  <MenuItem value={"linux-x64"}>Linux</MenuItem>
                </Select>
              </FormControl>

              {/* Arch */}
              <FormControl>
                <InputLabel id="demo-simple-select-label">
                  Architecture
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Architecture"
                  defaultValue={"amd64"}
                  onChange={(e) =>
                    setEndpoint((prevState) => ({
                      ...prevState,
                      arch: e.target.value,
                    }))
                  }
                >
                  <MenuItem value={"amd64"}>amd64</MenuItem>
                  <MenuItem value={"386"}>386</MenuItem>
                </Select>
              </FormControl>
              {/* Sleeptime  */}
              <TextField
                type="number"
                label="Sleeptime"
                helperText=""
                defaultValue={60}
                onChange={(e) =>
                  setEndpoint((prevState) => ({
                    ...prevState,
                    sleep_time: e.target.value,
                  }))
                }
              />

              {/* Config File  */}
              {/* <TextField
                type="text"
                label="Config File"
                helperText=""
                defaultValue={"blindspot-agent.conf"}
                onChange={(e) =>
                  setEndpoint((prevState) => ({
                    ...prevState,
                    conf_file: e.target.value,
                  }))
                }
              /> */}

              {/* Log File  */}
              {/* <FormControl>
                <TextField
                  type="text"
                  label="Log file"
                  helperText=""
                  defaultValue={"blindspot.log"}
                  onChange={(e) =>
                    setEndpoint((prevState) => ({
                      ...prevState,
                      log_file: e.target.value,
                    }))
                  }
                />
              </FormControl> */}

              {/* Sqlite file  */}
              {/* <TextField
                type="text"
                label="Sqlite File"
                helperText=""
                defaultValue={"blindspot-agent.db"}
                onChange={(e) =>
                  setEndpoint((prevState) => ({
                    ...prevState,
                    sqlite_file: e.target.value,
                  }))
                }
              /> */}

              {proxyList.length > 0 ? (
                <FormControl>
                  <InputLabel id="demo-simple-select-label">
                    Use Proxy
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Use Proxy"
                    defaultValue={"no"}
                    onChange={(e) =>
                      setEndpoint((prevState) => ({
                        ...prevState,
                        use_proxy: e.target.value,
                      }))
                    }
                  >
                    <MenuItem value={"no"}>No</MenuItem>
                    <MenuItem value={"yes"}>Yes</MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <FormControl disabled>
                  <InputLabel id="demo-simple-select-label">
                    Use Proxy
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Use Proxy"
                    defaultValue={"no"}
                    onChange={(e) =>
                      setEndpoint((prevState) => ({
                        ...prevState,
                        use_proxy: e.target.value,
                      }))
                    }
                  >
                    <MenuItem value={"no"}>No</MenuItem>
                    <MenuItem value={"yes"} disabled>
                      Yes
                    </MenuItem>
                  </Select>
                  <Typography variant="body2">No proxies available</Typography>
                </FormControl>
              )}

              {endpoint.use_proxy === "yes" ? (
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Proxy</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Proxy"
                    value={endpoint.proxy}
                    onChange={(e) => {
                      setEndpoint((prevState) => ({
                        ...prevState,
                        proxy: e.target.value,
                      }));
                    }}
                  >
                    {proxyList.map((proxy) => (
                      <MenuItem value={proxy.uuid} key={proxy.uuid}>
                        {proxy.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : null}

              {/* Garble  */}
              <FormControl>
                <Typography>
                  Obfuscate Agent
                  <Checkbox
                    sx={{ marginLeft: 5 }}
                    defaultChecked={false}
                    onChange={(e) =>
                      setEndpoint((prevState) => ({
                        ...prevState,
                        garble: e.target.value,
                      }))
                    }
                  />
                </Typography>
              </FormControl>

              {/* Beta */}
              <FormControl>
                <Typography>
                  Beta
                  <Checkbox
                    sx={{ marginLeft: 5 }}
                    defaultChecked={false}
                    onChange={(e) =>
                      setBeta(e.target.checked)
                    }
                  />
                </Typography>
              </FormControl>


              {/* Loading Button */}
              <LoadingButton
                variant="contained"
                loading={submitBtn.loading}
                onClick={handleSubmit}
                color={submitBtn.color}
                disabled={
                  nameError ||
                  (endpoint.use_proxy === "yes" && endpoint.proxy.length === 0)
                }
              >
                {submitBtn.text}
              </LoadingButton>
            </Stack>
          </Paper>
        </Box>
        <Box
          className="right-box"
          sx={{ flex: 1, paddingLeft: 3, marginTop: 1 }}
        >
          <Paper sx={{ marginBottom: 2, padding: 2, flex: 1 }}>
            <Typography
              sx={{ marginBottom: 1 }}
              fontWeight={"bold"}
              variant="h6"
            >
              Agent Build Help
            </Typography>
            The agent packages are designed to be built on-the-fly as needed.
            Each agent is compiled (and signed) for the platform you need it,
            with all of the required information to connect, no need to
            configure once you've downloaded it.
            <Typography></Typography>
            <b fontWeight={"bold"}>Name</b> - An identifier for this agent
            build.
            <Typography></Typography>
            <b fontWeight={"bold"}>Platform</b> - The Operating system you need
            the agent to run on.
            <Typography></Typography>
            <b fontWeight={"bold"}>Sleep Time</b> - Delay in seconds between
            Blindspot agent checkins (can be modified later for each individual
            running agent).
            <Typography></Typography>
            <b fontWeight={"bold"}>Proxy</b> - The Blindspot implants use the
            proxy settings of the host system by default. In the event that you
            need to specify a proxy configuration for the agent, you can select
            that profile here. Proxy profiles can be set on the proxy page.
            <Typography></Typography>
            <Typography>
              <b fontWeight={"bold"}>Obfuscate Agent</b> - Obfuscate executable
              code to get around security measures
            </Typography>
            <Typography>
              <b fontWeight={"bold"}>Use Proxy</b> - When set to "Yes",
              instructs the agent to start with the selected proxy profile (can
              be enabled once the agent is running as well).
            </Typography>
            <Typography>
              <b fontWeight={"bold"}>Beta</b> - When set, will use the beta build of the agent. This may contain new features or bug fixes that are not yet in the stable build.
            </Typography>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

AgentProfileAddForm.propTypes = {
  // simulation: PropTypes.array,
  setModalOpen: PropTypes.func.isRequired,
  onFinish: PropTypes.func,
};

export default AgentProfileAddForm;
