import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DataContext } from "../../../Context/dataContext";
import { useHttpRequest } from "../../../Hooks";
import { postRequest } from "../../../Helpers/httpRequests";
import { useAppSelector } from "../../../Redux/app/hooks";

const ServiceProviderAddUserGroups = ({ setOpen, modal = true, cxUUID }) => {
  const current_customer = useAppSelector(
    (state) => state.customer
  );
  // ----- CONTEXT -----
  const { accessToken } = useContext(DataContext);

  const { response: userGroups, fetch: getusergroups } = useHttpRequest({
    method: "GET",
    path: "/api/v2/user-groups",
  });

  const { response: customers, fetch: getCustomers } = useHttpRequest({
    method: "GET",
    path: `/react/api/${current_customer.uuid}/serviceprovider/customers`,
  });

  // ----- STATES -----
  const [formData, setFormData] = useState({
    customer: "",  // Store customer UUID here
    usergroups: [],
  });

  const [loadingBtn, setLoadingBtn] = useState({
    loading: false,
    color: "primary",
    text: "Submit",
    helperText: "",
    helperTextColor: "green",
  });

  // ---- Variables ------
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };

  // ----- Functions -----
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoadingBtn((prev) => ({ ...prev, loading: true }));

    const res = await postRequest(
      `/react/api/serviceprovider/customer/add`,
      accessToken,
      formData
    );
    setLoadingBtn((prev) => ({ ...prev, loading: false }));

    if (res.status === 202) {
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Success",
        color: "success",
        helperText: "Success",
        helperTextColor: "#2e7d32",
      }));

      if (setOpen !== undefined) {
        setTimeout(() => setOpen(false), 1500);
      }
    } else {
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Error - Try again",
        color: "error",
        helperText: "Form submission failed",
        helperTextColor: "#d32f2f",
      }));
    }
  };

  const handleUserGroupChange = (event) => {
    const {
      target: { value },
    } = event;
    setFormData((prev) => ({
      ...prev,
      usergroups: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleCustomerChange = (event) => {
    const {
      target: { value },
    } = event;
    setFormData((prev) => ({
      ...prev,
      customer: value, // Store the selected customer UUID
    }));
  };

  useEffect(() => {
    getusergroups();
    getCustomers();
  }, [getusergroups, getCustomers]);

  return (
    <Box sx={style}>
      <Typography variant="h5">Add User Group to Customer</Typography>
      <Divider style={{ marginBottom: "1rem" }} />

      <form onSubmit={handleSubmit}>
        <Stack direction="column" spacing={2} flex={1}>
          {/* Customer Selection */}
          <FormControl fullWidth>
            <InputLabel id="customer-select-label">Customer</InputLabel>
            <Select
              labelId="customer-select-label"
              id="customer-select"
              value={formData.customer}
              onChange={handleCustomerChange}
            >
              {customers?.customers?.length > 0 &&
                customers?.customers.map((customer) => (
                  <MenuItem key={customer.uuid} value={customer.uuid}>
                    {customer.display_name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          {/* User Groups */}
          <FormControl fullWidth>
            <InputLabel id="user-groups-select-label">User Groups</InputLabel>
            <Select
              labelId="user-groups-select-label"
              id="user-groups-select"
              multiple
              value={formData.usergroups}
              onChange={handleUserGroupChange}
              renderValue={(selected) =>
                selected
                  .map((id) => {
                    const group = userGroups.find((ug) => ug.id === id);
                    return group ? group.name : "";
                  })
                  .join(", ")
              }
            >
              {userGroups?.length > 0 &&
                userGroups.map((group) => (
                  <MenuItem key={group.id} value={group.id}>
                    <Checkbox
                      checked={formData.usergroups.includes(group.id)}
                    />
                    {group.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <Typography color={loadingBtn.helperTextColor} variant="subtitle2">
            {loadingBtn.helperText}
          </Typography>

          {/* Submit Button */}
          <LoadingButton
            loading={loadingBtn.loading}
            color={loadingBtn.color}
            type="submit"
            variant="contained"
          >
            {loadingBtn.text}
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
};

ServiceProviderAddUserGroups.propTypes = {
  setOpen: PropTypes.func,
  modal: PropTypes.bool,
};

export default ServiceProviderAddUserGroups;
