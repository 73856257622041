import React from "react";
import { FaDesktop, FaTimes } from "react-icons/fa";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SecurityUpdateWarningIcon from "@mui/icons-material/SecurityUpdateWarning";
import {
  Card,
  Stack,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { FaCheck } from "react-icons/fa";
import "./SecurityFlowChart.css";
import defenderImage from "./logos/defender.png";
import sentinelImage from "./logos/sentinel.png";
import crowdstrikeImage from "./logos/crowdstrike.svg";
import { COLORS } from "../../../../Styles/colors";

const SecurityFlowChart = (props) => {
  const { tools, alertInfo, alertFinding } = props;
  let machineName = "Host Machine";
  let isAvDetails = false;
  let alertPassed = false;
  if (alertInfo !== undefined) {
    machineName = alertInfo.av_campaign.machinename.host;
    isAvDetails = true;
    if (
      alertInfo.av_campaign.alert_validation.security_stack.tools.length === 1
    ) {
      alertPassed = true;
    }
    if (alertFinding?.incident_id && alertFinding?.alert_id) {
      alertPassed = true;
    }
  }
  return (
    <div className="flowchart-container">
      <Stack spacing={2} justifyContent="center" alignItems="center">
        <Stack direction="row" alignItems="center">
          <FaDesktop className="icon" />
          <Typography fontWeight="bold">{machineName}</Typography>
        </Stack>

        <ArrowDownwardIcon />

        {tools.map((tool, index) => {
          let iconImage;

          const toolToSwitch = tool.product ? tool.product : tool;

          switch (toolToSwitch) {
            case "Defender":
              iconImage = defenderImage;
              break;
            case "Sentinel":
              iconImage = sentinelImage;
              break;
            case "Crowdstrike":
              iconImage = crowdstrikeImage;
              break;
            default:
              iconImage = "none";
              break;
          }

          return (
            <Box sx={{ width: "100%" }} key={index}>
              <Stack
                spacing={2}
                justifyContent="center"
                alignItems="center"
                mb={2}
              >
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  sx={{
                    border:
                      isAvDetails &&
                      (alertPassed
                        ? `2px solid ${COLORS.success.main}`
                        : `2px solid ${COLORS.error.main}`),
                    width: "100%",
                    p: isAvDetails && 2,
                    borderRadius: "5px",
                  }}
                >
                  <Grid
                    item
                    sm={isAvDetails ? 4 : 12}
                    md={isAvDetails ? 4 : 12}
                    lg={isAvDetails ? 4 : 12}
                  >
                    <Card sx={{ display: "flex", width: "100%" }}>
                      <CardContent>
                        <Typography>{tool.name}</Typography>
                      </CardContent>
                      <CardMedia
                        component="img"
                        image={iconImage}
                        sx={{ height: 100 }}
                      />
                    </Card>
                  </Grid>

                  <Grid item sm={8} md={8} lg={8}>
                    {alertFinding ? (
                      <Grid container>
                        {(alertFinding?.incident_id &&
                          alertFinding?.alert_id) ||
                        (alertInfo.av_campaign.alert_validation.security_stack
                          .tools.length === 1 &&
                          alertFinding?.alert_id) ? (
                          <Grid item sm={12} md={12} lg={12}>
                            <FaCheck color={COLORS.success.main} size={40} />
                          </Grid>
                        ) : (
                          <Grid item sm={12} md={12} lg={12}>
                            <FaTimes color={COLORS.error.main} size={40} />
                          </Grid>
                        )}
                        {tool.product === "Defender" && (
                          <>
                            <Grid
                              sx={{ fontWeight: "bold" }}
                              item
                              sm={2}
                              md={2}
                              lg={2}
                            >
                              Alert ID:
                            </Grid>
                            <Grid
                              item
                              sm={10}
                              md={10}
                              lg={10}
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {alertFinding?.alert_id}
                            </Grid>
                          </>
                        )}

                        {tool.product === "Sentinel" && (
                          <>
                            <Grid
                              sx={{ fontWeight: "bold" }}
                              item
                              sm={3}
                              md={3}
                              lg={3}
                            >
                              Incident ID:
                            </Grid>
                            <Grid
                              item
                              sm={9}
                              md={9}
                              lg={9}
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {alertFinding?.incident_id}
                            </Grid>
                          </>
                        )}
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>

                <ArrowDownwardIcon />
              </Stack>
            </Box>
          );
        })}
        <Stack direction="row">
          <SecurityUpdateWarningIcon fontSize="large" />
          <Typography fontWeight="bold"> SOC Alert</Typography>
        </Stack>
      </Stack>
    </div>
  );
};

export default SecurityFlowChart;
