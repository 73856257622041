import React, { useContext, useState } from "react";
import { Breadcrumbs, Typography, TextField, IconButton } from "@mui/material";
import { Stack } from "@mui/system";
import { postRequest } from "../../../Helpers/httpRequests";
import { DataContext } from "../../../Context/dataContext";
import Feature from "./Feature";
import { FaMinus, FaPlus } from "react-icons/fa";
import { COLORS } from "../../../Styles/colors";
import { LoadingButton } from "@mui/lab";
import Fix from "./Fix";
import { toast } from "react-toastify";

const AdminNewRelease = () => {
  // ----- Context -----
  const { accessToken } = useContext(DataContext);

  // ----- States -----
  const [features, setFeatures] = useState([
    {
      name: "",
      description: "",
      file: null,
    },
  ]);
  const [numFeatures, setNumFeatures] = useState(1);
  const [fixes, setFixes] = useState([""]);
  const [numFixes, setNumFixes] = useState(1);

  // ----- Variable -----

  // ----- Functions -----

  const addFeature = () => {
    setFeatures((prev) => [
      ...prev,
      {
        name: "",
        description: "",
        file: null,
      },
    ]);
    setNumFeatures((prev) => parseInt(prev + 1));
  };

  const removeFeature = () => {
    if (features.length > 0) {
      setFeatures((prev) => {
        let newArray = Array.from(prev);
        newArray.pop();
        return newArray;
      });
      setNumFeatures((prev) => parseInt(prev - 1));
    }
  };

  const handleNumFeaturesChange = (e) => {
    if (e.target.value > -1) {
      setNumFeatures(e.target.value);
    }
  };

  const handleNumFixesChange = (e) => {
    if (e.target.value > -1) {
      setNumFixes(e.target.value);
    }
  };

  const handleFeatureNumSubmits = (e) => {
    if (e.key === "Enter" && numFeatures !== features.length) {
      if (numFeatures === 0) {
        setFeatures([]);
      } else {
        if (numFeatures > features.length) {
          const newFeatues = new Array(numFeatures - features.length).fill({
            name: "",
            description: "",
            file: null,
          });
          setFeatures((prev) => [...prev, ...newFeatues]);
        } else {
          let copyOfFeatures = features;
          while (parseInt(numFeatures) < copyOfFeatures.length)
            copyOfFeatures.pop();
          setNumFeatures(parseInt(e.target.value));
          setFeatures(copyOfFeatures);
        }
      }
    }
  };

  const handleFixNumSubmits = (e) => {
    if (e.key === "Enter" && numFixes !== fixes.length) {
      if (numFixes === 0) {
        setFixes([]);
      } else {
        if (numFixes > fixes.length) {
          const newFixes = new Array(numFixes - fixes.length).fill("");
          setFixes((prev) => [...prev, ...newFixes]);
        } else {
          let copyOfFixes = fixes;
          while (parseInt(numFixes) < copyOfFixes.length) copyOfFixes.pop();
          setNumFixes(parseInt(e.target.value));
          setFixes(copyOfFixes);
        }
      }
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleSubmit = async () => {
    let preFeatures = features;
    for (let i = 0; i < preFeatures.length; i++) {
      if (preFeatures[i].file) {
        preFeatures[i].file = await toBase64(preFeatures[i].file);
      }
    }
    const data = { features, fixes };
    const res = await postRequest(
      "/react/api/admin/new-release",
      accessToken,
      data,
      true
    );
    if (res.status === 201) {
      toast.success("Success!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Failed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // ----- On page load -----

  return (
    <Stack spacing={3}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Administration</Typography>
        <Typography color="text.primary">New Release</Typography>
      </Breadcrumbs>

      {/* Page Title */}

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          maxWidth: 1300,
        }}
      >
        <Typography variant="h3">New Release</Typography>
        <LoadingButton onClick={handleSubmit} variant="contained">
          Submit
        </LoadingButton>
      </div>

      <Stack direction="row" sx={{ width: "100%", maxWidth: 1300 }}>
        <TextField
          label="Number of Features"
          sx={{ width: "50%", mr: 3 }}
          value={numFeatures}
          onChange={handleNumFeaturesChange}
          onKeyDown={handleFeatureNumSubmits}
          type="number"
        />
        <TextField
          label="Number of Fixes"
          sx={{ width: "50%" }}
          value={numFixes}
          onChange={handleNumFixesChange}
          onKeyDown={handleFixNumSubmits}
          type="number"
        />
      </Stack>

      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", maxWidth: 1300 }}
      >
        {/* Left Side */}
        <Stack
          direction="column"
          spacing={2}
          sx={{ alignItems: "center", mr: 3, width: "50%" }}
        >
          {/* Features */}
          {features.length > 0 &&
            features.map((_feature, index) => (
              <Feature
                features={features}
                setFeatures={setFeatures}
                index={index}
                key={`feature-${index}`}
              />
            ))}

          {/* Add and Remove Buttons */}
          <Stack direction="row" spacing={2}>
            <IconButton
              sx={{ backgroundColor: "rgba(0,0,0,0.2)" }}
              onClick={removeFeature}
            >
              <FaMinus color={COLORS.error.main} />
            </IconButton>
            <IconButton
              sx={{ backgroundColor: "rgba(0,0,0,0.2)" }}
              onClick={addFeature}
            >
              <FaPlus color={COLORS.primary.main} />
            </IconButton>
          </Stack>
        </Stack>

        {/* Right Side */}
        <Stack
          direction="column"
          spacing={2}
          sx={{ alignItems: "center", width: "50%" }}
        >
          {fixes.length > 0 &&
            fixes.map((fix, index) => (
              <Fix
                fixes={fixes}
                setFixes={setFixes}
                index={index}
                key={`fix-${index}`}
              />
            ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AdminNewRelease;
