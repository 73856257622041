import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Auth0ProviderWithRedirectCallback from "./Auth0/auth0-provider-with-history";
import { DataContext } from "./Context/dataContext";
import Profile from "./Pages/Profile";
import SiteShell from "./Pages/SiteShell";

import { useEnvironment } from "./Hooks";
import LandingPage from "./Pages/LandingPage";
import Maintenance from "./Pages/Maintenance/Maintenance";
import { useAppSelector } from "./Redux/app/hooks";
import CustomerRoutes from "./Routes/CustomerRoutes";
// import Test from "./Pages/Test/Test";

function App() {
  const [accessToken, setAccessToken] = useState("");
  const [agreements, setAgreements] = useState({ eula: false, privacy: false });
  const [logo, setLogo] = useState(null);
  const { isMAINTENANCE } = useEnvironment();
  const darkmode = useAppSelector((state) => state.darkmode);

  const theme = createTheme({
    palette: {
      mode: darkmode ? "dark" : "light",
      background: {
        default: darkmode ? "#121212" : "#F3F7F9",
      },
    },
  });

  if (isMAINTENANCE) {
    return <Maintenance />;
  } else {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Auth0ProviderWithRedirectCallback>
            <DataContext.Provider
              value={{
                accessToken,
                setAccessToken,
                agreements,
                setAgreements,
                logo,
                setLogo,
              }}
            >
              <SiteShell>
                <Routes>
                  {/* For testing only comment out if you don't need */}
                  {/* <Route path="/test" element={<Test />} /> */}

                  {/* Home */}
                  <Route exact path="/" element={<LandingPage />} />

                  {/* Profile */}
                  <Route exact path="/profile" element={<Profile />} />

                  {/* Customer routes that require customerUUID in path */}
                  <Route path="/:customerUUID/*" element={<CustomerRoutes />} />
                </Routes>
              </SiteShell>
            </DataContext.Provider>
          </Auth0ProviderWithRedirectCallback>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}

export default App;
