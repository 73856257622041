import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import TextButton from "../../../Components/Buttons/TextButton";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import FormModal from "../../../Components/Modals/FormModal";
import { DataContext } from "../../../Context/dataContext";
import { getRequest, postRequest } from "../../../Helpers/httpRequests";
import { COLORS } from "../../../Styles/colors";
import ProcessInjectionTargetForm from "../../../Components/Forms/Config/ProcessInjectionTargetForm/ProcessInjectionTargetForm";
import { useAppSelector } from "../../../Redux/app/hooks";
import { GapRiskMatrix } from "./GapRiskMatrix";

const ConfigAttackSimulationSetting = () => {
  // ----- STATES && CONTEXT -----
  const [dataTableRows, setDataTableRows] = useState([]);
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector((state) => state.customer);
  const [reloadTargets, setReloadTargets] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteUUID, setDeleteUUID] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const [timeOut, setTimeOut] = useState(60);
  const [openAccordions, setOpenAccordions] = useState([false, false, false]); // PI Targets, Action Timeout, Gap Risk
  const [targetModal, setTargetModal] = useState(false);
  const [targetUUID, setTargetUUID] = useState(null);

  //Variables

  const columns = [
    {
      field: "name",
      headerName: "Name",
      maxWidth: 400,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Typography
              className="text-links"
              onClick={() => {
                setTargetUUID(params.row.id);
                setTargetModal(true);
              }}
            >
              {params.row.name}
            </Typography>
          </>
        );
      },
    },
    { field: "path", headerName: "Path", minWidth: 100, flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      maxWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Stack direction="row" spacing={2}>
              <IconButton
                onClick={() => {
                  setDeleteName(params.row.name);
                  setDeleteUUID(params.row.id);
                  setDeleteModal(true);
                }}
                title="Delete Target"
              >
                <FaTrashAlt color={COLORS.error.main} />
              </IconButton>
            </Stack>
          </>
        );
      },
    },
  ];

  //Functions

  //function used to change the timeout for the customer
  const submitTimeOut = async () => {
    const toastInfo = toast.info(
      "Attempting to save simulation action time out setting"
    );
    const res = await postRequest(
      `/react/api/${current_customer.uuid}/config/action_time_out/${timeOut}`,
      accessToken
    );
    setTimeout(() => {
      toast.dismiss(toastInfo);
    }, 100);
    if (res.status === 200) {
      toast.success("Action Time Out Changes successfully");
    } else {
      toast.error("Error changing Action Time Out");
    }
  };

  const deleteTarget = async () => {
    const toastInfo = toast.info("Attempting to delete Target");
    const res = await postRequest(
      `/react/api/${current_customer.uuid}/config/pi-targets/delete/${deleteUUID}`,
      accessToken
    );
    setTimeout(() => {
      toast.dismiss(toastInfo);
    }, 200);
    if (res.status === 200) {
      toast.success("Target deleted successfully");
      setDeleteModal(false);
      setReloadTargets((current) => !current);
    } else {
      toast.error("Error deleting target");
    }
  };

  // ----- ON PAGE LOAD -----

  useEffect(() => {
    const getTargets = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/config/pi-targets`,
        accessToken
      );
      if (res.status === 200) {
        setTimeOut(res.data.timeOut);
        populateDataGrid(res.data.targets);
      }
    };

    const populateDataGrid = (targets) => {
      const rows = targets.map((target) => {
        return {
          id: target.uuid,
          name: target.name,
          path: target.filepath,
          actions: [],
        };
      });
      setDataTableRows(rows);
    };

    getTargets();
  }, [current_customer, accessToken, reloadTargets]);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">Home</Link>
        <Typography color="text.primary">Configurations</Typography>
        <Typography color="text.primary">Attack Simulation Setting</Typography>
      </Breadcrumbs>

      <Typography component={"span"} fontWeight={"bold"} variant="h4">
        Attack Simulation Settings
      </Typography>

      {/* PI Targets */}
      <Accordion
        expanded={openAccordions[0]}
        onChange={() =>
          setOpenAccordions([
            !openAccordions[0],
            openAccordions[1],
            openAccordions[2],
          ])
        }
      >
        <AccordionSummary
          expandIcon={
            openAccordions[0] ? <ExpandMoreIcon /> : <ExpandMoreIcon />
          }
        >
          {/* Process Injection Targets */}
          <Typography component={"span"} fontWeight={"bold"} variant="h6">
            Process Injection Targets
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <TextButton
              onClick={() => {
                setTargetUUID(null);
                setTargetModal(true);
              }}
              tooltip="Add Target"
              icon={FaPlus}
            />
          </Box>
          <DataGridWithStyles
            name="config-proccess-targets"
            autoHeight
            paper
            rows={dataTableRows}
            columns={columns}
          />
        </AccordionDetails>
      </Accordion>

      {/* Action Timeout */}
      <Accordion
        expanded={openAccordions[1]}
        onChange={() =>
          setOpenAccordions([
            openAccordions[0],
            !openAccordions[1],
            openAccordions[2],
          ])
        }
      >
        <AccordionSummary
          expandIcon={
            openAccordions[1] ? <ExpandMoreIcon /> : <ExpandMoreIcon />
          }
        >
          <Typography component={"span"} fontWeight={"bold"} variant="h6">
            Action Timeout Settings
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* Action Timeout Settings */}
          <Stack sx={{ maxWidth: "30%" }} spacing={1}>
            <TextField
              type="number"
              label="Action timeout in seconds"
              value={timeOut}
              onChange={(event) => setTimeOut(event.target.value)}
              name="timeOutInput"
              inputProps={{ min: "60" }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                submitTimeOut();
              }}
            >
              Save
            </Button>
          </Stack>
        </AccordionDetails>
      </Accordion>

      {/* Gap Risk */}
      <Accordion
        expanded={openAccordions[2]}
        onChange={() =>
          setOpenAccordions([
            openAccordions[0],
            openAccordions[1],
            !openAccordions[2],
          ])
        }
      >
        <AccordionSummary
          expandIcon={
            openAccordions[2] ? <ExpandMoreIcon /> : <ExpandMoreIcon />
          }
        >
          <Typography component={"span"} fontWeight={"bold"} variant="h6">
            Gap Risk
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <GapRiskMatrix />
        </AccordionDetails>
      </Accordion>

      {/* Delete Modal */}
      <FormModal open={deleteModal} setOpen={setDeleteModal}>
        <Box component="form">
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: 4 }}
          >
            Are you sure you want to remove {deleteName}?
          </Typography>
          <Stack direction="column" spacing={2}>
            <Button onClick={() => deleteTarget()} variant="contained">
              Yes
            </Button>
          </Stack>
        </Box>
      </FormModal>

      {/*Process Injection Target Form*/}
      <FormModal
        open={targetModal}
        setOpen={setTargetModal}
        sx={{ minWidth: 550 }}
      >
        <ProcessInjectionTargetForm
          targetUUID={targetUUID}
          setTargetModal={setTargetModal}
          setReloadTargets={setReloadTargets}
        />
      </FormModal>
    </>
  );
};

export default ConfigAttackSimulationSetting;
