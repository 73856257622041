import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  Paper,
  Stack,
  Typography,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { DataContext } from "../../../Context/dataContext";
import { useAppSelector } from "../../../Redux/app/hooks";
import { LoadingButton } from "@mui/lab";
import { patchRequest } from "../../../Helpers/httpRequests";

const TemplateEditForm = ({ template, onCancel, setReloadTemplates }) => {
  const { accessToken } = useContext(DataContext);
  const user = useAppSelector((state) => state.user);
  const [formData, setFormData] = useState(null);
  const current_customer = useAppSelector(
    (state) => state.customer,
  );

  // Submit Btn
  const submitBtnColor = "primary";
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const submitBtnText = "Submit";

  useEffect(() => {
    if (template) {
      console.log("Template", template);
      setFormData({
        name: template.name,
        defaultTemplate: template.default,
        universal: template.universal,
        singleCampaign: template.single_campaign,
      });
    }
  }, [template]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setSubmitBtnLoading(true);
    let url = `/api/v2/${current_customer.uuid}/reporttemplates/${template.id}`;
    const res = await patchRequest(url, accessToken, formData);
    if (res.status === 200) {
      onCancel();
      setReloadTemplates((current) => !current);
    }
  };

  if (!formData) {
    return <CircularProgress />;
  }

  return (
    <Stack spacing={2}>
      <Typography fontWeight="bold" variant="h4">
        Edit Project Report {formData.name}
      </Typography>

      <Box sx={{ display: "flex", width: "50%" }}>
        <Box
          className="left-box"
          sx={{ flex: 1, paddingRight: 1, marginTop: 1 }}
        >
          <Paper sx={{ padding: 2 }}>
            <Stack spacing={2}>
              <TextField
                label="Template Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />

              {/* SingleCampaign */}
              {user.superuser && (
                <FormControlLabel
                  control={
                    <Checkbox
                      label="Single Campaign Template"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          singleCampaign: e.target.checked,
                        })
                      }
                      checked={formData.singleCampaign}
                    />
                  }
                  label="Single Campaign Template"
                />
              )}

              {/* Default Template */}
              {/*<FormControlLabel*/}
              {/*  control={*/}
              {/*    <Checkbox*/}
              {/*      label="Default Template"*/}
              {/*      checked={formData.defaultTemplate}*/}
              {/*      onChange={(e) => setFormData({ ...formData, defaultTemplate: e.target.checked })}*/}
              {/*    />*/}
              {/*  }*/}
              {/*  label="Default Template"*/}
              {/*/>*/}

              {/* Universal */}
              {user.superuser && (
                <FormControlLabel
                  control={
                    <Checkbox
                      label="Universal"
                      checked={formData.universal}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          universal: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Universal"
                />
              )}

              {/* Loading Button */}
              <LoadingButton
                variant="contained"
                loading={submitBtnLoading}
                onClick={handleSubmit}
                color={submitBtnColor}
              >
                {submitBtnText}
              </LoadingButton>
            </Stack>
          </Paper>
        </Box>
      </Box>
    </Stack>
  );
};

export default TemplateEditForm;

