import React, { useEffect, useContext, useState } from "react";
import { getRequest } from "../../../Helpers/httpRequests";
import { DataContext } from "../../../Context/dataContext";
import ActivityTimeline from "../ReportsCampaignDetails/ActivityTimeline";
import { useParams } from "react-router-dom";
import AttackMatrixDiagram from "../../../Components/Reporting/AttackMatrixDiagram";
import SankeyDiagram from "../../../Components/Reporting/SankeyDiagram";
import CircularProgressWithLabel from "../../../Components/CircularProgress/CircularProgressWithLabel";
import { CircularProgress, Box } from "@mui/material";
import ResultsBanner from "./ResultsBanner2";
import { useAppSelector } from "../../../Redux/app/hooks";

const ReportCharts = () => {
  // ----- Context -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  const { projectUUID } = useParams();

  const [sankey, setSankey] = useState([]);
  const [techniques, setTechniques] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [chartShown, setChartShown] = useState(0);

  // ----- States -----

  // ----- Variable -----

  const killChain = [
    { display: "Reconnaissance", identifier: "reconnaissance" },
    { display: "Resource Development", identifier: "resource-development" },
    { display: "Initial Access", identifier: "initial-access" },
    { display: "Execution", identifier: "execution" },
    { display: "Persistence", identifier: "persistence" },
    { display: "Privilege Escalation", identifier: "privilege-escalation" },
    { display: "Defense Evasion", identifier: "defense-evasion" },
    { display: "Credential Access", identifier: "credential-access" },
    { display: "Discovery", identifier: "discovery" },
    { display: "Lateral Movement", identifier: "lateral-movement" },
    { display: "Collection", identifier: "collection" },
    { display: "Command & Control", identifier: "command-and-control" },
    { display: "Exfiltration", identifier: "exfiltration" },
    { display: "Impact", identifier: "impact" },
  ];

  // ----- Functions -----

  // ----- On page load -----

  useEffect(() => {
    const getSankey = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/projectreport/${projectUUID}/sankey`,
        accessToken
      );
      if (res.status === 200) {
        setSankey(res.data.sankey);
      }
    };
    const getTechniques = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/projectreport/${projectUUID}/techniques`,
        accessToken
      );
      if (res.status === 200) {
        const techs = res.data.techniques;
        techs.map((tech) => {
          if (tech.attack_id in res.data.techOutcomes) {
            tech.outcomes = res.data.techOutcomes[tech.attack_id];
          }
          return null;
        });
        setTechniques(techs);
      }
    };

    const getCampaignData = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/projectreport/${projectUUID}/campaignData`,
        accessToken
      );
      if (res.status === 200) {
        setCampaignData(res.data.charts);
      }
    };

    getTechniques();
    getSankey();
    getCampaignData();
  }, [accessToken, current_customer, projectUUID]);

  if (techniques.length && sankey.length) {
    return (
      <div>
        <button id="next-button" onClick={() => setChartShown(chartShown + 1)}>
          next
        </button>
        <div id="ss">
          {chartShown === 0 && (
            <div
              style={{ transform: "scale(0.7)", transformOrigin: "top left" }}
            >
              <AttackMatrixDiagram
                killChain={killChain}
                techniques={techniques}
              />
            </div>
          )}
          {chartShown === 1 && (
            <SankeyDiagram sankey_data={sankey} includeUnassigned={true} />
          )}

          {campaignData.map((data, idx) => {
            return (
              chartShown === 2 + idx && (
                <div key={idx}>
                  {idx % 2 === 0 && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgressWithLabel
                        value={data.score * 100}
                        size={140}
                        thickness={3}
                        color={
                          data.score > 0.75
                            ? "success"
                            : data.score > 0.5
                            ? "warning"
                            : "error"
                        }
                      />
                      <ResultsBanner outcomeCounts={data.outcomeCounts} />
                    </Box>
                  )}
                  <ActivityTimeline timeline={data.timeline} index={idx} />
                </div>
              )
            );
          })}
        </div>
      </div>
    );
  } else {
    return <CircularProgress></CircularProgress>;
  }
};

export default ReportCharts;
