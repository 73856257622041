import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { useCallback, useState } from "react";

const CampaignRuntimeVariables = ({
  campaignRuntimeVariables,
  setCampaignRuntimeVariables,
}) => {
  const [newVariable, setNewVariable] = useState({
    name: "",
    default: "",
    description: "",
  });

  const handleVariableChange = useCallback(
    (index, field, value) => {
      const updatedVariables = campaignRuntimeVariables.map((variable, i) =>
        i === index ? { ...variable, [field]: value } : variable
      );
      setCampaignRuntimeVariables(updatedVariables);
    },
    [campaignRuntimeVariables, setCampaignRuntimeVariables]
  );

  const handleAddVariable = useCallback(() => {
    const newVar = { ...newVariable, id: campaignRuntimeVariables.length };
    setCampaignRuntimeVariables([...campaignRuntimeVariables, newVar]);
    setNewVariable({ name: "", default: "", description: "" });
  }, [campaignRuntimeVariables, newVariable, setCampaignRuntimeVariables]);

  return (
    <Box>
      <Typography variant="h5">Campaign Runtime Variables</Typography>
      {/* Variable rows */}
      {campaignRuntimeVariables.map((variable, index) => (
        <Stack direction="row" key={index} spacing={2} alignItems="center">
          <TextField
            label="Name"
            value={variable.name}
            onChange={(e) =>
              handleVariableChange(index, "name", e.target.value)
            }
          />
          <TextField
            label="Default Value"
            value={variable.default}
            onChange={(e) =>
              handleVariableChange(index, "default", e.target.value)
            }
          />
          <TextField
            label="Description"
            value={variable.description}
            onChange={(e) =>
              handleVariableChange(index, "description", e.target.value)
            }
          />
          <TextField
            label="Testing Value"
            value={variable.testingValue || ""}
            onChange={(e) =>
              handleVariableChange(index, "testingValue", e.target.value)
            }
          />
          {/* delete row */}
          <Button
            variant="contained"
            onClick={() =>
              setCampaignRuntimeVariables(
                campaignRuntimeVariables.filter((_, i) => i !== index)
              )
            }
          >
            -
          </Button>
        </Stack>
      ))}
      {/* Add Variable */}
      <Stack direction="row" spacing={2} alignItems="center">
        <TextField
          label="Name"
          value={newVariable.name}
          onChange={(e) =>
            setNewVariable((prev) => ({ ...prev, name: e.target.value }))
          }
        />
        <TextField
          label="Default Value"
          value={newVariable.default}
          onChange={(e) =>
            setNewVariable((prev) => ({ ...prev, default: e.target.value }))
          }
        />
        <TextField
          label="Description"
          value={newVariable.description}
          onChange={(e) =>
            setNewVariable((prev) => ({ ...prev, description: e.target.value }))
          }
        />
        <Button variant="contained" onClick={handleAddVariable}>
          +
        </Button>
      </Stack>
    </Box>
  );
};

export default CampaignRuntimeVariables;
