import React, { useState } from "react";
import { Button, TextField, Typography, Stack } from "@mui/material";

const ExecutiveSummaryForm = ({ onSubmit, onCancel, template, edit }) => {
  const [description, setDescription] = useState(
    edit ? template.executive_summary.description || "" : ""
  );
  const [preparedFor, setPreparedFor] = useState(
    edit ? template.executive_summary.prepared_for || "" : ""
  );
  const [name, setName] = useState(
    edit ? template.executive_summary.name || "" : ""
  );

  const handleSubmit = () => {
    const formData = {
      description,
      prepared_for: preparedFor,
      name: name,
    };
    onSubmit(formData);
  };

  return (
    <Stack spacing={2}>
      <Typography variant="h6">
        {edit
          ? `Edit Executive Summary for ${template.name}`
          : `Add Executive Summary for ${template.name}`}
      </Typography>
      <TextField
        label="Name"
        fullWidth
        variant="outlined"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        label="Description"
        fullWidth
        variant="outlined"
        multiline
        rows={10}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <TextField
        label="Prepared For"
        fullWidth
        variant="outlined"
        value={preparedFor}
        onChange={(e) => setPreparedFor(e.target.value)}
      />
      <Button
        disabled={description === "" || preparedFor === "" || name === ""}
        fullWidth
        onClick={handleSubmit}
        variant="contained"
        color="primary"
      >
        Save
      </Button>
    </Stack>
  );
};

export default ExecutiveSummaryForm;
