import { Box, Stack } from "@mui/material";
import html2canvas from "html2canvas";
import { useContext, useRef, useState } from "react";
import { DataContext } from "../../../Context/dataContext";
import { filePostRequest } from "../../../Helpers/httpRequests";
import { useAppSelector } from "../../../Redux/app/hooks";
import Breadcrumbs from "./Components/Breadcrumbs";
import Calendar from "./Components/Calendar";
import { Checklist } from "./Components/Checklist";
import DataSelector from "./Components/DataSelector";
import Endpoints from "./Components/Endpoints";
import MTTD from "./Components/MTTD";
import MTTR from "./Components/MTTR";
import StatRow from "./Components/StatRow";
import Provider from "./Provider/Provider";
import { toast } from "react-toastify";

const AVOverview = () => {
  const [csvData, setCsvData] = useState({});
  const customer = useAppSelector((state) => state.customer);
  const { accessToken } = useContext(DataContext);

  // Refs for each component
  const calendarRef = useRef(null);
  const statRowRef = useRef(null);
  const endpointsRef = useRef(null);
  const mttrRef = useRef(null);
  const mttdRef = useRef(null);

  // Capture a screenshot of a specific component with a given scale
  const captureComponent = async (componentRef, scale = 3) => {
    const element = componentRef.current;
    if (!element) return;

    const canvas = await html2canvas(element, {
      scale: window.devicePixelRatio * scale,
      width: element.offsetWidth,
      height: element.offsetHeight,
    });

    return canvas.toDataURL("image/png");
  };

  // Function to capture and send all screenshots
  const downloadWord = async () => {
    const loadingtoast = toast.loading("Generating AV Word Doc");

    // Set custom scales for each component as needed
    const calendarScreenshot = await captureComponent(calendarRef, 3);
    const statRowScreenshot = await captureComponent(statRowRef, 3);
    const endpointsScreenshot = await captureComponent(endpointsRef, 5);
    const mttrScreenshot = await captureComponent(mttrRef, 3);
    const mttdScreenshot = await captureComponent(mttdRef, 2);

    // Send the images to the backend using the filePostRequest function
    const res = await filePostRequest(
      `/react/api/${customer.uuid}/alertvalidation/overview/downloadword`,
      accessToken,
      "AVOverview.docx",
      {
        statRowScreenshot: statRowScreenshot,
        calendarScreenshot: calendarScreenshot,
        endpointsScreenshot: endpointsScreenshot,
        mttrScreenshot: mttrScreenshot,
        mttdScreenshot: mttdScreenshot,
      },
      true
    );
    if (res.status === 200) {
      toast.dismiss(loadingtoast);
    } else {
      toast.dismiss(loadingtoast);
      toast.error("Failed to generate AV Word document");
    }
  };

  return (
    <Provider>
      <Stack spacing={2}>
        <Breadcrumbs csvData={csvData} downloadWord={downloadWord} />
        <DataSelector />
        <Box ref={statRowRef}>
          <StatRow setCsvData={setCsvData} />
        </Box>
        <Box ref={calendarRef}>
          <Calendar />
        </Box>
        <Stack direction="row" spacing={2}>
          <Box ref={endpointsRef} sx={{ flex: 1 }}>
            <Endpoints />
          </Box>
          <Box ref={mttrRef} sx={{ flex: 1 }}>
            <MTTR setCsvData={setCsvData} />
          </Box>
        </Stack>
        <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
          <Box ref={mttdRef} sx={{ width: "100%" }}>
            <MTTD setCsvData={setCsvData} />
          </Box>
        </Stack>
        <Box>
          <Checklist />
        </Box>
      </Stack>
    </Provider>
  );
};

export default AVOverview;
