import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { getRequest } from "../../../Helpers/httpRequests";
import { DataContext } from "../../../Context/dataContext";
import {
  FormControl,
  InputLabel,
  Checkbox,
  Select,
  MenuItem,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import { useAppSelector } from "../../../Redux/app/hooks";

const CustomerAccesses = ({ formData, setFormData, service_provider }) => {
  // ----- Context -----
  const { accessToken } = useContext(DataContext);
  const user = useAppSelector((state) => state.user);

  // ----- States -----
  const [customers, setCustomers] = useState([]);

  // ----- Variables -----
  /**
   * Multi-Select Styles for Customer Accesses in form
   */
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // ---- Functions -----

  /**
   * This function handles the Customer Access select of the form.
   * @param {Event} event
   * @returns
   */
  const handleCxAccessChange = (event) => {
    const {
      target: { value },
    } = event;

    // if the last item in array is "all", set forData.customer_access
    //to an empty array if it is full, otherwise set it to equal ALL the
    //customers in the customers state
    if (value[value.length - 1] === "all") {
      setFormData((oldData) => ({
        ...oldData,
        customer_access:
          oldData.customer_access.length === customers.length ? [] : customers,
      }));
      return;
    }

    setFormData((oldData) => ({
      ...oldData,
      customer_access: typeof value === "string" ? value.split(",") : value,
    }));
  };

  // ----- On page load -----
  useEffect(() => {
    /**
     * Function to fetch initial data needed for form.
     * Retrieves:
     *  all customes,
     *  all users,
     *  all role choices on BlindspotUser model.
     */
    const getAllCustomers = async () => {
      let url = ""
      if (service_provider){
        url = "/react/api/serviceprovider/customers"
      } else {
        url = "/react/api/admin-add-user"
      }
      const res = await getRequest(url, accessToken);
      if (res.status === 200) {
        // set reponse fields to thier respective states
        setCustomers(res.data.customers);
      }
    };

    // if user.cures re-run api-request
    if (user.current_customer) {
      getAllCustomers();
    }
  }, [user.current_customer, accessToken, service_provider]);

  return (
    <FormControl required>
      <InputLabel id="cx-multiple-checkbox-label">Customer Accesses</InputLabel>
      <Select
        labelId="cx-multiple-checkbox-label"
        id="cx-multiple-checkbox"
        multiple
        value={formData.customer_access}
        onChange={handleCxAccessChange}
        input={<OutlinedInput label="Customer Accesses" />}
        renderValue={(selected) => {
          if (selected.length === customers.length) return "All";
          else {
            return selected.map((x) => x.name).join(",");
          }
        }}
        MenuProps={MenuProps}
      >
        <MenuItem sx={{ paddingLeft: 0 }} value="all">
          <Checkbox
            id="all-cx-checkbox"
            checked={
              customers.length > 0 &&
              customers.length === formData.customer_access.length
            }
            indeterminate={
              formData.customer_access.length > 0 &&
              formData.customer_access.length < customers.length
            }
          />
          <ListItemText primary="Select All" />
        </MenuItem>

        {customers.map((cx) => (
          <MenuItem key={cx.uuid} value={cx}>
            <Checkbox
              checked={formData.customer_access.some(
                (customer) => customer.uuid === cx.uuid
              )}
            />
            <ListItemText primary={cx.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

CustomerAccesses.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
};

export default CustomerAccesses;
