import React, { useState, useEffect, useContext } from "react";
import {
  Stack,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import { getRequest, postRequest } from "../../../../Helpers/httpRequests";
import { DataContext } from "../../../../Context/dataContext";
import { LoadingButton } from "@mui/lab";
import PropTypes from "prop-types";
import { useAppSelector } from "../../../../Redux/app/hooks";

const TechniqueAddForm = (props) => {
  // ----- STATES && CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector((state) => state.customer);
  const [techniques, setTechniques] = useState([]);
  const [selected, setSelected] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [isFormProcessing, setFormProcessing] = useState(false);

  // ----- VARIABLES -----
  const MenuProps = {
    style: {
      maxHeight: 800,
      width: 200,
    },
  };

  const { finding_uuid, setModalOpen, onFinish } = props;

  // ----- FUNCTIONS -----
  const theme = useTheme();

  // function used to show which technique is selected
  const getStyles = (name, selected, theme) => {
    return {
      fontWeight:
        selected.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelected(typeof value === "string" ? value.split(",") : value);
  };

  const handleSubmit = async () => {
    setSubmitDisabled(true);
    setFormProcessing(true);
    const data = { selected: selected };

    const result = await postRequest(
      `/react/api/${current_customer.uuid}/report/findings/${finding_uuid}/edit`,
      accessToken,
      data,
      true
    );

    if (result.status === 200) {
      setFormProcessing(false);
      setModalOpen(false);
      if (onFinish) {
        onFinish();
      }
    } else {
      setFormProcessing(false);
      setSubmitDisabled(false);
    }
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getLists = async () => {
      // get the list of endpoints and targets
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/report/findings/${finding_uuid}/edit`,
        accessToken
      );
      if (res.status === 200) {
        setTechniques(res.data.all_techs);
        const ids = res.data.finding.attack_id.map((tech) => {
          return tech.attack_id;
        });
        setSelected(ids);
      }
    };

    getLists();
  }, [accessToken, finding_uuid, current_customer.uuid]);

  return (
    <Stack sx={{ maxHeight: "100%", display: "flex" }}>
      <Box
        sx={{
          justifyContent: "space-between",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Typography variant="h5">Edit Attack Techniques</Typography>
        <LoadingButton
          variant="contained"
          loading={isFormProcessing}
          disabled={submitDisabled}
          onClick={handleSubmit}
        >
          Submit
        </LoadingButton>
      </Box>
      <FormControl sx={{ marginTop: 4, width: 835 }}>
        <InputLabel id="demo-multiple-name-label">Attack Techniques</InputLabel>
        <Select
          defaultOpen
          sx={{ height: "150%" }}
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={selected}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {selected.map((tech, index) => (
                <Chip key={index} label={tech} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {techniques.map((tech, index) => (
            <MenuItem
              key={index}
              value={tech.attack_id}
              label={tech.name}
              style={getStyles(tech.name, selected, theme)}
            >
              {tech.attack_id} - {tech.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

TechniqueAddForm.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
  finding_uuid: PropTypes.string.isRequired,
  onFinish: PropTypes.func,
};

export default TechniqueAddForm;
