import { CircularProgress, Box, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { Divider, Typography } from "@mui/material";
import moment from "moment";
import { COLORS } from "../../../Styles/colors";
import { useAppSelector } from "../../../Redux/app/hooks";
import { useHttpRequest } from "../../../Hooks";

const AlertsAvatar = () => {
  // ----- STATES && CONTEXT -----
  const customer = useAppSelector((state) => state.customer);
  const [alerts, setAlerts] = useState([]);
  const [loaded, setLoaded] = useState(false);

  // ----- ON PAGE LOAD -----
  const {
    response,
    status: responseStatus,
    fetch,
  } = useHttpRequest({
    method: "GET",
    path: `/react/api/${customer.uuid}/get_alerts_notifs`,
  });

  useEffect(() => {
    if (responseStatus === 200) {
      setAlerts(response.alerts);
      setLoaded(true);
    }
  }, [responseStatus, response, customer]);

  // ----- HANDLERS -----

  // Fucntion use to specify to number of notification to get back and show
  const handleShowAlerts = async (e) => {
    fetch();
  };

  return (
    <>
      <Box
        sx={{
          marginBottom: 1,
          display: "flex",
          justifyContent: "flex-start",
          height: 25,
        }}
      >
        <Select defaultValue={""} onChange={handleShowAlerts} displayEmpty>
          <MenuItem value="">Select Filter</MenuItem>
          <MenuItem value="last10">Show Last 10</MenuItem>
          <MenuItem value="all">Show Last 30</MenuItem>
        </Select>
      </Box>

      {loaded === true ? (
        <>
          {alerts.length > 0 ? (
            alerts.map((alert) => {
              let color = COLORS.secondary.main;
              if (alert.alert.includes("started")) {
                color = COLORS.primary.main;
              } else if (alert.alert.includes("completed")) {
                color = COLORS.success.main;
              } else if (alert.alert.includes("failed")) {
                color = COLORS.error.main;
              }
              return (
                <div
                  key={alert.uuid}
                  style={{ borderLeft: `5px solid ${color}`, paddingLeft: 4 }}
                >
                  <Typography>{alert.alert}</Typography>
                  <Typography variant="caption" color="textSecondary">
                    {" "}
                    {alert.user.first} {alert.user.last}{" "}
                    {moment(alert.created).format("MMM Do, YYYY hh:mm a")}
                  </Typography>
                  <Divider sx={{ marginBottom: 1 }} />
                </div>
              );
            })
          ) : (
            <Typography> No New Notifications</Typography>
          )}
        </>
      ) : (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 7,
          }}
        >
          <CircularProgress size={30} />
        </Box>
      )}
    </>
  );
};

export default AlertsAvatar;
