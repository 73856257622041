import React from "react";
import { TextField } from "@mui/material";

const FormInput = (props) => {
  const handleInputChange = (e) => {
    props.setFormData((prev) => ({ ...prev, [props.formKey]: e.target.value }));
  };

  let styles = {};

  if (!props.noDetails) {
    styles = {
      width: `${props.split[0]}%` || "25%",
    };
  }

  return (
    <TextField
      sx={styles}
      required={props.required}
      label={props.label}
      value={props.formData[props.formKey]}
      onChange={handleInputChange}
      type={props.inputType}
      fullWidth={props.fullWidth}
    />
  );
};

FormInput.defaultProps = {
  required: false,
  value: "",
  inputType: "text",
  fullWidth: false,
};

export default FormInput;
