import React, { useState, useEffect } from "react";
import {
  Stack,
  Typography,
  Chip,
  Grid,
  Divider,
  Box,
  Tooltip,
  IconButton,
  Alert,
} from "@mui/material";
import { FaTimesCircle } from "react-icons/fa";
import SelectSearch from "react-select-search";
import classes from "./style.module.css";

const ExpectedAlertsForm = ({
  formData,
  setFormData,
  formOptions,
}) => {
  const [availableAlerts, setAvailableAlerts] = useState([]);
  useEffect(() => {
    const expectedAlertsOptions = () => {
      const expected_alerts = formOptions.expected_alerts || []; // Ensure expected_alerts is an array
      const selectedSecurityStackUUID = formData.security_stack;
      const selectedSecurityStack = formOptions.securityStacks.find(
        (stack) => stack.uuid === selectedSecurityStackUUID
      );
      let options = [];
      if (expected_alerts.length > 0 && selectedSecurityStack) {
        const selectedTools = selectedSecurityStack.tools.map(
          (toolStack) => toolStack.tool
        );
        selectedTools.forEach((selectedTool) => {
          if (selectedTool) {
            options.push(
              ...expected_alerts
                .filter(
                  (alert) =>
                    alert.security_tool_product === selectedTool.product
                )
                .map((alert) => ({
                  uuid: alert.uuid,
                  alert_name: alert.alert_name,
                }))
            );
          }
        });
      }
      return options;
    };

    setAvailableAlerts(expectedAlertsOptions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Add Alert
  const handleExpectedAlertsChange = (selectedAlerts) => {
    setFormData({
      ...formData,
      expected_alerts: [...formData.expected_alerts, ...selectedAlerts],
    });
  };

  // Remove Alert
  const handleAlertChangeRemove = (alertUuid) => {
    const updatedSelectedAlerts = formData.expected_alerts.filter(
      (uuid) => uuid !== alertUuid
    );
    setFormData({
      ...formData,
      expected_alerts: updatedSelectedAlerts,
    });
  };

  return (
    <Stack spacing={2} sx={{ minWidth: 800 }}>
      <Typography variant="h6">Expected Alerts</Typography>
      <Grid container>
        <Grid item xs={7} sx={{ padding: 1 }}>
          <Typography>Selected Alerts</Typography>
          <Divider />
          <Box>
            {formData.expected_alerts.map((alertUuid) => {
              const selectedAlert = formOptions.expected_alerts.find(
                (alert) => alert.uuid === alertUuid
              );

              return (
                <Chip
                  key={alertUuid}
                  label={
                    selectedAlert ? (
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "400px 60px",
                          gap: "5px", // Add gap between columns
                          wordBreak: "break-word", // Allow words to break
                        }}
                      >
                        <Box
                          sx={{
                            flex: 1,
                            display: "flex",
                            flexWrap: "wrap",
                            minWidth: 0, // Allow text to shrink
                          }}
                        >
                          {selectedAlert.alert_name}
                        </Box>
                        <Box>
                          <Tooltip title={`Remove Alert`}>
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                              }}
                              onClick={(e) => {
                                e.stopPropagation(); // Stop click propagation
                                handleAlertChangeRemove(alertUuid);
                              }}
                            >
                              <FaTimesCircle size={18} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    ) : (
                      ""
                    )
                  }
                  onClick={() => {
                    if (!formData.expected_alerts.includes(alertUuid)) {
                      const updatedSelectedAlerts = [
                        ...formData.expected_alerts,
                        alertUuid,
                      ];
                      setFormData({
                        ...formData,
                        expected_alerts: updatedSelectedAlerts,
                      });
                    }
                  }}
                  sx={{
                    height: "auto",
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                    },
                    marginTop: 1,
                    width: "100%",
                    minHeight: "30px", // Adjust this value as needed
                    justifyContent: "flex-start",
                    textAlign: "left",
                    backgroundColor: "lightgray",
                  }}
                />
              );
            })}
          </Box>
        </Grid>
        <Grid item xs={5} sx={{ padding: 1 }}>
          {availableAlerts.length !== formData.expected_alerts.length ? (
            <>
              <Box>
                <Typography>Click on items to add</Typography>
              </Box>
              <Divider sx={{ marginBottom: 1 }} />
              <SelectSearch
                className={classes}
                multiple
                search
                placeholder="Search Expected Alerts"
                value={formData.expected_alerts}
                onChange={handleExpectedAlertsChange}
                options={availableAlerts
                  .filter(
                    (alert) => !formData.expected_alerts.includes(alert.uuid)
                  )
                  .map((alert) => ({
                    name: alert.alert_name,
                    value: alert.uuid,
                  }))}
              />
            </>
          ) : (
            <Alert>All Expected Alerts Selected</Alert>
          )}
        </Grid>
      </Grid>
    </Stack>
  );
};

export default ExpectedAlertsForm;
