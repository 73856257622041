import {
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import SelectSearch from "react-select-search";
import { toast } from "react-toastify";
import { DataGridWithStyles } from "../../../Components";
import { DataContext } from "../../../Context/dataContext";
import { deleteRequest } from "../../../Helpers/httpRequests";
import { useHttpRequest } from "../../../Hooks";
import { useAppSelector } from "../../../Redux/app/hooks";
import { COLORS } from "../../../Styles/colors";
import classes from "./style.module.css";

const ServiceProviderUserGroups = ({ users }) => {
  const [selectedUserGroup, setSelectedUserGroup] = useState(null);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    blindspot_users: [],
  });
  const [selectedUsers, setSelectedUsers] = useState([]);
  const customer = useAppSelector((state) => state.customer);
  const { accessToken } = useContext(DataContext);

  const { response: userGroups, fetch: refetch } = useHttpRequest({
    method: "GET",
    path: "/api/v2/user-groups",
  });

  const {
    fetch: saveUserGroup,
    status,
    loading,
  } = useHttpRequest({
    method: selectedUserGroup ? "PATCH" : "POST",
    path: selectedUserGroup
      ? `/api/v2/user-groups/${selectedUserGroup.id}`
      : "/api/v2/user-groups",
    defer: true,
    data: {
      ...formData,
      blindspot_users: selectedUsers,
    },
  });

  useEffect(() => {
    if (selectedUserGroup) {
      setFormData({
        name: selectedUserGroup.name,
        service_provider: selectedUserGroup.service_provider,
        blindspot_users: selectedUserGroup.blindspot_users.map(
          (user) => user.uuid
        ),
      });
      setSelectedUsers(
        selectedUserGroup.blindspot_users.map((user) => user.uuid)
      );
    }
  }, [selectedUserGroup]);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddNew = () => {
    setSelectedUserGroup(null);
    setFormData({
      name: "",
      service_provider: "",
      blindspot_users: [],
    });
    setSelectedUsers([]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUserGroup(null);
  };

  const handleDelete = async (id) => {
    const LoadingToast = toast.loading("Attempting to delete User Group");
    const res = await deleteRequest(
      `/api/v2/${customer.uuid}/user-groups/${id}`,
      accessToken
    );
    if (res.status === 204) {
      toast.dismiss(LoadingToast);
      toast.success("User Group Deleted Successfully");
      refetch();
      handleClose();
    } else {
      toast.dismiss(LoadingToast);
      toast.error("Failed to delete User Group");
    }
  };

  const handleSave = () => {
    toast.loading(
      `Attempting to ${selectedUserGroup ? "Edit" : "Add"} User Group`
    );
    saveUserGroup(); // Trigger the save operation (without awaiting)
  };

  useEffect(() => {
    if (!loading && status !== null) {
      if (status === 200) {
        toast.success(
          `User Group ${selectedUserGroup ? "updated" : "saved"} Successfully`
        );
        refetch();
        handleClose();
      } else {
        toast.error(
          `Failed to ${selectedUserGroup ? "update" : "save"} User Group`
        );
      }
      toast.dismiss();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, status]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      renderCell: (params) => (
        <Typography
          className="text-links"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedUserGroup(params.row);
            setOpen(true);
          }}
        >
          {params.row.name}
        </Typography>
      ),
    },

    {
      field: "blindspot_users",
      headerName: "Blindspot Users",
      width: 1200,
      renderCell: (params) => (
        <Box sx={{ display: "flex", gap: 0.5 }}>
          {params.row.blindspot_users.map((user) => (
            <Chip
              key={user.user.email}
              label={user.user.email}
              sx={{ marginBottom: 0.5 }}
            />
          ))}
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      maxWidth: 80,
      renderCell: (params) => {
        return (
          <>
            <Stack spacing={2} direction="row" title="Delete Template">
              <IconButton onClick={() => handleDelete(params.row.id)}>
                <FaTrashAlt color={COLORS.error.main} />
              </IconButton>
            </Stack>
          </>
        );
      },
    },
  ];

  return (
    <Box>
      <>
        <Button variant="contained" color="primary" onClick={handleAddNew}>
          Add New User Group
        </Button>
        <DataGridWithStyles
          name="User Groups"
          rows={(userGroups && userGroups) || []}
          columns={columns}
          autoHeight
          //   loading={loading}
          paper
        />
      </>
      <Modal open={open} onClose={handleClose} sx={{ border: "none" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <h2>
            {selectedUserGroup ? "Edit User Group" : "Add New User Group"}
          </h2>
          <TextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 1,
            }}
          >
            <Box sx={{ flex: 1, width: "60%", marginRight: 3 }}>
              <FormControl required margin="none">
                <SelectSearch
                  className={classes}
                  multiple
                  options={users
                    .filter((user) => !selectedUsers.includes(user.uuid))
                    .map((user) => ({
                      value: user.uuid,
                      name: user.user.email,
                    }))}
                  value={selectedUsers}
                  onChange={(value) => {
                    setFormData((oldData) => ({
                      ...oldData,
                      blindspot_users: [...oldData.blindspot_users, ...value],
                    }));
                    setSelectedUsers((prevSelectedUsers) => [
                      ...prevSelectedUsers,
                      ...value,
                    ]);
                  }}
                  placeholder="Search users..."
                  search
                />
              </FormControl>
            </Box>
            <Box sx={{ flex: 1, width: "40%" }}>
              {selectedUsers && selectedUsers?.length > 0 && (
                <>
                  <Typography variant="h6">Selected Users</Typography>
                  <Stack>
                    {selectedUsers &&
                      selectedUsers.map((user) => (
                        <Chip
                          sx={{
                            marginBottom: 1,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          key={user}
                          label={
                            users.find((u) => u.uuid === user)?.user.email || ""
                          }
                          onDelete={() => {
                            const updatedUsers = selectedUsers.filter(
                              (u) => u !== user
                            );
                            setSelectedUsers(updatedUsers);
                            setFormData((oldData) => ({
                              ...oldData,
                              blindspot_users: updatedUsers,
                            }));
                          }}
                        />
                      ))}
                  </Stack>
                </>
              )}
            </Box>
          </Box>
          <Button
            variant="contained"
            color="primary"
            disabled={
              formData.name === "" ||
              formData.blindspot_users.length === 0 ||
              loading
            }
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default ServiceProviderUserGroups;
