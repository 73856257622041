import React from "react";
import { FormControl, Select, InputLabel, MenuItem } from "@mui/material";

const ScoreSelect = ({ data, setData }) => {
  const handleChange = (e) => {
    setData((prev) => {
      return {
        ...prev,
        score: e.target.value,
      };
    });
  };

  return (
    <FormControl sx={{ flex: 3 }}>
      <InputLabel>Score</InputLabel>
      <Select value={data.score} label="Score" onChange={handleChange}>
        <MenuItem value="unchanged">No Change</MenuItem>
        <MenuItem value="blocked">Blocked</MenuItem>
        <MenuItem value="alerted">Alerted</MenuItem>
        <MenuItem value="logged">Logged</MenuItem>
        <MenuItem value="nothing">No Evidence</MenuItem>
      </Select>
    </FormControl>
  );
};

export default ScoreSelect;
