import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import SettingsIcon from "@mui/icons-material/Settings";
import HandshakeIcon from "@mui/icons-material/Handshake";
import StorageIcon from "@mui/icons-material/Storage";
import DnsIcon from "@mui/icons-material/Dns";
import { Link, ListItemButton } from "@mui/material";

const SettingsNavList = () => {
  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
        height: "max-content",
        position: "sticky",
        top: "76px",
      }}
    >
      <Link
        href="#general-section"
        style={{ textDecoration: "none", color: "black" }}
      >
        <ListItemButton>
          <ListItemAvatar>
            <Avatar>
              <SettingsIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="General" />
        </ListItemButton>
      </Link>
      <Divider />
      <Link
        href="#agreements-section"
        style={{ textDecoration: "none", color: "black" }}
      >
        <ListItemButton>
          <ListItemAvatar>
            <Avatar>
              <HandshakeIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Agreements" />
        </ListItemButton>
      </Link>
      <Divider />
      <Link
        href="#server-section"
        style={{ textDecoration: "none", color: "black" }}
      >
        <ListItemButton>
          <ListItemAvatar>
            <Avatar>
              <StorageIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Server" />
        </ListItemButton>
      </Link>
      <Divider />
      <Link
        href="#unified-builder-section"
        style={{ textDecoration: "none", color: "black" }}
      >
        <ListItemButton>
          <ListItemAvatar>
            <Avatar>
              <DnsIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Unified Builder" />
        </ListItemButton>
      </Link>
    </List>
  );
};

export default SettingsNavList;
