import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import TextButton from "../../../Components/Buttons/TextButton";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import C2ProxyConfigForm from "../../../Components/Forms/Config/C2ProxyConfigForm/C2ProxyConfigForm";
import FormModal from "../../../Components/Modals/FormModal";
import { DataContext } from "../../../Context/dataContext";
import { deleteRequest, getRequest } from "../../../Helpers/httpRequests";
import { useAppSelector } from "../../../Redux/app/hooks";
import { COLORS } from "../../../Styles/colors";

const C2ProxyConfigs = () => {
  // ----- CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  // ----- STATES -----

  const [modal, setModal] = useState({
    open: false,
    content: null,
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteUUID, setDeleteUUID] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const [configs, setConfigs] = useState([]);

  const [closeModalFn, setCloseModalFn] = useState(() => () => {});

  /**
   * contains the datagrid props
   */
  const [dataGridData, setDataGridData] = useState({
    rows: [],
    columns: [],
  });

  // ----- VARIABLES -----

  const modalStyle = {
    position: "absolute",
    bgcolor: "background.paper",
    top: "50%",
    overflow: "scroll",
    left: "35%",
    width: "90%",
    maxHeight: "100%",
    maxWidth: 1500,
    transform: "translate(-25%, -50%)",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  // ----- Functions -----
  const populateDataGrid = useRef((configs) => {
    const columns = [
      {
        field: "name",
        headerName: "Name",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {
          return (
            <Button
              onClick={() =>
                setModal({
                  open: true,
                  content: (
                    <C2ProxyConfigForm
                      setOpen={closeModalFn}
                      config={configs.find((conf) => conf.uuid === params.id)}
                    />
                  ),
                })
              }
            >
              {params.value}
            </Button>
          );
        },
      },
      {
        field: "ipAddress",
        headerName: "IP Address",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "port",
        headerName: "Port",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "redirector",
        headerName: "Redirector Address",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "Actions",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {
          return (
            <IconButton
              onClick={() => {
                setDeleteName(params.row.name);
                setDeleteUUID(params.row.id);
                setDeleteModal(true);
              }}
              title="delete Config"
            >
              <FaTrashAlt color={COLORS.error.main} />
            </IconButton>
          );
        },
      },
    ];

    const rows = configs.map((element) => {
      return {
        id: element.uuid,
        name: element.name || "",
        ipAddress: element.ip_address || "",
        port: element.port || 0,
        redirector: element.redirector_address || "",
      };
    });

    setDataGridData((data) => ({
      ...data,
      rows: rows,
      columns: columns,
    }));
    setConfigs(configs);
  });

  // ----- On page load -----

  useEffect(() => {
    const getData = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/config/c2proxyconfigs`,
        accessToken
      );

      if (res.status === 200) {
        populateDataGrid.current(res.data.configs);
      } else {
        console.log(
          "%cerror componentName.jsx useEffect()",
          "color: red; display: block; width: 100%;",
          "Reponse status not 200"
        );
      }
    };

    if (closeModalFn(true) !== "set" || closeModalFn(true) === undefined) {
      setCloseModalFn(() => (test = false) => {
        if (test) {
          return "set";
        }
        setModal({
          open: false,
          content: null,
        });
        getData();
      });
    } else {
      getData();
    }
  }, [accessToken, current_customer, closeModalFn]);

  return (
    <Stack spacing={3}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Config</Typography>
        <Typography color="text.primary">C2 Proxy Configs</Typography>
      </Breadcrumbs>

      {/* Page Title */}
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography variant="h3">C2 Proxy Configs</Typography>
        <TextButton
          onClick={() => {
            setModal({
              open: true,
              content: <C2ProxyConfigForm setOpen={closeModalFn} />,
            });
          }}
          tooltip="New Config"
          icon={FaPlus}
        />
      </Stack>

      {/* Template Grid */}
      <DataGridWithStyles
        name="c2-proxy-configs"
        autoHeight
        paper
        rows={dataGridData.rows}
        columns={dataGridData.columns}
      />

      {closeModalFn(true) === "set" ? (
        <Modal
          open={modal.open}
          onClose={() => closeModalFn()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>{modal.content}</Box>
        </Modal>
      ) : null}
      {/* Delete Modal */}
      <FormModal open={deleteModal} setOpen={setDeleteModal}>
        <Box component="form">
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: 4 }}
          >
            Are you sure you want to remove {deleteName}?
          </Typography>
          <Stack direction="column" spacing={2}>
            <Button
              onClick={() => {
                deleteRequest(
                  `/react/api/${current_customer.uuid}/config/c2proxyconfigs/delete?config_uuid=${deleteUUID}`,
                  accessToken
                );
                const temp = configs;
                const confIdx = configs.findIndex(
                  (conf) => conf.uuid === deleteUUID
                );
                temp.splice(confIdx, 1);
                setDeleteModal(false);
                populateDataGrid.current(temp);
              }}
              variant="contained"
            >
              Yes
            </Button>
          </Stack>
        </Box>
      </FormModal>
    </Stack>
  );
};

export default C2ProxyConfigs;
