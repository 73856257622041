import { useEffect, useContext, useState } from "react";
import { DataContext } from "../../Context/dataContext";
import axios from "axios";

const BASEURL =
  process.env.REACT_APP_ENVIRONMENT === "DEV" ||
  process.env.REACT_APP_ENVIRONMENT === "LOCAL"
    ? process.env.REACT_APP_DEV_BASEURL
    : process.env.REACT_APP_PROD_BASEURL;

const useGetRequest = (url, params = null) => {
  const { accessToken } = useContext(DataContext);
  const [response, setResponse] = useState({
    config: null,
    data: null,
    headers: null,
    request: null,
    status: null,
    statusText: null,
  });

  useEffect(() => {
    const getRequest = async () => {
      let options = {
        method: "GET",
        url: `${BASEURL}${url}`,
        headers: { Authorization: `Bearer ${accessToken}` },
        params: params,
      };

      try {
        const res = await axios.request(options);
        setResponse(res);
      } catch (e) {
        const res = e.response;
        setResponse(res);
      }
    };

    getRequest();
  }, [url, params, accessToken]);

  return response;
};

export default useGetRequest;
