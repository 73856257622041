import {
  Box,
  CircularProgress,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import useGetRequest from "../../../../Helpers/httpRequests/useGetRequest";
import { useAppSelector } from "../../../../Redux/app/hooks";
import { AvOverviewContext } from "../Provider/Context";
import Counts from "./Counts";
import Totals from "./Totals";

type Props = { setCsvData: Function };

function StatRow(props: Props) {
  const [counts, setCounts] = useState({
    daily: null,
    weekly: null,
    monthly: null,
  });
  const { avgMTTD, avgRemidateTime } = useContext(AvOverviewContext);
  const customer = useAppSelector((state) => state.customer);

  const response = useGetRequest(
    `/react/api/${customer.uuid}/alertvalidation/get-stats`
  );

  const formatTime = (seconds: number) => {
    let hours = 0;
    let hoursString = "";
    let minutes = Math.floor(seconds / 60);
    // if more than 60 min. convert to hr
    if (minutes / 60 >= 1) {
      hours = Math.floor(minutes / 60);
      minutes -= hours * 60;
      hoursString = `${hours}hr${hours > 1 ? "s " : " "}`;
    }
    const remainingSeconds = Math.floor(seconds % 60);
    return `${hours > 0 ? hoursString : ""}${minutes}min ${remainingSeconds}s`;
  };

  useEffect(() => {
    if (response.status === 200) {
      setCounts(response.data.counts);
    }
  }, [response]);

  return (
    <Stack
      spacing={2}
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
      alignItems="stretch" // Ensure items stretch to fill height
      sx={{ height: "100%" }} // Set the Stack to take full height
    >
      <Paper sx={{ p: 2, flex: 1 }} variant="outlined">
        <Totals counts={counts} />
      </Paper>
      <Paper sx={{ p: 2, flex: 1 }} variant="outlined">
        <Counts />
      </Paper>
      <Paper sx={{ p: 2, flex: 1 }} variant="outlined">
        <Stack spacing={2} alignItems="center" height="100%">
          <Box>
            <Typography flex={1} variant={"h5"}>
              Mean Time To Detect
            </Typography>
          </Box>
          <Box flex={1} alignContent="center">
            {!Number.isNaN(avgMTTD) ? (
              <Typography
                sx={{
                  padding: 1,
                  borderRadius: 2,
                  backgroundColor: "lightgray",
                }}
                fontSize={32}
              >
                {formatTime(avgMTTD)}
              </Typography>
            ) : (
              <CircularProgress />
            )}
          </Box>
        </Stack>
      </Paper>
      <Paper sx={{ p: 2, flex: 1 }} variant="outlined">
        <Stack spacing={2} alignItems="center" height="100%">
          <Box>
            <Typography flex={1} variant={"h5"}>
              Mean Time To Remediate
            </Typography>
          </Box>
          <Box flex={1} alignContent="center">
            {!Number.isNaN(avgRemidateTime) ? (
              <Typography
                sx={{
                  padding: 1,
                  borderRadius: 2,
                  backgroundColor: "lightgray",
                }}
                fontSize={32}
              >
                {formatTime(avgRemidateTime)}
              </Typography>
            ) : (
              <CircularProgress />
            )}
          </Box>
        </Stack>
      </Paper>
    </Stack>
  );
}

export default StatRow;
