import React from "react";
import { Typography, Paper, TextField } from "@mui/material";
import { Stack } from "@mui/system";

const Fix = React.memo( function Feature ({setFixes, index}) {

  // ----- Functions ------
  
  const handleFixChange = (e) => {
    setFixes((prev) => {
      let newArray = Array.from(prev)
      newArray[index] = e.target.value
      return newArray
    })
  }

  return (
    <Paper sx={{ p: 3, width: "100%" }}>
      <Stack spacing={2}>
        <Typography variant="h5">Fix {index + 1}</Typography>

        {/* Fix */}
        <TextField
          placeholder="Fix"
          label="Fix"
          type="text"
          required
          multiline
          minRows={4}
          maxRows={8}
          onChange={handleFixChange}
        />
      </Stack>
    </Paper>
  );
});

export default Fix;
