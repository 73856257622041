import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { COLORS } from "../../../Styles/colors"

const MAX_LINES = 3;

function ExpandableText({ text }) {
  const [expanded, setExpanded] = useState(false);

  const toggleExpansion = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };
  const isTextLong = text.length > 200;

  return (
    <div>
      <Typography
        variant="body1"
        style={{
          overflow: 'hidden',
          textOverflow: expanded ? 'initial' : 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: expanded ? 'unset' : MAX_LINES,
          WebkitBoxOrient: 'vertical',
        }}
      >
        {text}
      </Typography>
      {isTextLong && (
        <>
          {!expanded && (
            <IoIosArrowDown
              style={{
                cursor: 'pointer',
                fontSize: '1.5rem',
                color: COLORS.secondary.light
              }}
              onClick={toggleExpansion}
            >SHOW MORE</IoIosArrowDown>
          )}
          {expanded && (
            <IoIosArrowUp
              style={{
                cursor: 'pointer',
                fontSize: '1.5rem',
                color: COLORS.secondary.light
              }}
              onClick={toggleExpansion}
            >SHOW LESS</IoIosArrowUp>
          )}
        </>
      )}
    </div>
  );
}

export default ExpandableText;