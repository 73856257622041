import { useContext, useEffect, useState } from "react";
import { Paper, CircularProgress, Box, Typography } from "@mui/material";
import { useHttpRequest } from "../../../../Hooks";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { AvOverviewContext } from "../Provider/Context";
import { useAppSelector } from "../../../../Redux/app/hooks";

const MTTR = () => {
  const { startDate, endDate, setAvgRemidateTime } = useContext(AvOverviewContext);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [processedData, setProcessedData] = useState([]);
  const current_customer = useAppSelector((state) => state.customer);

  const { response: mttr_data, loading, status, fetch: getData } = useHttpRequest({
    method: "GET",
    path: `/react/api/${current_customer.uuid}/alertvalidation/overview/mttr?start=${startDate.toISOString()}&end=${endDate.toISOString()}`,
  });

  useEffect(() => {
    if (status !== 200) {
      return;
    }
    if (mttr_data && mttr_data.mttrByExercise) {
      const updatedData = mttr_data.mttrByExercise.map((mttr) => {
        const updatedEvents = mttr.events.map((event) => {
          const date = new Date(event.date);
          const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().slice(-2)}`;
          const timeInHours = Math.round(event.time / 60); // convert time to hours and round it
          return { ...event, date: formattedDate, time: timeInHours };
        });
        return { ...mttr, events: updatedEvents };
      });
      setProcessedData(updatedData);
      setAvgRemidateTime(mttr_data.mttr);
      setIsDataUpdated(true);
    }
  }, [mttr_data, setAvgRemidateTime, status, getData]);

  useEffect(() => {
    console.log("fetching data");
    getData();
  }, [startDate, endDate]);

  if (loading || !isDataUpdated) {
    return (
      <Paper sx={{ p: 3, width: "50%" }}>
        <CircularProgress />
      </Paper>
    );
  } else {
    return (
      <Paper sx={{ p: 3, width: "100%" }}>
        <Typography variant="h5">Time to Remediate Detection Outages</Typography>
        {mttr_data.mttrByExercise.map((mttr) => {
          return (
            <Box>
              <h3>{mttr.exercise}</h3>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={mttr.events}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey="time"
                    name="Total Time in Hours"
                    fill="#063970"
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          );
        })}
      </Paper>
    );
  }

  return (
    <Paper sx={{ p: 3, width: "50%" }}>
      <Typography variant="h5">Remediation Time by Outages</Typography>
      {processedData.map((mttr) => (
        <Box key={mttr.exercise}>
          <h3>{mttr.exercise}</h3>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={mttr.events}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="time" name="Total Time in Hours" fill="#063970" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      ))}
    </Paper>
  );
};

export default MTTR;
