export const COLORS = {
  primary: {
    light: "#09BDC9",
    main: "#1976d2",
    dark: "#1565c0",
  },
  secondary: {
    light: "#999999",
    main: "#777777",
    dark: "#555555",
  },
  error: {
    light: "#eB6272",
    main: "#d32f2f",
    dark: "#c62828",
    text: "#ffffff", // White text color for warning
  },
  warning: {
    light: "#f2CC63",
    main: "#ed6c02",
    dark: "#e65100",
  },
  info: {
    light: "#03a9f4",
    main: "#0288d1",
    dark: "#01579b",
  },
  success: {
    light: "#65D193",
    main: "#2e7d32",
    dark: "#1b5e20",
  },
  yellow: "#ECC010"
};
