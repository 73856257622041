import { createContext, useContext } from "react";

export interface DataContextType {
  accessToken?: string;
  setAccessToken?: React.Dispatch<React.SetStateAction<string>>;
  logo?: string | null;
  setLogo?: React.Dispatch<React.SetStateAction<string | null>>;
}

// Define a default value that matches the DataContextType
const defaultValue: DataContextType = {
  accessToken: "",
  // set default values for other properties
};

export const DataContext = createContext<DataContextType>(defaultValue);

export const useDataContext = () => useContext(DataContext);
