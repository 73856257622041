import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { default as React, useContext, useEffect, useState } from "react";
import { FaBell } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import AlertForm from "../../../Components/Forms/AlertValidation/AlertForm";
import { DataContext } from "../../../Context/dataContext";
import { getRequest } from "../../../Helpers/httpRequests";
import { useAppSelector } from "../../../Redux/app/hooks";

const AV_Library = (props) => {
  // Navigate
  const navigate = useNavigate();

  // ----- STATES && CONTEXT -----
  const [simulations, setSimulations] = useState([]);
  const [formSimulation, setFormSimulation] = useState("");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add a loading state

  // Contexts
  const { accessToken } = useContext(DataContext);
  const darkmode = useAppSelector((state) => state.darkmode);
  const current_customer = useAppSelector(
    (state) => state.customer,
  );

  //  ----- VARIABLES -----

  // ----- FUNCTIONS ------

  /**
   * Handle opening of Modal
   * @param {object} sim Simulation object
   */
  const handleOpen = (sim) => {
    // Set default form value, and THEN open the modal.
    setFormSimulation(sim);
    setOpen(true);
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getSimulations = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/alertvalidation/library`,
        accessToken,
      );
      setSimulations(res.data.simulations);
      setIsLoading(false); // Once data is loaded, set loading to false
    };

    getSimulations();
  }, [current_customer, accessToken]);

  return (
    <Stack spacing={3}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Alert Validation</Typography>
        <Typography color="text.primary">Exercise Library</Typography>
      </Breadcrumbs>

      <Typography variant="h3">Alert Validation Exercise Library</Typography>
      <Box sx={{ flexGrow: 1 }}>
        {isLoading ? ( // Display loading indicator
          <CircularProgress size={50} />
        ) : (
          <Grid container spacing={3} columns={24}>
            {simulations.map((sim) => (
              <Grid
                key={sim.id}
                item
                sx={{ display: "flex" }}
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={6}
              >
                <Card
                  sx={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    flexDirection: "column",
                  }}
                >
                  <CardHeader
                    sx={{
                      background: darkmode
                        ? "linear-gradient(to right, rgb(75, 85, 99), rgb(17, 24, 39))"
                        : "linear-gradient(to right, #e0eafc, #cfdef3)",
                    }}
                    avatar={
                      <Avatar
                        sx={{
                          backgroundColor: darkmode ? "#1e1e1e" : "#FAFAFA",
                        }}
                      >
                        {<FaBell color="#C22226" size={28} />}
                      </Avatar>
                    }
                    title={sim.name}
                    titleTypographyProps={{
                      fontSize: 16,
                      noWrap: true,
                    }}
                    subheader={sim.platform}
                  />
                  <CardContent>
                    <Typography sx={{ pt: 1 }} variant="body2">
                      {sim.description}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ marginTop: "auto" }}>
                    <Stack
                      direction="row"
                      sx={{ width: "100%" }}
                      justifyContent="space-between"
                    >
                      <Link
                        to={`/${current_customer.uuid}/alertvalidation/library/${sim.uuid}`}
                      >
                        <Button>Learn More</Button>
                      </Link>
                      <Button onClick={() => handleOpen(sim)}>Schedule</Button>
                    </Stack>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>

      {/* Model for form */}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflow: "scroll" }}
      >
        <div>
          <AlertForm
            simulation={formSimulation}
            setOpen={() => {
              // TODO: perhaps we change the name here, cause it's confusing
              setOpen(false);
              navigate(
                `/${current_customer.uuid}/alertvalidation/scheduled-exercises`,
              );
            }}
          />
        </div>
      </Modal>
    </Stack>
  );
};

export default AV_Library;
