import { useEffect, useState, useContext } from "react";
import { Typography, Box, Breadcrumbs, Paper } from "@mui/material";
import { useParams, Link } from "react-router-dom";
import { DataContext } from "../../../Context/dataContext";
import { getRequest } from "../../../Helpers/httpRequests";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import { useAppSelector } from "../../../Redux/app/hooks";

const Config_Security_Tool_Details = () => {
  // ----- STATES && CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer,
  );
  const [reloadSecurityTools, setReloadSecurityTools] = useState(true);
  const [mappedTechRows, setMappedTechRows] = useState([]);
  const [securityTool, setSecurityTool] = useState([]);

  // ----- VARIABLES -----
  const { toolUUID } = useParams();

  const mappedTechsColumns = [
    { field: "Name", headerName: "Created", minWidth: 300, flex: 1 },
    { field: "Attack_id", headerName: "Attack ID", minWidth: 100, flex: 1 },
    { field: "Tactics", headerName: "tactics", minWidth: 100, flex: 1 },
  ];

  // ----- FUNCTIONS -----

  const mappedTechGrid = (techs) => {
    const rows = techs.map((tech) => {
      return {
        name: tech?.name,
        attack_id: tech?.attack_id,
        tactic: tech?.tactic,
      };
    });
    setMappedTechRows(rows);
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getData = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/config/securitytools/detail/${toolUUID}`,
        accessToken,
      );
      if (res.status === 200) {
        try {
          setSecurityTool(res.data.tool_obj_serializer);
          mappedTechGrid(res.data.tool_obj_serializer.techniques_mapped);
        } catch (err) {
          console.log("Error while trying to set States from getData()", err);
        }
      }
    };

    // TODO: this is not how you refresh components...
    if (reloadSecurityTools === true) {
      getData();
      setReloadSecurityTools(false);
    }

    //eslint-disable-next-line
  }, [current_customer, accessToken]);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">Home</Link>
        <Link to={`/${current_customer.uuid}/config/securitytools`}>
          Security Tools
        </Link>
        <Typography color="text.primary">Security Tool Details</Typography>
      </Breadcrumbs>

      <Box
        sx={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Typography fontWeight={"bold"} variant="h4">
          {" "}
          {securityTool["name"]}
        </Typography>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box
          className="left-box"
          sx={{ flex: 1, paddingRight: 1, marginTop: 1 }}
        >
          <Paper sx={{ marginBottom: 2, padding: 2 }}>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                marginBottom: 2,
              }}
            >
              Details
            </Typography>
            <Typography component="div" sx={{ flexDirection: "column" }}>
              <Typography sx={{ marginBottom: 1 }}>
                <strong>Name:</strong> {securityTool.name}
              </Typography>
              <Typography sx={{ marginBottom: 1 }}>
                <strong>Vendor:</strong> {securityTool.vendor}
              </Typography>
              <Typography sx={{ marginBottom: 1 }}>
                <strong>Log Destination:</strong> {securityTool.log_destination}
              </Typography>
            </Typography>
          </Paper>
        </Box>

        <Box
          className="right-box"
          sx={{ flex: 1, paddingLeft: 3, marginTop: 1 }}
        >
          <Paper sx={{ marginBottom: 2, padding: 2, flex: 1 }}>
            <Typography fontWeight={"bold"} variant="h6">
              Mapped Techniques
            </Typography>
            <DataGridWithStyles
              name="config-sec-tool-details"
              autoHeight
              rows={mappedTechRows}
              columns={mappedTechsColumns}
            />
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default Config_Security_Tool_Details;
