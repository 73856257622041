import { Typography, Box, Stack, Button } from "@mui/material";
import FormModal from "../../../../Components/Modals/FormModal";
import { deleteAction } from "../functions";


const DeleteModal = ({deleteModal, setDeleteModal, deleteUUID, data, setReloadPage}) => {
  return (
    <FormModal open={deleteModal} setOpen={setDeleteModal}>
      <Box component="form">
        <Typography
          variant="h6"
          style={{ textAlign: "center", marginBottom: 4 }}
        >
          Are you sure you want to remove action {deleteUUID + 1}?
        </Typography>
        <Stack direction="column" spacing={2}>
          <Button
            onClick={() =>
              deleteAction(deleteUUID, data, setDeleteModal, setReloadPage)
            }
            variant="contained"
          >
            Yes
          </Button>
        </Stack>
      </Box>
    </FormModal>
  );
};

export default DeleteModal;
