import { Paper, Typography } from "@mui/material";

/**
 * This function returns what will be displayed in the tooltip of the onHover event for each day
 * @param {*} day This is passed in by the calendar component
 * @returns div
 */
export const Tooltip = (day) => {
  const tooltipStyles = {
    colorBox: {
      width: "20px",
      height: "20px",
      marginRight: 10,
      backgroundColor: day.color,
    },
    container: {
      minHeight: "1.5rem",
      minWidth: "1rem",
      display: "flex",
      alignItems: "center",
      borderRadius: 2,
      padding: "0.5rem",
    },
  };

  return (
    <Paper elevation={5} sx={tooltipStyles.container}>
      <div style={tooltipStyles.colorBox}></div>
      <Typography>{day.day}</Typography>
    </Paper>
  );
};

export const onClick = (e) => {
  console.log(e.day)
}