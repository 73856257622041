import { Warning } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Breadcrumbs,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment-timezone";
import { useCallback, useContext, useEffect, useState } from "react";
import { AiOutlineWarning } from "react-icons/ai";
import { FaEdit, FaPause, FaPlay, FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import AlertForm from "../../../Components/Forms/AlertValidation/AlertForm";
import FormModal from "../../../Components/Modals/FormModal";
import { DataContext } from "../../../Context/dataContext";
import { encryptId } from "../../../Helpers/functions/uni_funcs";
import {
  deleteRequest,
  getRequest,
  patchRequest,
} from "../../../Helpers/httpRequests";
import { useAppSelector } from "../../../Redux/app/hooks";
import { COLORS } from "../../../Styles/colors";

const convertUtcToLocal = (utcHour) => {
  const timezone = moment.tz.guess();
  if (utcHour < 0 || utcHour > 23) {
    throw new Error("Hour must be between 0 and 23");
  }
  const utcMoment = moment.utc().hour(utcHour).minute(0).second(0);
  const localMoment = utcMoment.tz(timezone);
  return localMoment.hour();
};

const AV_Alerts = () => {
  // Navigation for redirects

  // ----- STATES && CONTEXT -----
  const [dataTableRows, setDataTableRows] = useState([]);
  const [dataTableColumns, setDataTableColumns] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const [selectedAlert, setSelectedAlert] = useState({});
  const [alerts, setAlerts] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteName, setDeleteName] = useState("");
  const [deleteId, setDeleteId] = useState("");

  // Contexts
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer,
  );

  // ----- FUNCTIONS -----
  const onDeleteClick = async () => {
    await deleteRequest(
      `/react/api/${current_customer.uuid}/alertvalidation/alert/delete?alert_uuid=${deleteId}`,
      accessToken,
    );
    // Update the data grid by removing the deleted alert
    const updatedAlerts = alerts.filter((alert) => alert.id !== deleteId);
    populateDataGrid(updatedAlerts);

    // Close the delete modal
    setDeleteModal(false);
  };

  const populateDataGrid = useCallback(
    (alerts) => {
      const timeToRunOptions = [
        [0, "12am (Midnight)"],
        [1, "1am"],
        [2, "2am"],
        [3, "3am"],
        [4, "4am"],
        [5, "5am"],
        [6, "6am"],
        [7, "7am"],
        [8, "8am"],
        [9, "9am"],
        [10, "10am"],
        [11, "11am"],
        [12, "12pm (Noon)"],
        [13, "1pm"],
        [14, "2pm"],
        [15, "3pm"],
        [16, "4pm"],
        [17, "5pm"],
        [18, "6pm"],
        [19, "7pm"],
        [20, "8pm"],
        [21, "9pm"],
        [22, "10pm"],
        [23, "11pm"],
      ];
      const columns = [
        {
          field: "name",
          headerName: "Name",
          minWidth: 100,
          flex: 1,
          renderCell: (params) => {
            let tooltip = <div></div>;
            if (params.row.badApiKey) {
              tooltip = (
                <Tooltip
                  placement="right"
                  title={
                    <Typography>
                      The Endpoint this alert is scheduled for is checking in
                      with a bad api key. Click here to get a new one, then use
                      the ServiceManager to login with your new api key.
                    </Typography>
                  }
                >
                  <Link to="/profile">
                    <IconButton>
                      <AiOutlineWarning />
                    </IconButton>
                  </Link>
                </Tooltip>
              );
            }

            return (
              <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                <Link
                  to={`/${current_customer.uuid}/alertvalidation/alerts/history/${encryptId(params.row.id)}`}
                >
                  {params.value}
                </Link>
                {tooltip}
              </Stack>
            );
          },
        },
        {
          field: "endpoint",
          headerName: "Endpoint",
          minWidth: 100,
          flex: 1,
          renderCell: (params) => {
            const link = `/${current_customer.uuid}/simulations/endpoints/detail/${params.row.endpoint_uuid}`;
            const lastCheckin = params.row.lastCheckin;
            const timeDiffInHrs = moment()
              .utc()
              .diff(moment(lastCheckin), "hours");
            const showWarning = timeDiffInHrs >= 1 ? true : false;
            const isExercizeActive = params.row.active;
            return (
              <Stack direction="row" spacing={1}>
                <Link to={link}>{params.row.endpoint}</Link>
                {/* if agent failing to checking while not being paused. */}
                {showWarning && isExercizeActive && (
                  <Tooltip title="Service failing to check in, ensure the service is still running.">
                    <Warning color="error" />
                  </Tooltip>
                )}
              </Stack>
            );
          },
        },
        {
          field: "simulation",
          headerName: "Simulation",
          minWidth: 100,
          flex: 1,
        },
        {
          field: "created",
          headerName: "Created",
          minWidth: 120,
          flex: 1,
          renderCell: (params) => {
            return (
              <div>
                {moment(params.row.created).format("MMM DD,YYYY, h:mm a")}
              </div>
            );
          },
        },
        {
          field: "lastRun",
          headerName: "Last Run",
          minWidth: 120,
          flex: 1,
        },
        {
          field: "nextRun",
          headerName: "Next Run",
          minWidth: 130,
          flex: 1,
          renderCell: (params) => {
            // Check if next scheduled run exists
            if (params.row.nextRun) {
              return <div>{params.row.nextRun}</div>;
            }
            return <div>N/A</div>;
          },
        },
        {
          field: "frequency",
          headerName: "Frequency",
          minWidth: 100,
          flex: 0,
        },
        {
          field: "localtime",
          headerName: "Time To Run",
          minWidth: 120,
          flex: 0,
        },
        {
          field: "options",
          headerName: "Actions",
          minWidth: 100,
          flex: 1,
          renderCell: (params) => {
            const onEditClick = () => {
              const idx = alerts.findIndex((alert) => alert.id === params.id);
              setSelectedAlert(alerts[idx]);
              setOpen(true);
            };

            const onPauseClick = async () => {
              const data = {
                active: !params.row.active,
              };
              let result = await patchRequest(
                `/api/v2/${current_customer.uuid}/scheduledexercises/${params.row.id}`,
                accessToken,
                data,
              );
              if (result.status === 200) {
                let idx = alerts.findIndex((row) => row.id === params.id);
                if (idx !== -1) {
                  alerts[idx].active = data.active;
                }
                populateDataGrid(alerts);
              }
            };

            return (
              <Stack
                direction={"row"}
                sx={{ width: "100%", justifyContent: "center" }}
              >
                <Tooltip
                  title={
                    params.row.active ? "Pause Exercise" : "Resume Execution"
                  }
                >
                  <IconButton onClick={onPauseClick}>
                    {params.row.active ? (
                      <FaPause color={COLORS.secondary.main} />
                    ) : (
                      <FaPlay color={COLORS.secondary.main} />
                    )}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit Scheduled Exercise">
                  <IconButton onClick={onEditClick}>
                    <FaEdit color={COLORS.secondary.main} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete Scheduled Exercise">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteName(params.row.name);
                      setDeleteModal(true);
                      setDeleteId(params.id);
                    }}
                  >
                    <FaTrashAlt color={COLORS.error.main} />
                  </IconButton>
                </Tooltip>
              </Stack>
            );
          },
        },
      ];

      const rows = alerts.map((alert) => {
        let localTime = convertUtcToLocal(alert.time);
        let nextRunDate;
        let nextRun;
        if (alert.active) {
          nextRunDate = new Date(alert.next_scheduled_run);
          let localHour = convertUtcToLocal(nextRunDate.getHours());
          nextRunDate.setHours(localHour);
          nextRun = moment(nextRunDate).format("MMM DD,YYYY, h:mm a");
        } else {
          nextRun = "N/A";
        }
        return {
          id: alert.id,
          uuid: alert.uuid,
          name: alert.name,
          endpoint_uuid: alert.endpoint.uuid,
          endpoint: alert.endpoint.name,
          simulation: alert.payload
            ? alert.payload.name
            : alert.simulation.name,
          created: alert.created,
          lastRun: alert.last_run
            ? moment(alert.last_run).format("MMM DD,YYYY, h:mm a")
            : "Never",
          nextRun: nextRun,
          frequency:
            alert.frequency[0].toUpperCase() +
            alert.frequency.slice(1, alert.frequency.length),
          time: alert.time,
          localtime: timeToRunOptions[localTime][1],
          badApiKey: alert.bad_api_key,
          active: alert.active,
          lastCheckin: alert.endpoint.service_check_in,
        };
      });
      setAlerts(alerts);
      setDataTableRows(rows);
      setDataTableColumns(columns);
    },
    [accessToken, current_customer],
  );

  // ----- VARIABLES -----

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getAlerts = async () => {
      const res = await getRequest(
        // "/react/api/${current_customer.uuid}/alertvalidation/scheduled-exercises",
        `/api/v2/${current_customer.uuid}/scheduledexercises?depth=1`,
        accessToken,
      );
      setLoaded(true);
      populateDataGrid(res.data);
    };

    getAlerts();
  }, [current_customer, accessToken, populateDataGrid, refresh]);

  return (
    <Stack spacing={3}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Alert Validation</Typography>
        <Typography color="text.primary">Scheduled Exercises</Typography>
      </Breadcrumbs>

      {/* Page Title */}
      <Typography variant="h3">Scheduled Exercises</Typography>

      {!loaded ? (
        <CircularProgress />
      ) : (
        <>
          {dataTableRows.length === 0 ? (
            <Alert severity="info">
              <AlertTitle>No Schedule Exercises</AlertTitle>
              Go to the{" "}
              <Link to={`/${current_customer.uuid}/alertvalidation/library`}>
                <b>Exercise Library</b>
              </Link>{" "}
              to schedule you first exercise
            </Alert>
          ) : (
            <DataGridWithStyles
              name="av-alerts"
              autoHeight
              paper
              rows={dataTableRows}
              columns={dataTableColumns}
              disableRowSelectionOnClick
            />
          )}
        </>
      )}

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <AlertForm
            alert={selectedAlert}
            simulation={selectedAlert.simulation}
            setOpen={() => {
              setOpen(false);
              setRefresh(refresh + 1);
            }}
          />
        </div>
      </Modal>
      {/* Delete Modal */}

      <FormModal open={deleteModal} setOpen={setDeleteModal}>
        <Stack spacing={2}>
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: 4 }}
          >
            Are you sure you want to remove Exercise {deleteName} ?
          </Typography>
          <Button onClick={() => onDeleteClick()} variant="contained">
            Yes
          </Button>
        </Stack>
      </FormModal>
    </Stack>
  );
};

export default AV_Alerts;
