import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  FaTachometerAlt,
  FaChess,
  FaLaptop,
  FaShieldAlt,
  FaFile,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../Redux/app/hooks";

const iconColor = "#BDCCD4";
const iconSize = "16px";

const SMVnav = ({ open }) => {
  const current_customer = useAppSelector(
    (state) => state.customer,
  );
  const data = {
    navHeader: "SERVICE MONITORING VALIDATION",
    links: [
      {
        icon: <FaTachometerAlt color={iconColor} size={iconSize} />,
        text: "Campaigns",
        href: "/simulations/campaigns/list",
      },
      {
        icon: <FaChess color={iconColor} size={iconSize} />,
        text: "Simulation Library",
        href: "/simulations/list",
      },
      {
        icon: <FaLaptop color={iconColor} size={iconSize} />,
        text: "Endpoints",
        href: "/simulations/endpoints/list",
      },
      {
        icon: <FaShieldAlt color={iconColor} size={iconSize} />,
        text: "Security Tools",
        href: "/config/securitytools",
      },
      {
        icon: <FaFile color={iconColor} size={iconSize} />,
        text: "Templates",
        href: "/reporting/templates",
      },
    ],
  };
  return (
    <div>
      {open ? (
        <Typography
          variant="h6"
          px={1}
          mt={3}
          sx={{ fontSize: 10, color: "#BDCCD4" }}
        >
          {data.navHeader}
        </Typography>
      ) : null}
      {data.links.map((link) => {
        return (
          <ListItem key={link.text} disablePadding sx={{ display: "block" }}>
            <Link to={`/${current_customer.uuid}${link.href}`}>
              <ListItemButton
                sx={{
                  minHeight: 32,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {link.icon}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "12px" }}
                  primary={link.text}
                  sx={{ opacity: open ? 1 : 0, color: "white" }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
        );
      })}
    </div>
  );
};

export default SMVnav;
