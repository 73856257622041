import React, { useEffect, useState } from "react";
import {
  Breadcrumbs,
  Typography,
  Stack,
  Modal,
  IconButton,
  Button,
  Box,
  Paper,
} from "@mui/material";
import TextButton from "../../../Components/Buttons/TextButton";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import { COLORS } from "../../../Styles/colors";
import FormModal from "../../../Components/Modals/FormModal";
import { ServiceProviderForm } from "../../../Forms";
import { useHttpRequest } from "../../../Hooks";

const AdminServiceProvider = () => {
  // ----- CONTEXT -----

  // ----- STATES -----
  const [dataTableRows, setDataTableRows] = useState([]);
  const [dataTableColumns, setDataTableColumns] = useState([]);
  const [provider, setProvider] = useState(null);
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [deleteID, setDeleteID] = useState(null);
  const [deleteName, setDeleteName] = useState("");

  // ----- VARIABLES -----
  /**
   * These are the styles for the Box inside of the modal.
   * The Modal component renders as position Absolute so we
   * have to write styles accordingly
   */
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };

  // ----- Functions -----
  const { response, fetch } = useHttpRequest({
    method: "GET",
    path: `/api/v2/service-providers`,
    defer: true,
  });

  const { response: cxResponse, loading } = useHttpRequest({
    method: "GET",
    path: `/api/v2/customers`,
  });

  const { response: deleteResponse, fetch: deleteProvider } = useHttpRequest({
    method: "DELETE",
    path: `/api/v2/service-providers/`,
    defer: true,
  });

  //Function used to get information to edit provider in modal
  const handleCellClick = (params) => {
    setProvider(params.row.id);
    setOpen(true);
  };

  const handleDelete = () => {
    deleteProvider(deleteID);
    setDeleteModal(false);
  };

  const handleCreate = () => {
    setProvider(null);
    setOpen(true);
  };

  // Closes the add tenant modal
  const handleClose = () => {
    setOpen(false);
    setProvider(null);
  };

  // ----- On page load -----

  // Sets customers state AFTER response is received
  useEffect(() => {
    // Prevents functions from being called twice
    if (cxResponse) {
      setCustomers(cxResponse);
    }
  }, [cxResponse]);

  // Fetches datagrid data AFTER modal closes
  useEffect(() => {
    if (!open) {
      fetch();
    }
  }, [open, fetch]);

  // After delete response is back, fetch updated data
  useEffect(() => {
    if (deleteResponse) {
      fetch();
    }
  }, [deleteResponse, fetch]);

  useEffect(() => {
    const populateDataGrid = (service_providers) => {
      const columns = [
        {
          field: "id",
          headerName: "ID",
          renderCell: (params) => {
            return <p>{params.row.id}</p>;
          },
        },
        {
          field: "provider",
          headerName: "Provider",
          minWidth: 100,
          flex: 1,
          renderCell: (params) => {
            const customerID = params.row.customer;
            const customer = customers.find((cx) => cx.id === customerID);
            return (
              <Typography
                className="text-links"
                onClick={() => handleCellClick(params)}
              >
                {customer?.name}
              </Typography>
            );
          },
        },
        {
          field: "customers",
          headerName: "Customers",
          minWidth: 100,
          flex: 1,
          renderCell: (params) => {
            return params.row.customers.length;
          },
        },
        {
          field: "delete",
          headerName: "Delete Provider",
          minWidth: 100,
          flex: 1,
          renderCell: (params) => {
            const id = params.row.id;
            const customerID = params.row.customer;
            const customer = customers.find((cx) => cx.id === customerID);
            const modalOpen = () => {
              setDeleteModal(true);
              setDeleteName(customer?.name)
              setDeleteID(id);
            };

            return (
              <>
                <IconButton onClick={modalOpen} title="Delete Template">
                  <FaTrashAlt color={COLORS.error.main} />
                </IconButton>
              </>
            );
          },
        },
      ];

      const rows = service_providers.map((sp, index) => {
        return {
          id: sp.id,
          customer: sp.customer,
          customers: sp.customers,
          delete: sp.id,
        };
      });

      setDataTableRows(rows);
      setDataTableColumns(columns);
    };
    if (customers.length > 0 && response) populateDataGrid(response);
  }, [customers, response, open, deleteProvider, deleteModal]);

  return (
    <>
      <Stack spacing={3}>
        {/* Breadcrumbs */}
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="text.primary">Administration</Typography>
          <Typography color="text.primary">Service Provider</Typography>
        </Breadcrumbs>
        {/* Page Title  && Buttons */}
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography variant="h3">Service Providers</Typography>
          <TextButton
            tooltip="Add Service Provider"
            icon={FaPlus}
            onClick={handleCreate}
          />
        </Stack>
        {/* DataGrid */}
        <DataGridWithStyles
          name="admin-service-providers"
          autoHeight
          pointer
          paper
          rows={dataTableRows}
          columns={dataTableColumns}
          loading={loading}
        />

        <Modal open={open} onClose={handleClose}>
          <Paper sx={style} className="Modal-Container">
            <ServiceProviderForm
              type={provider ? "edit" : "create"}
              onComplete={handleClose}
              id={provider}
            />
          </Paper>
        </Modal>

        <FormModal open={deleteModal} setOpen={setDeleteModal}>
          <Box component="form">
            <Typography
              variant="h6"
              style={{ textAlign: "center", marginBottom: 4 }}
            >
              Are you sure you want to remove provider {deleteName}?
            </Typography>
            <Stack direction="column" spacing={2}>
              <Button onClick={handleDelete} variant="contained">
                Yes
              </Button>
            </Stack>
          </Box>
        </FormModal>
      </Stack>
    </>
  );
};

export default AdminServiceProvider;
