import React, { useEffect, useState, useContext } from "react";
import { Typography, Box, Breadcrumbs, Paper, Stack } from "@mui/material";
import {
  FaCamera,
  FaLaptop,
  FaThumbsDown,
  FaThumbsUp,
  FaQuestionCircle,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { DataContext } from "../../../Context/dataContext";
import { getRequest } from "../../../Helpers/httpRequests";
import TextButton from "../../../Components/Buttons/TextButton";

import moment from "moment";
import { useAppSelector } from "../../../Redux/app/hooks";

const Reports_Endpoints_Details = () => {
  // ----- STATES && CONTEXT -----

  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );
  const [reloadEndpoints, setReloadEndpoints] = useState(true);
  const [fail, setFail] = useState([]);
  const [notscored, setNotScored] = useState([]);
  const [pass, setPass] = useState([]);
  const [endpoint, setEndpoint] = useState([]);
  const [agentHost, setAgentHost] = useState([]);
  const [AV, setAV] = useState([]);
  const [screenshots, setScreenshots] = useState([]);
  const [sessions, setSessions] = useState([]);

  // ----- VARIABLES -----

  const { endpointUUID } = useParams();

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getData = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/report/endpoints/${endpointUUID}`,
        accessToken
      );
      try {
        setFail(res.data.counts.fail);
        setNotScored(res.data.counts.notscored);
        setPass(res.data.counts.pass);
        setEndpoint(res.data.agent);
        setAgentHost(res.data.agent_host);
        setAV(res.data.av);
        setSessions(res.data.sessions);
        setScreenshots(res.data.screenshots);
      } catch (err) {
        console.log("Error while trying to set States from getData()", err);
      }
    };

    // TODO: This is not the way to do this. This is a hack please re-write your logic to refresh the page in a more elegant way.
    if (reloadEndpoints === true) {
      getData();
      setReloadEndpoints(false);
    }

    //eslint-disable-next-line
  }, [current_customer, accessToken]);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">Home</Link>
        <Link to={`/${current_customer.uuid}/reporting/endpoints`}>
          Endpoints Assessments
        </Link>
        <Typography color="text.primary">Endpoint Details</Typography>
      </Breadcrumbs>

      <Box
        sx={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Typography fontWeight={"bold"} variant="h4">
          Assessment for {endpoint.name}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: "row",
          }}
        >
          <Link
            to={`/${current_customer.uuid}/simulations/endpoints/detail/${endpointUUID}`}
          >
            <TextButton tooltip="Agent Details" icon={FaLaptop} />
          </Link>
          <Link
            to={`/${current_customer.uuid}/endpoints/actions/screenshot/${endpointUUID}`}
          >
            <TextButton tooltip="Refresh Screenshot" icon={FaCamera} />
          </Link>
        </Box>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box
          className="left-box"
          sx={{ flex: 1, paddingRight: 1, marginTop: 1 }}
        >
          <Paper sx={{ marginBottom: 2, padding: 2 }}>
            <Typography component={"span"}>
              {screenshots.image != null ? (
                <>
                  <Typography
                    component={"span"}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <Typography>{endpoint?.name}</Typography>
                  </Typography>
                  <Typography>
                    Screenshot taken at{" "}
                    {moment(screenshots.timestamp).format("MMM D,YYYY hh:mm a")}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    component={"span"}
                    variant="h6"
                    textAlign={"right"}
                  >
                    No screenshots exist for this endpoint.
                  </Typography>
                </>
              )}
            </Typography>
          </Paper>

          <Paper sx={{ marginBottom: 2, padding: 2 }}>
            <Typography
              component={"span"}
              fontWeight={"bold"}
              variant="h6"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                marginBottom: 2,
              }}
            >
              Assessment Details
            </Typography>
            <Stack sx={{ marginTop: 1 }}>
              <Box sx={{ marginLeft: 10 }}>
                Name
                <Typography
                  sx={{ marginLeft: 5 }}
                  fontWeight={"bold"}
                  component={"span"}
                >
                  {endpoint.name}
                </Typography>
              </Box>
              <Box sx={{ marginLeft: 10 }}>
                Host Domain
                <Typography
                  sx={{ marginLeft: 5 }}
                  fontWeight={"bold"}
                  component={"span"}
                >
                  {agentHost.domain}
                </Typography>
              </Box>
              <Box sx={{ marginLeft: 10 }}>
                Platform
                <Typography
                  sx={{ marginLeft: 5 }}
                  fontWeight={"bold"}
                  component={"span"}
                >
                  {endpoint.host_os}
                </Typography>
              </Box>
              <Box sx={{ marginLeft: 10 }}>
                Licensed
                <Typography
                  sx={{ marginLeft: 5 }}
                  fontWeight={"bold"}
                  component={"span"}
                >
                  {endpoint.license === true ? "true" : "false"}
                </Typography>
              </Box>
              <Box sx={{ marginLeft: 10 }}>
                AV Registered
                <Typography
                  sx={{ marginLeft: 5 }}
                  fontWeight={"bold"}
                  component={"span"}
                >
                  {AV.display_name === null ? "None" : AV.display_name}
                </Typography>
              </Box>
              <Box sx={{ marginLeft: 10 }}>
                IP Addresses
                <Typography
                  sx={{ marginLeft: 5 }}
                  fontWeight={"bold"}
                  component={"span"}
                >
                  {agentHost.ip_address}
                </Typography>
              </Box>
              <Box sx={{ marginLeft: 10 }}>
                DNS Name
                <Typography
                  sx={{ marginLeft: 5 }}
                  fontWeight={"bold"}
                  component={"span"}
                >
                  {agentHost.dns_hostname}
                </Typography>
              </Box>
              <Box sx={{ marginLeft: 10 }}>
                Install Date
                <Typography
                  sx={{ marginLeft: 5 }}
                  fontWeight={"bold"}
                  component={"span"}
                >
                  {moment(endpoint.installed_date).format(
                    "MMM. D, YYYY, hh:mm a"
                  )}
                </Typography>
              </Box>
              <Box sx={{ marginLeft: 10 }}>
                Agent User Context
                <Typography
                  sx={{ marginLeft: 5 }}
                  fontWeight={"bold"}
                  component={"span"}
                >
                  {agentHost?.username}
                </Typography>
              </Box>
              <Box sx={{ marginLeft: 10 }}>
                Logged in User
                <Typography
                  sx={{ marginLeft: 5 }}
                  fontWeight={"bold"}
                  component={"span"}
                >
                  {sessions[0]?.username}
                </Typography>
              </Box>
              <Box sx={{ marginLeft: 10 }}>
                Domain
                <Typography
                  sx={{ marginLeft: 5 }}
                  fontWeight={"bold"}
                  component={"span"}
                >
                  {sessions[0]?.domain}
                </Typography>
              </Box>
              <Box sx={{ marginLeft: 10 }}>
                Admin
                <Typography
                  sx={{ marginLeft: 5 }}
                  fontWeight={"bold"}
                  component={"span"}
                >
                  {sessions[0]?.localAdmin === true ? "Yes" : "No"}
                </Typography>
              </Box>
              <Box sx={{ marginLeft: 10 }}>
                Login Date
                <Typography
                  sx={{ marginLeft: 5 }}
                  fontWeight={"bold"}
                  component={"span"}
                >
                  {moment(sessions[0]?.logonTime).format("MMM D,YYYY hh:mm a")}
                </Typography>
              </Box>
              <Box sx={{ marginLeft: 10 }}>
                LogonType
                <Typography
                  sx={{ marginLeft: 5 }}
                  fontWeight={"bold"}
                  component={"span"}
                >
                  {sessions[0]?.logonFriendlyType}
                </Typography>
              </Box>
            </Stack>
          </Paper>
        </Box>

        <Box
          className="right-box"
          sx={{
            flex: 1,
            display: "flex",
            marginLeft: 3,
            flexDirection: "column",
            height: 50,
          }}
        >
          <Box
            className="right-box-inner"
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginLeft: 3,
            }}
          >
            <Paper
              sx={{
                flex: 1,
                marginRight: 5,
                marginTop: 1,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FaThumbsDown
                style={{ marginTop: 10, marginLeft: 10 }}
                size={40}
                color={"red"}
              />
              <Stack sx={{ marginLeft: 5, marginTop: 1 }}>
                <Typography
                  component={"span"}
                  fontSize="25px"
                  fontWeight={"bold"}
                  color={"red"}
                >
                  {fail}
                </Typography>
                <Typography
                  component={"span"}
                  fontSize="15px"
                  color="text.primary"
                >
                  Failed
                </Typography>
              </Stack>
            </Paper>

            <Paper
              sx={{
                flex: 1,
                marginRight: 5,
                marginTop: 1,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FaThumbsUp
                style={{ marginTop: 10, marginLeft: 10 }}
                size={40}
                color={"green"}
              />
              <Stack sx={{ marginLeft: 5, marginTop: 1 }}>
                <Typography
                  component={"span"}
                  fontSize="25px"
                  fontWeight={"bold"}
                  color={"green"}
                >
                  {pass}
                </Typography>
                <Typography
                  component={"span"}
                  fontSize="15px"
                  color="text.primary"
                >
                  Passed
                </Typography>
              </Stack>
            </Paper>

            <Paper
              sx={{
                flex: 1,
                marginTop: 1,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FaQuestionCircle
                style={{ marginTop: 10, marginLeft: 10 }}
                size={40}
                color={"orange"}
              />
              <Stack sx={{ marginLeft: 5, marginTop: 1 }}>
                <Typography
                  component={"span"}
                  fontSize="25px"
                  fontWeight={"bold"}
                  color={"orange"}
                >
                  {notscored}
                </Typography>
                <Typography
                  component={"span"}
                  fontSize="15px"
                  color="text.primary"
                >
                  Not Scored
                </Typography>
              </Stack>
            </Paper>
          </Box>
          <Link
            sx={{ textAlign: "right", marginTop: 2, fontSize: 20 }}
            to={`/${current_customer.uuid}/reporting/endpoints`}
          >
            View All Assessments
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default Reports_Endpoints_Details;
