import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../../Types"; // Update the import path as necessary

// Define the initial state with type annotation
const initialState: User = {
  archived: false,
  uuid: "",
  user: {
    id: null,
    username: null,
    email: null,
  },
  role: "Analyst",
  first: "",
  last: "",
  customer_access: [],
  superuser: false,
  current_customer_view: null,
  current_customer: null,
  primary_customer: null,
  minimized: false,
  eula_ack: false,
  eula_agree_executed: null,
  privacy_ack: false,
  privacy_agree_executed: null,
  last_notifs_read: null,
  release_ack: false,
  selenium: false,
  selenium_pass: null,
  selenium_username: null,
  isLoaded: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<Partial<User>>) => {
      return {
        ...state,
        ...action.payload,
        isLoaded: true,
      };
    },
    updateUserEulaAgreement: (state, action: PayloadAction<boolean>) => {
      state.eula_ack = action.payload;
    },
    updateUserPrivacyAgreement: (state, action: PayloadAction<boolean>) => {
      state.privacy_ack = action.payload;
    },
  },
});
export const {
  updateUser,
  updateUserEulaAgreement,
  updateUserPrivacyAgreement,
} = userSlice.actions;
export default userSlice.reducer;
