import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const FormSelect = (props) => {
  const handleSelectChange = (e) => {
    props.setFormData((prev) => ({ ...prev, [props.formKey]: e.target.value }));
  };

  if (props.options === undefined) {
    return null;
  }

  let styles = {};

  if (!props.noDetails) {
    styles = { width: `${props.split[0]}%` || "25%" };
  }

  return (
    <FormControl sx={styles}>
      <InputLabel required={props.required}>{props.label}</InputLabel>
      <Select
        value={props.formData[props.formKey] || ""}
        onChange={(e) => handleSelectChange(e)}
        label={props.label}
        multiple={props.multiple === "true"}
      >
        {props.options.map((opt, i) => {
          return (
            <MenuItem key={`FormSelect-${opt}-${i}`} value={opt[0]}>
              {opt[1]}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

FormSelect.defaultProps = {
  required: false,
};

export default FormSelect;
