import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const getDarkMode = (): boolean => {
  const darkModeCookie = Cookies.get("darkmode");
  return darkModeCookie === "true";
};

const initialState: boolean = getDarkMode();

export const darkmodeSlice = createSlice({
  name: "darkmode",
  initialState,
  reducers: {
    toggleDarkMode: (state) => {
      const newState = !state;
      Cookies.remove("darkmode");
      Cookies.set("darkmode", newState, { expires: 365 });
      return newState;
    },
  },
});

export const { toggleDarkMode } = darkmodeSlice.actions;
export default darkmodeSlice.reducer;
