import { Box, Paper, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { GridToolbar } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import DataGridWithStyles from "../../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import { DataContext } from "../../../../Context/dataContext";
import { getRequest } from "../../../../Helpers/httpRequests";
import CampaignContext from "../provider/CampaignContext";
import { techOutcomesColumns } from "../utils";
import { useAppSelector } from "../../../../Redux/app/hooks";

const TechOutcomes = () => {
  const { executedUUID } = useContext(CampaignContext); // Access campaign from context
  const { accessToken } = useContext(DataContext);
  const [techOutcomes, setTechOutcomes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const customer = useAppSelector((state) => state.customer);

  const populateTechOutcomes = (techs) => {
    const rows = techs.map((tech) => {
      return {
        id: tech.id,
        name: tech.id,
        blocked: tech.blocked,
        alerted: tech.alerted,
        logged: tech.logged,
        no_evidence: tech.nothing,
        not_scored: tech.not_scored,
      };
    });
    setTechOutcomes(rows);
  };

  useEffect(() => {
    const fetchTechOutcomes = async () => {
      try {
        const res = await getRequest(
          `/react/api/${customer.uuid}/report/campaign/TechOutcomes/${executedUUID}`,
          accessToken
        );

        if (res.status === 200) {
          populateTechOutcomes(res.data.tech_outcomes);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTechOutcomes();
  }, [executedUUID, accessToken, customer.uuid]);

  return (
    <Box className="right-box" sx={{ flex: 1, paddingLeft: 3, marginTop: 2 }}>
      <Paper sx={{ padding: 2, marginTop: 2, marginBottom: 10 }}>
        <Typography fontWeight={"bold"} variant="h6">
          Outcomes Counts By ATTACK Technique
        </Typography>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography align="left" color="error">
            Error: {error.message}
          </Typography>
        ) : (
          <DataGridWithStyles
            name="outcome-counts-camp"
            components={{ Toolbar: GridToolbar }}
            autoHeight
            rows={techOutcomes}
            columns={techOutcomesColumns}
          />
        )}
      </Paper>
    </Box>
  );
};

export default TechOutcomes;
