import { LoadingButton } from "@mui/lab";
import { Divider } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../../Context/dataContext";
import { getRequest, postRequest } from "../../../../Helpers/httpRequests";
import { toast } from "react-toastify";
import {
  Typography,
  Stack,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useAppSelector } from "../../../../Redux/app/hooks";

const AdminAddC2Form = ({ setOpen, existing }) => {
  // ----- CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const user = useAppSelector((state) => state.user);

  // ----- STATES -----
  const [vendors, setVendors] = React.useState([]);
  const [customers, setCustomers] = React.useState([]);

  const initialFormData = {
    uuid: existing?.uuid || "",
    name: existing?.name || "",
    vendor: existing?.vendor || "",
    username: existing?.username || "",
    password: existing?.password || "",
    url: existing?.url || "",
    version: existing?.version || "",
    integrated: existing?.integrated || false,
    customerUUID: existing?.customerUUID || "",
    active: existing?.active || true,
  };

  /**
   * Object to hold the values of each input in form.
   */
  const [formData, setFormData] = React.useState(initialFormData);

  /**
   * Used for handling the props/children of <LoadingButton>
   */
  const [loadingBtn, setLoadingBtn] = useState({
    loading: false,
    color: "primary",
    text: "Submit",
    disabled: true,
    helperText: "",
    helperTextColor: "green",
  });

  // ---- Variables ------

  // ----- Functions -----

  /**
   * Checks each field in form to see
   * if they are not empty before enabling button
   */
  const onFormChange = () => {
    // Name cannot be empty
    if (formData.name === "") {
      setLoadingBtn((data) => ({ ...data, disabled: true }));
      return;
    }

    // Vendor is required
    if (formData.vendor === "") {
      setLoadingBtn((data) => ({ ...data, disabled: true }));
      return;
    }

    // Username is required
    if (formData.username === "") {
      setLoadingBtn((data) => ({ ...data, disabled: true }));
      return;
    }

    // Password is required
    if (formData.password === "") {
      setLoadingBtn((data) => ({ ...data, disabled: true }));
      return;
    }

    // Url is required
    if (formData.url === "") {
      setLoadingBtn((data) => ({ ...data, disabled: true }));
      return;
    }

    // Version only required for scythe
    if (formData.version === "" && formData.vendor === "scythe") {
      setLoadingBtn((data) => ({ ...data, disabled: true }));
      return;
    }

    setLoadingBtn((data) => ({ ...data, disabled: false }));
  };

  /**
   * Handles the form submission.
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    // prevent page reload on submit
    e.preventDefault();

    // set submit btn to loading
    setLoadingBtn((prev) => ({ ...prev, loading: true }));

    const res = await postRequest(
      `/react/api/admin/c2-systems-form?existing=${existing !== undefined}`,
      accessToken,
      formData,
      true
    );
    setLoadingBtn((prev) => ({ ...prev, loading: false }));

    // send form response back as 201 (created)
    if (res.status === 201) {
      // Success popup
      toast.success("Success!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setLoadingBtn({
        disabled: false,
        loading: false,
        text: "Success",
        color: "success",
        helperText: "Success",
        helperTextColor: "#2e7d32", // green
      });

      /**
       * If this form is contained in a modal, you can pass the setOpen state to this component.
       * This will wait 1.5 seconds and then close the modal
       * Add 'open' state to this components parent useEffect dependencies array to have your page reload the data.
       */
      if (setOpen !== undefined) {
        setOpen(false);
      }
    } else {
      // handle errors here
      setLoadingBtn({
        disabled: false,
        loading: false,
        text: "Error - Try again",
        color: "error",
        helperText: "Form submission failed",
        helperTextColor: "#d32f2f", // red
      });
      console.log(
        "%cerror AdminAddC2Form.jsx handleSubmit()",
        "color: red; display: block; width: 100%;",
        "Failed to submit form"
      );
    }
  };

  // ----- On page load -----
  useEffect(() => {
    const getFormData = async () => {
      const res = await getRequest(
        `/react/api/admin/c2-systems-form`,
        accessToken
      );
      if (res.status === 200) {
        setVendors(res.data.vendors);
      }
    };

    const getCustomers = async () => {
      const res = await getRequest(`/react/api/admin-customers`, accessToken);
      if (res.status === 200) {
        setCustomers(res.data.customers);
      }
    };

    // if user.current_customer changes re-run api-request
    if (user.current_customer) {
      getFormData();
      getCustomers();
    }
  }, [user.current_customer, accessToken]);

  /**
   * This is for detecting form change and
   * disabled/enabling the button accordingly
   */
  useEffect(() => {
    onFormChange();
    // eslint-disable-next-line
  }, [formData]);

  return (
    <>
      <Stack
        direction="row"
        sx={{ width: "auto" }}
        justifyContent="space-evenly"
        data-testid="test-comp"
      >
        <Box sx={{ width: "50%" }}>
          {/* Form Header */}
          <Typography variant="h5">Add C2 System</Typography>
          <Divider style={{ marginBottom: "0.5rem" }} />
          {/* FORM */}
          <form onSubmit={(e) => handleSubmit(e)}>
            <Stack direction="column" spacing={2}>
              {/* Start form fields here */}
              {/* Name */}
              <TextField
                placeholder="Name"
                label="Name"
                value={formData.name}
                required
                onChange={(e) =>
                  setFormData((data) => ({ ...data, name: e.target.value }))
                }
              />
              {/* Vendor */}
              <FormControl required margin="none">
                <InputLabel id="vendor-select-label">Vendor</InputLabel>
                <Select
                  labelId="vendor-select-label"
                  id="vendor-select"
                  value={vendors.length > 0 ? formData.vendor : ""}
                  label="Vendor *"
                  required={true}
                  inputProps={{
                    label: "Vendor Input",
                    "data-testid": "VendorInput",
                  }}
                  onChange={(e) =>
                    setFormData((data) => ({
                      ...data,
                      vendor: e.target.value,
                    }))
                  }
                >
                  {vendors.map((vendor) => (
                    <MenuItem
                      name="vendor-item"
                      data-testid={`${vendor}-vendorMenuItem`}
                      key={`${vendor}-choice`}
                      value={vendor}
                    >
                      {vendor}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* Username */}
              <TextField
                placeholder="Username"
                label="Username"
                required
                value={formData.username}
                onChange={(e) =>
                  setFormData((data) => ({ ...data, username: e.target.value }))
                }
              />
              {/* Password */}
              <TextField
                placeholder="Password"
                label="Password"
                required
                value={formData.password}
                onChange={(e) =>
                  setFormData((data) => ({ ...data, password: e.target.value }))
                }
              />
              {/* Url */}
              <TextField
                placeholder="Url"
                label="Url"
                type="url"
                required
                value={formData.url}
                onChange={(e) =>
                  setFormData((data) => ({ ...data, url: e.target.value }))
                }
              />
              {/* Version */}
              <TextField
                sx={{
                  display:
                    formData.vendor !== "scythe" ? "none" : "inline-flex",
                }}
                placeholder="Version"
                label="Version"
                required={formData.vendor === "scythe"}
                value={formData.version}
                onChange={(e) =>
                  setFormData((data) => ({ ...data, version: e.target.value }))
                }
              />

              {/* Customer */}
              <FormControl margin="none">
                <InputLabel id="customer-select-label">Customer</InputLabel>
                <Select
                  labelId="customer select label"
                  id="customer-select"
                  value={formData.customerUUID || ""}
                  label="Customer *"
                  data-testid="CustomerSelect"
                  inputProps={{ "data-testid": "CustomerInput" }}
                  onChange={(e) =>
                    setFormData((data) => ({
                      ...data,
                      customerUUID: e.target.value,
                    }))
                  }
                >
                  {customers.map((cx) => (
                    <MenuItem key={cx.uuid} value={cx.uuid}>
                      {cx.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Integrated */}
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.integrated}
                      onChange={() =>
                        setFormData((data) => ({
                          ...data,
                          integrated: !data.integrated,
                        }))
                      }
                    />
                  }
                  label="Integrated"
                />
              </FormControl>

              {/* Active */}
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.active}
                      onChange={() =>
                        setFormData((data) => ({
                          ...data,
                          active: !data.active,
                        }))
                      }
                    />
                  }
                  label="Active"
                />
              </FormControl>
              {/* End form fields */}
              {/* helper text */}
              <Typography
                color={loadingBtn.helperTextColor}
                variant="subtitle2"
              >
                {loadingBtn.helperText}
              </Typography>
              {/* Submit Button */}
              <LoadingButton
                data-testid="AdminAddC2Form-submit-btn"
                loading={loadingBtn.loading}
                color={loadingBtn.color}
                disabled={loadingBtn.disabled}
                type="submit"
                variant="contained"
              >
                {loadingBtn.text}
              </LoadingButton>
            </Stack>
          </form>
        </Box>
        <Stack spacing={2} sx={{ pl: 3, width: "50%" }}>
          <Typography variant="h5" fontWeight="bold">
            Adding or editing a C2 entry:
          </Typography>
          <Typography variant="body1">
            <b>Name:</b> Whatever label you would like to give this C2 system.
          </Typography>
          <Typography variant="body1">
            <b>Vendor:</b> Select the correct entry from the list.
          </Typography>
          <Typography variant="body1">
            <b>Username:</b> The username to log into the C2 system.
          </Typography>
          <Typography variant="body1">
            <b>Password:</b> The password to log into the C2 system.
          </Typography>
          <Typography variant="body1">
            <b>Url:</b> URL to access the admin interface or designated API URL
            of the C2 system.
          </Typography>
          <Typography variant="body1">
            <b>Version:</b> For SCYTHE servers, please enter the version of the
            SCYTHE server this C2 system is running, usually 3.2 or 3.3.
          </Typography>
          <Typography variant="body1">
            <b>Integrated:</b> Check this box if you want Blindspot to
            automatically pull campaigns and reports from the C2 instance.
          </Typography>
          <Typography variant="body1">
            <b>Customer:</b> If this C2 server is unique to a specific customer
            in the Blindspot application, select it here.
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

AdminAddC2Form.propTypes = {
  setOpen: PropTypes.func,
  existing: PropTypes.object,
};

export default AdminAddC2Form;
