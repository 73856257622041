import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import ActionsCompleted from "./Components/ActionsCompleted";
import ActionsFailed from "./Components/ActionsFailed";
import ActionsTimeLine from "./Components/ActionsTimeLine";
import BSBreadcrumbs from "./Components/BSBreadcrumbs";
import CampaignContextInfo from "./Components/CampaignContextInfo";
import CampaignHeader from "./Components/CampaignHeader";
import CampaignInfo from "./Components/CampaignInfo";
import CampaignNotes from "./Components/CampaignNotes";
import CampaignScore from "./Components/CampaignScore";
import RefreshScore from "./Components/RefreshScore";
import TechOutcomes from "./Components/TechOutcomes";
import Techniques from "./Components/Techniques";
import ResultsBanner from "./ResultsBanner";
import CampaignDetailsProvider from "./provider/CampaignDetailsProvider";
import ActionsPermissionDenied from "./Components/ActionsPermissionDenied";

const ReportsCampaignDetails = () => {
  const { executedUUID } = useParams();

  return (
    <CampaignDetailsProvider executedUUID={executedUUID}>
      <BSBreadcrumbs />
      <CampaignHeader />
      <CampaignScore />
      <RefreshScore />
      <ActionsTimeLine />
      <ResultsBanner />
      <ActionsCompleted />
      <ActionsFailed />
      <ActionsPermissionDenied />
      <CampaignNotes />
      <Techniques />
      <Box sx={{ display: "flex" }}>
        <Box
          className="left-box"
          sx={{ flex: 1, paddingRight: 1, marginTop: 2 }}
        >
          <CampaignInfo />
          <CampaignContextInfo />
        </Box>
        <TechOutcomes />
      </Box>
    </CampaignDetailsProvider>
  );
};
export default ReportsCampaignDetails;
