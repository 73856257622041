import React, { useContext } from "react";
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  Stack,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { getRequest } from "../../../Helpers/httpRequests";
import { DataContext } from "../../../Context/dataContext";

const APIKeys = ({ keys, setKeys }) => {
  const { accessToken } = useContext(DataContext);
  if (keys === undefined) {
    keys = [];
  }

  function sortObjectKeysAlphabetically(obj) {
    // Create a new object to store the sorted entries
    const sortedObj = {};

    // Get the keys of the object, sort them, and then iterate over them
    Object.keys(obj)
      .sort()
      .forEach((key) => {
        sortedObj[key] = obj[key]; // Assign the value to the sorted key in the new object
      });

    return sortedObj;
  }

  const handleDelete = async (prefix) => {
    const res = await getRequest(
      `/react/api/delete-api-key/${prefix}`,
      accessToken
    );
    if (res.status === 200) {
      // organize keys by the customer
      const keys = res.data.keys;
      const organizedKeys = {};
      for (const i in keys) {
        if (organizedKeys[keys[i].key_customer] === undefined) {
          organizedKeys[keys[i].key_customer] = [keys[i]];
        } else {
          organizedKeys[keys[i].key_customer].push(keys[i]);
        }
      }
      setKeys(sortObjectKeysAlphabetically(organizedKeys));
    }
  };
  return (
    <Stack spacing={2}>
      {Object.keys(keys).map((key, index) => {
        return (
          <React.Fragment key={`api-key-key-${index}`}>
            <Typography variant="h5">{key} Keys</Typography>
            <List>
              {keys[key].map((key, index) => (
                <ListItem
                  key={`api-key-prefix-${key.key}`}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDelete(key.key)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={`${index + 1}: ${key.key}...`}
                    primaryTypographyProps={{ variant: "subtitle1" }}
                    secondary={key.name}
                    secondaryTypographyProps={{ variant: "subtitle2" }}
                  />
                </ListItem>
              ))}
            </List>
          </React.Fragment>
        );
      })}
    </Stack>
  );
};

export default APIKeys;
