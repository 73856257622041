import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TextButton from "../../../Components/Buttons/TextButton";
import { DataContext } from "../../../Context/dataContext";
import { getRequest } from "../../../Helpers/httpRequests";

import { FaPhoneAlt, FaPlus, FaTrashAlt } from "react-icons/fa";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import FormModal from "../../../Components/Modals/FormModal";
import { useHttpRequest } from "../../../Hooks";
import { COLORS } from "../../../Styles/colors";
import { useAppSelector } from "../../../Redux/app/hooks";

const ConfigIntegrations = () => {
  // ----- STATES && CONTEXT -----
  const [dataTableRows, setDataTableRows] = useState([]);
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer,
  );
  const [reloadSysCreds, setReloadSysCreds] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteUUID, setDeleteUUID] = useState("");
  const [deleteName, setDeleteName] = useState("");

  const { fetch: deleteSelected } = useHttpRequest({
    method: "DELETE",
    path: `/api/v2/integrations/${deleteUUID}`,
    defer: true,
  });

  //Variables

  const columns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 300,
      flex: 1,
      renderCell: (params) => {
        let link = `/${current_customer.uuid}/config/integrations/edit/${params.row.id}`;
        return <Link to={link}>{params.row.name}</Link>;
      },
    },
    { field: "URL", headerName: "URL", minWidth: 100, flex: 1 },
    { field: "username", headerName: "Username", minWidth: 100, flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      maxWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Stack direction="row" spacing={4}>
              <IconButton
                onClick={() => testSysCred(params)}
                title="Test Connection"
              >
                <FaPhoneAlt
                  id={`${params.row.id}-ping`}
                  color={COLORS.yellow}
                />
              </IconButton>
              <IconButton
                onClick={() => {
                  setDeleteName(params.row.name);
                  setDeleteUUID(params.row.id);
                  setDeleteModal(true);
                }}
                title="Delete Security"
              >
                <FaTrashAlt color={COLORS.error.main} />
              </IconButton>
            </Stack>
          </>
        );
      },
    },
  ];

  //Functions

  const testSysCred = async (params) => {
    const element = document.getElementById(`${params.row.id}-ping`);
    try {
      const res = await fetch(params.row.URL, {
        method: "GET",
        data: params.row.id,
        accessToken,
      });
      if (res.status === 200) {
        element.style.color = "green";
      } else {
        element.style.color = COLORS.error.main;
      }
    } catch (err) {
      console.log("Cannot access Url");
      element.style.color = "red";
    }
  };

  const deleteSysCred = async () => {
    deleteSelected();
    setDeleteModal(false);
    setReloadSysCreds((current) => !current);
  };

  // ----- ON PAGE LOAD -----

  useEffect(() => {
    const getSysCreds = async () => {
      const res = await getRequest(
        `/api/v2/${current_customer.uuid}/integrations`,
        accessToken,
      );
      if (res.status === 200) {
        populateDataGrid(res.data);
      }
    };

    const populateDataGrid = (sysCreds) => {
      const rows = sysCreds.map((sysCred) => {
        return {
          id: sysCred.id,
          name: sysCred.name,
          URL: sysCred.url,
          username: sysCred.username,
          actions: [],
        };
      });
      setDataTableRows(rows);
    };

    getSysCreds();
  }, [current_customer, accessToken, reloadSysCreds]);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">Home</Link>
        <Typography color="text.primary">Integrations</Typography>
      </Breadcrumbs>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Typography component={"span"} fontWeight={"bold"} variant="h4">
          Integrations
        </Typography>
        <Link to={`/${current_customer.uuid}/config/integrations/add`}>
          <TextButton tooltip="Add Integrations" icon={FaPlus} />
        </Link>
      </Box>

      <DataGridWithStyles
        name="config-integrations"
        autoHeight
        paper
        rows={dataTableRows}
        columns={columns}
      />
      {/* Delete Modal */}
      <FormModal open={deleteModal} setOpen={setDeleteModal}>
        <Box component="form">
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: 4 }}
          >
            Are you sure you want to remove {deleteName}?
          </Typography>
          <Stack direction="column" spacing={2}>
            <Button onClick={() => deleteSysCred()} variant="contained">
              Yes
            </Button>
          </Stack>
        </Box>
      </FormModal>
    </>
  );
};

export default ConfigIntegrations;
