import React from "react";
import { Breadcrumbs as MUIBreadcrumbs, Typography } from "@mui/material";

const BreadCrumbs = () => {
  return (
    <MUIBreadcrumbs aria-label="breadcrumb">
      <Typography color="text.primary">Simulations</Typography>
      <Typography color="text.primary">Simulation Library</Typography>
    </MUIBreadcrumbs>
  );
};

export default BreadCrumbs;
