import React, { useContext, useState } from "react";
import { Stack } from "@mui/system";
import { Typography, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { FaCheckCircle, FaJs, FaTimesCircle } from "react-icons/fa";
import { fileRequest } from "../../../../Helpers/httpRequests";
import TextButton from "../../../../Components/Buttons/TextButton/TextButton";
import { DataContext } from "../../../../Context/dataContext";
import { useAppSelector } from "../../../../Redux/app/hooks";

export const DownloadAlertJson = (props) => {
  const { uuid, name } = props;
  const [downloading, setDownloading] = useState(false);
  const [downloadSuccess, setDownloadSuccess] = useState(null);
  const [downloadMessage, setDownloadMessage] = useState("");

  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector((state) => state.customer);

  const downloadAlertJson = async () => {
    // present the loading process
    setDownloading(true);
    setDownloadMessage("");
    const res = await fileRequest(
      `/react/api/${current_customer.uuid}/alertvalidation/alert/campaign/${uuid}/json`,
      accessToken,
      `${name}.json`
    );
    if (res.status !== 200) {
      setDownloadSuccess(false);
      const blob = new Blob([res.data]);
      const data = await blob.text();
      const obj = JSON.parse(data);
      if (obj) setDownloadMessage("Template error: " + obj.msg);
      else setDownloadMessage("System error");
    } else {
      setDownloadSuccess(true);
    }
    setDownloading(false);
  };

  return (
    <div>
      {downloading ? (
        <Stack>
          <CircularProgress />
          <Typography>Downloading...</Typography>
        </Stack>
      ) : (
        <Stack spacing={2} direction="row">
          <TextButton
            tooltip="Download Alert Json"
            icon={FaJs}
            onClick={downloadAlertJson}
          />
          {downloadSuccess ? (
            <FaCheckCircle color="green" fontSize={44} />
          ) : (
            <>
              {downloadSuccess !== null && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <FaTimesCircle color="red" fontSize={44} />
                  <Typography>{downloadMessage}</Typography>
                </Box>
              )}
            </>
          )}
        </Stack>
      )}
    </div>
  );
};
