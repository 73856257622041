import React, { useEffect, useContext, useState } from "react";
import { getRequest, postRequest } from "../../../../Helpers/httpRequests";
import {
  Divider,
  Stack,
  Typography,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Switch,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataContext } from "../../../../Context/dataContext";
import PropTypes from "prop-types";
import { useAppSelector } from "../../../../Redux/app/hooks";

const AdminEditCustomer = ({ setOpen, modal = true, cxUUID }) => {
  // ----- CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const user = useAppSelector((state) => state.user);

  // ----- STATES -----
  const [engagements, setEngagements] = useState([]);
  /**
   * Object to hold the values of each input in form.
   */
  const [formData, setFormData] = useState({
    name: "",
    display_name: "",
    poc_name: "",
    poc_email: "",
    poc_phone: "",
    domain: "",
    engagement_length: "",
    active: false,
    subscription: "",
    campaign_cap: 100,
    consumption_hard_cap: true,
    av_feature: false,
    autoscore_feature: false,
    notes: "",
    contract_reference_information: "",
    license_count: 2,
  });

  /**
   * Used for handling the props/children of <LoadingButton>
   */
  const [loadingBtn, setLoadingBtn] = useState({
    loading: false,
    color: "primary",
    text: "Submit",
    helperText: "",
    helperTextColor: "green",
  });

  // ---- Variables ------

  // ----- Functions -----

  /**
   * Handles the form submission.
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    // prevent page reload on submit
    e.preventDefault();

    // set submit btn to loading
    setLoadingBtn((prev) => ({ ...prev, loading: true }));

    const res = await postRequest(
      `/react/api/admin/edit-customer/${cxUUID}`,
      accessToken,
      formData
    );
    setLoadingBtn((prev) => ({ ...prev, loading: false }));

    // send form response back as 202 (accepted)
    if (res.status === 202) {
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Success",
        color: "success",
        helperText: "Success",
        helperTextColor: "#2e7d32", // green
      }));

      /**
       * If this form is contained in a modal, you can pass the setOpen state to this component.
       * This will wait 1.5 seconds and then close the modal
       * Add 'open' state to this components parent useEffect dependencies array to have your page reload the data.
       */
      if (setOpen !== undefined) {
        setTimeout(() => setOpen(false), 1500);
      }
    } else {
      // handle errors here
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Error - Try again",
        color: "error",
        helperText: "Form submission failed",
        helperTextColor: "#d32f2f", // red
      }));
      console.log(
        "%cerror AdminEditCustomer.jsx handleSubmit()",
        "color: red; display: block; width: 100%;",
        "Failed to submit form"
      );
    }
  };

  // ----- On page load -----
  useEffect(() => {
    const getCustomerInfo = async () => {
      const res = await getRequest(
        `/react/api/admin/edit-customer/${cxUUID}`,
        accessToken
      );
      if (res.status === 200) {
        setEngagements(res.data.engagements);
        const customer = res.data.customer;
        setFormData((prev) => ({
          ...prev,
          name: customer.name || "",
          display_name: customer.display_name || "",
          poc_name: customer.poc_name || "",
          poc_email: customer.poc_email || "",
          poc_phone: customer.poc_phone || "",
          domain: customer.domain || "",
          active: customer.active || false,
          subscription: customer.license_type || "subscription",
          campaign_cap: customer.campaign_cap || 100,
          engagement_length: customer.engagement_length || "30",
          consumption_hard_cap: customer.consumption_hard_cap,
          av_feature: customer.features.av,
          autoscore_feature: customer.features.autoscoring,
          contract_reference_information: customer.contract_reference_information || "",
          notes: customer.notes,
          license_count: customer.admin_config.license_count || 2,
          customer_type: customer.license_type,
        }));
      }
    };

    // if user.current_customer changes re-run api-request
    if (user.current_customer) {
      getCustomerInfo();
    }
  }, [user.current_customer, accessToken, cxUUID]);

  return (
    <Box>
      {/* Form Header */}
      <Typography variant="h5">Edit Customer</Typography>

      <Divider style={{ marginBottom: "0.5rem" }} />

      {/* FORM */}
      <form onSubmit={(e) => handleSubmit(e)}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" spacing={2} flex={1}>
            {/* Start form fields here */}

            {/* Name */}
            <TextField
              size="small"
              placeholder="Name"
              label="Name"
              type="text"
              required
              value={formData.name}
              onChange={(e) =>
                setFormData((data) => ({ ...data, name: e.target.value }))
              }
            />

            {/* Display Name */}
            <TextField
              size="small"
              placeholder="Display Name"
              label="Display Name"
              type="text"
              required
              value={formData.display_name}
              onChange={(e) =>
                setFormData((data) => ({
                  ...data,
                  display_name: e.target.value,
                }))
              }
            />

            {/* Poc Name */}
            <TextField
              size="small"
              placeholder="Poc Name"
              label="Poc Name"
              type="text"
              value={formData.poc_name}
              onChange={(e) =>
                setFormData((data) => ({ ...data, poc_name: e.target.value }))
              }
            />

            {/* Poc Email */}
            <TextField
              size="small"
              placeholder="Poc Email"
              label="Poc Email"
              type="text"
              value={formData.poc_email}
              onChange={(e) =>
                setFormData((data) => ({ ...data, poc_email: e.target.value }))
              }
            />

            {/* Poc phone */}
            <TextField
              placeholder="Poc phone"
              size="small"
              label="Poc phone"
              type="text"
              value={formData.poc_phone}
              onChange={(e) =>
                setFormData((data) => ({ ...data, poc_phone: e.target.value }))
              }
            />

            {/* Number of Allowed Endpoints */}
            <TextField
              placeholder="# of Endpoints"
              size="small"
              label="# of Endpoints"
              type="number"
              value={formData.license_count}
              onChange={(e) =>
                setFormData((data) => ({
                  ...data,
                  license_count: e.target.value,
                }))
              }
            />

            {/* Domain */}
            <TextField
              size="small"
              placeholder="Domain"
              label="Domain"
              type="text"
              value={formData.domain}
              onChange={(e) =>
                setFormData((data) => ({ ...data, domain: e.target.value }))
              }
            />
            {/* Subscription Select */}
            <Box sx={{ marginLeft: "16px" }}>
              <FormControl required margin="none">
                <InputLabel id="subscription-select-label">
                  License Type
                </InputLabel>
                <Select
                  label="License Type"
                  size="small"
                  labelId="subscription-select-label"
                  id="subscription-select"
                  value={formData.subscription}
                  onChange={(e) =>
                    setFormData((data) => ({
                      ...data,
                      subscription: e.target.value,
                    }))
                  }
                >
                  <MenuItem value="consumption">Consumption</MenuItem>
                  <MenuItem value="consumption_smv">
                    Consumption - SMV (Service Monitoring Validation)
                  </MenuItem>
                  <MenuItem value="subscription">Subscription</MenuItem>
                  <MenuItem value="engage">Engagement</MenuItem>
                  <MenuItem value="av_only">AV Only</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {/* Engagement length */}
            {formData.subscription === "engage" && (
              <FormControl margin="none">
                <InputLabel id="engagement-length-select-label">
                  Engagement length
                </InputLabel>
                <Select
                  size="small"
                  labelId="engagement-length-select-label"
                  id="engagement-length-select"
                  value={formData.engagement_length || ""}
                  label="Engagement length *"
                  required={true}
                  onChange={(e) =>
                    setFormData((data) => ({
                      ...data,
                      engagement_length: e.target.value,
                    }))
                  }
                >
                  {engagements.map((e) => (
                    <MenuItem key={e[0]} value={e[0]}>
                      {e[1]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {(formData.subscription === "consumption" ||
              formData.subscription === "consumption_smv") && (
                <>
                  {/*Consumption Hard Cap Checkbox */}
                  <Box sx={{ marginLeft: "16px" }}>
                    <Divider />
                    <RadioGroup
                      defaultValue={formData.consumption_hard_cap}
                      onChange={() => {
                        formData.consumption_hard_cap =
                          !formData.consumption_hard_cap;
                      }}
                      column
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Hard Cap"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="Enable Billing in arrears for exceeded cap"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                    <Divider />
                  </Box>
                  <TextField
                    size="small"
                    placeholder="Campaign_Cap"
                    label="Campaign_Cap"
                    type="number"
                    value={formData.campaign_cap}
                    onChange={(e) =>
                      setFormData((data) => ({
                        ...data,
                        campaign_cap: e.target.value,
                      }))
                    }
                  />
                </>
              )}

            {/* Active Checkbox */}
            <Box sx={{ marginLeft: "16px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.active}
                    onChange={() =>
                      setFormData({
                        ...formData,
                        active: !formData.active,
                      })
                    }
                  />
                }
                label="Active"
                labelPlacement="end"
              />
            </Box>
            <Divider />
            <Typography>Features</Typography>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.av_feature}
                    onChange={() =>
                      setFormData({
                        ...formData,
                        av_feature: !formData.av_feature,
                      })
                    }
                  />
                }
                label="AV"
                labelPlacement="end"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.autoscore_feature}
                    onChange={() =>
                      setFormData({
                        ...formData,
                        autoscore_feature: !formData.autoscore_feature,
                      })
                    }
                  />
                }
                label="Auto Score"
                labelPlacement="end"
              />
            </Box>

            {/* End form fields */}

            {/* helper text */}
            <Typography color={loadingBtn.helperTextColor} variant="subtitle2">
              {loadingBtn.helperText}
            </Typography>

            {/* Submit Button */}
            <LoadingButton
              loading={loadingBtn.loading}
              color={loadingBtn.color}
              type="submit"
              variant="contained"
            >
              {loadingBtn.text}
            </LoadingButton>
          </Stack>
          <Stack spacing={2} flex={1}>
            {/* Contract Reference Information */}
            <TextField
              size="small"
              placeholder="Contract Reference Information"
              label="Contract Reference Information"
              type="text"
              value={formData.contract_reference_information}
              onChange={(e) =>
                setFormData((data) => ({
                  ...data,
                  contract_reference_information: e.target.value,
                }))
              }
            />
            <TextField
              label="Notes"
              multiline
              minRows={5}
              value={formData.notes}
              onChange={(e) => {
                setFormData((prev) => {
                  return {
                    ...prev,
                    notes: e.target.value,
                  };
                });
              }}
            />
          </Stack>
        </Stack>
      </form>
    </Box>
  );
};

AdminEditCustomer.propTypes = {
  setOpen: PropTypes.func,
  modal: PropTypes.bool,
};

export default AdminEditCustomer;
