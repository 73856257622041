import { Box, Chip, Stack, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useContext } from "react";
import { FaBan, FaExclamationTriangle, FaFileCode } from "react-icons/fa";
import CircularProgressWithLabel from "../../../../Components/CircularProgress/CircularProgressWithLabel";
import { COLORS } from "../../../../Styles/colors";
import CampaignContext from "../provider/CampaignContext";

const CampaignScore = () => {
  const { campaign, findings, missedThreholds } = useContext(CampaignContext); // Access campaign from context

  if (!campaign) {
    // Campaign data is not available yet, show loading or fallback content
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        direction: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box>
        <CircularProgressWithLabel
          value={Math.round(campaign.score * 100)}
          size={140}
          thickness={3}
          color={
            campaign.score > 0.75
              ? "success"
              : campaign.score > 0.5
              ? "warning"
              : "error"
          }
        />
      </Box>
      <Stack spacing={1} sx={{ marginLeft: 5, justifyContent: "center" }}>
        <Typography
          sx={{
            width: "60px",
            height: "60px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "solid 1px grey",
            backgroundColor: "rgba(0,0,0,0.15)",
            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          {findings ? (
            <>
              {Math.round(campaign.score * findings.length)} / {findings.length}
            </>
          ) : (
            <></>
          )}
        </Typography>
        <Typography sx={{ alignSelf: "center" }} variant="h5">
          Missed Thresholds
        </Typography>
        <Box sx={{ marginLeft: 5, display: "flex", flexDirection: "row" }}>
          <Chip
            icon={<FaBan size={18} />}
            label={
              <Typography
                sx={{ color: COLORS.success.main, fontWeight: "bold" }}
              >
                Blocked: {missedThreholds.blocked}
              </Typography>
            }
            color="success"
            variant="outlined"
            sx={{ marginRight: 2 }}
          />
          <Chip
            sx={{ marginRight: 2 }}
            icon={<FaExclamationTriangle size={18} />}
            label={
              <Typography
                sx={{ color: COLORS.primary.main, fontWeight: "bold" }}
              >
                Alerted: {missedThreholds.alerted}
              </Typography>
            }
            variant="outlined"
          />
          <Chip
            icon={<FaFileCode size={18} color={COLORS.yellow} />}
            label={
              <Typography sx={{ color: COLORS.yellow, fontWeight: "bold" }}>
                Logged: {missedThreholds.logged}
              </Typography>
            }
            variant="outlined"
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default CampaignScore;
