import React, { useContext } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { SimLibraryContext } from "../../provider/context";
import ClearIcon from "@mui/icons-material/Clear";
import { useAppSelector } from "../../../../../Redux/app/hooks";

const SearchField = () => {
  const darkmode = useAppSelector((state) => state.darkmode);
  const { searchValue, setSearchValue } = useContext(SimLibraryContext);
  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };
  return (
    <TextField
      sx={{
        width: 600,
        input: {
          backgroundColor: darkmode ? "#1e1e1e" : "white",
        },
      }}
      label="Search by Name or MITRE ATT&CK® (e.g. T1003)"
      value={searchValue}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setSearchValue("")}>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchField;
