import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../../Context/dataContext";
import { getRequest, postRequest } from "../../../../Helpers/httpRequests";
import { useAppSelector } from "../../../../Redux/app/hooks";

const AdminAddUserForm = () => {
  // ----- Context -----
  const { accessToken } = useContext(DataContext);
  const user = useAppSelector((state) => state.user);

  // ----- States -----
  const [formData, setFormData] = useState({
    userID: "",
    role: "",
    customer_access: [],
    superuser: false,
  });

  const [customers, setCustomers] = useState([]);
  const [users, setUsers] = useState([]);
  const [roleChoices, setRoleChoices] = useState([]);

  // ---- Variables ------

  /**
   * These are the styles for the Box inside of the modal.
   * The Modal component renders as position Absolute so we
   * have to write styles accordingly
   */
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };

  /**
   * Multi-Select Styles for Customer Accesses in form
   */
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // ----- Functions -----

  /**
   * This function handles the Customer Access select of the form.
   * @param {Event} event
   * @returns
   */
  const handleCxAccessChange = (event) => {
    const {
      target: { value },
    } = event;

    // if the last item in array is "all", set forData.customer_access
    //to an empty array if it is full, otherwise set it to equal ALL the
    //customers in the customers state
    if (value[value.length - 1] === "all") {
      setFormData((oldData) => ({
        ...oldData,
        customer_access:
          oldData.customer_access.length === customers.length ? [] : customers,
      }));
      return;
    }

    setFormData((oldData) => ({
      ...oldData,
      customer_access: typeof value === "string" ? value.split(",") : value,
    }));
  };

  /**
   * Handles the form submission.
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    // prevent page reload
    e.preventDefault();

    const res = await postRequest(
      "/react/api/admin-add-user",
      accessToken,
      formData,
      true
    );

    if (!res.status === 201) {
      console.log(
        "%cerror AdminAddUserForm.jsx line:109 ",
        "color: red; display: block; width: 100%;",
        "Failed to submit user."
      );
    }
  };

  // ----- On page load -----
  useEffect(() => {
    /**
     * Function to fetch initial data needed for form.
     * Retrieves:
     *  all customes,
     *  all users,
     *  all role choices on BlindspotUser model.
     */
    const getAllCustomers = async () => {
      const res = await getRequest("/react/api/admin-add-user", accessToken);
      if (res.status === 200) {
        // set reponse fields to thier respective states
        setCustomers(res.data.customers);
        setUsers(res.data.users);
        setRoleChoices(res.data.roles);
      }
    };

    // if user.current_customer changes re-run api-request
    if (user.current_customer) {
      getAllCustomers();
    }
  }, [user.current_customer, accessToken]);

  return (
    <Box sx={style}>
      {/* Form Header */}
      <Typography variant="h5">Add User</Typography>

      <Divider style={{ marginBottom: "0.5rem" }} />

      {/* FORM */}
      <form onSubmit={(e) => handleSubmit(e)}>
        <Stack direction="column" spacing={2}>
          {/* User - Select */}
          <FormControl required margin="none">
            <InputLabel id="user-select-label">User</InputLabel>
            <Select
              labelId="user-select-label"
              id="user-select-required"
              value={formData.userID}
              label="User *"
              onChange={(e) =>
                setFormData((oldData) => ({
                  ...oldData,
                  userID: e.target.value,
                }))
              }
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.email}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Role - Select */}
          <FormControl required margin="none">
            <InputLabel id="role-select-label">Role</InputLabel>
            <Select
              labelId="role-select-label"
              id="role-select-required"
              value={formData.role || ""}
              label="Role *"
              onChange={(e) =>
                setFormData((currentData) => ({
                  ...currentData,
                  role: e.target.value,
                }))
              }
            >
              {roleChoices.map((role) => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Customer Access  - Multiple Select */}
          <FormControl required>
            <InputLabel id="demo-multiple-checkbox-label">
              Customer Accesses
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={formData.customer_access}
              onChange={handleCxAccessChange}
              input={<OutlinedInput label="Customer Accesses" />}
              renderValue={(selected) => selected.map((x) => x.name).join(",")}
              MenuProps={MenuProps}
            >
              <MenuItem sx={{ paddingLeft: 0 }} value="all">
                <Checkbox
                  id="all-cx-checkbox"
                  checked={
                    customers.length > 0 &&
                    customers.length === formData.customer_access.length
                  }
                  indeterminate={
                    formData.customer_access.length > 0 &&
                    formData.customer_access.length < customers.length
                  }
                />
                <ListItemText primary="Select All" />
              </MenuItem>

              {customers.map((cx) => (
                <MenuItem key={cx.uuid} value={cx}>
                  <Checkbox
                    checked={formData.customer_access.some(
                      (customer) => customer.uuid === cx.uuid
                    )}
                  />
                  <ListItemText primary={cx.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Superuser - CheckBox */}
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.superuser}
                  onChange={() =>
                    setFormData((oldData) => ({
                      ...oldData,
                      superuser: !oldData.superuser,
                    }))
                  }
                />
              }
              label="Superuser"
            />
          </FormControl>

          {/* Submit Button */}
          <LoadingButton type="submit" variant="contained">
            Submit
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
};

export default AdminAddUserForm;
