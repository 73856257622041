/* eslint-disable */
import { Box, Breadcrumbs, CircularProgress, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { FaDownload } from "react-icons/fa";
import { useParams } from "react-router-dom";
import TextButton from "../../../Components/Buttons/TextButton";
import AttackMatrixDiagram from "../../../Components/Reporting/AttackMatrixDiagram";
import { DataContext } from "../../../Context/dataContext";
import { fileRequest, getRequest } from "../../../Helpers/httpRequests";
import { useAppSelector } from "../../../Redux/app/hooks";

const AttackMatrix = () => {
  // ----- Context -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector((state) => state.customer);

  // ----- States -----
  const [techniques, setTechniques] = useState([]);
  const [killChain, setKillChain] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const { campaignUUID } = useParams();

  // ----- Variable -----

  // ----- Functions -----
  const onClick = () => {
    fileRequest(
      `/react/api/${current_customer.uuid}/report/all-attack-nav-export`,
      accessToken,
      "blindspot-export.json"
    );
  };

  // ----- On page load -----
  useEffect(() => {
    if (techniques.length > 0 && !loaded) {
      setLoaded(true);
    }
  }, [techniques, loaded]);

  useEffect(() => {
    const getData = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/report/matrix${
          campaignUUID ? `?campaign_uuid=${campaignUUID}` : ""
        }`,
        accessToken
      );

      if (res.status === 200) {
        let techs = res.data.techniques;
        if (
          res.data.techOutcomes &&
          Object.keys(res.data.techOutcomes).length > 0
        ) {
          techs.map((tech) => {
            if (tech.attack_id in res.data.techOutcomes) {
              tech.outcomes = res.data.techOutcomes[tech.attack_id];
            }
            return null; //why does eslint want this? I'm just looping through these techniques...
          });
        }
        if (techs.length === 0) {
          setTechniques([]);
        } else {
          setTechniques(techs);
        }
        setKillChain(res.data.kill_chain);
      }
    };

    getData();
  }, [accessToken, current_customer, campaignUUID]);

  return loaded ? (
    <Stack spacing={3}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Reports</Typography>
        <Typography color="text.primary">
          {" "}
          ATT&CK<sup>®</sup> Matrix
        </Typography>
      </Breadcrumbs>

      {/* Page Title */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Typography variant="h3">
          {" "}
          ATT&CK<sup>®</sup> Matrix
        </Typography>
        {current_customer ? (
          <TextButton
            icon={FaDownload}
            tooltip="Download ATT&CK® Navigator File"
            onClick={onClick}
          />
        ) : null}
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <AttackMatrixDiagram killChain={killChain} techniques={techniques} />
      </Box>
    </Stack>
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <CircularProgress />
      <Typography variant="h6" marginTop={2}>
        Data is being loaded...
      </Typography>
    </Box>
  );
};

export default AttackMatrix;
