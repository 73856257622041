import React, { useContext } from "react";
import PropTypes from "prop-types";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useHttpRequest } from "../../Hooks";
import { FormContext } from "../context/FormContext";
import { v1 as uuidv1 } from "uuid";
import { capitalize } from "../../Helpers/functions";

const CustomerSelect = (props) => {
  const { name, fullWidth, label, required, disabled } = props;
  const id = uuidv1();
  const { formData, setFormData } = useContext(FormContext);
  const {
    response: customers,
    loading,
    error,
  } = useHttpRequest({
    method: "GET",
    path: "/api/v2/customers",
  });

  const handleCustomerSelectChange = (e) => {
    setFormData((data) => ({ ...data, [name]: e.target.value }));
  };

  return !loading && !error ? (
    <FormControl fullWidth={fullWidth} required={required}>
      <InputLabel id={id}>{label || capitalize(name)}</InputLabel>
      <Select
        labelId={id}
        value={formData[name] || ""}
        label={label || capitalize(name)}
        onChange={handleCustomerSelectChange}
        disabled={disabled}
      >
        {customers.map((c) => (
          <MenuItem key={c.id} value={c.id}>
            {c.display_name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : null;
};

CustomerSelect.propTypes = {
  name: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

CustomerSelect.defaultProps = {
  fullWidth: false,
  required: false,
  disabled: false,
};

export default CustomerSelect;
