import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../../Context/dataContext";
import { postRequest } from "../../../../Helpers/httpRequests";
import { toast } from "react-toastify";

const SentinelOneConfigForm = (props) => {
  const { accessToken } = useContext(DataContext);
  const [showApiToken, setShowApiToken] = useState(false);
  const [apiToken, setApiToken] = useState("");
  const [base_url, setBaseUrl] = useState("");
  const [uuid, setUUID] = useState("");
  const { onFinish, closeModal, toolData, current_customer } = props;

  const handleSubmit = async () => {
    const formattedBaseUrl = base_url.trim().endsWith("/")
      ? base_url.trim()
      : `${base_url.trim()}/`;

    const data = {
      securityTool: toolData.uuid,
      base_url: formattedBaseUrl,
      apiToken: apiToken.trim(),
      uuid: uuid,
    };

    const loading_toast = toast.loading(
      `Attempting to ${toolData.action} Security Tool Integration`
    );

    const endpoint =
      toolData.action === "edit"
        ? `/react/api/${current_customer.uuid}/config/securitytoolconfigs/sentinelOne/edit`
        : `/react/api/${current_customer.uuid}/config/securitytoolconfigs/sentinelOne/add`;

    const result = await postRequest(endpoint, accessToken, data);

    toast.dismiss(loading_toast);

    if (result.data.status === 200) {
      toast.success("Request Successful");
      closeModal();
      if (onFinish) {
        onFinish();
      }
    } else {
      toast.error("Failed to connect Security Tool Integration");
    }
  };

  useEffect(() => {
    const setForm = async () => {
      if (toolData.action === "edit") {
        setBaseUrl(toolData.tool.tool_config.base_url);
        setApiToken(toolData.tool.tool_config.apiToken);
        setUUID(toolData.tool.tool_config.uuid);
      }
    };
    setForm();
  }, [accessToken, toolData]);

  return (
    <Stack>
      <Typography variant="h5">SentinelOne Integration Details</Typography>
      <Divider style={{ margin: "1rem 0" }} />

      <Stack spacing={2}>
        <TextField
          required
          id="tool-azure-resource-group"
          label="Base url"
          value={base_url}
          onChange={(e) => setBaseUrl(e.target.value)}
        />

        <TextField
          required
          id="tool-azure-subscription"
          label="Api Token"
          type={showApiToken ? "text" : "password"}
          value={apiToken}
          onChange={(e) => setApiToken(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowApiToken(!showApiToken)}>
                  {showApiToken ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button onClick={handleSubmit} variant="contained">
          Submit
        </Button>
      </Stack>
    </Stack>
  );
};

SentinelOneConfigForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onFinish: PropTypes.func,
  toolData: PropTypes.object.isRequired,
  current_customer: PropTypes.object.isRequired,
};

export default SentinelOneConfigForm;
