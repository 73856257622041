import { LoadingButton } from "@mui/lab";
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataContext } from "../../../../Context/dataContext";
import { getRequest, postRequest } from "../../../../Helpers/httpRequests";
import classes from "./style.module.css";

// Import the MultiSelect component and its CSS
import SelectSearch from "react-select-search";
import "react-select-search/style.css";
import { useAppSelector } from "../../../../Redux/app/hooks";

const AdminAddUserToTenantForm = ({ setAddUserModal}) => {
  const { accessToken } = useContext(DataContext);
  const user = useAppSelector((state) => state.user);
  const customer = useAppSelector((state) => state.customer);

  const [formData, setFormData] = useState({
    users: [],
    customerID: customer,
  });
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await postRequest(
      "/react/api/admin-add-user-to-tenant",
      accessToken,
      formData,
      true
    );

    if (res.status === 201) {
      toast.success("Users added successfully.");
      setAddUserModal(false);
    } else {
      toast.error("Failed to submit users.");
    }
  };

  useEffect(() => {
    const getAllCustomers = async () => {
      try {
        const res = await getRequest(
          "/react/api/admin-add-user-to-tenant",
          accessToken
        );

        if (res.status === 200) {
          setUsers(res.data.users);
          setLoading(false); // Mark loading as complete
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error fetching data.");
        setLoading(false); // Mark loading as complete in case of an error
      }
    };

    if (user.current_customer) {
      getAllCustomers();
    }
  }, [user.current_customer, accessToken]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    maxHeight: "100%",
  };

  return (
    <Box sx={style}>
      <Typography variant="h5">
        Add Existing Users to {customer.display_name}
      </Typography>
      <Divider style={{ marginBottom: "0.5rem" }} />
      {loading ? ( // Render CircularProgress if still loading
        <CircularProgress />
      ) : (
        <form onSubmit={handleSubmit}>
          <Stack direction="column" spacing={2}>
            <FormControl required margin="none">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box sx={{ flex: 1, width: "50%", marginRight: 5 }}>
                  {" "}
                  {/* First box */}
                  <FormControl required margin="none">
                    <SelectSearch
                      className={classes}
                      multiple
                      options={users
                        .filter((user) => !formData.users.includes(user.uuid))
                        .map((user) => ({
                          value: user.uuid,
                          name: user.user.email,
                        }))}
                      value={formData.users}
                      onChange={(value) => {
                        setFormData((oldData) => ({
                          ...oldData,
                          users: [...oldData.users, ...value],
                        }));
                        setSelectedUsers((prevSelectedUsers) => [
                          ...prevSelectedUsers,
                          ...value,
                        ]);
                      }}
                      placeholder="Search users..."
                      search
                    />
                  </FormControl>
                </Box>
                <Box sx={{ flex: 1, width: "50%" }}>
                  {selectedUsers.length > 0 && (
                    <>
                      <Typography variant="h6">Selected Users</Typography>
                      <Stack>
                        {selectedUsers.map((user) => (
                          <Chip
                            sx={{
                              marginBottom: 1,
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            key={user}
                            label={
                              users.find((u) => u.uuid === user)?.user.email ||
                              ""
                            }
                            onDelete={() => {
                              const updatedUsers = selectedUsers.filter(
                                (u) => u !== user
                              );
                              setSelectedUsers(updatedUsers);
                              setFormData((oldData) => ({
                                ...oldData,
                                users: updatedUsers,
                              }));
                            }}
                          />
                        ))}
                      </Stack>
                    </>
                  )}
                </Box>
              </Box>
            </FormControl>
            <Divider />
            <FormControl required margin="none">
              <TextField
                inputProps={{ style: { fontWeight: "bold", fontSize: 30 } }}
                value={customer.display_name}
                disabled
              />
            </FormControl>

            <LoadingButton
              type="submit"
              variant="contained"
              disabled={selectedUsers.length === 0}
            >
              Submit
            </LoadingButton>
          </Stack>
        </form>
      )}
    </Box>
  );
};

export default AdminAddUserToTenantForm;
