import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TextButton from "../../../Components/Buttons/TextButton";
import { DataContext } from "../../../Context/dataContext";
import { getRequest, postRequest } from "../../../Helpers/httpRequests";
import { COLORS } from "../../../Styles/colors";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import ProxySettingsForm from "../../../Components/Forms/Config/ProxySettingsForm/ProxySettingsForm";
import FormModal from "../../../Components/Modals/FormModal";
import { useAppSelector } from "../../../Redux/app/hooks";

const Config_Proxy_Settings = () => {
  // ----- STATES && CONTEXT -----
  const [dataTableRows, setDataTableRows] = useState([]);
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer,
  );
  const [reloadProxies, setReloadProxies] = useState(true);
  const [editProxy, setEditProxy] = useState(null);
  const [proxyModalAdd, setProxyModalAdd] = useState(false);
  const [proxyModalEdit, setProxyModalEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteUUID, setDeleteUUID] = useState("");
  const [deleteName, setDeleteName] = useState("");

  //Variables
  const PasswordCell = ({ row }) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    return (
      <div>
        {showPassword ? (
          <Typography sx={{ marginRight: 2 }} component="span">
            {row.password}
          </Typography>
        ) : (
          <Typography sx={{ marginRight: 2 }} component="span">
            *********
          </Typography>
        )}
        <IconButton onClick={togglePasswordVisibility} size="small">
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </div>
    );
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Typography
              className="text-links"
              onClick={() => {
                setEditProxy(params.row);
                setProxyModalEdit(true);
              }}
            >
              {params.row.name}
            </Typography>
          </>
        );
      },
    },
    { field: "address", headerName: "Address", minWidth: 100, flex: 1 },
    { field: "username", headerName: "User Name", minWidth: 100, flex: 1 },
    {
      field: "password",
      headerName: "Password",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => <PasswordCell row={params.row} />,
    },
    {
      field: "actions",
      headerName: "Actions",
      maxWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Stack direction="row" spacing={2}>
              <IconButton
                onClick={() => {
                  setDeleteUUID(params.row.id);
                  setDeleteName(params.row.name);
                  setDeleteModal(true);
                }}
                title="Delete Proxy Setting"
              >
                <FaTrashAlt color={COLORS.error.main} />
              </IconButton>
            </Stack>
          </>
        );
      },
    },
  ];

  //Functions

  const deleteProxy = async () => {
    const res = await postRequest(
      `/react/api/${current_customer.uuid}/config/proxies/delete/${deleteUUID}`,
      accessToken,
    );
    if (res.status === 200) {
      setDeleteModal(false);
      setReloadProxies((current) => !current);
    }
  };

  // ----- ON PAGE LOAD -----

  useEffect(() => {
    const getProxies = async () => {
      const res = await getRequest(`/react/api/${current_customer.uuid}/config/proxies`, accessToken);
      if (res.status === 200) {
        populateDataGrid(res.data.proxies);
      }
    };

    const populateDataGrid = (proxies) => {
      const rows = proxies.map((proxy) => {
        return {
          id: proxy.uuid,
          name: proxy.name,
          address: proxy.proxy_address,
          username:
            proxy.proxy_username === null ? "None" : proxy.proxy_username,
          password: proxy.proxy_pass === null ? "None" : proxy.proxy_pass,
          actions: [],
        };
      });
      setDataTableRows(rows);
    };

    getProxies();
  }, [current_customer, accessToken, reloadProxies]);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">Home</Link>
        <Typography color="text.primary">Configurations</Typography>
        <Typography color="text.primary">Proxy Settings</Typography>
      </Breadcrumbs>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Typography component={"span"} fontWeight={"bold"} variant="h4">
          Proxy Settings
        </Typography>
        <Typography component={"span"}>
          <TextButton
            onClick={() => {
              setProxyModalAdd(true);
            }}
            tooltip="Create New Proxy"
            icon={FaPlus}
          />
        </Typography>
      </Box>

      <DataGridWithStyles
        name="config-proxy-settings"
        autoHeight
        paper
        rows={dataTableRows}
        columns={columns}
      />
      {/* Delete Modal */}
      <FormModal open={deleteModal} setOpen={setDeleteModal}>
        <Box component="form">
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: 4 }}
          >
            Are you sure you want to remove {deleteName} proxy setting?
          </Typography>
          <Stack direction="column" spacing={2}>
            <Button onClick={() => deleteProxy()} variant="contained">
              Yes
            </Button>
          </Stack>
        </Box>
      </FormModal>
      <FormModal open={proxyModalAdd} setOpen={setProxyModalAdd}>
        <ProxySettingsForm
          setReload={setReloadProxies}
          setOpen={setProxyModalAdd}
          type="add"
        />
      </FormModal>
      <FormModal open={proxyModalEdit} setOpen={setProxyModalEdit}>
        <ProxySettingsForm
          setReload={setReloadProxies}
          setOpen={setProxyModalEdit}
          proxy={editProxy}
          type="edit"
        />
      </FormModal>
    </>
  );
};

export default Config_Proxy_Settings;
