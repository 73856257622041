import { Stack, Typography } from "@mui/material";

const AlertReviewForm = ({ formData, formOptions }) => {
  const timeToRunOptions = {
    0: "12am (Midnight)",
    1: "1am",
    2: "2am",
    3: "3am",
    4: "4am",
    5: "5am",
    6: "6am",
    7: "7am",
    8: "8am",
    9: "9am",
    10: "10am",
    11: "11am",
    12: "12pm (Noon)",
    13: "1pm",
    14: "2pm",
    15: "3pm",
    16: "4pm",
    17: "5pm",
    18: "6pm",
    19: "7pm",
    20: "8pm",
    21: "9pm",
    22: "10pm",
    23: "11pm",
  };

  // For some reason humans can't understand that 1 means Monday without you telling them
  // isoweekday has monday as 1 and sunday as 7
  const daysOfWeek = {
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
    7: "Sunday",
  };

  const monthdayOptions = {
    1: "1st",
    2: "2nd",
    3: "3rd",
    4: "4th",
    5: "5th",
    6: "6th",
    7: "7th",
    8: "8th",
    9: "9th",
    10: "10th",
    11: "11th",
    12: "12th",
    13: "13th",
    14: "14th",
    15: "15th",
    16: "16th",
    17: "17th",
    18: "18th",
    19: "19th",
    20: "20th",
    21: "21st",
    22: "22nd",
    23: "23rd",
    24: "24th",
    25: "25th",
    26: "26th",
    27: "27th",
    28: "28th",
    29: "29th",
    30: "30th",
    31: "31st",
  };

  const matchingEndpoint = formOptions.endpoints.find(
    (endpoint) => endpoint.uuid === formData.endpoint
  );

  let frequency = "";
  const time = timeToRunOptions[formData.time];
  if (formData.frequency === "daily") {
    frequency = `Daily at ${time} ${formData.timezone}`;
  } else if (formData.frequency === "weekly") {
    frequency = `Every ${daysOfWeek[formData.weekday]} at ${time} ${
      formData.timezone
    }`;
  } else {
    frequency = `The ${
      monthdayOptions[formData.monthday]
    } day of every Month at ${time} ${formData.timezone}`;
  }

  const execMethod = [
    {
      value: "exe",
      name: "Binary Executable",
    },
    {
      value: "dll",
      name: "Signed Proxy Execution",
    },
    {
      value: "sh",
      name: "Remote Process Injection",
    },
    {
      value: "local",
      name: "Local Process Injection",
    },
  ];

  // Need to take the formData.execution and getting the name from the execMethod array
    const executionMethod = execMethod.find(method => method.value === formData.execution);
  return (
    <>
      <Typography fontWeight={"bold"} variant="h5">
        Exercise Detail Summary
      </Typography>
      <Stack spacing={2} direction="row">
        {/* left */}
        <Stack spacing={2}>
          <Typography fontWeight="bold">Name:</Typography>
          <Typography fontWeight="bold">Simulation:</Typography>
          <Typography fontWeight="bold">Endpoint:</Typography>
          <Typography fontWeight="bold">Frequency:</Typography>
        </Stack>
        {/* right */}
        <Stack spacing={2}>
          <Typography>{formData.name}</Typography>
          <Typography>{formData.simulation.name}</Typography>
          <Typography>{matchingEndpoint.name}</Typography>
          <Typography>{frequency}</Typography>
        </Stack>
      </Stack>
      <Typography fontWeight="bold" variant="h5">
        Exercise Execution Summary
      </Typography>
      <Stack spacing={2} direction="row">
        {/* left */}
        <Stack spacing={2}>
          <Typography fontWeight="bold">Execution Method:</Typography>
        </Stack>
        {/* right */}
        <Stack spacing={2}>
          <Typography>{executionMethod.name}</Typography>
        </Stack>
      </Stack>
      {formData.piTarget?.length > 1 || formData.piMethod?.length > 1 ? (
        <Stack spacing={2} direction="row">
          {/* left */}
          <Stack spacing={2}>
            <Typography fontWeight="bold">PI Target:</Typography>
            <Typography fontWeight="bold">PI Method:</Typography>
          </Stack>
          {/* right */}
          <Stack spacing={2}>
            <Typography>{formData.piTarget.name}</Typography>
            <Typography>{formData.piMethod}</Typography>
          </Stack>
        </Stack>
      ) : null}
    </>
  );
};

export default AlertReviewForm;
