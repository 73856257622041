import moment from "moment";

/**
 * This function handles the creation of columns and rows for MUI Datagrid. Running this function will cause the MUI-Datagrid to re-mount.
 * @param {} param
 */
export const populateDataGrid = (customers, setDataGridData) => {
  const columns = [
    {
      field: "customer",
      headerName: "Customer",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "agents",
      headerName: "# of Agents",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "liveAgents",
      headerName: "# of live agents",
      minWidth: 100,
      flex: 1
    },
    {
      field: "lastCheckIn",
      headerName: "Time of last checkin",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        if (params.row.lastCheckIn)
          return moment(params.row.lastCheckIn).format('MMM DD, YYYY hh:mm a')
        else
          return "Never"
      }
    },
    {
      field: "users",
      headerName: "# of Users",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "campaigns",
      headerName: "Campaigns",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "total",
      headerName: "Campaigns ran in Date Range",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "campNotScored",
      headerName: "Camps not scored",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "scheduledExercises",
      headerName: "Scheduled Exercises ran",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "scheduledExerciseEndpoints",
      headerName: "Scheduled Exercise Endpoints",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "totalAVCampaigns",
      headerName: "Total AV Campaigns",
      minWidth: 100,
      flex: 1,
    }
  ];

  const rows = customers.map((cx) => {
    return {
      id: cx.uuid,
      customer: cx.name,
      agents: cx.agents,
      users: cx.users,
      campaigns: cx.campaigns,
      total: cx.total,
      campNotScored: cx.campaigns_not_scored,
      liveAgents: cx.active,
      lastCheckIn: cx.latest_check_in,
      scheduledExercises: cx.scheduled_exercises,
      scheduledExerciseEndpoints: cx.scheduled_exercise_endpoints,
      totalAVCampaigns: cx.total_av_campaigns
    };
  });

  setDataGridData((data) => ({
    ...data,
    rows: rows,
    columns: columns,
  }));
};
