import React from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import TextLink from "../../../Components/TextLink/TextLink";

const RunAgent = () => {
  // ----- Context -----

  // ----- States -----

  // ----- Variable -----

  // ----- Functions -----

  // ----- On page load -----

  return (
    <Stack spacing={3}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Support</Typography>
        <Typography color="text.primary">Run Agent</Typography>
      </Breadcrumbs>

      {/* Page Title */}
      <Typography variant="h4">
        Downloading and Running an Agent with BlindSPOT
      </Typography>
      <div>
        <ol>
          <li>
            <div>
              Login to the BlindSPOT platform, using your given credentials.
            </div>
          </li>
          <li>
            <div>
              Click the{" "}
              <TextLink display="inline" url="/simulations/campaigns/list">
                Campaigns
              </TextLink>{" "}
              option in the left-hand navigation menu
            </div>
            <img
              src="../../../static/img/help/CampaignNavitem.png"
              alt="Campaign option in nav bar"
            />
          </li>
          <br />
          <li>
            <div>
              Once on the{" "}
              <TextLink display="inline" url="/simulations/campaigns/list">
                Campaigns page
              </TextLink>
              , make sure there is a pop-up which states,{" "}
              <b>"No agents are currently running"</b>.
            </div>
            <img
              src="../../../static/img/help/NoAgentsRunning.png"
              alt="No agents runnin example"
            />
          </li>
          <br />
          <li>
            <div>
              To start a campaign, you need to run an agent. Start by pushing
              the green <b>Download Agents</b> button found at the end of the
              left-hand navigation menu.
            </div>
            <img
              src="../../../static/img/help/DownloadAgentsButton.png"
              alt="download agents button"
            />
          </li>
          <br />
          <li>
            <div>
              Next a <b>"Download BlindSPOT Agents"</b> screen should appear.
              Select the <b>"Download Agents"</b> button found on the right
              side.
            </div>
            <img
              src="../../../static/img/help/DownloadSingleAgent.png"
              alt="Download single agent screenshot."
            />
          </li>
          <br />
          <li>
            <div>
              After the Agent is downloaded it should look like this, a simple
              single file download.
            </div>
            <img
              src="../../../static/img/help/SingleAgentRunning.png"
              alt="Single agent running in powershell"
            />
          </li>
          <br />
          <li>
            <div>
              The program will then register the system that it is running on
              with the BlindSPOT platform. It will also gather some basic
              information about the computer you are running it on.
            </div>
            <img
              src="../../../static/img/help/AgentInfoGather.png"
              alt="Agent collecting information based on the os"
            />
          </li>
          <br />
          <li>
            <div>Go back to the BlindSPOT platform</div>
          </li>
          <li>
            <div>
              Click{" "}
              <TextLink display="inline" url="/simulations/list">
                Simulation Library
              </TextLink>{" "}
              on the left-hand side of the screen.
            </div>
            <img
              src="../../../static/img/help/simulationLibrary.png"
              alt="simulation library example"
            />
          </li>
          <br />
          <li>
            <div>
              A page with a green notification that says, "You have live agents
              reporting to BlindSPOT" should appear.
            </div>
            <img
              src="../../../static/img/help/AgentsRunning.png"
              alt="Agents running notification screenshot."
            />
            <div>
              <b>
                If this notification shows up it means that all steps have been
                completed correctly up until this point.
              </b>
            </div>
          </li>
          <br />
          <li>
            <div>
              Click the{" "}
              <TextLink display="inline" url="/simulations/endpoints/list">
                Endpoints
              </TextLink>{" "}
              tab on the left-hand side of the screen.
            </div>
            <img
              src="../../../static/img/help/EndpointsScreenshot.png"
              alt="Screenshot of endpoints in nav menu"
            />
          </li>
          <br />
          <li>
            <div>Now you should see the agent you just downloaded running.</div>
            <img
              src="../../../static/img/help/AgentRunningInBlindspot.png"
              alt="Screenshot of agent running in Blindspot"
            />
            <div>
              <b>
                Now you are ready to go. Your agent has been set up and is
                running.
              </b>
            </div>
          </li>
          <br />
          <li>
            <div>
              Notice that in the upper right-hand section of this screen you can
              assign the BlindSPOT platform tasks using simple push buttons.
              These tasks include: running an assessment, refreshing a
              screenshot, uploading a log, and terminating a run.
            </div>
            <img
              src="../../../static/img/help/RunningAgentActions.png"
              alt="running agents actions"
            />
            <div>
              <b>
                Simulations can also be run now by going to the{" "}
                <TextLink display="inline" url="/simulations/list">
                  Simulation Library
                </TextLink>{" "}
                on the left-hand side of the screen.
              </b>
            </div>
          </li>
        </ol>
      </div>
    </Stack>
  );
};

export default RunAgent;
