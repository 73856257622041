import React, { useEffect, useState, useContext } from "react";
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { getRequest, postRequest } from "../../../../Helpers/httpRequests";
import { DataContext } from "../../../../Context/dataContext";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../Redux/app/hooks";

const ConfigReportingToolForm = () => {
  // ----- Context -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  // ----- STATES -----
  const [reportToolName, setReportToolName] = useState("");
  const [customerIdentifier, setCustomerIdentifier] = useState("");
  const [remoteSystem, setRemoteSystem] = useState("");
  const [remoteSystems, setRemoteSystems] = useState([]);

  /**
   * States for Submit button
   */
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitText, setSubmitText] = useState("Submit");
  const [submitColor, setSubmitColor] = useState("primary");

  // ----- VARIABLES -----

  // ----- FUNCTIONS -----
  const handleSubmit = async () => {
    setSubmitLoading(true);
    const data = {
      name: reportToolName,
      identifier: customerIdentifier,
      remoteCredsUUID: remoteSystem,
    };
    const res = await toast.promise(
      postRequest(`/react/api/${current_customer.uuid}/config/reporttool`, accessToken, data),
      {
        pending: "Updating Reporting Tool",
        success: `Report Tool Updated`,
        error: "Something went wrong!",
      }
    );
    if (res.status === 200) {
      setSubmitLoading(false);
      setSubmitColor("success");
      setSubmitText("success");
    } else {
      setSubmitLoading(false);
      setSubmitColor("error");
      setSubmitText("Error");
    }
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getFormData = async () => {
      const res = await getRequest(`/react/api/${current_customer.uuid}/config/reporttool`, accessToken);
      setReportToolName(res.data.reportTool.name);
      setCustomerIdentifier(res.data.reportTool.customer_identifier);
      setRemoteSystems(res.data.remoteSystems);
      const remote_creds = res.data.reportTool.remote_creds;
      if (remote_creds !== null) {
        setRemoteSystem(remote_creds);
      }
    };
    getFormData();
  }, [current_customer, accessToken]);

  return (
    <Stack>
      <Typography variant="h5">Edit Reporting Tool</Typography>
      <Divider style={{ margin: "1rem 0" }} />

      <Stack spacing={2}>
        {/* Name */}
        <TextField
          label="Name"
          type="text"
          value={reportToolName || ""}
          onChange={(e) => setReportToolName(e.target.value)}
        />

        {/* Customer Identifier */}
        <TextField
          type="number"
          label="Customer Identifier"
          value={customerIdentifier || ""}
          onChange={(e) => setCustomerIdentifier(e.target.value)}
        />

        {/* Remote Creds */}
        <FormControl>
          <InputLabel>Report Tool</InputLabel>
          <Select
            value={remoteSystem || ""}
            label="Report Tool"
            onChange={(e) => setRemoteSystem(e.target.value)}
          >
            {remoteSystems.length > 0 &&
              remoteSystems.map((system) => (
                <MenuItem key={system.uuid} value={system.uuid}>
                  {system.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        {/* Submit Button */}
        <LoadingButton
          loading={submitLoading}
          variant="contained"
          onClick={handleSubmit}
          color={submitColor}
        >
          {submitText}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default ConfigReportingToolForm;
