import { Box, Drawer } from "@mui/material";
import React from "react";

const HowToWithHtml = ({ htmlContent, open, setOpen }) => {
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleDrawer}
    >
      <Box p={2} marginTop={10} sx={{ width: "800px" }}>
        {open && htmlContent}
      </Box>
    </Drawer>
  );
};

export default HowToWithHtml;
