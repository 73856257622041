import { FormControl, TextField as MUITextField } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { capitalize } from "../../Helpers/functions";
import { FormContext } from "../context/FormContext";

const TextField = (props) => {
  const { name, required, fullWidth, label, maxLength, type } = props;
  const { formData, setFormData } = useContext(FormContext);

  const handleChange = (e) => {
    setFormData((data) => ({
      ...data,
      [name]: e.target.value,
    }));
  };

  const charCountDisplay = () => {
    if (formData[name]) {
      return `${formData[name]?.length} / ${maxLength}`;
    }
    return `0 / ${maxLength}`;
  };

  return (
    <FormControl fullWidth={fullWidth}>
      <MUITextField
        label={label || capitalize(name)}
        value={formData[name] || ""}
        onChange={handleChange}
        inputProps={{ maxLength: maxLength }}
        helperText={maxLength ? charCountDisplay() : null}
        type={type}
        required={required}
      />
    </FormControl>
  );
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  maxLength: PropTypes.number,
  type: PropTypes.string,
};

TextField.defaultProps = {
  required: false,
  fullWidth: false,
  maxLength: null,
  helperText: null,
  type: "text",
};

export default TextField;
