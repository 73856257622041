import React from "react";
import { Breadcrumbs, Stack, Typography } from "@mui/material";


export const Header = () => {
    return (
        <Stack>
            <Breadcrumbs aria-label="breadcrumb">
                <Typography color="text.primary">Configurations</Typography>
                <Typography color="text.primary">Blacklist Schedule</Typography>
            </Breadcrumbs>

            <Typography variant="h4">
                Blacklist Schedule
            </Typography>
            <Typography>
                Set a schedule of allowed times for simulations to be ran on endpoints. If a day of the week does not have any hours set, the entire day is blacked out. On the calendar, double-clicking a date will have that date be blacked out as well.
            </Typography>
        </Stack>
    )
}