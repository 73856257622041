import { useContext, useState } from "react";
import { Button, Typography, Stack } from "@mui/material";
import { postRequest } from "../../../../Helpers/httpRequests";
import { DataContext } from "../../../../Context/dataContext";
import FileSelect from "../../../FileSelect/FileSelect";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../Redux/app/hooks";

const UpdateCxLogo = () => {
  // ----- Context -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );
  // ----- States -----
  const [formData, setFormData] = useState({
    img: null,
  });

  // ----- Variable -----

  // ----- Functions -----
  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await toast.promise(
      postRequest(`/react/api/${current_customer.uuid}/admin/customer-logo`, accessToken, formData),
      {
        pending: "Updating Settings",
        success: "Settings updated",
        error: "Something went wrong!",
      },
    );

    if (res.status === 200) window.location.reload();
  };

  // ----- On page load -----

  return (
    <Stack spacing={1}>
      <Typography variant="Body1">Update Customer Logo</Typography>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2} direction="row">
          <FileSelect
            label="Image"
            formKey="img"
            accept="image/*"
            setFormData={setFormData}
          />
          <Button type="submit" variant="contained">
            Save
          </Button>
        </Stack>
        <Typography variant="caption">
          *Save without uploading an image to clear.
        </Typography>
      </form>
    </Stack>
  );
};

export default UpdateCxLogo;
