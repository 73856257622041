import React, { useState, useEffect, useContext } from "react";
import {
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import DataGridWithStyles from "../../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import { getRequest } from "../../../../Helpers/httpRequests";
import { DataContext } from "../../../../Context/dataContext";
import { populateDataGrid } from "../Utils/functions";
import { useAppSelector } from "../../../../Redux/app/hooks";

const ExpectedAlertsTable = (props) => {
  const { open } = props;
  const { accessToken } = useContext(DataContext);
  const customer = useAppSelector((state) => state.customer);
  const [expectedAlerts, setExpectedAlerts] = useState([]);
  const [selectedSim, setSelectedSim] = useState("");
  const [simulations, setSimulations] = useState([]);
  const [dataGrid, setDataGrid] = useState({
    rows: [],
    columns: [],
  });

  const handleSimChange = (e) => {
    setSelectedSim(e.target.value);
  };

  const simulationSelect = simulations.length > 0 && (
    <FormControl sx={{ maxWidth: "400px" }}>
      <InputLabel id="simulation-select-label">Simulation</InputLabel>
      <Select
        labelId="simulation-select-label"
        id="simulation-select"
        value={selectedSim}
        label="Simulation"
        onChange={handleSimChange}
      >
        <MenuItem value="">
          <b>All Simulations</b>
        </MenuItem>
        {simulations.map((sim) => {
          return (
            <MenuItem key={sim[0]} value={sim[0]}>
              {sim[1]}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  // Get the expected Alerts
  useEffect(() => {
    const getData = async () => {
      const res = await getRequest(
        `/react/api/${customer.uuid}/alertvalidation/get-expected-alerts`,
        accessToken
      );
      console.log(res.data);
      if (res.status === 200) {
        setExpectedAlerts(res.data.expectedAlerts);

        // create a way to get list of simulations from expectedalerts without duplicates
        const sims = res.data.expectedAlerts.map((alert) => alert.simulation);
        setSimulations([
          ...new Map([...sims.map((sim) => [sim.uuid, sim.name])]),
        ]);
      }
    };
    if (!open) getData();
  }, [accessToken, open, customer.uuid]);

  // populate the rows / columns of data grid
  useEffect(() => {
    const args = {
      setDataGrid,
      expectedAlerts,
      selectedSim,
    };

    if (expectedAlerts.length > 0) populateDataGrid(args);
  }, [expectedAlerts, selectedSim]);

  return (
    <Paper sx={{ p: 3 }}>
      <Stack spacing={1}>
        {simulationSelect}
        <DataGridWithStyles
          name="expected-alerts"
          autoHeight
          rows={dataGrid.rows}
          columns={dataGrid.columns}
        />
      </Stack>
    </Paper>
  );
};

export default ExpectedAlertsTable;
