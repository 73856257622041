import React, { useEffect, useContext, useState } from "react";
import { getRequest, postRequest } from "../../../../Helpers/httpRequests";
import {
  CircularProgress,
  Stack,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataContext } from "../../../../Context/dataContext";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../Redux/app/hooks";

const AssessmentRefForm = ({ setOpen, modal = false }) => {
  // ----- CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const user = useAppSelector((state) => state.user);

  // ----- PARAMS -----
  const { refUUID } = useParams();

  // ----- STATES -----
  const [isFormLoaded, setIsFormLoaded] = useState(false);

  /**
   * Object to hold the values of each input in form.
   */
  const [formData, setFormData] = useState({
    uuid: "",
    friendly_name: "",
    references: "",
    remediation: "",
  });

  /**
   * Used for handling the props/children of <LoadingButton>
   */
  const [loadingBtn, setLoadingBtn] = useState({
    loading: false,
    color: "primary",
    text: "Submit",
    helperText: "",
    helperTextColor: "green",
  });

  // ---- Variables ------

  /**
   * These are the styles for the Box inside of the modal.
   * The Modal component renders as position Absolute so we
   * have to write styles accordingly
   *
   */
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };

  // ----- Functions -----

  /**
   * Handles the form submission.
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    // prevent page reload on submit
    e.preventDefault();

    // set submit btn to loading
    setLoadingBtn((prev) => ({ ...prev, loading: true }));

    const res = await postRequest(
      `/react/api/admin/assess-ref-details/${refUUID}`,
      accessToken,
      formData,
      true
    );
    setLoadingBtn((prev) => ({ ...prev, loading: false }));

    // send form response back as 201 (created)
    if (res.status === 201) {
      // Send Popup
      toast.success("Assessment Ref Updated Successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setLoadingBtn({
        loading: false,
        text: "Submit",
        color: "primary",
        helperText: "",
        helperTextColor: "#2e7d32", // green
      });

      /**
       * If this form is contained in a modal, you can pass the setOpen state to this component.
       * This will wait 1.5 seconds and then close the modal
       * Add 'open' state to this components parent useEffect dependencies array to have your page reload the data.
       */
      if (setOpen !== undefined) {
        setTimeout(() => setOpen(false), 1500);
      }
    } else {
      // handle errors here
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Error - Try again",
        color: "error",
        helperText: "Form submission failed",
        helperTextColor: "#d32f2f", // red
      }));
      console.log(
        "%cerror AssessmentRefForm.jsx handleSubmit()",
        "color: red; display: block; width: 100%;",
        "Failed to submit form"
      );
    }
  };

  // ----- On page load -----
  useEffect(() => {
    const getDetails = async () => {
      const res = await getRequest(
        `/react/api/admin/assess-ref-details/${refUUID}`,
        accessToken
      );
      if (res.status === 200) {
        setFormData(res.data.assessment);
        setIsFormLoaded(true);
      }
    };

    // if user.current_customer changes re-run api-request
    if (user.current_customer) {
      getDetails();
    }
  }, [user.current_customer, accessToken, refUUID]);

  if (!isFormLoaded) {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <Box sx={modal ? style : null} data-testid="assessrefform">
        {/* FORM */}
        <form onSubmit={(e) => handleSubmit(e)}>
          <Stack direction="column" spacing={2}>
            {/* Start form fields here */}
            {/* Friendly Name */}
            <TextField
              name="Friendly Name"
              // placeholder="Friendly Name"
              label="Friendly Name"
              value={formData.friendly_name || ""}
              onChange={(e) =>
                setFormData((data) => ({
                  ...data,
                  friendly_name: e.target.value,
                }))
              }
            />

            {/* References */}
            <TextField
              name="References"
              placeholder="Reference"
              label="References"
              value={formData.references || ""}
              multiline
              onChange={(e) =>
                setFormData((data) => ({
                  ...data,
                  references: e.target.value,
                }))
              }
            />

            {/* Remediation */}
            <TextField
              name="Remediation"
              placeholder="Remediation"
              label="Remediation"
              value={formData.remediation || ""}
              multiline
              onChange={(e) =>
                setFormData((data) => ({
                  ...data,
                  remediation: e.target.value,
                }))
              }
            />

            {/* End form fields */}

            {/* helper text */}
            <Typography color={loadingBtn.helperTextColor} variant="subtitle2">
              {loadingBtn.helperText}
            </Typography>

            {/* Submit Button */}
            <LoadingButton
              loading={loadingBtn.loading}
              color={loadingBtn.color}
              type="submit"
              variant="contained"
            >
              {loadingBtn.text}
            </LoadingButton>
          </Stack>
        </form>
      </Box>
    );
  }
};

AssessmentRefForm.propTypes = {
  setOpen: PropTypes.func,
  modal: PropTypes.bool,
};

export default AssessmentRefForm;
