import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import React from "react";
import { Button, Typography, CircularProgress, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const LogChainDND = (props) => {
  const { logChain, deleteItem, setLogChain } = props;

  const onDragEnd = (result, logChain, setLogChain) => {
    if (!result.destination) {
      return;
    }
    const updatedStack = Array.from(logChain);
    const [removed] = updatedStack.splice(result.source.index, 1);
    updatedStack.splice(result.destination.index, 0, removed);

    updatedStack.forEach((tool, index) => {
      tool.position = index;
    });

    setLogChain({
      tools: updatedStack,
    });
  };

  if (logChain) {
    return (
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, logChain, setLogChain)}
      >
        <Droppable droppableId="droppabale">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {logChain.map((tool, index) => (
                <Stack key={index} alignItems="center">
                  <Draggable
                    key={index}
                    draggableId={index.toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="border-2 border-neutral-500 rounded-md shadow-md overflow-hidden"
                      >
                        <div className="flex w-[320px] h-[100px] items-center bg-gradient-to-r from-slate-300 to-slate-400">
                          <div className="flex-1 flex justify-center">
                            <Typography variant="h5">
                              {tool.tool.name}
                            </Typography>
                          </div>
                          <div className="flex-1 flex justify-center">
                            {/* <Typography>
                                Product: {tool.tool.product}
                              </Typography> */}
                            {/* This Order is the tool.position field */}
                            {/* <Typography>Order: {index + 1}</Typography> */}
                            <Button onClick={() => deleteItem(tool)}>
                              <DeleteIcon color="error" />
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                  <div className="my-2">
                    <ArrowDownwardIcon />
                  </div>
                </Stack>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
  return <CircularProgress />;
};

export default LogChainDND;
