import React, { useState } from "react";
import Form from "../../../Components/Reporting/CampaignDataTable/Form";
import BSBreadcrumbs from "../AVExpectedAlerts/Components/BSBreadcrumbs";
import ExpectedAlertsTable from "./Components/ExpectedAlertsTable";

const AVExpectedAlerts = () => {
  // Track the state of the form so we know when to update our table values
  const [open, setOpen] = useState(false);

  return (
    <>
      <BSBreadcrumbs />
      <Form open={open} setOpen={setOpen} />
      <ExpectedAlertsTable open={open} />
    </>
  );
};

export default AVExpectedAlerts;
