import { TextField, Typography } from "@mui/material";
import FormModal from "../../../../Components/Modals/FormModal";
import { handleUploadFileChange } from "../functions";

const JsonUploadModal = ({ uploadModal, setUploadModal, setData }) => {
  return (
    <>
      <FormModal open={uploadModal} setOpen={setUploadModal}>
        <Typography fontWeight={"bold"} variant="h5" sx={{ marginBottom: 2 }}>
          Upload JSON File
        </Typography>
        <div>
          <TextField
            type="file"
            accept=".json"
            onChange={(event) =>
              handleUploadFileChange(event, setUploadModal, setData)
            }
          />
          {/* display the JSON data */}
        </div>
      </FormModal>
    </>
  );
};

export default JsonUploadModal;
