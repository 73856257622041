import React, { useEffect, useState, useContext } from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { getRequest } from "../../../Helpers/httpRequests";
import { DataContext } from "../../../Context/dataContext";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles";
import { useAppSelector } from "../../../Redux/app/hooks";
import { Link } from "react-router-dom";

const AdminEndpointAssessment = () => {
  // ----- CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  // ----- STATES -----

  /**
   * contains the datagrid props
   */
  const [dataGridData, setDataGridData] = useState({
    rows: [],
    columns: [],
    pageSize: 10,
  });

  // ----- VARIABLES -----

  // ----- Functions -----

  // ----- On page load -----

  useEffect(() => {
    /**
     * This function handles the creation of columns and rows for MUI Datagrid. Running this function will could the MUI-Datagrid to re-mount.
     * @param {} param
     */
    const populateDataGrid = (assessments) => {
      const columns = [
        {
          field: "category",
          headerName: "Category",
          minWidth: 100,
          flex: 1,
          renderCell: (params) => (
            <Link
              to={`/${current_customer.uuid}/administration/assess-references/${params.row.id}`}
            >
              {params.row.category}
            </Link>
          ),
        },
        {
          field: "friendlyName",
          headerName: "Friendly Name",
          minWidth: 100,
          flex: 1,
        },
      ];

      const rows = assessments.map((element) => {
        return {
          id: element.uuid,
          category: element.category,
          friendlyName: element.friendly_name,
        };
      });

      setDataGridData((data) => ({
        ...data,
        rows: rows,
        columns: columns,
      }));
    };

    const getAssessments = async () => {
      const res = await getRequest(
        "/react/api/admin/assess-references",
        accessToken
      );
      if (res.status === 200) {
        populateDataGrid(res.data.references);
      } else {
        console.log(
          "%cerror AdminEndpointAssessment.jsx useEffect()",
          "color: red; display: block; width: 100%;",
          "Reponse status not 200"
        );
      }
    };

    getAssessments();
  }, [accessToken, current_customer]);

  return (
    <Stack spacing={3} data-testid="adminendpointassessment">
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Administration</Typography>
        <Typography color="text.primary">Assessment References</Typography>
      </Breadcrumbs>

      {/* Page Title */}
      <Typography variant="h3">
        Endpoint Assessment Reference Library
      </Typography>

      {/* Template Grid */}
      <DataGridWithStyles
        name="admin-endpoint-assess"
        autoHeight
        paper
        rows={dataGridData.rows}
        columns={dataGridData.columns}
      />
    </Stack>
  );
};

export default AdminEndpointAssessment;
