import React from "react";
import { Breadcrumbs, Stack, Typography } from "@mui/material";
import AddEmails from "./components/AddEmails";

const AVSettings = () => {
  return (
    <Stack spacing={2}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Alert Validation</Typography>
        <Typography color="text.primary">Notifications</Typography>
      </Breadcrumbs>
      <Typography variant="h3">Notifications</Typography>

      <AddEmails sx={{ width: "50%" }} />
    </Stack>
  );
};

export default AVSettings;
