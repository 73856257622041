import React from "react";
import { Stack } from "@mui/material";
import { Header } from "./subcomponents/Header";
import { ScheduleCalendar } from "./subcomponents/ScheduleCalendar";
import { DetailsContext } from "./ContextProvider/ContextProvider";
import { useHttpRequest } from "../../../Hooks";
import { TimeSlots } from "./subcomponents/TimeSlots";


export const BlackoutScheduling = () => {

  const {
    response: schedule,
    fetch: getSchedule
  } = useHttpRequest({
    method: "GET",
    path: "/api/v2/schedule",
  })

  return (
    <DetailsContext.Provider
      value={{
        schedule,
        getSchedule
      }}
    >
      <Stack spacing={2}>
        <Header />
        <TimeSlots />
        <ScheduleCalendar />
      </Stack>
    </DetailsContext.Provider>
  )
}