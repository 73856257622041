export const populateDataGrid = (args) => {
  const { setDataGrid, expectedAlerts, selectedSim} = args;
  const columns = [
    {
      field: "expectedAlertName",
      headerName: "Name",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "secTool",
      headerName: "Security Tool",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "simulation",
      headerName: "Simulation",
      minWidth: 100,
      flex: 1,
    },
  ];

  /**
   * Filter the rows to ensure we only show the selected simulation.
   * OR if the value of selected sim is Falsy, this means that one hasn't been selected in the
   * dropdown and we should just return true and show all
   *
   * selectedSim is a state managed by `handleSimChange` function
   */
  const rows = expectedAlerts
    .filter(
      (alert) => alert.simulation.uuid === selectedSim || !selectedSim
    )
    .map((alert) => {
      return {
        id: alert.uuid,
        expectedAlertName: alert.alert_name,
        simulation: alert.simulation.name,
        secTool: alert.security_tool_product?.name,
      };
    });

  setDataGrid({
    rows: rows,
    columns: columns,
  });
};