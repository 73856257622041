import React from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import TextLink from "../../../Components/TextLink/TextLink";

const Walkthrough = () => {
  // ----- Context -----

  // ----- States -----

  // ----- Variable -----

  // ----- Functions -----

  // ----- On page load -----

  return (
    <Stack spacing={3}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <TextLink url="/support/help">Support</TextLink>
        <Typography color="text.primary">Walkthrough</Typography>
      </Breadcrumbs>

      {/* Page Title */}
      <Typography variant="h4">BlindSPOT Walk Through the Simulation Library</Typography>
      <div>
        <ol>
          <li>
            <div>
              Login to the BlindSPOT platform, using your given credentials
            </div>
          </li>
          <li>
            <div>
              Click the{" "}
              <TextLink display="inline" url="/simulations/list">
                Simulation Library
              </TextLink>{" "}
              option in the left-hand menu options sidebar.
            </div>
            <img
              src="../../../static/img/help/SimLibraryRedo.png"
              alt="Simulation Library Screenshot"
            />
          </li>
          <br />
          <li>
            <div>
              Once on the{" "}
              <TextLink display="inline" url="/simulations/list">
                Simulations Library
              </TextLink>{" "}
              page, you will notice that there are a number of options you have
              available to you.
            </div>
            <img
              src="../../../static/img/help/simulationExample.png"
              alt="simulation page example"
            />
          </li>
          <br />
          <li>
            <div>
              No matter which one you choose to use, when you open it, they will
              all look the same. Each simulation has a number of options you can
              select from in order to <b>start a campaign.</b>
            </div>
            <img
              src="../../../static/img/help/ContiExample.png"
              alt="Payload example screenshot"
            />
          </li>
          <br />
          <li>
            <div>
              On the <b>left column</b>, there are several options that allow
              you to kick off a simulation
            </div>
            <img
              src="../../../static/img/help/BinaryExeExample.png"
              alt="Executable example"
            />
          </li>
          <br />
          <li>
            <div>
              If you'd like to start a simulation, start by{" "}
              <b>selecting a payload file</b>, then copying it to your clipboard{" "}
              <span style={{ backgroundColor: "yellow" }}>
                and write it to disk, and attempt to run it.
              </span>
              <b>
                If you need additional help on how to run a simulation, please
                look at our tutorial on{" "}
                <TextLink display="inline" url="/support/RunAgent">Running a Simulation</TextLink>
              </b>
            </div>
            <img
              src="../../../static/img/help/Filename.png"
              alt="copy file name example"
            />
          </li>
          <br />
          <li>
            <div>
              On the right side of the
              <TextLink display="inline" url="/simulations/list">Simulations Library</TextLink> page, you can
              see the <b>techniques included in the selected simulation</b>.
            </div>
            <img
              src="../../../static/img/help/TechniquesIncluded.png"
              alt="Techniques included example"
            />
            <div>
              <b>
                If you click on any technique, you can get a more in-depth look
                at the details of that technique in our internal library.
              </b>
            </div>
          </li>
          <br />
          <li>
            <div>
              If you click on any technique, you can get a more in-depth look at
              the details of that technique in our <b>internal library</b>. The
              internal library should look like the image below.
            </div>
            <img
              src="../../../static/img/help/TechniqueView.png"
              alt="Technique vieww in library"
            />
          </li>
        </ol>
      </div>
    </Stack>
  );
};

export default Walkthrough;
