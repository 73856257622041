import React, { useEffect, useState, useContext } from "react";

import {
  Divider,
  FormControl,
  Stack,
  TextField,
  Typography,
  Box,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useParams, useNavigate } from "react-router-dom";
import { DataContext } from "../../../../Context/dataContext";
import { getRequest, postRequest } from "../../../../Helpers/httpRequests";
import { useAppSelector } from "../../../../Redux/app/hooks";

const Endpoint_Edit = () => {
  // ----- STATES && CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer,
  );
  const [endpoint, setEndpoint] = useState([]);
  const [endpointName, setEndpointName] = useState();
  const [endpointConfigFile, setEndpointConfigFile] = useState();
  const [endpointLogFile, setEndpointLogFile] = useState();
  const [endpointBaseUrl, setEndpointBaseUrl] = useState();
  const [endpointSleeptime, setEndpointSleeptime] = useState();
  const [endpointArchived, setEndpointArchived] = useState(false);

  // Submit Btn
  const [submitBtnColor, setSubmitBtnColor] = useState("primary");
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [submitBtnText, setSubmitBtnText] = useState("Submit");

  // ----- VARIABLES -----
  const { endpointUUID } = useParams();
  const navigate = useNavigate();

  // ----- FUNCTIONS -----
  const handleSubmit = async () => {
    setSubmitBtnLoading(true);
    const data = {
      name: endpointName,
      config_file: endpointConfigFile,
      log_file: endpointLogFile,
      baseURL: endpointBaseUrl,
      sleeptime: endpointSleeptime,
      archived: endpointArchived,
    };

    const res = await postRequest(
      `/react/api/${current_customer.uuid}/endpoints/edit/${endpointUUID}`,
      accessToken,
      data,
    );
    setSubmitBtnLoading(false);
    if (res.status === 200) {
      navigate(`/${current_customer.uuid}/simulations/endpoints/list/`);
    } else {
      setSubmitBtnColor("error");
      setSubmitBtnText("Error - Try again");
    }
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getFormData = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/endpoints/edit/${endpointUUID}`,
        accessToken,
      );
      try {
        setEndpoint(res.data.endpoints.name);
        setEndpointName(res.data.endpoints.name);
        setEndpointConfigFile(res.data.endpoints.config_file);
        setEndpointLogFile(res.data.endpoints.log_file);
        setEndpointBaseUrl(res.data.endpoints.baseURL);
        setEndpointSleeptime(res.data.endpoints.sleeptime);
      } catch (err) {
        console.log("Error while trying to set States from getFormData()", err);
      }
    };

    getFormData();
  }, [current_customer, accessToken, endpointUUID]);

  return (
    <>
      <Box sx={{ maxWidth: "md" }}>
        <Stack>
          <Typography fontWeight={"bold"} variant="h5">
            Edit {endpoint || ""}{" "}
          </Typography>
          <Divider style={{ margin: "1rem 0" }} />

          <Stack spacing={2}>
            {/* Name */}
            <FormControl>
              <TextField
                type="text"
                label="Name"
                helperText=""
                value={endpointName || ""}
                onChange={(e) => setEndpointName(e.target.value)}
              />
            </FormControl>

            {/* Config File
            <FormControl>
              <TextField
                type="text"
                label="Config File"
                helperText=""
                value={endpointConfigFile || ""}
                onChange={(e) => setEndpointConfigFile(e.target.value)}
              />
            </FormControl> */}

            {/* Log File */}
            {/* <FormControl>
              <TextField
                type="text"
                label="Log File"
                helperText=""
                value={endpointLogFile || ""}
                onChange={(e) => setEndpointLogFile(e.target.value)}
              />
            </FormControl> */}

            {/* BaseURL  */}
            {/* <FormControl>
              <TextField
                type="text"
                label="BaseURL"
                helperText=""
                value={endpointBaseUrl || ""}
                onChange={(e) => setEndpointBaseUrl(e.target.value)}
              />
            </FormControl> */}

            {/* Sleeptime  */}
            {/* <FormControl>
              <TextField
                type="number"
                label="Sleeptime"
                helperText=""
                value={endpointSleeptime || ""}
                onChange={(e) => setEndpointSleeptime(e.target.value)}
              />
            </FormControl> */}

            {/* Archived  */}
            <FormControl>
              <Typography>
                Archived
                <Checkbox
                  sx={{ marginLeft: 5 }}
                  defaultChecked={false}
                  onChange={(e) => setEndpointArchived(e.target.checked)}
                />
              </Typography>
            </FormControl>

            {/* Loading Button */}
            <LoadingButton
              variant="contained"
              loading={submitBtnLoading}
              onClick={handleSubmit}
              color={submitBtnColor}
            >
              {submitBtnText}
            </LoadingButton>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default Endpoint_Edit;
