import React, { useEffect, useState, useContext } from "react";
import {
  FormControl,
  Stack,
  TextField,
  Typography,
  Paper,
  Box,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataContext } from "../../../../Context/dataContext";
import { getRequest, postRequest } from "../../../../Helpers/httpRequests";
import { useAppSelector } from "../../../../Redux/app/hooks";

const SecurityToolEditForm = ({ toolUUID, closeModal, setReload }) => {
  // ----- STATES && CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  const [formData, setFormData] = useState({
    name: "",
    vendor: "",
    log_destination: "",
    access_url: "",
    api_key: "",
    product: "",
  });

  const [product_list, setProductList] = useState([]);

  // Submit Btn
  const [submitBtnColor, setSubmitBtnColor] = useState("primary");
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [submitBtnText, setSubmitBtnText] = useState("Submit");

  // ----- VARIABLES -----

  // ----- FUNCTIONS -----
  const handleSubmit = async () => {
    setSubmitBtnLoading(true);
    const data = {
      name: formData.name,
      vendor: formData.vendor,
      log_destination: formData.log_destination,
      access_url: formData.access_url,
      api_key: formData.api_key,
      product: formData.product,
    };

    const res = await postRequest(
      `/react/api/${current_customer.uuid}/config/securitytools/edit/${toolUUID}`,
      accessToken,
      data
    );
    setSubmitBtnLoading(false);
    if (res.status === 200) {
      // Close the modal when form data is loaded
      closeModal();
      setReload((current) => !current);
    } else {
      setSubmitBtnColor("error");
      setSubmitBtnText("Error - Try again");
    }
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getFormData = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/config/securitytools/edit/${toolUUID}`,
        accessToken
      );
      if (res.status === 200) {
        try {
          setFormData({
            name: res.data.form_serializer.name || "",
            vendor: res.data.form_serializer.vendor || "",
            access_url: res.data.form_serializer.access_url || "",
            api_key: res.data.form_serializer.api_key || "",
            log_destination: res.data.form_serializer.log_destination || "",
            product: res.data.form_serializer.product || "Other",
          });
          setProductList(res.data.product_choices);
        } catch (err) {
          console.log(
            "Error while trying to set States from getFormData()",
            err
          );
        }
      }
    };

    getFormData();
  }, [current_customer, accessToken, toolUUID]);

  return (
    <>
      <Box
        sx={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Typography fontWeight={"bold"} variant="h4">
          Edit Security Tool
        </Typography>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box
          className="left-box"
          sx={{ flex: 1, paddingRight: 1, marginTop: 1 }}
        >
          <Paper sx={{ padding: 2 }}>
            <Stack spacing={1.5}>
              {/* Name */}
              <TextField
                type="text"
                label="Name"
                helperText=""
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
              />

              {/* Vendor */}
              <TextField
                type="text"
                label="Vendor"
                helperText=""
                value={formData.vendor}
                onChange={(e) =>
                  setFormData({ ...formData, vendor: e.target.value })
                }
              />

              {/* Product */}
              <FormControl>
                <InputLabel id="product">Product</InputLabel>
                <Select
                  labelId="product-label"
                  label="Product"
                  value={formData.product}
                  onChange={(e) =>
                    setFormData({ ...formData, product: e.target.value })
                  }
                >
                  {product_list.map((product) => (
                    <MenuItem key={product[0]} value={product[0]}>
                      {product[1]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Log Destination */}
              <FormControl>
                <InputLabel id="demo-simple-select-label">
                  Log Destination
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Log Destination"
                  value={formData.log_destination}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      log_destination: e.target.value,
                    })
                  }
                >
                  <MenuItem value="">------</MenuItem>
                  <MenuItem value={"siem"}>SIEM</MenuItem>
                  <MenuItem value={"console"}>System Console</MenuItem>
                  <MenuItem value={"other"}>Other Log Destination</MenuItem>
                  <MenuItem value={"none"}>
                    No Logs forwarded or collected
                  </MenuItem>
                </Select>
              </FormControl>

              {/* Loading Button */}
              <LoadingButton
                variant="contained"
                loading={submitBtnLoading}
                onClick={handleSubmit}
                color={submitBtnColor}
              >
                {submitBtnText}
              </LoadingButton>
            </Stack>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default SecurityToolEditForm;
