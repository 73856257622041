import React, { useContext, useEffect, useState } from "react";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import './css/Calendar.css'
import { DetailsContext } from "../ContextProvider/ContextProvider";
import moment from "moment";
import { useHttpRequest } from "../../../../Hooks";



export const ScheduleCalendar = () => {
    const { schedule } = useContext(DetailsContext)

    const [value, onChange] = useState(new Date());

    // this is to make changing a date to be enabled/disabled a double click event
    const [lastDateClicked, setLastDateClicked] = useState(null);

    const daysAvailable = []

    const {
        fetch: enableOrDisableCall,
        response
    } = useHttpRequest({
        method: "PATCH",
        path: "/api/v2/schedule",
        defer: true,
        data: {
            schedule: schedule?.id,
            date: lastDateClicked
        }
    })

    // null safety is only for when the page first loads
    if (schedule?.time_availability) {
        schedule.time_availability.forEach(timeslot => {
            if (!daysAvailable.includes(timeslot.day)) {
                daysAvailable.push(timeslot.day)
            }
        });
    }


    const disableDay = ({ activeStartDate, date, view }) => {
        return !daysAvailable.includes(date.getDay())
    }

    // this just applies css that makes a date look like it's disabled 
    // for cases where a date that otherwise would be legal becomes disabled
    // this is so you can re-enable the date
    const psuedoDisableDay = ({ activeStartDate, date, view }) => {
        if (schedule?.date_availability.includes(moment(date).format("YYYY-MM-DD"))) {
            return "psuedo-disabled"
        } else {
            return ""
        }
    }


    const enableOrDisableDate = (date, event) => {
        if (lastDateClicked === moment(date).format("YYYY-MM-DD")) {
            if (schedule?.date_availability.includes(moment(date).format("YYYY-MM-DD"))) {
                const index = schedule.date_availability.indexOf(moment(date).format("YYYY-MM-DD"));
                if (index !== -1) {
                    schedule.date_availability.splice(index, 1);
                }
            } else {
                schedule.date_availability.push(moment(date).format("YYYY-MM-DD"))
            }
            enableOrDisableCall()
        } else {
            setLastDateClicked(moment(date).format("YYYY-MM-DD"))
        }
    }

    const tileContent = ({ activeStartDate, date, view }) => {
        if (daysAvailable.includes(date.getDay()) && !schedule?.date_availability.includes(moment(date).format("YYYY-MM-DD"))) {
            let contentStr = ""
            schedule.time_availability.forEach(timeslot => {
                if (timeslot.day === date.getDay()) {
                    let { start_hour, start_minute, end_hour, end_minute } = timeslot
                    if (start_minute.toString().length === 1) {
                        start_minute = `0${start_minute}`
                    }
                    if (end_minute.toString().length === 1) {
                        end_minute = `0${end_minute}`
                    }
                    contentStr += `${start_hour}:${start_minute} - ${end_hour}:${end_minute}\n`
                }
            })
            return <pre>{contentStr}</pre>
        }
    }

    useEffect(() => { 
        setLastDateClicked(null) 
    }, [response])

    return (
        <Calendar
            onChange={onChange}
            value={value}
            calendarType="gregory"
            minDate={new Date()}
            tileDisabled={disableDay}
            onClickDay={enableOrDisableDate}
            tileClassName={psuedoDisableDay}
            tileContent={tileContent}
        />
    )
}