import { Box, Breadcrumbs, Paper, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TextButton from "../../../Components/Buttons/TextButton";
import { DataContext } from "../../../Context/dataContext";
import { getRequest } from "../../../Helpers/httpRequests";

import { Stack } from "@mui/system";
import moment from "moment";
import {
  FaDownload,
  FaQuestionCircle,
  FaThumbsDown,
  FaThumbsUp,
} from "react-icons/fa";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import { useAppSelector } from "../../../Redux/app/hooks";
import { COLORS } from "../../../Styles/colors";

const Reports_Endpoints = () => {
  const [dataTableRows, setDataTableRows] = useState([]);
  const [fail, setFail] = useState([]);
  const [notscored, setNotScored] = useState([]);
  const [pass, setPass] = useState([]);
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  //Variables

  const columns = [
    {
      field: "name",
      headerName: "Agent Name",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        let link =
          `/${current_customer.uuid}/reporting/endpoints/` + params.row.id;
        return <Link to={link}>{params.row.name}</Link>;
      },
    },
    { field: "host", headerName: "Host", minWidth: 100, flex: 1 },
    { field: "domain", headerName: "Domain", minWidth: 100, flex: 1 },
    { field: "host_os", headerName: "Host OS", minWidth: 100, flex: 1 },
    {
      field: "last_assessment",
      headerName: "Last Assessment",
      minWidth: 100,
      flex: 1,
    },
  ];

  //Functions

  const populateDataGrid = (endpoints_ass) => {
    const rows = endpoints_ass.map((endpoint) => {
      return {
        id: endpoint.uuid,
        name: endpoint.name,
        host: endpoint.host,
        domain: endpoint.domain || "None",
        host_os: endpoint.host_os,
        last_assessment: moment(endpoint.installed_date).format(
          "MMM. D, YYYY, hh:mm a"
        ),
      };
    });
    setDataTableRows(rows);
  };

  // ----- ON PAGE LOAD -----

  useEffect(() => {
    const getEndpoints_Assess = async () => {
      const res = await getRequest(`/react/api/${current_customer.uuid}/report/endpoints`, accessToken);
      if (res.status === 200) {
        setFail(res.data.counts.fail);
        setNotScored(res.data.counts.notscored);
        setPass(res.data.counts.pass);
        populateDataGrid(res.data.agent_list);
      }
    };

    getEndpoints_Assess();
  }, [current_customer, accessToken]);

  return (
    <Stack spacing={2}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">Home</Link>
        <Typography color="text.primary">Reports</Typography>
        <Typography color="text.primary">Endpoint Assessments</Typography>
      </Breadcrumbs>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography component={"span"} fontWeight={"bold"} variant="h4">
          Endpoint Assessments
        </Typography>
        <Link
          to={`/${current_customer.uuid}/simulations/endpoints/download-agents`}
        >
          <TextButton tooltip="Download Agents" icon={FaDownload} />
        </Link>
      </Box>

      <Box
        sx={{
          height: "10%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Paper
          sx={{
            flex: 1,
            marginRight: 5,
            marginTop: 1,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FaThumbsDown
            style={{ marginTop: 20, marginLeft: 20 }}
            size={40}
            color={COLORS.error.main}
          />
          <Stack sx={{ marginLeft: 5, marginTop: 1 }}>
            <Typography
              component={"span"}
              fontSize={25}
              fontWeight={"bold"}
              color={"red"}
            >
              {fail}
            </Typography>
            <Typography component={"span"} fontSize={15} color="text.primary">
              Failed
            </Typography>
          </Stack>
        </Paper>
        <Paper
          sx={{
            flex: 1,
            marginRight: 4,
            marginTop: 1,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FaThumbsUp
            style={{ marginTop: 20, marginLeft: 20 }}
            size={40}
            color={COLORS.success.main}
          />
          <Stack sx={{ marginLeft: 5, marginTop: 1 }}>
            <Typography
              component={"span"}
              fontSize={25}
              fontWeight={"bold"}
              color={"green"}
            >
              {pass}
            </Typography>
            <Typography component={"span"} fontSize={15} color="text.primary">
              Passed
            </Typography>
          </Stack>
        </Paper>
        <Paper
          sx={{ flex: 1, marginTop: 1, display: "flex", flexDirection: "row" }}
        >
          <FaQuestionCircle
            style={{ marginTop: 20, marginLeft: 20 }}
            size={40}
            color={COLORS.yellow}
          />
          <Stack sx={{ marginLeft: 5, marginTop: 1 }}>
            <Typography
              component={"span"}
              fontSize={25}
              fontWeight={"bold"}
              color={"orange"}
            >
              {notscored}
            </Typography>
            <Typography component={"span"} fontSize={15} color="text.primary">
              Not Scored
            </Typography>
          </Stack>
        </Paper>
      </Box>

      <DataGridWithStyles
        name="report-endpoints"
        autoHeight
        paper
        rows={dataTableRows}
        columns={columns}
      />
    </Stack>
  );
};

export default Reports_Endpoints;
