import React, { useContext } from "react";
import { Button, Stack, Typography } from "@mui/material";
import FormModal from "../../../../Components/Modals/FormModal";
import { getRequest } from "../../../../Helpers/httpRequests";
import { DataContext } from "../../../../Context/dataContext";
import { toast } from "react-toastify";

const DeleteModal = ({ open, setOpen, user, customer }) => {
  const { accessToken } = useContext(DataContext);
  const handleArchive = async () => {
    const url = `/react/api/admin/archive-user/${user.uuid}`;
    const res = await toast.promise(getRequest(url, accessToken), {
      pending: "Archiving User",
      success: `${user.first} ${user.last} archived successfully!`,
      error: "Something went wrong!",
    });
    if (res.status === 200) {
      setOpen(false);
    }
  };
  const handleDelete = async () => {
    const url = `/react/api/admin/delete-user/${user.uuid}`;
    const res = await toast.promise(getRequest(url, accessToken), {
      pending: "Deleting User",
      success: `${user.first} ${user.last} deleted successfully!`,
      error: "Something went wrong!",
    });
    if (res.status === 200) {
      setOpen(false);
    }
  };
  return (
    <FormModal setOpen={setOpen} open={open}>
      <Stack spacing={1} sx={{ mb: 3 }}>
        <Typography>
          Would you like to delete this user permanently or would you just like
          to archive them?
        </Typography>
        <Typography>
          Deleting a user will remove them from any and all campaigns/projects,
          they will no longer appear anywhere in the app.
        </Typography>
        <Typography>
          Archiving a user will still make the users account inactive. But the
          user will still appear on campaigns/projects they were part of, or
          created.
        </Typography>
      </Stack>
      <Stack spacing={2} direction="row" justifyContent="space-evenly">
        <Button variant="contained" color="warning" onClick={handleArchive}>
          Archive
        </Button>
        <Button variant="contained" color="error" onClick={handleDelete}>
          Delete
        </Button>
      </Stack>
    </FormModal>
  );
};

export default DeleteModal;
