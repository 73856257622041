/* eslint-disable */
import { useEffect, useContext, useState } from "react";
import {
  Stack,
  TextField,
  Typography,
  Modal,
  CircularProgress,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { DataContext } from "../../../Context/dataContext";
import { getRequest, postRequest } from "../../../Helpers/httpRequests";
import { Box } from "@mui/system";

import { LoadingButton } from "@mui/lab";
import "./styles.css";
import AVTestCaseActivies from "./AVTestCaseActivites";
import AdditionalAlerts from "./AdditionalAlerts";
import {
  Bcrumbs,
  PageTitle,
  ExecutionDetails,
  BigStatus,
  ToolsValidated,
  AVMissingSimAction,
} from "./subcomponents";
import ExerciseDetails from "./subcomponents/ExerciseDetails";
import ExpectedAlertPanel from "./subcomponents/ExpectedAlerts/ExpectedAlertPanel";
import InternalError from "../../../Components/InternalError";
import { AVDetailsContext } from "./ContextProvider/ContextProvider";
import { useAppSelector } from "../../../Redux/app/hooks";
const moment = require("moment");
/**
Importing momentDurationFormatSetup lets you format time delta. 
It doesn't get called but does overide init method.
Consider adding a time_delta field onto the alert_finding so this 
doesn't need to be calculated everytime this page loads for every finding
*/

// eslint-disable-next-line
let momentDurationFormatSetup = require("moment-duration-format");

const AV_Details = () => {
  // The parameter passed in from the url
  const { campaignUUID } = useParams();

  // Navigation for redirection and links

  // ----- STATES && CONTEXT -----
  const [alertID, setAlertID] = useState("");
  const [slaTime, setSlaTime] = useState(30);
  const [alertInfo, setAlertInfo] = useState({
    av_campaign: {},
    expected_alerts: [],
    cached_findings: {},
    additional_alerts: [],
  });
  const [expanded, setExpanded] = useState(false);
  const [note, setNote] = useState("");
  const [noteUUID, setNoteUUID] = useState("");
  const [findingUUID, setFindingUUID] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");
  const [reloadPage, setReloadPage] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [alertProcessing, setAlertProcessing] = useState(false);
  const [secTools, setSecTools] = useState([]);
  const [expectedAlerts, setExpectedAlerts] = useState([]);
  const [findings, setFindings] = useState();
  const [missedActions, setMissedActions] = useState([]);
  const [backendError, setBackendError] = useState(false);

  // Contexts
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  // ----- VARIABLES -----

  const modalStyle = {
    position: "absolute",
    bgcolor: "background.paper",
    top: "50%",
    left: "50%",
    width: "40%",
    maxWidth: 1000,
    transform: "translate(-25%, -50%)",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  // ----- FUNCTIONS -----

  const closeModal = () => {
    setOpenModal(false);
  };

  const closeEditModal = () => {
    setOpenEditModal(false);
  };

  const handleSubmit = async () => {
    const result = await postRequest(
      `/react/api/${current_customer.uuid}/alertvalidation/notes/add/${findingUUID}`,
      accessToken,
      { note: note }
    );
    if (result.status === 200) {
      setOpenModal(false);
      setReloadPage((current) => !current);
    } else {
      setShowErrorMsg(true);
      setStatusMsg(result.data.message);
    }
  };

  const EditNoteSubmit = async () => {
    const result = await postRequest(
      `/react/api/${current_customer.uuid}alertvalidation/notes/${noteUUID}/edit`,
      accessToken,
      { note: note }
    );
    if (result.status === 200) {
      setOpenEditModal(false);
      setReloadPage((current) => !current);
    } else {
      setShowErrorMsg(true);
      setStatusMsg(result.data.message);
    }
  };

  // handles the opening and closing of each finding
  const handleChange = (panel) => (_event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getAlertDetails = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/alertvalidation/alert/campaign/${campaignUUID}`,
        accessToken
      );
      if (res.status === 200) {
        console.log("inside alert details", res.data);
        setAlertInfo({
          av_campaign: res.data.av_campaign,
          expected_alerts: res.data.expected_alerts,
          cached_findings: res.data.cached_findings,
          additional_alerts: res.data.additional_alerts,
        });
        setAlertID(res.data.av_campaign.scheduled_exercise.id);
        setSlaTime(res.data.av_campaign.scheduled_exercise.sla_time);
        const commaIndex = res.data.av_campaign.name.indexOf(",");
        const shortenedTitle =
          commaIndex !== -1
            ? res.data.av_campaign.name.substring(0, commaIndex)
            : res.data.av_campaign.name;
        setPageTitle(shortenedTitle);
        setSecTools(
          res.data.av_campaign.scheduled_exercise.security_stack.tools
        );
        setExpectedAlerts(
          res.data.av_campaign.scheduled_exercise.expected_alerts
        );
        setFindings(res.data.cached_findings);
        setMissedActions(res.data.missed_sim_actions)
      } else {
        setBackendError(true);
      }
    };

    // Prevents functions from being called twice
    // Ensure page is rerendered if the cx is changed
    if (!alertProcessing) {
      getAlertDetails();
    }
  }, [
    current_customer,
    reloadPage,
    accessToken,
    campaignUUID,
    alertProcessing,
  ]);

  return alertInfo.cached_findings.length > 0 || missedActions.length > 0 ? (
    <AVDetailsContext.Provider
      value={{
        alertID,
        alertProcessing,
        setAlertProcessing,
        pageTitle,
        campaignUUID,
        alertInfo,
        slaTime,
        expanded,
        handleChange,
        secTools,
        expectedAlerts,
        findings,
        setReloadPage,
        reloadPage,
        missedActions
      }}
    >
      <Stack spacing={3}>
        <Bcrumbs />
        <PageTitle />

        {/* Details and status */}
        <Stack direction="row" spacing={2}>
          <ExerciseDetails />
          <ExecutionDetails />
          <ToolsValidated />
          <BigStatus />
        </Stack>

        {/* Expected Alert Objects */}
        <Stack direction="row" spacing={2}>
          <ExpectedAlertPanel />
        </Stack>

        <Stack spacing={2} sx={{ marginTop: 3 }}>
          <AVTestCaseActivies avCachedFindings={alertInfo.cached_findings} />
          {missedActions.length > 0 && <AVMissingSimAction missedActions={missedActions} />}
        </Stack>
        <Stack spacing={2} sx={{ marginTop: 3 }}>
          <AdditionalAlerts
            additionalAlerts={alertInfo.additional_alerts}
            av_campaign={alertInfo.av_campaign}
          />
        </Stack>
        {/* New Note modal */}
        <Modal
          open={openModal}
          onClose={closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle} component="form">
            <Typography variant="h5">Add New Note</Typography>
            <Stack direction="column" spacing={2}>
              {/* Note */}
              <TextField
                type="text"
                label="Note"
                helperText=""
                minRows={1}
                maxRows={4}
                multiline
                onChange={(e) => setNote(e.target.value)}
              />
              {/* Error Status Message */}
              <Typography
                sx={{ display: showErrorMsg ? "block" : "none" }}
                color="error"
                variant="p"
              >
                Bad Request: {statusMsg}
              </Typography>
              <LoadingButton variant="contained" onClick={handleSubmit}>
                Submit
              </LoadingButton>
            </Stack>
          </Box>
        </Modal>
        {/* Edit Note modal */}
        <Modal
          open={openEditModal}
          onClose={closeEditModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle} component="form">
            <Typography variant="h5">Edit Note</Typography>
            <Stack direction="column" spacing={2}>
              {/* Note */}
              <TextField
                type="text"
                value={note}
                label="Note"
                helperText=""
                minRows={1}
                maxRows={4}
                multiline
                onChange={(e) => setNote(e.target.value)}
              />
              {/* Error Status Message */}
              <Typography
                sx={{ display: showErrorMsg ? "block" : "none" }}
                color="error"
                variant="p"
              >
                Bad Request: {statusMsg}
              </Typography>
              <LoadingButton variant="contained" onClick={EditNoteSubmit}>
                Submit
              </LoadingButton>
            </Stack>
          </Box>
        </Modal>
      </Stack>
    </AVDetailsContext.Provider>
  ) : !backendError ? (
    <CircularProgress />
  ) : (
    <InternalError errorMessage="Error access Exercise Details, Please try again later." />
  );
};

export default AV_Details;
