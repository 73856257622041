import { useContext, useEffect, useRef } from "react";
import {
  Breadcrumbs,
  Paper,
  Typography,
  Divider,
  Button,
  TextField,
  Popover,
} from "@mui/material";
import { Stack } from "@mui/system";
import { getRequest } from "../../Helpers/httpRequests";
import { DataContext } from "../../Context/dataContext";
import { useState } from "react";
import APIKeys from "./Components/APIKeys";
import ApiKeyCustomerSelect from "./Components/ApiKeyCustomerSelect.jsx";
import axios from "axios";
import { useAppSelector } from "../../Redux/app/hooks";

const STYLES = {
  left: {
    color: "#757575",
    flex: 1,
  },
  right: {
    flex: 2,
  },
};

const SettingsRow = ({ field, text }) => {
  return (
    <Stack direction="row">
      <Typography sx={STYLES.left}>{field}</Typography>
      <Typography sx={STYLES.right}>{text}</Typography>
    </Stack>
  );
};

const Profile = () => {
  const user = useAppSelector((state) => state.user);

  // ----- Context -----
  const { accessToken } = useContext(DataContext);

  // ----- States -----
  const [apikey, setApikey] = useState("");
  const [apiLabel, setApiLabel] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [keys, setKeys] = useState({});
  const [customerID, setCustomerID] = useState("");
  const [passwordResetMessage, setPasswordResetMessage] = useState("");
  const customer = useAppSelector((state) => state.customer);

  // ----- Variable -----
  const textfieldRef = useRef(null);
  const open = Boolean(anchorEl);
  const id = open ? "textfield-popover" : undefined;

  // ----- Functions -----
  const genApiKey = async () => {
    const res = await getRequest(
      `/react/api/${customer.uuid}/gen-api-key/${apiLabel}/${customerID}`,
      accessToken,
    );
    if (res.status === 200) {
      setApikey(res.data.key);
      setApiLabel("");
    }
  };

  const handleClick = (e) => {
    // copy to clipboard
    e.target.focus();
    e.target.select();
    navigator.clipboard.writeText(e.target.value);

    // display popup
    setAnchorEl(e.currentTarget);
  };

  function sortObjectKeysAlphabetically(obj) {
    // Create a new object to store the sorted entries
    const sortedObj = {};

    // Get the keys of the object, sort them, and then iterate over them
    Object.keys(obj)
      .sort()
      .forEach((key) => {
        sortedObj[key] = obj[key]; // Assign the value to the sorted key in the new object
      });

    return sortedObj;
  }

  function resetPassword() {
    // call the reset password endpoint
    let options = {
      method: "POST",
      url: process.env.REACT_APP_PASSWORD_RESET_URL,
      headers: { "content-type": "application/json" },
      data: {
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
        email: user.email,
        connection: "Username-Password-Authentication",
      },
    };
    console.log(options);
    axios.request(options).then((res) => {
      if (res.status === 200) {
        // show a success message
        setPasswordResetMessage("Password reset email sent");
      } else {
        // show an error message
        setPasswordResetMessage("Error sending password reset email");
      }
    }).catch((err) => {
      console.log(err);
      setPasswordResetMessage("Error sending password reset email");
    });
  }

  // ----- On page load -----
  useEffect(() => {
    const getKeys = async () => {
      const res = await getRequest(`/react/api/${customer.uuid}/get-api-keys`, accessToken);
      if (res.status === 200) {
        // organize keys by the customer
        const keys = res.data.keys;
        const organizedKeys = {};
        for (const i in keys) {
          if (organizedKeys[keys[i].key_customer] === undefined) {
            organizedKeys[keys[i].key_customer] = [keys[i]];
          } else {
            organizedKeys[keys[i].key_customer].push(keys[i]);
          }
        }
        setKeys(sortObjectKeysAlphabetically(organizedKeys));
      }
    };
    getKeys();
  }, [apikey, accessToken, customer.uuid]);

  return (
    <Stack spacing={3}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Profile</Typography>
      </Breadcrumbs>

      <Paper sx={{ p: 3, maxWidth: 700 }}>
        <Stack spacing={2}>
          {/* Name */}
          <SettingsRow field="Name" text={`${user.first} ${user.last}`} />

          <Divider />

          {/* Email */}
          <SettingsRow field="Email" text={user.user.email} />

          <Divider />

          {/* Role */}
          <SettingsRow field="Role" text={user.role} />

          <Button variant="contained" onClick={resetPassword}>Change Password</Button>
          {passwordResetMessage !== "" && (
            <Typography>{passwordResetMessage}</Typography>
          )}
        </Stack>
      </Paper>

      <Paper sx={{ p: 3, maxWidth: 700 }}>
        <Stack spacing={2}>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
          >
            <Paper sx={{ p: 1 }}>Copied to clipboard</Paper>
          </Popover>

          <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
            <TextField
              label="API Label"
              value={apiLabel}
              sx={{ width: "75%" }}
              onChange={(e) => setApiLabel(e.target.value)}
            />
            <ApiKeyCustomerSelect
              customerID={customerID}
              setCustomerID={setCustomerID}
            />
          </Stack>
          <Button
            variant="contained"
            onClick={genApiKey}
            disabled={apiLabel === "" || customerID === ""}
          >
            Generate API KEY
          </Button>
          {apikey !== "" && (
            <TextField
              InputProps={{ readOnly: true }}
              value={apikey}
              onClick={handleClick}
              ref={textfieldRef}
            />
          )}
          <APIKeys keys={keys} setKeys={setKeys} />
        </Stack>
      </Paper>
    </Stack>
  );
};

export default Profile;
