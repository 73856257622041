import { Box, Button, Stack, Typography } from "@mui/material";
import moment from "moment";
import { default as React, useContext } from "react";
import { DataContext } from "../../../../Context/dataContext";
import "react-select-search/style.css";
import FormModal from "../../../../Components/Modals/FormModal";
import { saveSim } from "../functions";
import { useNavigate } from "react-router-dom";

const ConflictModal = ({
  conflict,
  setConflict,
  conflictBy,
  conflictTime,
  setConflictBy,
  setConflictTime,
  updateContributor,
  setReloadPage,
  data,
  setChangesMade,
  current_customer,
}) => {
  const { accessToken } = useContext(DataContext);
  const navigate = useNavigate();
  return (
    <FormModal open={conflict} setOpen={setConflict}>
      <Box component="form">
        <Typography
          variant="h6"
          style={{ textAlign: "center", marginBottom: 4 }}
        >
          This sim has been modified by {conflictBy} on{" "}
          {moment(conflictTime).format("MMM DD,YYYY hh:mm:ss a")} !!
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            onClick={() => {
              updateContributor();
              setReloadPage((current) => !current);
              setConflict(false);
            }}
          >
            Get Modified Version
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              // Change the simName first
              const newSimName = data.simName + "(Copy)";
              // Call the saveSim function with the updated data
              saveSim(
                {
                  ...data,
                  simName: newSimName,
                },
                accessToken,
                current_customer,
                navigate,
                setChangesMade,
                setConflict,
                setConflictBy,
                setConflictTime,
              );
            }}
          >
            Create a copy with my work
          </Button>
        </Stack>
      </Box>
    </FormModal>
  );
};

export default ConflictModal;
