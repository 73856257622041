import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  FaBan,
  FaBed,
  FaBookmark,
  FaBroom,
  FaCamera,
  FaClipboard,
  FaCloudDownloadAlt,
  FaCloudUploadAlt,
  FaCode,
  FaCog,
  FaCopy,
  FaDownload,
  FaExclamationTriangle,
  FaEyeSlash,
  FaFile,
  FaFileCode,
  FaInfo,
  FaLock,
  FaNetworkWired,
  FaPlus,
  FaRunning,
  FaSearch,
  FaServer,
  FaTerminal,
  FaTimesCircle,
  FaTools,
  FaEllipsisV,
} from "react-icons/fa";
import { COLORS } from "../../../Styles/colors";
import { capitalizeWord, duplicateAction } from "./functions";
import { useAppSelector } from "../../../Redux/app/hooks";

// Map module names to corresponding react-icons/fa icons
const moduleIcons = {
  clipboard: FaClipboard,
  crypt: FaLock,
  dnslookup: FaNetworkWired,
  downloader: FaDownload,
  embed: FaCode,
  exfilData: FaFile,
  file: FaFile,
  pingsweep: FaNetworkWired,
  portsweep: FaNetworkWired,
  privileges: FaTools,
  processes: FaCog,
  run: FaTerminal,
  screenshot: FaCamera,
  services: FaServer,
  sleep: FaBed,
  sysinfo: FaInfo,
  coffloader: FaCloudDownloadAlt,
  datagen: FaCloudUploadAlt,
  "execute-assembly": FaRunning,
  exfilFTP: FaCloudUploadAlt,
  exfilHTTP: FaCloudUploadAlt,
  search: FaSearch,
  padded_file: FaFileCode,
};

const DraggableList = ({
  data,
  setData,
  setDeleteUUID,
  setDeleteModal,
  EditAction,
  setEdittedAction,
  setInitialEdittedAction,
  setEditPage,
  setEdittedActionIndex,
  getItemStyle,
  onDragEnd,
  setAddActionModal,
  setEdittedActionModal,
  setAddActionIndex,
  testing,
  testingResults,
  campaignRuntimeVariables,
}) => {

  const darkmode = useAppSelector((state) => state.darkmode);
   // Define colors based on dark mode
   const backgroundColor = darkmode ? "#333" : "lightgray";
   const textColor = darkmode ? "#fff" : "#000";
   const chipBackgroundColor = darkmode ? "#555" : "darkgrey";
   const iconColor = darkmode ? COLORS.primary.main : COLORS.secondary.main;
   const notebackground =  darkmode ? "#56545c" : "#f0f0f0";

  const [showFullContent, setShowFullContent] = useState(
    Array(data.actions.length).fill(false)
  );
  const [anchorEl, setAnchorEl] = useState(
    Array(data.actions.length).fill(null)
  );

  const open = (index) => Boolean(anchorEl[index]);

  const toggleContent = (index) => {
    const updatedShowFullContent = [...showFullContent];
    updatedShowFullContent[index] = !updatedShowFullContent[index];
    setShowFullContent(updatedShowFullContent);
  };

  const handleMenuClick = (event, index) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = event.currentTarget;
    setAnchorEl(newAnchorEl);
  };

  const handleMenuClose = (index) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = null;
    setAnchorEl(newAnchorEl);
  };

  const getHighlightedArgsString = (args_string) => {
    const parts = args_string.split(/(#\{\w+\})/g);

    const crvs = parts.map((part) =>
      campaignRuntimeVariables.find((crv) => crv.name === part.slice(2, -1))
    );

    return (
      <Stack
        direction="row"
        sx={{ flexWrap: "nowrap", alignItems: "center", whiteSpace: "nowrap" }}
      >
        {parts.map((part, idx) =>
          part.startsWith("#{") && part.endsWith("}") && crvs[idx] ? (
            <Tooltip
              key={idx}
              title={`${crvs[idx].testingValue || crvs[idx].default}`}
              sx={{ margin: "2px" }}
            >
              <span
                style={{
                  color: "blue",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                {part}
              </span>
            </Tooltip>
          ) : (
            <span
              key={idx}
              style={{ whiteSpace: "nowrap", marginRight: "4px" }}
            >
              {part}
            </span>
          )
        )}
      </Stack>
    );
  };

  return (
    <>
      <DragDropContext onDragEnd={(result) => onDragEnd(result, data, setData)}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {data.actions.map((action, index) => {
                const args_string = action.arguments.join(" ");

                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      maxWidth: "100%",
                    }}
                  >
                    <Box
                      className="right-box"
                      sx={{ width: "50%", flex: "none" }}
                    >
                      <Draggable draggableId={index.toString()} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              margin: 0,
                              padding: 0,
                              ...getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              ),
                            }}
                          >
                            <div>
                              <Chip
                                sx={{
                                  height: "auto",
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                    color: textColor,
                                  },
                                  marginBottom: 1,
                                  width: "100%",
                                  minHeight: "60px", // Adjust this value as needed
                                  justifyContent: "flex-start",
                                  textAlign: "left",
                                  backgroundColor,
                                }}
                                label={
                                  <Box display={"flex"}>
                                    {moduleIcons[action.module] && (
                                      <Box sx={{ padding: 0.5 }}>
                                        <IconButton
                                          size="small"
                                          style={{
                                            marginRight: "6px",
                                            boxShadow:
                                              "0px 4px 4px rgba(0, 0, 0, 0.1)", // Add box shadow
                                            border: "1px solid #c0c0c0", // Add border for the box outline
                                            borderRadius: "1px", // Add border radius for rounded corners
                                            color: iconColor,
                                          }}
                                        >
                                          {React.createElement(
                                            moduleIcons[action.module],
                                            { size: 35 }
                                          )}
                                        </IconButton>
                                      </Box>
                                    )}
                                    <Box>
                                      <Tooltip title="Options">
                                        <IconButton
                                          sx={{
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                            padding: 2,
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleMenuClick(e, index);
                                          }}
                                        >
                                          <FaEllipsisV size={20} />
                                        </IconButton>
                                      </Tooltip>
                                      <Menu
                                        anchorEl={anchorEl[index]}
                                        open={open(index)}
                                        onClose={(e) => {
                                          e.stopPropagation();
                                          handleMenuClose(index);
                                        }}
                                      >
                                        <MenuItem
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            duplicateAction(
                                              index,
                                              data,
                                              setData
                                            );
                                            handleMenuClose(index);
                                          }}
                                        >
                                          <FaCopy
                                            size={20}
                                            style={{ marginRight: "10px" }}
                                          />
                                          Duplicate action {index + 1}
                                        </MenuItem>
                                        <MenuItem
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setDeleteUUID(index);
                                            setDeleteModal(true);
                                            handleMenuClose(index);
                                          }}
                                        >
                                          <FaTimesCircle
                                            size={20}
                                            style={{ marginRight: "10px" }}
                                          />
                                          Delete action {index + 1}
                                        </MenuItem>
                                      </Menu>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-evenly",
                                        maxWidth: "80%",
                                        marginBottom: 1,
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          width: "100%",
                                          wordBreak: "break-word",
                                        }}
                                      >
                                        <Typography component="span">
                                          {`${index + 1}. ${capitalizeWord(
                                            action.module
                                          )}` +
                                            (action.module.toLowerCase() !==
                                              "padded_file" &&
                                            action.arguments.length > 1
                                              ? " "
                                              : "")}
                                          {getHighlightedArgsString(
                                            args_string
                                          )}{" "}
                                          {/* Use the helper function */}
                                          {action.module.toLowerCase() ===
                                            "padded_file" && (
                                            <div>{action.arguments[1]}</div>
                                          )}
                                          {action.module.toLowerCase() ===
                                            "padded_file" &&
                                            action.arguments[3] !== "pad1" && (
                                              <div>
                                                Payload Command:{" "}
                                                {action.arguments[3]}
                                              </div>
                                            )}
                                        </Typography>

                                        {action.expected_outcome && (
                                          <Tooltip
                                            title={`Expected Outcome: ${action.expected_outcome}`}
                                          >
                                            <IconButton
                                              sx={{
                                                position: "absolute",
                                                top: 0,
                                                right: 35,
                                                padding: 2,
                                              }}
                                            >
                                              {action.expected_outcome ===
                                                "blocked" && (
                                                <FaBan
                                                  size={17}
                                                  color={COLORS.success.main}
                                                />
                                              )}
                                              {action.expected_outcome ===
                                                "alerted" && (
                                                <FaExclamationTriangle
                                                  size={17}
                                                  color={COLORS.primary.main}
                                                />
                                              )}
                                              {action.expected_outcome ===
                                                "logged" && (
                                                <FaFileCode
                                                  size={17}
                                                  color={"#ECC010"}
                                                />
                                              )}
                                              {action.expected_outcome ===
                                                "nothing" && (
                                                <FaEyeSlash
                                                  size={17}
                                                  color={COLORS.error.main}
                                                />
                                              )}
                                            </IconButton>
                                          </Tooltip>
                                        )}

                                        {action.clean_up && (
                                          <Tooltip title="This is a clean up step">
                                            <IconButton
                                              sx={{
                                                position: "absolute",
                                                top: 0,
                                                right: 70,
                                                padding: 2,
                                              }}
                                            >
                                              <FaBroom
                                                size={20}
                                                color={COLORS.secondary.main}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        )}
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexWrap: "wrap",
                                          alignItems: "center",
                                        }}
                                      >
                                        {action.ttps.map((ttp, ttpIndex) => (
                                          <Chip
                                            key={ttpIndex}
                                            label={ttp}
                                            size="small"
                                            sx={{
                                              backgroundColor: chipBackgroundColor,
                                              color: textColor,
                                              marginRight: 1,
                                              marginBotton: 1,
                                            }}
                                          />
                                        ))}
                                      </Box>
                                      {action.note && (
                                        <Box
                                          sx={{
                                            marginTop: 1,
                                            borderRadius: "8px",
                                            backgroundColor: notebackground,
                                            display: "flex",
                                            padding: 0.5,
                                            alignItems: "center",
                                            maxWidth: "100%",
                                            wordBreak: "break-word",
                                          }}
                                        >
                                          <IconButton sx={{ fontSize: 10 }}>
                                            <FaBookmark />
                                          </IconButton>
                                          <Typography
                                            component="span"
                                            sx={{
                                              fontStyle: "italic",
                                              fontSize: 15,
                                            }}
                                          >
                                            {action.note}
                                          </Typography>
                                        </Box>
                                      )}
                                    </Box>
                                  </Box>
                                }
                                variant="outlined"
                                onClick={() => {
                                  setAddActionModal(false);
                                  setEdittedActionModal(true);
                                  EditAction(
                                    index,
                                    data,
                                    setEdittedAction,
                                    setInitialEdittedAction,
                                    setEditPage,
                                    setEdittedActionIndex
                                  );
                                }}
                                disabled={testing}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        marginBottom={1}
                      >
                        <Tooltip title={`Add Action at step ${index + 2}`}>
                          <IconButton
                            disabled={testing}
                            sx={{ fontSize: 15, position: "relative" }}
                            onClick={() => {
                              setEdittedActionModal(false);
                              setAddActionModal(true);
                              setAddActionIndex(index + 1);
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                left: "50%",
                                bottom: "20px", // Adjust this value to control the arrow visibility
                                transform: "translate(-50%)",
                                height: "20",
                                color: "rgba(0, 0, 0, 0.4)", // Adjust the alpha value to control opacity
                              }}
                            >
                              &#124;
                            </div>
                            <FaPlus
                              color={
                                testing
                                  ? COLORS.secondary.main
                                  : COLORS.info.main
                              }
                            />
                            <div
                              style={{
                                position: "absolute",
                                left: "50%",
                                bottom: "-10px", // Adjust this value to control the arrow visibility
                                transform: "translate(-50%)",
                                height: "20",
                                color: "rgba(0, 0, 0, 0.4)", // Adjust the alpha value to control opacity
                              }}
                            >
                              &#8595;
                            </div>
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                    <Box
                      className="left-box"
                      sx={{
                        paddingLeft: 3,
                        width: "50%",
                        flex: "none",
                        marginTop: 0.5,
                      }}
                    >
                      {testingResults[index] ? (
                        <Accordion key={index} sx={{ marginBottom: 7.5 }}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>
                              {index + 1}.{" "}
                              {testingResults[index].output ? (
                                <>
                                  {atob(
                                    testingResults[index]?.output.slice(0, 20)
                                  )}
                                  ... | PID: {testingResults[index].PID} |
                                  Timestamp:{" "}
                                  {moment
                                    .unix(testingResults[index].DTStamp)
                                    .format("HH:mm:ss a")}
                                </>
                              ) : (
                                <>No output available for this action.</>
                              )}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography sx={{ whiteSpace: "pre-wrap" }}>
                              {testingResults[index]?.output ? (
                                <>
                                  {showFullContent[index]
                                    ? atob(testingResults[index]?.output)
                                    : atob(testingResults[index]?.output)
                                        .split("\n")
                                        .slice(0, 7)
                                        .join("\n")}
                                </>
                              ) : (
                                <></>
                              )}
                            </Typography>
                            <Button onClick={() => toggleContent(index)}>
                              {showFullContent[index]
                                ? "Show Less"
                                : "Show More"}
                            </Button>
                          </AccordionDetails>
                        </Accordion>
                      ) : (
                        testing && (
                          <Box key={index} sx={{ marginBottom: 1.5 }}>
                            {index === 0 ||
                            (testingResults[index - 1] &&
                              !testingResults[index]) ? (
                              <CircularProgress />
                            ) : null}
                          </Box>
                        )
                      )}
                    </Box>
                  </Box>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default DraggableList;
