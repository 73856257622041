import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Switch as MUISwitch, FormControlLabel } from "@mui/material";
import { FormContext } from "../context/FormContext";
import { capitalize } from "../../Helpers/functions";

const Switch = (props) => {
  const { name, required, label, disabled, defaultValue } = props;
  const { formData, setFormData } = useContext(FormContext);

  const handleChange = (e) => {
    setFormData((data) => ({
      ...data,
      [name]: e.target.checked,
    }));
  };

  useEffect(() => {
    if (defaultValue && formData[name] === undefined) {
      setFormData((data) => ({
        ...data,
        [name]: defaultValue,
      }));
    }
  }, [name, setFormData, defaultValue, formData]);

  return (
    <FormControlLabel
      required={required}
      disabled={disabled}
      control={
        <MUISwitch
          checked={
            formData[name] || (formData[name] === undefined && defaultValue)
          }
          onChange={handleChange}
        />
      }
      label={label || capitalize(name)}
    />
  );
};

Switch.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.bool,
};

Switch.defaultProps = {
  required: false,
  disabled: false,
  defaultValue: false,
};

export default Switch;
