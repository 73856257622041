import React, { useContext } from "react";
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import { FormContext } from "../context/FormContext";

const SubmitButton = (props) => {
  const { text, variant } = props;
  const { loading } = useContext(FormContext);
  return (
    <Button disabled={loading} variant={variant} type="submit">
      {text}
    </Button>
  );
};

SubmitButton.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.string,
};

SubmitButton.defaultProps = {
  text: "Submit",
  variant: "contained",
};

export default SubmitButton;
