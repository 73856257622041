import {
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  ListItemIcon,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  FaCheckCircle,
  FaClipboard,
  FaEdit,
  FaEllipsisV,
  FaFileDownload,
  FaFileExcel,
  FaFileUpload,
  FaFolderPlus,
  FaHourglass,
  FaPlus,
  FaQuestionCircle,
  FaRedo,
  FaSpinner,
  FaTimesCircle,
  FaUnlink,
} from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DataGridWithStyles from "../../../../Components/DataGrid/DataGridWithStyles";
import CampaignForm from "../../../../Components/Forms/Simulations/CampaignForm";
import ProjectForm from "../../../../Components/Forms/Simulations/ProjectForm/ProjectForm";
import FormModal from "../../../../Components/Modals/FormModal";
import ProjectAddExistingCampaign from "../../../../Components/Reporting/ProjectAddExistingCampaign/ProjectAddExistingCampaign";
import { DataContext } from "../../../../Context/dataContext";
import {
  fileRequest,
  getRequest,
  postRequest,
} from "../../../../Helpers/httpRequests";
import { useHttpRequest } from "../../../../Hooks";
import { useAppSelector } from "../../../../Redux/app/hooks";
import { COLORS } from "../../../../Styles/colors";
import { DuplicateProject } from "./DuplicateProject";
import ReRunCampaignModal from "./ReRunCampaignModal";
import ScoringExcelModal from "./ScoringExcelModal";
import UploadLogs from "../../../../Components/Reporting/UploadLogs/UploadLogs";

const Project_Details = () => {
  const { projectUUID } = useParams();
  const role = useAppSelector((state) => state.user.role);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // ----- STATES && CONTEXT -----
  // const [projectName, setProjectName] = useState("");
  const [project, setProject] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dataTableRows, setDataTableRows] = useState([]);
  const [dataTableColumns, setDataTableColumns] = useState([]);
  const [reloadProj, setReloadProj] = useState(false);

  // TODO - Refactor this to use just one modal state
  const [openNewCampaignModal, setOpenNewCampaignModal] = useState(false);
  const [openDuplicateProjectModal, setOpenDuplicateProjectModal] =
    useState(false);
  const [openAddCampaignModal, setOpenAddCampaignModal] = useState(false);
  const [openEditProjectModal, setOpenEditProjectModal] = useState(false);
  const [openRerunModal, setOpenRerunModal] = useState(false);
  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [uploadLogsOpen, setUploadLogsOpen] = useState(false);

  const [rerunCampaignId, setRerunCampaignId] = useState("");
  const [addCampaignStep, setAddCampaignStep] = useState(0);
  const [hasCampaigns, setHasCampaigns] = useState(false);
  const [simulations, setSimulations] = useState([]);
  const [selectedSimulation, setSelectedSimulation] = useState(null);
  const [hasCompletedSim, setHasCompletedSim] = useState(false);

  const [unlinkModal, setUnlinkModal] = useState(false);
  const [unlinkUUID, setUnlinkUUID] = useState("");
  const [unlinkName, setUnlinkName] = useState("");
  const [statusMsg, setStatusMsg] = useState("");
  const [loadingSimulations, setLoadingSimulations] = useState(true);

  const [editCampaign, setEditCampaign] = useState(-1);
  const [editCampaignName, setEditCampaignName] = useState("");
  const [editCampaignCLV, setEditCampaignCLV] = useState([]);
  const [editCampaignSim, setEditCampaignSim] = useState(null);

  // Contexts
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector((state) => state.customer);

  // ----- VARIABLES -----

  const modalStyle = {
    position: "absolute",
    bgcolor: "background.paper",
    top: "50%",
    left: "50%",
    // width: "calc(100vw - 33%)",
    width: 1150,
    maxWidth: "calc(100vw - 2rem)",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  const {
    loading: loadingEdit,
    status: statusEdit,
    fetch: callEdit,
  } = useHttpRequest({
    method: "PATCH",
    path: `/api/v2/campaigns/${editCampaign}`,
    data: { name: editCampaignName, campaign_level_vars: editCampaignCLV },
    defer: true,
  });
  // ----- FUNCTIONS -----

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const genScoringExcel = async (uuid, name) => {
    const LoadingToast = toast.loading("Downloading Excel File");
    // Remove spaces from the name and replace with underscores
    const filename = `${name.replace(/\s+/g, "_")}.xlsx`;
    try {
      const res = await fileRequest(
        `/react/api/${current_customer.uuid}/projects/scoring_excel/${uuid}`,
        accessToken,
        filename
      );

      if (res.status === 200) {
        toast.dismiss(LoadingToast);
        toast.success("Scoring Excel Downloaded Successfully");
      } else {
        toast.dismiss(LoadingToast);
        toast.error("Error downloading scoring document");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.dismiss(LoadingToast);
      toast.error("An error occurred while downloading the document");
    }
  };

  const removeCampaign = async () => {
    toast.info("Attempting to Unlink Campaign");
    const res = await postRequest(
      `/react/api/${current_customer.uuid}/projects/removecampaign/${projectUUID}/${unlinkUUID}`,
      accessToken
    );
    if (res.status === 200) {
      toast.success("Campaign Unlinked Successfully");
      setUnlinkModal(false);
      setReloadProj((current) => !current);
    } else {
      toast.error("Failed to Unlink Campaign");
    }
  };

  useEffect(() => {
    if (!loadingEdit) {
      if (statusEdit === 200) {
        toast.success("Campaign name updated successfully");
        setEditCampaign(-1);
        setEditCampaignName("");
        setReloadProj((current) => !current);
      } else if (!statusEdit) {
        // do nothing
      } else {
        toast.error("Failed to update campaign name");
      }
    }
  }, [statusEdit, loadingEdit]);

  // Duplicate Project functionality
  const populateDataGrid = useCallback(
    (data) => {
      const columns = [
        {
          field: "campaign",
          headerName: "Campaign",
          minWidth: 250,
          flex: 1,
          renderCell: (params) => {
            const campLink = `/${current_customer.uuid}/reporting/campaign/${params.row.uuid}`;
            return (
              <Stack
                direction={"row"}
                spacing={1}
                sx={{ alignItems: "center" }}
              >
                <Link
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                  }}
                  to={campLink}
                >
                  {params.row.campaign}
                </Link>
                <IconButton
                  onClick={() => {
                    setEditCampaign(params.row.id);
                    setEditCampaignSim(params.row.simulation);
                    setEditCampaignName(params.row.campaign);
                    if (params.row.campaign_level_vars) {
                      setEditCampaignCLV(params.row.campaign_level_vars);
                    }
                  }}
                >
                  <FaEdit />
                </IconButton>
              </Stack>
            );
          },
        },
        {
          field: "simulation",
          headerName: "Simulation Info",
          minWidth: 100,
          flex: 1,
          renderCell: (params) => {
            const simLink = `/${current_customer.uuid}/simulations/${params.row.simulation.uuid}`;
            const endLink = `/${current_customer.uuid}/simulations/endpoints/detail/${params.row.endpoint.uuid}`;
            return (
              <Stack sx={{ py: 1 }}>
                <div>
                  <Tooltip title="Simulation" placement="left" arrow>
                    <Link style={{ fontWeight: "bold" }} to={simLink}>
                      {params.row.simulation
                        ? params.row.simulation.name
                        : "archived simulation"}
                    </Link>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title="Endpoint" placement="left" arrow>
                    <Link to={endLink}>{params.row.endpoint.name}</Link>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title="Execution method" placement="left" arrow>
                    <Typography sx={{ fontSize: 12 }}>
                      {params.row.method.exec}
                    </Typography>
                  </Tooltip>
                </div>
              </Stack>
            );
          },
        },
        {
          field: "user",
          headerName: "User",
          flex: 1,
        },
        {
          field: "status",
          headerName: "Status",
          minWidth: 150,
          flex: 1,
          renderCell: (params) => {
            let icon,
              message,
              color,
              compClass = "";

            switch (params.row.status) {
              case "completed":
                icon = FaCheckCircle;
                color = COLORS.success.main;
                message = "Completed";
                break;

              case "ready":
                icon = FaHourglass;
                color = COLORS.primary.main;
                message = "Ready for execution";
                break;

              case "retrieved":
                icon = FaHourglass;
                color = COLORS.info.main;
                message = "Retrieved for execution";
                break;

              case "error":
                icon = FaTimesCircle;
                color = COLORS.error.main;
                message = "Failed on execution";
                break;

              case "running":
                icon = FaSpinner;
                color = "orange";
                message = "Running";
                compClass = "spinner";
                break;

              case "partial":
                icon = FaCheckCircle;
                color = COLORS.yellow;
                message = "Partial completion";
                break;
              default:
                icon = FaQuestionCircle;
                color = COLORS.secondary.main;
                message = "Unknown status";
                break;
            }
            return (
              <Chip
                icon={React.createElement(icon, {
                  size: 20,
                  color: "white",
                  className: compClass,
                })}
                label={message}
                variant="outlined"
                sx={{
                  color: "white",
                  backgroundColor: color,
                  borderRadius: "6px",
                  width: "100%",
                  "& .MuiChip-label": {
                    marginTop: "3px",
                    width: "100%",
                    textAlign: "center",
                  },
                  display: "flex",
                  justifyContent: "space-between",
                }}
              />
            );
          },
        },
        {
          field: "not_scored",
          headerName: "Not Scored",
          maxWidth: 120,
          flex: 1,
          renderCell: (params) => {
            const fraction = `${params.row.not_scored} / ${params.row.steps}`;
            if (params.row.steps !== 0) {
              if (params.row.not_scored === 0) {
                return (
                  <Stack direction="row" alignItems="center">
                    <Typography fontSize={13}>Scoring Complete</Typography>
                  </Stack>
                );
              }
              return (
                <Stack direction="row" alignItems="center">
                  <Typography fontSize={13}>{fraction}</Typography>
                </Stack>
              );
            }
            return "";
          },
        },
        {
          field: "actions",
          headerName: "Actions",
          minWidth: 100,
          flex: 1,
          renderCell: (params) => {
            const runCampaign = async (e) => {
              e.stopPropagation();
              setStatusMsg("");
              toast.info("Attempting to run campaign");
              const res = await postRequest(
                `/react/api/${current_customer.uuid}/projects/startcampaign/${projectUUID}/${params.id}`,
                accessToken
              );
              if (res.status === 200) {
                toast.success("Campaign started successfully");
                setReloadProj((current) => !current);
              } else if (res.status === 400) {
                toast.error(
                  "Campaign Failed to run. Please check if Agent assigned is currently licensed"
                );
              } else {
                setStatusMsg(res.data.message || "");
              }
            };

            // null safety stuff for sanity
            const status = params.value.status ? params.value.status : "";
            const active = params.value.endpoint
              ? params.value.endpoint.active
              : false;
            let runBtn = null;

            // A simplified version of the button display from
            if (status === "ready" && active) {
              runBtn = (
                <Button variant="contained" onClick={runCampaign}>
                  Run
                </Button>
              );
            } else if (status === "ready" && !active) {
              runBtn = (
                <Button variant="contained" disabled>
                  Run
                </Button>
              );
            } else if (
              (status === "completed" ||
                status === "error" ||
                status === "partial") &&
              active
            ) {
              runBtn = (
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenRerunModal(true);
                    setRerunCampaignId(params.id);
                  }}
                >
                  Re-run
                </Button>
              );
            } else {
              runBtn = (
                <Button variant="contained" disabled>
                  Re-run
                </Button>
              );
            }

            return (
              <Stack direction={"row"}>
                {runBtn}
                <Tooltip title="Unlink campaign" placement="left" arrow>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setUnlinkName(params.row.campaign);
                      setUnlinkUUID(params.row.uuid);
                      setUnlinkModal(true);
                    }}
                    disabled={role === "View_Only"}
                    style={{ opacity: role === "View_Only" ? 0.5 : 1 }}
                  >
                    <FaUnlink color={COLORS.error.main} />
                  </IconButton>
                </Tooltip>
                {params.row.simulation.campaign_level_vars && (
                  <Tooltip
                    title="Edit Campaign Level Variables"
                    placement="left"
                    arrow
                  >
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditCampaignSim(params.row.simulation);
                        setEditCampaignName(params.row.campaign);
                        setEditCampaignCLV(params.row.campaign_level_vars);
                        setEditCampaign(params.row.id);
                      }}
                    >
                      <FaEdit />
                    </IconButton>
                  </Tooltip>
                )}
                {status === "running" && (
                  <Tooltip title="Kill running campaign" placement="left" arrow>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        toast.info("Attempting to stop campaign");
                        getRequest(
                          `/api/endpoints/force-complete/${params.row.uuid}/${params.row.endpoint.uuid}`,
                          accessToken
                        ).then((res) => {
                          if (res.status === 200) {
                            toast.success("Campaign stopped successfully");
                            setReloadProj((current) => !current);
                          }
                        });
                      }}
                    >
                      <FaTimesCircle color={COLORS.error.main} />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            );
          },
        },
      ];
      const rows = data.map((campaign) => {
        return {
          id: campaign.id,
          uuid: campaign.uuid,
          campaign: campaign.name,
          simulation: campaign.simulation,
          endpoint: campaign.endpoint,
          user: campaign.user ? campaign.user : "None",
          method: {
            exec: campaign.execution,
            platform: campaign.simulation.platform,
          },
          status: campaign.status,
          results: campaign.status,
          not_scored: campaign.not_scored,
          steps: campaign.steps,

          actions: {
            endpoint: campaign.endpoint,
            status: campaign.status,
          },
          campaign_level_vars: campaign.campaign_level_vars,
        };
      });
      setDataTableColumns(columns);
      setDataTableRows(rows);
    },
    [accessToken, projectUUID, role, current_customer]
  );

  const handleProjEditSubmit = async (data) => {
    toast.info("Attempting to Update project");
    const result = await postRequest(
      `/react/api/${current_customer.uuid}/projects/update`,
      accessToken,
      data
    );
    if (result.status === 200) {
      toast.success("Project updated successfully");
      setProject(result.data.project);
      setReloadProj(!reloadProj);
      setOpenEditProjectModal(false);
    } else {
      toast.error("Failed to Update Project");
    }
  };

  const getProjDetails = useCallback(async () => {
    const res = await getRequest(
      `/react/api/${current_customer.uuid}/projects/${projectUUID}`,
      accessToken
    );
    if (res.status === 200) {
      setProject(res.data.project);
      const tmpStart = moment(res.data.project.date_start)
        .utc()
        .format("MM/DD/YY");
      const tmpEnd = moment(res.data.project.date_end).utc().format("MM/DD/YY");
      setStartDate(tmpStart);
      setEndDate(tmpEnd);
      res.data.project.campaignv2s.map((campaign) => {
        if (campaign.status === "completed" || campaign.status === "partial") {
          setHasCompletedSim(true);
          return true;
        }
        return false;
      });
      populateDataGrid(res.data.project.campaignv2s);
      if (res.data.project.campaignv2s.length > 0) {
        setHasCampaigns(true);
      }
    } else {
      navigate(`/${current_customer.uuid}/simulations/campaigns/projects/list`);
    }
  }, [accessToken, navigate, populateDataGrid, projectUUID, current_customer]);

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getSimulations = async () => {
      const result = await getRequest(
        `/react/api/${current_customer.uuid}/simulations`,
        accessToken
      );
      if (result.status === 200) {
        setSimulations(result.data.simulations);
        setLoadingSimulations(false);
      }
    };
    getSimulations();
    getProjDetails();
  }, [
    current_customer,
    projectUUID,
    accessToken,
    navigate,
    reloadProj,
    getProjDetails,
  ]);

  return (
    <>
      <Stack spacing={3}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="text.primary">Simulations</Typography>
          <Link
            to={`/${current_customer.uuid}/simulations/campaigns/projects/list`}
          >
            Projects
          </Link>
          <Typography color="text.primary">{project.name}</Typography>
        </Breadcrumbs>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h4" component={"span"}>
              {project.name}
            </Typography>
            <Tooltip title="Edit Project">
              <IconButton
                onClick={() => setOpenEditProjectModal(true)}
                size="small"
                sx={{ marginLeft: 1, marginTop: -1.5 }}
              >
                <FaEdit size={25} />
              </IconButton>
            </Tooltip>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Tooltip title="Options">
              <IconButton onClick={handleMenuClick}>
                <FaEllipsisV size={30} />
              </IconButton>
            </Tooltip>
            <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
              <MenuItem
                onClick={() => {
                  setAddCampaignStep(0);
                  setOpenNewCampaignModal(true);
                  handleMenuClose();
                }}
              >
                <ListItemIcon>
                  <FaPlus />
                </ListItemIcon>
                Add Campaign
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOpenAddCampaignModal(true);
                  handleMenuClose();
                }}
              >
                <ListItemIcon>
                  <FaFolderPlus />
                </ListItemIcon>
                Add Existing Campaign
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate(
                    `/${current_customer.uuid}/simulations/campaigns/projects/${projectUUID}/report`
                  );
                  handleMenuClose();
                }}
                disabled={!hasCompletedSim}
              >
                <ListItemIcon>
                  <FaClipboard />
                </ListItemIcon>
                Project Report
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOpenDuplicateProjectModal(true);
                  handleMenuClose();
                }}
              >
                <ListItemIcon>
                  <FaRedo />
                </ListItemIcon>
                Duplicate Project
              </MenuItem>
              <MenuItem
                onClick={() => {
                  genScoringExcel(project.uuid, project.name);
                  handleMenuClose();
                }}
                disabled={!hasCampaigns}
              >
                <ListItemIcon>
                  <FaFileDownload />
                </ListItemIcon>
                Download Scoring Excel
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOpenExcelModal(true);
                  handleMenuClose();
                }}
                disabled={!hasCampaigns}
              >
                <ListItemIcon>
                  <FaFileExcel />
                </ListItemIcon>
                Upload Scoring Excel
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  setUploadLogsOpen(true);
                }}
              >
                <ListItemIcon>
                  <FaFileUpload />
                </ListItemIcon>
                Upload Security Tool Logs
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <Typography variant="h5">
          {startDate} - {endDate}
        </Typography>
      </Stack>
      <DataGridWithStyles
        name="project-details"
        autoHeight
        paper
        rows={dataTableRows}
        columns={dataTableColumns}
        getRowHeight={() => "auto"}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
      />

      <Typography
        sx={{ display: statusMsg.length > 0 ? "block" : "none" }}
        color="error"
        variant="p"
      >
        Bad Request: {statusMsg}
      </Typography>

      {/* Add New Campaign */}
      <FormModal open={openNewCampaignModal} setOpen={setOpenNewCampaignModal}>
        {loadingSimulations ? (
          <CircularProgress />
        ) : (
          <>
            {addCampaignStep === 0 && (
              <Stack sx={{ justifyContent: "right" }}>
                <FormControl>
                  <InputLabel required>Simulation</InputLabel>
                  <Select
                    value={selectedSimulation ? selectedSimulation : ""}
                    onChange={(e) => setSelectedSimulation(e.target.value)}
                    label="Simulation"
                  >
                    {simulations
                      ? simulations.map((simulation) => {
                          return (
                            <MenuItem key={simulation.uuid} value={simulation}>
                              {simulation.name}
                            </MenuItem>
                          );
                        })
                      : null}
                  </Select>
                </FormControl>
                <Button
                  disabled={selectedSimulation === null}
                  onClick={() => setAddCampaignStep(1)}
                  variant="contained"
                >
                  Next
                </Button>
              </Stack>
            )}
            {addCampaignStep === 1 && (
              <CampaignForm
                simulation={selectedSimulation}
                setModalOpen={setOpenNewCampaignModal}
                onFinish={() => setReloadProj((current) => !current)}
                project={project}
                customer={current_customer}
              />
            )}
          </>
        )}
      </FormModal>

      {/* Duplicate Campaign */}
      <Modal
        open={openDuplicateProjectModal}
        onClose={() => setOpenDuplicateProjectModal(false)}
      >
        <DuplicateProject
          oldProject={project}
          onSuccess={() => {
            setOpenDuplicateProjectModal(false);
          }}
        />
      </Modal>

      {/* Add Existing Campaign */}
      <Modal
        open={openAddCampaignModal}
        onClose={() => {
          setOpenAddCampaignModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper sx={modalStyle} spacing={2}>
          <Stack spacing={2}>
            {/* Technically these are the only filters we're supporting. 
                It gets the campaigns within the scope of the project with a extra week of leeway on either side */}
            <ProjectAddExistingCampaign
              filters={{
                created__gte: moment(startDate).add(-1, "week").toISOString(),
                created__lte: moment(endDate).add(1, "week").toISOString(),
              }}
              projectUUID={projectUUID}
              setOpen={(x) => {
                setOpenAddCampaignModal(false);
                getProjDetails();
              }}
            />
          </Stack>
        </Paper>
      </Modal>

      {/* Edit Project */}
      <FormModal open={openEditProjectModal} setOpen={setOpenEditProjectModal}>
        <ProjectForm
          handleSubmit={handleProjEditSubmit}
          project={project}
          customer={current_customer}
        />
      </FormModal>
      <Modal open={openRerunModal} onClose={() => setOpenRerunModal(false)}>
        <ReRunCampaignModal
          campaign_id={rerunCampaignId}
          project_uuid={projectUUID}
          setOpenModal={setOpenRerunModal}
          reloadProj={() => setReloadProj(!reloadProj)}
        />
      </Modal>

      {/* Unlink Modal */}
      <FormModal open={unlinkModal} setOpen={setUnlinkModal}>
        <Box component="form">
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: 4 }}
          >
            Are you sure you want to unlink Campaign {unlinkName} ?
          </Typography>
          <Stack direction="column" spacing={2}>
            <Button onClick={() => removeCampaign()} variant="contained">
              Yes
            </Button>
          </Stack>
        </Box>
      </FormModal>
      {/* Scoring Excel Modal */}
      <Modal
        open={openExcelModal}
        onClose={() => setOpenExcelModal(false)}
        sx={{
          width: "30%",
          height: "30%",
          position: "absolute",
          top: "10%",
          left: "40%",
        }}
      >
        <ScoringExcelModal
          uuid={projectUUID}
          setOpenExcelModal={setOpenExcelModal}
        />
      </Modal>

      <Modal
        open={editCampaign !== -1}
        onClose={() => {
          setEditCampaign(-1);
          setEditCampaignName("");
          setEditCampaignCLV([]);
          setEditCampaignSim(null);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper sx={modalStyle}>
          <Stack spacing={2}>
            <Typography variant="h5">Edit Campaign Name</Typography>
            {/* Campaign name  */}
            <TextField
              label="Campaign Name"
              value={editCampaignName}
              onChange={(e) => {
                setEditCampaignName(e.target.value);
              }}
            />

            {/* Campaign Level Variables */}
            {editCampaignCLV?.length > 0 && (
              <Stack direction="column" spacing={2}>
                <Typography variant="h6">Campaign Level Variables</Typography>
                {editCampaignCLV?.map((variable, index) => (
                  <Stack
                    direction="row"
                    key={index}
                    spacing={2}
                    alignItems="center"
                  >
                    <Tooltip
                      title={
                        editCampaignSim.campaign_level_var_descriptions[index]
                      }
                      placement="top"
                      arrow
                    >
                      <TextField
                        label={editCampaignSim.campaign_level_vars[index]}
                        value={variable}
                        onChange={(e) =>
                          setEditCampaignCLV((prev) => {
                            const newVars = [...prev];
                            newVars[index] = e.target.value;
                            return newVars;
                          })
                        }
                      />
                    </Tooltip>
                  </Stack>
                ))}
              </Stack>
            )}
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                onClick={() => {
                  setEditCampaign(-1);
                  setEditCampaignName("");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  callEdit();
                }}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </Paper>
      </Modal>

      {dataTableRows.length > 0 && (
        <UploadLogs
          open={uploadLogsOpen}
          setOpen={(b) => {
            setUploadLogsOpen(b);
            getProjDetails();
          }}
          campaignUUIDs={dataTableRows.map((row) => row.uuid)}
        />
      )}
    </>
  );
};

export default Project_Details;
