import React from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../Redux/app/hooks";

interface LinkWithCustomerUUIDProps {
  to: string;
  children: React.ReactNode;
}

/**
 * A wrapper around the React Router Link component that prepends the customer UUID to the URL.
 * @param to The URL to link to.
 * @param children The children of the Link component.
 * @returns A Link component with the customer UUID prepended to the URL.
 */
export const LinkWithCustomerUUID: React.FC<LinkWithCustomerUUIDProps> = ({
  to,
  children,
  ...props
}) => {
  const customer = useAppSelector((state) => state.customer);
  const customerUUID = customer.uuid;

  // Prepend the customerUUID to the provided 'to' URL
  const newTo = `/${customerUUID}${to}`;

  return (
    <Link to={newTo} {...props}>
      {children}
    </Link>
  );
};
