import * as React from "react";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { removeItemByValue } from "./helpers";

const DynamicList = ({ items, updateItems }) => {
  const handleRemoveItem = (item) => {
    const filteredList = removeItemByValue(items, item);
    updateItems(filteredList, items);
  };

  return (
    <List sx={{ width: "100%" }}>
      {items.map((item) => {
        const labelId = `checkbox-list-label-${item}`;

        return (
          <ListItem
            key={item}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="comments"
                onClick={() => handleRemoveItem(item)}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
            }
            disablePadding
          >
            <ListItemText id={labelId} primary={item} />
          </ListItem>
        );
      })}
    </List>
  );
};

DynamicList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
};

export default DynamicList;
