import React, { useEffect, useState, useContext } from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import { getRequest } from "../../../../Helpers/httpRequests";
import { DataContext } from "../../../../Context/dataContext";
import { useAppSelector } from "../../../../Redux/app/hooks";

const BSBreadcrumbs = (props) => {
  const current_customer = useAppSelector(
    (state) => state.customer
  );
  const [queryParams] = useSearchParams();
  const [campaignName, setCampaignName] = useState("");
  const { accessToken } = useContext(DataContext);

  const { campaignUUID } = props;
  useEffect(() => {
    if (campaignUUID) {
      const getCampaignName = async () => {
        const res = await getRequest(
          `/react/api/${current_customer.uuid}/report/campaign/getName`,
          accessToken,
          {
            campaign: campaignUUID,
          }
        );
        setCampaignName(res.data.name);
      };
      getCampaignName();
    }
  }, [queryParams, campaignUUID, accessToken, current_customer]);

  // Home / Campaigns / ${Campaign name} / Finding Results
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link to="/">Home</Link>
      <Link to={`/${current_customer.uuid}/simulations/campaigns/list`}>
        Campaigns
      </Link>
      <Link to={`/${current_customer.uuid}/reporting/campaign/${campaignUUID}`}>
        {campaignName}
      </Link>
      <Typography color="text.primary">Finding Details</Typography>
    </Breadcrumbs>
  );
};

export default BSBreadcrumbs;
