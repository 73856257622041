import { Alert, Box, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { FaLink } from "react-icons/fa";
import TextButton from "../../../../Components/Buttons/TextButton";
import { DataContext } from "../../../../Context/dataContext";
import { getRequest } from "../../../../Helpers/httpRequests";
import { LogChainEdit } from "./LogChainEdit";
import { LogChainTable } from "./LogChainTable";
import { useAppSelector } from "../../../../Redux/app/hooks";

export const LogChain = () => {
  const { accessToken } = useContext(DataContext);
  const customer = useAppSelector((state) => state.customer);
  const [tableData, setTableData] = useState({
    loaded: false,
    securityStacks: [],
  });
  const [editOpen, setEditOpen] = useState(false);
  const [currentStack, setStack] = useState({});
  const [securityTools, setSecurityTools] = useState({
    securityTools: [],
  });
  const [action, setAction] = useState("edit");

  useEffect(() => {
    const getLogChains = async () => {
      const res = await getRequest(
        `/react/api/${customer.uuid}/alertvalidation/get-securitystacks`,
        accessToken
      );

      setTableData({ loaded: true, securityStacks: res.data.securityStacks });
    };
    const getSecurityTools = async () => {
      const res = await getRequest(
        `/react/api/${customer.uuid}/config/securitytools`,
        accessToken
      );
      setSecurityTools({ securityTools: res.data.security_tools });
    };
    if (!editOpen) {
      getLogChains();
      getSecurityTools();
    }
  }, [accessToken, setTableData, editOpen, customer.uuid]);

  const handleAddLogChain = () => {
    setAction("add");
    setEditOpen(true);
  };

  return (
    <Stack spacing={2}>
      {tableData.securityStacks.length === 0 && (
        <Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
            }}
          >
            <TextButton
              onClick={handleAddLogChain}
              tooltip={`Add New LogChain`}
              icon={FaLink}
            />
          </Box>
          <Alert severity="warning">
            There are no Security Stacks created. Click the add Log Chain button
            to start.
          </Alert>
        </Stack>
      )}
      {(tableData.securityStacks.length > 0 && tableData.loaded) || editOpen ? (
        editOpen ? (
          <LogChainEdit
            securityToolOptions={securityTools}
            securityStack={currentStack}
            setEditOpen={setEditOpen}
            customer={customer}
            action={action}
          />
        ) : (
          <Stack sx={{marginTop: 2}}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <Typography fontWeight={"bold"} variant="h4" sx={{marginTop: 3}}>
                Log Chain Table
              </Typography>
              <TextButton
                onClick={handleAddLogChain}
                tooltip={`Add New LogChain`}
                icon={FaLink}
              />
            </Box>

            <LogChainTable
              setTableData={setTableData}
              tableData={tableData.securityStacks}
              setStack={setStack}
              setEditLogChain={setEditOpen}
            />
          </Stack>
        )
      ) : null}
    </Stack>
  );
};
