import React, { useEffect, useContext, useState } from "react";
import { DataContext } from "../../../Context/dataContext";
import TextButton from "../../../Components/Buttons/TextButton";
import {
  Breadcrumbs,
  Typography,
  Box,
  Stack,
  IconButton,
  Button,
} from "@mui/material";
import { fileRequest } from "../../../Helpers/httpRequests";
import { FaDownload, FaPlus, FaTrashAlt } from "react-icons/fa";
import { COLORS } from "../../../Styles/colors";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import FormModal from "../../../Components/Modals/FormModal";
import { toast } from "react-toastify";
import { useHttpRequest } from "../../../Hooks";
import { ExerciseTemplateForm } from "./submodules/ExerciseTemplateForm";
import { useAppSelector } from "../../../Redux/app/hooks";

const ExerciseTemplates = () => {
  // ----- STATES && CONTEXT -----
  const role = useAppSelector((state) => state.user.role);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  const [dataTableRows, setDataTableRows] = useState([]);
  const { accessToken } = useContext(DataContext);
  const [deleteModal, setDeleteModal] = useState(false);
  const [templateModal, setTemplateModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [deleteID, setDeleteID] = useState(-1);
  const [deleteName, setDeleteName] = useState("");

  const { fetch: deleteTemp } = useHttpRequest({
    method: "DELETE",
    path: `/api/v2/exercisetemplates/${deleteID}`,
    defer: true,
  });
  let { response: templates, fetch } = useHttpRequest({
    method: "GET",
    path: "/api/v2/exercisetemplates",
  });

  //Variables

  const columns = [
    {
      field: "name",
      headerName: "Name",
      maxWidth: 400,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Typography
              className="text-links"
              style={{
                opacity: role === "View_Only" ? 0.5 : 1,
                fontWeight: params.row.default ? "bold" : "normal",
              }}
              onClick={() => {
                if (role !== "View_Only") {
                  setTemplateModal(true);
                  setSelectedTemplate(params.row);
                }
              }}
            >
              {params.row.name}
            </Typography>
          </>
        );
      },
    },
    { field: "fileName", headerName: "FileName", minWidth: 100, flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      maxWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Stack spacing={2} direction="row" title="Delete Template">
              <IconButton
                disabled={role === "View_Only"}
                style={{ opacity: role === "View_Only" ? 0.5 : 1 }}
                onClick={() => {
                  setDeleteName(params.row.name);
                  setDeleteID(params.row.id);
                  setDeleteModal(true);
                }}
              >
                <FaTrashAlt color={COLORS.error.main} />
              </IconButton>
              <IconButton
                title="Download Template"
                onClick={() => downloadTemplate(params)}
              >
                <FaDownload />
              </IconButton>
              {/* <IconButton
                title="Test Template"
                onClick={() => downloadTemplateTest(params)}
              >
                <FaExclamationCircle />
              </IconButton> */}
            </Stack>
          </>
        );
      },
    },
  ];

  //Functions

  const deleteTemplate = async () => {
    deleteTemp();
    setDeleteModal(false);
    templates = templates.filter((elem) => {
      return elem.id !== deleteID;
    });
    fetch();
  };

  const downloadTemplate = async (params) => {
    await toast.promise(
      fileRequest(
        `/api/v2/${current_customer.uuid}/exercisetemplates/${params.row.id}/file`,
        accessToken,
        `${params.row.fileName}`
      ),
      {
        pending: "Downloading Template",
        success: `Template Downloaded`,
        error: "Something went wrong!",
      }
    );
  };


  // ----- ON PAGE LOAD -----

  useEffect(() => {
    if (templates !== null) {
      const rows = templates.map((template) => {
        return {
          id: template.id,
          name: template.name,
          fileName: template.name + ".docx",
          universal: template.universal,
          default: template.default,
          actions: [],
        };
      });
      setDataTableRows(rows);
    } else {
      fetch();
    }
  }, [templates, fetch]);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Alert Validation</Typography>
        <Typography color="text.primary">Exercise Templates</Typography>
      </Breadcrumbs>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Typography component={"span"} fontWeight={"bold"} variant="h4">
          Templates
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <TextButton
            role={role}
            tooltip="Add Template"
            icon={FaPlus}
            onClick={() => setTemplateModal(true)}
          />
        </Box>
      </Box>

      <DataGridWithStyles
        name="reports-templates"
        autoHeight
        paper
        rows={dataTableRows}
        columns={columns}
      />
      {/* Delete Modal */}
      <FormModal open={deleteModal} setOpen={setDeleteModal}>
        <Box component="form">
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: 4 }}
          >
            Are you sure you want to remove template {deleteName}?
          </Typography>
          <Stack direction="column" spacing={2}>
            <Button onClick={() => deleteTemplate()} variant="contained">
              Yes
            </Button>
          </Stack>
        </Box>
      </FormModal>

      {/* Edit/Add modal */}
      <FormModal open={templateModal} setOpen={setTemplateModal}>
        <ExerciseTemplateForm
          template={selectedTemplate}
          mode={selectedTemplate ? "edit" : "add"}
          onComplete={() => {
            setTemplateModal(false);
            setSelectedTemplate(null);
            fetch();
          }}
        />
      </FormModal>
    </>
  );
};

export default ExerciseTemplates;
