import { useContext } from "react";
import { toast } from "react-toastify";
import { DataContext } from "../../../../../../Context/dataContext";
import { getRequest } from "../../../../../../Helpers/httpRequests";
import { AVDetailsContext } from "../../../ContextProvider/ContextProvider";
import { useAppSelector } from "../../../../../../Redux/app/hooks";

const useReprocessAlerts = () => {
  const { campaignUUID, setAlertProcessing } = useContext(AVDetailsContext);
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );


  const reprocessAlerts = async () => {
    setAlertProcessing(true);
    await toast.promise(
      getRequest(
        `/react/api/${current_customer.uuid}/alertvalidation/alert/process-results/${campaignUUID}`,
        accessToken
      ),
      {
        pending: "Reprocessing Results...",
        success: `Results updated!`,
        error: "Something went wrong!",
      }
    );
    setAlertProcessing(false);
  };

  return { reprocessAlerts };
};

export default useReprocessAlerts;
