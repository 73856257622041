import React, { useState, useContext } from "react";
import { Button, ButtonGroup, Tooltip } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { postRequest } from "../../../Helpers/httpRequests";
import {
  FaBan,
  FaFileCode,
  FaEyeSlash,
  FaExclamationTriangle,
} from "react-icons/fa";
import { DataContext } from "../../../Context/dataContext";

/**
 * This component, given the state and setState of a finding object, will allow you to change
 * the score.
 * @param {Props} param0
 * @returns
 */
const ScoreButtonGroup = ({ finding, customer, setFinding, setReloadPage }) => {
  const { accessToken } = useContext(DataContext);
  const [bgColors, setBgColors] = useState([
    "#ffffff00",
    "#ffffff00",
    "#ffffff00",
    "#ffffff00",
  ]);

  /**
   * Modifying the colors of the Buttongroup for scoring.
   * provided to the <ThemeProvider> from MUI as a prop
   */
  const theme = createTheme({
    palette: {
      logged: {
        main: "#ECC010",
        contrastText: "#fff",
      },
    },
  });

  /**
   * Handles the scoring of the finding.
   * @param {String} score
   * @param {Boolean} isActive
   */
  const onChange = async (score, isActive) => {
    // Split the path so that we can get uuid of exe camp and finding.
    const path = window.location.pathname;
    const splitPath = path.substring(1).split("/");

    /**
     * Send request to backend to update the finding score.
     * Don't need to use toast here, it will likely be spammed and cause UI issues.
     */
    const res = await postRequest(`/react/api/${customer.uuid}/report/js-tags`, accessToken, {
      status: score,
      executedID: splitPath[3],
      itemID: splitPath[4],
      action: isActive ? "remove" : "add",
    });

    /**
     * If response is good, update the finding state
     */
    if (res.status === 200) {
      const f = res.data.finding;
      console.log("f: ", f);
      setFinding((finding) => {
        return {
          ...finding,
          alerted: f.alerted,
          blocked: f.blocked,
          logged: f.logged,
          nothing: f.nothing,
        };
      });
      setReloadPage((current) => !current);
    }
  };

  useState(() => {
    /**
     * handles the logic responsible for setting the expectedOutcome and background color of the
     * scoring button group.
     */
    const configureBgColors = (finding) => {
      const sim_action = finding.simulation_action;
      const successColor = "#cefad0";
      const failColor = "#ffeaea";
      let colors;

      if (sim_action.blocked)
        colors = [successColor, failColor, failColor, failColor];

      if (sim_action.alerted)
        colors = [successColor, successColor, failColor, failColor];

      if (sim_action.logged)
        colors = [successColor, successColor, successColor, failColor];

      if (sim_action.nothing)
        colors = [successColor, successColor, successColor, successColor];

      if (colors) {
        setBgColors(colors);
      }
    };
    if (finding.simulation_action) {
      configureBgColors(finding);
    }
  }, []);

  if (Object.keys(finding).length <= 0) {
    return null;
  } else {
    return (
      <ThemeProvider theme={theme}>
        <ButtonGroup
          variant="outlined"
          aria-label="outlined button group"
          size="large"
          fullWidth
        >
          <Tooltip title="Blocked" placement="top" arrow>
            <Button
              color="success"
              sx={{
                backgroundColor: finding.blocked ? "success" : bgColors[0],
              }}
              variant={finding.blocked ? "contained" : "outlined"}
              onClick={() => onChange("blocked", finding.blocked)}
            >
              <FaBan />
            </Button>
          </Tooltip>
          <Tooltip title="Alerted" placement="top" arrow>
            <Button
              color="primary"
              sx={{
                backgroundColor: finding.alerted ? "primary" : bgColors[1],
              }}
              variant={finding.alerted ? "contained" : "outlined"}
              onClick={() => onChange("alerted", finding.alerted)}
            >
              <FaExclamationTriangle />
            </Button>
          </Tooltip>
          <Tooltip title="Logged" placement="top" arrow>
            <Button
              color="logged"
              sx={{
                backgroundColor: finding.logged ? "logged" : bgColors[2],
              }}
              variant={finding.logged ? "contained" : "outlined"}
              onClick={() => onChange("logged", finding.logged)}
            >
              <FaFileCode />
            </Button>
          </Tooltip>
          <Tooltip title="No Evidence / Nothing" placement="top" arrow>
            <Button
              color="error"
              sx={{
                backgroundColor: finding.nothing ? "error" : bgColors[3],
              }}
              variant={finding.nothing ? "contained" : "outlined"}
              onClick={() => onChange("nothing", finding.nothing)}
            >
              <FaEyeSlash />
            </Button>
          </Tooltip>
        </ButtonGroup>
      </ThemeProvider>
    );
  }
};

export default ScoreButtonGroup;
