export const containerStyles = {
  marginBottom: 5,
  textAlign: "center",
};

export const themes = {
  dark: {
    textColor: "#fff"
  },
  light: {
    textColor: "#000"
  }
}

export const loadingContainerStyles = {
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}