import React, { useEffect, useContext, useState } from "react";
import { postRequest } from "../../../../Helpers/httpRequests";
import { Divider, Stack, Typography, Box, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataContext } from "../../../../Context/dataContext";
import PropTypes from "prop-types";
import { useAppSelector } from "../../../../Redux/app/hooks";

const CampaignNameForm = ({ setOpen, onChange, campaign, modal = true }) => {
  // A form for editing the name of the Campaign.
  // NOTE: This takes in only a Campaign object not an ExecutedCampaign object

  // ----- CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  // ----- STATES -----

  const [formData, setFormData] = useState({
    uuid: "",
    name: "",
  });

  /**
   * Used for handling the props/children of <LoadingButton>
   */
  const [loadingBtn, setLoadingBtn] = useState({
    loading: false,
    color: "primary",
    text: "Submit",
    helperText: "",
    helperTextColor: "green",
  });

  // ---- Variables ------

  /**
   * These are the styles for the Box inside of the modal.
   * The Modal component renders as position Absolute so we
   * have to write styles accordingly
   *
   */
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };

  // ----- Functions -----

  /**
   * Handles the form submission.
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    // prevent page reload on submit
    e.preventDefault();

    // set submit btn to loading
    setLoadingBtn((prev) => ({ ...prev, loading: true }));

    const res = await postRequest(
      `/react/api/${current_customer.uuid}/report/campaign/changeName`,
      accessToken,
      formData
    );
    setLoadingBtn((prev) => ({ ...prev, loading: false }));

    // send form response back as 200 (ok)
    if (res.status === 200) {
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Success",
        color: "success",
        helperText: "Success",
        helperTextColor: "#2e7d32", // green
      }));

      /**
       * If this form is contained in a modal, you can pass the setOpen state to this component.
       * This will wait 1.5 seconds and then close the modal
       * The setOpen for this specific form closes the modal and then sets the name on the parent object
       * so pass in the name
       */
      onChange(formData.name);
      if (setOpen !== undefined) {
        setTimeout(() => setOpen(false), 1500);
      }
    } else {
      // handle errors here
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Error - Try again",
        color: "error",
        helperText: "Form submission failed",
        helperTextColor: "#d32f2f", // red
      }));
      console.log(
        "%cerror CampaignNameForm.jsx handleSubmit()",
        "color: red; display: block; width: 100%;",
        "Failed to submit form"
      );
    }
  };

  // ----- On page load -----
  useEffect(() => {
    setFormData({ name: campaign.name, uuid: campaign.uuid });
  }, [current_customer, accessToken, campaign]);

  return (
    <Box sx={modal ? style : null}>
      {/* Form Header */}
      <Typography variant="h5">Change Campaign Name</Typography>

      <Divider style={{ marginBottom: "0.5rem" }} />

      {/* FORM */}
      <form onSubmit={(e) => handleSubmit(e)}>
        <Stack direction="column" spacing={2}>
          {/* Start form fields here */}
          {/* Name */}
          <TextField
            required
            value={formData.name}
            onChange={(e) => {
              setFormData({ ...formData, name: e.target.value });
            }}
            label="Name"
          />
          {/* End form fields */}

          {/* helper text */}
          <Typography color={loadingBtn.helperTextColor} variant="subtitle2">
            {loadingBtn.helperText}
          </Typography>

          {/* Submit Button */}
          <LoadingButton
            loading={loadingBtn.loading}
            color={loadingBtn.color}
            type="submit"
            variant="contained"
          >
            {loadingBtn.text}
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
};

CampaignNameForm.propTypes = {
  setOpen: PropTypes.func,
  onChange: PropTypes.func,
  modal: PropTypes.bool,
  campaign: PropTypes.object.isRequired,
};

export default CampaignNameForm;
