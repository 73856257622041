import {
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
  Alert,
} from "@mui/material";
import React from "react";
import { FaDownload } from "react-icons/fa";

const SimulationReviewForm = ({
  formData,
  jsonString,
  simUUID,
  OpenJsonInNewTab,
  OpenJsonInNewTabWithDownload,
  downloadFile,
  renderJsonStatus,
  handleJSONUpload,
  hanldeZIPupload,
  checkingJson,
  type,
}) => {
  const data = [
    { label: "Name", value: formData.name || "" },
    { label: "Description", value: formData.description },
    { label: "Customers", value: formData.customer_access.length },
    {
      label: "JSON",
      value:
        type === "edit" ? (
          <Stack spacing={1} direction="row">
            <Tooltip title="Open Json in new Tab">
              <Typography
                className="text-links"
                onClick={() => OpenJsonInNewTab(jsonString)}
              >
                {formData.json_file}
              </Typography>
            </Tooltip>
            <Tooltip title="Download JSON">
              <IconButton
                onClick={() =>
                  OpenJsonInNewTabWithDownload(
                    jsonString,
                    `${formData.name}.json`
                  )
                }
              >
                <FaDownload size={15} />
              </IconButton>
            </Tooltip>
          </Stack>
        ) : (
          <>
            <div>
              <Button
                disabled
                variant="outlined"
                size="small"
                component="label"
                sx={{ mr: 1 }}
              >
                {"CHOOSE FILE"}
                <input
                  type="file"
                  accept=".json"
                  onChange={handleJSONUpload}
                  hidden
                />
              </Button>
              {formData.json_file?.name && (
                <span>{formData.json_file?.name}</span>
              )}
            </div>

            {renderJsonStatus(checkingJson)}
          </>
        ),
    },
    {
      label: "Zip",
      value:
        type === "edit" ? (
          <>
            {formData.zip_file == null ? (
              <Alert severity="info"> No zip file added</Alert>
            ) : (
              <Stack spacing={1} direction="row">
                <Tooltip title="Download Zip File">
                  <Typography
                    className="text-links"
                    onClick={() => downloadFile(simUUID)}
                  >
                    {formData.zip_file}
                  </Typography>
                </Tooltip>
              </Stack>
            )}
          </>
        ) : (
          <>
            {formData.zip_file == null ? (
              <Alert severity="info"> No zip file added</Alert>
            ) : (
              <div>
                <Button
                  disabled
                  variant="outlined"
                  size="small"
                  component="label"
                  sx={{ mr: 1 }}
                >
                  {"CHOOSE ZIP"}
                  <input
                    type="file"
                    accept=".json"
                    onChange={hanldeZIPupload}
                    hidden
                  />
                </Button>
                {formData.zip_file?.name && (
                  <span>{formData.zip_file?.name}</span>
                )}
              </div>
            )}
          </>
        ),
    },
    { label: "Category", value: formData.category },
    { label: "Platform", value: formData.platform },
    { label: "Default", value: formData.default ? "Yes" : "No" },
    { label: "AV", value: formData.alert_validation ? "Yes" : "No" },
    { label: "SMV", value: formData.smv_access ? "Yes" : "No" },
  ];
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index} sx={{ marginBottom: 1 }}>
              <TableCell sx={{ fontWeight: "bold", borderBottom: "none" }}>
                {item.label}
              </TableCell>
              <TableCell sx={{ borderBottom: "none" }}>{item.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SimulationReviewForm;
