import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DataGridWithStyles from "../../../../Components/DataGrid/DataGridWithStyles";
import { postRequest } from "../../../../Helpers/httpRequests";
import { DataContext } from "../../../../Context/dataContext";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../Redux/app/hooks";

const ChangeGlobalStatesModal = ({ data, closeModal, setChangesMade }) => {
  const [simulationName, setSimulationName] = useState(data.simulation.name);
  const [simactionRows, setSimactionRows] = useState(data.simactions);
  const [noteDialogOpen, setNoteDialogOpen] = useState(false);
  const [currentNote, setCurrentNote] = useState("");
  const [currentNoteIndex, setCurrentNoteIndex] = useState(null);

  const { accessToken } = useContext(DataContext);
  const customer = useAppSelector((state) => state.customer);

  const handleNoteChange = (index, value) => {
    const updatedRows = [...simactionRows];
    updatedRows[index].note = value;
    setSimactionRows(updatedRows);
  };

  const handleCleanUpChange = (index) => {
    const updatedRows = [...simactionRows];
    updatedRows[index].clean_up = !updatedRows[index].clean_up;
    setSimactionRows(updatedRows);
  };

  const handleSubmit = async () => {
    const LoadingToast = toast.loading("Updating states globally")
    const updatedData = {
      simulationName: simulationName,
      uuid: data.simulation.uuid,
      simRows: simactionRows,
    };
    const result = await postRequest(
      `/react/api/${customer.uuid}/simulations/change_global`,
      accessToken,
      updatedData,
      true
    );
    if (result.status === 200) {
      toast.dismiss(LoadingToast)
      closeModal()
      setChangesMade(current => !current)
      toast.success("Successfully updated states globally")
    } else {
      toast.dismiss(LoadingToast)
      toast.error("Failed to update states")
    }
  };

  const handleNoteDialogOpen = (index) => {
    setCurrentNoteIndex(index);
    setCurrentNote(simactionRows[index].note || "");
    setNoteDialogOpen(true);
  };

  const handleNoteDialogClose = () => {
    setNoteDialogOpen(false);
    if (currentNoteIndex !== null) {
      handleNoteChange(currentNoteIndex, currentNote);
    }
    setCurrentNoteIndex(null);
    setCurrentNote("");
  };

  const columns = [
    {
      field: "name",
      headerName: "Step Name",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "note",
      headerName: "Note",
      minWidth: 700,
      flex: 1,
      renderCell: (params) => (
        <TextField
          fullWidth
          value={params.row.note}
          onClick={() => handleNoteDialogOpen(params.row.index)}
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      field: "clean_up",
      headerName: "Clean Up",
      maxWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={params.row.clean_up}
          onChange={() => handleCleanUpChange(params.row.index)}
        />
      ),
    },
  ];

  const rows = simactionRows.map((action, index) => ({
    id: action.uuid,
    index,
    name: action.name,
    note: action.note || "",
    clean_up: action.clean_up || false,
    step: action.step
  }));

  return (
    <Box sx={{ maxWidth: "70%", width: "60%", marginLeft: 50, marginTop: 10 }}>
      <Paper sx={{ padding: 2 }}>
        <Stack spacing={2}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Change Global States
          </Typography>
          <TextField
            label="Simulation Name"
            value={simulationName}
            onChange={(e) => setSimulationName(e.target.value)}
            fullWidth
          />
          <DataGridWithStyles
            name="global-states"
            autoHeight
            rows={rows}
            columns={columns}
          />
          <Button variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Stack>
      </Paper>
      <Dialog
        open={noteDialogOpen}
        onClose={handleNoteDialogClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Edit Note</DialogTitle>
        <DialogContent>
          <TextField
            multiline
            fullWidth
            rows={4}
            value={currentNote}
            onChange={(e) => setCurrentNote(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNoteDialogClose} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ChangeGlobalStatesModal;
