import React, { useState, useEffect, useContext } from "react";
import { Button, Paper, Stack, TextField, Typography } from "@mui/material";
import DynamicList from "../../../../Components/DynamicList/DynamicList";
import { DataContext } from "../../../../Context/dataContext";
import { getRequest, postRequest } from "../../../../Helpers/httpRequests";
import { toast } from "react-toastify";
import { containerStyles } from "../styles";
import { useAppSelector } from "../../../../Redux/app/hooks";

const AddEmails = ({ sx }) => {
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );
  const [emails, setEmails] = useState([]);
  const [emailTextField, setEmailTextField] = useState("");

  const URL = `/react/api/${current_customer.uuid}/alertvalidation/settings`;

  const handleEmailTextFieldChange = (e) => {
    setEmailTextField(e.target.value);
  };

  const handleUpdateEmails = async (newEmails, oldEmails) => {
    const res = await postRequest(
      URL,
      accessToken,
      { emails: newEmails },
      true
    );
    if (res.status === 201) {
      setEmails(res.data.emails);
    } else {
      setEmails(oldEmails);
      toast.error("Failed to remove email, please contact support");
    }
  };

  const handleAddEmail = async (e) => {
    // prevent page from reloading
    e.preventDefault();

    // set new email state
    const oldEmails = emails;
    const newEmails = [emailTextField, ...emails];
    setEmails(newEmails);

    // clear the text field
    setEmailTextField("");

    // update database
    const data = { emails: newEmails };
    const res = await postRequest(URL, accessToken, data, true);

    // if status is not returned at 201 (created) raise error
    if (res.status === 201) {
      setEmails(res.data.emails);
    } else {
      setEmails(oldEmails);
      toast.error("Failed to update emails, contact support.");
    }
  };

  useEffect(() => {
    /**
     * fetches current customers alert validation
     * emails from database and sets the emails state
     */
    const getSettings = async () => {
      const res = await getRequest(URL, accessToken);
      setEmails(res.data.emails);
    };

    getSettings();
  }, [current_customer, accessToken, URL]);

  return (
    <Paper sx={{ ...containerStyles, ...sx }}>
      <Stack spacing={2}>
        <Typography>
          Emails to be notified when Alert Validations do not meet expected
          results
        </Typography>
        <form onSubmit={handleAddEmail}>
          <Stack spacing={2} direction="row">
            <TextField
              fullWidth
              placeholder="Email"
              label="Email"
              type="email"
              value={emailTextField}
              onChange={handleEmailTextFieldChange}
              required
            />
            <Button type="submit" variant="contained">
              ADD
            </Button>
          </Stack>
        </form>
        <DynamicList
          items={emails}
          setItems={setEmails}
          updateItems={handleUpdateEmails}
        />
      </Stack>
    </Paper>
  );
};

export default AddEmails;
