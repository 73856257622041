import React, { useState, useContext, useEffect } from "react";
import { getRequest } from "../../../../Helpers/httpRequests";
import { Stack } from "@mui/system";
import { Typography, CircularProgress } from "@mui/material";
import TextButton from "../../../../Components/Buttons/TextButton/TextButton";
import { DataContext } from "../../../../Context/dataContext";
import { FaCheckCircle, FaCloudUploadAlt, FaTimesCircle } from "react-icons/fa";
import { useAppSelector } from "../../../../Redux/app/hooks";

export const PlextracProcess = (props) => {
  const { uuid } = props;

  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  const [reportTool, setReportTool] = useState([]);
  const [plextracProcessing, setPlextracProcessing] = useState(false);
  const [plextracResult, setPlextracResult] = useState("");

  const uploadToPlextrac = async () => {
    setPlextracProcessing(true);
    // plextrac result is kinda a boolean but with not set as a value. ik there's a better way but I forgor
    setPlextracResult("");
    const res = await getRequest(
      `/react/api/${current_customer.uuid}/plextrac/${uuid}/uploadProject`,
      accessToken
    );
    setPlextracProcessing(false);
    if (res.status === 200) {
      setPlextracResult("success");
    } else {
      setPlextracResult("fail");
    }
  };

  useEffect(() => {
    const getReportTool = async () => {
      const res = await getRequest(`/react/api/${current_customer.uuid}/get-report-tool`, accessToken, {
        active: true,
      });

      if (res.status === 200) {
        setReportTool(res.data.customerReportTool);
      }
    };

    getReportTool();
  }, [accessToken, current_customer]);

  return reportTool.length > 0 ? (
    <Stack>
      {/* Plextrac processing status */}
      {plextracProcessing ? (
        <Stack>
          <CircularProgress />
          <Typography>Processing...</Typography>
        </Stack>
      ) : (
        <TextButton
          tooltip="Upload to Plextrac"
          icon={FaCloudUploadAlt}
          onClick={uploadToPlextrac}
        />
      )}
      {plextracResult === "" ? null : (
        <>
          {/* result */}
          {plextracResult === "success" ? (
            <Typography>
              Successfully Exported! <FaCheckCircle color="green" />
            </Typography>
          ) : (
            <Typography>
              Something Went Wrong! <FaTimesCircle color="red" />
            </Typography>
          )}
        </>
      )}
    </Stack>
  ) : null;
};
