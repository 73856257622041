import React, { useContext } from "react";
import { Divider, Paper, Grid, Stack, Typography } from "@mui/material";
import sentinel from "../lib/icons/sentinel.png";
import defender from "../lib/icons/Windows-defender.png";
import crowdstrike from "../lib/icons/crowdstrike.png";
import sentinelone from "../../../../Images/security_tools_icons/sentinel_one.png"
import { AVDetailsContext } from "../ContextProvider/ContextProvider";

const ToolsValidated = () => {
  const { secTools } = useContext(AVDetailsContext);

  const toolLogos = {
    Defender: defender,
    Sentinel: sentinel,
    Crowdstrike: crowdstrike,
    SentinelOne: sentinelone
  };

  return (
    <Paper className="flex-1" sx={{ p: 3 }}>
      <Stack spacing={1}>
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            fontWeight: "bold",
          }}
        >
          Tools Validated
        </Typography>
        <Divider />
        <Grid container columns={3}>
          {secTools.map((tool, index) => {
            return (
              <Grid
                item
                key={`${tool.tool.name}-${index}`}
                xs={1}
                sm={1}
                md={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  style={{ maxHeight: "80px", width: "auto" }}
                  src={toolLogos[tool.tool.product]}
                  alt={tool.tool.name}
                />
                <p className="mt-2">{tool.tool.name}</p>
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </Paper>
  );
};

export default ToolsValidated;
