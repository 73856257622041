/**
 * Hook to return the state of the current environment.
 * EX: const { isMAINTENANCE } = useEnvironment();
 * @returns Object of all possible environments
 */
export const useEnvironment = () => {
  // Env from system env
  const env = process.env.REACT_APP_ENVIRONMENT;

  // Defaults
  const environments = {
    isDEV: false,
    isPROD: false,
    isLOCAL: false,
    isMAINTENANCE: false,
    currentEnv: null,
  };

  // Only one can be true.
  switch (env) {
    case "PROD":
      environments.isPROD = true;
      environments.currentEnv = "PROD";
      break;
    case "DEV":
      environments.isDEV = true;
      environments.currentEnv = "DEV";
      break;
    case "LOCAL":
      environments.isLOCAL = true;
      environments.currentEnv = "LOCAL";
      break;
    case "MAINTENANCE":
      environments.isMAINTENANCE = true;
      environments.currentEnv = "MAINTENANCE";
      break;
    default:
      break;
  }

  return environments;
};
