import { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
  Box,
  Button,
  Breadcrumbs,
  Typography,
  TextField,
  Divider,
  Switch,
  FormGroup,
  FormControlLabel,
  CircularProgress,
  Alert,
} from "@mui/material";
import { Stack } from "@mui/system";
import { getRequest } from "../../../Helpers/httpRequests";
import { DataContext } from "../../../Context/dataContext";
import moment from "moment";
import SimulationMultiSelect from "./SimulationMultiSelect";
import EndpointsMultiSelect from "./EndpointMultiSelect";
import { useSearchParams } from "react-router-dom";
import SankeyDiagram from "../../../Components/Reporting/SankeyDiagram";
import ProjectsMultiSelect from "./ProjectsMultiSelect";
import { useAppSelector } from "../../../Redux/app/hooks";

const Dashboard = () => {
  // ----- Search Params -----
  const [searchParams, setSearchParams] = useSearchParams();

  // ----- Context -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector((state) => state.customer);

  // ----- States -----
  const [endpoints, setEndpoints] = useState([]);
  const [simulations, setSimulations] = useState([]);
  const [projects, setProjects] = useState([]);
  const [includeUnassigned, setIncludeUnassigned] = useState(true);
  const [sankeyData, setSankeyData] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    start: moment().subtract(6, "months").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
    simulations: [],
    endpoints: [],
    projects: [],
    projectUUIDs: [],
  });

  // ----- Variable -----

  // ----- Functions -----
  const handleFilterSubmit = async (event) => {
    event.preventDefault();
    let simulations = "";
    formData.simulations.map((s) => (simulations += `${s},`));
    let endpoints = "";
    formData.endpoints.map((e) => (endpoints += `${e[0]},`));

    let projectuuids = formData.projectUUIDs.join(",");
    // let string = `dateFrom=${formData.start}&dateTo=${formData.end}&agents__name__in=${endpoints}&simulation__name__in${simulations}`
    let query = {
      dateFrom: formData.start,
      dateTo: formData.end,
      agents__name__in: endpoints,
      simulation__name__in: simulations,
      projects: projectuuids,
    };
    setSearchParams(query);
  };

  // ----- On page load -----

  useLayoutEffect(() => {
    const getData = async () => {
      setSankeyData(null);
      const dateFrom = searchParams.get("dateFrom");
      const dateTo = searchParams.get("dateTo");
      const agents__name__in = searchParams.get("agents__name__in");
      const simulation__name__in = searchParams.get("simulation__name__in");
      const project = searchParams.get("projects");

      let query = "";
      // dateFrom
      if (dateFrom !== null) {
        query += `dateFrom=${dateFrom}`;
      }

      // dateTo
      if (dateTo !== null) {
        query += `&dateTo=${dateTo}`;
      }

      // agents__name__in
      if (agents__name__in !== null) {
        query += `&agents__name__in=${agents__name__in}`;
      }

      // simulation__name__in
      if (simulation__name__in !== null) {
        query += `&simulation__name__in=${simulation__name__in}`;
      }

      if (project !== null) {
        query += `&project__uuid__in=${project}`;
      }

      // include Unassigned
      if (includeUnassigned) {
        query += `&includeUnassigned=${includeUnassigned}`;
      }

      const res = await getRequest(
        `/react/api/${current_customer.uuid}/report/dashboard?${query}`,
        accessToken
      );

      if (res.status === 200) {
        // set the filters
        setEndpoints(res.data.filters.endpoints);
        setSimulations(res.data.filters.simulations);
        setSankeyData(res.data.sankey_data);
        const formattedDate = moment(res.data.start_date).format("YYYY-MM-DD");

        setFormData((prevState) => ({
          ...prevState,
          start: formattedDate,
        }));
        setLoaded(true);

        // // create the chart
        // am4core.ready(function () {

        //   chart.data = res.data.sankey_data
        //   let hoverState2 = chart.links.template.states.create("hover");
        //   chart.links.template.colorMode = "gradient";
        //   hoverState2.properties.fillOpacity = 0.8;

        //   // Exporting
        //   chart.exporting.menu = new am4core.ExportMenu();
        //   chart.exporting.menu.items = [
        //     {
        //       label: "...",
        //       menu: [
        //         {
        //           type: "png",
        //           label: "PNG",
        //         },
        //         {
        //           type: "jpg",
        //           label: "JPG",
        //         },
        //         {
        //           type: "svg",
        //           label: "SVG",
        //         },
        //         {
        //           type: "pdf",
        //           label: "PDF",
        //         },
        //       ],
        //     },
        //   ];
        //   chart.exporting.filePrefix = "blindspot";

        //   chart.dataFields.fromName = "from";
        //   chart.dataFields.toName = "to";
        //   chart.dataFields.value = "value";
        //   chart.dataFields.color = "nodeColor";
        //   chart.paddingRight = 120;

        //   let nodeTemplate = chart.nodes.template;
        //   nodeTemplate.inert = true;
        //   nodeTemplate.readerTitle = "Drag me!";
        //   nodeTemplate.showSystemTooltip = true;
        //   nodeTemplate.width = 30;
        //   nodeTemplate.readerTitle = "Click to show/hide or drag to rearrange";
        //   nodeTemplate.showSystemTooltip = true;
        //   nodeTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;
        //   nodeTemplate.nameLabel.label.width = 250;

        //   // let title2 = chart.titles.create();
        //   // title2.text = "Detection Pipeline By Tactic";
        //   // title2.fontSize = 25;
        //   // title2.marginBottom = 30;
        // });
      } else {
        setError(
          "An error occurred while fetching data. Please contact administrator if issue persists !"
        );
        setLoaded(true);
      }
    };

    if (current_customer) {
      getData();
    }
  }, [accessToken, current_customer, searchParams, includeUnassigned]);

  useEffect(() => {
    const getProjects = async () => {
      const res2 = await getRequest(
        `/react/api/${current_customer.uuid}/projects`,
        accessToken
      );
      if (res2.status === 200) {
        setProjects(res2.data.projects);
      }
    };
    if (projects.length === 0 && current_customer) {
      getProjects();
    }
  }, [projects.length, accessToken, current_customer]);

  return loaded ? (
    <Stack spacing={3}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Reporting</Typography>
        <Typography color="text.primary">Pipeline View</Typography>
      </Breadcrumbs>

      {/* Includ UnAssigned */}
      <Box sx={{ width: 220 }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={includeUnassigned}
                onChange={() => setIncludeUnassigned((prev) => !prev)}
              />
            }
            label="Include Unassigned"
          />
        </FormGroup>
      </Box>

      <form onSubmit={handleFilterSubmit}>
        <Stack direction="row" spacing={1}>
          {/* Start Filter Date */}
          <TextField
            label="Start"
            type="date"
            required
            value={formData.start}
            onChange={(e) =>
              setFormData((data) => ({ ...data, start: e.target.value }))
            }
          />
          {/* Start Filter Date */}
          <TextField
            label="End"
            type="date"
            required
            value={formData.end}
            onChange={(e) =>
              setFormData((data) => ({ ...data, end: e.target.value }))
            }
          />
          <Divider variant="middle" orientation="vertical" flexItem />
          <SimulationMultiSelect
            formData={formData}
            setFormData={setFormData}
            simulations={simulations}
          />
          <EndpointsMultiSelect
            formData={formData}
            setFormData={setFormData}
            endpoints={endpoints}
          />
          <ProjectsMultiSelect
            formData={formData}
            setFormData={setFormData}
            projects={projects}
          />
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </Stack>
        <Button
          sx={{ mt: 1 }}
          size="small"
          variant="outlined"
          onClick={() => {
            setFormData({ ...formData, simulations: [], endpoints: [] });
          }}
        >
          Clear Filters
        </Button>
      </form>

      {error && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="left"
          alignItems="left"
          height="5vh"
        >
          <Alert sx={{ width: "40%" }} variant="filled" severity="error">
            {error}
          </Alert>
        </Box>
      )}

      <Typography variant="body1">
        Detection Pipeline By Tactic - Simulation Actions with Assigned Tools
        and Outcomes
      </Typography>

      {sankeyData && (
        <SankeyDiagram
          sankey_data={sankeyData}
          includeUnassigned={includeUnassigned}
        />
      )}
    </Stack>
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <CircularProgress />
      <Typography variant="h6" marginTop={2}>
        Data is being loaded...
      </Typography>
    </Box>
  );
};

export default Dashboard;
