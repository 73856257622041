import React from "react";
import { Route, Routes } from "react-router-dom";
import Agent from "../../Pages/Support/Agent/Agent";
import AWLBypass from "../../Pages/Support/AWLBypass/AWLBypass";
import BinaryEXE from "../../Pages/Support/BinaryEXE/BinaryEXE";
import HelpPage from "../../Pages/Support/HelpPage/HelpPage";
import ProccessInjection from "../../Pages/Support/ProccessInjection/ProccessInjection";
import ReleaseNotes from "../../Pages/Support/ReleaseNotes/ReleaseNotes";
import RunAgent from "../../Pages/Support/RunAgent/RunAgent";
import ScoreCampaign from "../../Pages/Support/ScoreCampaign/ScoreCampaign";
import Walkthrough from "../../Pages/Support/Walkthrough/Walkthrough";
import HelpPages from "../../Pages/Support/HelpPages/HelpPages";
import HelpPageTemplate from "../../Pages/Support/HelpPages/HelpPageTemplate";

const AdministrationRoutes = () => {
  return (
    <Routes>
      <Route path="help" element={<HelpPage/>}/>
      <Route path="AWLBypass" element={<AWLBypass/>}/>
      <Route path="RunAgent" element={<RunAgent/>}/>
      <Route path="BinaryEXE" element={<BinaryEXE/>}/>
      <Route path="scorecampaign" element={<ScoreCampaign/>}/>
      <Route path="ProccessInjection" element={<ProccessInjection/>}/>
      <Route path="walkthrough" element={<Walkthrough/>}/>
      <Route path="Agent" element={<Agent/>}/>
      <Route path="release-notes" element={<ReleaseNotes/>}/>
      <Route path="help-pages" element={<HelpPages/>}/>
      <Route path="help-pages/agents" element={<HelpPageTemplate/>}/>
      <Route path="help-pages/endpoints" element={<HelpPageTemplate/>}/>
      <Route path="help-pages/campaigns" element={<HelpPageTemplate/>}/>
      <Route path="help-pages/scoring" element={<HelpPageTemplate/>}/>
      <Route path="help-pages/projects" element={<HelpPageTemplate/>}/>
      <Route path="help-pages/av" element={<HelpPageTemplate/>}/>
      <Route path="help-pages/reporting" element={<HelpPageTemplate/>}/>
      <Route path="help-pages/config" element={<HelpPageTemplate/>}/>
      <Route path="help-pages/sim_builder" element={<HelpPageTemplate/>}/>
      <Route path="help-pages/sim_library" element={<HelpPageTemplate/>}/>
    </Routes>
  );
};

export default AdministrationRoutes;
