import React, { useContext } from "react";
import { Paper, Stack } from "@mui/material";
import { classNames } from "../../../../Helpers";
import { AVDetailsContext } from "../ContextProvider/ContextProvider";

const BigStatus = () => {
  const { alertInfo } = useContext(AVDetailsContext);
  return (
    <Paper sx={{ p: 3 }}>
      <Stack
        className="w-full h-full self-center"
        direction="row"
        justifyContent="space-between"
      >
        <div
          className={classNames(
            "font-bold text-8xl text-end",
            alertInfo.av_campaign.alert_validation_status.toUpperCase() ===
              "FAILED"
              ? "text-red-500 border-red-500"
              : "text-green-500 border-green-500",
            "border-2 p-3 px-6 rounded-md shadow h-full items-center flex"
          )}
        >
          {alertInfo.av_campaign.alert_validation_status.toUpperCase()}
        </div>
      </Stack>
    </Paper>
  );
};

export default BigStatus;
