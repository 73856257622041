import { ExpandMore } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Stack,
    Typography,
    TextField,
    Alert
} from "@mui/material";
import React from "react";

const CampAccordions = ({campaigns, btnLoading, handleSubmit}) => {
    // ----- Variables -----
  const accordions = campaigns.map((campaign) => {

    return (
      <Accordion key={campaign.uuid}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls={`panel${campaign.uuid}-content`}
          id={`panel${campaign.uuid}-header`}
        >
            <Box display="flex" alignItems="center" gap={2}>
                <Typography>{campaign.name}</Typography>
                <Typography>CachedFindings {campaign.action_executed}</Typography>
                <Typography>Command Output Actions {campaign.actions_returned}</Typography>
                {campaign.actions_returned > campaign.action_executed && (
                    <Alert severity="warning">
                        Reprocessing Recommended
                    </Alert>
                )}
            </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={1}>
              {/* CMD OUTPUT */}
              <TextField
                  id={`camp-cmd-${campaign.uuid}`}
                  multiline
                  fullWidth
                  maxRows={10}
                  defaultValue={campaign.decoded_cmd_output.trim()}
                  variant="filled"
                  disabled={btnLoading}
              />


            {/* Error Text */}
            <Typography
              id={`input-err-${campaign.uuid}`}
              sx={{ display: "none", color: "red" }}
              variant="caption"
            ></Typography>

            {/* Submit Btn */}
            <Box
              sx={{
                display: "display",
                width: "100%",
                textAlign: "right",
              }}
            >
              <LoadingButton
                id={`camp-btn-${campaign.uuid}`}
                onClick={() => handleSubmit(campaign.uuid)}
                sx={{ maxWidth: "5rem" }}
                variant="contained"
                loading={btnLoading}
              >
                Submit
              </LoadingButton>
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion>
    );
  })
  return (
    <>
      {accordions}
    </>
  )
};

export default CampAccordions;