import { useContext } from "react";
import { Breadcrumbs, Typography, Button, Paper } from "@mui/material";
import { Stack } from "@mui/system";
import { fileRequest } from "../../../Helpers/httpRequests";
import { DataContext } from "../../../Context/dataContext";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../Redux/app/hooks";

const ServiceManager = () => {
  // ----- Context -----
  const { accessToken } = useContext(DataContext);
  const darkmode = useAppSelector((state) => state.darkmode);
  const customer = useAppSelector((state) => state.customer);

  // ----- States -----
  const btnStyles = {
    maxWidth: "20rem",
  };

  const spanStyles = {
    color: darkmode ? "#90caf9" : "#0071b1",
    cursor: "pointer",
    transition: `color ${0.2}s`,
    "&:hover": {
      color: "#0071b1",
    },
  };

  // ----- Variable -----

  // ----- Functions -----

  // ----- On page load -----
  const download = async () => {
    await fileRequest(
      `/react/api/${customer.uuid}/endpoints/installer/download`,
      accessToken,
      "ServiceManagerSetup.msi",
    );
  };

  return (
    <Stack spacing={3}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Config</Typography>
        <Typography color="text.primary">Service Manager</Typography>
      </Breadcrumbs>

      {/* Page Title */}

      <Paper sx={{ p: 3 }}>
        <Stack spacing={2}>
          <Typography>
            The service manager creates a Windows service that can check for
            pending simulations. When a pending campaign is created for that
            endpoint the service manager starts the specified BlindSPOT agent,
            runs the campaign(s), and then terminates the agent.
          </Typography>

          <Typography>
            The service manager allows you to run scheduled campaigns without
            having to manually start the BlindSPOT agent on the endpoint. During
            the install you will be given the option to configure which agent to
            run when a campaign is pending. Note: You will need an active API
            key to connect the service manager to your account.
          </Typography>

          <Typography>
            To retrieve a API KEY, head over to your{" "}
            <Link style={spanStyles} to="/profile">
              profile
            </Link>{" "}
            and click the button that says, "Generate New KEY".
          </Typography>
          <Button variant="contained" onClick={download} sx={btnStyles}>
            Download Service Manager
          </Button>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default ServiceManager;
