import React, { useEffect, useContext, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DataContext } from "../../../../Context/dataContext";
import { getRequest } from "../../../../Helpers/httpRequests";
import SankeyDiagram from "../../../../Components/Reporting/SankeyDiagram/SankeyDiagram";
import { useAppSelector } from "../../../../Redux/app/hooks";

export const SankeyProject = (props) => {
  const { uuid } = props;
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  const [sankeyData, setSankeyData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/projectreport/${uuid}/sankey`,
        accessToken
      );
      if (res.status === 200) {
        setSankeyData(res.data.sankey);
      }
    };
    getData();
  }, [accessToken, current_customer, uuid]);

  return sankeyData.length > 0 ? (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="sankey-accordion"
        id="sankey-accordion"
      >
        <Typography
          variant="h5"
          sx={{ marginTop: "auto", marginBottom: "auto" }}
        >
          Project Sankey chart
        </Typography>
      </AccordionSummary>
      <div>
        <SankeyDiagram sankey_data={sankeyData} includeUnassigned={true} />
      </div>
    </Accordion>
  ) : (
    <CircularProgress />
  );
};
