import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../../Context/dataContext";
import { postRequest } from "../../../../Helpers/httpRequests";
import {
  Box,
  Stack,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../Redux/app/hooks";

const ProxySettingsForm = ({ setReload, setOpen, type, proxy }) => {
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  const [proxyData, setProxyData] = useState({
    name: "",
    address: "",
    username: "",
    password: "",
    showPassword: false,
  });

  const [submitBtnColor, setSubmitBtnColor] = useState("primary");
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [submitBtnText, setSubmitBtnText] = useState("Submit");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProxyData({
      ...proxyData,
      [name]: value,
    });
  };

  const togglePasswordVisibility = () => {
    setProxyData({
      ...proxyData,
      showPassword: !proxyData.showPassword,
    });
  };

  const handleSubmit = async () => {
    setSubmitBtnLoading(true);
    const data = { ...proxyData };

    const action = type === "add" ? "Add" : "Edit";

    try {
      toast.info(`Attempting to ${action} Proxy ${proxyData.name}`);
      const res = await postRequest(
        type === "add"
          ? `/react/api/${current_customer.uuid}/config/proxies/add`
          : `/react/api/${current_customer.uuid}/config/proxies/edit/${proxy.id}`,
        accessToken,
        data
      );

      if (res.status === 200) {
        toast.success(`Proxy Setting ${action.toLowerCase()}ed successfully`);
        setOpen(false);
        setReload((current) => !current);
      } else {
        throw new Error(`Failed to ${action.toLowerCase()} proxy setting`);
      }
    } catch (error) {
      toast.error(`Error - ${error.message}`);
      setSubmitBtnColor("error");
      setSubmitBtnText(`Error - Try again`);
    } finally {
      setSubmitBtnLoading(false);
    }
  };

  useEffect(() => {
    if (proxy) {
      setProxyData(proxy);
    }
  }, [proxy, current_customer, accessToken]);

  return (
    <>
      <Typography variant="h4">
        {type === "add" ? "Create New Proxy" : `Edit Proxy ${proxyData.name}`}
      </Typography>

      <Box>
        <Box
          className="left-box"
          sx={{ flex: 1, paddingRight: 1, marginTop: 1 }}
        >
          <Stack spacing={1.5}>
            <TextField
              type="text"
              label="Name"
              name="name"
              helperText=""
              value={proxyData.name}
              onChange={handleChange}
            />

            <TextField
              type="text"
              label="Address"
              name="address"
              helperText=""
              value={proxyData.address}
              onChange={handleChange}
            />

            <TextField
              type="text"
              label="User Name"
              name="username"
              helperText=""
              value={proxyData.username}
              onChange={handleChange}
            />

            <TextField
              label="Password"
              type={proxyData.showPassword ? "text" : "password"}
              name="password"
              value={proxyData.password}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={togglePasswordVisibility}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {proxyData.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <LoadingButton
              disabled={
                proxyData.name === "" ||
                proxyData.address === "" ||
                proxyData.username === "" ||
                proxyData.password === ""
              }
              variant="contained"
              loading={submitBtnLoading}
              onClick={handleSubmit}
              color={submitBtnColor}
            >
              {submitBtnText}
            </LoadingButton>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default ProxySettingsForm;
