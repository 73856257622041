import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { FaTrain } from "react-icons/fa";
import { LinkWithCustomerUUID } from "../../../Components/Links";
import { useAppSelector } from "../../../Redux/app/hooks";
const iconColor = "#BDCCD4";
const iconSize = "16px";

const Beta = ({ open }) => {
  const user = useAppSelector((state) => state.user);

  const data = {
    navHeader: "BETA",
    links: [
      {
        icon: <FaTrain color={iconColor} size={iconSize} />,
        text: "Simulation Builder",
        to: "/beta/simulation-builder",
      },
    ],
  };

  return (
    <div>
      {open ? (
        <Typography
          variant="h6"
          px={1}
          mt={3}
          sx={{ fontSize: 10, color: "#BDCCD4" }}
        >
          {data.navHeader}
        </Typography>
      ) : null}
      {data.links.map((link) => {
        return (
          <ListItem key={link.text} disablePadding sx={{ display: "block" }}>
            <LinkWithCustomerUUID
              to={link.to}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 32,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
                disabled={
                  user.current_customer.license_type === "av_only" ||
                  user.current_customer.license_type === "consumption_smv"
                }
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {link.icon}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "12px" }}
                  primary={link.text}
                  sx={{ opacity: open ? 1 : 0, color: "white" }}
                />
              </ListItemButton>
            </LinkWithCustomerUUID>
          </ListItem>
        );
      })}
    </div>
  );
};

export default Beta;
