import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography
} from "@mui/material";
import React, { useContext } from "react";
import { AVDetailsContext } from "../ContextProvider/ContextProvider";

const AVMissingSimAction = () => {
  const { missedActions } = useContext(AVDetailsContext);
  const [detailsExpanded, setDetailsExpanded] = React.useState(false);

  const handleDetailsChange = (panel) => (_event, isExpanded) => {
    setDetailsExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ flexDirection: "row-reverse" }}
      >
        <Typography variant="h5">Actions Not Executed in Exercise</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack>
          {missedActions?.map((action, index) => {
            return (
              <div key={action.uuid}>
                <Accordion
                  expanded={detailsExpanded === `panelDetails${index}`}
                  onChange={handleDetailsChange(`panelDetails${index}`)}
                  sx={{ marginTop: "16px" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panelDetails${index}bh-content`}
                    id={`panelDetails${index}bh-header`}
                    sx={{ flexDirection: "row-reverse" }}
                  >
                    <Typography sx={{ width: "33%", flexGrow: 1 }}>
                      <b>Step {action.step}: </b>
                      {action.name}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* Add the details you want to display here */}
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default AVMissingSimAction;