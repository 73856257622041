import React from "react";
import { Route, Routes } from "react-router-dom";
import SimBuilderHome from "../../Pages/Administration/SimulationBuilder/SimBuilderHome";
import SimulationBuilder from "../../Pages/Administration/SimulationBuilder/SimulationBuilder";

const BetaRoutes = () => {
  return (
    <Routes>
      <Route path="simulation-builder" element={<SimBuilderHome />} />
      <Route
        path="simulation-builder/build/:sim_uuid"
        element={<SimulationBuilder />}
      />
      <Route path="simulation-builder/build" element={<SimulationBuilder />} />
    </Routes>
  );
};

export default BetaRoutes;
