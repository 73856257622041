import {
  Stack,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const architectures = [
  {
    value: "64",
    name: "64-bit",
  },
  {
    value: "32",
    name: "32-bit",
  },
];

const linuxExecMethod = [
  {
    value: "exe64",
    name: "64-bit Executable",
  },
  {
    value: "exe32",
    name: "32-bit Executable",
  },
];

const execMethod = [
  {
    value: "exe",
    name: "Binary Executable",
  },
  {
    value: "dll",
    name: "Signed Proxy Execution",
  },
  {
    value: "sh",
    name: "Remote Process Injection",
  },
  {
    value: "local",
    name: "Local Process Injection",
  },
];

const piMethods = [
  "CreateRemoteThread",
  "NtCreateRemoteThread",
  "QueueUserAPC",
  "NtQueueUserAPC",
  "RtlCreateUserThread",
];

const AlertSimulationFrom = ({
  formData,
  setFormData,
  simulation,
  piTargets,
}) => {
  return (
    <Stack spacing={2}>
      <Typography variant="h6">Scheduled Exercise Execution Options</Typography>

      {/* Architecture */}
      <FormControl>
        <InputLabel required>Architecture</InputLabel>
        <Select
          value={formData.architecture}
          onChange={(e) =>
            setFormData((prev) => ({
              ...prev,
              architecture: e.target.value,
            }))
          }
          label="Architecture"
        >
          {architectures.map((arch) => {
            return (
              <MenuItem key={arch.value} value={arch.value}>
                {arch.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      {/* Execution Method */}
      <FormControl>
        <InputLabel required>Execution Method</InputLabel>
        <Select
          value={formData.execution}
          onChange={(e) => {
            setFormData((prev) => ({
              ...prev,
              execution: e.target.value,
              exec_name: e.target.value.name,
            }))
          }}
          label="Execution Method"
        >
          {simulation.platform === "linux"
            ? linuxExecMethod.map((exec) => {
                return (
                  <MenuItem key={exec.value} value={exec}>
                    {exec.name}
                  </MenuItem>
                );
              })
            : execMethod.map((exec) => {
                return (
                  <MenuItem key={exec.value} value={exec.value}>
                    {exec.name}
                  </MenuItem>
                );
              })}
        </Select>
      </FormControl>
      {/* PI stuff */}
      {/* PI method */}
      {formData.execution?.includes("sh") && (
        <>
          <FormControl>
            <InputLabel required>Process Injection Method</InputLabel>
            <Select
              value={formData.piMethod}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  piMethod: e.target.value,
                }))
              }
              label="Process Injection Method"
            >
              {piMethods &&
                piMethods.map((exec) => {
                  return (
                    <MenuItem value={exec} key={exec}>
                      {exec}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel required>Process Injection Target</InputLabel>
            <Select
              value={formData.piTarget}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  piTarget: e.target.value,
                }))
              }
              label="Process Injection Target"
            >
              {piTargets &&
                piTargets.map((exec) => {
                  return (
                    <MenuItem key={exec.uuid} value={exec}>
                      {exec.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </>
      )}
    </Stack>
  );
};

export default AlertSimulationFrom;
