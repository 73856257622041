import React from "react";
import Provider from "./Provider/Provider";
import { AdminFocusContent } from "./AdminFocusContent";

const AdminFocus = () => {
  return (
    <Provider>
      <AdminFocusContent />
    </Provider>
  );
};

export default AdminFocus;
