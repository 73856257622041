import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Typography,
  Chip,
} from "@mui/material";
import moment from "moment";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import {
  FaApple,
  FaAws,
  FaBan,
  FaChessBoard,
  FaCog,
  FaExclamationTriangle,
  FaEyeSlash,
  FaFileCode,
  FaGoogle,
  FaLinux,
  FaMicrosoft,
  FaPlus,
  FaWindows,
} from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import TextButton from "../../../../Components/Buttons/TextButton";
import DataGridWithStyles from "../../../../Components/DataGrid/DataGridWithStyles";
import CampaignForm from "../../../../Components/Forms/Simulations/CampaignForm";
import ExecutionThresholdSettingsForm from "../../../../Components/Forms/Simulations/ExecutionThresholdSettingsForm/ExecutionThresholdSettingsForm";
import { DataContext } from "../../../../Context/dataContext";
import { getRequest, postRequest } from "../../../../Helpers/httpRequests";
import { useAppSelector } from "../../../../Redux/app/hooks";
import ActivityTimeline from "../../../Reports/ReportsCampaignDetails/ActivityTimeline";
import ChangeGlobalStatesModal from "./ChangeGobalStatesModal";

const SimulationDetail = () => {
  // ----- STATES && CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector((state) => state.customer);
  const user = useAppSelector((state) => state.user);
  const { simulationUUID } = useParams();
  const [techTableRows, setTechTableRows] = useState([]);
  const [techTableColumns, setTechTableColumns] = useState([]);
  const [simactionTableRows, setSimactionTableRows] = useState([]);
  const [contributors, setContributors] = useState([]);
  const [simactionTableColumns, setSimactionTableColumns] = useState([]);
  const [techset, setTech] = useState(false);
  const [timeline, setTimeline] = useState([]);
  const [modal, setModal] = useState({
    open: false,
    content: null,
  });
  const [changeGlobalStates, setChangeGlobalStates] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    maxHeight: "90%",
    maxWidth: "25%",
    overflowY: "auto",
  };

  const [data, setData] = useState({
    simulation: {},
    simactions: [],
    techniques: [],
    securitytools: [],
  });

  // ----- VARIABLES -----
  const role = useAppSelector((state) => state.user.role);

  const defaultModalState = {
    open: false,
    content: null,
  };

  // ----- FUNCTIONS -----

  const populatetechniques = (techniques) => {
    const columns = [
      {
        field: "name",
        headerName: "Name",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "attackid",
        headerName: "ATT&CK® ID",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "tactic",
        headerName: "Tactics",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "platforms",
        headerName: "Platforms",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {
          let rowdata = [];
          for (let i = 0; i < params.row.platforms.length; i++) {
            if (params.row.platforms[i] === "Windows") {
              rowdata.push(
                <FaWindows key={`${params.row.id}-windows`} title="Windows" />,
              );
            }
            if (params.row.platforms[i] === "Linux") {
              rowdata.push(
                <FaLinux key={`${params.row.id}-linux`} title="Linux" />,
              );
            }
            if (params.row.platforms[i] === "macOS") {
              rowdata.push(
                <FaApple key={`${params.row.id}-macos`} title="MacOS" />,
              );
            }
            if (params.row.platforms[i] === "AWS") {
              rowdata.push(<FaAws key={`${params.row.id}-aws`} title="AWS" />);
            }
            if (params.row.platforms[i] === "GCP") {
              rowdata.push(
                <FaGoogle key={`${params.row.id}-gcp`} title="GCP" />,
              );
            }
            if (params.row.platforms[i] === "Azure") {
              rowdata.push(
                <FaMicrosoft key={`${params.row.id}-azure`} title="Azure" />,
              );
            }
          }
          return rowdata;
        },
      },
    ];
    const rows = techniques.map((tech) => {
      let tactics = [];
      for (let i = 0; i < tech.tactic.length; i++) {
        tactics.push(tech.tactic[i].name);
      }

      let platforms = [];
      for (let i = 0; i < tech.x_mitre_platforms?.length; i++) {
        platforms.push(tech.x_mitre_platforms[i].name);
      }
      return {
        id: tech.uuid,
        name: tech.name,
        attackid: tech.attack_id,
        tactic: tactics,
        platforms: platforms,
      };
    });
    setTechTableRows(rows);
    setTechTableColumns(columns);
    setTech(true);
  };

  const changeThreshold = useCallback(
    async (mode, action = null) => {
      let postData;
      let res;
      // mode can be "single", "default", or "clear"
      let threshold;
      switch (mode) {
        case "single":
          if (action.blocked) {
            threshold = "blocked";
          } else if (action.alerted) {
            threshold = "alerted";
          } else if (action.logged) {
            threshold = "logged";
          } else if (action.nothing) {
            threshold = "nothing";
          } else {
            threshold = "clear";
          }
          postData = {
            mode: mode,
            action_uuid: action.uuid,
            threshold: threshold,
            securityTool: action.security_tool?.uuid,
          };
          res = await postRequest(
            `/react/api/${current_customer.uuid}/simulations/updatesimthreshold`,
            accessToken,
            postData,
            false,
          );
          if (res.status === 200) {
            // So I want the front end to change first and not necessarily wait for the back end to confirm it's successful
            // below is code if you want to wait for success confirmation. Will need changes elsewhere
            // let actions = data.simactions
            // const idx = actions.findIndex((obj) => obj.uuid === action.uuid)
            // actions[action.step] = res.data.sim_action
            // setData({ ...data, simactions: actions })
          }
          break;
        default:
          postData = {
            mode: mode,
            simulation_uuid: simulationUUID,
          };
          res = await postRequest(
            `/react/api/${current_customer.uuid}/simulations/updatesimthreshold`,
            accessToken,
            postData,
            false,
          );
          if (res.status === 200) {
            setData({ ...data, simactions: res.data.sim_actions });
          }
          break;
      }
    },
    [accessToken, data, simulationUUID, current_customer],
  );

  // TODO need to add in security tools drop down and pass them back in seralizer
  // Add Button Group

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getSimDetails = async () => {
      // Get simulation detail
      const simres = await getRequest(
        `/react/api/${current_customer.uuid}/simulations/${simulationUUID}`,
        accessToken,
      );

      if (simres.status === 200) {
        setData(simres.data);
        setTimeline(simres.data.timeline);
        setContributors(simres.data.contributors);
        populatetechniques(simres.data.techniques);
      }
    };

    getSimDetails();
  }, [current_customer, accessToken, simulationUUID, changesMade]);

  const NameColumn = ({ row }) => {
    const [showNote, setShowNote] = useState(false);

    const toggleNote = () => {
      setShowNote(!showNote);
    };

    return (
      <div style={{ overflow: "hidden", minWidth: 0 }}>
        <Box>
          {row.name}
          {row.note && (
            <>
              <Button
                sx={{
                  marginLeft: 2,
                  size: "small",
                  padding: 0,
                  minWidth: 0,
                }}
                onClick={toggleNote}
              >
                {showNote ? "Hide Note" : "Show Note"}
              </Button>
              {showNote && <div>Note: {row.note}</div>}
            </>
          )}
        </Box>
      </div>
    );
  };

  useEffect(() => {
    const populatesimactions = (actions, securityTools) => {
      const columns = [
        {
          field: "name",
          headerName: "Name",
          minWidth: 400,
          flex: 1,
          renderCell: (params) => <NameColumn row={params.row} />,
        },
        {
          field: "execType",
          headerName: "Execution Type",
          maxWidth: 150,
          flex: 1,
        },
        {
          field: "securityTool",
          headerName: "Security Tool",
          maxWidth: 250,
          flex: 1,
          renderCell: (params) => {
            let securityTool = params.row.securityTool;
            let row = params.row;
            const handleChange = async (event) => {
              params.row.securityTool = event.target.value;
              securityTool = event.target.value;

              let securitytooldata = securityTools.find(
                (tool) => tool.name === securityTool,
              );
              let actions = data.simactions;
              const action = data.simactions.find((obj) => obj.uuid === row.id);
              actions[action.step].security_tool = securitytooldata;
              setData({ ...data, actions });
              changeThreshold("single", actions[action.step]);
            };
            return (
              <FormControl fullWidth>
                <Select
                  disabled={role === "View_Only"}
                  style={{ opacity: role === "View_Only" ? 0.5 : 1 }}
                  labelId="security-tool-select"
                  id={"security-tool-select" + row.id}
                  value={securityTool.name || ""}
                  onChange={handleChange}
                >
                  {securityTools.map((tool) => {
                    return (
                      <MenuItem key={tool.uuid} value={tool.name}>
                        {tool.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            );
          },
        },
        {
          field: "threshold",
          headerName: "Threshold",
          maxWidth: 500,
          flex: 1,
          renderCell: (params) => {
            let row = params.row.threshold;
            const handleThresholdClick = async (row, threshold) => {
              let actions = data.simactions;
              let action = actions[row.index];
              action.blocked =
                threshold === "blocked" && !action.blocked ? true : false;
              action.alerted =
                threshold === "alerted" && !action.alerted ? true : false;
              action.logged =
                threshold === "logged" && !action.logged ? true : false;
              action.nothing =
                threshold === "nothing" && !action.nothing ? true : false;

              setData({ ...data, actions });

              changeThreshold("single", action);
            };
            return (
              <ButtonGroup
                variant="outlined"
                aria-label="outlined button group"
                size="large"
                fullWidth
              >
                <Button
                  disabled={role === "View_Only"}
                  color="success"
                  variant={row.blocked ? "contained" : "outlined"}
                  onClick={(e) => {
                    e.persist();
                    handleThresholdClick(params.row, "blocked");
                  }}
                >
                  <FaBan />
                </Button>
                <Button
                  disabled={role === "View_Only"}
                  color="primary"
                  variant={row.alerted ? "contained" : "outlined"}
                  onClick={(e) => {
                    e.persist();
                    handleThresholdClick(params.row, "alerted");
                  }}
                >
                  <FaExclamationTriangle />
                </Button>
                <Button
                  disabled={role === "View_Only"}
                  color="warning"
                  variant={row.logged ? "contained" : "outlined"}
                  onClick={(e) => {
                    e.persist();
                    handleThresholdClick(params.row, "logged");
                  }}
                >
                  <FaFileCode />
                </Button>
                <Button
                  disabled={role === "View_Only"}
                  color="error"
                  variant={row.nothing ? "contained" : "outlined"}
                  onClick={(e) => {
                    e.persist();
                    handleThresholdClick(params.row, "nothing");
                  }}
                >
                  <FaEyeSlash />
                </Button>
              </ButtonGroup>
            );
          },
        },
        {
          field: "clean_up",
          headerName: "Clean Up",
          maxWidth: 100,
          flex: 1,
          renderCell: (params) => {
            let row = params.row;
            const handleCleanUpClick = async (row) => {
              let actions = data.simactions;
              let action = actions[row.index];
              action.clean_up = !action.clean_up;
              setData({ ...data, actions });
              changeThreshold("single", action);

              await postRequest(
                `/react/api/${current_customer.uuid}/simulations/${action.uuid}/changecleanup`,
                accessToken,
                { clean_up: action.clean_up },
              );
            };
            return (
              // checkbox that can be clicked to change the clean up status if the user is not view only
              <input
                type="checkbox"
                checked={row.clean_up}
                onChange={(e) => handleCleanUpClick(params.row)}
                disabled={role !== "Provider_Admin"}
              />
            );
          },
        },
        {
          field: "ttps",
          headerName: "TTPS",
          maxWidth: 250,
          flex: 1,
          renderCell: (params) => {
            const ttps = params.row.ttps;

            return (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  overflow: "auto",
                  overflowY: "hidden",
                }}
              >
                <Stack direction="row" spacing={1}>
                  {ttps.length > 0 &&
                    ttps.map((link) => (
                      <Chip
                        key={link}
                        label={link}
                        sx={{ whiteSpace: "nowrap" }}
                      />
                    ))}
                </Stack>
              </Box>
            );
          },
        },
      ];

      const rows = actions.map((action, index) => {
        return {
          id: action.uuid,
          step: action.step,
          name: action.name,
          note: action.note,
          execType: action.execution_type,
          securityTool: action.security_tool || "",
          threshold: action,
          index: index,
          clean_up: action.clean_up || false,
          ttps: JSON.parse(action.procedure.tags.replace(/'/g, '"')) || [],
        };
      });
      setSimactionTableRows(rows);
      setSimactionTableColumns(columns);
    };

    const setActionTable = async () => {
      populatesimactions(data.simactions, data.securitytools);
    };
    if (data.simactions.length !== 0) {
      setActionTable();
    }
  }, [
    data,
    simulationUUID,
    accessToken,
    changeThreshold,
    role,
    current_customer,
  ]);

  return (
    <Stack spacing={1}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Simulations</Typography>
        <Link to={`/${current_customer.uuid}/simulations/list`}>
          Simulation Library
        </Link>
        <Typography color="text.primary">Simulation Details</Typography>
      </Breadcrumbs>
      {data && techset && (
        <Fragment>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Typography component={"span"} fontWeight={"bold"} variant="h4">
              {data.simulation.name}
            </Typography>
            <Typography
              component={"span"}
              className="text-links"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                marginBottom: 2,
              }}
            >
              <TextButton
                role={role}
                onClick={() => {
                  setModal({
                    open: true,
                    content: (
                      <CampaignForm
                        simulation={data.simulation}
                        setModalOpen={() => setModal(defaultModalState)}
                        onFinish={() => setModal(defaultModalState)}
                        customer={current_customer}
                      />
                    ),
                  });
                }}
                tooltip="New Campaign"
                icon={FaPlus}
              />
              <Link
                to={`/${current_customer.uuid}/reporting/matrix/${data.simulation.uuid}`}
              >
                <TextButton tooltip="Technique Matrix" icon={FaChessBoard} />
              </Link>
              <TextButton
                role={role}
                onClick={() => {
                  setModal({
                    open: true,
                    content: <ExecutionThresholdSettingsForm />,
                  });
                }}
                tooltip="Change Execution Threshold Settings"
                icon={FaCog}
              />
            </Typography>
          </Box>
          {!data.simplan.threshold_set && (
            <Alert severity="error">
              Thresholds are not set on this Simulation. This may affect scoring
              !
            </Alert>
          )}
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper sx={{ padding: 1 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        Simulation Details
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={0.5}>
                          <Typography variant="s1" sx={{ fontWeight: "bold" }}>
                            Name:
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="s1">
                            {data.simulation.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography variant="s1" sx={{ fontWeight: "bold" }}>
                            Category:
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="s1">
                            {data.simulation.category.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography variant="s1" sx={{ fontWeight: "bold" }}>
                            Platform:
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography variant="s1">
                            {data.simulation.platform}
                          </Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                          <Typography variant="s1" sx={{ fontWeight: "bold" }}>
                            Published Date:
                          </Typography>
                        </Grid>
                        <Grid item xs={2.5}>
                          <Typography
                            variant="s1"
                            style={{ whiteSpace: "pre-line" }}
                          >
                            {data.simulation.published_date
                              ? moment(data.simulation.published_date).format(
                                  "MMMM D, YYYY",
                                )
                              : moment(data.simulation.created_date).format(
                                  "MMMM D, YYYY",
                                )}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography variant="s1" sx={{ fontWeight: "bold" }}>
                            Description:
                          </Typography>
                        </Grid>
                        <Grid item xs={11}>
                          <Typography
                            variant="s1"
                            style={{ whiteSpace: "pre-wrap" }}
                          >
                            {data.simulation.description}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <ActivityTimeline timeline={timeline} />
              <Grid item xs={12}>
                <Paper sx={{ padding: 1 }}>
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      Simulation Steps and Thresholds{" "}
                    </Typography>
                    <ButtonGroup
                      aria-label="outlined button group"
                      size="large"
                    >
                      {user.superuser === true && (
                        <Button
                          variant="contained"
                          sx={{ marginX: "8px", paddingX: "8px" }}
                          onClick={() => setChangeGlobalStates(true)}
                        >
                          Change global states
                        </Button>
                      )}
                      <Button
                        disabled={role === "View_Only"}
                        style={{ opacity: role === "View_Only" ? 0.5 : 1 }}
                        variant="contained"
                        sx={{ marginX: "8px", paddingX: "8px" }}
                        onClick={() => changeThreshold("default")}
                      >
                        Restore Defaults
                      </Button>
                      <Button
                        disabled={role === "View_Only"}
                        style={{ opacity: role === "View_Only" ? 0.5 : 1 }}
                        variant="contained"
                        sx={{ marginX: "8px", paddingX: "8px" }}
                        onClick={() => changeThreshold("clear")}
                      >
                        Clear
                      </Button>
                    </ButtonGroup>
                  </Stack>
                  <DataGridWithStyles
                    name="sim-actions"
                    autoHeight
                    getRowHeight={() => "auto"}
                    rows={simactionTableRows}
                    columns={simactionTableColumns}
                  />
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper sx={{ padding: 1 }}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Techniques in This Simulation
                  </Typography>
                  <DataGridWithStyles
                    name="sim-teqs"
                    autoHeight
                    rows={techTableRows}
                    columns={techTableColumns}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper sx={{ padding: 1 }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", marginBottom: 1 }}
                  >
                    Contributors for this Simulation
                  </Typography>
                  {contributors.length > 0 ? (
                    contributors.map((contributor) => (
                      <Box key={contributor.uuid} sx={{ marginBottom: 1 }}>
                        <li>
                          {contributor.contributor.first}{" "}
                          {contributor.contributor.last}{" "}
                          {moment(contributor.modification_date).format(
                            "MMMM D, YYYY h:mm A",
                          )}
                        </li>
                      </Box>
                    ))
                  ) : (
                    <Typography>Blindspot Content Team</Typography>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Fragment>
      )}

      <Modal
        open={changeGlobalStates}
        onClose={() => setChangeGlobalStates(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ChangeGlobalStatesModal
          data={data}
          setChangesMade={setChangesMade}
          closeModal={() => setChangeGlobalStates(false)}
        />
      </Modal>
      <Modal
        open={modal.open}
        onClose={() => setModal(defaultModalState)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>{modal.content}</Box>
      </Modal>
    </Stack>
  );
};

export default SimulationDetail;
