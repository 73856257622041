import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Form } from "../../../../../FormBuilder";
import CustomSelectSearch from "../../../../../FormBuilder/fields/CustomSelectSearch";
import TextField from "../../../../../FormBuilder/fields/TextField";

export const ProjectTestPlanModal = (props) => {
  const { mode, testplan, onComplete, simulations } = props;
  const [simNames, setSimNames] = useState([]);

  useEffect(() => {
    if (simulations?.length > 0) {
      const temp = simulations.map((sim) => {
        return [sim.id, sim.name, sim?.category];
      });
      setSimNames(temp);
    }
  }, [simulations]);

  return (
    <Form
      method={mode === "edit" ? "PATCH" : "POST"}
      path={
        mode === "edit"
          ? `/api/v2/project-test-plans/${testplan.id}`
          : `/api/v2/project-test-plans`
      }
      onSuccess={onComplete}
      defaultValues={{ ...testplan }}
    >
      <Stack spacing={2}>
        <Typography variant="h4">
          {mode === "edit" ? "Edit" : "Add"} Project Test Plan
        </Typography>

        <Stack>
          <TextField name="name" required />
          <CustomSelectSearch
            name="simulations"
            options={simNames}
            defaults={testplan?.simulations}
          />

          <Box className="flex justify-center">
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Form>
  );
};
