import React, { useEffect, useState, useContext } from "react";
import {
  Breadcrumbs,
  Box,
  Chip,
  CircularProgress,
  Typography,
  IconButton,
} from "@mui/material";
import { Stack } from "@mui/system";
import { getRequest } from "../../../Helpers/httpRequests";
import { DataContext } from "../../../Context/dataContext";
import FormModal from "../../../Components/Modals/FormModal";
import AdminEditAttackForm from "../../../Components/Forms/Administration/AdminEditAttackForm";
import { FaEdit } from "react-icons/fa";
import { STYLES } from "./styles";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import { useAppSelector } from "../../../Redux/app/hooks";

const AdminAttackRef = () => {
  // ----- CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  // ----- STATES -----
  // for http response
  const [isLoading, setIsLoading] = useState(true);

  // Modal
  const [open, setOpen] = useState(false);

  // Form
  const [formData, setFormData] = useState({
    name: "",
    attack_id: "",
    description: "",
    mitigations: "",
    detection: "",
    tactic: [],
    x_mitre_data_sources: [],
    x_mitre_platforms: [],
  });

  /**
   * contains the datagrid props
   */
  const [dataGridData, setDataGridData] = useState({
    rows: [],
    columns: [],
    pageSize: 10,
  });

  // ----- VARIABLES -----

  // ----- Functions -----

  /**
   * This function handles the creation of columns and rows for MUI Datagrid. Running this function will could the MUI-Datagrid to re-mount.
   * @param {} param
   */
  const populateDataGrid = (refs) => {
    const columns = [
      {
        field: "name",
        headerName: "Name",
        minWidth: 100,
        flex: 3,
      },
      {
        field: "attack_id",
        headerName: "ATT&CK® ID",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "is_sub",
        headerName: "Sub Technique?",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "tactic",
        headerName: "Tactics",
        minWidth: 100,
        flex: 3,
        renderCell: (params) => {
          const tactics = params.row.tactic;
          return (
            <Stack direction="row" spacing={1}>
              {tactics.length > 0 &&
                tactics.map((tactic, index) => {
                  return (
                    <Chip
                      key={`${params.row.id}-${tactic.name}-${index}`}
                      label={tactic.name}
                      size="small"
                    />
                  );
                })}
            </Stack>
          );
        },
      },
      {
        field: "",
        headerName: "Actions",
        midWidth: 100,
        flex: 0,
        renderCell: (params) => {
          const handleOpen = (e) => {
            e.stopPropagation();
            setFormData(params.row);
            setOpen(true);
          };
          return (
            <IconButton onClick={(e) => handleOpen(e)}>
              <FaEdit />
            </IconButton>
          );
        },
      },
    ];

    const rows = refs.map((element) => {
      return {
        id: element.uuid,
        attack_id: element.attack_id,
        description: element.description || "",
        mitigations: element.mitigations || "",
        detection: element.detection || "",
        x_mitre_data_sources: element.x_mitre_data_sources,
        x_mitre_platforms: element.x_mitre_platforms,
        name: element.name,
        is_sub: element.is_sub,
        tactic: element.tactic,
      };
    });

    setDataGridData((data) => ({
      ...data,
      rows: rows,
      columns: columns,
    }));
  };

  // ----- On page load -----

  useEffect(() => {
    const getRefs = async () => {
      const res = await getRequest(
        `/react/api/admin/attack-references`,
        accessToken
      );

      if (res.status === 200) {
        populateDataGrid(res.data.attackReferences);
        setIsLoading(false);
      } else {
        console.log(
          "%cerror AdminAttackRef.jsx useEffect()",
          "color: red; display: block; width: 100%;",
          "Reponse status not 200"
        );
      }
    };

    getRefs();
  }, [accessToken, current_customer, open]);

  return (
    <>
      <Stack spacing={3} sx={{ height: "calc(100% - 3.5rem)", mb: "3rem" }}>
        {/* Breadcrumbs */}
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="text.primary">Administration</Typography>
          <Typography color="text.primary">Attack Reference Library</Typography>
        </Breadcrumbs>
        {/* Page Title */}
        <Typography variant="h3">Attack Reference Library</Typography>
        {/* Template Grid */}
        {isLoading && (
          <Box sx={STYLES.loadingContainer}>
            <CircularProgress />
          </Box>
        )}
        {!isLoading && (
          <DataGridWithStyles
            name="admin-attack-ref"
            paper
            autoHeight
            rows={dataGridData.rows}
            columns={dataGridData.columns}
          />
        )}
      </Stack>
      <FormModal open={open} setOpen={setOpen}>
        <AdminEditAttackForm
          formData={formData}
          setFormData={setFormData}
          setOpen={setOpen}
        />
      </FormModal>
    </>
  );
};

export default AdminAttackRef;
