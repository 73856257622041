import React, { useState, useEffect, useContext, useRef } from "react";
import { Breadcrumbs, Typography, Paper } from "@mui/material";
import { Stack } from "@mui/system";
import { getRequest } from "../../Helpers/httpRequests";
import { DataContext } from "../../Context/dataContext";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  updateUserEulaAgreement,
  updateUserPrivacyAgreement,
} from "../../Redux/features/user/userSlice";
import { useAppSelector } from "../../Redux/app/hooks";

const Agreements = () => {
  // ----- Typing -----
  type Agreements = {
    eula: { name: string; agreement_text: string };
    privacy: { name: string; agreement_text: string };
  };

  const isMounted = useRef(true);
  // ----- Redux ----
  const user = useAppSelector((state) => state.user);
  const dispatch = useDispatch();
  const customer = useAppSelector((state) => state.customer);

  // ----- Context -----
  const { accessToken } = useContext(DataContext);

  // ----- States -----
  const [agreements, setAgreements] = useState<Agreements>({
    eula: {
      name: "",
      agreement_text: "",
    },
    privacy: {
      name: "",
      agreement_text: "",
    },
  });
  const [loading, setLoading] = useState({ eula: false, privacy: false });

  // ----- Variable -----
  const stackStyles = { alignItems: "center" };
  const paperStyles = { p: 3, whiteSpace: "pre-line" };
  const buttonStyles = { width: "33%" };

  // ----- Functions -----
  const AgreementDisplay = () => {
    // function for eula agree
    const agreeToEula = async () => {
      setLoading((data) => ({ ...data, eula: true }));
      const res = await toast.promise<Promise<any>>(
        getRequest(`/react/api/${customer.uuid}/agreement/eula`, accessToken),
        {
          pending: "Updating User",
          success: `Agreed to EULA`,
          error: "Something went wrong!",
        },
      );
      if (res.status === 200) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        dispatch(updateUserEulaAgreement(true));
      }
      setLoading((data) => ({ ...data, eula: false }));
    };

    // function for privacy agree
    const agreeToPrivacy = async () => {
      setLoading((data) => ({ ...data, eula: true }));
      const res = await toast.promise<Promise<any>>(
        getRequest(
          `/react/api/${customer.uuid}/agreement/privacy`,
          accessToken,
        ),
        {
          pending: "Updating User",
          success: `Agreed to Privacy`,
          error: "Something went wrong!",
        },
      );
      if (res.status === 200) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        dispatch(updateUserPrivacyAgreement(true));
      }
      setLoading((data) => ({ ...data, eula: false }));
    };

    if (!user.eula_ack) {
      return (
        <Stack spacing={3} sx={stackStyles}>
          <Typography variant="h3">{agreements.eula.name} (1/2)</Typography>
          <Paper sx={paperStyles}>{agreements.eula.agreement_text}</Paper>
          <LoadingButton
            onClick={agreeToEula}
            loading={loading.eula}
            variant="contained"
            sx={buttonStyles}
          >
            Agree
          </LoadingButton>
        </Stack>
      );
    } else if (!user.privacy_ack) {
      return (
        <Stack spacing={3} sx={stackStyles}>
          <Typography variant="h3">{agreements.privacy.name} (2/2)</Typography>
          <Paper sx={paperStyles}>{agreements.privacy.agreement_text}</Paper>
          <LoadingButton
            onClick={agreeToPrivacy}
            loading={loading.privacy}
            variant="contained"
            sx={buttonStyles}
          >
            Agree
          </LoadingButton>
        </Stack>
      );
    } else {
      return null;
    }
  };

  // ----- On page load -----

  useEffect(() => {
    const getAgreements = async () => {
      const res = await getRequest(
        `/react/api/${customer.uuid}/agreements`,
        accessToken,
      );
      if (isMounted.current) {
        if (res.status === 200) {
          setAgreements(res.data);
        }
      }
    };

    if (user.current_customer) {
      getAgreements();
    }
    return () => {
      isMounted.current = false;
    };
  }, [accessToken, user.current_customer, setAgreements, customer]);

  return (
    <Stack spacing={3}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Agreements</Typography>
      </Breadcrumbs>

      {/* Page Title */}
      <AgreementDisplay />
    </Stack>
  );
};

export default Agreements;
