import React from "react";

import { Breadcrumbs, Paper, Stack, Typography } from "@mui/material";
import ConfigReportingToolForm from "../../../Components/Forms/Config/ConfigReportingToolForm";

const ConfigReportingTool = () => {
  // ----- STATES && CONTEXT -----

  // ----- VARIABLES -----

  // ----- FUNCTIONS -----

  // ----- ON PAGE LOAD -----

  return (
    <Stack spacing={2}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Configurations</Typography>
        <Typography color="text.primary">Reporting Tool</Typography>
      </Breadcrumbs>

      {/* Form */}
      <Paper sx={{ padding: 3, maxWidth: 600 }}>
        <ConfigReportingToolForm />
      </Paper>
    </Stack>
  );
};

export default ConfigReportingTool;
