import {
  Breadcrumbs,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import ServiceProviderSettingsForm from "../../../Components/Forms/ServiceProvider/ServiceProviderSettingsForm";
import ServiceProviderUpdateCxLogo from "../../../Components/Forms/ServiceProvider/ServiceProviderUpdateCxLogo/ServiceProviderUpdateCxLogo";
import { DataContext } from "../../../Context/dataContext";
import { getRequest } from "../../../Helpers/httpRequests";
import { useAppSelector } from "../../../Redux/app/hooks";

const ServiceProviderSettings = () => {
  // ----- STATES && CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const customer = useAppSelector((state) => state.customer);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");

  // ----- VARIABLES -----

  /* ! ----- FUNCTIONS ----- */

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    // Retrieves all the users for the current cx view.
    const getCustomers = async () => {
      const res = await getRequest(
        `/react/api/${customer.uuid}/serviceprovider/settings`,
        accessToken,
      );
      const fetchedCustomers = res.data.customers;
      setCustomers(fetchedCustomers);
      if (fetchedCustomers.length > 0) {
        setSelectedCustomer(fetchedCustomers[0]);
      }
    };

    getCustomers();
  }, [accessToken, customer.uuid]);

  return (
    <Stack spacing={2}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Service Provider</Typography>
        <Typography color="text.primary">Settings</Typography>
      </Breadcrumbs>

      {/* Customer dropdown */}
      <FormControl sx={{ maxWidth: 250 }}>
        <InputLabel id="customer-label">Customer</InputLabel>
        <Select
          labelId="customer-label"
          id="customer"
          value={selectedCustomer}
          onChange={(e) => setSelectedCustomer(e.target.value)}
        >
          {customers.map((customer) => (
            <MenuItem key={customer.id} value={customer}>
              {customer.display_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Forms */}
      {selectedCustomer && (
        <>
          <Paper sx={{ padding: 3, maxWidth: 600 }}>
            <ServiceProviderSettingsForm selectedCustomer={selectedCustomer} />
          </Paper>

          <ServiceProviderUpdateCxLogo selectedCustomer={selectedCustomer} />
        </>
      )}
    </Stack>
  );
};

export default ServiceProviderSettings;
