/**
 * If you are seeing this and wondering, "what the fuck is this?"
 * Don't worry about it. This just adds a "[]" to the end of any
 * key name in the object so django can parse it.
 * ps. Don't fuck with it.
 * @param {Object} data
 * @returns Object
 */
export default function sanitizeData(data) {
  if (!data) return {};
  const cleanObject = {};
  for (const key in data) {
    if (Array.isArray(data[key])) {
      if (typeof data[key] === "object") {
        cleanObject[`${key}[]`] = sanitizeData(data[key]);
      } else {
        cleanObject[`${key}[]`] = data[key];
      }
    } else {
      cleanObject[key] = data[key];
    }
  }
  return cleanObject;
}
