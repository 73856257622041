import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  FaExclamationTriangle,
  FaEye,
  FaTachometerAlt,
  FaUserCog,
  FaUserFriends,
  FaUserPlus
} from "react-icons/fa";
import { LinkWithCustomerUUID } from "../../../Components/Links";
import { useAppSelector } from "../../../Redux/app/hooks";

const iconColor = "#BDCCD4";
const iconSize = "16px";

const ServiceProvider = ({ open }) => {
  const user = useAppSelector((state) => state.user);
  const has_av = user.primary_customer?.service_provider?.sp_has_av;
  const data = {
    navHeader: "SERVICE PROVIDER",
    links: [
      {
        icon: <FaUserFriends color={iconColor} size={iconSize} />,
        text: "Customers",
        to: "/serviceprovider/customers",
      },
      {
        icon: <FaTachometerAlt color={iconColor} size={iconSize} />,
        text: "Campaigns",
        to: "/serviceprovider/campaigns",
      },
      {
        icon: <FaUserPlus color={iconColor} size={iconSize} />,
        text: "User Management",
        to: "/serviceprovider/users",
      },
      {
        icon: <FaUserCog color={iconColor} size={iconSize} />,
        text: "Service Provider Settings",
        to: "/serviceprovider/admin",
      },
      {
        icon: <FaEye color={iconColor} size={iconSize} />,
        text: "Utilization Details",
        to: "/serviceprovider/utilization",
      },
      {
        icon: <FaExclamationTriangle color={iconColor} size={iconSize} />,
        text: "Scheduled Exercises",
        to: "/serviceprovider/scheduled-exercises",
        showIf: has_av,
      },
    ],
  };

  const filteredLinks = data.links.filter(
    (link) => link.showIf === undefined || link.showIf
  );

  return (
    <div>
      {open ? (
        <Typography
          variant="h6"
          px={1}
          mt={3}
          sx={{ fontSize: 10, color: "#BDCCD4" }}
        >
          {data.navHeader}
        </Typography>
      ) : null}
      {filteredLinks.length > 0 &&
        filteredLinks.map((link, index) => {
          return (
            <ListItem
              key={link.text + `${index}`}
              disablePadding
              sx={{ display: "block" }}
            >
              <LinkWithCustomerUUID
                to={link.to}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 32,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                  disabled={user.current_customer.license_type === "av_only"}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {link.icon}
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ fontSize: "12px" }}
                    primary={link.text}
                    sx={{ opacity: open ? 1 : 0, color: "white" }}
                  />
                </ListItemButton>
              </LinkWithCustomerUUID>
            </ListItem>
          );
        })}
    </div>
  );
};

export default ServiceProvider;
