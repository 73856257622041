import { useState, useEffect, useContext } from "react";
import { DataContext } from "../../Context/dataContext";
import sanitizeData from "./sanitizeData";
import { useCallback } from "react";
import { useMemo } from "react";
import axios from "axios";
import { useAppSelector } from "../../Redux/app/hooks";

const BASEURL =
  process.env.REACT_APP_ENVIRONMENT === "DEV" ||
  process.env.REACT_APP_ENVIRONMENT === "LOCAL"
    ? process.env.REACT_APP_DEV_BASEURL
    : process.env.REACT_APP_PROD_BASEURL;

/**
 * This functions serves to provide an easy way to send HTTP Requests via axios to the backend.
 * @param {String} path The url you are trying to hit
 * @param {Object} params A object of query params to be added to url
 * @param {Object} data A object of data you wish to send in the body of the request
 * @returns axios response
 */
const useHttpRequest = ({
  method,
  path,
  params = null,
  data = null,
  defer = false,
  rest = true,
}) => {
  const { accessToken } = useContext(DataContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [additionalSlugs, setAdditionalSlugs] = useState(null);
  const [reload, setReload] = useState(0);
  const [status, setStatus] = useState(null);
  const customer = useAppSelector((state) => state.customer);

  if (accessToken == null) throw new Error("accessToken is bad.");

  const calculateUrl = useCallback(() => {
    if (!path.startsWith("/api/v2")) return path;
    // add the current cx view after /api/v2/
    const pathArray = path.split("/");
    pathArray.splice(3, 0, customer.uuid);
    return pathArray.join("/");
  }, [path, customer]);

  let options = useMemo(
    () => ({
      method: method,
      url: `${BASEURL}${calculateUrl()}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      },
      params: params,
      data: rest ? sanitizeData(data) : data,
    }),
    [accessToken, method, data, params, rest, calculateUrl]
  );

  const fetch = useCallback((...args) => {
    if (args.length > 0) {
      setAdditionalSlugs(args.join("/"));
    } else {
      setReload((prev) => prev + 1);
    }
  }, []);

  /**
   * This useEffect is used when the defer === false
   * or fetch() is called without arguments.
   */
  useEffect(() => {
    const controller = new AbortController();
    if (defer) setLoading(false);
    const sendRequest = async () => {
      setLoading(true);
      try {
        const res = await axios.request({
          ...options,
          signal: controller.signal,
        });
        setResponse(res.data);
        setStatus(res.status);
        setError(null);
      } catch (e) {
        setError({
          message: e.message,
          response: e.response,
        });
        setStatus(e.response?.status);
      } finally {
        setLoading(false);
      }
    };
    // run on load
    if (path && method && (defer === false || reload > 0)) {
      sendRequest();
    }

    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  /**
   * This useEffect is triggered when fetch is called with arguments.
   */
  useEffect(() => {
    const controller = new AbortController();
    const sendRequest = async () => {
      setLoading(true);
      try {
        let url = options.url;
        if (url.charAt(options.url.length - 1) === "/") {
          url = url.substring(0, url.length - 1);
        }
        const res = await axios.request({
          ...options,
          signal: controller.signal,
          url: `${url}/${additionalSlugs}`,
        });
        setResponse(res.data);
        setStatus(res.status);
        setError(null);
      } catch (e) {
        setError({
          message: e.message,
          response: e.response,
        });
        setStatus(e.response.status);
      } finally {
        setLoading(false);
        setAdditionalSlugs(null);
      }
    };
    if (path && method && additionalSlugs) {
      sendRequest();
    }
    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalSlugs]);

  return { response, status, loading, error, fetch, customer };
};

export default useHttpRequest;
