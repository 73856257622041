import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  CircularProgress,
  Paper,
  Typography,
  Alert,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { DataContext } from "../../../Context/dataContext";
import { getRequest } from "../../../Helpers/httpRequests";
import { useAppSelector } from "../../../Redux/app/hooks";

const AttackTechnique = () => {
  // ----- Context -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  const { techniqueID } = useParams();

  // ----- States -----

  const [technique, setTechnique] = useState({
    loaded: false,
  });
  const [sigmaRules, setSigmaRules] = useState([]);
  const [sims, setSims] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [loadingSims, setLoadingSims] = useState(true);

  // ----- Variable -----

  // ----- Functions -----
  const handleChange = (panel) => (_event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // ----- On page load -----

  useEffect(() => {
    if (technique.name && !technique.loaded) {
      setTechnique({ ...technique, loaded: true });
    }
  }, [technique]);

  useEffect(() => {
    const getTechnique = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/report/technique/${techniqueID}`,
        accessToken
      );

      if (res.status === 200) {
        setTechnique(res.data.technique);
        setSigmaRules(res.data.sigma_rules);
      }
    };

    const getLinkedSims = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/report/technique/linked_sims/${techniqueID}`,
        accessToken
      );
      if (res.status === 200) {
        setSims(res.data.sims);
        setLoadingSims(false);
      }
    };
    getTechnique();
    getLinkedSims();
  }, [accessToken, current_customer, techniqueID]);

  return technique.loaded ? (
    <Stack spacing={3} height="100%">
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Reports</Typography>
        <Link to={`/${current_customer.uuid}/reporting/matrix`}>
          ATT&CK® Matrix
        </Link>
        <Typography color="text.primary">Technique</Typography>
      </Breadcrumbs>
      <Typography variant="h3">
        {technique.attack_id} - {technique.name}
      </Typography>
      <Box spacing={3} display="flex">
        <Paper sx={{ width: "60%", p: 3, marginRight: 4 }}>
          <Typography component="span" fontWeight="bold">
            Description
          </Typography>
          <Typography>{technique.description}</Typography>
          <Typography component="span" fontWeight="bold">
            Detection
          </Typography>
          <Typography>{technique.detection}</Typography>
          <Typography fontWeight="bold">Tactics</Typography>
          {technique.tactic.map((tactic, index) => {
            return (
              <Typography component={"span"} key={index}>
                {tactic.name} |{" "}
              </Typography>
            );
          })}
          <Typography fontWeight="bold">References</Typography>
          {technique.external_references
            ? technique.external_references.map((ref, index) => {
                return (
                  <Typography key={index}>
                    {ref.source_name} - <a href={ref.url}>{ref.url}</a>
                  </Typography>
                );
              })
            : null}
        </Paper>
        <Paper sx={{ width: "30%" }}>
          {sigmaRules.length > 0 ? (
            <Box sx={{ p: 3 }}>
              <Typography component="span" fontWeight="bold">
                Sigma Rules
              </Typography>
              {sigmaRules.map((rule, index) => {
                // gonna have the sigma rules expand here rather than have separate pages for them
                // just not done yet...
                return (
                  <Accordion
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                    key={`panel${index}`}
                  >
                    {/* Yes I am copying this from Jesse's AVDetails page. No, I don't feel bad :) */}
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index}bh-content`}
                      id={`panel${index}bh-header`}
                      sx={{ flexDirection: "row-reverse" }}
                    >
                      <Typography sx={{ width: "33%", flexGrow: 1 }}>
                        {rule.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack direction="column">
                        {rule.status ? (
                          <Typography>Status: {rule.status}</Typography>
                        ) : null}
                        {rule.id ? (
                          <Typography>Sigma ID: {rule.id}</Typography>
                        ) : null}
                        {rule.author ? (
                          <Typography>Author: {rule.author}</Typography>
                        ) : null}
                        {rule.description ? (
                          <Typography>
                            Description: {rule.description}
                          </Typography>
                        ) : null}
                        {rule.references ? (
                          <Typography>
                            References: {rule.references.join(", ")}
                          </Typography>
                        ) : null}
                        {rule.tags ? (
                          <Typography>Tags: {rule.tags.join(", ")}</Typography>
                        ) : null}
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          ) : null}
          <Box sx={{ p: 3 }}>
            <Typography component="span" fontWeight="bold">
              Linked Simulations
            </Typography>
            {!loadingSims ? (
              <>
                {sims.length > 0 ? (
                  sims.map((sim, index) => (
                    <Box key={index}>
                      <Link
                        to={`/${current_customer.uuid}/simulations/${sim.uuid}`}
                      >
                        {index + 1}. {sim.name}
                      </Link>
                    </Box>
                  ))
                ) : (
                  <Alert>No Sims Linked to this Technique</Alert>
                )}
              </>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress size={20} />
                <Typography fontSize={15} marginTop={1}>
                  Loading Linked Sims...
                </Typography>
              </Box>
            )}
          </Box>
        </Paper>
      </Box>
    </Stack>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default AttackTechnique;
