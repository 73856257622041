import { LoadingButton } from "@mui/lab";
import { Breadcrumbs, IconButton, Tab, Tabs, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { FaCheck, FaPlus, FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import TextButton from "../../../Components/Buttons/TextButton";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import AdminSimulationForm from "../../../Components/Forms/Administration/AdminSimulationForm/AdminSimulationForm";
import FormModal from "../../../Components/Modals/FormModal";
import { DataContext } from "../../../Context/dataContext";
import { getRequest } from "../../../Helpers/httpRequests";
import { useAppSelector } from "../../../Redux/app/hooks";
import { COLORS } from "../../../Styles/colors";

const AdminSimulations = () => {
  // ----- CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  // ----- STATES -----
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [reload, setReload] = useState(false);
  const [simUUID, setSimUUID] = useState("");
  const [filteredAction, setFilteredAction] = useState("all");

  /**
   * contains the datagrid props
   */
  const [dataGridData, setDataGridData] = useState({
    rows: [],
    columns: [],
  });

  // ----- VARIABLES -----
  /**
   * These are the styles for the Box inside of the modal.
   * The Modal component renders as position Absolute so we
   * have to write styles accordingly
   *
   */
  const style = {
    position: "absolute",
    marginTop: "2px",
    marginLeft: "10px",
    bgcolor: "background.paper",
    borderRadius: "5px",
    width: "40%",
    height: "88%",
  };

  // ----- Functions -----

  // Filter active and inactive customers based on the activeTab state
  const filteredDataTableRows = dataGridData.rows.filter(
    (row) =>
      (filteredAction === "active" && !row.archived) ||
      (filteredAction === "inactive" && row.archived) ||
      (filteredAction === "draft" && row.draft) ||
      (filteredAction === "all" && row)
  );

  // ----- On page load -----

  useEffect(() => {
    /**
     * This function handles the creation of columns and rows for MUI Datagrid. Running this function will could the MUI-Datagrid to re-mount.
     * @param {} param
     */
    const populateDataGrid = (sims) => {
      const columns = [
        {
          field: "name",
          headerName: "Name",
          minWidth: 100,
          flex: 1,
          renderCell: (params) => (
            <Typography
              className={` ${params.row.draft ? "" : "text-links"}`}
              onClick={() => {
                if (!params.row.draft) {
                  setSimUUID(params.row.id);
                  setOpen(true);
                }
              }}
            >
              {params.row.name}
            </Typography>
          ),
        },
        {
          field: "customerNum",
          headerName: "Number of Customers",
          minWidth: 100,
          flex: 1,
        },
        {
          field: "av",
          headerName: "Has Alert Validation",
          maxWidth: 150,
          flex: 1,
          renderCell: (params) => (
            <IconButton>
              {params.row.av ? (
                <FaCheck color={COLORS.success.main} />
              ) : (
                <FaTimes color={COLORS.error.main} />
              )}
            </IconButton>
          ),
        },
        {
          field: "smv",
          headerName: "Smv Access",
          maxWidth: 150,
          flex: 1,
          renderCell: (params) => (
            <IconButton>
              {params.row.smv ? (
                <FaCheck color={COLORS.success.main} />
              ) : (
                <FaTimes color={COLORS.error.main} />
              )}
            </IconButton>
          ),
        },
        {
          field: "draft",
          headerName: "Draft",
          maxWidth: 150,
          flex: 1,
          renderCell: (params) => (
            <IconButton>
              {params.row.draft ? (
                <FaCheck color={COLORS.success.main} />
              ) : (
                <FaTimes color={COLORS.error.main} />
              )}
            </IconButton>
          ),
        },
        {
          field: "archived",
          headerName: "Actions",
          maxWidth: 200,
          flex: 1,
          renderCell: (params) => {
            const archived = params.row.archived;
            const text = archived ? "Un-Archive" : "Archive";

            const changeArchive = async () => {
              const res = await toast.promise(
                getRequest(
                  `/react/api/admin/simulation/archive/${params.row.id}`,
                  accessToken
                ),
                {
                  pending: `${
                    archived
                      ? "Un-Archiving Simulation"
                      : "Archiving Simulation"
                  }`,
                  success: `${archived ? "Un-Archived" : "Archived"}`,
                  error: "Something went wrong!",
                }
              );
              if (res.status === 200) {
                populateDataGrid(res.data.sims);
              }
            };

            return (
              <LoadingButton onClick={changeArchive} variant="contained">
                {text}
              </LoadingButton>
            );
          },
        },
      ];

      const rows = sims.map((element) => {
        return {
          id: element.uuid,
          name: element.name,
          customerNum: element.customer_access?.length,
          archived: element.archived,
          smv: element.smv_access,
          draft: element.draft,
          av: element.alert_validation,
        };
      });

      setDataGridData((data) => ({
        ...data,
        rows: rows,
        columns: columns,
      }));
    };

    const getSims = async () => {
      const res = await getRequest("/react/api/admin/simulation/all", accessToken);

      if (res.status === 200) {
        populateDataGrid(res.data.sims);
      } else {
        console.log(
          "%cerror AdminSimulations.jsx useEffect()",
          "color: red; display: block; width: 100%;",
          "Reponse status not 200"
        );
      }
    };

    getSims();
  }, [accessToken, current_customer, reload]);

  return (
    <>
      <Stack spacing={3}>
        {/* Breadcrumbs */}
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="text.primary">Administration</Typography>
          <Typography color="text.primary">Simulations</Typography>
        </Breadcrumbs>
        {/* Page Title  && Buttons */}
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography variant="h3">Simulations</Typography>
          <TextButton
            tooltip="Add Simulation"
            icon={FaPlus}
            onClick={() => setOpen2(true)}
          />
        </Stack>
        <Stack>
          {/* Tabs for filtering active and inactive customers */}
          <Tabs
            value={filteredAction}
            onChange={(event, newValue) => {
              setFilteredAction(newValue);
            }}
            variant="40%"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="All" value="all" />
            <Tab label="Active" value="active" />
            <Tab label="Inactive" value="inactive" />
            <Tab label="Draft" value="draft" />
          </Tabs>
          {/* Template Grid */}
          <DataGridWithStyles
            name="admin-simulations"
            autoHeight
            paper
            pointer
            rows={filteredDataTableRows}
            columns={dataGridData.columns}
          />
        </Stack>
      </Stack>
      <FormModal open={open} setOpen={setOpen} sx={style}>
        {simUUID !== "" && (
          <AdminSimulationForm
            simUUID={simUUID}
            setReload={setReload}
            setOpen={setOpen}
            open={open}
            reload={reload}
            type="edit"
          />
        )}
      </FormModal>
      <FormModal open={open2} setOpen={setOpen2} sx={style}>
        <AdminSimulationForm
          setReload={setReload}
          setOpen={setOpen2}
          open={open2}
          reload={reload}
          type="add"
        />
      </FormModal>
    </>
  );
};

export default AdminSimulations;
