import { Box, Paper, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useContext } from "react";
import ActivityTimeLine from "../ActivityTimeline";
import CampaignContext from "../provider/CampaignContext";

const ActionsTimeLine = () => {
  const { timeline } = useContext(CampaignContext); // Access campaign from context

  if (!timeline) {
    // Campaign data is not available yet, show loading or fallback content
    return (
      <Paper sx={{ marginTop: 2 }}>
        <Typography
          fontWeight="bold"
          variant="h6"
          sx={{ display: "flex", padding: 1 }}
        >
          Activity Timeline
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "700px",
          }}
        >
          <CircularProgress />
        </Box>
      </Paper>
    );
  }

  return (
    <Paper sx={{ marginTop: 2 }}>
      <Typography
        fontWeight="bold"
        variant="h6"
        sx={{ display: "flex", padding: 1 }}
      >
        Activity Timeline
      </Typography>
      <ActivityTimeLine timeline={timeline} />
    </Paper>
  );
};

export default ActionsTimeLine;
