import React, { useContext, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Autocomplete, TextField } from "@mui/material";
import { useHttpRequest } from "../../Hooks";
import { FormContext } from "../context/FormContext";
import { v1 as uuidv1 } from "uuid";
import { capitalize } from "../../Helpers/functions";

const BlindspotUserSelect = (props) => {
  const { name, fullWidth, required, disabled, label, sx } = props;
  const id = uuidv1();
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const { setFormData } = useContext(FormContext);
  const {
    response: users,
    loading,
    error,
  } = useHttpRequest({
    method: "GET",
    path: "/api/v2/admin/blindspot-users", // Requires User.is_staff == True
  });

  useEffect(() => {
    if (users && !error) {
      let options = [...users];
      for (const i in options) {
        const fullName = `${options[i].first} ${options[i].last}`;
        options[i].label = fullName;
      }
      setOptions(options);
    }
  }, [users, error]);

  const isRequired = useCallback(() => {
    if (!required) return false;
    if (value === null && inputValue === "") return true;
    if (value.length === 0 && required) return true;
    return required;
  }, [value, inputValue, required]);

  return !loading && !error && options.length > 0 ? (
    <Autocomplete
      required={required}
      disabled={disabled}
      fullWidth={fullWidth}
      clearOnBlur
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        const user = options.find((o) => {
          if (newInputValue === "") return false;
          return o.email === newInputValue;
        });
        setInputValue(newInputValue);
        setFormData((data) => ({
          ...data,
          [name]: user?.id,
        }));
      }}
      id={id}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth={fullWidth}
          required={isRequired()}
          label={label || capitalize(name)}
          sx={sx}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.first} {option.last}
          </li>
        );
      }}
    />
  ) : null;
};

BlindspotUserSelect.propTypes = {
  name: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

BlindspotUserSelect.defaultProps = {
  fullWidth: false,
  required: false,
  disabled: false,
  sx: {},
};

export default BlindspotUserSelect;
