import React from "react";
import { Breadcrumbs, Typography, Paper, Box, Stack } from "@mui/material";
import AssessmentRefForm from "../../../Components/Forms/Administration/AdminAssessmentRefForm/AssessmentRefForm";
import { useAppSelector } from "../../../Redux/app/hooks";
import { Link } from "react-router-dom";

const AdminAssessmentRefDetail = () => {
  const current_customer = useAppSelector(
    (state) => state.customer
  );
  // ----- Context -----

  // ----- States -----

  // ----- Variable -----

  // ----- Functions -----

  // ----- On page load -----

  return (
    <Stack spacing={3}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Administration</Typography>
        <Link to={`/${current_customer.uuid}/administration/assess-references`}>
          Endpoint Assessment Reference Library
        </Link>
        <Typography color="text.primary">Edit Reference</Typography>
      </Breadcrumbs>

      {/* Page Title */}
      <Typography variant="h3">Edit</Typography>

      <Box sx={{ display: "flex", maxWidth: 1300 }}>
        {/* Form */}
        <Paper sx={{ flex: 1, mr: 1, p: 3 }}>
          <AssessmentRefForm />
        </Paper>

        {/* Right Box */}
        <Paper sx={{ flex: 1, ml: 1, p: 3 }}>
          <Stack spacing={2}>
            <Typography variant="h6" fontWeight="bold">
              Endpoint Assessment Reference Detail
            </Typography>
            <Typography>
              <b>Friendly Name</b>: The label for this endpoint check
            </Typography>
            <Typography>
              <b>References</b>: Any background detail you would like to include
              as background when the check is being reviewed by the end user.
            </Typography>
            <Typography>
              <b>Remeditation</b>: Any guidance on resolving this issue, if a
              resultion is required at all.
            </Typography>
          </Stack>
        </Paper>
      </Box>
    </Stack>
  );
};

export default AdminAssessmentRefDetail;
