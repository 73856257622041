import { Box, Chip, Divider, FormControl, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import SelectSearch from "react-select-search";
import { FormContext } from "../context/FormContext";
import classes from "./style.module.css";
import "react-select-search/style.css";

const CustomSelectSearch = (props) => {
  const { name, fullWidth, options, defaults } = props;
  const { formData, setFormData } = useContext(FormContext);
  const [selectedValues, setSelectedValues] = useState([]);
  const handleItemSelect = (value) => {
    const updatedValues = [...selectedValues, value];
    const updatedIds = updatedValues.map((val) => val[0][0]);
    setSelectedValues(updatedValues);
    setFormData((data) => ({
      ...data,
      [name]: updatedIds,
    }));
  };

  const handleChipDelete = (temp) => {
    const updatedValues = selectedValues.filter(
      (value) => value[0] !== temp[0]
    );
    const updatedIds = updatedValues.map((val) => val[0][0]);
    setSelectedValues(updatedValues);
    setFormData((data) => ({
      ...data,
      [name]: updatedIds,
    }));
  };

  useEffect(() => {
    if (options.length > 0 && defaults) {
      setFormData((prevData) => {
        const temp = defaults.map((id) => [
          [id, options.find((option) => option[0] === id)[1]],
        ]);
        setSelectedValues(temp);
        return {
          ...prevData,
          [name]: defaults,
        };
      });
    }
  }, [options, defaults, name, setFormData]);
  useEffect(() => {
    // This effect does nothing, but it depends on formData
  }, [formData]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-evenly",
        marginBottom: 1,
        marginTop: 1,
      }}
    >
      <Box sx={{ width: "100%" }}>
        <FormControl fullWidth={fullWidth}>
          <SelectSearch
            className={classes}
            name={name}
            options={options
              .filter(
                (value) =>
                  !selectedValues.some(
                    (selected) => selected[0][0] === value[0]
                  )
              )
              .map((value) => ({
                value: value,
                name: value[1] + "-" + value[2],
                key: value[0],
              }))}
            multiple={true}
            onChange={(value) => handleItemSelect(value)}
            placeholder={`Search ${name}`}
            search
          />
        </FormControl>
      </Box>

      <Box
        sx={{
          width: "100%",
          padding: 1,
          overflowY: "auto",
          maxHeight: 245,
        }}
      >
        <Typography>Selected {name}</Typography>
        <Divider />
        {selectedValues.map((value) => (
          <Chip
            key={value[0][0]}
            label={value[0][1]}
            onDelete={() => handleChipDelete(value)}
            variant="outlined"
            sx={{
              height: "auto",
              "& .MuiChip-label": {
                display: "block",
                whiteSpace: "normal",
              },
              marginTop: 1,
              width: "100%",
              minHeight: "30px",
              justifyContent: "space-between",
              textAlign: "left",
              backgroundColor: "lightgray",
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

CustomSelectSearch.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  maxLength: PropTypes.number,
  type: PropTypes.string,
};

CustomSelectSearch.defaultProps = {
  required: false,
  fullWidth: false,
  maxLength: null,
  helperText: null,
  type: "text",
};

export default CustomSelectSearch;
